import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import food_delivery from '../../assets/images/food-delivery.png';

class ContactRestaurant extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.callInformation = this.callInformation.bind(this);
        this.changeActiveNumber = this.changeActiveNumber.bind(this);
        this.state = {
            phoneNumber: "",
            phoneNumberFetched: false
        }
    }

    componentDidUpdate(){
        if (this.props.restaurant.phone_number !== undefined && !this.state.phoneNumberFetched){
            this.setState({
                phoneNumber: Math.round(this.props.restaurant.phone_number[0].trim()),
                phoneNumberFetched: true
            })
        }
    }

    closeModalHandler() {
        this.props.closeContactModal();
    }

    getActiveClassName(phoneNumber){
        if (Math.round(phoneNumber.trim()) === this.state.phoneNumber){
            return "RestaurantContactNumberActive";
        } else {
            return "RestaurantContactNumber";
        }
    }

    callActiveNumber() {
        if (this.state.phoneNumber !== ""){
            window.open("tel:" + this.state.phoneNumber, "_blank");
        } else {
            window.open("tel:" + this.props.restaurant.phone_number[0], "_blank");
        }
    }

    changeActiveNumber(phoneNumber){
        if (this.state.phoneNumber === Math.round(phoneNumber.trim())){
            this.setState({
                phoneNumber: ""
            })
        } else {
            this.setState({
                phoneNumber: Math.round(phoneNumber.trim())
            })
        }
    }

    callInformation() {
        if(this.props.restaurantDetailsLoaded){
            return (
                <div className="RestaurantContactModalBelowContainer">
                    {this.props.restaurant.phone_number.map((phone_number) => {
                        return (
                            <div onClick={() => this.changeActiveNumber(phone_number)} key={phone_number} className={this.getActiveClassName(phone_number)}>+91 {Math.round(phone_number.trim())}</div>
                        )
                    })}
                </div>
            );
        } else {
            return(
                <div className="DishRateModalBelowContainer">
                    <div className="DishSuccessModalHeader">Loading Restaurant Details...</div>
                </div>
            )
        }
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    customMessage(){
        if (this.props.restaurant.message !== ""){
            return (
                <div style={{textAlign: "center", fontFamily: "Quicksand-SemiBold", fontSize: "18px", margin: "3px 0px", color: "#EA5B2F", letterSpacing: "-1px"}}>
                        {this.props.restaurant.message}
                </div>
            )
        } else {
            return null;
        }
    }

    getCallButtonStyle() {
        return {padding: "15px 45px", marginTop: "10px", backgroundColor: "rgba(234,91,47, 0.8)"};
    }
    
    render() {
        if(!this.props.showContactModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div style={{height: "80px", backgroundColor: "#F9E841", borderRadius: "10px 10px 0px 0px"}}>
                        <div className="RestaurantContactImageContainer">
                            <img className="RestaurantContactImage" src={food_delivery} alt=""/>
                        </div>
                    </div>
                    <div style={{textAlign: "center", marginTop: "80px", fontFamily: "Poppins-Bold", fontSize: "24px"}}>
                        {this.props.restaurant.name}
                    </div>
                    {this.customMessage()}
                    {this.callInformation()}
                    <div style={this.getCallButtonStyle()} onClick={() => this.callActiveNumber()} className="DishRateModalSubmitButtonContainer">
                        Call Now
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactRestaurant;