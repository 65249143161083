import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import '../../assets/styles/AboutUs.css';
import logo from '../../assets/images/logo_white.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Logo/>
                        <Authentication
                            toggleAuthModal={this.props.toggleAuthModal}
                            setActiveForm={this.props.setActiveForm}
                        />
                    </div>
                </div> 
            )
        }
        else{
            return (
                <div style={headerStyle} className="Header">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Logo/>
                        <Authentication
                            toggleAuthModal={this.props.toggleAuthModal}
                            setActiveForm={this.props.setActiveForm}
                        />
                    </Grid>
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {background: "#F9D543"};
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <Link  to={'/'}><img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/></Link> 
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div style={{marginTop: "10px"}} className="Logo LogoHome">
                                <Link  to={'/'}><img style={{cursor: "pointer"}} className="LogoImage LogoImageMargin" src={logo} alt="logo"/></Link>
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

// Log In and Sign Up options
class Authentication extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleAuthModalHander = this.toggleAuthModalHander.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    toggleAuthModalHander(form) {
        window.scrollTo(0, 0);
        this.props.toggleAuthModal();
        this.props.setActiveForm(form);
    }

    render() {

        if (this.state.isMobileScreen){
            return (
                <div style={{paddingTop: "15px"}}>
                    <Button onClick={() => this.toggleAuthModalHander("login")} id="login-button-mobile">
                        Login
                    </Button>
                    <Button onClick={() => this.toggleAuthModalHander("register")} id="signup-button-mobile">
                        Sign Up
                    </Button>
                </div>
            )
        } else {
            return (
                <Grid container justifyContent="flex-end" item xs={6} spacing={0}>
                    <React.Fragment>
                        <Grid className="AuthBackgroundWrapper" container item alignItems="flex-start" justifyContent="flex-end">
                            <Grid style={{marginRight: "3vw"}} className="AuthBackground">
                                <Button onClick={() => this.toggleAuthModalHander("login")} id="login-button">
                                    Login
                                </Button>
                                <Button onClick={() => this.toggleAuthModalHander("register")} id="signup-button">
                                    Sign Up
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    }
}

export default withRouter(Header);