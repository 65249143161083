import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import { HashLink } from 'react-router-hash-link';

class Filters extends Component {

    constructor(props){
        super(props);
        this.state = {
            priceFilter: "ascending",
            filters: this.props.filters,
            filersChanged: false,
            isMobileScreen: false
        };
        this.changeActiveTab = this.changeActiveTab.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidUpdate(){
        if (!this.state.filtersChanged && this.props.filters.length > 0){
            this.setState({
                filters: this.props.filters,
                filtersChanged: true
            })
        }
    }
    componentDidMount(){
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    changeActiveTab(dishTypeId, type){
        if(this.props.allDishTypesLoaded && this.props.allDishesLoaded){
            let items = [...this.state.filters];
            let activeDishTypeItems = [];
            let activeCuisineTypeItems = [];
            for (var i=0; i <this.state.filters.length; i++){
                if (this.state.filters[i].type === "dish_type"){
                    if (type === this.state.filters[i].type){
                        if (dishTypeId === this.state.filters[i].dishTypeId){
                            items[i].isActive = !items[i].isActive;
                        }
                    }
                    if (items[i].isActive){
                        activeDishTypeItems.push(items[i].dishTypeId);
                    }
                } else {
                    if (type === this.state.filters[i].type){
                        if (dishTypeId === this.state.filters[i].cuisineId){
                            items[i].isActive = !items[i].isActive;
                        }
                    }
                    if (items[i].isActive){
                        activeCuisineTypeItems.push(items[i].cuisineId);
                    }
                }
            }
            this.setState({
                filters: items  
            })
            if (this.props.isDishScreen){
                this.props.applyFilters(activeDishTypeItems, activeCuisineTypeItems);
            } else {
                if (activeDishTypeItems.length > 0){
                    this.props.filterDishTypes(activeDishTypeItems);
                }
            }
            if (activeDishTypeItems.length === 0 && activeCuisineTypeItems.length === 0){
                this.clearFilters();
            }
        }
    }

    clearFilters(){
        let items = [...this.state.filters];
        for (var i=0; i <this.state.filters.length; i++){
            items[i].isActive = false;
        }
        this.setState({
            filters: items  
        })
        let activeItems = [];
        this.props.filterDishTypes(activeItems);
        if (this.props.isDishScreen){
            this.props.filterCuisineTypes(activeItems);
        }
    }

    showFilterImage(filter, imageStyle, id){
        if (this.props.isDishScreen){
            return (
                this.state.isMobileScreen ? 
                <img className="FilterItemsImageM" src={filter.image} alt="filter img"/>
                :
                <img style={imageStyle[id]} className="FilterItemsImage" src={filter.image} alt="filter img"/>
            )
        } else {
            return null;
        }
    }

    showFilterText(filter, textStyle, restaurantTextStyle, id){
        if (this.props.isDishScreen){
            return (
                <div style={textStyle[id]} className="FilterItemsText">{filter.name}</div>
            )
        } else {
            return (
                <div style={restaurantTextStyle[id]} className="FilterItemsText FilterRestaurantItemsText">{filter.name}</div>
            )
        }
    }

    scrollWithOffset = (el, menuName) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -240;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        this.props.setCurrentRestoMenu(menuName);
    }

    filterItem(filter, imageStyle, textStyle, restaurantTextStyle, id){
        if (filter.display){
            if (this.props.isDishScreen){
                if (filter.type === "dish_type"){
                    return(
                        <div onClick={() => this.changeActiveTab(filter.dishTypeId, "dish_type")} className="FilterItemsContainer" key={"dish_type" + filter.dishTypeId}>
                            {this.showFilterImage(filter, imageStyle, id)}
                            {this.showFilterText(filter, textStyle, restaurantTextStyle, id)}
                        </div>
                    )
                } else {
                    return(
                        <div onClick={() => this.changeActiveTab(filter.cuisineId, "cuisine")} className="FilterItemsContainer" key={"cuisine" + filter.cuisineId}>
                            {this.showFilterImage(filter, imageStyle, id)}
                            {this.showFilterText(filter, textStyle, restaurantTextStyle, id)}
                        </div>
                    )
                }
            } else {
                if (this.state.isMobileScreen){
                    return null;
                } else {
                    return(
                        <HashLink style={{textDecoration: "none", color: "white"}} scroll={el => this.scrollWithOffset(el, filter.name)} smooth to={`#resto_filter_id${filter.name}`}>
                            <div className="FilterItemsContainer FilterRestaurantItemsContainer" key={filter.dishTypeId} style={this.state.isMobileScreen ? {marginRight:"5px", marginLeft:"0px"} : {}}>
                                {this.showFilterImage(filter, imageStyle, id)}
                                {this.showFilterText(filter, textStyle, restaurantTextStyle, id)}
                            </div>
                        </HashLink>
                    )
                }
            }
        } else {
            return null;
        }
    }

    getFilter(dishTypeID, filters, type){
        for (var i=0; i<filters.length; i++){
            if (type === "dish_type"){
                if (dishTypeID === filters[i].dishTypeId){
                    return i;
                }
            } else {
                if (dishTypeID === filters[i].cuisineId){
                    return i;
                }
            }
        }
    }

    render() {
        let imageStyle = [];
        let textStyle = [];
        let restaurantTextStyle = [];
        let filterPresent = false;
        for (var i=0; i<this.state.filters.length; i++){
            if (this.state.filters[i].display === true){
                imageStyle.push({});
                textStyle.push({});
                restaurantTextStyle.push({});
                if ((this.state.filters[i].isActive && this.props.isDishScreen) || (this.state.filters[i].name === this.props.currentRestoMenu && !this.props.isDishScreen)) {
                    filterPresent = true;
                    imageStyle[i] = {width: "50px"};
                    textStyle[i] = this.state.isMobileScreen ? {color: "#EA5B2F", fontFamily: "Poppins-Medium"} : {color: "#EA5B2F", fontFamily: "Poppins-Medium"};
                    restaurantTextStyle[i] = {color: "#EA5B2F", backgroundColor: "#FFFBFA", borderRadius: '5px', padding: this.state.isMobileScreen ? '10px' : '10px 30px', border: '1px solid #EA5B2F', fontFamily: 'Quicksand-Bold'};
                } else {
                    imageStyle[i] = {height: "50px"};
                    textStyle[i] = {color: "#606060", fontFamily: "Poppins-Medium"};
                    restaurantTextStyle[i] = {color: "#2B2624", backgroundColor: "#FFFBFA", borderRadius: '5px', padding: this.state.isMobileScreen ? '10px' : '10px 30px', border: '1px solid #DEC7BF', fontFamily: 'Quicksand-Bold'};
                }
            }
        }
        if (this.props.nonServicable && this.props.isDishScreen){
            return null;
        } else {
            if (this.props.dishTypeLoading){
                return(
                    <div style={{marginBottom: "80px"}} className="DishesActions">
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid justifyContent="center" alignItems="center" className="FilterRow" container item xs={12}>
                                <div>Loading Filters...</div>
                            </Grid>
                        </Grid>
                    </div>
                )
            } else {
                return(
                    <div className="DishesActions DishFilters">
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid justifyContent="center" alignItems="center" className="FilterRow" container item xs={12}>
                                <div style={{marginRight: filterPresent ? '0px' : '3.5vw'}} className={this.state.isMobileScreen ? "SearchBarContainer FilterItemsSuperM ddnoScroll" : "SearchBarContainer FilterItemsSuper"}>
                                    {
                                        this.state.filters.map((filter) => {
                                            return this.filterItem(filter, imageStyle, textStyle, restaurantTextStyle, this.getFilter(filter.type === "dish_type" ? filter.dishTypeId : filter.cuisineId, this.state.filters, filter.type))
                                        })
                                    }
                                </div>
                                {/* {clearButton} */}
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        }
    }
}

export default Filters;