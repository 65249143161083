import React, { Component } from 'react';
import '../../assets/styles/PartnerTnc.css';
import { withRouter } from 'react-router';
import '../../assets/styles/PartnerWithUs.css';

// Top Header which contains logo, auth options, burger icon and header description
class Content extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

   render() {
    return (
        <div className="TncContainer">
            <Title/>
            <Intro/>
            <Definitions/>
            <Eligibility/>
            <Modifications/>
            <UserContent/>
            <License/>
            <Registration/>
            <Ownership/>
            <Reviews/>
            <Behaviour/>
            <Indemnity/>
            <Limitation/>
            <ThirdPartyWebsites/>
            <Termination/>
            <Interruptions/>
            <NoWarranties/>
            <Agreement/>
            <Severability/>
            <Relationship/>
            <GoverningLaw/>
            <Notice/>
            <Refund/>
            <ContactUs/>
            <PrivacyPolicy/>
            <Consent/>
            <Survival/>
        </div>
    )
   }
}

class Title extends Component {
    render() {
        return(
            <div className="TncTitle">TERMS AND CONDITIONS OF USE</div>
        )
    }
}

class Intro extends Component {
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">1. INTRODUCTION</div>
                <div className="TncSectionContent">
                    <span className="TncBold">This document is an electronic record in 
                    terms of the Information Technology Act, 2000 and rules made thereunder, 
                    as applicable and the amended provisions about electronic records in 
                    various statutes as amended by the Information Technology Act, 2000. 
                    that require prominently publishing the rules and regulations, 
                    Privacy Policy for access of the website or mobile application. 
                    This electronic record is generated by a computer system and does not 
                    require any physical or digital signatures.</span>
                </div>
                <div className="TncSectionContent">
                    We thank you for visiting us! We love food and we are here to help 
                    everyone who shares this fine habit. We welcome all the reviews, ratings, 
                    pictures & comments you share but we must adhere to the content 
                    guidelines. We provide an online platform for reviews and ratings which are 
                    submitted by our registered users, which includes but is not limited to 
                    information about and commentary on all kinds of food items, dishes and 
                    their serving restaurants to get great food, dishes and other eatable 
                    recommendations through our websites and mobile application.
                </div>
                <div className="TncSectionContent">
                    These Terms and Conditions  of Use (hereinafter referred to as 
                    <span className="TncBold"> "Terms"</span> / <span className="TncBold"> "Terms and Conditions"
                    </span>) constitute a legally binding agreement made 
                    between you, (hereinafter referred to as
                    <span className="TncBold"> "you"</span> or <span className="TncBold"> "your"</span>), 
                    a User and DaiDish hereinafter referred to as <span className="TncBold">"we"</span> or 
                    <span className="TncBold"> "us"</span> or 
                    <span className="TncBold"> "our"</span> or 
                    <span className="TncBold"> "DaiDish"</span>), concerning your access to and use of 
                    the website being www.daidish.com  (<span className="TncBold">"website"</span>) and 
                    its related mobile application (<span className="TncBold">"App"</span>)  and services 
                    that DaiDish may provide now or in the future (collectively, referred to as 
                    the <span className="TncBold">"Site"</span> or 
                    <span className="TncBold"> "Website"</span> or 
                    <span className="TncBold"> "App"</span> or 
                    <span className="TncBold"> "Application"</span> or 
                    <span className="TncBold"> "Platform"</span>). You agree that by accessing the Site/App, 
                    you have read, understood, and agreed to be fully bound by all of these terms.
                </div>
                <div className="TncSectionContent">
                    This Site enables you to avail certain services from DaiDish. Some of the services offered 
                    by DaiDish are listed below: 
                    <div className="TncBulletPoint">
                        1. Enables Users to provide reviews and ratings, which includes but is not limited to 
                        information about and commentary on all kinds of food items, dishes of the serving 
                        restaurants to get great food, dishes and other eatable recommendations through our Platform.
                    </div>
                    <div className="TncBulletPoint">
                        2. Enables Users to choose the restaurants and place orders for dish and/or food products ("Order/(s)""). 
                    </div>
                </div>
                <div className="TncSectionContent">
                    Please note that third party service providers i.e. pick-up and delivery partners ("PDP") will be responsible 
                    for providing the pick-up and delivery services of the Orders.  For both Orders and delivery services, 
                    DaiDish is merely acting as an intermediary between the Users, the PDP and the restaurants and DaiDish 
                    shall not be responsible for the Orders and delivery services. For the Orders, pickup and delivery services, 
                    PDPs may charge the Users of the Site), a service fee (inclusive of applicable taxes whenever not 
                    expressly mentioned). DaiDish shall not be liable in any manner for any services rendered by the PDPs 
                    including Orders, pickup and delivery services. Any failure by the PDP to render the Order or pickup and 
                    delivery service shall be the sole responsibility of such PDP and the User acknowledges that the User 
                    shall not hold DaiDish responsible for such failure in any manner.
                </div>
                <div className="TncSectionContent">
                    These Terms are effective for all our existing and future Users including but without limitation of 
                    all kind of Users contributing content and reviews in the form of written, oral or in any audio-visual 
                    mode or reading, liking, sharing or commenting on these contributed reviews.
                </div>
                <div className="TncSectionContent">
                    By accessing this website,
                    <div className="TncBulletPoint">
                        a) you acknowledge that you have read, understood, and agree to be bound by this Agreement, and
                    </div>
                    <div className="TncBulletPoint">
                        b) you represent and warrant that you are of legal age and not prohibited by any law as applicable 
                        to (now or in future) from accessing or using this website/ Application. 
                    </div>
                </div>
                <div className="TncSectionContent">
                    <span className="TncBold">We reserve the right, in our sole discretion, to update, make changes or 
                    modifications to these Terms at any time and for any reason. The date of the last revision or update 
                    appears at the top under the title. PLEASE READ THIS TERMS CAREFULLY. BY REGISTERING FOR, ACCESSING, 
                    BROWSING, OR USING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY 
                    THIS TERMS, INCLUDING OUR PRIVACY POLICY (https://daidish.com/privacypolicy).</span> It is your sole 
                    responsibility to periodically review these Terms to stay informed of any updates. You will be subject 
                    to and will be deemed to have been made aware of and to have accepted the changes in any revised Terms and 
                    Conditions by your continued use of the Site on the date such revised terms are posted and made applicable.
                </div>
                <div className="TncSectionContent TncAgreement">
                    IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS AND PRIVACY POLICY IN THEIR ENTIRETY, THEN YOU ARE 
                    EXPRESSLY PROHIBITED FROM USING THE PLATFORM AND YOU MUST NOT USE THE PLATFORM AND YOU MUST LEAVE THIS 
                    WEBSITE/APP IMMEDIATELY.
                </div>
            </div>
        )
    }
}

class Definitions extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">2. DEFINITIONS</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.1</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"Applicable Law" </span>shall mean all 
                        applicable Indian laws, by-laws, rules, regulations, orders ordinances, 
                        protocols, codes, guidelines, policies, notices, directions, judgments, 
                        decrees or other requirements or official directive of any governmental 
                        authority or person acting under the authority of any governmental authority of India;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.2</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"Content" </span>shall include (but is not 
                        limited to) dish reviews, comments, ratings, texts, any data, content, images, 
                        product description, videos, location data, software, emails, communications or 
                        other materials or information of any type that is available through the Site;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.3</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"Intellectual Property Rights" </span>shall mean registered 
                        and unregistered rights in all forms of intellectual property subsisting under the 
                        laws of India and all analogous rights subsisting under the laws of other 
                        jurisdictions and shall include any legally protectable product or process of 
                        the human intellect whether registrable as patents, trademarks, copyrights, 
                        designs or otherwise such as an invention, expression or literary creation, 
                        unique name, trade secret, business method, database, industrial process, computer 
                        program, source code, process or presentation, content, and videos and exercises;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.4</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"DaiDish Information/Data" </span>shall mean any and all 
                        information relating to the User provided to the Site by the User at his sole 
                        discretion in order to use the Site;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.5</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"DaiDish Services" </span>shall mean and include the 
                        services provided by DaiDish through its Site;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.6</div>
                    <div className="TncSectionSubContentBody">
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"User Content" </span>shall mean Content that you 
                        upload, share or transmit to, through or in connection with the Website, 
                        such as likes, ratings, comments, questions, dish reviews, images, photos, 
                        messages, videos and any other material that you display or displayed on the Site.
                    </div>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.7</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"User/ Users" </span>shall mean any individual who 
                        visits, accesses, posts suggestions, ideas, comments, questions, food 
                        review or other information on the Platform and uses the Services provided on the Site; and
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">2.8</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">"Website" </span>shall mean the website www.daidish.com.
                    </div>
                </div>
            </div>
        )
    }
}

class Eligibility extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">3. ELIGIBILITY TO USE THE WEBSITE</div>
                <div className="TncSectionContent">
                    You must be legally competent for entering into a binding contract under Applicable 
                    Law. If you are not legally competent for entering into a contract under Applicable Law, 
                    you cannot use the Site and cease its use immediately. If you are under the age of 18 years, 
                    you are not eligible to register for the Website. If you wish to use our Website, such use 
                    shall be made available to you by your legal guardian or parents. The parent or legal 
                    guardian agree to be bound by these Terms in respect of your use of the Website.
                </div>
            </div>
        )
    }
}

class Modifications extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">4. MODIFICATIONS TO SERVICES/OFFERINGS & DATA</div>
                <div className="TncSectionContent">
                    The offerings on our Website may change over time as we may add more features and 
                    functionality or delete some of the less used features. We may modify, suspend or 
                    discontinue, temporarily or permanently, the offerings, Services (or a part of the 
                    Services) from time to time without any prior or subsequent notice to you. Please 
                    backup your data as we have no responsibility in case of deletion or failure to 
                    store any data or other content maintained or transmitted by the offerings and 
                    services. You agree that We will not be liable to you or any third party for 
                    any modification, suspension or discontinuance of the Services or deletion of your data.
                </div>
                <div className="TncSectionContent">
                    You acknowledge that We may establish general practices and limit the use of the various 
                    offering of our Services, including without limitation the maximum period that data or 
                    other content will be retained by the offering of our Services and the maximum storage 
                    space that will be allotted on our servers on your behalf. You agree that we have no 
                    responsibility or liability for the deletion or failure to store any data or other 
                    content maintained or uploaded by Services.
                </div>
            </div>
        )
    }
}

class UserContent extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">5. USER CONTENT</div>
                <div className="TncSectionContent">
                    Our Site may permit You to submit, transmit, post or otherwise provide User Content 
                    to the extent you access our Services through a mobile device, your wireless service 
                    carrier's standard charges, data rates and other fees may apply. You agree that DaiDish 
                    will not be responsible for the message and data rates due to your use of any of our 
                    mobile applications on your device and You are solely responsible for any costs 
                    associated with using the Site on your devices. In the event you change or deactivate 
                    your mobile telephone number, you agree to immediately update your account information 
                    to ensure that your messages are not sent to the person that acquires your old number.
                </div>
                <div className="TncSectionContent">
                    We may share User Content with restaurants. The delivery personnel of the PDP 
                    will also have the phone number, address and other data needed to deliver Your Orders. 
                    We are not responsible for misuse of such data by the delivery Personnel or the PDP. 
                </div>
                <div className="TncSectionContent">
                    The Services and/or the Content may contain technical inaccuracies, typographical errors, 
                    or omissions, including with respect to allergy information and/or food preparation 
                    standards. We are not responsible for any such typographical, technical, pricing, 
                    or other errors listed on or omitted from the Services and/or the Content.
                </div>
            </div>
        )
    }
}

class License extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">6. LICENSE GRANT AND RESTRICTIONS BY USERS</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.1. LICENSE GRANT</div>
                </div>
                <div className="TncSectionContent">
                    Subject to the terms and conditions of this Terms of Use, DaiDish grants 
                    you a personal, limited, non-exclusive, non-transferable, non-sub-licenseable, 
                    revocable license to access the Platform. Except for this limited grant of 
                    access and use under these Terms and Conditions, we do not grant you any 
                    other rights to the Platform.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.2. RESTRICTIONS</div>
                </div>
                <div className="TncSectionContent">
                    You agree, undertake and confirm that your use of the Site shall be strictly 
                    governed by the following binding principles and you agree not to:
                </div>
                <div className="TncSectionContainer">
                    <div className="TncBulletPoint">
                        a) Access the Site through automated or non-human means, whether through a bot, script or otherwise.
                    </div>
                    <div className="TncBulletPoint">
                        b) Use the Site for any illegal or unauthorized purpose.
                    </div>
                    <div className="TncBulletPoint">
                        c) Violate any Applicable Law.
                    </div>
                    <div className="TncBulletPoint">
                        d) Interfere with or damage operation of the Site, uploading or otherwise disseminating viruses, 
                        adware, spyware, worms, or other malicious code intended to interrupt, destroy or limit the 
                        functionality of any computer resource.
                    </div>
                    <div className="TncBulletPoint">
                        e) Use our Services provided by the Platform for commercial purposes of any kind.
                    </div>
                    <div className="TncBulletPoint">
                        f) Advertise or sell any products, services or otherwise (whether or not for profit), 
                        or solicit others (including, without limitation, solicitations for contributions 
                        or donations) or use any public forum for commercial purposes of any kind. 
                        In the event you want to advertise your product or service, please 
                        contact <a href="mailto:info@daidish.com">info@daidish.com</a>.
                    </div>
                    <div className="TncBulletPoint">
                        g) Use another person's username, password or other account information, or another 
                        person's name, likeness, voice, image or photograph or impersonate any person or 
                        entity or misrepresent your identity or affiliation with any person or entity.
                    </div>
                    <div className="TncBulletPoint">
                        h) Engage in any form of antisocial, disrupting, or destructive acts, 
                        including "flaming", "spamming", "flooding", "trolling", and "griefing" as those 
                        terms are commonly understood and used on the Internet.
                    </div>
                    <div className="TncBulletPoint">
                        i) Copy or distribute any proprietary information for any commercial or business use, 
                        except with our prior written permission.
                    </div>
                    <div className="TncBulletPoint">
                        j) Use our Platform to post, transmit, or share material that you did not create or 
                        that you do not have permission to use.
                    </div>
                    <div className="TncBulletPoint">
                        k) Upload, post, transmit, share or otherwise make available through the Platform 
                        any illegal material, or any material that contains software viruses or any other 
                        computer code designed to interfere with the functionality of any computer software 
                        or hardware or telecommunications equipment.
                    </div>
                    <div className="TncBulletPoint">
                        l) Use the Platform to upload, modify, publish, post, transmit, or share material that 
                        is defamatory or invasive of the privacy of another person, graphically violent, or 
                        otherwise inappropriate for a general audience.
                    </div>
                    <div className="TncBulletPoint">
                        m) Upload, post, email, transmit or otherwise make available any unsolicited or 
                        unauthorized advertising, promotional materials, junk mail, spam mail, chain letters or 
                        any other form of solicitation, encumber to exist any lien or security interest on the 
                        subject matter of these Terms or to make any representation or warranty on behalf of 
                        DaiDish in any form or manner whatsoever.
                    </div>
                    <div className="TncBulletPoint">
                        n) Use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, 
                        algorithm or methodology, or any similar or equivalent manual process, to access, acquire, 
                        copy or monitor any portion of the Platform or any Content, or in any way reproduce or 
                        circumvent the navigational structure or presentation of the Platform or any Content, to 
                        obtain or attempt to obtain any materials, documents or information through any means not 
                        purposely made available through the Platform. We reserve our right to prohibit any such activity. 
                    </div>
                </div>
            </div>
        )
    }
}

class Registration extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">7. USER REGISTRATION AND SECURITY</div>
                <div className="TncSectionContent">
                    If you wish to access our Site, You may be required to register with us on our 
                    Site by providing your user name and password. You agree to keep your password 
                    confidential and shall be responsible for all use under your account and password. 
                    We reserve the right to remove, reclaim, or change a username you select if we 
                    determine, in our sole discretion, that such username is inappropriate, obscene, 
                    or otherwise objectionable. We have the final authority and sole discretion 
                    regarding deleting or amending your user data. 
                </div>
                <div className="TncSectionContent">
                    You can delete/unregister your account anytime by submitting a request 
                    to <a href="mailto:support@daidish.com">support@daidish.com</a>. DaiDish may require 
                    additional information from the User in order to enable us to verify your account 
                    before the deletion of your account.
                </div>
            </div>
        )
    }
}

class Ownership extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">8. OWNERSHIP OF USER CONTENT</div>
                <div className="TncSectionContent">
                    By posting, submitting or transmitting User Content in the Platform other than 
                    information submitted for registration you grant us and our affiliates a worldwide, 
                    non-exclusive, royalty-free, perpetual, transferable, irrevocable and fully 
                    sub-licensable right to:
                    <div className="TncBulletPoint">
                        a) Use, reproduce, modify, adapt, translate, distribute, publish, create derivative 
                        works from and publicly display and perform such Content submissions throughout the 
                        world in any media, now known or hereafter devised, for any purpose; and
                    </div>
                    <div className="TncBulletPoint">
                        b) Use the name and/or photo or Content that you submit in connection with such submissions.
                    </div>
                </div>
                <div className="TncSectionContent">
                    You acknowledge that we may choose to provide attribution of your comments or reviews at our 
                    discretion. By posting, submitting or transmitting User Content using our Site you permit 
                    DaiDish the right to store that information with us, and to use such User Content for, 
                    maintenance and marketing. You agree and understand that you must evaluate the submission of 
                    such User Content to the Site. You hereby agree that you shall, defend, and hold harmless 
                    DaiDish for all claims resulting from User Content provided by you to the Site.
                </div>
                <div className="TncSectionContent">
                    You agree that DaiDish has the right to reject, remove, edit, and move or prevent access to any 
                    User Content provided by the User to Daidish. However, the obligation to maintain the accuracy 
                    and correctness of the User Content remains with you.
                </div>
                <div className="TncSectionContent">
                    You acknowledge and agree that neither DaiDish nor any third party service providers are 
                    responsible for the submissions of the User Content transmitted or posted by You to the Site.
                </div>
                <div className="TncSectionContent">
                    You acknowledge that We have no obligation to monitor yours or anyone else's access to use of our 
                    Services for violations of the Terms or to review or edit any Content. However, we have the 
                    right to do so to operate and improve our Services (including without limitation for fraud 
                    prevention, risk assessment, investigation and customer support purposes), to ensure your 
                    compliance with this Terms and to comply with Applicable Law.
                </div>
                <div className="TncSectionContent">
                    You acknowledge and agree that Your submissions of User Content are original, non-confidential 
                    and non-proprietary and that you have the right or appropriate license to provide or make that 
                    such submission on our Site.
                </div>
                <div className="TncSectionContent">
                    You acknowledge and understand that DaiDish is and shall remain the registered owner of the 
                    word mark "DaiDish" and its logo, and also holds relevant Intellectual Property Rights in its other 
                    forms or variants.
                </div>
                <div className="TncSectionContent">
                    You are responsible for Your Content. You represent and warrant that you are the sole author of, 
                    own, or otherwise control all of the rights of Your Content.
                </div>
                <div className="TncSectionContent TncAgreement">
                    YOU ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST 
                    ON THE PLATFORM, YOUR PRIVATE MESSAGES AND FOR ALL ACTIVITIES THAT OCCUR IN YOUR ACCOUNT.
                </div>
            </div>
        )
    }
}

class Reviews extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">9. CUSTOMER REVIEWS & OUR RESPONSIBILITY</div>
                <div className="TncSectionContent">
                    Customer reviews and/or ratings are given for dish(es) and/or restaurants 
                    provided by the Users do not reflect the opinion of DaiDish. We receive 
                    many reviews and ratings for food & dishes by customers. These reviews 
                    reflect the opinion of such customers about those specific products. It 
                    is important to say that every review posted on our platform is the 
                    personal opinion of the customer/reviewer only. We are a neutral 
                    platform, which solely provides a means of communication between 
                    customers/reviewers and the concerned dish/food preparing restaurants and/ or 
                    owners/representatives with access to the restaurant business page where they 
                    post their most favored dish(es).
                </div>
                <div className="TncSectionContent">
                    The advertisements published on our Site are completely independent of the 
                    reviews received by such advertiser's food products.
                </div>
                <div className="TncSectionContent"> 
                    We are a neutral platform, and we don't arbitrate disputes, however in 
                    case if someone writes a review about a dish(es) or food product(s) that 
                    the restaurant/manufacturer (aggrieved party) thinks to be misleading or 
                    the one made with malafide intentions, the best option for the aggrieved 
                    party would be to contact the reviewer or post a public response to clear 
                    up any misunderstandings. If the aggrieved party believes that any 
                    particular customer's review violates any of our Terms and Conditions or policies, 
                    the aggrieved party may write to us 
                    at <a href="mailto:grievances@daidish.com">grievances@daidish.com</a> and bring 
                    such violation to our attention. We may remove the review in its sole discretion 
                    if the review violates the terms, or content guidelines and policies or otherwise 
                    harmful to the services. However, we do not warrant, covenant or promise to do 
                    so, and therefore, in case we fail to remove such review, rating or feedback, 
                    we shall not be liable in any manner whatsoever.
                </div>
                <div className="TncSectionContent"> 
                    We are not responsible for the food rating, quality, quantity, hygiene or anything 
                    related to the restaurant. DaiDish does not sell the products contained in your 
                    Order and is not a party to any such transaction. DaiDish has no control over the 
                    quality or safety of the Orders. Your Order is between you and the restaurant 
                    from which you Order.
                </div>
                <div className="TncSectionContent"> 
                    All the offers provided on the platform are limited to online orders for delivery 
                    only. No offers are provided for Dine-in at the restaurant.
                </div>
            </div>
        )
    }
}

class Behaviour extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">10. RESPONSIBLE USER BEHAVIOUR, REVIEW SPACES & RESTRICTIONS:</div>
                <div className="TncSectionContent">
                    Our Website may contain discussion forums, bulletin boards, review 
                    services or other forums in which you or any third parties may post 
                    reviews of food and dishes related experience you may have or other 
                    content, messages, materials or other items on this Website 
                    (<span className="TncBold">"Review Spaces"</span>). 
                </div>
                <div className="TncSectionContent">
                    If We provide such Review Spaces, you are solely responsible for your 
                    use of such Review Spaces and shall use them at your discretion. By 
                    using any Review Spaces, you expressly agree not to post, upload to, 
                    transmit, distribute, store, create or otherwise publish through this 
                    Site any of the following:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.1</div>
                    <div className="TncSectionSubContentBody">
                        Any message, data, information, text, music, sound, photos, graphics, 
                        code or any other material ("Content") that is unlawful, libellous, 
                        defamatory, obscene, pornographic, indecent, lewd, suggestive, 
                        harassing, threatening, invasive of privacy or publicity rights, 
                        abusive, inflammatory, fraudulent or otherwise objectionable.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.2</div>
                    <div className="TncSectionSubContentBody">
                        Content that would constitute, encourage or provide instructions 
                        for a criminal offence, violate the rights of any party, or that 
                        would otherwise create liability or violate any local, state, 
                        central or international law or the content that is threatening, 
                        harassing, indecent, pornographic, vulgar, embarrassing, malicious, 
                        defamatory, political, menacing to our interest etc. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.3</div>
                    <div className="TncSectionSubContentBody">
                        Content that may infringe any patent, trademark, trade secret, 
                        copyright or other intellectual proprietary rights of any party. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.4</div>
                    <div className="TncSectionSubContentBody">
                        Content that impersonates any person or entity or otherwise 
                        misrepresents your affiliation with a person or entity. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.5</div>
                    <div className="TncSectionSubContentBody">
                        Unsolicited promotions, advertising, contests, raffles, or solicitations.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.6</div>
                    <div className="TncSectionSubContentBody">
                        Private information of any third party, including, without limitation, 
                        surname (family name) addresses, phone numbers, email addresses, 
                        Aadhar numbers, credit card numbers etc.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.7</div>
                    <div className="TncSectionSubContentBody">
                        Viruses, corrupted data or other harmful, disruptive or destructive files;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.8</div>
                    <div className="TncSectionSubContentBody">
                        Content that is unrelated to the topic of the Review Spaces(s) in 
                        which such Content is posted; or
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.9</div>
                    <div className="TncSectionSubContentBody">
                        It will be our sole judgement to decide about the content or links to 
                        content if it:
                        <div className="TncBulletPoint">
                            a) Violates the previous subsections herein,
                        </div>
                        <div className="TncBulletPoint">
                            b) Is objectionable
                        </div>
                        <div className="TncBulletPoint">
                            c) Which restricts or inhibits any other person from using or 
                            enjoying the Review Spaces or this Website, or
                        </div>
                        <div className="TncBulletPoint">
                            d) This may expose us or our affiliates or its users to any 
                            harm or liability of any type.
                        </div>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.10</div>
                    <div className="TncSectionSubContentBody">
                        We take no responsibility and assume no liability for any Content 
                        posted, stored or uploaded by you or any third party, or for any 
                        loss or damage thereto, nor are we liable for any mistakes, 
                        defamation, slander, libel, omissions, falsehoods, obscenity, 
                        pornography or profanity you may encounter. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.11</div>
                    <div className="TncSectionSubContentBody">
                        As a provider of interactive services, we are not liable for any 
                        statements, representations or Content provided by its users in any 
                        public forum, personal home page or other Review Spaces.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.12</div>
                    <div className="TncSectionSubContentBody">
                        Although We have no obligation to screen, edit or monitor any of the content 
                        posted to or distributed through any Review Spaces, We reserve the right and have 
                        absolute discretion, to remove, screen, translate or edit without notice any 
                        Content posted or stored on its Website at any time and for any reason, or to 
                        have such actions performed by third parties on its behalf, and you are solely 
                        responsible for creating backup copies of and replacing any Content you post or 
                        store on this Website at your sole cost and expense.
                    </div>
                </div>
                <div className="TncSectionContent">
                    Any use of the Review Spaces or other portions of this Website in 
                    violation of the foregoing violates the terms of this Agreement and may 
                    result in, among other things, termination or suspension of your rights 
                    to use the Review Spaces and/or this Website.
                </div>
            </div>
        )
    }
}

class Indemnity extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">11. USER REPRESENTATIONS AND WARRANTIES AND INDEMNITY</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">11.1</div>
                    <div className="TncSectionSubContentBody">
                        <div>
                            You represent and warrant that
                        </div>
                        <div className="TncExtraSpace"></div>
                        <div>
                            <div className="TncBulletPoint">
                                a) All registration information that you submit will be true, accurate, current, and complete.
                            </div>
                            <div className="TncBulletPoint">
                                b) You will maintain the accuracy of such information and promptly update such registration 
                                information as necessary.
                            </div>
                            <div className="TncBulletPoint">
                                c) You have the legal capacity, and you agree to comply with these Terms of Use.
                            </div>
                            <div className="TncBulletPoint">
                                d) You are not under the age of 18 or such other legal age as per Applicable Law.
                            </div>
                            <div className="TncBulletPoint">
                                e) You own or otherwise control all of the rights to any of your Content submitted by 
                                you and that all User Content submitted by you is accurate.
                            </div>
                            <div className="TncBulletPoint">
                                f) Usage of such User Content by us and its other Users, partners, and licensees will 
                                not violate this Terms and Conditions, cause injury to any person or entity, or 
                                infringe any third-party rights (including, without limitation, Intellectual Property 
                                Rights and rights of privacy or publicity).
                            </div>
                            <div className="TncBulletPoint">
                                g) You shall use our Platform for lawful purposes only and that you will not violate these 
                                Terms and Conditions and all Applicable Law.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">11.2</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">INDEMNITY</span>
                        <div className="TncExtraSpace"></div>
                        <div>
                            To the maximum extent permitted by Applicable Law, You agree to indemnify, hold harmless, 
                            and defend DaiDish, our affiliates, and our representatives, agents, directors, managers, 
                            officers, employees, and shareholders from and against all claims resulting from:-
                        </div>
                        <div>
                            <div className="TncBulletPoint">
                                a) any User Content submitted by you,
                            </div>
                            <div className="TncBulletPoint">
                                b) your use of our Services, or
                            </div>
                            <div className="TncBulletPoint">
                                c) any damages, costs and expenses, including reasonable attorneys' fees, arising 
                                out of or related to your breach or violation by you of this Terms and Conditions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Limitation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">12. LIMITATION OF LIABILITY</div>
                <div className="TncSectionContent">
                    TO THE POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT 
                    SHALL WE BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM: 
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.1</div>
                    <div className="TncSectionSubContentBody">
                        ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, AND/OR PERSONAL INJURY OR PROPERTY 
                        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.2</div>
                    <div className="TncSectionSubContentBody">
                        ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL 
                        PERSONAL INFORMATION STORED THEREIN, AND/OR
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.3</div>
                    <div className="TncSectionSubContentBody">
                        ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR 
                        SERVERS, AND/OR
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.4</div>
                    <div className="TncSectionSubContentBody">
                        ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE 
                        TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.5</div>
                    <div className="TncSectionSubContentBody">
                        ANY LOSS OF YOUR DATA OR CONTENT FROM THE SERVICES, AND/OR 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.6</div>
                    <div className="TncSectionSubContentBody">
                        ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF 
                        ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, 
                        TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES,
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.7</div>
                    <div className="TncSectionSubContentBody">
                        THE DISCLOSURE OF INFORMATION ACCORDING TO THESE TERMS OR OUR 
                        PRIVACY POLICY, AND/OR
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.8</div>
                    <div className="TncSectionSubContentBody">
                        YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND 
                        CONFIDENTIAL, AND/OR
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.9</div>
                    <div className="TncSectionSubContentBody">
                        LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT 
                        LIMITED TO LOSS OR DAMAGE AS A RESULT OF RELIANCE PLACED BY YOU ON 
                        THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISEMENT OR 
                        FOOD/DISH REVIEW OR FEEDBACK POSTED BY ANY USER OR THIRD PARTY, OR 
                        AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY 
                        ADVERTISER OR SPONSOR WHOSE ADVERTISEMENT APPEARS ON WEBSITE, 
                        AND/OR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND 
                        OUR REASONABLE CONTROL. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.10</div>
                    <div className="TncSectionSubContentBody">
                        LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">12.11</div>
                    <div className="TncSectionSubContentBody">
                        LIABILITIES THAT MAY ARISE FOR ANY LOSSES OR DAMAGES IN CONNECTION WITH THE USE OF DAIDISH 
                        INFORMATION OR FOR ANY INACCURACY, INVALIDITY OR DISCREPANCY IN THE CERTIFICATION OR 
                        NON-COMPLIANCE OF ANY APPLICABLE LAWS BY THE RESTAURANT PARTNER/MERCHANT.
                    </div>
                </div>
                <div className="TncSectionContainer">
                    IN NO EVENT SHALL WE BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, 
                    SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES WHATSOEVER, 
                    HOWEVER, CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING BUT NOT 
                    LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), 
                    ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, 
                    COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER 
                    INTANGIBLE LOSS.
                </div>
                <div className="TncSectionContainer">
                    YOU AGREE THAT THE LIABILITY OF DAIDISH, ITS DIRECTORS, AGENTS, EMPLOYEES AND 
                    THIRD PARTIES ASSOCIATED WITH THE WEBSITE IS LIMITED SOLELY TO DIRECT DAMAGES 
                    AND IN NO EVENT SHALL IT EXCEED, THE AGGREGATE OF RS.10000.
                </div>
            </div>
        )
    }
}

class ThirdPartyWebsites extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">13. THIRD-PARTY WEBSITES</div>
                <div className="TncSectionContainer">
                    The Site may contain (or you may be sent via a HYPERLINK ON the Site) 
                    links to other websites (<span className="TncBold">"Third-Party Websites"</span>) 
                    as well as articles, photographs, text, graphics, pictures, designs, music, 
                    sound, video, information, applications, software, and other content or items 
                    belonging to or originating from third parties 
                    (<span>"Third-Party Content"</span>).
                </div>
                <div className="TncSectionContainer">
                    Such Third-Party Websites and Third-Party Content are not investigated, 
                    monitored, or checked for accuracy, appropriateness, or completeness by 
                    us, and we are not responsible for any Third-Party Websites accessed 
                    through the Site or any Third-Party Content posted on, available 
                    through, or installed from the Site, including the content, accuracy, 
                    offensiveness, opinions, reliability, privacy practices, or other 
                    policies of or contained in the Third-Party Websites or the 
                    Third-Party Content. 
                </div>
                <div className="TncSectionContainer">
                    You should take precautions when downloading files from Third-Party Websites to protect 
                    your computer from viruses and other destructive programs. If you decide to access 
                    linked Third-Party Websites, you do so at your own risk.
                </div>
                <div className="TncSectionContainer">
                    Inclusion of, linking to, or permitting the use or installation of any 
                    Third-Party Websites or any Third-Party Content does not imply approval 
                    or endorsement thereof by us. If you decide to leave the Site and access 
                    the Third-Party Websites or to use or install any Third-Party Content, 
                    you do so at your own risk, and you should be aware that these Terms of 
                    Use no longer govern.
                </div>
                <div className="TncSectionContainer">
                    You should review the applicable terms and policies, including terms of 
                    use, privacy policy, disclaimers and other data gathering practices, of 
                    any website to which you navigate from the Site or relating to any 
                    applications you use or install from the Site. Any purchases you make 
                    through Third-Party Websites will be through other websites and from 
                    other companies, and we take no responsibility whatsoever for such 
                    purchases which are exclusively between you and the applicable third 
                    party. You agree and acknowledge that we do not endorse the products 
                    or services offered on Third-Party Websites and you shall hold us 
                    harmless from any harm caused by your purchase of such products or services. 
                    Additionally, you shall hold us harmless from any losses sustained by you or 
                    harm caused to you relating to or resulting in any way from any Third-Party 
                    Content or any contact with Third-Party Websites.
                </div>
            </div>
        )
    }
}

class Termination extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">14. TERM AND TERMINATION</div>
                <div className="TncSectionContainer">
                    These Terms of Use shall remain in full force and effect while you 
                    use the Site. 
                </div>
                <div className="TncSectionContainer">
                    Either party may terminate the use of the Platform at any time, with or without 
                    notice, for no reason or any reason. If you wish to terminate your account on 
                    your mobile device, you may do so by removing the application from your device 
                    and following the instructions on the application or through the Platform. 
                </div>
                <div className="TncSectionContainer">
                    Please email <a href="mailto:support@daidish.com">support@daidish.com</a> for any 
                    termination inquiries/questions. On termination, you lose the right to access or use the Platform.
                </div>
                <div className="TncSectionContainer">
                    WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE 
                    THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, 
                    DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP 
                    ADDRESSES), TO ANY PERSON FOR ANY REASON OR NO REASON, INCLUDING 
                    WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR 
                    COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR 
                    REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE APP OR 
                    DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED 
                    AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. 
                </div>
                <div className="TncSectionContainer">
                    Any Content uploaded by you, shall be subject to Applicable Law and may be disabled, or 
                    and may be subject to investigation under Applicable law. Further, if you are found to 
                    be in non-compliance with the Applicable Law and these Terms, we have the right to 
                    immediately block your access and usage of our Platform and we will have the right 
                    to remove any non-compliant content and or comment forthwith, uploaded by you and shall 
                    further have the right to take appropriate recourse to such remedies as would be 
                    available under Applicable Law.
                </div>
                <div className="TncSectionContainer">
                    If we terminate or suspend your account for any reason, you are 
                    prohibited from registering and creating a new account under your name, 
                    a fake or borrowed name, or the name of any third party, even if you may 
                    be acting on behalf of the third party. In addition to terminating or 
                    suspending your account, we reserve the right to take appropriate 
                    legal action, including without limitation pursuing civil, criminal, 
                    and injunctive redressal. 
                </div>
            </div>
        )
    }
}

class Interruptions extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">15. INTERRUPTIONS</div>
                <div className="TncSectionContainer">
                    We cannot guarantee the Site will be available at all times. We may 
                    experience hardware, software, or other problems or need to perform 
                    maintenance related to the Site, resulting in interruptions, delays, 
                    or errors. We reserve the right to change, revise, update, suspend, 
                    discontinue, or otherwise modify the Site at any time or for any reason 
                    without notice to you. You agree that we have no liability whatsoever 
                    for any loss, damage, or inconvenience caused by your inability to 
                    access or use the Site during any downtime or discontinuance of the 
                    Site. Nothing in these Terms of Use will be construed to obligate us 
                    to maintain and support the Site or to supply any corrections, updates, 
                    or releases in connection therewith.
                </div>
            </div>
        )
    }
}

class NoWarranties extends Component{
    render(){
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">16. NO WARRANTIES</div>
                <div className="TncSectionContainer TncAgreement">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT ALL INFORMATION, CONTENT, MATERIALS OR YOUR USE OF 
                    THE SITE IS AT YOUR SOLE RISK AND THAT THE SITE IS PROVIDED ON AN "AS IS" BASIS WITHOUT ANY 
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. DAIDISH, ITS DIRECTORS, AGENTS AND 
                    EMPLOYEES, AND THIRD PARTIES ASSOCIATED WITH THE SITE, TO THE FULLEST EXTENT PERMITTED 
                    BY LAW, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES 
                    OF TITLE, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, NON-INFRINGEMENT OF 
                    PROPRIETARY RIGHTS. DAIDISH, ITS DIRECTORS, AGENTS AND EMPLOYEES, AND THIRD PARTIES 
                    ASSOCIATED WITH THE SITE, DO NOT WARRANT THAT THE SITE OR SITE CONTENT ARE ERROR OR VIRUS 
                    FREE, WILL OPERATE WITHOUT INTERRUPTION, OR ARE COMPATIBLE WITH ALL EQUIPMENT AND SOFTWARE 
                    CONFIGURATIONS. DAIDISH, ITS DIRECTORS, AGENTS AND EMPLOYEES, AND THIRD PARTIES ASSOCIATED WITH THE 
                    SITE, DO NOT MAKE ANY WARRANTIES REGARDING THE ACCURACY, RELIABILITY OR QUALITY OF THE CONTENT 
                    AND NO USER SHALL HAVE ANY REMEDY AGAINST DAIDISH WITH RESPECT TO THE SAME.
                </div>
            </div>
        )
    }
}

class Agreement extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">17. ENTIRE AGREEMENT AND WAIVER </div>
                <div className="TncSectionContainer">
                    These Terms, together with the Privacy Policy shall constitute the entire agreement 
                    between you and us concerning our Services. No failure or delay by us in exercising 
                    any right, power or privilege under these terms shall operate as a waiver of such 
                    right or acceptance of any variation of the terms and nor shall any single or partial 
                    exercise by either party of any right, power or privilege preclude any further exercise 
                    of that right or the exercise of any other right, power or privilege.
                </div>
            </div>
        )
    }
}

class Severability extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">18. SEVERABILITY </div>
                <div className="TncSectionContainer">
                    If any provision of these Terms is deemed unlawful, invalid, or unenforceable by a judicial 
                    court for any reason, then that provision shall be deemed severed from these Terms, and the 
                    remainder of the Terms shall continue in full force and effect.
                </div>
            </div>
        )
    }
}

class Relationship extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">19. RELATIONSHIP </div>
                <div className="TncSectionContainer">
                    None of the provisions of these Terms shall be deemed to constitute a relationship, partnership 
                    or agency between you and us and you shall have no authority to bind us in any form or manner, whatsoever.
                </div>
            </div>
        )
    }
}

class GoverningLaw extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">20. GOVERNING LAW & DISPUTE RESOLUTION</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">20.1</div>
                    <div className="TncSectionSubContentBody">
                        These Terms and Conditions shall be governed by the laws of India.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">20.2</div>
                    <div className="TncSectionSubContentBody">
                        In case of any claim, dispute, matter or controversy arising out of 
                        or concerning these terms between the parties, the parties shall 
                        spend at least 15 days to try and amicably resolve such dispute, 
                        through mutual discussions.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">20.3</div>
                    <div className="TncSectionSubContentBody">
                        If the Parties fail to resolve such a claim, dispute, matter or 
                        controversy through mutual discussions, then they shall refer such 
                        claim, dispute, matter or controversy to a binding expedited 
                        Arbitration, to be headed by a sole Arbitrator who shall be solely 
                        appointed by DaiDish. The Arbitration proceedings (including any 
                        documents to be submitted therein) shall be conducted in the 
                        English language and the seat of Arbitration shall be in 
                        Bangalore, Karnataka. The Arbitration proceedings shall be governed 
                        by the provisions of the Arbitration & Conciliation Act, 1996, and 
                        its applicable rules, as amended from time to time.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">20.4</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">The Courts of Bangalore, Karnataka Shall 
                        have exclusive jurisdiction for any dispute if remains unresolved 
                        after arbitration or for any challenge to the Arbitration clause 
                        mentioned above.</span>
                    </div>
                </div>
                <div className="TncSectionContainer">
                    NOTWITHSTANDING ANYTHING CONTAINED HEREIN, YOU MUST COMMENCE ANY 
                    LEGAL ACTION AGAINST US WITHIN ONE (1) YEAR AFTER THE ALLEGED HARM 
                    INITIALLY OCCURS. FAILURE TO COMMENCE THE ACTION WITHIN THAT PERIOD 
                    SHALL FOREVER BAR ANY CLAIMS OR CAUSES OF ACTION REGARDING THE SAME 
                    FACTS OR OCCURRENCE, NOTWITHSTANDING ANY STATUTE OF LIMITATIONS OR 
                    OTHER LAW TO THE CONTRARY. WITHIN THIS PERIOD, ANY FAILURE BY US TO 
                    ENFORCE OR EXERCISE ANY PROVISION OF THESE TERMS OR ANY RELATED RIGHT 
                    SHALL NOT CONSTITUTE A WAIVER OF THAT RIGHT OR PROVISION.
                </div>
            </div>
        )
    }
}

class Notice extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">21. NOTICE OF COPYRIGHT INFRINGEMENT</div>
                <div className="TncSectionContainer">
                    We shall not be liable for any infringement of copyright arising out 
                    of materials posted on or transmitted through our Platform, or items 
                    advertised on our Platform, by end-users or any other third parties. We 
                    respect the intellectual property rights of others and require those 
                    that use the Services to do the same. We may, in appropriate 
                    circumstances and at our discretion, remove or disable access to 
                    material on the Services that infringes upon the copyright and other 
                    intellectual property rights of others. We also may, at our discretion, 
                    remove or disable links or references to an online location that 
                    contains infringing material or infringing activity. If any Customers 
                    of the Services repeatedly infringe on others' copyrights, we may in 
                    our sole discretion terminate those individuals' rights to use 
                    the Services. If you believe that your copyright has been or is 
                    being infringed upon by material found in the Services, you are 
                    required to follow the below procedure to file a notification:
                    <div className="TncBulletPoint">
                        a) Identify in writing the copyrighted material that you claim has been 
                        infringed upon;
                    </div>
                    <div className="TncBulletPoint">
                        b) Provide your current and up-to-date contact information, including 
                        your address, telephone number, and e-mail address (if available);
                    </div>
                    <div className="TncBulletPoint">
                        c) Provide your physical and/or electronic signature;
                    </div>
                    <div className="TncBulletPoint">
                        d) Send us a written communication 
                        to <a href="mailto:info@daidish.com">info@daidish.com</a>; 
                    </div>
                    <div className="TncBulletPoint">
                        e) You may be subject to liability if you knowingly make any 
                        misrepresentations on a take-down notice.
                    </div>
                </div>
            </div>
        )
    }
}

class Refund extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">22. PAYMENTS REFUND AND ORDER CANCELLATION</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">22.1. Payment</div>
                </div>
                <div className="TncSectionContent">
                    Payment of food Orders available on the Site can be made by credit cards, debit cards, net 
                    banking, wallets and cash on delivery.
                </div>
                <div className="TncSectionContent">
                    While availing any of the payment method/s offered by us, we are not responsible or take no 
                    liability of whatsoever nature in respect of any loss or damage arising directly or indirectly 
                    to you including but not limited to the following: 
                    <div className="TncBulletPoint">
                        a) lack of authorization for any transaction/s;
                    </div>
                    <div className="TncBulletPoint">
                        b) or exceeding the present limit mutually agreed by you and between your bank
                    </div>
                    <div className="TncBulletPoint">
                        c) or any payment issues arising out of the transaction;
                    </div>
                    <div className="TncBulletPoint">
                        d) or decline of transaction for any other reason/s; or 
                    </div>
                    <div className="TncBulletPoint">
                        e) stolen card 
                    </div>
                </div>
                <div className="TncSectionContent">
                    All payments made against the purchase of online food Orders on our Site by you shall be as 
                    against the price displayed on our Site and shall be in terms and conditions of the third-party 
                    online payment gateway services as adopted and applicable to your transaction as approved 
                    by us. You are required to make full payment for the purchase of your Orders. In case you 
                    do not make full payment for your purchase, your food Order will not be processed. Please 
                    review such online payment gateway terms and conditions and privacy policy before using the 
                    Platform services. Once the Order is confirmed you will receive a confirmation email 
                    summarizing the confirmed booking.
                </div>
                <div className="TncSectionContent">
                    The final tax bill will be issued by the restaurant and PDP (if registered for tax purposes) to 
                    you along with the Order and DaiDish is merely collecting the payment on behalf of such 
                    restaurant and PDP. All applicable taxes and levies, the rates thereof and the manner of 
                    applicability of such taxes on the bill are being charged and determined by the restaurant 
                    and PDP. DaiDish holds no responsibility for the legal correctness/validity of the levy of 
                    such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with 
                    the restaurant and the PDP.
                </div>
                <div className="TncSectionContent">
                    The prices reflected on the Platform, including packaging or handling charges, are determined 
                    solely by the listed restaurants and are listed based on the listed restaurant's information. Very 
                    rarely, prices may change at the time of placing Order due to the listed restaurant changing the 
                    menu price without due intimation and such change of price are at the sole discretion of the listed 
                    restaurant attributing to various factors beyond control.
                </div>
                <div className="TncSectionContent">
                    All prices of the Orders listed on the Website are exclusive of GST. GST shall be additionally 
                    applicable to such prices.
                </div>
                <div className="TncSectionContent">
                    We reserve the right to charge a subscription and/or membership and/or a convenience fee from You 
                    by giving reasonable prior notice.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">22.2. Refund and Order Cancellation</div>
                </div>
                <div className="TncSectionContainer">
                    All refunds are subject to the refund policies of the restaurants from which you order. If 
                    you contact us seeking a refund, we cannot and will not process any refund until we receive 
                    the approval from the applicable restaurant. We will use reasonable efforts to request and 
                    obtain refunds when appropriate.
                </div>
                <div className="TncSectionContainer">
                    We reserve the right to cancel or modify an Order where it appears that a User has engaged 
                    in fraudulent or inappropriate activity or under other circumstances where it appears that 
                    the Order contains or resulted from a mistake or error. In addition, we reserve the right to 
                    report any fraudulent or inappropriate conduct to appropriate authorities at our discretion.
                </div>
                <div className="TncSectionContainer">
                    For more information on our refund and Order cancellation Policy, please refer to Our refund 
                    and cancellation policy provided below:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">Refund and Cancellation Policy</div>
                </div>
                <div className="TncSectionContainer">
                    <div className="TncBulletPoint">
                        a) As a general rule, all Orders placed on the Platform and DaiDish Services are treated as confirmed.
                    </div>
                    <div className="TncBulletPoint">
                        b) DaiDish does not offer any refunds against Orders already purchased through the Platform unless an 
                        error that is directly attributable to DaiDish has occurred during the purchase of such Orders.
                    </div>
                    <div className="TncBulletPoint">
                        c) You agree and acknowledge that once an Order is placed you cannot cancel the Order. However, 
                        if the restaurant partner cancels / rejects the Order, full refund will be provided to you 
                        within 7 days of the cancellation, provided there are justified reasons to refund the amount paid.
                    </div>
                    <div className="TncBulletPoint">
                        d) We constantly strive to provide you with accurate information on the Platform. However, in 
                        the event of an error, we may, in our sole discretion, contact you with further instructions.
                    </div>
                    <div className="TncBulletPoint">
                        e) If you use the Platform, you do the same at your own risk.
                    </div>
                    <div className="TncBulletPoint">
                        f) You agree that the Orders shall be provided through the Platform only during the working 
                        hours of the relevant restaurant and PDPs.
                    </div>
                    <div className="TncBulletPoint">
                        g) There may be cases where DaiDish is either unable to accept your Order or cancels the Order, 
                        due to reasons including without limitation, technical errors, unavailability of the item(s) 
                        ordered, or any other reason attributable to DaiDish, the restaurant partner or PDP. In such 
                        cases, DaiDish shall not charge a cancellation charge from you. If the Order is cancelled after 
                        payment has been charged and you are eligible for a refund of the Order value or any part 
                        thereof, the said amount will be reversed to you.
                    </div>
                    <div className="TncBulletPoint">
                        h) No replacement / refund / or any other resolution will be provided without the 
                        restaurant partner's permission.
                    </div>
                    <div className="TncBulletPoint">
                        i) The details of the menu and price list available on the Platform with respect to restaurant 
                        services, goods or any other services are based on the information provided by the restaurant 
                        partner and DaiDish shall not be responsible for any change or cancellation or unavailability.
                    </div>
                    <div className="TncBulletPoint">
                        j) You and the restaurant partners agree and acknowledge that DaiDish is not responsible for 
                        any liability arising out of delivery services provided by PDP to you.
                    </div>
                    <div className="TncBulletPoint">
                        k) DaiDish shall not be liable for any acts or omissions on part of the restaurant 
                        partner/ including improper service, wrong delivery of Order or Order mismatch, quality, 
                        incorrect pricing, deficient quantity, time taken to fulfil the Order, etc.
                    </div>
                    <div className="TncBulletPoint">
                        l) You may not be able to avail DaiDish services if their delivery location is outside DaiDish 
                        current scope of Service. DaiDish will you informed of the same at the time of confirming your 
                        Order booking. You understand that delivery time quoted at the time of confirming the Order is 
                        an approximate estimate and may vary based on the information obtained from PDPs and the 
                        restaurant partner and DaiDish will not be responsible for any delay in the delivery of an Order. 
                    </div>
                    <div className="TncBulletPoint">
                        m) Your Order will be only delivered to the address designated by you at the time of placing the 
                        Order on the Platform. Your Order will be cancelled in the event of any change of the address as 
                        informed by the PDP and you shall not be entitled to any refund for the same. Delivery of Orders 
                        in the event of change of the delivery location shall be subject to acceptance by the PDP or 
                        sole discretion of DaiDish.
                    </div>
                    <div className="TncBulletPoint">
                        n) You may be required to submit proof in case of any complaints related to the Order. 
                    </div>
                    <div className="TncBulletPoint">
                        o) You undertake to provide adequate directions, information and authorisations to accept delivery. 
                        In the event of no delivery due to any act or omission attributable to you, the goods or services 
                        shall be deemed to have been delivered to you and all risk and responsibility in relation thereto 
                        shall pass to you without being entitled to any refund.
                    </div>
                    <div className="TncBulletPoint">
                        p) You understand that DaDish's (including restaurant partner and PDP's) liability ends once the 
                        Order has been delivered, except where the product liability of the restaurant partner subsists. 
                    </div>
                </div>
            </div>
        )
    }
}

class ContactUs extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">23. CONTACT US</div>
                <div className="TncSectionContainer">
                    If you have any questions concerning the Terms and Conditions you may contact 
                    us on <a href="mailto:support@daidish.com">support@daidish.com</a>.
                </div>
            </div>
        )
    }
}

class PrivacyPolicy extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">24. PRIVACY POLICY</div>
                <div className="TncSectionContainer">
                    To see what data we collect and how we use or store or share such data, please refer to the detailed privacy 
                    policy <a target="_blank" rel="noopener noreferrer" href="/privacypolicy">here</a>.
                </div>
            </div>
        )
    }
}

class Consent extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">25. CONSENT FOR RECEIPT OF PHONE CALLS, SMSS AND/OR E-MAILS</div>
                <div className="TncSectionContainer">
                    Your registration on the Platform shall be deemed to be your consent to be contacted by DaiDish,
                    <div className="TncBulletPoint">
                        a) on the mobile number shared by you
                    </div>
                    <div className="TncBulletPoint">
                        b) by way of SMS or email notifications or messages in any other electronic form.
                    </div> 
                </div>
            </div>
        )
    }
}

class Survival extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">26. SURVIVAL</div>
                <div className="TncSectionContainer">
                    The obligations under the provisions of section 12, 14, 16 and 20 will survive any expiration 
                    or termination of this Terms and Conditions.
                </div>
            </div>
        )
    }
}

export default withRouter(Content);