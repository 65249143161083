import React, { Component } from 'react';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

class OrderCard extends Component {

    constructor(props) {
      super(props);
      this.handleChangeL = this.handleChangeL.bind(this);
      this.handleChangeR = this.handleChangeR.bind(this);
      this.OrderList = this.OrderList.bind(this);
      this.OrderList1 = this.OrderList1.bind(this);
      this.AddedItems = this.AddedItems.bind(this);
      this.CouponSection = this.CouponSection.bind(this);
      this.RestoDiscount = this.RestoDiscount.bind(this);
      this.OrderSummary = this.OrderSummary.bind(this);
      this.updateCartValues = this.updateCartValues.bind(this);
      this.state = { 
        isMobileScreen: false,
        sumTotal: 0,
        couponDiscPerc: 5,
        couponDiscAmt: 0,
        restoDiscPerc: 20,
        restoDiscAmt: 0,
        deliveryCharges: 25,
        grandTotal: 0,
        itemsInCart: 0,
        restoName: "",
        cartList: [],
        cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
        stateL: {backgroundColor: '#EA5B2F', opacity : '1'},
        stateR: {backgroundColor: '#999999', opacity : '0.5'},
      };
    } 
  
    componentDidMount(){
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.updateCartValues();
      this.setState({
        restoName : this.state.cartDetails.RestoName,
      });
    }

    componentDidUpdate() {
        if (this.props.cartChanged === true){
            this.updateCartValues();
            this.props.updateCartChangedValue(false);
        }
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }
    
    updateCartValues(keyItem, choice){
        let cartDetails = localStorage.getItem('CartDetails');
        var obj = JSON.parse(cartDetails);
        let itemToUpdate = 0;
        let counter = 0;
        let cartList = [];
        let sumTotal = 0;

        for (let i=0; i<obj.Cart_Items.length; i++) {
            if(obj.Cart_Items[i].DishID === keyItem){
                itemToUpdate = counter;
            }
            counter = counter + 1;
        }
        
        if(choice === "Remove"){
            if(Number(obj.Cart_Items[itemToUpdate].DishQuantity) === 1){
                obj.Cart_Items.splice(itemToUpdate,1);
            }
            else{
                obj.Cart_Items[itemToUpdate].DishQuantity = Number(obj.Cart_Items[itemToUpdate].DishQuantity) - Number(1);
            }
        }
        else if(choice === "Add"){
            obj.Cart_Items[itemToUpdate].DishQuantity = Number(obj.Cart_Items[itemToUpdate].DishQuantity) + Number(1);
        }
        else if(choice === "RemoveItem"){
            obj.Cart_Items.splice(itemToUpdate,1);
        }
        
        for(let k = 0; k < obj.Cart_Items.length; k++){
            cartList.push(obj.Cart_Items[k]);
            sumTotal = sumTotal + (obj.Cart_Items[k].DishCost * obj.Cart_Items[k].DishQuantity);
        }

        let restoDiscAmt = ((this.state.restoDiscPerc/100) * sumTotal).toFixed(2);
        let couponDiscAmt = ((this.state.couponDiscPerc/100) * sumTotal).toFixed(2);
        let grandTotal = ((Number(Number(sumTotal) + Number(this.state.deliveryCharges)) - Number(Number(((this.state.couponDiscPerc/100) * sumTotal).toFixed(2)) + Number(((this.state.restoDiscPerc/100) * sumTotal).toFixed(2))))).toFixed(2);
        
        cartDetails = JSON.stringify(obj);
        localStorage.setItem('CartDetails', cartDetails); 
        this.setState({
            cartList: cartList,
            sumTotal: sumTotal,
            restoDiscAmt: restoDiscAmt,
            couponDiscAmt: couponDiscAmt,
            grandTotal: grandTotal,
            cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
            itemsInCart : obj.Cart_Items.length,
        });
        var token;
        var headers;
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
            cartDetailsAPI = JSON.parse(cartDetailsAPI);
            if (choice === "Remove"){
                let url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
                let req = {
                    'cart_id': cartDetailsAPI.id,
                    'dish_id': obj.Cart_Items[itemToUpdate].DishID,
                    'quantity': 1
                };
                token = localStorage.getItem("auth_token");
                headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                        localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                    }
                }).catch((error) => this.setState({}));
            } else if (choice === "Add"){
                let url = CONSTANTS.hostURL + "api/main_app/cart/add/";
                let req = {
                    'cart_id': cartDetailsAPI.id,
                    'dish_id': obj.Cart_Items[itemToUpdate].DishID,
                    'quantity': 1
                };
                token = localStorage.getItem("auth_token");
                headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                        localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                    }
                }).catch((error) => this.setState({}));
            }
        }
    }

    handleChangeL(){
        this.setState({
            stateL: {backgroundColor: '#EA5B2F', opacity : '1', transition: 'opacity .25s ease-in'},
            stateR: {backgroundColor: '#999999', opacity : '0.5', transition: 'opacity .25s ease-out'},
        });
    };

    handleChangeR(){
        this.setState({
            stateL: {backgroundColor: '#999999', opacity : '0.5', transition: 'opacity .25s ease-out'},
            stateR: {backgroundColor: '#EA5B2F', opacity : '1', transition: 'opacity .25s ease-in'},
        });
    };

    OrderList(){
        return(
            <div>
                <Grid container spacing={2} style={{backgroundColor:"#F8F8F8", borderRadius:'5px'}}>
                    <Grid item xs={6} className="cart-header">
                        Cart
                    </Grid>
                    {/* <Grid id="cartFinalPrice" item xs={6} >
                        <span>₹ {this.state.grandTotal}</span>
                    </Grid> */}
                    <Grid item xs={12} style={{marginTop:"-20px"}}>
                        <span style={{fontSize:"13px"}}>From <span style={{color:"#EA5B2F"}}>{this.state.restoName}</span></span><br/>
                        <span className="items-in-cart">{Number(this.state.itemsInCart) > 1 ? this.state.itemsInCart+ " Items" : this.state.itemsInCart+ " Item"} </span>
                    </Grid>
                </Grid>
            </div>
        );
    }
    
    OrderList1(){
        return(
            <div>
                {this.AddedItems()}
            </div>
        );
    }

    AddedItems(){
        const listItems = this.state.cartList.map((keyItem, index) =>
            <div key={"CartItem_"+this.state.cartList.indexOf(keyItem)} style={{marginTop: index>0 ? "2px" : "0px"}}>
                <div className="cart-details">
                    <Grid container spacing={2} >
                        <Grid item xs={2} style={{textAlign:"center"}}>
                            <div className="cart-Item-Controls">
                                <RemoveIcon style={{ fontSize: 13.5 }} className="remove-quantity" onClick={() => this.updateCartValues(keyItem.DishID, "Remove")}/>
                                <span className="item-Qty">{keyItem.DishQuantity}</span>
                                <AddCircleIcon style={{ fontSize: 16 }} className="add-quantity" onClick={() => this.updateCartValues(keyItem.DishID, "Add")}/>
                            </div>
                        </Grid>
                        <Grid item xs={7}>
                            <div className="cart-item-names">
                                {keyItem.DishName}
                            </div>
                            <div style={{fontSize:"12px"}}>₹ <span className="cart-item-price">{keyItem.DishCost * keyItem.DishQuantity}</span></div>
                        </Grid>
                        {typeof keyItem.DishImage !== 'undefined' ? 
                        <Grid item xs={3}>
                            <div style={{position:"relative"}}>
                                {keyItem.DishIsVeg === true ? <FiberManualRecordIcon style={{ fontSize: 13, top: "5px", position:"absolute"}} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13, top: "5px", position:"absolute"}} className="nonVegTag"/>}
                                <img className="cart-food-image" src={keyItem.DishImage} alt={keyItem.DishName} />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={3}>
                            <div style={{position:"relative"}}>
                                {keyItem.DishIsVeg === true ? <FiberManualRecordIcon style={{ fontSize: 13, top: "2px", position:"absolute"}} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13, top: "2px", position:"absolute"}} className="nonVegTag"/>}
                            </div>
                        </Grid>
                        }
                    </Grid>   
                </div>
            </div>
        );
        return(
            <>
                {listItems}
            </>
        );
    }

    CouponSection(){
        return(
            <Grid container spacing={2} style={{backgroundColor:"#F8F8F8", paddingTop:"22px"}}>
                <Grid item xs={12}>
                    <div className="cart-details">
                        <Grid item xs={7}>
                            <ScheduleIcon fontSize="small" className="voucher-coupon-style"/>
                            <span className="coupon-name">Voucher Code Name</span>
                            <div className="coupon-discount-details-left">{this.state.couponDiscPerc}% off upto Rs 80</div>
                        </Grid>
                        <Grid item xs={5} style={{textAlign:'right'}}>
                            <span className="coupon-discount-details-right" style={{color:'rgb(79, 147, 236)'}}>-Rs {this.state.couponDiscAmt}</span>
                            <div className="coupon-discount-details-right" style={{color:'#EA5B2F'}}>Change Offer</div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }

    RestoDiscount(){
        return(
            <Grid container spacing={2} style={{backgroundColor:"#F8F8F8", paddingTop:"22px"}}>
                <Grid item xs={12}>
                    <div className="cart-details">
                        <Grid item xs={8}>
                            <FastfoodIcon fontSize="small" className="resto-coupon-style"/>
                            <span className="coupon-name" style={{color:"rgb(248, 0, 165)"}}>Resto Offer {this.state.restoDiscPerc}%</span>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'right'}}>
                            <span className="coupon-discount-details-right" style={{color:"rgb(248, 0, 165)"}}>-Rs {this.state.restoDiscAmt}</span><br/>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }

    OrderSummary(){
        return(
            <div style={{paddingTop:"15px", position:"absolute", bottom:"10px", width:"94.2%"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="dlvBtnGroup">
                        <span onClick={this.handleChangeL} style={this.state.stateL} className="deliveryButtonsL"><DirectionsBikeIcon style={{ fontSize: 16 }} className="delIcon"/>Delivery</span>
                        <span onClick={this.handleChangeR} style={this.state.stateR} className="deliveryButtonsR"><LocalMallIcon style={{ fontSize: 16 }} className="delIcon"/>Pickup</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <span className="summary-header">SUBTOTAL</span><br/>
                    </Grid>
                    <Grid item xs={7} style={{paddingTop:'0%'}}>
                        <div className="summary-details-left">Total Price</div>
                        <div className="summary-details-left">Delivery charges for 2.35 Kms</div>
                        <div className="summary-details-left">Restaurant Discount</div>
                    </Grid>
                    <Grid item xs={5} style={{textAlign:'right', paddingTop:'0%'}}>
                        <div className="summary-details-right">Rs {this.state.sumTotal}</div>
                        <div className="summary-details-right">Rs {this.state.deliveryCharges}</div>
                        <div className="summary-details-right">- Rs {this.state.couponDiscAmt}</div>
                    </Grid> */}
                    <Grid item xs={9} style={{paddingTop:'0%', fontStyle:"bold"}}>
                        <span className="summary-header">SUBTOTAL</span>
                        <div style={{color:"blue", fontStyle:"italic"}} className="summary-details-left">Extra charges may apply for Resto delivery</div>
                    </Grid>
                    <Grid item xs={3} style={{textAlign:'right', paddingTop:'0%', fontStyle:"bold"}}>
                        <span className="summary-header">Rs {this.state.sumTotal}</span>
                    </Grid>
                    <Grid item xs={12} className="cart-buttons" onClick={this.props.showDlvCard}>
                        <span className="cart-buttons-text" >CHECKOUT</span><ArrowRightAltIcon/>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render(){
        return(
            <div style={{height:"100%"}}>
                {/* {this.OrderList()} */}
                {this.OrderList1()}
                {/* {this.CouponSection()} */}
                {/* {this.RestoDiscount()} */}
                {/* {this.OrderSummary()} */}
            </div>
        );
    }
}

export default OrderCard;