import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import home_burger from '../../assets/images/landing_page_illustration.webp';
import login_home from '../../assets/images/login_home.svg';
import '../../assets/styles/HomeNew.css';
import logo from '../../assets/images/logo_black.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { withRouter } from 'react-router';
import Geocode from "react-geocode";
import { Link } from 'react-router-dom';
import AuthenticationForm from '../../components/Home/AuthenticationNew';


Geocode.setApiKey('AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4');
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleAuthModalHandler = this.toggleAuthModalHandler.bind(this);
        this.setActiveFormHandler = this.setActiveFormHandler.bind(this);
    }


    toggleAuthModalHandler(){
        this.props.toggleAuthModal();
    }

    setActiveFormHandler(type){
        this.props.setActiveForm(type);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        {this.props.authModalOpen ? null : <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Logo/>
                            <Authentication
                                toggleAuthModal={this.props.toggleAuthModal}
                                setActiveForm={this.props.setActiveForm}
                                authModalOpen={this.props.authModalOpen}
                            />
                        </div>}
                        {this.props.authModalOpen ? null : <HeaderDescription
                            authModalOpen={this.props.authModalOpen}
                            history={this.props.history}
                        />}
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                            <BurgerImage
                                authModalOpen={this.props.authModalOpen}
                                activeForm={this.props.activeForm}
                                toggleAuthModal={this.toggleAuthModalHandler}
                                setActiveForm={this.setActiveFormHandler}
                            />
                        </Grid>
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        {this.props.authModalOpen ? null : <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between"}}>
                            <Logo/>
                            <Authentication
                                toggleAuthModal={this.toggleAuthModalHandler}
                                setActiveForm={this.setActiveFormHandler}
                                authModalOpen={this.props.authModalOpen}
                            />
                        </div>}
                        {this.props.authModalOpen ? null : <HeaderDescription
                            authModalOpen={this.props.authModalOpen}
                            history={this.props.history}
                        />}
                        <BurgerImage
                            authModalOpen={this.props.authModalOpen}
                            activeForm={this.props.activeForm}
                            toggleAuthModal={this.toggleAuthModalHandler}
                            setActiveForm={this.setActiveFormHandler}
                        />
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "#FFFBE8"};
    } else {
        headerStyle = {background: "#FFFBE8"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={48} height={48}/>  
                </div>
            )
        } else {
            return (
                <div style={{marginTop: "0px"}} className="Logo LogoHome">
                    <img style={{zIndex: '999999999'}} className="LogoImage" src={logo} alt="logo"/>  
                </div>
            )
        }

    }
}


// Log In and Sign Up options
class Authentication extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false,
            screenWidth: window.innerWidth,
        };
        this.toggleAuthModalHander = this.toggleAuthModalHander.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    toggleAuthModalHander(form) {
        window.scrollTo(0, 0);
        if (!this.props.authModalOpen){
            this.props.toggleAuthModal();
        }
        this.props.setActiveForm(form);
    }

    render() {

        if (this.state.isMobileScreen){
            return (
                <div style={{paddingTop: "15px"}}>
                    <Button onClick={() => this.toggleAuthModalHander("login")} id="LoginButtonNew">
                        Login
                    </Button>
                    <Button onClick={() => this.toggleAuthModalHander("register")} id="SignupButtonNew">
                        Sign Up
                    </Button>
                </div>
            )
        } else {
            return (
                <div style={{paddingTop: "20px", paddingRight: '30px'}}>
                    <Link to={'/aboutus'} style={{ textDecoration: 'none' }}>
                        <Button id="HomeButtonNew">
                            About Us
                        </Button>
                    </Link>
                    <Link to={'/partnerwithus'} style={{ textDecoration: 'none' }}>
                        <Button id="HomeButtonNew">
                            Add a Restaurant
                        </Button>
                    </Link>
                    <Button onClick={() => this.toggleAuthModalHander("login")} id="LoginButtonNew">
                        Login
                    </Button>
                    <Button onClick={() => this.toggleAuthModalHander("register")} id="SignupButtonNew">
                        Sign Up
                    </Button>
                </div>
            )
        }
    }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleAuthModalHandler = this.toggleAuthModalHandler.bind(this);
        this.setActiveFormHandler = this.setActiveFormHandler.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    toggleAuthModalHandler(){
        this.props.toggleAuthModal();
    }

    setActiveFormHandler(type){
        this.props.setActiveForm(type);
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {}
        }
        if (this.props.authModalOpen){
            return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {this.state.isMobileScreen ? null : <div style={{flex: '1'}}>
                    <img style={{width: '45vw', display: 'inline-block', zIndex: '1', margin: 'auto', transform: 'translateY(3px)'}} src={login_home} alt="burger_img"/>
                </div>}
                <div style={{padding: '40px 0px', transform: this.state.isMobileScreen ? 'unset': 'translateY(-50px)'}}>
                    <AuthenticationForm
                        toggleAuthModal={this.toggleAuthModalHandler}
                        setActiveForm={this.setActiveFormHandler}
                        authModalOpen={this.props.authModalOpen}
                        activeForm={this.props.activeForm}
                    />
                </div>
            </div>
        } else {
            if (this.state.isMobileScreen){
                return (
                    <Grid style={{paddingTop: "0px", height: 'unset'}} className="BurgerContainerNew" container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                        <img style={{width: 'calc(100vw - 20px)', transform: 'translateY(0px)', height: 'unset'}} className="BurgerNew" src={home_burger} alt="burger_img"/>
                    </Grid>
                )
            } else {
                return (
                    <Grid style={burgerContainerStyle} className="BurgerContainerNew" container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                        <img style={burgerStyle} className="BurgerNew" src={home_burger} alt="burger_img"/>
                    </Grid>
                )
            }
        }
    }
}

// Description present in header
class HeaderDescription extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address: '',
            latitude: '',
            longitude: '',
            isMobileScreen: false,
            locationErrorMessage: '',
            clearUserSuggestions: false
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.hitLocationAPI = this.hitLocationAPI.bind(this);
        this.showLocationError = this.showLocationError.bind(this);
    }
    
    handleChange = address => {
        var lenDiff = address.length - this.state.address.length;
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address
        })
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    handleSelect = address => {
        this.setState({
            address: address,
            clearUserSuggestions: true
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        }, this.setLocation)
    }

    setLocation(){
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            localStorage.setItem("user_address", this.state.address);
            this.props.history.push('/home');
        }
    }

    getCurrentLocation(){
        this.setState({
            locationErrorMessage: "Getting current location, please wait..."
        });
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitLocationAPI, this.showLocationError);
        } else {
            this.setState({
                locationErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser"
            });
        }
    }

    hitLocationAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              localStorage.setItem("user_latitude", this.state.latitude);
                localStorage.setItem("user_longitude", this.state.longitude);
                localStorage.setItem("location_present", "yes");
                localStorage.setItem("user_address", address);
                this.props.history.push('/home');
            }
        );
    }

    showLocationError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                locationErrorMessage: "You have blocked Daidish from accessing your location. To use this, change your location settings in your browser",
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
            });
            break;
          case error.TIMEOUT:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay"
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
          default:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
        }
    }

    handleKeyPress = (event) => {}

    showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading){
        if (this.state.address.length > 1 && !this.state.clearUserSuggestions){
            return (
                <div className="autocomplete-dropdown-container PlaceSuggestn"
                    style={{
                        width: this.state.isMobileScreen ? "72vw" : "36vw",
                        marginLeft: "1vw",
                        backgroundColor: "#F0F0F0",
                        zIndex: '100',
                        position: 'absolute',
                        top: '50px',
                        paddingTop: suggestions.length > 0 ? '30px' : '0px',
                        paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        overflow: 'hidden'
                    }}
                >
                {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                    return (<div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        })}
                        key={suggestion.formattedSuggestion.mainText}
                    >
                        <div style={{paddingTop: "2px"}}>
                            <LocationOnIcon fontSize='small'/>
                        </div>
                        <div>
                        <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px'}}>{suggestion.formattedSuggestion.mainText}</div>
                        <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: '12px'}}>{suggestion.formattedSuggestion.secondaryText}</div>
                        </div>
                    </div>)
                })}
            </div>
            )
        } else {
            return null;
        }
    }

    render(){
        if (!this.state.isMobileScreen) {
            return (
                <div>
                    <h1 className='HomeTitleNew'>
                        Curating <span className='HomeTitleRed'>Bangalore's Best Food Delivery Experience</span>
                    </h1>
                    <div style={{zIndex: 1}} className='LocationContainerNew'>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            searchOptions={{
                                componentRestrictions: {country: ['in']}
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{position: 'relative', zIndex: 'inherit'}}>
                                <div style={{ zIndex: 'inherit'}} className="LocationInputContainerNew">
                                    <input
                                    className="LocationInputNew"
                                    {...getInputProps({
                                        placeholder: 'Enter your location here',
                                    })}
                                    onKeyPress={this.handleKeyPress}
                                    style={{
                                        border: "#E6E6E6 1px solid !important",
                                        borderTopLeftRadius: "30px",
                                        borderBottomLeftRadius: "30px",
                                        backgroundColor: "white",
                                        width: "36vw",
                                        padding: "15px 25px",
                                        fontFamily: 'Montserrat-Regular',
                                        fontSize: "14px",
                                        paddingLeft: "1.5vw",
                                        color: "black",
                                        zIndex: 'inherit',
                                        boxShadow: this.state.locationErrorMessage ? "0px 1px 5px #EFE7C4" : '0px 3px 50px #EFE7C4'
                                    }}
                                    />
                                    <div onClick={() => this.getCurrentLocation()} className="LocationIconContainer">
                                        <span className="LocationIcon">
                                            <GpsFixedIcon className="GPSIcon"/>
                                        </span>
                                    </div>
                                </div>
                                {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                            </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    {this.state.locationErrorMessage && <div style={{backgroundColor: "#FFF5F5", border: "1px solid #FFD9D9", padding: "40px 20px 8px 20px", transform: "translateY(-32px)", borderRadius: "12px"}} className='LocationContainerNew'>
                        <div style={{color: "#EB7676", fontFamily: "Poppins-Regular", fontSize: "12px"}}>{this.state.locationErrorMessage}</div>
                    </div>}
                </div> 
            )
        } else {
            return (
                <div>
                    <h1 style={{fontSize: '30px'}} className='HomeTitleNew'>
                        Curating <span className='HomeTitleRed'>Bangalore's Best Food Delivery Experience</span>
                    </h1>
                    <div style={{width: '84vw', zIndex: 1}} className='LocationContainerNew'>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            searchOptions={{
                                componentRestrictions: {country: ['in']}
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{position: 'relative', zIndex: 'inherit'}}>
                                <div style={{ zIndex: 'inherit'}} className="LocationInputContainerNew">
                                    <input
                                    className="LocationInputNew"
                                    {...getInputProps({
                                        placeholder: 'Enter your location here',
                                    })}
                                    onKeyPress={this.handleKeyPress}
                                    style={{
                                        border: "#E6E6E6 1px solid !important",
                                        borderTopLeftRadius: "30px",
                                        borderBottomLeftRadius: "30px",
                                        backgroundColor: "white",
                                        width: "72vw",
                                        padding: "15px 25px 15px 25px",
                                        fontFamily: 'Montserrat-Regular',
                                        fontSize: "14px",
                                        color: "black",
                                        zIndex: 'inherit',
                                        boxShadow: this.state.locationErrorMessage ? "0px 1px 5px #EFE7C4" : '0px 3px 50px #EFE7C4'
                                    }}
                                    />
                                    <div onClick={() => this.getCurrentLocation()} className="LocationIconContainer">
                                        <span style={{transform: 'translate(-10px, 3px)'}} className="LocationIcon">
                                            <GpsFixedIcon style={{transform: 'translateY(3px)'}} className="GPSIcon"/>
                                        </span>
                                    </div>
                                </div>
                                {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                            </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    {this.state.locationErrorMessage && <div style={{backgroundColor: "#FFF5F5", border: "1px solid #FFD9D9", padding: "40px 20px 8px 20px", transform: "translateY(-32px)", borderRadius: "12px", width: "84vw"}} className='LocationContainerNew'>
                        <div style={{color: "#EB7676", fontFamily: "Poppins-Regular", fontSize: "12px"}}>{this.state.locationErrorMessage}</div>
                    </div>}
                </div> 
            )
        }
    }
}

export default withRouter(Header);