import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import SearchIcon from '@material-ui/icons/Search';
import dish from '../../assets/images/dish.svg';
import restaurant_red from '../../assets/images/restaurant_red.png';
import axios from 'axios';
import CONSTANTS from "../../config/constants";

class SearchBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeOption: -1,
            searching: false,
            filteredOptions: [],
            showOptions: false,
            loading: false,
            userInput: '',
            options: ["Hello", "Bello", "Gello"]
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.checkSearch = this.checkSearch.bind(this);
    }

    onChange = (e) => {
    
        var options = [];
        const dishTypeOptions = [];
        const cuisineOptions = [];
        const restaurantOptions = [];

        var userInput = e.currentTarget.value;
        this.setState({
            userInput: e.currentTarget.value
        })
        if (userInput.length > 1){
            this.setState({
                loading: true
            })
            var latitude = localStorage.getItem("user_latitude");
            var longitude = localStorage.getItem("user_longitude");
            var url = CONSTANTS.hostURL + "api/main_app/search/suggest/" + userInput + "?latitude=" + latitude + "&longitude=" + longitude + "&entity=cuisine,restaurant";
            axios.get(url).then(res => {
                if (res.data.status === 1){
                    var results = res.data.data.results;
                    for (var i=0; i<results.length; i++){
                        var searchItem = {}
                        searchItem.id = i;
                        searchItem.name = results[i].name;
                        searchItem.searchType = results[i].type;
                        if (results[i].type === "cuisine"){
                            searchItem.cuisineId = results[i].id;
                            cuisineOptions.push(searchItem);
                        } else if (results[i].type === "restaurant"){
                            searchItem.restaurantId = results[i].id;
                            searchItem.address = "Bangalore";
                            restaurantOptions.push(searchItem);
                        }
                    }
                    options = [...dishTypeOptions, ...cuisineOptions, ...restaurantOptions];
                } 
                this.setState({
                    searching: true,
                    loading: false,
                    showOptions: true,
                    filteredOptions: options,
                    activeOption: -1,
                })
            })
        } else {
            this.setState({
                searching : false,
                loading: false
            })
        }


        // for (var i=0; i<this.props.cuisines.length; i++){
        //     options.push(this.props.cuisines[i]);
        // }
        // for (var j=0; j<this.props.dishes.length; j++){
        //     options.push(this.props.dishes[j]);
        // }
        // for (var k=0; k<this.props.restaurants.length; k++){
        //     options.push(this.props.restaurants[k]);
        // }
        // const userInput = e.currentTarget.value;
    
        // const filteredOptions = options.filter(
        //   (optionName) =>
        //     optionName.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        // );
        // this.setState({
        //   activeOption: -1,
        //   filteredOptions,
        //   showOptions: true,
        //   userInput: e.currentTarget.value
        // });
        // if (e.currentTarget.value !== ""){
        //     this.setState({
        //         searching : true
        //     })
        // } else {
        //     this.setState({
        //         searching : false
        //     })
        // }
    };

    onClick(option) {
        this.setState({
          activeOption: -1,
          filteredOptions: [],
          showOptions: false,
          userInput: option.name,
          searching: false
        });
        if (option.searchType === "restaurant"){
            this.props.history.push('/restaurants/' + option.restaurantId);
            if (!this.props.isDishScreen){
                window.location.reload();
            }
        } else if (option.searchType === "cuisine"){
            if (this.props.isDishScreen){
                this.props.clearSearchFilter();
                this.props.applyCuisineSearch(option.cuisineId);
            } else {
                localStorage.setItem('search_type', 'cuisine');
                localStorage.setItem('search_id', option.cuisineId);
                this.props.history.push('/home/');
            }
        } else {
            if (this.props.isDishScreen){
                this.props.clearFilters();
                this.props.applyDishTypeSearch(option.dishTypeId);
            } else {
                localStorage.setItem('search_type', 'dish_type');
                localStorage.setItem('search_id', option.dishTypeId);
                this.props.history.push('/home/');
            }
        }
    };

    onKeyDown = (e) => {
        const { activeOption, filteredOptions } = this.state;

        if (e.keyCode === 13) {
            if (activeOption !== -1){
                this.setState({
                    activeOption: -1,
                    showOptions: false,
                    userInput: filteredOptions[activeOption],
                    searching: false
                });
            }
        } else if (e.keyCode === 38) {
            if (activeOption <= 0) {
                this.setState({ activeOption: filteredOptions.length - 1 });
            } else {
                this.setState({ activeOption: activeOption - 1 });
            }
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                this.setState({ activeOption: 0 });
                return;
            }
            this.setState({ activeOption: activeOption + 1 });
        }
    };

    clearSearch = (e) => {
        e.stopPropagation();
        this.setState({
            userInput : "",
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        })
        if (this.props.isDishScreen){
            this.props.clearFilters(); 
            this.props.clearSearchFilter();
        }
    }

    checkSearch() {
        this.setState({
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        }) 
    }

    optionImage(type){
        if (type === "dish_type"){
            return (
                <div style={{display: "inline-block", marginRight: "10px"}}>
                    <img style={{height: "12px", transform: "translateY(-18px)"}} src={dish} alt="dish" />
                </div>
            )
        } else if (type === "restaurant"){
            return (
                <div style={{display: "inline-block", marginRight: "10px"}}>
                    <img style={{height: "16px", transform: "translateY(-18px)"}} src={restaurant_red} alt="dish" />
                </div>
            )
        } else {
            return null;
        }
    }

    filterSubTitle(option){
        if(option.searchType === "cuisine"){
            return(
                <div style={{fontFamily: 'Poppins-Regular', fontSize: "12px", color: "#666666"}}>
                    Cuisine
                </div>
            )
        } else if(option.searchType === "restaurant"){
            return(
                <div style={{fontFamily: 'Poppins-Regular', fontSize: "12px", color: "#666666"}}>
                    {option.address}
                </div>
            )
        } else {
            return(
                <div style={{fontFamily: 'Poppins-Regular', fontSize: "12px", color: "#666666"}}>
                    Dish
                </div>
            )
        }
    }

    render() {
        let optionList;
        let searchBarColor;
        let containerShadow;
        let crossIcon;
        let searchIconStyle;
        if (this.state.loading){
            optionList = (
                <div className="GlobalSearchBarOptions">
                    <div className="GlobalFilteredOptionNotFound">Loading results, please wait..</div>
                </div>
              );
        } else {
            if (this.state.showOptions && this.state.userInput) {
                if (this.state.filteredOptions.length) {
                  optionList = (
                    <div className="GlobalSearchBarOptions">
                      <div className="GlobalSearchBarOptionsContainer">
                        {this.state.filteredOptions.map((optionName, index) => {
                            let className;
                            if (index === this.state.activeOption) {
                            className = 'GlobalFilteredOptionActive';
                            }
                            return (
                            <div className={className + " GlobalFilteredOption"} key={optionName} onMouseDown={() => this.onClick(optionName)}>
                                {this.optionImage(optionName.searchType)}
                                <div style={{display: "inline-block"}}>
                                    <div style={{fontFamily: 'Poppins-Regular', fontSize: "15px"}}>
                                        {optionName.name}
                                    </div>
                                    {this.filterSubTitle(optionName)}
                                </div>
                            </div>
                            );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  optionList = (
                    <div className="GlobalSearchBarOptions">
                        <div className="GlobalFilteredOptionNotFound">No resuts found...</div>
                    </div>
                  );
                }
            }
        }
        if (this.state.searching){
            searchBarColor = {backgroundColor: "#F9F9F9", position: "relative"}
            searchIconStyle = {top: "5px"}
            containerShadow = {boxShadow: "3px 3px 12px #1B1B1B59", marginTop: "15px", borderRadius: "12px"};
        } else {
            searchBarColor = {backgroundColor: "white", display: "flex", position: "relative", borderRadius: "40px"}
            containerShadow = {};
            searchIconStyle = {};
        }
        if (this.state.userInput){
            crossIcon = (<span className="GlobalClearSearch" onMouseDown={this.clearSearch}>X</span>);
        } else {
            crossIcon = (<span></span>)
        }
        return (
            <div onClick={(e) => e.stopPropagation()} style={containerShadow} className="GlobalSearchBarSuper">
                <div style={searchBarColor}>
                    <SearchIcon style={searchIconStyle} className="GlobalSearchIcon"/>
                    <input
                        autoFocus
                        type="text"
                        className="GlobalSearchBar"
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        value={this.state.userInput}
                        onBlur={this.checkSearch}
                        placeholder="Search for Restaurants, Cuisines..."
                    />
                    {crossIcon}
                </div>
                {optionList}
            </div>
        );
    }
}

export default SearchBar;