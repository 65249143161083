import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import { withRouter } from 'react-router';
// import ReactAudioPlayer from 'react-audio-player';
// import pop_sound from '../../assets/media/pop_sound.mp3';

class ConfirmationBox extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
    }

    closeModalHandler() {
        this.props.toggleConfirmationBox("Cancel");
        this.props.setDishesAddingState(this.props.dishDetails, false)
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.showConfirmationBox){
            return null;
        }

        let dishIDPart = this.props.confirmationBoxMessage.split(':');

        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                    <div style={{width: "100%", textAlign: "center", fontFamily: "Poppins-Regular", padding: "30px 10px", fontSize:"13px"}}>
                        {this.props.confirmationBoxMessage.includes("not accepting orders currently") ?
                            dishIDPart[1] + " Please try some other restaurants."
                        :
                            this.props.confirmationBoxMessage.includes("is out of stock") ?
                                dishIDPart[1] + " Removing the item(s) from the cart."
                            :
                                this.props.confirmationBoxMessage.includes("is not delivering orders now.") ?
                                    dishIDPart[1] + " Would you like to pickup your order from the restaurant?"
                                :
                                    this.props.confirmationBoxMessage.includes("is not accepting orders now.") ?
                                        dishIDPart[1] + " Please try some other restaurants."
                                    :
                                        dishIDPart[1]
                        }
                    </div>
                    <div style={{padding: "0px 0px 30px 0px", display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                        {this.props.confirmationBoxMessage.includes("not accepting orders currently")?
                            <div style={{width: "40%"}}>
                                <div 
                                onClick={() => {this.props.toggleConfirmationBox("REMOVE RESTO DISHES");}} 
                                style={{backgroundColor: "#EA5B2F", color:"#FFFFFF", width: "100%", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                    Ok
                                </div>
                            </div>
                        :
                            this.props.confirmationBoxMessage.includes("is out of stock") ?
                                <div style={{width: "40%"}}>
                                    <div 
                                    onClick={() => {this.props.toggleConfirmationBox("REMOVE DISH:"+dishIDPart[0]);}} 
                                    style={{backgroundColor: "#EA5B2F", color:"#FFFFFF", width: "100%", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                        Ok
                                    </div>
                                </div>
                            :
                                this.props.confirmationBoxMessage.includes("currently not delivering orders") ?
                                    <>
                                        <div style={{width: "40%", padding: "0px 1.5vw 0px 4.5vw"}}>
                                            <div 
                                            onClick={() => {this.props.toggleConfirmationBox("Proceed With Pickup");}} 
                                            style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                                Yes
                                            </div>
                                        </div>
                                        <div style={{width: "40%", padding: "0px 4.5vw 0px 1.5vw"}}>
                                            <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                                No
                                            </div>
                                        </div>
                                    </>
                                :
                                    this.props.confirmationBoxMessage.includes("is not delivering orders now.") ?
                                        <>
                                            <div style={{width: "40%", padding: "0px 1.5vw 0px 4.5vw"}}>
                                                <div 
                                                onClick={() => {this.props.toggleConfirmationBox("Refresh Delivery Type");}} 
                                                style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                                    Yes
                                                </div>
                                            </div>
                                            <div style={{width: "40%", padding: "0px 4.5vw 0px 1.5vw"}}>
                                                <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                                    No
                                                </div>
                                            </div>
                                        </>
                                    :
                                        this.props.confirmationBoxMessage.includes("is not accepting orders now.") ?
                                            <>
                                                <div style={{width: "40%", padding: "0px 1.5vw 0px 4.5vw"}}>
                                                    <div 
                                                    onClick={() => {this.props.toggleConfirmationBox("Clear cart and go home");}} 
                                                    style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                                        Ok
                                                    </div>
                                                </div>
                                                <div style={{width: "40%", padding: "0px 4.5vw 0px 1.5vw"}}>
                                                    <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div style={{width: "40%", padding: "0px 1.5vw 0px 4.5vw"}}>
                                                    <div 
                                                    onClick={() => {this.props.toggleConfirmationBox("Yes");}} 
                                                    style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "10px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                                        Yes
                                                    </div>
                                                </div>
                                                <div style={{width: "40%", padding: "0px 4.5vw 0px 1.5vw"}}>
                                                    <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "10px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                                        No
                                                    </div>
                                                </div>
                                            </>
                        }
                        
                   </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ConfirmationBox);