import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import CloseIcon from '@material-ui/icons/Close';
import { Star } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiStarFill } from "react-icons/ri";

class DishCard extends Component{

    constructor(props){
        super(props);
        this.checkAspectRatio = this.checkAspectRatio.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.showAddToCartModalHandler = this.showAddToCartModalHandler.bind(this);
        this.state = {
            gridSize: 12,
            dishImageWidth: "",
            dishImageTransform: "",
            isMobileScreen: false,
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 650) {
            this.setState({ gridSize: 12 });
            this.setState({ isMobileScreen: true });
        } else if(screen_width < 1000) {
          this.setState({ gridSize: 6 });
          this.setState({ isMobileScreen: true });
        } else if (screen_width < 1400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else if (screen_width < 2400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else {
            this.setState({gridSize: 2});
            this.setState({ isMobileScreen: false });
        }
    }

    toggleImageHover(index) {
        this.props.toggleImageHover(index);
    }

    toggleRateHover(index) {
        this.props.toggleRateHover(index);
    }

    toggleReviewHover(index) {
        this.props.toggleReviewHover(index);
    }

    toggleLikeButton(index) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.toggleLikeButton(index);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showRatingsModalHandler(dish) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    checkUserLogIn() {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            return true;
        } else {
            return false;
        }
    }

    showAddToCartModalHandler() {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            localStorage.setItem('CartDetails', '{"RestoID":"' + this.props.dish.restaurantId + '", "RestoName":"' + this.props.dish.restaurant + '", "Cart_Items":[{"DishID":"' + this.props.dish.dishId + '", "DishName":"' + this.props.dish.name + '", "DishCost":"' + this.props.dish.cost + '", "DishImage":"' + this.props.dish.image + '", "DishIsVeg":"' + this.props.dish.is_veg + '", "DishQuantity":"1"}]}')
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    getDescription(description, dishName){
        var maxLength;
        if (this.state.gridSize === 12){
            maxLength = 54;
        } else {
            maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                return description.slice(0, maxLength) + "..";
            }
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        var maxLength;
        if (this.state.gridSize === 12){
            maxLength = 54;
        } else {
            maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length > maxLength){
                return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // if (this.state.gridSize === 12){
                //     return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // } else {
                //     return <div onMouseEnter={() => this.toggleImageHover(dish.id - 1)} onMouseLeave={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">Read more</div> ;
                // }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    discountCard(dish){
        if (dish.discount_present && dish.discount_present === true){
            return (
                <span className="Ribbon">
                    <div className="RibbonText">{dish.discount_text}</div>
                </span>
            )
        } else {
            return null;
        }
    }

    checkAspectRatio({target:img}){
        let aspectRatio = img.offsetHeight/img.offsetWidth;
        if (0.95 < aspectRatio < 1.05){
            if (this.state.gridSize === 12){
                this.setState({
                    dishImageWidth: "300px !important",
                });
            } else {
                this.setState({
                    dishImageWidth: "275px !important",
                });
            }
        }

        if (this.state.gridSize === 12){
            if (img.offsetWidth > 300){
                let excessWidth = Math.floor((img.offsetWidth - 300) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        } else {
            if (img.offsetWidth > 275){
                let excessWidth = Math.floor((img.offsetWidth - 275) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        }
    }
    
    render(){
        return (
        <Grid key={this.props.dish.id} container justifyContent="center" alignItems="center" item xs={this.state.gridSize}>
            <Grid item xs={12}>
                <div className="DishPreviewCardWrapper">
                    <div style={this.props.descriptionOverlayStyle[this.props.dish.id - 1]} className="DishDescriptionOverlay">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                {this.props.dish.name}
                            </div>
                            <CloseIcon style={{color: "black", opacity: this.state.gridSize === 12 ? "1" : "1", cursor: "pointer"}} onClick={() => this.toggleImageHover(this.props.dish.id - 1)} />
                        </div>
                        <div className="DishDescriptionOverlayText">
                            {(this.props.dish.description === null || this.props.dish.description === "") ? this.props.dish.name : this.props.dish.description}
                        </div>
                    </div>
                    <div className="DishCardTopContainer">
                        {this.discountCard(this.props.dish)}
                        <div className="DishPreviewImageOverlay">
                            <img onLoad={this.checkAspectRatio} style={{transform: this.state.dishImageTransform, width: this.state.dishImageWidth}} src={this.props.dish.image} alt="DishImage" className="DishPreviewImage"/>
                            {/* <span className="DishRatingsNumber">({this.props.dish.no_rating})</span> */}
                            <div className="DishRatingsSuperContainer" style={{cursor: "pointer"}} onClick={() => this.showReviewsModalHandler(this.props.dish)}>
                                <div className="DishRatingsContainer">
                                    <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                    <span style={{fontFamily: "Poppins-SemiBold"}}>{this.props.dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58"}}> ({this.props.dish.no_rating})</span>
                                </div>
                            </div>
                            <div className="LikeButtonContainerSuper">
                                <div className="LikeButtonContainer">
                                    <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                        <span onClick={() => this.toggleLikeButton(this.props.dish.id - 1)} className={this.props.likeButtonStyle[this.props.dish.id - 1]}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="DishTextContainer">
                            <div className="DishText">
                                <span className="DishDescription">{ this.getDescription(dish.description, dish.name) }</span>
                            </div>
                        </div> */}
                    </div>
                    <div style={{marginTop: this.state.isMobileScreen ? "60px" : "145px", width: "275px", boxShadow: "2px 2px 10px #aaaaaa", borderRadius: "0px 0px 20px 20px"}} className={`${this.state.isMobileScreen ? "DishCardBottomContainerMobile" : "DishCardBottomContainer"}`}>
                        <div className="DishDetailsRow">
                            <div>
                                {this.props.dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                            </div>
                            <div className="DishImageText">
                                <div className="DishName">{this.props.dish.name}</div>
                                {this.props.dish.restaurantId !== -1 ? <Link to={'/restaurants/' + this.props.dish.restaurantId} style={{ textDecoration: 'none' }}>
                                    <div className="DishRestaurant">{this.props.dish.restaurant}</div>
                                </Link> : <div className="DishRestaurant">{this.props.dish.restaurant}</div>}
                                <div  className="DishName"> 
                                    Rs.{this.props.dish.cost}
                                </div>
                                <div style={{flex: "1 1 auto"}}>
                                    <div className="DishDescription">
                                        { this.getDescription(this.props.dish.description, this.props.dish.name) }
                                        { this.getShowMore(this.props.dish.description, this.props.dish) }
                                    </div>
                                </div>
                                <div className="DishCardActionsContainer">
                                    <div onClick={() => this.showRatingsModalHandler(this.props.dish)}>
                                        <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                    </div>
                                    
                                    <div>
                                        {this.props.screenType === "partner_with_us" ? <span className="ddAddCartBtn"><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span> : this.checkUserLogIn() === true ? <Link to={'/restaurants/' + this.props.dish.restaurantId} style={{ textDecoration: 'none' }} onClick={() => this.showAddToCartModalHandler()}>
                                        <span className="ddAddCartBtn"><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                                        </Link> : 
                                        <span className="ddAddCartBtn" onClick={() => this.showAddToCartModalHandler()} disabled><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
        )
    }

}

export default DishCard;