import React, { Component } from 'react';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import '../../assets/styles/Restaurants.css';
import axios from 'axios';
import CONSTANTS from '../../config/constants';

class TabsContent extends Component {

    componentWillMount() {
        this.setState({
          activeItemIndex: 0,
          noOfItems: 0,
          isUpdating: false,
          isSaving: false,
          editText: "Edit",
          isNameError: false,
          isUsernameError: false,
          isMobileError: false,
          isEmailError: false,
          isAddressError: false,
          nameError: "",
          usernameError: "",
          mobileError: "",
          emailError: "",
          addressError: "",
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        let user = JSON.parse(localStorage.getItem("user"));
        if (user["name"] != null){
            var name = user["name"];
        } else {
            name = ""
        }
        if (user["username"] != null){
            var username = user["username"];
        } else {
            username = ""
        }
        if (user["mobile_no"] != null){
            var mobile = user["mobile_no"];
        } else {
            mobile = ""
        }
        if (user["email"] != null){
            var email = user["email"];
        } else {
            email = ""
        }
        if (user["address"] != null){
            var address = user["address"];
        } else {
            address = ""
        }
        this.setState({
            name: name,
            editName: name,
            username: username,
            editUsername: username,
            mobile: mobile,
            editMobile: mobile,
            email: email,
            editEmail: email,
            address: address,
            editAddress: address,
        })
      }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1 });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 2 });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 3 }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 4 }); 
        } else {
            this.setState({noOfItems: 5});
        }
    }
    handleEditing(){
       if (!this.state.isUpdating && !this.state.isSaving){
           this.setState({
               editText: "Save",
               isUpdating: !this.state.isUpdating,
               editName: this.state.name,
               editUsername: this.state.username,
               editMobile: this.state.mobile,
               editEmail: this.state.email,
               editAddress: this.state.address
           })
       } else {
           this.setState({
                isNameError: false,
                isUsernameError: false,
                isMobileError: false,
                isEmailError: false,
                isAddressError: false,
                nameError: "",
                usernameError: "",
                mobileError: "",
                emailError: "",
                addressError: "",
                editText: "Saving...",
                isSaving: true
           })
            var name = this.state.editName;
            var username = this.state.editUsername;
            var mobile = this.state.editMobile;
            var email = this.state.editEmail;
            var address = this.state.editAddress;
            var isError = false;
            if (name === ""){
                isError = true;
                this.setState({
                    isNameError: true,
                    nameError: "Name cannot be blank."
                })
            } else {
                if (name.length < 3){
                    isError = true;
                    this.setState({
                        isNameError: true,
                        nameError: "Name should have a minimum of 3 characters."
                    })
                }
            }
            if (username === ""){
                isError = true;
                this.setState({
                    isUsernameError: true,
                    usernameError: "Username cannot be blank."
                })
            } else {
                if (username.length < 5){
                    isError = true;
                    this.setState({
                        isUsernameError: true,
                        usernameError: "Username should have a minimum of 5 characters."
                    })
                }
            }
            if (mobile === ""){
                isError = true;
                this.setState({
                    isMobileError: true,
                    mobileError: "Mobile Number cannot be blank."
                })
            } else {
                let validator = /^[0]?[6789]\d{9}$/;
                if (!validator.exec(mobile)){
                    isError = true;
                    this.setState({
                        isMobileError: true,
                        mobileError: "Please enter a valid mobile number."
                    })
                }
            }
            if (email === ""){
                this.setState({
                    isEmailError: true,
                    emailError: "Email address can't be blank.",
                })
                isError = true;
            } else {
                var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!emailValidator.exec(email)){
                    this.setState({
                        isEmailError: true,
                        emailError: "Please enter a valid email address."
                    }) 
                }
            }
            if (address === ""){
                isError = true;
                this.setState({
                    isAddressError: true,
                    addressError: "Address cannot be blank."
                })
            } else {
                if (address.length < 5){
                    isError = true;
                    this.setState({
                        isAddressError: true,
                        addressError: "Address should have a minimum of 5 characters."
                    })
                }
            }
            if (isError){
                this.setState({
                    isSaving: false,
                    editText: "Save",
                })
                return;
            } else {
                var req = {
                    name: name,
                    username: username,
                    phone_number: mobile,
                    email: email,
                    address: address
                }
                var token = localStorage.getItem("auth_token");
                var headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                let url = CONSTANTS.hostURL + "api/accounts/profile_update/";
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    var data = response.data;
                    if (data.status === 1){
                        this.setState({
                            name: name,
                            username: username,
                            mobile: mobile,
                            email: email,
                            address: address,
                            editText: "Edit",
                            isUpdating: false,
                            isSaving: false
                        })
                        var user = JSON.parse(localStorage.getItem("user"));
                        user["name"] = name;
                        user["username"] = username;
                        user["mobile_no"] = mobile;
                        user["email"] = email;
                        user["address"] = address;
                        localStorage.setItem("user", JSON.stringify(user));
                    } else if (data.status === -1){
                        var errors = data.errors;
                        for (var i=0; i<errors.length; i++){
                            if (errors[i]["type"] === "username"){
                                this.setState({
                                    isUsernameError: true,
                                    usernameError: errors[i]["info"]
                                })
                            } else if (errors[i]["type"] === "mobile"){
                                this.setState({
                                    isMobileError: true,
                                    mobileError: errors[i]["info"]
                                })
                            } else if (errors[i]["type"] === "email"){
                                this.setState({
                                    isEmailError: true,
                                    emailError: errors[i]["info"]
                                })
                            }
                        }
                        this.setState({
                            isSaving: false,
                            editText: "Save",
                        })
                    }
                }).catch((error) => this.setState({
                    isSaving: false,
                    editText: "Save",
                }));
            }
       }
    }

    cancelButton(){
        if (this.state.isUpdating && !this.state.isSaving){
            return(
                <span onClick={() => this.setState({isUpdating: false, editText: "Edit"})} style={{marginLeft: "10px"}}>Cancel</span>
            )
        } else {
            return null;
        }
    }


    render() {
        var fieldStyle = {};
        var inputStyle = {};
        var nameErrorStyle = {};
        var usernameErrorStyle = {};
        var mobileErrorStyle = {};
        var emailErrorStyle = {};
        var addressErrorStyle = {};
        if (this.state.isUpdating){
            fieldStyle = {display: 'none'};
            inputStyle = {display: 'block'};
        } else {
            fieldStyle = {display: 'block'};
            inputStyle = {display: 'none'};
        }
        if (this.state.isUpdating && this.state.isNameError){
            nameErrorStyle = {display: 'block'};
        } else {
            nameErrorStyle = {display: 'none'};;
        }
        if (this.state.isUpdating && this.state.isUsernameError){
            usernameErrorStyle = {display: 'block'};
        } else {
            usernameErrorStyle = {display: 'none'};;
        }
        if (this.state.isUpdating && this.state.isMobileError){
            mobileErrorStyle = {display: 'block'};
        } else {
            mobileErrorStyle = {display: 'none'};;
        }
        if (this.state.isUpdating && this.state.isEmailError){
            emailErrorStyle = {display: 'block'};
        } else {
            emailErrorStyle = {display: 'none'};;
        }
        if (this.state.isUpdating && this.state.isAddressError){
            addressErrorStyle = {display: 'block'};
        } else {
            addressErrorStyle = {display: 'none'};;
        }
        return (
            <div className="TabsContentContainer">
                <div className="SettingsHeader">
                    <div className="SettingsLeftHeader">User Info</div>
                    <div className="SettingsRightHeader"><span onClick={() => this.handleEditing()}>{this.state.editText}</span> {this.cancelButton()}</div>
                </div>
                <div className="ProfileUpdateContainer">
                    <div className="ProfileUpdateLabel">Name</div>
                    <div style={fieldStyle} className="ProfileUpdateField">{this.state.name}</div>
                    <input placeholder="Enter name" onChange={(event) => this.setState({editName: event.target.value})} value={this.state.editName} style={inputStyle} className="ProfileUpdateInputField"/>
                    <div style={nameErrorStyle} className="ProfileUpdateError">{this.state.nameError}</div>
                    <div className="ProfileUpdateLabel">Username</div>
                    <div style={fieldStyle} className="ProfileUpdateField">{this.state.username}</div>
                    <input placeholder="Enter username" onChange={(event) => this.setState({editUsername: event.target.value})} value={this.state.editUsername} style={inputStyle} className="ProfileUpdateInputField"/>
                    <div style={usernameErrorStyle} className="ProfileUpdateError">{this.state.usernameError}</div>
                    <div className="ProfileUpdateLabel">Mobile</div>
                    <div style={fieldStyle} className="ProfileUpdateField">{this.state.mobile}</div>
                    <input placeholder="Enter mobile number" onChange={(event) => this.setState({editMobile: event.target.value})} value={this.state.editMobile}  style={inputStyle} className="ProfileUpdateInputField"/>
                    <div style={mobileErrorStyle} className="ProfileUpdateError">{this.state.mobileError}</div>
                    <div className="ProfileUpdateLabel">Email</div>
                    <div style={fieldStyle} className="ProfileUpdateField">{this.state.email}</div>
                    <input placeholder="Enter email address" onChange={(event) => this.setState({editEmail: event.target.value})} value={this.state.editEmail}  style={inputStyle} className="ProfileUpdateInputField"/>
                    <div style={emailErrorStyle} className="ProfileUpdateError">{this.state.emailError}</div>
                    <div className="ProfileUpdateLabel">Address</div>
                    <div style={fieldStyle} className="ProfileUpdateField">{this.state.address}</div>
                    <input placeholder="Enter address" onChange={(event) => this.setState({editAddress: event.target.value})} value={this.state.editAddress}  style={inputStyle} className="ProfileUpdateInputField"/>
                    <div style={addressErrorStyle} className="ProfileUpdateError">{this.state.addressError}</div>
                </div>
            </div>
        )
    }
}


export default TabsContent;