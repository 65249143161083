import React, { Component } from 'react';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import '../../assets/styles/Restaurants.css';
import rupee from '../../assets/images/rupee.svg';
// import dinner from '../../assets/images/dinner.svg';
import pin from '../../assets/images/pin.svg';
import SearchBar from '../Commons/SearchBar';
import { withRouter } from 'react-router'; 
import DiscountImage from "../../assets/images/discountImage.png";
import FreeDelivery from "../../assets/images/freeDelivery1.png";

class Cover extends Component {

    constructor(props){
        super(props);
        this.closeSearchModalHandler = this.closeSearchModalHandler.bind(this);
        this.onImgLoad = this.onImgLoad.bind(this);
        this.state = {
            isMobileScreen: false,
            imageHeight: 0,
            imageWidth: 0
        }
    }
    componentWillMount() {
        this.closeSearchModalHandler = this.closeSearchModalHandler.bind(this);
        this.setState({
          activeItemIndex: 0,
          noOfItems: 0
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
      }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1 });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 2 });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 3 }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 4 }); 
        } else {
            this.setState({noOfItems: 5});
        }
        screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    showContactModalHandler() {
        if (this.props.restaurant.phone_number.length > 1){
            this.props.showContactModal();
        } else {
            window.open("tel:" + this.props.restaurant.phone_number, "_blank");
        }
    }

    mobileSpecificComponent() {
        if (this.state.isMobileScreen){
            return (
                <div className="RestaurantDetails">
                    <div style={{padding:"3px 0px"}}>
                        <img style={{width: "7px", transform: "translateY(1px)", marginRight: "1px"}} src={rupee} alt="pin"/> {this.props.restaurant.cost_for_two} for two
                    </div>
                    <div style={{padding:"3px 0px"}}>
                        <img style={{width: "10px", transform: "translateY(2px)", marginRight: "3px"}} src={pin} alt="pin"/> {this.props.restaurant.address}
                    </div>
                    {/* <div style={{padding:"3px 0px"}}>
                        <img style={{width: "14px", transform: "translateY(2px)", marginRight: "3px"}} src={dinner} alt="dinner"/> {this.props.restaurant.delivery_type}
                    </div> */}
                </div>
            )
        } else {
            return (
                <div className="RestaurantDetails">
                    <img style={{width: "7px", transform: "translateY(1px)", marginRight: "1px"}} src={rupee} alt="pin"/> {this.props.restaurant.cost_for_two} for two
                    <span style={{margin: "0px 10px"}}>&#8226;</span>
                    <img style={{width: "10px", transform: "translateY(2px)", marginRight: "3px"}} src={pin} alt="pin"/> {this.props.restaurant.address}
                    {/* <span style={{margin: "0px 10px"}}>&#8226;</span>
                    <img style={{width: "14px", transform: "translateY(2px)", marginRight: "3px"}} src={dinner} alt="dinner"/> {this.props.restaurant.delivery_type} */}
                </div>
            )
        }
    }

    isFreeDelivery(){
        let user_latitude = localStorage.getItem('user_latitude');
        let user_longitude = localStorage.getItem('user_longitude');
        let freeDelivery = true;
        if (user_latitude!== undefined && user_longitude!== undefined && this.props.restaurant.latitude !== null && this.props.restaurant.longitude !== null){
          let distance = this.calcDistance(user_latitude, user_longitude, this.props.restaurant.latitude, this.props.restaurant.longitude);
          if(!this.props.restaurant.is_delivery_available){
            freeDelivery = false;
          }
          if (this.props.restaurant.delivery_charges !== null){
            let deliveryCharges = this.props.restaurant.delivery_charges;
            for (var key in deliveryCharges) {
              let chargeItem = deliveryCharges[key];
              if (chargeItem.start < distance < chargeItem.end){
                if (chargeItem.charge !== 0){
                  freeDelivery = false;
                }
              }
            }
          }
        }
        return freeDelivery;
    }

    // Calculate distance between two geolocation points
    calcDistance(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = this.toRad(lat2-lat1);
        var dLon = this.toRad(lon2-lon1);
        var lat1_rad = this.toRad(lat1);
        var lat2_rad = this.toRad(lat2);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1_rad) * Math.cos(lat2_rad); 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c;
        return d;
    }

    // Converts numeric degrees to radians
    toRad(Value) {
        return Value * Math.PI / 180;
    }

    restoOfferings(){
        // console.log(this.props.restaurant)
        if((this.props.restaurant.discount === "" || this.props.restaurant.discount === 0) && this.isFreeDelivery() === false && this.props.restaurant.is_accepting_orders === true)
            return(<></>)
        else{
            return(
                <div className='ddRestoOfferings'>
                    {(this.props.restaurant.discount !== "" && this.props.restaurant.discount > 0) && <><img src={DiscountImage} alt="DiscountImage" style={{width:"25px"}}/>
                    <span style={{position:"relative", top:"4px", marginLeft:"5px", marginRight:"10px", fontFamily:"Poppins-Bold"}}>{this.props.restaurant.discount}% off</span></>}

                    {((this.props.restaurant.discount !== "" && this.props.restaurant.discount > 0) && this.isFreeDelivery()) && <span style={{borderRight:"#EDEDED 1px solid", marginRight:"10px"}}></span>}

                    {this.isFreeDelivery() && <><img src={FreeDelivery} alt="freeDelivery" style={{width:"25px"}}/>
                    <span style={{position:"relative", top:"4px", marginLeft:"5px"}}>Free Delivery</span></>}

                    {this.props.restaurant.is_accepting_orders === false && <><span style={{position:"relative", marginLeft:"5px", background:"#FFFFFF", color:"#3356AA", padding:"3px 11px 0px 11px", borderRadius:"5px"}}>Restaurant Closed</span></>}

                </div>
            )
        }
    }

    restaurantDetails(){
        if(!this.props.restaurantDetailsLoaded){
            return(
                <div className="RestaurantDetailsLeftContainer">
                    {/* <div style={{height: "70px", width: "70px", borderRadius: "50px", border: "2px solid white", marginBottom: "10px"}}>
                        <img style={{height: "100%", width: "100%"}} src={restaurant_pic} alt=""/>
                    </div> */}
                    <div style={{fontSize: "18px"}} className="RestaurantName"></div>
                </div>
            )
        } else {
            return (
                <div className="RestaurantDetailsLeftContainer" style={{padding:"0px", display:"block"}}>
                    <div className="RestaurantDetailsLeftContainerFirst"  style={{padding:"20px"}}>
                        {!this.props.restaurant.logo_url ? null : <div style={{height: "70px", width: "70px", borderRadius: "50px", border: "2px solid white", marginBottom: "10px"}}>
                            <img style={{height: "100%", width: "100%"}} src={this.props.restaurant.logo_url} alt=""/>
                        </div>}
                        <div className="RestaurantName">{this.props.restaurant.name}</div>
                        <div className="RestaurantDescription">{this.props.restaurant.description}</div>
                        {this.mobileSpecificComponent()}
                        
                    </div>
                    
                    {this.restoOfferings()}
                    {/* <div className="RestaurantDetailsLeftContainerSecond">
                        <div onClick={() => this.showContactModalHandler()} className="RestaurantContact"> 
                            Call Restaurant
                        </div>
                    </div> */}
                </div>
            )
        }
    }

    closeSearchModalHandler(){
        this.props.closeSearchModal();
    }

    onImgLoad({target:img}) {
        this.setState({
            imageHeight: img.offsetHeight,
            imgeWidth: img.offsetWidth
        });
    }

    getImageHeight(){
        if (this.state.isMobileScreen){
            return "translateY(0%)";
        } else {
            if (this.state.imageHeight > 480){
                let height = Math.floor((this.state.imageHeight - 480) / 2);
                return "translateY(-" + height + "px)";
            } else {
                return "translateY(0%)";
            }
        }
    }

    render() {
        let searchBarPopup;
        if (this.props.showSearchModal){
            searchBarPopup = (<div className="GlobalSearchBarContainer">
                <SearchBar
                    cuisines={this.props.cuisines}
                    dishes={this.props.dishes}
                    history={this.props.history}
                    isDishScreen={this.props.isDishScreen}
                />
            </div>);
        } else {
            searchBarPopup = null;
        }
        return (
            <div onClick={this.closeSearchModalHandler} className="RestaurantsCoverSuperContainer" style={{marginTop: "60px"}}>
                {searchBarPopup}
                <div className="RestaurantsCoverContainer">
                    <img style={{transform: this.getImageHeight()}} onLoad={this.onImgLoad} className="RestaurantsCoverImage" src={this.props.restaurant.profile_image_url} alt=""/>
                </div>
                {/* <div className="RestaurantsCoverBlur"></div> */}
                {this.restaurantDetails()}
                {/* <div className="RestaurantDetailsRightContainer">
                    <div onClick={() => this.showContactModalHandler()} className="RestaurantContact"> 
                        <PhoneIcon style={{fontSize: "1vw", position: "absolute", top: "10px"}}/><span style={{marginLeft: "18px"}}> Call for delivery</span>
                    </div>   
                </div> */}
            </div>
        )
    }
}


export default withRouter(Cover);