import React, { Component } from 'react';
import Header from '../components/Dishes/Header';
import LogoutConfirm from '../components/Commons/LogoutConfirm';
import UserOptions from '../components/Dishes/UserOptions';
import Footer from '../components/Commons/Footer';
import Cover from '../components/Profile/Cover';
import ProfileUpdate from '../components/Settings/ProfileUpdate';
import '../assets/styles/Settings.css';
import UserCart from '../components/Restaurants/UserCart';

class Settings extends Component {

  constructor() {
    super();
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.updateCartChangedValue = this.updateCartChangedValue.bind(this);
    this.updateCartModalDisplay = this.updateCartModalDisplay.bind(this);
    this.state = { 
      isOpen: false,
      logoutModal: false,
      searchModal: false,
      showCart: false,
    };
  } 

  componentDidMount(){
    window.scrollTo(0,0);
    if (localStorage.getItem("auth_token") === null){
      this.props.history.replace("/");
    }
    if (localStorage.getItem("preferences_present") === null){
      this.props.history.replace("/preferences");
    }
    this.updateCartChangedValue(true);
  }

  updateCartChangedValue(value) {
    this.setState({
      cartChanged: value
    })
    let cartDetails = localStorage.getItem('cartDetailsAPI');
    let itemsInCart = 0;
    if (cartDetails !== null){
      let cartDetailsObj = JSON.parse(cartDetails);
      var itemVal = Object.values(cartDetailsObj.items)
      for(var i=0; i<itemVal.length; i++){
        itemsInCart = itemsInCart + itemVal[i].quantity
      }
    }
    this.setState({
      itemsInCart: itemsInCart
    });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 37: 1, 38: 1, 39: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {  
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.logoutModal  || this.state.searchModal || this.state.isOpen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleSearchModal() {
    if (this.state.searchModal === false){
      window.scrollTo(0, 0);
    }
    this.setState({
      searchModal: !this.state.searchModal
    });
  }

  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal
    })
  }

  toggleUserOptions(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  updateCartModalDisplay(value) {
    this.setState({
      showCart: value
    })
  }

  render() {
    return (
      <div onClick={(e) => {
        e.stopPropagation();
        if (this.state.isOpen){
          this.setState({isOpen: false});
        }
        if (this.state.searchModal){
          this.setState({searchModal: false});
        }
      }}>
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Header 
          toggleUserOptions={this.toggleUserOptions} 
          toggleSearchModal={this.toggleSearchModal}
          updateCartModalDisplay={this.updateCartModalDisplay}
          updateCartChangedValue={this.updateCartChangedValue}
          itemsInCart={this.state.itemsInCart}
          showCart={this.state.showCart}/>
        <UserCart 
          cartChanged={this.state.cartChanged}
          showCart={this.state.showCart}
          updateCartChangedValue={this.updateCartChangedValue}
          updateCartModalDisplay={this.updateCartModalDisplay}
          itemsInCart={this.state.itemsInCart}
          restaurantId={-1}
          defaultAddresses={this.state.defaultAddresses}
          addresses={this.state.addresses}
        />
        <Cover 
          showSearchModal={this.state.searchModal}
          toggleActiveTab={this.toggleActiveTab}
          activeTab={this.state.activeTab}
          tabPresent={false}
          screenType={"settings"}
        />
        <ProfileUpdate/>
        <LogoutConfirm
          showLogoutModal={this.state.logoutModal}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Footer/>
      </div>
    )
  };
}

export default Settings;
