import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo_black.png';
import '../../assets/styles/PartnerTnc.css';
import { Link } from 'react-router-dom';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

   render() {
    return (
        <div style={{position: "fixed", height: "70px", width: "100vw", zIndex: "10000", backgroundColor: "white"}} className="Header HeaderShadowBottom">
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Logo/>
            </div>
        </div> 
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <Link  to={'/home'} style={{ textDecoration: 'none' }}>
                        <img style={{transform: "translateY(-5px)"}} className="LogoImage LogoImageMargin" src={logo} alt="logo"/>  
                    </Link>
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="Logo LogoHome">
                                <Link  to={'/home'} style={{ textDecoration: 'none' }}>
                                    <img style={{transform: "translateY(-5px)"}} className="LogoImage LogoImageMargin" src={logo} alt="logo"/>  
                                </Link>
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

export default withRouter(Header);