import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Restaurants.css';
import best_dish from '../../assets/images/best_dish.png';
import PopularDishStar from '../../assets/images/PopularDishStar.png';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { Star } from '@material-ui/icons';
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiStarFill } from "react-icons/ri";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonLoading from '../../assets/images/btnLoading.gif';

class PopularDish extends Component {

    constructor(props){
        super(props);
        this.popularDishDivRef = React.createRef();
        this.toggleRateHover = this.toggleRateHover.bind(this);
        this.toggleImageHover = this.toggleImageHover.bind(this);
        this.toggleReviewHover = this.toggleReviewHover.bind(this);
        this.toggleLikeButton = this.toggleLikeButton.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.checkAspectRatio = this.checkAspectRatio.bind(this);
        this.state = { 
            isMobileScreen: false,
            customisation_set: "",
            dishImageWidth: "",
            dishImageTransform: "",
        };
    }

    componentWillMount() {
        this.setState({
            dish: {
                image: best_dish, 
                id: 1, 
                name: "Chicken Cheese Burger", 
                restaurant: "Sanky's Burger House", 
                cost: 300, 
                imageHover: false, 
                rateHover: false, 
                reviewHover: false, 
                liked: false,
                isLoggedIn: true
            }
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.setState({
            customisation_set: ""
        })
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.setState({
              isLoggedIn: true
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ["open_"+nextProps.popDishId] : nextProps.popDishState
        })
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 650) {
            this.setState({ gridSize: 12 });
            this.setState({ isMobileScreen: true });
        } else if(screen_width < 1000) {
          this.setState({ gridSize: 6 });
          this.setState({ isMobileScreen: true });
        } else if (screen_width < 1400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else if (screen_width < 2400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else {
            this.setState({gridSize: 2});
            this.setState({ isMobileScreen: false });
        }
    }

    toggleImageHover(index) {
        this.props.toggleImageHover(index);
    }

    toggleRateHover(index) {
        this.props.toggleRateHover(index);
    }

    toggleReviewHover(index) {
        this.props.toggleReviewHover(index);
    }

    toggleLikeButton(item) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            var user = JSON.parse(localStorage.getItem("user"));
            var likes_list = user.likes;
            if (!item.liked){
                likes_list.push(item.dishId);
            } else {
                let dishIndex = likes_list.indexOf(item.dishId)
                if (dishIndex > -1) {
                    likes_list.splice(dishIndex, 1);
                }
            }
            let type = "like";
            if (item.liked){
                type = "dislike";
            }
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            var req = {
                likes_list: likes_list,
                type: type
            }
            var url = CONSTANTS.hostURL + "api/accounts/likes/";
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                if (response.data.status === 1){
                    var user = JSON.parse(localStorage.getItem("user"));
                    user.likes = likes_list;
                    localStorage.setItem("user", JSON.stringify(user));
                }
            });
            
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showRatingsModalHandler(dish) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    getDescription(description, dishName){
        // let screen_width = window.innerWidth - 20;
        // var maxLength = 190 + Math.floor(((screen_width - 360)/3));
        if (description !== "" && description !== null){
            // if (description.length < maxLength){
            //     return description;
            // } else {
            //     if (this.state.isMobileScreen){
            //         return description.slice(0, maxLength) + "..";
            //     } else {
            //         return description;
            //     }
            // }
            return description;
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        let screen_width = window.innerWidth - 20;
        var maxLength = 190 + Math.floor(((screen_width - 360)/3));
        if (this.state.isMobileScreen){
            if (description !== "" && description !== null){
                if (description.length > maxLength){
                    return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                    // if (this.state.gridSize === 12){
                    //     return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                    // } else {
                    //     return <div onMouseEnter={() => this.toggleImageHover(dish.id - 1)} onMouseLeave={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">Read more</div> ;
                    // }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    discountCard(dish){
        if (dish.discount_present && dish.discount_present === true){
            return (
                this.state.isMobileScreen ? 
                    <span className="RibbonMBP">
                        <div className="RibbonTextM">{dish.discount} % off</div>
                    </span>
                    :
                    <span className="Ribbon">
                        <div className="RibbonText">{dish.discount} % off</div>
                    </span>
            )
        } else {
            return null;
        }
    }

    onChange(customItem){
        this.setState({
            customisation_set: customItem
        })
    }

    customisationList(){
        if(this.props.temp_customizations !== undefined){
            const customizations = this.props.temp_customizations;
            let listItems = null;
            let customizationsList = [];
            
            if(customizations.length > 1){
                for(var a=0; a<customizations.length; a++){
                    customizationsList.push(this.props.getCustomisationText(customizations[a]));
                }
                listItems = customizationsList.map((customItem, index) =>
                    <div key={index+"_"+index}>
                        <FormControlLabel 
                            checked={this.state.customisation_set === customizations[index] ? true: false}
                            onChange={this.onChange.bind(this,customizations[index])}
                            name={"custom_"+index} 
                            control={<Radio 
                                color="#EA5B2F"
                                size="small"/>} 
                            label={<span style={{ fontSize: '13px', fontFamily:'Poppins-Regular'}}>{customItem}</span>}
                        />                     
                    </div>
                );
            }
            else{
                listItems = this.props.getCustomisationText(customizations[0]);
            }
            
            return (
                <div className="ddCustomisationText2">{listItems}</div>
            );
        }
        else{
            return (
                <div className="ddCustomisationText2">No DATA</div>
            );
        }
    }

    checkAspectRatio({target:img}){
        let aspectRatio = img.offsetHeight/img.offsetWidth;
        if (0.95 < aspectRatio < 1.05){
            if (this.state.gridSize === 12){
                this.setState({
                    dishImageWidth: "300px !important",
                });
            } else {
                this.setState({
                    dishImageWidth: "275px !important",
                });
            }
        }

        if (this.state.gridSize === 12){
            if (img.offsetWidth > 300){
                let excessWidth = Math.floor((img.offsetWidth - 300) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        } else {
            if (img.offsetWidth > 275){
                let excessWidth = Math.floor((img.offsetWidth - 275) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        }
    }

    popularDishList(){
        const listItems = this.props.popularDishes.map((keyItem) =>
            <Grid key={keyItem.id} item xs={this.state.gridSize}>
                <Grid item xs={12}>
                    {this.state.isMobileScreen ? 
                    <div className="ddDishCardWrapperM" style={{margin:"25px auto"}}>
                        <div style={{textAlign:"center", position:"relative"}}>
                            <img className="ddPopularDishRibbon" src={PopularDishStar} alt="PopularDishStar" style={{zIndex:"101"}}/>
                            <hr style={{position:"absolute", borderRadius:"5px", borderTop: "3px solid #EA5B2F", top: "-7px", left:"0px", right:"0px", width:"150px", zIndex:"100"}}/>
                            <span style={{position:"absolute", fontFamily:"Poppins-Bold", color:"#FFFFFF", left: "0", right: "0", zIndex:"1000", top:"-4px", fontSize:"9px"}}>POPULAR</span>
                        </div>
                        <div style={{display: 'none'}} className="DishDescriptionOverlay">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                    {keyItem.name}
                                </div>
                                <CloseIcon style={{color: "black", opacity: this.state.gridSize === 12 ? "1" : "1", cursor: "pointer"}} /*onClick={() => this.toggleImageHover(this.props.dish.id - 1)}*/ />
                            </div>
                            <div className="DishDescriptionOverlayText">
                                {(keyItem.description === null || keyItem.description === "") ? keyItem.name : keyItem.description}
                            </div>
                        </div>
                        <div className="DishCardTopContainerM" style={{background:"#FFFFFF"}}>
                            {this.discountCard(keyItem)}
                            <div style={{display:"block", padding:"7px 7px 0px 7px", background:"#FFFEEF"}} className="ddDishImageOverlayM">
                                <div><img onLoad={this.checkAspectRatio} src={keyItem.image} alt="DishImage" className="DishImageM"/></div>
                                <div className="DishRatingsSuperContainer" style={{cursor: "pointer"}} 
                                    //onClick={() => this.showReviewsModalHandler(keyItem)}
                                >
                                    <div className="DishRatingsContainer">
                                        <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                        <span style={{fontFamily: "Poppins-SemiBold"}}>{keyItem.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58"}}> ({keyItem.no_rating})</span>
                                    </div>
                                </div>
                                <div className="LikeButtonContainerSuper" style={{top:"15px"}}>
                                    <div className="LikeButtonContainer">
                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                            <span onClick={() => this.toggleLikeButton(keyItem)} className={keyItem.liked ? "LikeNewButton LikeNewButtonActive favme dashicons dashicons-heart": "LikeNewButton favme dashicons dashicons-heart"}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="DishCardBottomContainerMobile" style={{background:"#FFFFFF"}}>
                            <div className="DishDetailsRow">
                                <div>
                                    {keyItem.is_veg === true ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                </div>
                                <div className="DishImageText">
                                    <div className="DishName">{keyItem.name}</div>
                                    <div  className="DishName"> 
                                        Rs.{keyItem.cost}
                                    </div>
                                    <div style={{flex: "1 1 auto"}}>
                                        <div className="DishDescription" style={{height:"100px"}}>
                                            { this.getDescription(keyItem.description, keyItem.name) }
                                            { this.getShowMore(keyItem.description, keyItem) }
                                        </div>
                                    </div>
                                    {/* {keyItem.in_stock !== true && <div style={{position:"absolute", bottom:"60px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></div>} */}
                                    <div className={this.state.isMobileScreen ? "ddDishCardActionsContainerM" : "ddDishCardActionsContainer"} style={{position: "absolute", width: "400px"}}>
                                        <div onClick={() => this.showRatingsModalHandler(keyItem)}>
                                            <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                        </div>

                                        {this.checkAddToCart(keyItem)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="DishCardWrapper HomeDishCardWrapper">
                        <img className="ddPopularDishRibbon" src={PopularDishStar} alt="PopularDishStar"/>
                        <hr style={{position:"absolute", borderRadius:"5px", borderTop: "3px solid #EA5B2F", top: this.state.isMobileScreen?"-6px":"-8px", left:"0px", right:"0px", width:"150px"}}/>
                        <span style={{position:"absolute", fontFamily:"Poppins-Bold", color:"#FFFFFF", left: this.state.isMobileScreen ? "130px" : "119px", zIndex:"100", top:"-4px", fontSize:"9px"}}>POPULAR</span>
                        <div style={{display: 'none'}} className="DishDescriptionOverlay">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                    {keyItem.name}
                                </div>
                                <CloseIcon style={{color: "black", opacity: this.state.gridSize === 12 ? "1" : "1", cursor: "pointer"}} /*onClick={() => this.toggleImageHover(this.props.dish.id - 1)}*/ />
                            </div>
                            <div className="DishDescriptionOverlayText">
                                {(keyItem.description === null || keyItem.description === "") ? keyItem.name : keyItem.description}
                            </div>
                        </div>
                        <div className="DishCardTopContainer">
                            {this.discountCard(keyItem)}
                            <div style={{display:"block"}} className="ddDishImageOverlay">
                                <img onLoad={this.checkAspectRatio} style={{transform: this.state.dishImageTransform, width: this.state.dishImageWidth}} src={keyItem.image} alt="DishImage" className="DishImage"/>
                                <span className="DishRatingsNumber">({keyItem.no_rating})</span>
                                <div className="DishRatingsSuperContainer" style={{cursor: "pointer"}} 
                                    //onClick={() => this.showReviewsModalHandler(keyItem)}
                                >
                                    <div className="DishRatingsContainer">
                                        <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                        <span style={{fontFamily: "Poppins-SemiBold"}}>{keyItem.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58"}}> ({keyItem.no_rating})</span>
                                    </div>
                                </div>
                                <div className="LikeButtonContainerSuper" style={{marginRight:"0px", top:"5px"}}>
                                    <div className="LikeButtonContainer">
                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                            <span onClick={() => this.toggleLikeButton(keyItem)} className={keyItem.liked ? "LikeNewButton LikeNewButtonActive favme dashicons dashicons-heart": "LikeNewButton favme dashicons dashicons-heart"}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${this.state.isMobileScreen ? "DishCardBottomContainerMobile" : "DishCardBottomContainer"}`}>
                            <div className="DishDetailsRow">
                                <div style={{position: "relative", top: "-2px"}}>
                                    {keyItem.is_veg === true ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                </div>
                                <div className="DishImageText">
                                    <div className="DishName">{keyItem.name}</div>
                                    <div  className="DishName"> 
                                        Rs.{keyItem.cost}
                                    </div>
                                    <div style={{flex: "1 1 auto"}}>
                                        <div className="DishDescription">
                                            { this.getDescription(keyItem.description, keyItem.name) }
                                            { this.getShowMore(keyItem.description, keyItem) }
                                        </div>
                                    </div>
                                    {keyItem.in_stock !== true && <div style={{position:"absolute", bottom:"60px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                    <div className={this.state.isMobileScreen ? "ddDishCardActionsContainerM" : "ddDishCardActionsContainer"} style={{position: "absolute"}}>
                                        <div onClick={() => this.showRatingsModalHandler(keyItem)}>
                                            <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                        </div>

                                        {this.checkAddToCart(keyItem)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Grid>
            </Grid>
        );
        return(
            <Grid container spacing={2}>
                {listItems}
            </Grid>
        );
    }

    popOverDetails(dishID){
        return(
            this.props.repeatCustomisationMessage === "Repeat Last Customisation?" ?
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.props.temp_customizations.length > 1 ?
                            this.state.customisation_set !== "" ? 
                                <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                    this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.state.customisation_set));
                                }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                            :
                                <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                    Select Customisation
                                </span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.props.temp_customizations[0]));
                            }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                        }
                        <span style={{marginLeft:"10px"}} className={this.state.isMobileScreen ? "ddPopOverIllChooseBtnM" : "ddPopOverIllChooseBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("No","");
                            }}>I'll Choose
                        </span>
                    </div>
                </div>
            :
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.state.customisation_set !== "" ? 
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Remove", JSON.stringify(this.state.customisation_set));
                            }}>Remove Selected</span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                Select Customisation
                            </span>
                        }
                        <span style={{marginLeft:"10px"}} className="ddPopOverIllChooseBtn" onClick={() => {
                                this.props.toggleRepeatCustomisation("","");
                            }}>Cancel
                        </span>
                    </div>
                </div>
        );
    }

    getIndex(dish){
        for(let i=0; i<this.props.dishesAddingState.length; i++){
            if(this.props.dishesAddingState[i].dishID === dish.dishId)
                return i
        }
    }

    checkAddToCart(dish) {
        var token = localStorage.getItem("auth_token");
        let itemsPresent = false;
        let dishQuantity = 0;
        if (token !== null && token !== ""){
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI !== null) {
                let cartItems = JSON.parse(cartDetailsAPI)["items"];
                var itemKey = Object.keys(cartItems);
                var itemValue = Object.values(cartItems);
                for (var i=0; i<itemValue.length; i++){
                    if (Number(itemKey[i]) === Number(dish.dishId)){
                        dishQuantity = dishQuantity + itemValue[i]["quantity"];
                        itemsPresent = true;
                        //break;
                    }
                } 
            }
            if (dishQuantity > 0) {

            }
        }

        if(this.props.restaurant.is_accepting_orders){
            if (!itemsPresent) {
                return (
                    <>
                        {dish.in_stock === true ?
                            <>
                                {!this.props.dishesAddingState[this.getIndex(dish)].addingState ? 
                                    <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"} onClick={() => this.props.addToCartAction(dish, "add", false)} disabled><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                                    :
                                    <img src={ButtonLoading} alt="Complete Order & Pay" style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"10px", left:"82px", height:"20px"} : {width:"70px", position:"relative", top:"10px", left:"22px", height:"20px"}}/>
                                }
                            </>                            
                        : 
                            // <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                            <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px", padding:"6px 8px 4px 8px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}>{this.state.isMobileScreen ? <>Out of Stock</> : <><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</>}</span>
                        }
                        {dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && <span className="ddCustomisableTag" style={{position:"absolute", top:"39px", left: this.state.isMobileScreen ? dish.in_stock === true ? "172px" : "177px" : "112px"}}>Customisable</span>}
                    </>
                )
            } else {
                var popularDishDivRef = React.createRef();
                return (
                    <>
                    <span ref={popularDishDivRef} style={{position:"relative", padding: "4px 0px 3px 0px", marginLeft: this.state.isMobileScreen ? "50px" : "35px", top:"4px"}} className="cart-Item-Controls">
                        {this.props.dishesAddingState[this.getIndex(dish)].addingState ?
                            <img src={ButtonLoading} alt="loading.." style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"1px", height:"20px"} : {width:"63px", position:"relative", top:"1px"}}/>
                            :
                            <>
                                <RemoveIcon style={{ fontSize: 14 }} className="remove-quantity" 
                                    aria-describedby={this.props.popUpId} 
                                    onClick={() => {
                                        this.props.addToCartAction(dish, "remove", true, popularDishDivRef.current)}}/>
                                <span style={{fontSize: "14px"}} className="item-Qty">{dishQuantity}</span>
                                <AddIcon style={{ fontSize: 14 }} className="add-quantity" 
                                    aria-describedby={this.props.popUpId} 
                                    onClick={() => {
                                        this.props.addToCartAction(dish, "add", true, popularDishDivRef.current)}}/>
                            </>
                        }
                        <Popover
                            id={this.props.popUpId}
                            open={this.props.popDishState && (this.props.popUpId === "anchorEl_"+dish.dishId+"_"+dish.id)}
                            anchorEl={this.props.popOverAnchorEl}
                            onClose={() => this.props.handlePopOverClose(dish)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {this.popOverDetails(dish.id)}
                        </Popover>
                    </span>
                    {
                    dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && 
                    <span className="ddCustomisableTag" style={{position:"absolute", top:"34px", left: this.state.isMobileScreen?"139px":"124px"}}>Customisable</span>}
                    </>
                )
            }
        }
        else{
            return(
                <>
                    <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px", padding:"4px 20px 5px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    {dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && <span className="ddCustomisableTag" style={{position:"absolute", top:"39px", left: this.state.isMobileScreen ? "174px" : "112px"}}>Customisable</span>}
                </>
            )
        }
    }

    render() {
        // var yellowLineStyle = {};
        if (!this.props.bestDishReady){
            return (
                <Grid alignItems="center" justifyContent="center" container item xs={12}>
                    <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                        Loading Popular Dishes...
                    </div>
                </Grid>
            );
        } else {
            if(this.props.popularDishes.length > 0){
                return (
                    this.state.isMobileScreen ? 
                    <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <img style={{height : '35px'}} src={PopularDishStar} alt="PopularDishStar"/>
                                <span className="ddPopularDishZoneTextM">Popular Dish Zone</span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{fontFamily:"Poppins-Medium", position:"relative", left:"10px", fontSize:"14px"}}>
                            {/* <span>Curate the<span style={{color:"#EA5B2F"}}>&nbsp;Popular Dishes</span> in your area. We believe that you should 'eat right every time.</span> */}
                            <span>{this.props.popularDishDescription}</span>
                        </Grid>
                        
                        <div style={{marginTop:"30px"}}>
                            {this.popularDishList()}
                        </div>
                        

                    </div>
                    :
                    <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={7}>
                                <img style={{height : '60px'}} src={PopularDishStar} alt="PopularDishStar"/>
                                <span className="ddPopularDishZoneText">Popular Dish Zone</span>
                            </Grid>
                            <Grid item xs={5}>
                                <hr className="ddHrStyle" style={{ borderTop: "5px solid #EA5B2F"}}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{fontFamily:"Poppins-Medium", width:"600px", position:"relative", left:"80px"}}>
                            {/* <span>Curate the<span style={{color:"#EA5B2F"}}>&nbsp;Popular Dishes</span> in your area. We believe that you should 'eat right every time.</span> */}
                            <span>{this.props.popularDishDescription}</span>
                        </Grid>
                        
                        <div style={{marginTop:"30px"}}>
                            {this.popularDishList()}
                        </div>
                        

                    </div>
                )
            } else {
                return null;
            }
        }
    }
}

export default PopularDish;