import React, {Component} from 'react';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiEdit2Fill } from "react-icons/ri";
import { MdAddLocationAlt } from "react-icons/md";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import ButtonLoading from '../../assets/images/btnLoading.gif';

class DeliveryAddressCard extends Component {

    constructor(props) {
      super(props);
      this.refMap = React.createRef();
      this.getDefaultLocation = this.getDefaultLocation.bind(this);
      this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
      this.editingItem = this.editingItem.bind(this);
      this.addAddressItem = this.addAddressItem.bind(this);
      this.showAddressCard = this.showAddressCard.bind(this);
      this.showChooseDefAddress = this.showChooseDefAddress.bind(this);
      this.showEditAddress = this.showEditAddress.bind(this);
      this.showAddAddress = this.showAddAddress.bind(this);
      this.handleChangeL = this.handleChangeL.bind(this);
      this.handleChangeR = this.handleChangeR.bind(this);
      this.handleChangeM = this.handleChangeM.bind(this);
      this.updateDefaultAddress = this.updateDefaultAddress.bind(this);
      this.updateEditAddress = this.updateEditAddress.bind(this);
      this.changeButtonName = this.changeButtonName.bind(this);
      this.deleteAddress = this.deleteAddress.bind(this);
      this.setMapCenter = this.setMapCenter.bind(this);
      this.ChooseDefAddress = this.ChooseDefAddress.bind(this);
      this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
      this.state = { 
        isMobileScreen: false,
        locationFromStorage: {lat: 5.40, lng: -1.40},
        center: {lat: 5.40, lng: -1.40},
        buttonName: "REQUEST ORDER CONFIRMATION FROM RESTO",
        paymentBtnState: false,
        requestBtnState: true,
        addressCardsVisibility: false,
        chooseDefAddressVisibility: true,
        editAddressVisibility: false,
        addAddressVisibility: false,
        itemToUpdate: 1,
        itemToEdit: -1,
        editAddressData: "",
        valueAddress: "",
        valueAddressError: "",
        valueFlat: "",
        valueFlatError: "",
        valueBuilding: "",
        valueBuildingError: "",
        valueLandmark: "",
        valueLandmarkError: "",
        addressTypeError: "",
        saveAddressText: "Save Address",
        valueId: -1,
        stateL: {backgroundColor: '#F2F2F2', color: '#000000'},
        stateR: {backgroundColor: '#F2F2F2', color: '#000000'},
        stateM: {backgroundColor: '#F2F2F2', color: '#000000'},
        selectedType: "",
        isDefault: false,
        addresses : [],
        address: "",
        delivers_to: [],
        dataFetched: false,
        addressSelectingState: {}
      };
    } 

    componentDidMount(){
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.setState({
        editAddressData : this.state.addresses[this.state.itemToEdit],
        //addresses : this.props.addresses
      });
      this.setMapCenter();
      this.getUserAddress();
      this.props.getUserAddress();
      this.getDefaultLocation()
      
      //this.props.getUserAddress();
    //   this.getUserAddress();

    //   if(){
    //     this.showAddAddress();
    //   }
    //   else{

    //   }
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }

    setAddressSuggestion(position){
        this.setState({
            locationFromStorage: {
                lat: position['lat'], 
                lng: position['lng']
            },
            center: {
                lat: position['lat'], 
                lng: position['lng']
            },
        })
    }

    getDefaultLocation(){
        if(this.state.addAddressVisibility){
            const Lat = localStorage.getItem('user_latitude');
            const Lon = localStorage.getItem('user_longitude');
            this.setState({
                locationFromStorage: {
                    lat: Lat, 
                    lng: Lon
                },
                center: {
                    lat: Lat, 
                    lng: Lon
                },
            });
        }
        else{
            this.setState({
                locationFromStorage: {
                    lat: this.state.locationFromStorage.lat, 
                    lng: this.state.locationFromStorage.lng
                },
                center: {
                    lat: this.state.center.lat, 
                    lng: this.state.center.lng
                },
            });
        }
    }

    handleBoundsChanged = () => {
        const mapCenter = this.refMap.current;
        fetch('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4&latlng='+mapCenter.map.center.lat()+','+mapCenter.map.center.lng() + '&sensor=false')
        .then(response => response.json())
        .then(data => {
            this.setState({
                valueAddress: data.results[0].formatted_address
            })
        })
        .catch(error => console.log(error))
        this.setState({center: {
            lat : mapCenter.map.center.lat(),
            lng : mapCenter.map.center.lng(),
        }});
    };
    
    editingItem(address){
        const editAddressData = address;
        this.setState({
            itemToEdit: address.id,
            editAddressData : editAddressData,
            valueId: editAddressData.id,
            valueAddress: editAddressData.address,
            locationFromStorage: {
                lat: editAddressData.latitude, 
                lng: editAddressData.longitude
            },
            center: {
                lat: editAddressData.latitude, 
                lng: editAddressData.longitude
            },
            valueFlat: editAddressData.full_address.split(";").length > 0 ? editAddressData.full_address.split(";")[0] : "",
            valueBuilding: editAddressData.full_address.split(";").length > 1 ? editAddressData.full_address.split(";")[1] : "",
            valueLandmark: editAddressData.full_address.split(";").length > 2 ? editAddressData.full_address.split(";")[2] : "",
            stateL: editAddressData.tag === "Home" ? {backgroundColor: '#EA5B2F', color: '#FFFFFF'} : {backgroundColor: '#F2F2F2', color: '#000000'},
            stateR: editAddressData.tag === "Office" ? {backgroundColor: '#EA5B2F', color: '#FFFFFF'} : {backgroundColor: '#F2F2F2', color: '#000000'},
            stateM: editAddressData.tag === "Others" ? {backgroundColor: '#EA5B2F', color: '#FFFFFF'} : {backgroundColor: '#F2F2F2', color: '#000000'},
            selectedType: editAddressData.tag,
            isDefault: editAddressData.is_default,
            valueAddressError: "",
            valueFlatError: "",
            valueBuildingError: "",
            valueLandmarkError: "",
            addressTypeError: "",
            saveAddressText: "Save Address"
        });
        this.showEditAddress();
    }

    addAddressItem(){
        var isDefault = false;
        if (this.state.addresses.length === 0){
            isDefault = true;
        }
        this.setState({
            address: "",
            valueAddress: "",
            valueAddressError: "",
            valueFlat: "",
            valueFlatError: "",
            valueBuilding: "",
            valueBuildingError: "",
            valueLandmark: "",
            valueLandmarkError: "",
            addressTypeError: "",
            stateL: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateR: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateM: {backgroundColor: '#F2F2F2', color: '#000000'},
            selectedType: "",
            saveAddressText: "Save Address",
            isDefault: isDefault
        });
        this.showAddAddress();
    }
    
    showAddressCard(){
        this.setState({
            addressCardsVisibility: true,
            chooseDefAddressVisibility: false,
            editAddressVisibility: false,
            addAddressVisibility: false,
        });
    }
    
    showChooseDefAddress(){
        this.setState({
            addressCardsVisibility: false,
            chooseDefAddressVisibility: true,
            editAddressVisibility: false,
            addAddressVisibility: false,
        });
    }
    
    showEditAddress(){
        this.setState({
            addressCardsVisibility: false,
            chooseDefAddressVisibility: false,
            editAddressVisibility: true,
            addAddressVisibility: false,
        }, () => this.getDefaultLocation());
    }

    showAddAddress(){
        this.setState({
            addressCardsVisibility: false,
            chooseDefAddressVisibility: false,
            editAddressVisibility: false,
            addAddressVisibility: true,
        }, () => this.getDefaultLocation());
    }

    handleChangeL(){
        this.setState({
            stateL: {backgroundColor: '#EA5B2F', color: '#FFFFFF'},
            stateR: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateM: {backgroundColor: '#F2F2F2', color: '#000000'},
            selectedType: "Home",
        });
    }
    
    handleChangeR(){
        this.setState({
            stateL: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateR: {backgroundColor: '#EA5B2F', color: '#FFFFFF'},
            stateM: {backgroundColor: '#F2F2F2', color: '#000000'},
            selectedType: "Office",
        });
    }
    
    handleChangeM(){
        this.setState({
            stateL: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateR: {backgroundColor: '#F2F2F2', color: '#000000'},
            stateM: {backgroundColor: '#EA5B2F', color: '#FFFFFF'},
            selectedType: "Others",
        });
    }
    
    updateDefaultAddress(address){
        console.log('address', address);
        const editAddressData = address;
        this.setAddressAddingState(editAddressData, true)
        var req={
            "action": "edit",
            "id": editAddressData.id,
            "cart_id": JSON.parse(localStorage.getItem('cartDetailsAPI'))["id"],
            "address": editAddressData.address.substring(0, 100) ,
            "full_address": editAddressData.full_address,
            "state": 1,
            "city": 1,
            "latitude": editAddressData.latitude,
            "longitude": editAddressData.longitude,
            "tag": editAddressData.tag,
            "is_default": true,
        };
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            var headers = {
                'Authorization' : 'Bearer ' + token,
            }
            var url = CONSTANTS.hostURL + "api/geo/location/";
            axios.post(url, req, {
                headers: headers
            }).then((response) => {
                var resData = response.data;
                this.setState({
                    saveAddressText: "Save Address"
                });
                if (resData.status === 1){
                    console.log('res data', resData.data);
                    this.showChooseDefAddress();
                    this.ChooseDefAddress();
                    if(!this.state.isMobileScreen){
                        this.props.showPaymentCard();
                        this.getUserAddress();
                        this.props.getUserAddress();
                    }
                    else{
                        this.props.getSelectedAddrMob(resData.data.id)
                    }
                    this.setAddressAddingState(editAddressData, false)
                    //this.props.editAddressItem(resData.data, "defaultUpdate");
                }
            }).catch((error) => {this.setState({
                saveAddressText: "Save Address"
            }); 
            this.showChooseDefAddress();
            this.setAddressAddingState(editAddressData, false)
            });
        }
        else{
            this.setAddressAddingState(editAddressData, false)
        }
    }

    deleteAddress(address){
        //const deleteAddressData = this.state.addresses[itemNo];
        var req={
            "action": "delete",
            "id": address.id,
        };
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            var headers = {
                'Authorization' : 'Bearer ' + token,
            }
            var url = CONSTANTS.hostURL + "api/geo/location/";
            axios.post(url, req, {
                headers: headers
            }).then((response) => {
                var resData = response.data;
                this.setState({
                    saveAddressText: "Save Address"
                });
                if (resData.status === 1){
                    //this.props.editAddressItem(address.id, "delete");
                    this.showChooseDefAddress();
                    this.getUserAddress();
                    this.props.getUserAddress();
                    this.ChooseDefAddress();
                }
                this.showChooseDefAddress();
                this.getUserAddress();
                this.props.getUserAddress();
                this.ChooseDefAddress();
            }).catch((error) => this.setState({
                saveAddressText: "Save Address"
            }), this.showChooseDefAddress());
        }
    }
    
    updateEditAddress(newAddr){
        let errorPresent = false;
        if (this.state.valueAddress === ''){
            this.setState({
                valueAddressError: "Address is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueAddressError: ""
            })
        }
        if (this.state.valueFlat === ''){
            this.setState({
                valueFlatError: "Flat/Door No is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueFlatError: ""
            })
        } 
        if (this.state.valueBuilding === ''){
            this.setState({
                valueBuildingError: "Building Name is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueBuildingError: ""
            })
        } 
        if (this.state.valueLandmark === ''){
            this.setState({
                valueLandmarkError: "Landmark is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueLandmarkError: ""
            })
        }
        if (this.state.selectedType === ''){
            this.setState({
                addressTypeError: "Choose an Address Type"
            })
            errorPresent = true;
        } else {
            this.setState({
                addressTypeError: ""
            })
        }

        if (errorPresent === true){
            return;
        } else {
            if (this.state.valueId !== -1){
                this.setState({
                    valueAddressError: "",
                    valueFlatError: "",
                    valueBuildingError: "",
                    valueLandmarkError: "",
                    addressTypeError: "",
                    saveAddressText: "Saving Address..."
                });
                var req={
                    "action": "edit",
                    "id": this.state.valueId,
                    "cart_id": JSON.parse(localStorage.getItem('cartDetailsAPI'))["id"],
                    "address": this.state.valueAddress.substring(0, 100) ,
                    "full_address": this.state.valueFlat +';'+ this.state.valueBuilding +';'+ this.state.valueLandmark,
                    "state": 1,
                    "city": 1,
                    "latitude": this.state.center.lat,
                    "longitude": this.state.center.lng,
                    "tag": this.state.selectedType,
                    "is_default": this.state.isDefault,
                };
                var token = localStorage.getItem("auth_token");
                if (token !== null && token !== ""){
                    var headers = {
                        'Authorization' : 'Bearer ' + token,
                    }
                    var url = CONSTANTS.hostURL + "api/geo/location/";
                    axios.post(url, req, {
                        headers: headers
                    }).then((response) => {
                        var resData = response.data;
                        this.setState({
                            saveAddressText: "Save Address"
                        });
                        if (resData.status === 1){
                            //this.props.editAddressItem(resData.data, "");
                            this.showChooseDefAddress();
                            this.getUserAddress();
                            this.props.getUserAddress();
                            this.ChooseDefAddress();
                        }
                    }).catch((error) => this.setState({
                        saveAddressText: "Save Address"
                    }), this.showChooseDefAddress());
                }
            }
        }
    }
    
    changeButtonName(){
        this.setState({buttonName: "Verifying with Resto"});
        setTimeout(() => {
            this.setState({buttonName: "Order Accepted by Resaurant"});
        }, 3000); 
        setTimeout(() => {
            this.setState({
                buttonName: "Proceed to Payment",
                requestBtnState: false,
                paymentBtnState: true,
            });
        }, 2000); 
    }

    DefaultAddress(){
        return(
            <div>
                <Grid container spacing={2} style={{paddingLeft:"8px", paddingRight: this.state.isMobileScreen ? "8px" : "24px", paddingBottom: this.state.isMobileScreen ? "150px" : "0px"}}>
                    {this.state.addressCardsVisibility && this.AddressCards()}
                    {this.state.chooseDefAddressVisibility && this.ChooseDefAddress()}
                    {this.state.editAddressVisibility && this.EditAddress()}
                    {this.state.addAddressVisibility && this.AddAddress()}
                </Grid>
            </div>
        );
    }

    setMapCenter(){
        if(this.state.addresses != null && this.state.addresses.length > 0){
            for(let i=0; i<this.state.addresses.length; i++){
                if(this.state.addresses[i].is_default === true){
                    this.setState({
                        locationFromStorage: {
                            lat: this.state.addresses[i].latitude, 
                            lng: this.state.addresses[i].longitude
                        },
                        center: {
                            lat: this.state.addresses[i].latitude, 
                            lng: this.state.addresses[i].longitude
                        },
                    });
                }
            }
        }
    }

    AddressCards(){
        if(this.state.addresses != null && this.state.addresses.length > 0){
            const allAddress = this.state.addresses.map((keyItem) =>
                <>
                {keyItem.is_default === true &&
                <Grid key={"UserAddr_"+keyItem.id} item xs={12} style={{paddingBottom:"35px"}}>
                    <div className="address-cards" style={{height:"110px"}}>
                        <LocationOnIcon fontSize="small" className="address-icon" /> <span className="address-type" onClick={()=>{
                            this.setState({
                                locationFromStorage: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                                center: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                            });
                        }}>{keyItem.tag}</span>
                        {/* <span className="defaultAddrTxt" onClick={() => this.showChooseDefAddress()}> Change Address </span> */}
                        <div style={{height:"80px"}}>
                            <div className="address-line">{keyItem.address}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 0 ? keyItem.full_address.split(";")[0] : ""}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 1 ? keyItem.full_address.split(";")[1] : ""}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 2 ? "Near " + keyItem.full_address.split(";")[2] : ""}</div>
                        </div>
                    </div>
                </Grid>
                }
                </>
            );
            return(
                <>
                    <Grid item xs={12} style={{fontFamily:"Poppins-Medium", fontSize:"15px", paddingLeft:"5px", paddingTop:"50px"}}>
                        Deliver to
                        <span style={{height:"16px", float:"right", top:"4px", position:"relative"}} className="chooseAddr" onClick={() => this.showChooseDefAddress()}>Change or Add Address</span>
                    </Grid>
                    
                    {allAddress}
                    {/* <Grid item xs={12} className="cart-buttons">
                        {this.state.requestBtnState && <span className="cart-buttons-text" onClick={() => this.changeButtonName()}>{this.state.buttonName}</span>}
                        {this.state.paymentBtnState && <span className="cart-buttons-text" onClick={() => this.props.goToPaymentCard()}>{this.state.buttonName}</span>}
                    </Grid> */}
                    {/* <Grid item xs={12} style={{textAlign:"center", paddingBottom:"10px"}}>
                        <span style={{width:"100%"}} className="ddCartBtns" onClick={this.props.showPaymentCard}>CONFIRM ADDRESS & PROCEED</span>
                    </Grid> */}
                </>
            );
        }
        else{
            return(
                <>{this.AddAddress()}</>
            );
        }
    }

    updateDeliveryMode(address){
        this.setAddressAddingState(address, true)
        var req={
            "mode": "DELIVERY",
            "cart_id": JSON.parse(localStorage.getItem('cartDetailsAPI'))["id"],
            "address_id": address.id , 
        };
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            var headers = {
                'Authorization' : 'Bearer ' + token,
            }
            var url = CONSTANTS.hostURL + "api/main_app/cart/update-delivery-mode/";
            axios.post(url, req, {
                headers: headers
            }).then((response) => {
                let data = response.data;
                if (data.status === 1){
                    localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                    this.props.getUserAddress();
                    if(!this.state.isMobileScreen)
                        this.props.showPaymentCard();
                    this.props.getCartList();
                    this.setAddressAddingState(address, false)
                }
                else{
                    this.props.toggleConfirmationBox("598:" + data.errors.message);
                    this.setAddressAddingState(address, false)
                }
            }).catch((error) => this.setAddressAddingState(address, false));
        }
    }

    getIndex(address){
        for(let i=0; i<this.state.delivers_to.length; i++){
            if(this.state.delivers_to[i] === address.id)
                return i
        }
    }

    ChooseDefAddress(){
        const allAddress = this.state.addresses.map((keyItem) =>
            <Grid key={"DefAddr_"+keyItem.id} item xs={ this.state.isMobileScreen ? 12 : 6}>
                {this.state.isMobileScreen ? 
                    <div className={this.state.delivers_to.includes(keyItem.id) ? "" : "address-cards1M-disabled"} style={{position:"relative", border: "#E8E8E8 1px solid", borderRadius: '12px', fontSize: '18px', paddingBottom: '8px', backgroundColor: this.state.dataFetched && !this.state.delivers_to.includes(keyItem.id) ? '#f1f0f0' : 'white'}}>
                        <LocationOnIcon fontSize="small" className="address-icon" /> <span className="address-type" onClick={()=>{
                            this.setState({
                                locationFromStorage: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                                center: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                            });
                        }}>{keyItem.tag} {this.state.dataFetched && !this.state.delivers_to.includes(keyItem.id) && "(UNDELIVERABLE)" }</span>

                        {/* <span className="addressEditBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.editingItem(keyItem);
                        }}>
                            <div className="optionTooltip"><RiEdit2Fill style={{ fontSize: 14}}/>
                                <span className="optionTooltiptext">Edit Address</span>
                            </div>
                        </span>
                        <span className="addressDeleteBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.deleteAddress(keyItem);
                        }}>
                            <div className="optionTooltip"><RiDeleteBin6Line style={{ fontSize: 14}}/>
                                <span className="optionTooltiptext">Delete Address</span>
                            </div>        
                        </span> */}

                        <div className="ddAddressBody" style={{width:"calc(100vw - 100px)",overflowY:"scroll"}}>
                            <div style={{fontSize: '14px'}} className="address-line">{keyItem.address.length > 40 ? `${keyItem.address.substring(0, 40)}..` : keyItem.address}</div>
                            <div className="address-line" style={{flexDirection: 'row', display: 'flex', fontFamily: 'Poppins-SemiBold', fontSize: '15px', color: '#EA5B2F', marginTop: '8px'}}>
                                <div onClick={()=>{
                                    this.editingItem(keyItem);
                                }} style={{marginRight: '10px', cursor: 'pointer'}}>EDIT</div>
                                <div onClick={()=>{
                                    this.deleteAddress(keyItem);
                                }}style={{cursor: 'pointer'}}>DELETE</div>
                            </div>
                            {/* <div className="address-line">{keyItem.full_address.split(";").length > 0 ? keyItem.full_address.split(";")[0] : ""}</div> */}
                            {/* <div className="address-line">{keyItem.full_address.split(";").length > 1 ? keyItem.full_address.split(";")[1] : ""}</div> */}
                            {/* <div className="address-line">{keyItem.full_address.split(";").length > 2 ? "Near " + keyItem.full_address.split(";")[2] : ""}</div> */}
                        </div>
                        <div style={{ position:"absolute", right:"0px", left:"0px", top: "80px" }}>
                            {this.state.delivers_to.includes(keyItem.id) ? 
                                    !this.state.addressSelectingState[keyItem.id] ? <span 
                                        style={{width:"10px", position:"absolute", bottom:"31px", right:"10px", padding: '7px', transform: 'translateY(10px)', borderColor: 'transparent'}} 
                                        className={this.props.selectedAddr === keyItem.id ? "ddPopOverCartBtn1M" : "ddPopOverCartBtnFlatM"}
                                        onClick={() => {
                                            // this.updateDeliveryMode(keyItem.id);
                                            this.updateDefaultAddress(keyItem);}
                                        }
                                    ></span> : <img src={ButtonLoading} alt="loading" style={{width:"40px", position:"absolute", bottom:"31px", right:"10px"}}/>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                    :
                    <div className={this.state.delivers_to.includes(keyItem.id) ? "address-cards1" : "address-cards1-disabled"} style={{height:"160px", padding:"5px 10px 0px 5px", position:"relative"}}>
                        <LocationOnIcon fontSize="small" className="address-icon" /> <span className="address-type" onClick={()=>{
                            this.setState({
                                locationFromStorage: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                                center: {
                                    lat: keyItem.latitude, 
                                    lng: keyItem.longitude
                                },
                            });
                        }}>{keyItem.tag}</span>
                        {keyItem.is_default === true ? <>
                        {/* <span className="defaultDel" style={{height:"16px", float:"right"}}>
                            <DoneOutlineIcon style={{ fontSize: 14}}/><span className="addressEditBtnIcon">Deliver Here</span></span> */}
                        <span className="addressEditBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.editingItem(keyItem);
                            }}>
                                <div className="optionTooltip"><RiEdit2Fill style={{ fontSize: 14}}/>
                                    <span className="optionTooltiptext">Edit Address</span>
                                </div>
                            </span>
                        <span className="addressDeleteBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.deleteAddress(keyItem);
                            }}>
                            <div className="optionTooltip"><RiDeleteBin6Line style={{ fontSize: 14}}/>
                                <span className="optionTooltiptext">Delete Address</span>
                            </div>        
                        </span>    
                        </>
                        : <>
                        {/* <span className="defaultDel2" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.updateDefaultAddress(this.state.addresses.indexOf(keyItem));
                            }}><DoneOutlineIcon style={{ fontSize: 14}}/><span className="addressEditBtnIcon">Deliver Here</span></span> */}
                        <span className="addressEditBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.editingItem(keyItem);
                            }}>
                                <div className="optionTooltip"><RiEdit2Fill style={{ fontSize: 14}}/>
                                    <span className="optionTooltiptext">Edit Address</span>
                                </div>    
                            </span>
                        <span className="addressDeleteBtn" style={{height:"16px", float:"right"}} onClick={()=>{
                                this.deleteAddress(keyItem);
                            }}>
                            <div className="optionTooltip"><RiDeleteBin6Line style={{ fontSize: 14}}/>
                                <span className="optionTooltiptext">Delete Address</span>
                            </div>
                        </span>   
                        </>
                        }
                        <div className="ddAddressBody" style={{height:"75px", overflowY:"scroll"}}>
                            <div className="address-line">{keyItem.address}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 0 ? keyItem.full_address.split(";")[0] : ""}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 1 ? keyItem.full_address.split(";")[1] : ""}</div>
                            <div className="address-line">{keyItem.full_address.split(";").length > 2 ? "Near " + keyItem.full_address.split(";")[2] : ""}</div>
                        </div>
                        {this.state.dataFetched && <div style={{textAlign:"center", position:"absolute", right:"0px", left:"0px"}}>
                            {keyItem.is_default === true ?
                                
                                this.state.delivers_to.includes(keyItem.id) ? 
                                    !this.state.addressSelectingState[keyItem.id] ?
                                        <span 
                                            style={{width:"70%", position:"absolute", left:"31px", bottom:"-40px"}} 
                                            className="ddPopOverCartBtn1"
                                            onClick={() => {
                                                this.updateDeliveryMode(keyItem);
                                            }}
                                        >
                                            DELIVER HERE
                                        </span>
                                        :
                                        <img src={ButtonLoading} alt="loading" style={{width:"70px", position:"relative", top:"12px", height:"18px"}}/>
                                    :
                                    <span 
                                        style={{width:"70%", position:"absolute", left:"31px", bottom:"-40px"}} 
                                        className="ddPopOverCartBtn1"
                                    >
                                        UNDELIVERABLE
                                    </span>
                                :
                                this.state.delivers_to.includes(keyItem.id) ? 
                                    !this.state.addressSelectingState[keyItem.id] ?
                                        <span 
                                            style={{width:"70%", position:"absolute", left:"31px", bottom:"-40px"}} 
                                            className="ddPopOverCartBtnFlat"
                                            onClick={()=>{
                                                this.updateDeliveryMode(keyItem)
                                                this.updateDefaultAddress(keyItem);
                                            }}
                                        >
                                            DELIVER HERE
                                        </span>
                                        :
                                        <img src={ButtonLoading} alt="loading" style={{width:"70px", position:"relative", top:"12px", height:"18px"}}/>
                                    :
                                    <span 
                                        style={{width:"70%", position:"absolute", left:"31px", bottom:"-40px"}} 
                                        className="ddPopOverCartBtnFlat"
                                    >
                                        UNDELIVERABLE
                                    </span>
                            }
                        </div>}
                    </div>
                }
            </Grid>
        );
        return(
            <Grid container spacing={2} style={this.state.isMobileScreen ? {marginTop:"10px"} : {marginTop:"40px", height:"330px"}}>
                <Grid item xs={this.state.isMobileScreen ? 12 : 6}>
                    {this.state.isMobileScreen ?
                    <>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <div style={{fontFamily:"Poppins-Regular", letterSpacing: '0.01rem', fontSize:"18px", margin: '2px 0px', flex: 1}}>Select Delivery Address</div>
                            <div onClick={() => this.addAddressItem()} style={{fontFamily:"Poppins-SemiBold", color: '#EA5B2F', fontSize:"15px", cursor: 'pointer', margin: '2px 0px'}}>ADD NEW</div>
                        </div>
                        {/* <div style={{fontFamily:"Poppins-Regular", fontSize:"13px"}}>Or skip this step and choose <span onClick={() => this.props.switchToPickup()} style={{color: '#3356aa', textDecoration: 'underline', cursor: 'pointer', fontWeight: 600}}>Self Pickup</span></div> */}
                    </>
                    // <div onClick={() => this.addAddressItem()} style={{border:"#E8E8E8 1px solid"}}>
                    //     <MdAddLocationAlt fontSize="small" className="address-icon" style={{color:"#FCD844"}}/> <span className="address-type">Add New Address</span>
                    //     {/* <div style={{textAlign:"center"}}><span style={{width:"70%", marginTop:"55px", marginLeft:"5px"}} className="ddPopOverCartBtnFlat" onClick={() => this.addAddressItem()}>ADD NEW</span></div> */}
                    // </div>
                    :
                    <div className="address-cards" style={{height:"160px", padding:"5px 10px 0px 5px"}}>
                        <MdAddLocationAlt fontSize="small" className="address-icon" style={{color:"#FCD844"}}/> <span className="address-type">Add New Address</span>
                        <div className="address-line">Add a new address and save it for later</div>
                        <div style={{textAlign:"center"}}><span style={{width:"70%", marginTop:"55px", marginLeft:"5px"}} className="ddPopOverCartBtnFlat" onClick={() => this.addAddressItem()}>ADD NEW</span></div>
                    </div>
                    }
                </Grid>
                {allAddress}
                {/* <Grid item xs={12} style={{textAlign:"center", paddingBottom:"10px"}}>
                    <span style={{width:"100%"}} className="ddCartBtns" onClick={() => this.showAddressCard()}>Save Changes</span>
                </Grid> */}
            </Grid>
        );
    }

    EditAddress(){
        return(
            <>
                <div style={this.state.isMobileScreen ? {paddingTop:"10px", width:"100%"} : {paddingTop:"10px"}}>
                    <Grid item xs={12}>
                        <span className="textBoxHeader" style={{zIndex:"12"}}>Address</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input disabled={true} type="text" value={this.state.valueAddress} placeholder="Address" className="addressText" onChange={e => this.setState({valueAddress: e.target.value})}/>
                            {this.state.valueAddressError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "4px"}}>{this.state.valueAddressError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Flat/Door No.</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueFlat} placeholder="Flat/Door No." className="addressText" onChange={e => this.setState({valueFlat: e.target.value})}/>
                            {this.state.valueFlatError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "4px"}}>{this.state.valueFlatError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Building Name</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueBuilding} placeholder="Building Name" className="addressText" onChange={e => this.setState({valueBuilding: e.target.value})}/>
                            {this.state.valueBuildingError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "4px"}}>{this.state.valueBuildingError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Landmark</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueLandmark} placeholder="Landmark" className="addressText" onChange={e => this.setState({valueLandmark: e.target.value})}/>
                            {this.state.valueLandmarkError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "4px"}}>{this.state.valueLandmarkError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px", paddingBottom:"10px"}}>
                        <div className="addressTypeBtnGrp">
                            {this.state.addressTypeError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "4px"}}>{this.state.addressTypeError}</div>}
                            <span onClick={() => this.handleChangeL()} style={this.state.stateL} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><HomeIcon style={{ fontSize: 16 }} className="delIcon"/>Home</span>
                            <span onClick={() => this.handleChangeR()} style={this.state.stateR} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><BusinessIcon style={{ fontSize: 16 }} className="delIcon"/>Office</span>
                            <span onClick={() => this.handleChangeM()} style={this.state.stateM} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><PinDropIcon style={{ fontSize: 16 }} className="delIcon"/>Others</span>
                        </div>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/* <div style={{float: 'right'}} className="cardCancelButton"onClick={() => this.showChooseDefAddress()}>Cancel</div> */}
                            <span style={this.state.isMobileScreen ? {float:"right", width:"157px"} : {float:"right", width:"208px"}} className="ddCartCancelBtns" onClick={() => this.showChooseDefAddress()}>Cancel</span>
                        </Grid>
                        <Grid item xs={6}>
                            {/* <div style={{float: 'left'}} className="cardSaveButton">{this.state.saveAddressText}</div> */}
                            <span onClick={()=>{this.updateEditAddress()}} style={this.state.isMobileScreen ? {float:"left", width:"157px"} : {float:"left", width:"205px"}} className="ddAddCartBtn">{this.state.saveAddressText}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    addNewAddress(newAddr){
        let errorPresent = false;
        if (this.state.valueAddress === ''){
            this.setState({
                valueAddressError: "Address is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueAddressError: ""
            })
        }
        if (this.state.valueFlat === ''){
            this.setState({
                valueFlatError: "Flat/Door No is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueFlatError: ""
            })
        } 
        if (this.state.valueBuilding === ''){
            this.setState({
                valueBuildingError: "Building Name is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueBuildingError: ""
            })
        } 
        if (this.state.valueLandmark === ''){
            this.setState({
                valueLandmarkError: "Landmark is required"
            })
            errorPresent = true;
        } else {
            this.setState({
                valueLandmarkError: ""
            })
        }
        if (this.state.selectedType === ''){
            this.setState({
                addressTypeError: "Choose an Address Type"
            })
            errorPresent = true;
        } else {
            this.setState({
                addressTypeError: ""
            })
        }

        if (errorPresent === true){
            return;
        } else {
            this.setState({
                valueAddressError: "",
                valueFlatError: "",
                valueBuildingError: "",
                valueLandmarkError: "",
                addressTypeError: "",
            });
            var isDefault = false;
            if (this.state.addresses.length === 0){
                isDefault = true;
            }
            var req={
                "action": "add",
                "cart_id": JSON.parse(localStorage.getItem('cartDetailsAPI'))["id"],
                "address": this.state.valueAddress.substring(0, 100) , 
                "full_address": this.state.valueFlat +';'+ this.state.valueBuilding +';'+ this.state.valueLandmark,
                "state": 1,
                "city": 1,
                "latitude": this.state.center.lat,
                "longitude": this.state.center.lng,
                "tag": this.state.selectedType,
                "is_default": isDefault,
            };
            var token = localStorage.getItem("auth_token");
            if (token !== null && token !== ""){
                var headers = {
                    'Authorization' : 'Bearer ' + token,
                }
                var url = CONSTANTS.hostURL + "api/geo/location/";
                axios.post(url, req, {
                    headers: headers
                }).then((response) => {
                    var resData = response.data;
                    this.setState({
                        saveAddressText: "Save Address"
                    });
                    if (resData.status === 1){
                        //this.props.editAddressItem(resData.data, "");
                        this.showChooseDefAddress();
                        this.getUserAddress();
                        this.props.getUserAddress();
                        this.ChooseDefAddress();
                    }
                }).catch((error) => this.setState({
                    saveAddressText: "Save Address"
                }), this.showChooseDefAddress());
            }
        }
    }

    getUserAddress(){
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
          var headers = {
            'Authorization' : 'Bearer ' + token,
          }
          var url = CONSTANTS.hostURL + "api/geo/location/";
          axios.get(url, {
            headers: headers
          }).then((response) => {
              var resData = response.data;
              let addressSelectingState = this.state.addressSelectingState;
              if (resData.status === 1){
                this.setState({addresses : resData.data.locations});
                var restoID = JSON.parse(localStorage.getItem('cartDetailsAPI')).restaurant.id;
                let addrListString = ""
                for(let i=0; i<resData.data.locations.length; i++){
                    addrListString = addrListString + resData.data.locations[i].id + ","
                }

                if(addrListString.length > 0){
                    var url = CONSTANTS.hostURL + "api/main_app/restaurant/deliverability?resto_id=" + restoID + "&address_ids=" + addrListString.substring(0, addrListString.length-1);
                    axios.get(url).then((response) => {
                        var resData = response.data;
                        if (resData.status === 1){
                            for(var k=0; k<resData.data.delivers_to.length; k++){
                                addressSelectingState[resData.data.delivers_to[k]] = false;
                            }
                            this.setState({
                                delivers_to: resData.data.delivers_to,
                                addressSelectingState: addressSelectingState,
                                dataFetched: true
                            }, () => this.props.setDataFetched())
                        }
                    });
                }
                
              } else {
                this.setState({addresses : []});
              }
          });
        }
    }

    setAddressAddingState(address, trueOrFalse){
        console.log('addrss', address.id);
        console.log('trueOrFalse', trueOrFalse);
        const updateAddress = {[address.id]: trueOrFalse};
        console.log('final state', {...this.state.addressSelectingState, ...updateAddress});
        this.setState({
            addressSelectingState: {...this.state.addressSelectingState, ...updateAddress}
        });
    }

    AddAddress(){
        return(
            <>
                <div style={this.state.isMobileScreen ? {paddingTop:"10px", width:"100%"} : {paddingTop:"10px"}}>
                    <Grid item xs={12}>
                        <span className="textBoxHeader" style={{zIndex:"12"}}>Address</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input disabled={true} type="text" value={this.state.valueAddress} placeholder="Address" className="addressText" onChange={e => this.setState({valueAddress: e.target.value})}/>
                            {this.state.valueAddressError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "-3px", marginLeft: "12px"}}>{this.state.valueAddressError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Flat/Door No.</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueFlat} placeholder="Flat/Door No." className="addressText" onChange={e => this.setState({valueFlat: e.target.value})}/>
                            {this.state.valueFlatError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "-3px", marginLeft: "12px"}}>{this.state.valueFlatError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Building Name</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueBuilding} placeholder="Building Name" className="addressText" onChange={e => this.setState({valueBuilding: e.target.value})}/>
                            {this.state.valueBuildingError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "-3px", marginLeft: "12px"}}>{this.state.valueBuildingError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px"}}>
                        <span className="textBoxHeader">Landmark</span>
                        <div className={this.state.isMobileScreen ? "editAddressM" : "editAddress"}>
                            <input type="text" value={this.state.valueLandmark} placeholder="Landmark" className="addressText" onChange={e => this.setState({valueLandmark: e.target.value})}/>
                            {this.state.valueLandmarkError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "-3px", marginLeft: "12px"}}>{this.state.valueLandmarkError}</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-24px", paddingBottom:"10px"}}>
                        <div className="addressTypeBtnGrp">
                            {this.state.addressTypeError !== "" && <div style={{color: "red", fontSize: "12px", marginTop: "-3px", marginLeft: "12px"}}>{this.state.addressTypeError}</div>}
                            <span onClick={() => this.handleChangeL()} style={this.state.stateL} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><HomeIcon style={{ fontSize: 16 }} className="delIcon"/>Home</span>
                            <span onClick={() => this.handleChangeR()} style={this.state.stateR} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><BusinessIcon style={{ fontSize: 16 }} className="delIcon"/>Office</span>
                            <span onClick={() => this.handleChangeM()} style={this.state.stateM} className={this.state.isMobileScreen ? "addressTypeM" : "addressType"}><PinDropIcon style={{ fontSize: 16 }} className="delIcon"/>Others</span>
                        </div>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/* <div style={{float: 'right'}} className="cardCancelButton" onClick={() => this.showChooseDefAddress()}>Cancel</div> */}
                            <span style={this.state.isMobileScreen ? {float:"right", width:"157px"} : {float:"right", width:"208px"}} className="ddCartCancelBtns" onClick={() => this.showChooseDefAddress()}>Cancel</span>
                        </Grid>
                        <Grid item xs={6}>
                            {/* <div style={{float: 'left'}} className="cardSaveButton"></div> */}
                            <span onClick={()=>{this.addNewAddress()}} style={this.state.isMobileScreen ? {float:"left", width:"157px"} : {float:"left", width:"205px"}} className="ddAddCartBtn">{this.state.saveAddressText}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    render(){
        return(
            <div className="ddOrderDivScroll" style={this.state.isMobileScreen ? {marginTop:"10px", maxHeight:"70vh"} : {marginTop:"10px", maxHeight:"700px", overflowY:"scroll"}}>
                <Grid container spacing={2} style={{position:"relative", top:"10px"}}>
                    {/* <span className="ddCartBackBtns" style={{top:"10px", left:"28px", position:"relative"}} onClick={this.props.showDeliveryModeCard}><IoMdArrowRoundBack style={{top:"2px", position:"relative", marginRight:"5px"}}/>Back to Delivery Mode</span> */}
                    
                    {(this.state.editAddressVisibility || this.state.addAddressVisibility) && 
                    <>
                        <div style={{fontFamily:"Poppins-Medium", fontSize:"15px", marginLeft:"15px", paddingTop:"20px"}}>
                            {this.state.editAddressVisibility ? "Edit Address" : "Add Address"}
                        </div>
                        <Grid item xs={12}>
                            <div style={this.state.isMobileScreen ? {width:"100%"} : {}} className="map-dimension">
                                <Map 
                                    ref={this.refMap}
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    google={this.props.google} 
                                    zoom={15}
                                    streetViewControl={false}
                                    mapTypeControl={false}
                                    center={this.state.locationFromStorage}
                                    onBoundsChanged={() => this.handleBoundsChanged()}>
                                    <Marker name={'Current location'} 
                                        position={this.state.center}
                                        className="mapMarkerTransition"/>
                                </Map>
                            </div>
                        </Grid>
                    </>}
                </Grid>
                {this.DefaultAddress()}
            </div>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: ("AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4")
})(DeliveryAddressCard)