import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import image07 from '../../assets/images/07_1.png';
import home_burger from '../../assets/images/landing_page_illustration12.png';
import '../../assets/styles/AboutUs.css';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import Geocode from "react-geocode";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { withRouter } from 'react-router';

Geocode.setApiKey('AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4');
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

class Segment4 extends Component {

    constructor(props){
        super(props);
        this.toggleAuthModalHandler = this.toggleAuthModalHandler.bind(this);
        this.setActiveFormHandler = this.setActiveFormHandler.bind(this);
    }

    toggleAuthModalHandler() {
        this.props.toggleAuthModal();
    }

    setActiveFormHandler(form){
        this.props.setActiveForm(form);
    }

    render() {
        return (
            <div className="Content">
                <GreyContainer 
                    toggleAuthModal={this.toggleAuthModalHandler}
                    setActiveForm={this.setActiveFormHandler}
                    history={this.props.history}
                />
            </div>
        )
    }
}

class LocationWebSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address: '',
            latitude: '',
            longitude: '',
            isMobileScreen: false,
            locationErrorMessage: '',
            clearUserSuggestions: false
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.hitLocationAPI = this.hitLocationAPI.bind(this);
        this.showLocationError = this.showLocationError.bind(this);
    }
    
    handleChange = address => {
        var lenDiff = address.length - this.state.address.length;
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address
        })
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    handleSelect = address => {
        this.setState({
            address: address,
            clearUserSuggestions: true
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        }, this.setLocation)
    }

    setLocation(){
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            localStorage.setItem("user_address", this.state.address);
            this.props.history.push('/home');
        }
    }

    getCurrentLocation(){
        this.setState({
            locationErrorMessage: "Getting current location, please wait..."
        });
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitLocationAPI, this.showLocationError);
        } else {
            this.setState({
                locationErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser"
            });
        }
    }

    hitLocationAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              localStorage.setItem("user_latitude", this.state.latitude);
                localStorage.setItem("user_longitude", this.state.longitude);
                localStorage.setItem("location_present", "yes");
                localStorage.setItem("user_address", address);
                this.props.history.push('/home');
            }
        );
    }

    showLocationError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                locationErrorMessage: "Please enable the location access to proceed further",
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
            });
            break;
          case error.TIMEOUT:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay"
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
          default:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
        }
    }

    handleKeyPress = (event) => {
        // if (event.keyCode === 8 || event.keyCode === 46){
        //     this.setState({
        //         clearUserSuggestions: true
        //     })
        // } else {
        //     this.setState({
        //         clearUserSuggestions: false
        //     })
        // }
    }

    showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading){
        if (this.state.address.length > 1 && !this.state.clearUserSuggestions){
            return (
                <div className="autocomplete-dropdown-container PlaceSuggestn"
                    style={{
                        width: "27vw",
                        marginLeft: "3vw",
                        backgroundColor: "#F0F0F0",
                        zIndex: '0',
                        position: 'absolute',
                        top: '20px',
                        paddingTop: suggestions.length > 0 ? '30px' : '0px',
                        paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        overflow: 'hidden'
                    }}
                >
                {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                    return (<div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        })}
                        key={suggestion.formattedSuggestion.mainText}
                    >
                        <div style={{paddingTop: "2px"}}>
                            <LocationOnIcon fontSize='small'/>
                        </div>
                        <div>
                        <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px'}}>{suggestion.formattedSuggestion.mainText}</div>
                        <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: '12px'}}>{suggestion.formattedSuggestion.secondaryText}</div>
                        </div>
                    </div>)
                })}
            </div>
            )
        } else {
            return null;
        }
    }

    render(){
        return (
            <Grid container item xs={6} direction="column" justifyContent="center" alignItems="center">
                <div style={{position: 'relative'}} className="HeaderDescriptionContainer">
                <div style={{
                        margin: "auto", 
                        width: "35vw", 
                        textAlign: "center", 
                        transform: "translateX(-2vw)",
                        fontFamily: "Poppins-Regular",
                        fontSize: "14px"
                    }}>
                        {this.state.locationErrorMessage}
                    </div>
                    <div style={{
                        paddingTop: '10px', 
                        zIndex: '100', 
                        display: this.props.authModalOpen ? 'none': 'block'
                    }}
                        className="AutoSuggestContainer"
                    >
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            searchOptions={{
                                componentRestrictions: {country: ['in']}
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{position: 'relative', zIndex: 'inherit'}}>
                                <div style={{ zIndex: 'inherit'}} className="LocationContainer">
                                    <input
                                    className="Location"
                                    {...getInputProps({
                                        placeholder: 'Enter location',
                                    })}
                                    onKeyPress={this.handleKeyPress}
                                    style={{
                                        border: "#E6E6E6 1px solid !important",
                                        borderTopLeftRadius: "30px",
                                        borderBottomLeftRadius: "30px",
                                        backgroundColor: "white",
                                        width: "24vw",
                                        padding: "12px 5px",
                                        fontFamily: 'Montserrat-Regular',
                                        fontSize: "14px",
                                        paddingLeft: "1.5vw",
                                        color: "black",
                                        zIndex: 'inherit'
                                    }}
                                    />
                                    <div onClick={() => this.getCurrentLocation()} className="LocationIconContainer">
                                        <span className="LocationIcon">
                                            <GpsFixedIcon className="GPSIcon"/>
                                        </span>
                                    </div>
                                </div>
                                {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                            </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
                {/* <div className="LocationContainer">
                    <input className="Location" type="text" placeholder="Enter location"></input>
                    <span className="LocationIcon">
                        <GpsFixedIcon className="GPSIcon"/>
                    </span>
                </div> */}
            </Grid>
        )
    }
}

class LocationMobileSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address: '',
            latitude: '',
            longitude: '',
            isMobileScreen: false,
            locationErrorMessage: ''
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.hitLocationAPI = this.hitLocationAPI.bind(this);
        this.showLocationError = this.showLocationError.bind(this);
    }
    
    handleChange = address => {
        var lenDiff = address.length - this.state.address.length;
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address,
            isMobileScreen: true
        })
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    handleSelect = address => {
        this.setState({
            address: address,
            clearUserSuggestions: true
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        }, this.setLocation)
    }

    setLocation(){
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            this.props.history.push('/home');
        }
    }

    getCurrentLocation(){
        this.setState({
            locationErrorMessage: "Getting current location, please wait..."
        });
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitLocationAPI, this.showLocationError);
        } else {
            this.setState({
                locationErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser"
            });
        }
    }

    hitLocationAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              localStorage.setItem("user_latitude", this.state.latitude);
                localStorage.setItem("user_longitude", this.state.longitude);
                localStorage.setItem("location_present", "yes");
                localStorage.setItem("user_address", address);
                this.props.history.push('/home');
            }
        );
    }

    showLocationError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                locationErrorMessage: "Please enable the location access to proceed further",
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
            });
            break;
          case error.TIMEOUT:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay"
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
          default:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
        }
    }

    showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading){
        if (this.state.address.length > 1 && !this.state.clearUserSuggestions){
            return (
                <div className="autocomplete-dropdown-container PlaceSuggestn"
                    style={{
                        width: this.state.isMobileScreen ? "76vw" : "27vw",
                        marginLeft: this.state.isMobileScreen ? "12vw" : "3vw",
                        backgroundColor: "#F0F0F0",
                        zIndex: '0',
                        position: 'absolute',
                        top: '20px',
                        paddingTop: suggestions.length > 0 ? '30px' : '0px',
                        paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        overflow: 'hidden'
                    }}
                >
                {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                    return (<div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        })}
                        key={suggestion.formattedSuggestion.mainText}
                    >
                        <div style={{paddingTop: "2px"}}>
                            <LocationOnIcon fontSize='small'/>
                        </div>
                        <div>
                        <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px'}}>{suggestion.formattedSuggestion.mainText}</div>
                        <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: '12px'}}>{suggestion.formattedSuggestion.secondaryText}</div>
                        </div>
                    </div>)
                })}
            </div>
            )
        } else {
            return null;
        }
    }

    render(){
        var gridStyle = {}
        if (this.state.isMobileScreen){
            gridStyle = {};
        } else {
            gridStyle = {display: "none"}
        }
        if (!this.props.authModalOpen){
            return (
                <Grid style={gridStyle} container item xs={6} direction="column" justifyContent="center" alignItems="center">
                    <div className="HeaderDescriptionContainer">
                        <div style={{paddingBottom: "10px"}}>
                            <div style={{
                                margin: "auto", 
                                width: "90vw", 
                                textAlign: "center", 
                                fontFamily: "Poppins-Regular",
                                fontSize: "14px", 
                                marginTop: this.state.locationErrorMessage === '' ? '0px' : '10px'
                            }}>
                                {this.state.locationErrorMessage}
                            </div>
                            <div style={{
                                paddingTop: '10px', 
                                zIndex: '100', 
                                display: this.props.authModalOpen ? 'none': 'block'
                            }}
                                className="AutoSuggestContainer"
                            >
                                <PlacesAutocomplete
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelect}
                                    searchOptions={{
                                        componentRestrictions: {country: ['in']}
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div style={{position: 'relative', zIndex: 'inherit'}}>
                                        <div style={{ zIndex: 'inherit'}} className="LocationContainer">
                                            <input
                                            className="Location"
                                            {...getInputProps({
                                                placeholder: 'Enter location',
                                            })}
                                            style={{
                                                border: "#E6E6E6 1px solid !important",
                                                borderTopLeftRadius: "30px",
                                                borderBottomLeftRadius: "30px",
                                                backgroundColor: "white",
                                                width: "100vw",
                                                padding: "8px 5px",
                                                fontFamily: 'Montserrat-Regular',
                                                fontSize: "14px",
                                                paddingLeft: "15px",
                                                color: "black",
                                                zIndex: 'inherit'
                                            }}
                                            />
                                            <div className="LocationIconContainer">
                                                <span onClick={() => this.getCurrentLocation()} className="LocationIcon">
                                                    <GpsFixedIcon className="GPSIcon"/>
                                                </span>
                                            </div>
                                        </div>
                                        {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                                    </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </div>
                    </div>
                    {/* <div className="LocationContainer">
                        <input className="Location" type="text" placeholder="Enter location"></input>
                        <span className="LocationIcon">
                            <GpsFixedIcon className="GPSIcon"/>
                        </span>
                    </div> */}
                </Grid>
            )
        } else {
            return null;
        }
    }
}

// Grey Container of the content
class GreyContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            buttonID: "signup-button-content",
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ isMobileScreen: false });
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return(
                <div>
                    <Grid spacing={2} style={{marginTop : '30px', marginBottom:'30px'}}>
                        <Grid container spacing={0} direction="row">
                            <div style={{margin: "auto"}}>
                                <h2 className="topText">
                                    How we <span style={{color: "#EA5B2F", marginLeft:'0'}}>Do it?</span>
                                </h2>
                            </div>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                            <img style={{width:'80vw'}} src={image07} alt="image07"/>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                            <div>
                                <div>
                                    <div className="DescriptionTaglineDetails" style={{textAlign: 'center'}}>
                                        Ensuring happiness in the world of food delivery is no joke; it involves a truckload of groundwork. But we're committed to delivering the best food in Bangalore! So whether it's engaging in discussions with talented chefs, collaborating with passionate restaurant owners and trusted food critics, and, of course, savoring the food itself, we invest countless hours to make sure you receive nothing but the best.
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="center">
                            <Button onClick={() => {
                                window.scrollTo(0, 0);
                                this.props.toggleAuthModal();
                                this.props.setActiveForm("register");
                            }} id={this.state.buttonID}>
                                SIGN UP NOW
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{background: "linear-gradient(130deg, #F9D543 66%, #FCF9CF 33%)", paddingTop:'20px'}}>
                        <Grid spacing={2}  direction="row" style={{marginTop : '30px'}}>
                            <Grid container spacing={0} direction="column">
                                <div style={{margin: "auto"}}>
                                    <h2 className="topText">
                                        <span style={{color: "#3e4bad"}}>Ready to eat </span><span style={{color: "#EA5B2F"}}>best food in Bangalore?</span>
                                    </h2>
                                </div>
                            </Grid>
                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                                <img style={{width:'70vw'}}  src={home_burger} alt="home_burger"/>
                            </Grid>
                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                                <div className="DescriptionTaglineDetails" style={{textAlign: 'center'}}>
                                Browse through the top-rated restaurants in town and pick your favorite dishes
                                </div>
                            </Grid>
                            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                                <div style={{position: 'relative', marginLeft:'-10%'}}>
                                    <LocationMobileSearch 
                                        history={this.props.history}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div>
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch" style={{marginTop : '30px'}}>
                        <Grid container spacing={0} direction="row">
                            <div style={{margin: "auto"}}>
                                <h2 className="topText">
                                    <br/>How we <span style={{color: "#EA5B2F", marginLeft:'0'}}>Do it?</span>
                                </h2>
                            </div>
                        </Grid>
                        <Grid container item xs={6} direction="column" justifyContent="center" style={{overflow:  'visible'}}>
                            <img style={{width:'58vw', marginLeft:'-12%'}} src={image07} alt="image07"/>
                        </Grid>
                        <Grid container item xs={6} direction="column" justifyContent="center" style={{marginTop : '30px'}}>
                            <div style={{paddingLeft: '7vw'}}>
                                <div>
                                    <div className="ContentDescriptionDetails">
                                        Ensuring happiness in the world of food delivery is no joke; it involves a truckload of groundwork. But we're committed to delivering the best food in Bangalore! So whether it's engaging in discussions with talented chefs, collaborating with passionate restaurant owners and trusted food critics, and, of course, savoring the food itself, we invest countless hours to make sure you receive nothing but the best.
                                    </div>
                                    <Button onClick={() => {
                                        window.scrollTo(0, 0);
                                        this.props.toggleAuthModal();
                                        this.props.setActiveForm("register");
                                    }} id={this.state.buttonID}>
                                        SIGN UP NOW
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{background: "linear-gradient(130deg, #F9D543 66%, #FCF9CF 33%)", paddingTop:'40px'}}>
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid container item xs={7} direction="column" justifyContent="center">
                            <div style={{paddingLeft: '10vw'}}>
                                <h2 style={{fontFamily: 'Quicksand-Bold', fontSize: '3.6vw', letterSpacing: "-1px"}}>
                                    <span style={{color: "#3e4bad"}}>Ready to eat </span><span style={{color: "#EA5B2F"}}>best food in Bangalore?</span>
                                </h2>
                                <div>
                                    <div className="ContentDescriptionDetails">
                                        Browse through the top-rated restaurants in town and pick your favorite dishes
                                    </div> 
                                </div>
                            </div>
                            
                            <div style={{position: 'relative', paddingLeft: '6.5vw'}}>
                                <LocationWebSearch
                                    history={this.props.history}
                                />
                            </div>
                            </Grid>
                            <Grid container item xs={5} direction="column" justifyContent="center">
                                <img  style={{width:'36vw', marginRight:'auto'}}  src={home_burger} alt="home_burger"/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(Segment4);