import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import Home from './pages/Home';
import Dishes from './pages/Dishes';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Preferences from './pages/Preferences';
import Restaurants from './pages/Restaurants';
import PartnerWithUs from './pages/PartnerWithUs';
import Tnc from './pages/Tnc';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PartnerTnc from './pages/PartnerTnc';
import AboutUs from './pages/AboutUs';
import HttpsRedirect from 'react-https-redirect';
import NotFound from './pages/NotFound';
import Cart from './pages/Cart';
import Orders from './pages/Orders';
import HomeNew from './pages/HomeNew';
import Careers from './pages/Careers';

function App() {
  return (
    <HttpsRedirect>
      <div>
        <BrowserRouter>
          <div>
            <Switch>
              {/* <Route exact path='/' component={Home}/> */}
              <Route exact path='/' component={HomeNew}/>
              <Route exact path='/home' component={Dishes}/>
              <Route exact path='/profile' component={Profile}/>
              <Route exact path='/settings' component={Settings}/>
              <Route exact path='/preferences' component={Preferences}/>
              <Route path='/restaurants/:restaurant_id' component={Restaurants}/> 
              <Route exact path='/partnerwithus' component={PartnerWithUs}/>
              <Route exact path='/tnc' component={Tnc}/>
              <Route exact path='/partnertnc' component={PartnerTnc}/>
              <Route exact path='/privacypolicy' component={PrivacyPolicy}/>
			        <Route exact path='/aboutus' component={AboutUs}/>
              <Route exact path='/cart' component={Cart}/>
              <Route exact path='/orders' component={Orders}/>
              <Route exact path='/careers' component={Careers}/>
              <Route component={NotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </HttpsRedirect>
  );
}

export default App;
