import React, { Component } from 'react';
import Header from '../components/Dishes/Header';
import LogoutConfirm from '../components/Commons/LogoutConfirm';
import UserOptions from '../components/Dishes/UserOptions';
import Footer from '../components/Commons/Footer';
import Cover from '../components/Profile/Cover';
import TabsContent from '../components/Profile/TabsContent';
import axios from 'axios';
import CONSTANTS from '../config/constants';
import DishRating from '../components/Commons/DishRating';
import RatingSuccess from '../components/Commons/RatingSuccess';
import dish_card1 from '../assets/images/dish_card1.png';
import '../assets/styles/Profile.css';
import DishRatingInformation from '../components/Commons/DishRatingInformation';
import AuthPopup from '../components/Commons/AuthPopup';
import UserCart from '../components/Restaurants/UserCart';

class Profile extends Component {

  constructor() {
    super();
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.toggleActiveTab = this.toggleActiveTab.bind(this);
    this.loadUserReviewes = this.loadUserReviewes.bind(this);
    this.loadUserPreferences = this.loadUserPreferences.bind(this);
    this.loadUserLikes = this.loadUserLikes.bind(this);
    this.showRatingsModal = this.showRatingsModal.bind(this);
    this.closeRatingsModal = this.closeRatingsModal.bind(this);
    this.closeRatingsInformationModal = this.closeRatingsInformationModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.removeLike = this.removeLike.bind(this);
    this.removeUserPreferences = this.removeUserPreferences.bind(this);
    this.addUserPreference = this.addUserPreference.bind(this);
    this.showAuthPopupModal = this.showAuthPopupModal.bind(this);
    this.closeAuthPopupModal = this.closeAuthPopupModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.updateCartModalDisplay = this.updateCartModalDisplay.bind(this);
    this.updateCartChangedValue = this.updateCartChangedValue.bind(this);
    this.state = { 
      isOpen: false,
      logoutModal: false,
      searchModal: false,
      activeTab: 'likes',
      reviewsFetched: false,
      preferencesFetched: false,
      reviews: [],
      preferences: [],
      likes: [],
      likesFetched: false,
      dishSelected: {},
      ratingModal: false,
      ratingInformationModal: false,
      successModal: false, 
      emoji: "", 
      ratingNumber: "",
      isFiveStar: false,
      cuisines: [],
      cuisineLoading: true,
      cuisineCount: 0,
      cuisinesLoaded: 0,
      allCuisinesLoaded: false,
      activeForm: "",
      showCart: false,
    };
  }
  
  showAuthPopupModal(form) {
    this.setState({
      authPopupModal: true,
    })
    if (form != null){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthPopupModal() {
    this.setState({
      authPopupModal: false
    })
    window.location.pathname = "/";
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  showRatingsModal(dish) {
    this.setState({
      ratingInformationModal: true,
      dishSelected: dish
    });
    setTimeout(() => {
      this.setState({
        ratingInformationModal: false
      })
      if (!this.state.ratingModal){
        this.setState({
          ratingModal: true
        })
      }
    }, 4000);
  };

  closeRatingsModal() {
    this.setState({
      ratingModal: false
    });
  };

  closeRatingsInformationModal() {
    this.setState({
      ratingInformationModal: false,
      ratingModal: true
    });
  };

  showSuccessModal(rating) {
    this.setState({
      successModal: true,
      emoji: rating.imageHover,
      ratingNumber: rating.value
    });
    if (rating.value === 5){
      this.setState({
        isFiveStar: true
      })
    }
  };

  updateCartChangedValue(value) {
    this.setState({
      cartChanged: value
    })
    let cartDetails = localStorage.getItem('cartDetailsAPI');
    let itemsInCart = 0;
    if (cartDetails !== null){
      let cartDetailsObj = JSON.parse(cartDetails);
      var itemVal = Object.values(cartDetailsObj.items)
      for(var i=0; i<itemVal.length; i++){
        itemsInCart = itemsInCart + itemVal[i].quantity
      }
    }
    this.setState({
      itemsInCart: itemsInCart
    });
  }

  removeUserPreferences(item){
    let preferences = this.state.cuisines;
    preferences[item.id].display = false;
    this.setState({
      cuisines: preferences
    })
    var token = localStorage.getItem("auth_token");
    var user = JSON.parse(localStorage.getItem("user"));
    var preference_list = user.preferences;
    let preferenceIndex = preference_list.indexOf(item.preferenceId)
    if (preferenceIndex > -1) {
      preference_list.splice(preferenceIndex, 1);
    }
    let type = "remove";
    var headers = {
      'Authorization' : 'Bearer ' + token 
    }
    var req = {
      preference_list: [item.preferenceId],
      preference_type: type
    }
    var url = CONSTANTS.hostURL + "api/accounts/preference/";
    axios.post(url, req,  {
      headers: headers
    }).then((response) => {
        var user = JSON.parse(localStorage.getItem("user"));
        user.preferences = preference_list;
        localStorage.setItem("user", JSON.stringify(user));
        if (preference_list.length === 0){
          localStorage.setItem("preferences_present", false);
        }
        // let preferences = this.state.cuisines;
        // preferences[item.id].display = false;
        // this.setState({
        //   cuisines: preferences
        // })
    });
  }

  removeLike(item){
    let likedDishes = this.state.likes;
    likedDishes[item.id].liked = false;
    this.setState({
      likes: likedDishes
    })
    var token = localStorage.getItem("auth_token");
    var user = JSON.parse(localStorage.getItem("user"));
    var likes_list = user.likes;
    let dishIndex = likes_list.indexOf(item.dishId)
    if (dishIndex > -1) {
      likes_list.splice(dishIndex, 1);
    }
    let type = "dislike";
    var headers = {
      'Authorization' : 'Bearer ' + token 
    }
    var req = {
      likes_list: [item.dishId],
      type: type
    }
    var url = CONSTANTS.hostURL + "api/accounts/likes/";
    axios.post(url, req,  {
      headers: headers
    }).then((response) => {
        var user = JSON.parse(localStorage.getItem("user"));
        user.likes = likes_list;
        localStorage.setItem("user", JSON.stringify(user));
        let likedDishes = this.state.likes;
        likedDishes[item.id].display = false;
        this.setState({
          likes: likedDishes
        })
    });
  }

  componentDidMount(){
    window.scrollTo(0,0);
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      var preferencesPresent = localStorage.getItem("preferences_present");
      if (preferencesPresent === "false" && (token !== null || token !== "")){
        this.props.history.replace("/preferences");
      }
      var user = JSON.parse(localStorage.getItem("user"));
      var likes = user["likes"];
      let url = CONSTANTS.hostURL + "api/accounts/reviews/";
      this.loadUserReviewes(url, 0);
      this.loadUserLikes(likes);
      this.loadCuisines();
      this.getCartDetails();
      this.updateCartChangedValue(true);
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  getCartDetails(){
    var token = localStorage.getItem("auth_token");
    if (token){
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios.get(url, {
          headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1 && 'id' in data.data){
          let cart = data.data;
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          
          let itemsInCart = 0;
          let cartItems = [];
          for (var key in cart.items){
            if(cart.items[key].customizations.length > 1){
              for(var k=0; k<cart.items[key].customizations.length; k++){
                cartItems.push({
                  'DishID': parseInt(key),
                  'DishName': cart.items[key].name,
                  'DishCost': cart.items[key].customizations[k].sub_total,
                  'DishQuantity': cart.items[key].customizations[k].quantity,
                  "DishImage": cart.items[key].image, 
                  'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                  'Customisation': cart.items[key].customizations[k]
                });
                itemsInCart = itemsInCart + cart.items[key].customizations[k].quantity
              }
            }
            else{
              cartItems.push({
                'DishID': parseInt(key),
                'DishName': cart.items[key].name,
                'DishCost': cart.items[key].mrp,
                'DishQuantity': cart.items[key].quantity,
                "DishImage": cart.items[key].image, 
                'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                'Customisation': cart.items[key].customizations[0]
              });
              itemsInCart = itemsInCart + cart.items[key].quantity
            }
          }

          this.setState({
            itemsInCart: itemsInCart,
          });
        } else {
          if (data.status === -1){
            localStorage.removeItem('cartDetailsAPI');
          }
        }
      });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 37: 1, 38: 1, 39: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {  
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.logoutModal  || this.state.searchModal || this.state.isOpen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  loadUserReviewes(url, reviewsLoaded){
    let token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let headers = {
        'Authorization' : 'Bearer ' + token 
      }
      axios.get(url, {
          headers: headers
      }).then(res => {
          let results = res.data.results;
          let reviewItems = this.state.reviews;
          for (let i=0; i<results.length; i++){
            let reviewItem = {}
            let resultItem = results[i];
            reviewItem['id'] = i + reviewsLoaded;
            reviewItem['reviewId'] = resultItem.id;
            reviewItem['dish'] = resultItem.dish.name;
            reviewItem['cost'] = resultItem.dish.cost;
            reviewItem['restaurantID'] = resultItem.dish.restaurant.id;
            reviewItem['restaurant'] = resultItem.dish.restaurant.name;
            reviewItem['description'] = resultItem.description;
            reviewItem['rating'] = resultItem.rating;
            reviewItem.choice = resultItem.dish.choice;
            if (reviewItem.choice === 'V'){
              reviewItem.is_veg = true;
            } else {
              reviewItem.is_veg = false;
            }
            reviewItems.push(reviewItem);
          }
          this.setState({
            reviews : reviewItems
          })
          if (!this.state.reviewsFetched){
            this.setState({
              reviewsFetched: true
            })
          }
          if (res.data.next !== null){
            this.loadUserReviewes(res.data.next, results.length + reviewsLoaded);
          }
      })
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  loadCuisines() {
    let url = CONSTANTS.hostURL + "api/main_app/cuisine/";
    axios.get(url).then(res => {
      let cuisineList = this.state.cuisines;
      let results = res.data.results;
      for (var i=0; i<results.length; i++){
        let cuisineItem = results[i];
        let item = {};
        item.id = i;
        item.preferenceId = cuisineItem.id;
        item.name = cuisineItem.name;
        item.display = false;
        cuisineList.push(item);
      }
      this.setState({
        cuisines: cuisineList,
        cuisineLoading: false,
        cuisineCount: res.data.count,
        cuisinesLoaded: results.length
      }, this.fetchMoreCuisines(res.data.count, results.length, 2));
    })
  }

  fetchMoreCuisines(cuisineCount, cuisinesLoaded, nextPage) {
    if(cuisineCount>cuisinesLoaded && !this.state.allCuisinesLoaded){
      this.setState({
        moreCuisineLoading: true
      })
      let url = CONSTANTS.hostURL + "api/main_app/cuisine/?limit=12&page=" + nextPage;
      axios.get(url).then(res => {
        let cuisineList = this.state.cuisines;
        let results = res.data.results;
        for (var i=0; i<results.length; i++){
          let cuisineItem = results[i];
          let item = {};
          item.id = i + cuisinesLoaded;
          item.preferenceId = cuisineItem.id;
          item.name = cuisineItem.name;
          item.display = false;
          cuisineList.push(item);
        }
        this.setState({
          cuisines: cuisineList,
          cuisineLoading: false,
          cuisineCount: res.data.count,
          cuisinesLoaded: results.length + cuisinesLoaded
        }, this.fetchMoreCuisines(res.data.count, results.length + cuisinesLoaded, nextPage + 1));
        })
      } else {
        this.setState({
          allCuisinesLoaded: true
        });
        var user = JSON.parse(localStorage.getItem("user"));
        var preferences = user["preferences"];
        this.loadUserPreferences(preferences);
      }
  }

  loadUserPreferences(preferences){
    let preferenceList = this.state.cuisines;
    for (var i=0; i< preferenceList.length; i++){
      if (preferences.includes(preferenceList[i].preferenceId)){
        preferenceList[i].display = true;
      }
    }
    this.setState({
      preferencesFetched: true,
      cuisines: preferenceList
    })
  }

  loadUserLikes(likes){
    let token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let headers = {
        'Authorization' : 'Bearer ' + token 
      }
      let likes_string = ""
      for (var i=0; i<likes.length; i++){
        if(likes[i] !== null){
          likes_string = likes_string + likes[i].toString() + ",";
        }
      }
      let url = CONSTANTS.hostURL + "api/accounts/likes/";
      if (likes_string !== ""){
        url = url + "?likes=" + likes_string.substring(0, likes_string.length-1);
      }
      axios.get(url, {
          headers: headers
      }).then(res => {
          let results = res.data.results;
          let likesItems = [];
          for (var i=0; i<results.length; i++){
            let likesItem = {}
            let resultItem = results[i];
            let avg_rating = 0;
            if (resultItem.no_rating !== 0){
              avg_rating = Math.round(resultItem.total_rating / resultItem.no_rating * 10) / 10;
            }
            likesItem['id'] = i;
            likesItem['dishId'] = resultItem.id;
            likesItem['name'] = resultItem.name;
            likesItem['cost'] = resultItem.cost;
            likesItem['choice'] = resultItem.choice;
            if (likesItem.choice === 'V'){
              likesItem.is_veg = true;
            } else {
              likesItem.is_veg = false;
            }
            likesItem['rating'] = avg_rating;
            likesItem['image'] = dish_card1;
            likesItem['liked'] = true;
            likesItem['display'] = true;
            likesItem['restaurantID'] = resultItem.restaurant_id;
            likesItem['restaurant'] = resultItem.restaurant;
            likesItems.push(likesItem);
          }
          this.setState({
            likes : likesItems
          })
          this.setState({
            likesFetched: true
          })
      })
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  addUserPreference(id){
    let preferenceList = this.state.cuisines;
    let preferenceItem = preferenceList[id];
    preferenceItem.display = true;
    preferenceList[id] = preferenceItem;
    this.setState({
      cuisines: preferenceList
    })
    var token = localStorage.getItem("auth_token");
    var user = JSON.parse(localStorage.getItem("user"));
    var preference_list = user.preferences;
    preference_list.push(preferenceItem.preferenceId);
    var headers = {
      'Authorization' : 'Bearer ' + token 
    }
    var req = {
      preference_list: [preferenceItem.preferenceId]
    }
    var url = CONSTANTS.hostURL + "api/accounts/preference/";
    axios.post(url, req,  {
      headers: headers
    }).then((response) => {
        var user = JSON.parse(localStorage.getItem("user"));
        user.preferences = preference_list;
        localStorage.setItem("user", JSON.stringify(user));
    });
  }

  toggleSearchModal() {
    if (this.state.searchModal === false){
      window.scrollTo(0, 0);
    }
    this.setState({
      searchModal: !this.state.searchModal
    });
  }

  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal
    })
  }

  toggleUserOptions(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleActiveTab(tab){
    this.setState({
      activeTab: tab
    })
  }

  closeSuccessModal() {
    this.setState({
      successModal: false,
      isFiveStar: false
    });
  };

  updateCartModalDisplay(value) {
    this.setState({
      showCart: value
    })
  }

  render() {
    return (
      <div onClick={(e) => {
        e.stopPropagation();
        if (this.state.isOpen){
          this.setState({isOpen: false});
        }
        if (this.state.searchModal){
          this.setState({searchModal: false});
        }
      }}>
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Header 
          toggleUserOptions={this.toggleUserOptions} 
          toggleSearchModal={this.toggleSearchModal}
          showAuthPopupModal={this.showAuthPopupModal}
          searchPresent={true}
          itemsInCart={this.state.itemsInCart}
          updateCartModalDisplay={this.updateCartModalDisplay}
          updateCartChangedValue={this.updateCartChangedValue}
          showCart={this.state.showCart}
        />
        <UserCart 
          cartChanged={this.state.cartChanged}
          showCart={this.state.showCart}
          updateCartChangedValue={this.updateCartChangedValue}
          updateCartModalDisplay={this.updateCartModalDisplay}
          itemsInCart={this.state.itemsInCart}
          restaurantId={-1}
          defaultAddresses={this.state.defaultAddresses}
          addresses={this.state.addresses}
        />
        <Cover 
          showSearchModal={this.state.searchModal}
          toggleActiveTab={this.toggleActiveTab}
          activeTab={this.state.activeTab}
          tabPresent={true}
        />
        <TabsContent
          activeTab={this.state.activeTab}
          reviewsFetched={this.state.reviewsFetched}
          reviews={this.state.reviews}
          preferencesFetched={this.state.preferencesFetched}
          preferences={this.state.cuisines}
          likesFetched={this.state.likesFetched}
          likes={this.state.likes}
          showRatingsModal={this.showRatingsModal}
          removeLike={this.removeLike}
          removeUserPreferences={this.removeUserPreferences}
          addUserPreference={this.addUserPreference}
        />
        <DishRating 
          dish={this.state.dishSelected} 
          showRatingsModal={this.state.ratingModal} 
          closeRatingsModal={this.closeRatingsModal}
          showSuccessModal={this.showSuccessModal}
        />
        <DishRatingInformation
          showRatingsInformationModal={this.state.ratingInformationModal} 
          closeRatingsInformationModal={this.closeRatingsInformationModal}
        />
        <RatingSuccess
          showSuccessModal={this.state.successModal}
          closeSuccessModal={this.closeSuccessModal}
          emoji={this.state.emoji}
          isFiveStar={this.state.isFiveStar}
          ratingNumber={this.state.ratingNumber}
        />
        <AuthPopup
          showAuthPopupModal={this.state.authPopupModal}
          closeAuthPopupModal={this.closeAuthPopupModal}
          setActiveForm={this.setActiveForm}
          activeForm={this.state.activeForm}
        />
        <LogoutConfirm
          showLogoutModal={this.state.logoutModal}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Footer/>
      </div>
    )
  };
}

export default Profile;
