import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import DishCard from '../PartnerWithUs/DishCard';

class DishCardPreview extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.toggleImageHover = this.toggleImageHover.bind(this);
        this.state = {
            phoneNumber: "",
            phoneNumberFetched: false
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    closeModalHandler() {
        this.props.closeDishCardPreviewModal();
    }

    showReviewsModalHandler(){}

    showRatingsModalHandler(){}

    toggleImageHover(index){
        this.props.toggleImageHover(index);
    }

    toggleRateHover(){}

    toggleLikeButton(){}

    showAuthPopupModalHandler(){}

    handleModalClick(e){
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.dishPreviewOpen){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{background: "linear-gradient(to bottom, #F8D542 380px, white 380px)", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer DishCardPreviewModalContainer">
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div style={{margin: "auto"}}>
                        <div style={{margin: this.state.isMobileScreen ? "10px auto" : "20px auto 10px auto", color: "#3356AA", fontSize: this.state.isMobileScreen ? "24px" : "36px", fontFamily: "Quicksand-SemiBold", textAlign: "center", letterSpacing: "-1px"}}>Looks Great!</div>
                        <DishCard
                            dish={this.props.dish}
                            descriptionOverlayStyle={[this.props.descriptionOverlayStyle]}
                            overlayStyle={[{}]}
                            likeButtonStyle={["LikeNewButton favme dashicons dashicons-heart"]}
                            showReviewsModal={this.showReviewsModalHandler} 
                            showRatingsModal={this.showRatingsModalHandler}
                            toggleImageHover={this.toggleImageHover}
                            toggleRateHover={this.toggleRateHover}
                            toggleReviewHover={this.toggleReviewHover}
                            toggleLikeButton={this.toggleLikeButton}
                            showAuthPopupModal={this.showAuthPopupModalHandler}
                            screenType={"partner_with_us"}
                        />
                        {/* <div style={{textAlign: "center", margin: "20px auto"}}>
                            <div onClick={this.closeModalHandler} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                                Okay
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default DishCardPreview;