import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import MenuIcon from '../../assets/images/menu_icon.svg';
import { HashLink } from 'react-router-hash-link';

class RestoMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            isMobileScreen: false,
            menuOpen: false,
            bottomBarHeight: "70px",
            filterName: ""
        }
    }

    componentDidMount() {
        this.updateDimensions();
        if([
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)){
            this.setState({
                bottomBarHeight: "85px"
            })
        }
        else{
            this.setState({
                bottomBarHeight: "70px"
            })
        }
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    changeActiveTab(dishTypeId){
        let items = [...this.props.filters];
        let filterName = this.state.filterName;
        for (var i=0; i <items.length; i++){
            if (dishTypeId === this.props.filters[i].dishTypeId){
                filterName = items[i].name;
            }
        }
        this.setState({
            menuOpen: false  
        })
        if (filterName && filterName!== this.state.filterName){
            this.setState({
                filterName: filterName
            });
        }
    }

    scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -240;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    filterItem(filter, id){
        return(
            <HashLink style={{textDecoration: "none", color: "white"}} scroll={el => this.scrollWithOffset(el)} smooth to={`#resto_filter_id${filter.name}`}>
                <div style={{marginBottom: id === this.props.filters.length - 1 ? '0px' : '25px'}} onClick={() => this.changeActiveTab(filter.dishTypeId)} key={filter.dishTypeId}>
                    <div style={{fontSize: (filter.name === this.state.filterName) ? '16px' : '14px', fontFamily: (filter.name === this.state.filterName) ? 'Poppins-SemiBold' : 'Poppins-Regular'}}>{filter.name}</div>
                </div>
            </HashLink>
        )
    }
    
    render() {
        if (!this.state.isMobileScreen){
            return null;
        }
        return(
            <div>
                {!this.state.menuOpen && <div onClick={() => this.setState({menuOpen: true})} style={{position: 'fixed', zIndex: '10', padding: '8px 10px', borderRadius: '10px', bottom: this.state.bottomBarHeight, right: '15px', backgroundColor: '#3356AA', boxShadow: '5px 5px 12px #00000052'}}>
                    <div style={{marginLeft: '9px'}}><img alt="menu-icon" src={MenuIcon} className="mail-icon"/></div>
                    <div style={{marginTop: '2px', color: 'white', fontSize: '12px', fontFamily: 'Poppins-Medium'}}>Menu</div>
                </div>}
                {this.state.menuOpen && <div onClick={() => this.setState({menuOpen: false})} style={{position: 'fixed', height: '100vh', width: '100vw', top: '0px', left: '0px', zIndex: '20'}}></div>}
                {this.state.menuOpen && <div style={{bottom: this.state.bottomBarHeight}} className="MenuCard">
                    {
                        this.props.filters.map((filter, id) => {
                            return this.filterItem(filter, id)
                        })
                    }
                </div>}
            </div>
        )
    }
}

export default RestoMenu;