import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Switch from "react-switch";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import { RiArrowDownSFill } from "react-icons/ri";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { RiShoppingCart2Line } from "react-icons/ri";
// import FreeDeliveryImage from "../../assets/images/freeDelivery1.png"
import PersonalisedFilter from "../../assets/images/personalisedFilter.png"
import AllCuisines from "../../assets/images/allCuisines.png"
import { HashLink } from 'react-router-hash-link';
import BurgerTooltip from "../../assets/images/burger_tooltip.png"

class Actions extends Component {
    constructor(props) {
        super(props);
        this.sortHandleClick = this.sortHandleClick.bind(this);
        this.sortHandleClose = this.sortHandleClose.bind(this);
        this.personalisedDishes = this.personalisedDishes.bind(this);
        this.freeDeliveryDishes = this.freeDeliveryDishes.bind(this);
        // this.offersDishes = this.offersDishes.bind(this);
        this.allCuisinesDishes = this.allCuisinesDishes.bind(this);
        this.state = { 
            isMobileScreen: false,
            toggleChecked: false,
            anchorEl: null,
            selectedSortType: "Select",
            priceSortingOrder: "descending",
            distanceSortingOrder: "descending",
            personalisedFilterSelected: false,
            freeDeliveryFilterSelected: false,
            userLoggedIn: false,
            bottomBarHeight: "55px",
            searchValue: "",
            isVisible: false
        }; 
    }

    componentWillMount() {
        if (this.props.isDishScreen){
            this.setState({
                gridSize: 3,
                gridSize2: 9
            });
        } else {
            this.setState({
                gridSize: 6,
                gridSize2: 6
            });
        }
        this.showCuisinesModalHandler = this.showCuisinesModalHandler.bind(this);
        this.toggleRecommendedHandler = this.toggleRecommendedHandler.bind(this);
        this.closeSearchModalHandler = this.closeSearchModalHandler.bind(this);
        this.searchRestaurantDishHandler = this.searchRestaurantDishHandler.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        setTimeout(() => {
            console.log('hahahahhahaha');
            console.log(typeof localStorage.getItem('best_dish_tooltip_shown'));
            if (localStorage.getItem('best_dish_tooltip_shown') !== 'true'){
                this.setState({
                    isVisible: true
                }, () => {
                    setTimeout(() => {
                        localStorage.setItem('best_dish_tooltip_shown', true);
                        this.setState({
                            isVisible: false
                        });
                    }, 3000);
                });
            }
        }, 2000);
    }

    searchRestaurantDishHandler(dishId){
        this.props.searchRestaurantDish(dishId);
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width > 1000) {
          if (this.props.isDishScreen){
            this.setState({ gridSize: 4, gridSize2: 8 });
          } else {
            this.setState({ gridSize: 7, gridSize2: 5 });
          }
        } else {
            this.setState({gridSize: 12, gridSize2: 12});
        }
        screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }

        if([
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)){
            this.setState({
                bottomBarHeight: "70px"
            })
        }
        else{
            this.setState({
                bottomBarHeight: "55px"
            })
        }
    }

    showCuisinesModalHandler(){
        this.props.showCuisinesModal();
    }

    componentDidMount() {
        this.updateDimensions();
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token !== undefined && auth_token !== null){
            this.setState({
                userLoggedIn: true
            })
        }
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    toggleRecommendedHandler(toggleState){
        this.props.showRecommendedDishes(toggleState);
    }

    closeSearchModalHandler(){
        this.props.closeSearchModal();
        this.props.clearFilters();
        this.props.clearSearchFilter();
    }

    checkPadding(){
        if (this.props.activeHeaderType === 'header'){
            if (!this.state.isMobileScreen){
                return '25px 0vw'; 
            } else {
                return '15px 0vw'; 
            }
        } else {
            if (!this.state.isMobileScreen){
                return '20px 0px';
            } else {
                if (this.props.isDishScreen){
                    return '5px 0px 15px 0px';
                } else {
                    return '15px 0px 15px 0px';
                }
            }
        }
    }

    handleToggle(toggleChecked) {
        if(this.props.allDishesLoaded){
            this.setState({ toggleChecked });
            this.props.toggleVegDishes(toggleChecked);
        }
    }
    
    checkTotalDishes(){
        let totalDishes = this.props.dishes.length;
        if (!this.props.isDishScreen){
            totalDishes = this.props.dishes.filter(function(dish) {return dish.vegDisplay && dish.cuisineDisplay && dish.dishTypeDisplay && dish.recommendedDisplay && dish.personalizedDisplay && dish.searchDisplay && dish.freeDeliveryDisplay;}).length;
        }
        return totalDishes;
    }

    handleMouseEnter () {
        this.setState({
            isVisible: true
        });
    }

    handleMouseLeave () {
        this.setState({
            isVisible: false
        });
        localStorage.setItem('best_dish_tooltip_shown', true);
    }

    checkFilters(){
        const isVisible = this.state.isVisible ? ' is-visible' : ' is-hidden';
        const className = `tooltip ${isVisible}`;
        if (this.props.isDishScreen){
            if (this.props.cuisineFilterActive){
                return(
                    <><span className="DishCardHeaderText" style={{paddingRight:"10px"}}>Personalised Dishes For You</span>
                    <span className="ddFilterDishCount">{this.checkTotalDishes()} Dishes</span></>
                )
            } else {
                if (this.props.isLoggedIn){
                    if (this.state.personalisedFilterSelected){
                        return(
                            <><span className="DishCardHeaderText" style={{paddingRight:"10px"}}>Showing your Preferred Dishes</span>
                            <span className="ddFilterDishCount">{this.checkTotalDishes()} Dishes</span></>
                        )
                    } else {
                        return(
                            <>
                                <span className={className}>
                                        <span onClick={() => {
                                            this.setState({
                                                isVisible: !this.state.isVisible
                                            });
                                        }} data-for="tooltipStep" data-tip="test" className="DishCardHeaderText" style={{paddingRight:"10px", cursor: 'pointer'}}>Best Dishes Only</span>
                                        {this.props.activeHeaderType === 'header' && <span style={{display: 'flex', flexDirection: 'row', padding: this.state.isMobileScreen ? '15px 10px' : '5px 10px'}} className="tooltip-label">
                                            <img style={{width: '60px', height: '68px'}} src={BurgerTooltip} alt="burgerTooltip"/>
                                            <span style={{color: '#FFFFFF', fontFamily: 'Poppins-SemiBold', fontSize: '16px', padding: '0px 10px', transform: this.state.isMobileScreen ? 'translateY(0px)' : 'translateY(10px)', flex: 1}}>Two handpicked dishes from every restaurant to make you absolutely sure.</span>
                                            <CloseIcon onClick={this.handleMouseLeave.bind(this)} style={{color: '#7F9CE4', marginRight: '10px', transform: 'translateY(18px)', cursor: 'pointer'}}/>
                                        </span>}
                                </span>
                                <span className="ddFilterDishCount" style={{top : this.state.isMobileScreen ? "0px" : "-3px"}}>{this.checkTotalDishes()} Dishes</span>
                            </>
                        )
                    }
                } else {
                    return(
                        <>
                            <span className={className}>
                                    <span onClick={() => {
                                        if (this.state.isVisible){
                                            localStorage.setItem('best_dish_tooltip_shown', true);
                                        }
                                        this.setState({
                                            isVisible: !this.state.isVisible
                                        });
                                    }} data-for="tooltipStep" data-tip="test" className="DishCardHeaderText" style={{paddingRight:"10px", cursor: 'pointer'}}>Best Dishes Only</span>
                                    {this.props.activeHeaderType === 'header' && <span style={{display: 'flex', flexDirection: 'row', padding: this.state.isMobileScreen ? '15px 10px' : '5px 10px'}} className="tooltip-label">
                                        <img style={{width: '64px', height: '68px'}} src={BurgerTooltip} alt="burgerTooltip"/>
                                        <span style={{color: '#FFFFFF', fontFamily: 'Poppins-SemiBold', fontSize: '15px', padding: '0px 10px', transform: this.state.isMobileScreen ? 'translateY(0px)' : 'translateY(10px)', flex: 1}}>Two handpicked dishes from every restaurant to make you absolutely sure.</span>
                                        <CloseIcon onClick={this.handleMouseLeave.bind(this)} style={{color: '#7F9CE4', marginRight: '10px', transform: 'translateY(18px)', cursor: 'pointer'}}/>
                                    </span>}
                            </span>
                            <span className="ddFilterDishCount" style={{top : this.state.isMobileScreen ? "0px" : "-3px"}}>{this.checkTotalDishes()} Dishes</span>
                        </>
                        // <><span className="DishCardHeaderText" style={{paddingRight:"10px"}}>Only the Best Dishes</span>
                        // <span className="ddFilterDishCount">{this.checkTotalDishes(totalDishes)} Dishes</span>
                        // <div style={{fontFamily:"Poppins-Regular", fontSize:"12px", color:"#5E4B45", position: this.state.isMobileScreen ? "relative" : "absolute"}}>{this.state.isMobileScreen ? "Only two handpicked dishes from every restaurant" : "Two handpicked dishes from every restaurant to make you absolutely sure. Order a new dish everyday!"}</div></>
                    )
                }
            }
        } else {
            return(
                <>
                    <span className="DishCardHeaderText" style={{paddingRight:"10px"}}>Only the Best Dishes</span>
                    {/* <span className="ddFilterDishCount">{this.checkTotalDishes(totalDishes)} Dishes</span> */}
                    {!this.state.isMobileScreen && <>
                        <div style={{display: "inline-block", backgroundColor: "#EA5B2F", borderRadius: "50%", padding: "0px 4px", transform: "translateY(-2px)"}}>
                            <SearchIcon className="ddSearchIcon" style={{top: "1px", color: "white", fontSize: "14px"}}/>
                        </div>
                        <input
                            type="text"
                            className="ddSearchTextBox"
                            placeholder={this.props.isDishScreen === true ? "Search for the dish you are craving.." : "Search for dishes within the restaurant..."}
                            onChange={(event) => {
                                this.setState({
                                    searchValue: event.target.value
                                });
                                this.props.handleSearchInput(event.target.value);
                            }}
                            style={{width: this.props.isLoggedIn ? "calc(70% - 40px)" : "55%", top: "-4px", marginLeft: "5px"}}
                            value={this.state.searchValue}
                        />
                    </>} 
                    {!this.state.isMobileScreen && this.state.searchValue !== "" && <div onClick={() => {
                        this.setState({searchValue: ""});
                        this.props.handleSearchInput("");
                    }} style={{display: "inline-block", backgroundColor: "#ECECEC", borderRadius: "10px", padding: "0px 2px", transform: "translateY(-4px)", cursor: "pointer"}}>
                        <CloseIcon className="ddSearchIcon" style={{top: "1px", color: "black", fontSize: "14px"}}/>
                    </div>}
                </>
            )
        }
    }
    
    sortHandleClick = (event) => {
        this.setState({anchorEl : event.currentTarget});
    };

    sortHandleClose(type){
        this.setState({anchorEl : null});
        if (type === "Ratings"){
            this.props.showRatingSortedDishes();
        } else if (type === "Price"){
            this.props.showPriceSortedDishes(this.state.priceSortingOrder === "descending" ? "ascending" : "descending");
            this.setState({
                priceSortingOrder: this.state.priceSortingOrder === "descending" ? "ascending" : "descending"
            })
        } else if (type === "Distance"){
            this.props.showDistanceSortedDishes(this.state.distanceSortingOrder === "descending" ? "ascending" : "descending");
            this.setState({
                distanceSortingOrder: this.state.distanceSortingOrder === "descending" ? "ascending" : "descending"
            })
        } else if (type === "Discount"){
            this.props.showDiscountSortedDishes();
        }
    };

    personalisedDishes(){
        this.setState({
            personalisedFilterSelected: !this.state.personalisedFilterSelected,
            allCuisineFilterSelected: false
        })
        this.props.togglePersonalized();
    }
    
    freeDeliveryDishes(){
        this.setState({
            freeDeliveryFilterSelected: !this.state.freeDeliveryFilterSelected,
            allCuisineFilterSelected: false
        })
        this.props.toggleFreeDelivery();
    }

    // offersDishes(){}

    allCuisinesDishes(){
        this.setState({
            allCuisineFilterSelected: true
        })
        this.setState({
            personalisedPillStyle : {background:"#FFFBEA", color:"#D9B728", border:"1px solid #FFFBD8"},
            personalisedIconStyle : {color:"#FFFFFF", background:"#EDCB3E"},
            freeDeliveryPillStyle : {background:"#EFF3FF", color:"#3356AA", border:"1px solid #E2EBFF"},
            freeDeliveryIconStyle : {color:"#EFF3FF", background:"#3356AA"},
            offersPillStyle : {background:"#FFF7FB", color:"#EA2785", border:"1px solid #FFEFF7"},
            offersIconStyle : {color:"#FFF7FB", background:"#EA2785"},
            allCuisinesPillStyle : {background:"#EA5B2F", color:"#FFF5F2", border:"1px solid #EA5B2F"},
            allCuisinesIconStyle : {color:"#EA5B2F", background:"#FFF5F2"},
        })
        this.props.showCuisinesModal();
    }

    showUserCart() {
        if (localStorage.getItem("auth_token") === null) {
            return null;
        } else {
            return (
                (this.state.isMobileScreen) ?
                    null
                :
                    <span onClick={() => this.props.updateCartModalDisplay(!this.props.showCart)} style={{marginLeft: "15px", fontSize:"20px"}} className="ddHeaderCartIcon">
                        <span onClick={() => this.props.updateCartModalDisplay(!this.props.showCart)} style={{marginLeft: "15px", fontSize:"16px", backgroundColor: this.props.itemsInCart === 0 ? "#2B2624" : "#EA5B2F"}} className="ddHeaderCartIcon">
                            <RiShoppingCart2Line style={{transform: "translate(2px, 2px)", marginRight: "1px"}}/> {this.props.itemsInCart}
                        </span>
                    </span>
            )
        }
    }

    getArrow(){
        if (this.state.selectedSortType === "Distance"){
            if (this.state.distanceSortingOrder === "ascending"){
                return <span><ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/></span>;
            } else {
                return <span><ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/></span>
            }
        } else if (this.state.selectedSortType === "Price"){
            if (this.state.priceSortingOrder === "ascending"){
                return <span><ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/></span>;
            } else {
                return <span><ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/></span>
            }
        }
    }

    showFilterImage(filter, imageStyle, id){
        if (this.props.isDishScreen){
            return (
                this.state.isMobileScreen ? 
                <img className="FilterItemsImageM" src={filter.image} alt="filter img"/>
                :
                <img style={imageStyle[id]} className="FilterItemsImage" src={filter.image} alt="filter img"/>
            )
        } else {
            return null;
        }
    }

    showFilterText(filter, textStyle, restaurantTextStyle, id){
        if (this.props.isDishScreen){
            return (
                <div style={textStyle[id]} className="FilterItemsText">{filter.name}</div>
            )
        } else {
            return (
                <div style={restaurantTextStyle[id]} className="FilterItemsText FilterRestaurantItemsText">{filter.name}</div>
            )
        }
    }

    getFilter(dishTypeID, filters, type){
        for (var i=0; i<filters.length; i++){
            if (type === "dish_type"){
                if (dishTypeID === filters[i].dishTypeId){
                    return i;
                }
            } else {
                if (dishTypeID === filters[i].cuisineId){
                    return i;
                }
            }
        }
    }

    scrollWithOffset = (el, menuName) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
        this.props.setCurrentRestoMenu(menuName);
    }

    filterItem(filter, imageStyle, textStyle, restaurantTextStyle, id){
        return(
            <HashLink style={{textDecoration: "none", color: "white"}} scroll={el => this.scrollWithOffset(el, filter.name)} smooth to={`#resto_filter_id${filter.name}`}>
                <div className="FilterItemsContainer FilterRestaurantItemsContainer" key={filter.dishTypeId} style={this.state.isMobileScreen ? {marginRight:"5px", marginLeft:"0px"} : {}}>
                    {this.showFilterImage(filter, imageStyle, id)}
                    {this.showFilterText(filter, textStyle, restaurantTextStyle, id)}
                </div>
            </HashLink>
        )
    }

    render() {
        if (this.props.nonServicable && this.props.isDishScreen){
            return null;
        } else {
            let imageStyle = [];
            let textStyle = [];
            let restaurantTextStyle = [];
            for (var i=0; i<this.props.filters.length; i++){
                if (this.props.filters[i].display === true){
                    imageStyle.push({});
                    textStyle.push({});
                    restaurantTextStyle.push({});
                    if (this.props.filters[i].name === this.props.currentRestoMenu) {
                        imageStyle[i] = {width: "50px"};
                        textStyle[i] = this.state.isMobileScreen ? {color: "#EA5B2F", fontFamily: "Poppins-Medium"} : {color: "#EA5B2F", fontFamily: "Poppins-Medium"};
                        restaurantTextStyle[i] = {color: "#EA5B2F", backgroundColor: "#FFFBFA", borderRadius: '5px', padding: this.state.isMobileScreen ? '10px' : '10px 30px', border: '1px solid #EA5B2F', fontFamily: 'Quicksand-Bold'};
                    } else {
                        imageStyle[i] = {height: "50px"};
                        textStyle[i] = {color: "#606060", fontFamily: "Poppins-Medium"};
                        restaurantTextStyle[i] = {color: "#2B2624", backgroundColor: "#FFFBFA", borderRadius: '5px', padding: this.state.isMobileScreen ? '10px' : '10px 30px', border: '1px solid #DEC7BF', fontFamily: 'Quicksand-Bold'};
                    }
                }
            }
            return(
                this.state.isMobileScreen ?
                <>
                    {
                        this.props.activeHeaderType === 'actions' ? <div className="DishesActions DishesActionsHeader DishesActionsIdentifier" style={{padding: this.checkPadding()}}>
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid item xs={12} style={{marginLeft:"20px", marginRight:"20px"}}>
                                <div style={{marginTop: "10px", marginBottom: "10px"}}>{this.checkFilters()}</div>
                                <div className="ddFoodSearchDivM">
                                    <SearchIcon style={{fontSize:"22px"}} className="ddSearchIcon"/>
                                    <input
                                        type="text"
                                        className="ddSearchTextBox"
                                        style={{width: "calc(90% - 10px)"}}
                                        value={this.state.searchValue}
                                        placeholder={this.props.isDishScreen === true ? "Search for the dish you are craving.." : "Search for dishes within the restaurant..."}
                                        onChange={(event) => {
                                            this.setState({searchValue: event.target.value});
                                            this.props.handleSearchInput(event.target.value);
                                        }}
                                        onClick={() => {
                                            if (this.state.isMobileScreen){
                                                if (this.props.isDishScreen){
                                                    window.scrollTo(0, 220);
                                                } else {
                                                    window.scrollTo(0, 320);
                                                }
                                            }
                                        }}
                                    />
                                    {this.state.searchValue !== "" && <div onClick={() => {
                                        this.setState({searchValue: ""});
                                        this.props.handleSearchInput("");
                                    }} style={{display: "inline-block", backgroundColor: "#ECECEC", borderRadius: "10px", padding: "0px 2px", transform: "translateY(-2px)", cursor: "pointer"}}>
                                        <CloseIcon className="ddSearchIcon" style={{top: "1px", color: "black", fontSize: "14px"}}/>
                                    </div>}
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{marginRight:"15px", marginLeft:"15px", overflowX:"scroll", marginTop:"10px"}} className="ddnoScroll">
                                {this.props.isDishScreen === true ?
                                <div style={{width:"max-content", overflowY: 'hidden'}}>
                                    {this.state.userLoggedIn === true && this.props.isDishScreen === true ? 
                                        <span onClick={this.personalisedDishes} className="ddFilterPillsM" 
                                        style={this.state.personalisedFilterSelected === true ? {background:"#D9B728", color:"#FFFBEA", border:"1px solid #D9B728"} : {background:"#FFFBEA", color:"#D9B728", border:"1px solid #FFFBD8"}}>
                                            <img src={PersonalisedFilter} alt="personalisedFilter" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            Personalised
                                        </span>
                                    : null}
                                    {/* {this.props.isDishScreen === true ? 
                                        <span onClick={this.freeDeliveryDishes} className="ddFilterPillsM" 
                                        style={this.state.freeDeliveryFilterSelected === true ? {background:"#3356AA", color:"#EFF3FF", border:"1px solid #3356AA"} : {background:"#EFF3FF", color:"#3356AA", border:"1px solid #E2EBFF"}}>
                                            <img src={FreeDeliveryImage} alt="freeDelivery" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            Free Delivery
                                        </span>
                                    : null} */}
                                    
                                    {this.props.isDishScreen === true ? 
                                        <span onClick={this.allCuisinesDishes} className="ddFilterPillsM" 
                                        style={this.props.cuisinesModal === true ? {background:"#EA5B2F", color:"#FFF5F2", border:"1px solid #EA5B2F"} : {background:"#FFF5F2", color:"#EA5B2F", border:"1px solid #FFECE6"}}>
                                            <img src={AllCuisines} alt="allCuisines" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            All Cuisines
                                        </span>
                                    : null}
                                </div>
                                :
                                null}
                            </Grid>
                            <Grid item xs={12} style={{marginLeft:"20px", marginRight:"20px", marginTop:"10px"}}>
                                <div>
                                    <label className="VegToggleContainer" style={{marginRight: "20px"}}>
                                        <Switch 
                                            onChange={this.handleToggle} 
                                            checked={this.state.toggleChecked} 
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={40}
                                            className="VegToggleM"
                                            offHandleColor="#A9A9A9"
                                            // onHandleColor="#A9A9A9"
                                            handleDiameter={14}
                                            offColor="#ECECEC"
                                        />
                                        <span className="VegToggleText">Veg Only</span>
                                    </label>
                                    <span onClick={this.sortHandleClick} style={{cursor:"pointer", float:"right"}} aria-controls="simple-menu" aria-haspopup="true" >
                                        <span className="ddBlackBand">Sort</span>
                                        <span className="ddBlackBand2">
                                            {this.state.selectedSortType}
                                            {this.getArrow()}
                                        </span>
                                        <RiArrowDownSFill className="ddBlackBandIcon"/>
                                    </span>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.sortHandleClose}
                                        style={{transform: 'translateZ(400000000)', width:"140px"}}
                                    >
                                        {/* <MenuItem style={{fontSize:"13px"}} onClick={() => {this.setState({selectedSortType: "Select a sort order"}); this.sortHandleClose("Select a sort order")}}>Select a sort order</MenuItem> */}
                                        {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px", minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Discount"}); this.sortHandleClose("Discount")}}>Discount</MenuItem> : null}
                                        <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important', minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Ratings"}); this.sortHandleClose("Ratings")}}>Ratings</MenuItem>
                                        <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important', minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Price"}); this.sortHandleClose("Price")}}>Price <span>{this.state.priceSortingOrder === "descending" ? 
                                            <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)", zIndex: '4000000000 !important'}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                        }</span></MenuItem>
                                        {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px", minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Distance"}); this.sortHandleClose("Distance")}}>Distance <span>{this.state.distanceSortingOrder === "descending" ? 
                                            <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                        }</span></MenuItem> : null}
                                    </Menu>
                                    {this.showUserCart()}
                                </div>
                            </Grid>
                        </Grid>
                    </div> : null
                    }
                    <div className="DishesActions" style={{padding: this.checkPadding()}}>
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid item xs={12} style={{marginLeft:"20px", marginRight:"20px"}}>
                                <div style={{marginTop: "10px", marginBottom: "10px"}}>{this.checkFilters()}</div>
                                {this.props.isDishScreen && this.props.activeHeaderType === 'header' && <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0px', background: 'linear-gradient(90deg, #FFFCEC 0%, #FFEB9A 100%)', width: 'calc(100vw - 40px)', borderRadius: '12px', padding: '0px 8px'}}>
                                    <img style={{width: '60px', height: '68px'}} src={BurgerTooltip} alt="burgerTooltip"/>
                                    <span style={{color: '#735E58', fontFamily: 'Poppins-SemiBold', fontSize: '16px', padding: '0px 10px', transform: this.state.isMobileScreen ? 'translateY(10px)' : 'translateY(20px)', flex: 1}}>Two handpicked dishes from every restaurant{this.state.isMobileScreen ? '' : ' to make you absolutely sure. Order a new dish everyday!'}</span>
                                </div>}
                                <div className="ddFoodSearchDivM">
                                    <SearchIcon style={{fontSize:"22px"}} className="ddSearchIcon"/>
                                    <input
                                        type="text"
                                        className="ddSearchTextBox"
                                        style={{width: "calc(90% - 10px)"}}
                                        value={this.state.searchValue}
                                        placeholder={this.props.isDishScreen === true ? "Search for the dish you are craving.." : "Search for dishes within the restaurant..."}
                                        onChange={(event) => {
                                            this.setState({searchValue: event.target.value});
                                            this.props.handleSearchInput(event.target.value);
                                        }}
                                        onClick={() => {
                                            if (this.state.isMobileScreen){
                                                if (this.props.isDishScreen){
                                                    window.scrollTo(0, 220);
                                                } else {
                                                    window.scrollTo(0, 320);
                                                }
                                            }
                                        }}
                                    />
                                    {this.state.searchValue !== "" && <div onClick={() => {
                                        this.setState({searchValue: ""});
                                        this.props.handleSearchInput("");
                                    }} style={{display: "inline-block", backgroundColor: "#ECECEC", borderRadius: "10px", padding: "0px 2px", transform: "translateY(-2px)", cursor: "pointer"}}>
                                        <CloseIcon className="ddSearchIcon" style={{top: "1px", color: "black", fontSize: "14px"}}/>
                                    </div>}
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{marginRight:"15px", marginLeft:"15px", overflowX:"scroll", marginTop:"10px"}} className="ddnoScroll">
                                {this.props.isDishScreen === true ?
                                <div style={{width:"max-content", overflowY: 'hidden'}}>
                                    {this.state.userLoggedIn === true && this.props.isDishScreen === true ? 
                                        <span onClick={this.personalisedDishes} className="ddFilterPillsM" 
                                        style={this.state.personalisedFilterSelected === true ? {background:"#D9B728", color:"#FFFBEA", border:"1px solid #D9B728"} : {background:"#FFFBEA", color:"#D9B728", border:"1px solid #FFFBD8"}}>
                                            <img src={PersonalisedFilter} alt="personalisedFilter" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            Personalised
                                        </span>
                                    : null}
                                    {/* {this.props.isDishScreen === true ? 
                                        <span onClick={this.freeDeliveryDishes} className="ddFilterPillsM" 
                                        style={this.state.freeDeliveryFilterSelected === true ? {background:"#3356AA", color:"#EFF3FF", border:"1px solid #3356AA"} : {background:"#EFF3FF", color:"#3356AA", border:"1px solid #E2EBFF"}}>
                                            <img src={FreeDeliveryImage} alt="freeDelivery" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            Free Delivery
                                        </span>
                                    : null} */}
                                    
                                    {this.props.isDishScreen === true ? 
                                        <span onClick={this.allCuisinesDishes} className="ddFilterPillsM" 
                                        style={this.props.cuisinesModal === true ? {background:"#EA5B2F", color:"#FFF5F2", border:"1px solid #EA5B2F"} : {background:"#FFF5F2", color:"#EA5B2F", border:"1px solid #FFECE6"}}>
                                            <img src={AllCuisines} alt="allCuisines" style={{position:"relative", top:"8px", width:"26px", left:"-8px"}}/>
                                            All Cuisines
                                        </span>
                                    : null}
                                </div>
                                :
                                null}
                            </Grid>
                            <Grid item xs={12} style={{marginLeft:"20px", marginRight:"20px", marginTop:"10px"}}>
                                <div>
                                    <label className="VegToggleContainer" style={{marginRight: "20px"}}>
                                        <Switch 
                                            onChange={this.handleToggle} 
                                            checked={this.state.toggleChecked} 
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={40}
                                            className="VegToggleM"
                                            offHandleColor="#A9A9A9"
                                            // onHandleColor="#A9A9A9"
                                            handleDiameter={14}
                                            offColor="#ECECEC"
                                        />
                                        <span className="VegToggleText">Veg Only</span>
                                    </label>
                                    <span onClick={this.sortHandleClick} style={{cursor:"pointer", float:"right"}} aria-controls="simple-menu" aria-haspopup="true" >
                                        <span className="ddBlackBand">Sort</span>
                                        <span className="ddBlackBand2">
                                            {this.state.selectedSortType}
                                            {this.getArrow()}
                                        </span>
                                        <RiArrowDownSFill className="ddBlackBandIcon"/>
                                    </span>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.sortHandleClose}
                                        style={{transform: 'translateZ(400000000)', width:"140px"}}
                                    >
                                        {/* <MenuItem style={{fontSize:"13px"}} onClick={() => {this.setState({selectedSortType: "Select a sort order"}); this.sortHandleClose("Select a sort order")}}>Select a sort order</MenuItem> */}
                                        {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px", minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Discount"}); this.sortHandleClose("Discount")}}>Discount</MenuItem> : null}
                                        <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important', minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Ratings"}); this.sortHandleClose("Ratings")}}>Ratings</MenuItem>
                                        <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important', minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Price"}); this.sortHandleClose("Price")}}>Price <span>{this.state.priceSortingOrder === "descending" ? 
                                            <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)", zIndex: '4000000000 !important'}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                        }</span></MenuItem>
                                        {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px", minHeight:"30px"}} onClick={() => {this.setState({selectedSortType: "Distance"}); this.sortHandleClose("Distance")}}>Distance <span>{this.state.distanceSortingOrder === "descending" ? 
                                            <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                        }</span></MenuItem> : null}
                                    </Menu>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
                :
                <>
                    {this.props.activeHeaderType === 'actions' ? <div className="DishesActions DishesActionsHeader DishesActionsIdentifier" style={{padding: this.checkPadding()}}>
                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid item xs={12} direction="row" style={{marginLeft:"110px", marginRight:"110px"}}>
                            {this.checkFilters()}
                            <div style={!this.state.isMobileScreen ? {float:"right"} : {}}>
                                <label className="VegToggleContainer" style={{marginRight: "20px"}}>
                                    <Switch 
                                        onChange={this.handleToggle} 
                                        checked={this.state.toggleChecked} 
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={this.state.isMobileScreen ? 12 : 20}
                                        width={40}
                                        className="VegToggle"
                                        offHandleColor="#A9A9A9"
                                        // onHandleColor="#A9A9A9"
                                        handleDiameter={14}
                                        offColor="#ECECEC"
                                    />
                                    <span className="VegToggleText" style={{position:"relative", top:"-2px"}}>Veg Only</span>
                                </label>
                                <span onClick={this.sortHandleClick} style={{cursor:"pointer"}} aria-controls="simple-menu" aria-haspopup="true" >
                                    <span className="ddBlackBand">Sort</span>
                                    <span className="ddBlackBand2">
                                        {this.state.selectedSortType}
                                        {this.getArrow()}
                                    </span>
                                    <RiArrowDownSFill className="ddBlackBandIcon"/>
                                </span>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.sortHandleClose}
                                    style={{transform: 'translateZ(400000000)'}}
                                >
                                    {/* <MenuItem style={{fontSize:"13px"}} onClick={() => {this.setState({selectedSortType: "Select a sort order"}); this.sortHandleClose("Select a sort order")}}>Select a sort order</MenuItem> */}
                                    {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px"}} onClick={() => {this.setState({selectedSortType: "Discount"}); this.sortHandleClose("Discount")}}>Discount</MenuItem> : null}
                                    <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important'}} onClick={() => {this.setState({selectedSortType: "Ratings"}); this.sortHandleClose("Ratings")}}>Ratings</MenuItem>
                                    <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important'}} onClick={() => {this.setState({selectedSortType: "Price"}); this.sortHandleClose("Price")}}>Price <span>{this.state.priceSortingOrder === "descending" ? 
                                        <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)", zIndex: '4000000000 !important'}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                    }</span></MenuItem>
                                    {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px"}} onClick={() => {this.setState({selectedSortType: "Distance"}); this.sortHandleClose("Distance")}}>Distance <span>{this.state.distanceSortingOrder === "descending" ? 
                                        <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                    }</span></MenuItem> : null}
                                </Menu>
                                {this.showUserCart()}
                            </div>
                        </Grid>
                        {this.props.isDishScreen && <div style={{display: 'flex', flexDirection: 'row'}}>
                        </div>}
                        {this.props.isDishScreen && <Grid item xs={12} style={{marginLeft:"100px", marginRight:"100px"}}>
                            <div className="ddFoodSearchDiv" style={{padding:"5px 8px 0px 10px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={this.props.isDishScreen === true ? 6 : 12}>
                                        <SearchIcon className="ddSearchIcon" style={{top: this.props.isDishScreen === true ? "5px" : "3px"}}/>
                                        <input
                                            type="text"
                                            className="ddSearchTextBox"
                                            placeholder={this.props.isDishScreen === true ? "Search for the dish you are craving.." : "Search for dishes within the restaurant..."}
                                            onChange={(event) => {
                                                this.props.handleSearchInput(event.target.value);
                                                this.setState({
                                                    searchValue: event.target.value
                                                })
                                            }}
                                            value={this.state.searchValue}
                                            style={{width:"calc(95% - 30px)", top: this.props.isDishScreen === true ? "-3px" : "-6px"}}
                                        />
                                        {this.state.searchValue !== "" && <div onClick={() => {
                                            this.setState({searchValue: ""});
                                            this.props.handleSearchInput("");
                                        }} style={{display: "inline-block", backgroundColor: "#ECECEC", borderRadius: "10px", padding: "0px 2px", transform: "translateY(-2px)", cursor: "pointer"}}>
                                            <CloseIcon className="ddSearchIcon" style={{top: "1px", color: "black", fontSize: "14px"}}/>
                                        </div>}
                                    </Grid>
                                    {this.props.isDishScreen &&
                                        <Grid item xs={6}>
                                            <div style={{float:"right"}}>
                                                {this.state.userLoggedIn === true && this.props.isDishScreen === true ?
                                                    <>
                                                    <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"-5px"}}></span>
                                                    <span onClick={this.personalisedDishes} className="ddFilterPills" 
                                                    style={this.state.personalisedFilterSelected === true ? {background:"#D9B728", color:"#FFFBEA", border:"1px solid #D9B728"} : {background:"#FFFBEA", color:"#D9B728", border:"1px solid #FFFBEA"}}>
                                                        <img src={PersonalisedFilter} alt="personalisedFilter" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        Personalised
                                                    </span>
                                                    </>
                                                : null}
                                                {/* {this.props.isDishScreen === true ? 
                                                    <span onClick={this.freeDeliveryDishes} className="ddFilterPills" 
                                                    style={this.state.freeDeliveryFilterSelected === true ? {background:"#3356AA", color:"#EFF3FF", border:"1px solid #3356AA"} : {background:"#EFF3FF", color:"#3356AA", border:"1px solid #E2EBFF"}}>
                                                        <img src={FreeDeliveryImage} alt="freeDelivery" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        Free Delivery
                                                    </span>
                                                : null} */}
                                                {/* <span onClick={this.offersDishes} className="ddFilterPills" style={this.state.offersPillStyle}><MdOutlineLocalOffer className="ddFilterPillIcons" style={this.state.offersIconStyle} />Offers</span> */}
                                                {this.props.isDishScreen === true ? 
                                                    <span onClick={this.allCuisinesDishes} className="ddFilterPills" 
                                                    style={this.props.cuisinesModal === true ? {background:"#EA5B2F", color:"#FFF5F2", border:"1px solid #EA5B2F"} : {background:"#FFF5F2", color:"#EA5B2F", border:"1px solid #FFECE6"}}>
                                                        <img src={AllCuisines} alt="allCuisines" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        All Cuisines
                                                    </span>
                                                : null}
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                                {/* <div>
                                    
                                </div>
                                <span style={{float:"right"}}>
                                    
                                </span> */}
                            </div>
                        </Grid>}
                        {(!this.props.isDishScreen && !this.state.isMobileScreen) && <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid justifyContent="center" alignItems="center" className="FilterRow" container item xs={12}>
                                <div style={{margin: "15px 7vw 0px 7vw"}} className={this.state.isMobileScreen ? "SearchBarContainer FilterItemsSuperM ddnoScroll" : "SearchBarContainer FilterItemsSuper"}>
                                    {
                                        this.props.filters.map((filter) => {
                                            return this.filterItem(filter, imageStyle, textStyle, restaurantTextStyle, this.getFilter(filter.type === "dish_type" ? filter.dishTypeId : filter.cuisineId, this.props.filters, filter.type))
                                        })
                                    }
                                </div>
                                {/* {clearButton} */}
                            </Grid>
                        </Grid>}
                    </Grid>
                </div> : null}
                    <div className="DishesActions" style={{padding: this.checkPadding()}}>
                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid item xs={12} style={{marginLeft:"110px", marginRight:"110px"}}>
                            {this.checkFilters()}
                            <div style={!this.state.isMobileScreen ? {float:"right"} : {}}>
                                <label className="VegToggleContainer" style={{marginRight: "20px"}}>
                                    <Switch 
                                        onChange={this.handleToggle} 
                                        checked={this.state.toggleChecked} 
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={this.state.isMobileScreen ? 12 : 20}
                                        width={40}
                                        className="VegToggle"
                                        offHandleColor="#A9A9A9"
                                        // onHandleColor="#A9A9A9"
                                        handleDiameter={14}
                                        offColor="#ECECEC"
                                    />
                                    <span className="VegToggleText" style={{position:"relative", top:"-2px"}}>Veg Only</span>
                                </label>
                                <span onClick={this.sortHandleClick} style={{cursor:"pointer"}} aria-controls="simple-menu" aria-haspopup="true" >
                                    <span className="ddBlackBand">Sort</span>
                                    <span className="ddBlackBand2">
                                        {this.state.selectedSortType}
                                        {this.getArrow()}
                                    </span>
                                    <RiArrowDownSFill className="ddBlackBandIcon"/>
                                </span>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.sortHandleClose}
                                    style={{transform: 'translateZ(400000000)'}}
                                >
                                    {/* <MenuItem style={{fontSize:"13px"}} onClick={() => {this.setState({selectedSortType: "Select a sort order"}); this.sortHandleClose("Select a sort order")}}>Select a sort order</MenuItem> */}
                                    {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px"}} onClick={() => {this.setState({selectedSortType: "Discount"}); this.sortHandleClose("Discount")}}>Discount</MenuItem> : null}
                                    <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important'}} onClick={() => {this.setState({selectedSortType: "Ratings"}); this.sortHandleClose("Ratings")}}>Ratings</MenuItem>
                                    <MenuItem style={{fontSize:"13px", width: "150px", zIndex: '4000000000 !important'}} onClick={() => {this.setState({selectedSortType: "Price"}); this.sortHandleClose("Price")}}>Price <span>{this.state.priceSortingOrder === "descending" ? 
                                        <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)", zIndex: '4000000000 !important'}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                    }</span></MenuItem>
                                    {this.props.isDishScreen === true ? <MenuItem style={{fontSize:"13px", width: "150px"}} onClick={() => {this.setState({selectedSortType: "Distance"}); this.sortHandleClose("Distance")}}>Distance <span>{this.state.distanceSortingOrder === "descending" ? 
                                        <ArrowDownward style={{fontSize: "16px", transform: "translateY(3px)"}}/> : <ArrowUpward style={{fontSize: "16px", transform: "translateY(3px)"}}/>
                                    }</span></MenuItem> : null}
                                </Menu>
                            </div>
                        </Grid>
                        {this.props.isDishScreen && this.props.activeHeaderType === 'header' && <div style={{display: 'flex', flexDirection: 'row', margin: '10px 7vw', background: 'linear-gradient(90deg, #FFFCEC 0%, #FFEB9A 100%)', width: '86vw', borderRadius: '12px', padding: '0px 15px'}}>
                            <img style={{width: '60px', height: '68px'}} src={BurgerTooltip} alt="burgerTooltip"/>
                            <span style={{color: '#735E58', fontFamily: 'Poppins-SemiBold', fontSize: '16px', padding: '0px 10px', transform: this.state.isMobileScreen ? 'translateY(0px)' : 'translateY(22px)', flex: 1}}>Two handpicked dishes from every restaurant{this.state.isMobileScreen ? '' : ' to make you absolutely sure. Order a new dish everyday!'}</span>
                        </div>}
                        {this.props.isDishScreen && <Grid item xs={12} style={{marginLeft:"100px", marginRight:"100px"}}>
                            <div className="ddFoodSearchDiv" style={{padding:"5px 8px 0px 10px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={this.props.isDishScreen === true ? 6 : 12}>
                                        <SearchIcon className="ddSearchIcon" style={{top: this.props.isDishScreen === true ? "5px" : "3px"}}/>
                                        <input
                                            type="text"
                                            className="ddSearchTextBox"
                                            placeholder={this.props.isDishScreen === true ? "Search for the dish you are craving.." : "Search for dishes within the restaurant..."}
                                            onChange={(event) => {
                                                this.props.handleSearchInput(event.target.value);
                                                this.setState({
                                                    searchValue: event.target.value
                                                })
                                            }}
                                            value={this.state.searchValue}
                                            style={{width:"calc(95% - 30px)", top: this.props.isDishScreen === true ? "-3px" : "-6px"}}
                                        />
                                        {this.state.searchValue !== "" && <div onClick={() => {
                                            this.setState({searchValue: ""});
                                            this.props.handleSearchInput("");
                                        }} style={{display: "inline-block", backgroundColor: "#ECECEC", borderRadius: "10px", padding: "0px 2px", transform: "translateY(-2px)", cursor: "pointer"}}>
                                            <CloseIcon className="ddSearchIcon" style={{top: "1px", color: "black", fontSize: "14px"}}/>
                                        </div>}
                                    </Grid>
                                        <Grid item xs={6}>
                                            <div style={{float:"right"}}>
                                                {this.state.userLoggedIn === true && this.props.isDishScreen === true ?
                                                    <>
                                                    <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"-5px"}}></span>
                                                    <span onClick={this.personalisedDishes} className="ddFilterPills" 
                                                    style={this.state.personalisedFilterSelected === true ? {background:"#D9B728", color:"#FFFBEA", border:"1px solid #D9B728"} : {background:"#FFFBEA", color:"#D9B728", border:"1px solid #FFFBEA"}}>
                                                        <img src={PersonalisedFilter} alt="personalisedFilter" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        Personalised
                                                    </span>
                                                    </>
                                                : null}
                                                {/* {this.props.isDishScreen === true ? 
                                                    <span onClick={this.freeDeliveryDishes} className="ddFilterPills" 
                                                    style={this.state.freeDeliveryFilterSelected === true ? {background:"#3356AA", color:"#EFF3FF", border:"1px solid #3356AA"} : {background:"#EFF3FF", color:"#3356AA", border:"1px solid #E2EBFF"}}>
                                                        <img src={FreeDeliveryImage} alt="freeDelivery" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        Free Delivery
                                                    </span>
                                                : null} */}
                                                {/* <span onClick={this.offersDishes} className="ddFilterPills" style={this.state.offersPillStyle}><MdOutlineLocalOffer className="ddFilterPillIcons" style={this.state.offersIconStyle} />Offers</span> */}
                                                {this.props.isDishScreen === true ? 
                                                    <span onClick={this.allCuisinesDishes} className="ddFilterPills" 
                                                    style={this.props.cuisinesModal === true ? {background:"#EA5B2F", color:"#FFF5F2", border:"1px solid #EA5B2F"} : {background:"#FFF5F2", color:"#EA5B2F", border:"1px solid #FFECE6"}}>
                                                        <img src={AllCuisines} alt="allCuisines" style={{position:"relative", top:"12px", width:"35px", left:"-8px"}}/>
                                                        All Cuisines
                                                    </span>
                                                : null}
                                            </div>
                                        </Grid>
                                </Grid>
                                {/* <div>
                                    
                                </div>
                                <span style={{float:"right"}}>
                                    
                                </span> */}
                            </div>
                        </Grid>}
                    </Grid>
                </div>
                </>
            )
        }
    }
}

// class DishListHeader extends Component {

//     constructor(){
//         super();
//         this.state = {
//             isPersonalizedShown: true,
//             isMobileScreen: false
//         }
//     }

//     componentWillMount() {
//         this.searchRestaurantDishHandler = this.searchRestaurantDishHandler.bind(this);
//     }

//     searchRestaurantDishHandler(dishId){
//         this.props.searchRestaurantDish(dishId);
//     }

//     updateDimensions() {
//         let screen_width = window.innerWidth;
//         if(screen_width < 500) {
//             this.setState({ isMobileScreen: true });
//         } else {
//             this.setState({ isMobileScreen: false });
//         }
//     }

//     togglePersonalizedHandler(){
//         if (this.props.isLoggedIn){
//             if (this.state.isPersonalizedShown && this.props.isDishScreen){
//                 this.props.closeSearchModal();
//             }
//             this.props.togglePersonalized(!this.state.isPersonalizedShown);
//             this.setState({
//                 isPersonalizedShown: !this.state.isPersonalizedShown
//             })
//         } else {
//             if (this.props.isDishScreen){
//                 this.props.closeSearchModal();
//             }
//         }
//     }

//     checkLoggedIn(headerStyle){
//         if (this.props.isDishScreen){
//             if (this.props.isLoggedIn || this.props.cuisineFilterActive){
//                 return (
//                     <div style={headerStyle} onClick={() => this.togglePersonalizedHandler()} className="DishCardHeaderSupportText">{this.props.isPersonalizedShown ? "Show All" : "Show Preferred Dishes"}</div>
//                 )
//             } else {
//                 return null;
//             }
//         } else {
//             return null;
//         }
//     }

//     checkTotalDishes(totalDishes){
//         if (this.props.initialDishesLoaded){
//             return "(" + totalDishes + ")";
//         } else {
//             return "";
//         }
//     }

//     checkFilters(headerStyle){
//         var totalDishes = this.props.dishes.filter(function(dish) {return dish.vegDisplay && dish.cuisineDisplay && dish.dishTypeDisplay && dish.recommendedDisplay && dish.personalizedDisplay && dish.searchDisplay;}).length;
//         if (this.props.isDishScreen){
//             if (this.props.cuisineFilterActive){
//                 return(
//                     <div style={headerStyle} className="DishCardHeaderText">{totalDishes} Dishes</div>
//                 )
//             } else {
//                 if (this.props.isLoggedIn){
//                     if (this.state.isPersonalizedShown){
//                         return(
//                             <div style={headerStyle} className="DishCardHeaderText">Showing your Preferred Dishes {this.checkTotalDishes(totalDishes)}</div> 
//                         )
//                     } else {
//                         return(
//                             <div style={headerStyle} className="DishCardHeaderText">Showing Best Dishes Only</div> 
//                         )
//                     }
//                 } else {
//                     return(
//                         <div style={headerStyle} className="DishCardHeaderText">Only the Best Dishes</div> 
//                     )
//                 }
//             }
//         } else {
//             return(
//                 <div style={headerStyle} className="DishCardHeaderText">Only the Best Dishes</div> 
//             )
//         }
//     }

//     checkSearchBar(){
//         if (this.state.isMobileScreen){
//             return null;
//         } else {
//             return <SearchBar
//                 dishes={this.props.dishes}
//                 nonRatedDishes={this.props.nonRatedDishes}
//                 searchRestaurantDish={this.searchRestaurantDishHandler}
//             />;
//         }
//     }

//     render() {
//         var headerStyle = {};
//         if (this.props.activeHeaderType === 'header'){
//             headerStyle = {transform: "translateX(5vw)"};
//         } else {
//             headerStyle = {transform: "translateX(5vw)"};
//         }
//         if (this.state.isMobileScreen){
//             return null;
//         } else {
//             if (this.props.isDishScreen){
//                 return(
//                     <Grid container item xs={this.props.gridSize}>
//                         <Grid item xs={12}>
//                             <div className="DishCardHeaderContainer" style={{paddingTop: this.props.activeHeaderType === 'header' ? '20px' : '10px'}}>
//                                 {this.checkFilters(headerStyle)}
//                                 {this.checkLoggedIn(headerStyle)}
//                             </div>
//                         </Grid>
//                     </Grid>
//                 )
//             } else {
//                 return(
//                     <Grid container alignItems="flex-start" justifyContent="flex-start" alignContent="flex-start" item xs={this.props.gridSize}>
//                         <Grid item xs={4}>
//                             <div className="DishCardHeaderContainer">
//                                 {this.checkFilters(headerStyle)}
//                                 {this.checkLoggedIn(headerStyle)}
//                             </div>
//                         </Grid>
//                         {this.checkSearchBar()}
//                     </Grid>
//                 )
//             }
//         }
//     }
// }

// class SearchBar extends Component {

//     constructor(props){
//         super(props);
//         this.state = {
//             activeOption: -1,
//             searching: false,
//             filteredOptions: [],
//             showOptions: false,
//             userInput: '',
//             options: []
//         };
//         this.onChange = this.onChange.bind(this);
//         this.onClick = this.onClick.bind(this);
//         this.onKeyDown = this.onKeyDown.bind(this);
//         this.clearSearch = this.clearSearch.bind(this);
//         this.checkSearch = this.checkSearch.bind(this);
//     }

//     onChange = (e) => {
    
//         const options = [];
//         const ids = [];
//         options.push(...this.props.dishes);
//         this.props.dishes.forEach(function(dish){
//             ids.push(dish.dishId);
//         })
//         this.props.nonRatedDishes.forEach(function(dishType){
//             if (Object.keys(dishType).length!==0){
//                 dishType["dishes"].forEach(function(dish){
//                     if (!ids.includes(dish.dishId)){
//                         options.push(dish);
//                         ids.push(dish.dishId);
//                     }
//                 })
//             }
//         })
//         const userInput = e.currentTarget.value;
    
//         const filteredOptions = options.filter(
//           (optionName) =>
//             optionName.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
//         );
    
//         this.setState({
//           activeOption: -1,
//           filteredOptions,
//           showOptions: true,
//           userInput: e.currentTarget.value
//         });
//         if (e.currentTarget.value !== ""){
//             this.setState({
//                 searching : true
//             })
//         } else {
//             this.setState({
//                 searching : false
//             })
//         }
//     };

//     onClick(dish) {
//         this.props.searchRestaurantDish(dish.dishId);
//         this.setState({
//           activeOption: -1,
//           filteredOptions: [],
//           showOptions: false,
//           userInput: dish.name,
//           searching: false
//         });
//     };

//     onKeyDown = (e) => {
//         const { activeOption, filteredOptions } = this.state;

//         if (e.keyCode === 13) {
//             if (activeOption !== -1){
//                 this.setState({
//                     activeOption: -1,
//                     showOptions: false,
//                     userInput: filteredOptions[activeOption],
//                     searching: false
//                 });
//             }
//         } else if (e.keyCode === 38) {
//             if (activeOption <= 0) {
//                 this.setState({ activeOption: filteredOptions.length - 1 });
//             } else {
//                 this.setState({ activeOption: activeOption - 1 });
//             }
//         } else if (e.keyCode === 40) {
//             if (activeOption === filteredOptions.length - 1) {
//                 this.setState({ activeOption: 0 });
//                 return;
//             }
//             this.setState({ activeOption: activeOption + 1 });
//         }
//     };

//     clearSearch = (e) => {
//         this.setState({
//             userInput : "",
//             activeOption : -1,
//             filteredOptions: [],
//             showOptions: false,
//             searching: false
//         }) 
//         this.props.searchRestaurantDish(-1);
//     }

//     checkSearch() {
//         this.setState({
//             activeOption : -1,
//             filteredOptions: [],
//             showOptions: false,
//             searching: false
//         }) 
//     }

//     render() {
//         let optionList;
//         let searchBarColor;
//         let containerShadow;
//         let crossIcon;
//         if (this.state.showOptions && this.state.userInput) {
//             if (this.state.filteredOptions.length) {
//               optionList = (
//                 <div className="SearchBarOptions" style={{top: "36px", maxHeight: "300px", overflow: "scroll"}}>
//                   {this.state.filteredOptions.map((optionName, index) => {
//                     let className;
//                     if (index === this.state.activeOption) {
//                       className = 'FilteredOptionActive';
//                     }
//                     return (
//                       <div className={className + " FilteredOption"} key={optionName.dishId} onMouseDown={() => this.onClick(optionName)}>
//                         {optionName.name}
//                       </div>
//                     );
//                   })}
//                 </div>
//               );
//             } else {
//               optionList = (
//                 <div className="SearchBarOptions" style={{top: "36px"}}>
//                     <div className="FilteredOptionNotFound">No dish present...</div>
//                 </div>
//               );
//             }
//         }
//         if (this.state.searching){
//             searchBarColor = {backgroundColor: "#F9F9F9"}
//             containerShadow = {boxShadow: "3px 3px 12px #1B1B1B59"};
//         } else {
//             searchBarColor = {backgroundColor: "white"}
//             containerShadow = {};
//         }
//         if (this.state.userInput){
//             crossIcon = (<span className="CancelSearch" onMouseDown={this.clearSearch}>X</span>);
//         } else {
//             crossIcon = (<span></span>)
//         }
//         return (
//             <Grid container item xs={8}>
//                 <Grid item xs={12}>
//                     <div style={containerShadow} className="SearchBarSuper">
//                         <div style={searchBarColor} className="SearchBarContainer First">
//                             <SearchIcon className="SearchIcon"/>
//                             <input
//                                 type="text"
//                                 className="SearchBar"
//                                 style={{paddingTop: "0px"}}
//                                 onChange={this.onChange}
//                                 onKeyDown={this.onKeyDown}
//                                 value={this.state.userInput}
//                                 onBlur={this.checkSearch}
//                                 placeholder="Search for dishes within the restaurant..."
//                             />
//                             {crossIcon}
//                         </div>
//                         {optionList}
//                     </div>
//                 </Grid>
//             </Grid>
//         );
//     }
// }

// class Filters extends Component {

//     constructor(props){
//         super(props);
//         var filters;
//         if (this.props.isDishScreen){
//             filters = [
//                 {id: 1, name: "Recommended", isActive: false},
//                 {id: 2, name: "Ratings", isActive: false},
//                 {id: 3, name: "Price", isActive: false},
//                 {id: 4, name: "Cuisines", isActive: false},
//             ];
//         } else {
//             filters = [];
//         }
//         this.state = {
//             priceFilter: "descending",
//             filters: filters,
//             toggleChecked: false,
//             isMobileScreen: false
//         };
//         this.changeActiveTab = this.changeActiveTab.bind(this);
//         this.checkPriceArrow = this.checkPriceArrow.bind(this);
//         this.handleToggle = this.handleToggle.bind(this);
//     }

//     componentDidMount() {
//         this.updateDimensions();
//         window.addEventListener("resize", this.updateDimensions.bind(this));
//     }

//     updateDimensions() {
//         let screen_width = window.innerWidth;
//         if(screen_width < 500) {
//             this.setState({ isMobileScreen: true });
//         } else {
//             this.setState({ isMobileScreen: false });
//         }
//     }

//     handleToggle(toggleChecked) {
//         if(this.props.allDishesLoaded){
//             this.setState({ toggleChecked });
//             this.props.toggleVegDishes(toggleChecked);
//         }
//       }

//     changeActiveTab(id){
//         if (this.props.initialDishesLoaded) {
//             let items = [...this.state.filters];
//             var isRecommended = items[0].isActive;
//             if (id === 0){
//                 this.props.showRecommendedDishes(!items[0].isActive);
//             }
//             else if (id === 1){
//                 this.props.showRatingSortedDishes();
//             }
//             else if (id === 2){
//                 if (items[id].isActive === true){
//                     if (this.state.priceFilter === "descending"){
//                         this.setState({
//                             priceFilter: "ascending"
//                         })
//                         this.props.showPriceSortedDishes("ascending");
//                     } else {
//                         this.setState({
//                             priceFilter: "descending"
//                         })
//                         this.props.showPriceSortedDishes("descending");
//                     }
//                 } else {
//                     this.setState({
//                         priceFilter: "descending"
//                     })
//                     this.props.showPriceSortedDishes("descending");
//                 }
//             }
//             else{
//                 this.props.showCuisinesModal();
//             }
//             for (var i=0; i <this.state.filters.length; i++){
//                 if (id !== i){
//                     if (id !== 0){
//                         items[i].isActive = false;
//                     }
//                 } else {
//                     if (i!==0){
//                         if (items[i].isActive === false){
//                             items[i].isActive = true;
//                         }
//                     } else {
//                         items[i].isActive = !items[i].isActive;
//                     }
//                 }
//             }
//             if (id !== 0){
//                 items[0].isActive = isRecommended;
//             }
//             this.setState({
//                 filters: items  
//             })
//         }
//     }

//     checkPriceArrow(id){
//         if (id === 3){
//             if (this.state.priceFilter === "descending"){
//                 return (<ArrowDropDown className="PriceArrow"/>);
//             } else {
//                 return (<ArrowDropUp className="PriceArrow"/>);
//             }
//         } else if (id === 4){
//             return (
//                 <div style={{display: "inline-block"}}>
//                     <img style={{height: this.state.isMobileScreen ? "16px" : "24px", marginLeft: this.state.isMobileScreen ? "5px" : "10px", transform: this.state.isMobileScreen ? "translateY(3px)" : "translateY(5px)"}} src={cuisine_red} alt="dish" />
//                 </div>
//             )
//         } else {
//             return (<span></span>);
//         }
//     }

//     showVegIcon() {
//         if (this.props.activeHeaderType === 'header' && !this.state.isMobileScreen){
//             return (
//                 <label className="VegToggleContainer" style={{marginRight: "7vw"}}>
//                     <Switch 
//                         onChange={this.handleToggle} 
//                         checked={this.state.toggleChecked} 
//                         uncheckedIcon={false}
//                         checkedIcon={false}
//                         height={this.state.isMobileScreen ? 12 : 20}
//                         width={40}
//                         className="VegToggle"
//                         offHandleColor="#A9A9A9"
//                         // onHandleColor="#A9A9A9"
//                         handleDiameter={14}
//                         offColor="#ECECEC"
//                     />
//                     <span className="VegToggleText">Veg Only</span>
//                 </label>
//             )
//         } else {
//             return null;
//         }
//     }
    
//     render() {
//         let position;
//         let buttonStyle = [];
//         let borderStyle = [];
//         if (this.props.gridSize === 8 || this.props.gridSize === 5){
//             position = "flex-end";
//         } else {
//             position = "flex-start";
//         }
//         for (var i=0; i<this.state.filters.length; i++){
//             buttonStyle.push({});
//             borderStyle.push({});
//             if (this.state.filters[i].isActive === true) {
//                 buttonStyle[i] = {fontWeight: "bold"};
//                 borderStyle[i] = {width: "40%"};
//             } else {
//                 buttonStyle[i] = {};
//                 borderStyle[i] = {width: "0%"};
//             }
//         }
//         return (
//             <Grid container justifyContent={position} item xs={this.props.gridSize} spacing={0}>
//                 <div className="FiltersContainer" style={{marginRight: this.props.activeHeaderType === "header" ? "0px" : "5.2vw"}}>
//                     <React.Fragment>
//                         <Grid container item alignItems="flex-start" justifyContent={position}>
//                             <Grid>
//                                 {
//                                     this.state.filters.map((filter) => {
//                                         return(
//                                             <div onClick={() => this.changeActiveTab(filter.id - 1)} key={filter.id} variant="contained" className="filter-button">
//                                                 <span className="FilterButton" style={buttonStyle[filter.id - 1]}>{filter.name}</span>
//                                                 {this.checkPriceArrow(filter.id)}
//                                                 <div style={borderStyle[filter.id - 1]} className="filter-button-border"></div>
//                                             </div>
//                                         )
//                                     })
//                                 }
//                                 {this.showVegIcon()}
//                             </Grid>
//                         </Grid>
//                     </React.Fragment>
//                 </div>
//             </Grid>
//         )
//     }
// }

export default Actions;