import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import SearchIcon from '@material-ui/icons/Search';

class DishCuisinesModal extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this); 
        this.changeActiveTab = this.changeActiveTab.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.updateCuisinesList=this.updateCuisinesList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.itemStyle = this.itemStyle.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.state = {
            cuisineCount: this.props.cuisineCount,
            moreCuisineLoading: this.props.moreCuisineLoading
        }
    }

    applyFilters(){
        this.props.closeCuisinesModal();
        this.props.clearSearch();
        this.props.applyFilters();
    }

    closeModalHandler() {
        this.props.closeCuisinesModal();
        this.props.clearSearch();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    changeActiveTab(id){
        this.props.changeActiveTab(id);
    }

    clearFilters(){
        this.props.clearFilters();
        this.props.clearSearch();
        this.props.closeCuisinesModal();
    }

    updateCuisinesList(filteredOptions){
        this.props.updateCuisinesList(filteredOptions);
    }

    clearSearch(){
        this.props.clearSearch();
    }

    itemStyle(isActive){
        if (isActive){
            return {fontFamily: "Montserrat-Bold", color: "#FF052E"};
        } else {
            return {};
        }
    }
    
    render() {
        if(!this.props.showCuisinesModal){
            return null;
        }
        let noCuisinesFound;
        let items = [...this.props.cuisines];
        let cuisinesPresent = false;
        for (var i=0; i<items.length; i++){
            if (items[i].display){
                cuisinesPresent = true;
                break;
            }
        }
        if (!cuisinesPresent){
            noCuisinesFound = (
                <div style={{width: "100%"}}>
                    <div style={{margin: "auto", width: "180px", marginTop: "20px", fontFamily: "Montserrat-Regular", fontSize: "14px"}}>No cuisines found....</div>
                </div>
            )
        } else {
            noCuisinesFound = null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div onClick={(e) => this.handleModalClick(e)} className="RatingModalContainer">
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div className="CuisinesHeader">
                        Cuisines
                    </div>
                    <SearchBar
                        cuisinesList={this.props.cuisines}
                        updateCuisinesList={this.updateCuisinesList}
                        clearSearch={this.clearSearch}
                    />
                    <div className="CuisinesList">
                        {this.props.cuisines.map((cuisine) => {
                            if (cuisine.display){
                                return (
                                    <div key={cuisine.id} className="CuisinesColumn">
                                        <div style={this.itemStyle(cuisine.isActive)} onMouseDown={() => this.changeActiveTab(cuisine.id)} className="CuisinesItem">
                                            {cuisine.name}
                                        </div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                        {noCuisinesFound}
                    </div>
                    <div className="CuisinesButtons">
                        <div className="CuisinesApply" onClick={this.applyFilters}>Apply</div>
                        <div className="CuisinesClear" onClick={this.clearFilters}>Clear</div>
                    </div>
                </div>
            </div>
        )
    }
}

class SearchBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            userInput: '',
        };
        this.onChange = this.onChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    onChange = (e) => { 
        const options = [];
        const userInput = e.currentTarget.value;
        for (var i=0; i<this.props.cuisinesList.length; i++){
            options.push(this.props.cuisinesList[i].name);
        }
    
        const filteredOptions = options.filter(
          (optionName) =>
            optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        
        this.props.updateCuisinesList(filteredOptions);
    
        this.setState({
          userInput: e.currentTarget.value
        });
    };

    clearSearch = (e) => {
        this.setState({
            userInput : "",
        }) 
        this.props.clearSearch(); 
    }

    render() {
        let crossIcon;
        if (this.state.userInput){
            crossIcon = (<span className="CancelSearch" onMouseDown={this.clearSearch}>X</span>);
        } else {
            crossIcon = (<span></span>)
        }
        return (
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}></div>
                <div className="SearchBarSuper">
                    <div className="SearchBarContainer First">
                        <div style={{flex: 1}}></div>
                        <SearchIcon className="SearchIcon"/>
                        <input
                            type="text"
                            className="SearchBar"
                            onChange={this.onChange}
                            value={this.state.userInput}
                            placeholder="Search for Cuisines..."
                            style={{width: "200px", padding: "10px"}}
                        />
                        {crossIcon}
                    </div>
                </div>
            </div>
        );
    }
}

export default DishCuisinesModal;