import React, { Component } from 'react';
import ItemsCarousel from 'react-items-carousel';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import offers1 from '../../assets/images/image0.png';
import offers2 from '../../assets/images/image1.png';
import offers3 from '../../assets/images/image2.jpeg';
import offers4 from '../../assets/images/image3.jpeg';
import offers5 from '../../assets/images/image4.jpeg';
import offers6 from '../../assets/images/image5.jpeg';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SearchBar from '../Commons/SearchBar';

class Offers extends Component {

    constructor(props){
        super(props);
        this.showOffers = this.showOffers.bind(this);
        this.applyCuisineSearchHandler = this.applyCuisineSearchHandler.bind(this);
        this.applyDishTypeSearchHandler = this.applyDishTypeSearchHandler.bind(this);
        this.clearFiltersHandler = this.clearFiltersHandler.bind(this);
        this.clearSearchFilterHandler = this.clearSearchFilterHandler.bind(this);
        this.searchDishHandler = this.searchDishHandler.bind(this);
        this.closeSearchModalHandler = this.closeSearchModalHandler.bind(this);
        this.state = {
            offerImages: [offers1, offers2, offers3, offers4, offers5, offers6],
            offerBackground: ["#EA5B2F", "#FFFFFF", "#F9E841" , "#FFFFFF"],
            isMobileScreen: false
        }
    }

    componentWillMount() {
        this.setState({
          activeItemIndex: 0,
          noOfItems: 0
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
      }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1, isMobileScreen: true });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 1, isMobileScreen: false });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 2, isMobileScreen: false }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 3, isMobileScreen: false }); 
        } else {
            this.setState({noOfItems: 3, isMobileScreen: false});
        }
    }

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    getImageIndex(index){
        return index % 6;
    }

    showOffers(activeItemIndex, noOfItems, chevronWidth){
        if (this.props.offersFetched){
            if (this.props.offers.length){
                return(
                    <ItemsCarousel
                            requestToChangeActive={this.changeActiveItem}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={noOfItems}
                            gutter={20}
                            leftChevron={<div className="OffersCarouselPrevSlide">{'<'}</div>}
                            rightChevron={<div className="OffersCarouselNextSlide">{'>'}</div>}
                            outsideChevron
                            chevronWidth={chevronWidth}
                        >
                            {this.props.offers.map((offer) => {
                                return(
                                    <Link key={offer.offerId}  to={'/restaurants/' + offer.restaurantId} style={{ textDecoration: 'none' }}>
                                        <div key={offer.offerId} style={{backgroundImage: `url(${this.state.offerImages[this.getImageIndex(offer.id)]})`}} className="OffersCard">
                                            <div style={{color: offer.id % 2 === 0 ? "#3356AA" : "#3356AA"}} className="OffersText">
                                                <div className="OffersCount">{offer.restaurant.toString().toUpperCase()}</div>
                                                <div className="OffersDiscount">{offer.tagline}</div>
                                                <div className="OffersDescription">{offer.description}</div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </ItemsCarousel>
                )
            } else {
                return (
                    <div>
                        <div style={{margin: "auto", color: "white", fontSize: "20px", textAlign: "center"}}>No offers Found</div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div style={{margin: "auto", color: "white", fontSize: "20px", textAlign: "center"}}>Loading Offers...</div>
                </div>
            )
        }
    }

    applyCuisineSearchHandler(id){
        this.props.applyCuisineSearch(id);
    }

    applyDishTypeSearchHandler(id){
        this.props.applyDishTypeSearch(id);
    }

    clearFiltersHandler(){
        this.props.clearFilters();
    }

    searchDishHandler(id){
        this.props.searchDish(id);
    }

    clearSearchFilterHandler(){
        this.props.clearSearchFilter();
    }

    closeSearchModalHandler(){
        this.props.closeSearchModal();
        this.props.clearFilters();
        this.props.clearSearchFilter();
    }

    render() {
        const chevronWidth = 40;
        const {
            activeItemIndex,
            noOfItems
          } = this.state;
        let searchBarPopup;
        if (this.props.showSearchModal){
            searchBarPopup = (<div className="GlobalSearchBarContainer">
                <SearchBar
                    cuisines={this.props.cuisines}
                    dishes={this.props.dishes}
                    history={this.props.history}
                    applyCuisineSearch={this.applyCuisineSearchHandler}
                    applyDishTypeSearch={this.applyDishTypeSearchHandler}
                    clearFilters={this.clearFiltersHandler}
                    searchDish={this.searchDishHandler}
                    clearSearchFilter={this.clearSearchFilterHandler}
                    isDishScreen={this.props.isDishScreen}
                />
            </div>);
        } else {
            searchBarPopup = null;
        }
        if (this.props.nonServicable && this.props.isDishScreen){
            return null;
        } else {
            return (
                <div onClick={this.closeSearchModalHandler} className={this.state.isMobileScreen ? "OffersCarouselContainerM":"OffersCarouselContainer"}>
                    {searchBarPopup}
                    <div className="OffersCarousel" style={{ padding: `0 ${chevronWidth}px` }}>
                        {this.showOffers(activeItemIndex, noOfItems, chevronWidth)}
                    </div>
                </div>
            )
        }
    }
}


export default withRouter(Offers);