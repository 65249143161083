import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { MdLocationOn } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";

class OrderDetails extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
    }

    closeModalHandler() {
        this.props.closeOrderDetailsModal();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    itemList(orderItem){
        var itemKey = Object.keys(orderItem.items)
        var itemValue = Object.values(orderItem.items)
        var cartList = []
        var sumTotal = 0
        for(let i = 0; i < itemValue.length; i++){
            if(itemValue[i].customizations[0].combination !== null){
                for(var j=0; j<itemValue[i].customizations.length; j++ ){
                    cartList.push({
                        "dishId": Number(itemKey[i]), 
                        "name": itemValue[i].name,
                        "image": itemValue[i].image,
                        "cost": itemValue[i].customizations[j].sub_total, 
                        "choice": itemValue[i].choice, 
                        "DishQuantity": itemValue[i].customizations[j].quantity,
                        "Customisation": itemValue[i].customizations[j], 
                        "restaurant": orderItem.restaurant.name
                    });
                    sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
                }
            }
            else{
                cartList.push({
                    "dishId": Number(itemKey[i]), 
                    "name": itemValue[i].name,
                    "image": itemValue[i].image,
                    "cost": itemValue[i].item_total, 
                    "choice": itemValue[i].choice, 
                    "DishQuantity": itemValue[i].quantity,
                    "restaurant": orderItem.restaurant.name,
                    "Customisation": {"combination": null, "quantity": itemValue[i].quantity, "sub_total": itemValue[i].item_total}
                });
                sumTotal = sumTotal + itemValue[i].item_total
            }
        }

        const listItems = cartList.map((keyItem) =>
            <Grid key={"CartItem_"+cartList.indexOf(keyItem)} item xs={12}>
                <div style={{padding:"10px 0px"}}>
                    <div className="cart-item-details">
                        <div className="cart-item-names" style={this.props.isMobileScreen ? {marginLeft:"10px"} : {}}>
                            <Grid container spacing={0} >
                                <Grid item xs={1} style={{position:"relative"}}>
                                    <span style={{top:"1px", position:"absolute", left:"0px"}}>{keyItem.choice === "V" ? <FiberManualRecordIcon className="vegTag" style={{fontSize:"13px"}}/> : <IoTriangleSharp className="nonVegTag" style={{fontSize:"13px"}}/>}</span>
                                </Grid>
                                <Grid item xs={9}>
                                    <div>{keyItem.DishQuantity}x {keyItem.name}</div>
                                    {keyItem.Customisation.combination !== null && <div className="ddCustomisationText" style={this.props.isMobileScreen ? {width:"95%"} : {}}>{this.props.getCustomisationText(keyItem.Customisation)}</div>}
                                </Grid>
                                <Grid item xs={2} style={{position:"relative"}}>
                                    <span style={{fontFamily: "Poppins-Regular", position:"absolute", right:"0px", color:"#5E4B45"}}>Rs. {keyItem.cost}</span>
                                </Grid>
                            </Grid>
                        </div>
                        {/* {keyItem.image !== null && 
                            <img className="cart-food-image" src={keyItem.image} alt={keyItem.name} />
                        } */}
                    </div>
                </div>
            </Grid>
        );
        return(
            <div style={this.props.isMobileScreen ? {borderBottom:"1px solid #E6E6E6"} : {padding:"10px 0px", borderBottom:"1px solid #E6E6E6"}} className="ddOrderItemBox">
                {listItems}
            </div>
        );
    }

    getDeliveredAt(deliveredAt){
        var momentTz = require('moment-timezone');
        var date = momentTz.tz(deliveredAt, 'Asia/Kolkata').format('llll');
        return date;
    }

    render() {
        if(!this.props.showOrderDetailsModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={this.props.isMobileScreen ? {height: "unset", margin: "auto", width:"90%"} : {height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="ddOrderDetailsModalContainer">
                    <AiOutlineCloseCircle size="1.5em" style={{position:"absolute", color:"#626262", right:"10px", top:"10px"}} onClick={this.closeModalHandler}/>
                    <div style={this.props.isMobileScreen ? {padding: "20px 15px"} : {padding: "20px"}}>
                        <div style={{fontFamily: "Quicksand-Regular"}}>Order ID. #{this.props.orderToShow.order_id}</div>
                        <div style={{fontFamily: "Poppins-Medium", fontSize:"14px"}}>Delivered on {this.getDeliveredAt(this.props.orderToShow.delivered_at)}</div>
                        <div style={{fontFamily: "Poppins-Regular", fontSize:"14px", color:"#EA5B2F"}}>{this.props.restoNameAddress}</div>
                        <div style={{fontFamily: "Poppins-Regular", fontSize:"14px", color:"#83706A"}}>{this.props.itemsCount(this.props.orderToShow) > 1 ? this.props.itemsCount(this.props.orderToShow) + " Items" : this.props.itemsCount(this.props.orderToShow) + " Item"}</div>
                        {this.itemList(this.props.orderToShow)}
                        <Grid container spacing={0} >
                            <Grid item xs={12} style={{paddingTop:'10px'}}>
                                <div style={{fontFamily:"Poppins-Medium", color:"#1A1A1A"}}>Summary</div>
                            </Grid>
                            <Grid item xs={7} style={{paddingTop:'10px'}}>
                                {this.props.orderToShow.delivery_mode !== "SELF_PICKUP" && <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"#5E4B45"}}>Delivery Charges</div>}
                                <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"#5E4B45"}}>Taxes & Charges</div>
                                <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:'rgb(79, 147, 236)'}}>Daidish Discount</div>
                                {/* <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"rgb(248, 0, 165)"}}>Restaurant Discount</div> */}
                            </Grid>
                            <Grid item xs={5} style={{textAlign:'right', paddingTop:'10px'}}>
                                {this.props.orderToShow.delivery_mode !== "SELF_PICKUP" && <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"#5E4B45"}}>Rs {this.props.orderToShow.other_charges.delivery_charge}</div>}
                                <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"#5E4B45"}}>Rs {this.props.orderToShow.other_charges.other_charges}</div>
                                <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:'rgb(79, 147, 236)'}}>- Rs {this.props.orderToShow.extra_data.discounts.length > 0 ? this.props.orderToShow.extra_data.discounts[0].discount : 0}</div>
                                {/* <div style={{fontFamily:"Poppins-Regular", fontSize:"14px", color:"rgb(248, 0, 165)"}}>- Rs {this.props.orderToShow.extra_data.discounts.length > 1 ? this.props.orderToShow.extra_data.discounts[1].discount: 0}</div> */}
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={0} style={{padding:"10px", background:"#F8F8F8", boxShadow:"0px -3px 20px #0000001D", borderBottomRightRadius:"20px", borderBottomLeftRadius:"20px"}}>
                        <Grid item xs={7} style={{paddingTop:'0%', fontStyle:"bold"}}>
                            <span className="summary-header">GRAND TOTAL</span>
                        </Grid>
                        <Grid item xs={5} style={{textAlign:'right', paddingTop:'0%', fontStyle:"bold"}}>
                            <span className="summary-header">Rs {this.props.orderToShow.payment_details.payment_amount}</span>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            {this.props.orderToShow.delivery_mode === "SELF_PICKUP" ? 
                                <>
                                    <div style={{fontFamily: "Poppins-Regular", color:"#EA5B2F", fontSize:"16px"}}>Order Picked up from</div>
                                    <div style={{fontFamily: "Poppins-Regular", color:"#808080", fontSize:"14px"}}>{this.props.orderToShow.restaurant.full_address}</div>
                                </>
                            : 
                                <>
                                    <div style={{fontFamily: "Poppins-Regular", color:"#EA5B2F", fontSize:"16px", marginLeft:"-5px"}}><MdLocationOn style={{top:"3px", position:"relative"}}/> {this.props.orderToShow.delivery_address.tag}</div>
                                    <div style={{fontFamily: "Poppins-Regular", color:"#808080", fontSize:"14px", marginLeft:"16px"}}>{this.props.orderToShow.delivery_address.address}</div>
                                </>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withRouter(OrderDetails);