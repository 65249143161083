import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import OrderCard from './OrderCard';
import { withRouter } from 'react-router-dom';


class UserCart extends Component {

    constructor(props) {
      super(props);
      this.updateCartChanged = this.updateCartChanged.bind(this);
      this.updateCartModalDisplayHandler =  this.updateCartModalDisplayHandler.bind(this);
      this.state = { 
        isMobileScreen: false,
        cartButtonShowHide: "-61px",
        showOrderCard: true,
        showDeliveryAddressCard: false,
        showOrderSummary: false,
        showPaymentCard: false,
        showOrderConfirmation: false,
        showOrderStatus: false
      };
    } 

    componentDidUpdate() {
        if (this.props.cartChanged === true){
            this.props.updateCartChangedValue(false);
        }
    }
  
    componentDidMount(){
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }

    updateCartChanged(value) {
        this.props.updateCartChangedValue(value);
    }

    checkUserLogIn() {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            return true;
        } else {
            return false;
        }
    }

    updateCartModalDisplayHandler(value) {
        this.props.updateCartModalDisplay(value);
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    render() {
        if (this.checkUserLogIn() === true && this.props.showCart){
            return(
                <div className="RatingModal" onClick={() => this.props.updateCartModalDisplay(false)}>
                    <div onClick={(e) => this.handleModalClick(e)} style={{position:"relative"}}>
                        <Card id={this.state.isMobileScreen ? "cart-cardM" : "cart-card"} elevation={8} style={{right: "-10px", overflowX: 'hidden', width: this.state.isMobileScreen && "100vw"}}>
                            {this.state.showOrderCard && 
                                <OrderCard 
                                    updateCartModalDisplay={this.updateCartModalDisplayHandler} 
                                    itemsInCart={this.props.itemsInCart} 
                                    cartChanged={this.props.cartChanged} 
                                    updateCartChangedValue={this.updateCartChanged} 
                                    restaurantId={this.props.restaurantId} 
                                    history={this.props.history}
                                    loadRestaurantDetails={this.props.loadRestaurantDetails}
                                    toggleConfirmationBox={this.props.toggleConfirmationBox}
                                    defaultAddresses={this.props.defaultAddresses}
                                    addresses={this.props.addresses}
                                />}
                        </Card>
                    </div>
                </div>
            );
        } else { 
            return null;
        }
    }
}

export default withRouter(UserCart);