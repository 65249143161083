import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import home_burger from '../../assets/images/landing_page_illustration.webp';
import '../../assets/styles/HomeNew.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { withRouter } from 'react-router';
import Geocode from "react-geocode";


Geocode.setApiKey('AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4');
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

// Top Header which contains logo, auth options, burger icon and header description
class BottomHeader extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <HeaderDescription
                            authModalOpen={this.props.authModalOpen}
                            history={this.props.history}
                        />
                        {/* <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <HeaderDescription
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                            />
                        </Grid>  */}
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <HeaderDescription
                            authModalOpen={this.props.authModalOpen}
                            history={this.props.history}
                        />
                        {/* <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <HeaderDescription
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                            />
                        </Grid>  */}
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "#FFFBE8"};
    } else {
        headerStyle = {background: "#FFFBE8"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {overflow: 'visible', height: 'unset', padding: '40px 80px'};
            burgerStyle = {width: '30vw', height: 'unset', transform: 'none'};
        }
        if (this.state.isMobileScreen){
            return (
                <Grid style={{paddingTop: "0px", height: 'unset', paddingBottom: '60px'}} className="BurgerContainerNew" container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                    <img style={{width: 'calc(100vw - 20px)', transform: 'translateY(0px)', height: 'unset'}} className="BurgerNew" src={home_burger} alt="burger_img"/>
                </Grid>
            )
        } else {
            return (
                <Grid style={burgerContainerStyle} className="BurgerContainerNew" container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                    <img style={burgerStyle} className="BurgerNew" src={home_burger} alt="burger_img"/>
                </Grid>
            )
        }
    }
}

// Description present in header
class HeaderDescription extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address: '',
            latitude: '',
            longitude: '',
            isMobileScreen: false,
            locationErrorMessage: '',
            clearUserSuggestions: false
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.hitLocationAPI = this.hitLocationAPI.bind(this);
        this.showLocationError = this.showLocationError.bind(this);
    }
    
    handleChange = address => {
        var lenDiff = address.length - this.state.address.length;
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address
        })
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    handleSelect = address => {
        this.setState({
            address: address,
            clearUserSuggestions: true
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        }, this.setLocation)
    }

    setLocation(){
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            localStorage.setItem("user_address", this.state.address);
            this.props.history.push('/home');
        }
    }

    getCurrentLocation(){
        this.setState({
            locationErrorMessage: "Getting current location, please wait..."
        });
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitLocationAPI, this.showLocationError);
        } else {
            this.setState({
                locationErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser"
            });
        }
    }

    hitLocationAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const address = response.results[0].formatted_address;
              localStorage.setItem("user_latitude", this.state.latitude);
                localStorage.setItem("user_longitude", this.state.longitude);
                localStorage.setItem("location_present", "yes");
                localStorage.setItem("user_address", address);
                this.props.history.push('/home');
            }
        );
    }

    showLocationError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                locationErrorMessage: "Please enable the location access to proceed further",
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
            });
            break;
          case error.TIMEOUT:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay"
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
          default:
            this.setState({
                locationErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
            });
            break;
        }
    }

    handleKeyPress = (event) => {
        // if (event.keyCode === 8 || event.keyCode === 46){
        //     this.setState({
        //         clearUserSuggestions: true
        //     })
        // } else {
        //     this.setState({
        //         clearUserSuggestions: false
        //     })
        // }
    }

    showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading){
        if (this.state.address.length > 1 && !this.state.clearUserSuggestions){
            return (
                <div className="autocomplete-dropdown-container PlaceSuggestn"
                    style={{
                        width: this.state.isMobileScreen ? "72vw" : "36vw",
                        marginLeft: "1vw",
                        backgroundColor: "#F0F0F0",
                        zIndex: '100',
                        position: 'absolute',
                        top: '50px',
                        paddingTop: suggestions.length > 0 ? '30px' : '0px',
                        paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        overflow: 'hidden'
                    }}
                >
                {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                    return (<div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        })}
                        key={suggestion.formattedSuggestion.mainText}
                    >
                        <div style={{paddingTop: "2px"}}>
                            <LocationOnIcon fontSize='small'/>
                        </div>
                        <div>
                        <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px'}}>{suggestion.formattedSuggestion.mainText}</div>
                        <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: '12px'}}>{suggestion.formattedSuggestion.secondaryText}</div>
                        </div>
                    </div>)
                })}
            </div>
            )
        } else {
            return null;
        }
    }

    render(){
        if (!this.state.isMobileScreen) {
            return (
                <div style={{display: 'flex', flexDirection: 'row', paddingTop: '40px'}}>
                    <div>
                        <div style={{color: '#3356AB', paddingLeft: '10vw', fontSize: '40px', marginBottom: '20px'}} className='HomeTitleNew'>
                            Ready to eat <span className='HomeTitleRed'>the Best?</span>
                        </div>
                        <div style={{fontSize: '1.1vw', width: '38vw', paddingLeft: '10vw', margin: 'auto', marginTop: '0px', marginBottom: '20px', color: '#171717'}} className="WhyDaidishDetails">
                            Browse through the Best Dishes around you & order now
                        </div>
                        <div style={{paddingLeft: '10vw',}} className='LocationContainerNew'>
                            <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={{
                                    componentRestrictions: {country: ['in']}
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{position: 'relative', zIndex: 'inherit'}}>
                                    <div style={{ zIndex: 'inherit'}} className="LocationInputContainerNew">
                                        <input
                                        className="LocationInputNew"
                                        {...getInputProps({
                                            placeholder: 'Enter your location here',
                                        })}
                                        onKeyPress={this.handleKeyPress}
                                        style={{
                                            border: "#E6E6E6 1px solid !important",
                                            borderTopLeftRadius: "30px",
                                            borderBottomLeftRadius: "30px",
                                            backgroundColor: "white",
                                            width: "36vw",
                                            padding: "15px 5px",
                                            fontFamily: 'Montserrat-Regular',
                                            fontSize: "14px",
                                            paddingLeft: "1.5vw",
                                            color: "black",
                                            zIndex: 'inherit',
                                            boxShadow: '0px 3px 50px #EFE7C4'
                                        }}
                                        />
                                        <div onClick={() => this.getCurrentLocation()} className="LocationIconContainer">
                                            <span className="LocationIcon">
                                                <GpsFixedIcon className="GPSIcon"/>
                                            </span>
                                        </div>
                                    </div>
                                    {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                                </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                    </div>
                    <BurgerImage/>
                </div> 
                // <Grid container item xs={6} direction="column" justifyContent="center" alignItems="center">
                //     <div style={{position: 'relative'}} className="HeaderDescriptionContainer">
                //         <div style={{position: 'absolute', top: '-265px', left: '-25vw', zIndex: '0', WebkitTransform: "translate3d(0,0,0)"}}>
                //             <img style={{width: "44vw"}} src={bubble} alt='Landing Page Bubble'/>
                //         </div>
                //         <div style={{position: 'absolute', top: '-125px', left: '-18vw'}}>
                //             <div className="HeaderDescription topText">
                //                 Welcome to <span style={{color: "#EB622F"}}>DaiDish</span>
                //             </div>
                //             <div className="HeaderDescription bottomText">
                //                 The only ‘dish’ review platform out there (we checked)
                //             </div>
                //             <div style={{
                //                 margin: "auto", 
                //                 width: "35vw", 
                //                 textAlign: "center", 
                //                 transform: "translateX(-2vw)",
                //                 fontFamily: "Poppins-Regular",
                //                 fontSize: "14px"
                //             }}>
                //                 {this.state.locationErrorMessage}
                //             </div>
                            
                //         </div>
                //     </div>
                //     {/* <div className="LocationContainer">
                //         <input className="Location" type="text" placeholder="Enter location"></input>
                //         <span className="LocationIcon">
                //             <GpsFixedIcon className="GPSIcon"/>
                //         </span>
                //     </div> */}
                // </Grid>
            )
        } else {
            return (
                <div style={{paddingTop: '40px 8vw 0px 8vw'}}>
                    <div style={{color: '#3356AB', paddingLeft: '8vw', fontSize: '24px', letterSpacing: '-1px', margin:'0px 0px 20px 0px', textAlign: 'left'}} className='HomeTitleNew'>
                        Ready to eat <span className='HomeTitleRed'>the Best?</span>
                    </div>
                    <div>
                        <div style={{fontSize: '16px', paddingLeft: '8vw', marginTop: '0px', marginBottom: '40px', color: '#171717', width: '92vw'}} className="WhyDaidishDetails">
                            Browse through the Best Dishes around you & order now
                        </div>
                        <div style={{width: '84vw'}} className='LocationContainerNew'>
                            <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={{
                                    componentRestrictions: {country: ['in']}
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{position: 'relative', zIndex: 'inherit'}}>
                                    <div style={{ zIndex: 'inherit'}} className="LocationInputContainerNew">
                                        <input
                                        className="LocationInputNew"
                                        {...getInputProps({
                                            placeholder: 'Enter your location here',
                                        })}
                                        onKeyPress={this.handleKeyPress}
                                        style={{
                                            border: "#E6E6E6 1px solid !important",
                                            borderTopLeftRadius: "30px",
                                            borderBottomLeftRadius: "30px",
                                            backgroundColor: "white",
                                            width: "72vw",
                                            padding: "15px 25px 15px 25px",
                                            fontFamily: 'Montserrat-Regular',
                                            fontSize: "14px",
                                            color: "black",
                                            zIndex: 'inherit',
                                            boxShadow: '0px 3px 50px #EFE7C4'
                                        }}
                                        />
                                        <div onClick={() => this.getCurrentLocation()} className="LocationIconContainer">
                                            <span style={{transform: 'translate(-10px, 3px)'}} className="LocationIcon">
                                                <GpsFixedIcon style={{transform: 'translateY(3px)'}} className="GPSIcon"/>
                                            </span>
                                        </div>
                                    </div>
                                    {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                                </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                    </div>
                    <BurgerImage/>
                </div> 
                // <Grid container item xs={6} direction="column" justifyContent="center" alignItems="center">
                //     <div style={{position: 'relative'}} className="HeaderDescriptionContainer">
                //         <div style={{position: 'absolute', top: '-265px', left: '-25vw', zIndex: '0', WebkitTransform: "translate3d(0,0,0)"}}>
                //             <img style={{width: "44vw"}} src={bubble} alt='Landing Page Bubble'/>
                //         </div>
                //         <div style={{position: 'absolute', top: '-125px', left: '-18vw'}}>
                //             <div className="HeaderDescription topText">
                //                 Welcome to <span style={{color: "#EB622F"}}>DaiDish</span>
                //             </div>
                //             <div className="HeaderDescription bottomText">
                //                 The only ‘dish’ review platform out there (we checked)
                //             </div>
                //             <div style={{
                //                 margin: "auto", 
                //                 width: "35vw", 
                //                 textAlign: "center", 
                //                 transform: "translateX(-2vw)",
                //                 fontFamily: "Poppins-Regular",
                //                 fontSize: "14px"
                //             }}>
                //                 {this.state.locationErrorMessage}
                //             </div>
                            
                //         </div>
                //     </div>
                //     {/* <div className="LocationContainer">
                //         <input className="Location" type="text" placeholder="Enter location"></input>
                //         <span className="LocationIcon">
                //             <GpsFixedIcon className="GPSIcon"/>
                //         </span>
                //     </div> */}
                // </Grid>
            )
        }
    }
}

export default withRouter(BottomHeader);