import React from 'react';
import { Grid } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import '../../assets/styles/Commons.css';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';
import logo from '../../assets/images/logo_white.png';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import { RiWhatsappFill } from "react-icons/ri"; 

// This is the common footer used in the website

function mouseIn(e) {
    e.target.style.color = '#FFFFFF';
}
function mouseOut(e) {
    e.target.style.color = '#dbdbdb';
}

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen){
        if (isMobileScreen){
            return(
                <div className="FooterColumn">
                    <Information
                        gridSize={12}
                    />
                    <div style={{height: "30px"}}/>
                    <HelpfulLinks
                        gridSize={12}
                    />
                    <div style={{height: "30px"}}/>
                    <ContactUs
                        gridSize={12}
                        isMobileScreen={this.state.isMobileScreen}
                    />
                    <div style={{height: "10px"}}/>
                    <Subscribe
                        gridSize={12}
                        mailSize={12}
                        buttonSize={12}
                    />
                </div>
            )
        } else {
            return (
                <Grid className="FooterColumn" container spacing={0} direction="row">
                    <Information
                        gridSize={3}
                    />
                    <HelpfulLinks
                        gridSize={3}
                    />
                    <ContactUs
                        gridSize={3}
                        isMobileScreen={this.state.isMobileScreen}
                    />
                    <Subscribe
                        gridSize={3}
                        mailSize={12}
                        buttonSize={4}
                    />
                </Grid>
            )
        }
    }

    render() {
        return (
            <div className="Footer">
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                    <Logo/>
                </Grid>
                {this.getMobileComponents(this.state.isMobileScreen)}
                <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
                    <SocialMedia/>
                    <Copyright/>
                </Grid>
            </div>
        )
    }
}

// Logo present in the footer
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <Grid container item xs={12} direction="column">
                    <div style={{margin: "auto"}} className="FooterLogo">
                        <img className="LogoImage FooterLogoImage" src={logo} alt="logo"/>  
                    </div>
                </Grid>
            )
        } else {
            return (
                <Grid container item xs={12} direction="column">
                    <div className="FooterLogo">
                        <img className="LogoImage" src={logo} alt="logo"/>  
                    </div>
                </Grid>
            )
        }
    }
}

// First Column of the footer which contains about, blog, careers and partner with us
class Information extends Component {
    render() {
        return (
            <Grid container item xs={this.props.gridSize} direction="column">
                <div className="FooterColumnHeader">Information</div>
                <Link to={'/aboutus'} style={{ textDecoration: 'none' }}>
                    <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">About</div>
                </Link>
                <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Blog</div>
                <Link to={'/careers'} style={{ textDecoration: 'none' }}>
                    <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Careers</div>
                </Link>
                <Link  to={'/partnerwithus'} style={{ textDecoration: 'none' }}>
                    <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Partner with us</div>
                </Link>
            </Grid>
        )
    }
}

// Second column of the footer which contains helpful links, privacy policy, terms and conditions & privacy policy
class HelpfulLinks extends Component {
    render() {
        return (
            <Grid container item xs={this.props.gridSize} direction="column">
                <div className="FooterColumnHeader">Helpful Links</div>
                <Link  to={'/privacypolicy'} style={{ textDecoration: 'none' }}>
                    <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Privacy Policy</div>
                </Link>
                <Link  to={'/tnc'} style={{ textDecoration: 'none' }}>
                    <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Terms & Conditions</div>
                </Link>
                <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} className="FooterColumnItem">Cookie Policy</div>
            </Grid>
        )
    }
}

// Third column of the footer which contains contact information
class ContactUs extends Component {
    render() {
        return (
            <Grid container item xs={this.props.gridSize} direction="column">
                <div className="FooterColumnHeader">Contact Us</div>
                <div className="FooterColumnItem">
                    <a style={{color: '#DBDBDB', textDecoration: 'none'}} href="mailto:info@daidish.com">
                        <MailIcon className="mail-icon"/> <span className="mail-Name">info@daidish.com</span>
                    </a>
                </div>
                {this.props.isMobileScreen ? 
                    <div className="FooterColumnItem">
                        <a style={{textDecoration:"none", color:"#dbdbdb", cursor: "pointer"}} href="whatsapp://send?phone=+919108618389">
                            <RiWhatsappFill className="mail-icon"/> <span className="mail-Name">+919108618389</span>
                        </a>
                    </div>
                :
                    <div style={{cursor: "pointer"}} onClick={() => window.open("https://wa.me/919108618389")} className="FooterColumnItem">
                        <RiWhatsappFill className="mail-icon"/> <span className="mail-Name">+919108618389</span>
                    </div>
                }
            </Grid>
        )
    }
}

// Last column of the footer used to subscribe to the website
class Subscribe extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            emailError: '',
            emailSuccess: '',
            isSubscribing: false
        }
        this.subscribeNewsLetter = this.subscribeNewsLetter.bind(this);
    }

    subscribeNewsLetter() {
        this.setState({
            emailError: '',
            emailSuccess: '',
            isSubscribing: true
        })
        if (this.state.email === ''){
            this.setState({
                emailError: 'Email is required.',
                isSubscribing: false
            })
            return;
        }
        var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailValidator.exec(this.state.email)){
            this.setState({
                emailError: "Please enter a valid email",
                isSubscribing: false
            }) 
            return;
        }
        var requestBody = {
            email: this.state.email
        }
        let url = CONSTANTS.hostURL + "api/newsletter/subscribe/"; 
        axios.post(url, requestBody).then(res => {
            var data = res.data;
            if (data.status === -1){
                this.setState({
                    emailError: data.message,
                    isSubscribing: false
                })
            } else if (data.status === 1) {
                this.setState({
                    emailSuccess: data.message,
                    isSubscribing: false,
                    email: ''
                })
            } else {
                this.setState({
                    emailError: "Network or Server Error",
                    isSubscribing: false
                }) 
            }
        }).catch((err) => this.setState({
            emailError: "Network or Server Error",
            isSubscribing: false
        }));
    }

    submitButton(){
        if (this.props.gridSize === 3){
            return (
                <div style={{paddingTop: "15px"}}>
                    <div onClick={() => this.subscribeNewsLetter()} className="mail-button-mobile" variant="contained" id="mail-submit">
                        {this.state.isSubscribing ? 'Subscribing..' : 'Subscribe'}
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{paddingTop: "15px"}}>
                    <div onClick={() => this.subscribeNewsLetter()} className="mail-button-mobile" variant="contained" id="mail-submit">
                        {this.state.isSubscribing ? 'Subscribing..' : 'Subscribe'}
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <Grid container item xs={this.props.gridSize} direction="column">
                <div style={{textAlign: "center"}} className="FooterColumnHeader">Subscribe</div>
                {/* <div className="FooterColumnItem Subscribe">
                    Subscribe
                </div> */}
                <Grid container item direction="row" justifyContent="center">
                    <Grid item xs={this.props.mailSize}>
                        <div style={{color: "green", fontSize: "12px", textAlign: "center"}}>{this.state.emailSuccess}</div>
                        <input onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} className="Mail" id="mail-input" type="text" placeholder="Enter your Email here"></input>
                        <div style={{color: "red", fontSize: "12px", textAlign: "center"}}>{this.state.emailError}</div>
                    </Grid>
                    {this.submitButton()}
                </Grid>
            </Grid>
        )
    }
}

// Social Media Icons
function SocialMedia() {
    return (
        <div className="SocialMediaIconsContainer">
            <a rel="noopener noreferrer" href="https://www.facebook.com/thedaidish" target="_blank"><img className="SocialMediaIcon" src={facebook} alt="facebook_icon"/></a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/thedaidish" target="_blank"><img className="SocialMediaIcon" src={instagram} alt="instagram_icon"/></a>
            <a rel="noopener noreferrer" href="https://twitter.com/thedaidish" target="_blank"><img className="SocialMediaIcon" src={twitter} alt="twitter_icon"/></a>
        </div>
    )
}

// Copyright text
function Copyright() {
    return (
        <div className="Copyright">
            2022<span>&#8482;</span> Daidish All rights reserved
            {/* 2021 <img style={{width: "16px", margin: "0px 3px", transform: "translateY(4px)"}} src={tm_logo} alt="tm_logo"/> Daidish.Ltd All rights reserved */}
        </div>
    )
}

export default Footer;