import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import '../../assets/styles/Dishes.css';
import logo from '../../assets/images/logo_black.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import profile from '../../assets/images/profile.png';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { RiShoppingCart2Line } from "react-icons/ri";
import { FiHome } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";


// Top Header which contains logo, auth options and location of user
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false,
            isAddressChanging: false,
            bottomBarHeight: "55px"
        };
        this.updateCartModalDisplayValue = this.updateCartModalDisplayValue.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if([
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)){
            this.setState({
                bottomBarHeight: "70px"
            })
        }
        else{
            this.setState({
                bottomBarHeight: "55px"
            })
        }
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    componentWillMount() {
        this.toggleSearchModalHandler = this.toggleSearchModalHandler.bind(this);
        this.toggleUserOptionsHandler = this.toggleUserOptionsHandler.bind(this);
        this.showAuthPopupModalHandler = this.showAuthPopupModalHandler.bind(this);
    }

    toggleSearchModalHandler() {
        this.props.toggleSearchModal();
    }

    toggleUserOptionsHandler(){
        this.props.toggleUserOptions();
    }

    showAuthPopupModalHandler(form){
        this.props.showAuthPopupModal(form);
    }

    updateCartModalDisplayValue(value){
        this.props.updateCartModalDisplay(value);
    }
    
    toggleAddressChanging = () => {
        this.setState({
            isAddressChanging: !this.state.isAddressChanging,
        })
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <>
                <div className="DishesHeader" style={{position: "fixed", top: "0px", left: "0px", zIndex: "100000", width: "100%", display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                    <Logo 
                        history={this.props.history} 
                        location={this.props.location}
                        isAddressChanging={this.state.isAddressChanging}
                        toggleAddressChanging={this.toggleAddressChanging}/>
                    <Authentication 
                        toggleUserOptions={this.toggleUserOptionsHandler} 
                        history={this.props.history} 
                        toggleSearchModal={this.toggleSearchModalHandler}
                        showAuthPopupModal={this.showAuthPopupModalHandler}
                        searchPresent={this.props.searchPresent}
                        itemsInCart={this.props.itemsInCart}
                        updateCartModalDisplay={this.updateCartModalDisplayValue}
                        showCart={this.props.showCart}
                        isAddressChanging={this.state.isAddressChanging}
                        toggleAddressChanging={this.toggleAddressChanging}
                    />
                </div>
                <div style={{height:this.state.bottomBarHeight, background:"#FFFFFF", position:"fixed", bottom:"-1px", width:"100%", zIndex:"10000", boxShadow:"#0000001A 0px -3px 6px"}}>
                    <Grid container spacing={0} style={{textAlign:"center"}}>
                        <Grid item xs={4} onClick={() => {
                            if(window.location.pathname !== "/home")
                                window.location.href="/home";
                        }}>
                            <FiHome className='ddMobileBottomBarIcon'/>
                            <div style={{fontFamily:"Poppins-Medium", fontSize:"12px", marginTop:"-5px"}}>Home</div>
                        </Grid>
                        <Grid item xs={4} onClick={() => {
                            if(localStorage.getItem("auth_token") !== null && localStorage.getItem("auth_token") !== ""){
                                this.props.updateCartModalDisplay(!this.props.showCart)
                            }
                            else{
                                this.props.showAuthPopupModal("login")
                            }
                        }}>
                            <FiShoppingCart className='ddMobileBottomBarIcon' style={{color: this.props.itemsInCart === 0 ? "#5E4B45" : "#EA5B2F", marginRight: this.props.itemsInCart !== 0 ? "-8px" : "4px"}}/>
                            {this.props.itemsInCart !== 0 && 
                            <span style={{fontFamily: "Poppins-Medium", fontSize:"10px", left:"-3px", top:"-16px", position:"relative", background: this.props.itemsInCart === 0 ? "#5E4B45" : "#EA5B2F", borderRadius:"50px", padding:"1px 6px", color:"#FFFFFF"}}>
                                {this.props.itemsInCart}
                            </span>}
                            <div style={{fontFamily:"Poppins-Medium", fontSize:"12px", marginTop:"-5px", color: this.props.itemsInCart === 0 ? "#5E4B45" : "#EA5B2F"}}>Cart</div>
                            
                        </Grid>
                        <Grid item xs={4} onClick={() => {
                            if(localStorage.getItem("auth_token") !== null && localStorage.getItem("auth_token") !== ""){
                                this.props.toggleUserOptions()
                            }
                            else{
                                this.props.showAuthPopupModal("login")
                            }
                        }}>
                            <CgProfile className='ddMobileBottomBarIcon'/>
                            <div style={{fontFamily:"Poppins-Medium", fontSize:"12px", marginTop:"-5px"}}>Account</div>
                        </Grid>
                    </Grid>
                </div>
                </>
            ) 
        } else {
            return (
                <div className="DishesHeader" style={{position: "fixed", top: "0px", left: "0px", zIndex: "100000", width: "100%"}}>
                    <Grid container item xs={12} spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Logo 
                            history={this.props.history} 
                            location={this.props.location}
                            isAddressChanging={this.state.isAddressChanging}
                            toggleAddressChanging={this.toggleAddressChanging}/>
                        <Authentication 
                            toggleUserOptions={this.toggleUserOptionsHandler} 
                            history={this.props.history} 
                            toggleSearchModal={this.toggleSearchModalHandler}
                            showAuthPopupModal={this.showAuthPopupModalHandler}
                            searchPresent={this.props.searchPresent}
                            itemsInCart={this.props.itemsInCart}
                            updateCartModalDisplay={this.updateCartModalDisplayValue}
                            showCart={this.props.showCart}
                            isAddressChanging={this.state.isAddressChanging}
                            toggleAddressChanging={this.toggleAddressChanging}
                        />
                    </Grid>
                </div>
            )
        }
    }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <AddressBar
                    history={this.props.history}
                    location={this.props.location}
                    isAddressChanging={this.props.isAddressChanging}
                    toggleAddressChanging={this.props.toggleAddressChanging}
                />
            )
        } else {
            return (
                <div className="Logo">
                    <img style={{cursor: "pointer"}} onClick={() => {
                        if (this.props.location.pathname !== "/home"){
                            this.props.history.push("/home")
                        } else {
                            window.location.reload();
                        }
                    }} className="LogoImage" src={logo} alt="logo"/>
                </div>
            )
        }
    }
}

class AddressBar extends Component {

    constructor(props){
        super(props);
        this.state = { 
            address: "",
            pastAddress: "Add your Location",
            //isAddressChanging: false,
            latitude: '',
            longitude: '',
            isMobileScreen: false
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (localStorage.getItem('user_address') !== null){
            this.setState({
                pastAddress : localStorage.getItem('user_address')
            });
        }
    }

    componentDidUpdate() {
        if (localStorage.getItem('user_address') !== null){
            if (this.state.pastAddress !== localStorage.getItem('user_address')){
                this.setState({
                    pastAddress : localStorage.getItem('user_address')
                });
            }
        }
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        })
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            localStorage.setItem("user_address", this.state.address);
            window.location.reload();
        }
    }

    handleChange = address => {
        this.setState({
            address: address
        })
    };

    handleSelect = address => {
        this.setState({
            address: address
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    toggleAddressChanging = () => {
        this.props.toggleAddressChanging();
        this.setState({
            //isAddressChanging: !this.props.isAddressChanging,
            address: "",
            latitude: '',
            longitude: ''
        })
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getAddressBarWidth(){
        if (this.state.isMobileScreen){
            if (localStorage.getItem("auth_token") === null){
                return "165px";
            }
            else{
                return "200px";
            }
        } else {
            return "320px";
        }
    }

    getInputBarWidth(){
        let screen_width = window.innerWidth;
        if (this.state.isMobileScreen){
            if (localStorage.getItem("auth_token") === null){
                return screen_width - 150 + "px";
            } else {
                return screen_width - 80 + "px";
            }
        } else {
            return "320px";
        }
    }

    checkMobileLogo() {
        if (this.state.isMobileScreen){
            return (
                <img style={{cursor: "pointer"}} src={logo_mobile} alt="" width={30} height={30} onClick={() => {
                    if (this.props.location.pathname !== "/home"){
                        this.props.history.push("/home");
                    } else {
                        window.location.reload();
                    }
                }}/>
            )
        } else {
            return null;
        }
    }

    render() {
        if (this.props.isAddressChanging){
            return (
                <div style={{
                    display: 'inline-block',
                    marginTop: this.state.isMobileScreen ? "14px": "0px",
                    flex: 1,
                    marginLeft: this.state.isMobileScreen ? "10px": "0px"
                }}
                    className="AutoSuggestContainer"
                    onBlur={this.toggleAddressChanging}
                >
                    {this.checkMobileLogo()}
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={{
                            componentRestrictions: {country: ['in']}
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{position: 'relative', zIndex: 'inherit', display: "inline-block", transform: this.state.isMobileScreen ? "translateY(-10px)" : ""}}>
                            <div style={{ zIndex: 'inherit', width: this.getInputBarWidth(), marginLeft: "10px"}} className="HomeLocationContainer">
                                <input
                                className="Location"
                                autoFocus={true}
                                {...getInputProps({
                                    placeholder: 'Enter location',
                                })}
                                style={{
                                    border: "#E6E6E6 1px solid !important",
                                    borderRadius: "30px",
                                    backgroundColor: "white",
                                    width: this.getInputBarWidth(),
                                    padding: "9px 5px",
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: this.state.isMobileScreen ? "12px" : "14px",
                                    paddingLeft: "15px",
                                    color: "black",
                                    zIndex: 'inherit',
                                }}
                                />
                            </div>
                            <div className="autocomplete-dropdown-container PlaceSuggestn"
                                style={{
                                    width: this.getInputBarWidth(),
                                    margin: "0px 0px 0px 10px",
                                    backgroundColor: "#F0F0F0",
                                    zIndex: '0',
                                    position: 'absolute',
                                    top: '20px',
                                    paddingTop: suggestions.length > 0 ? '30px' : '0px',
                                    paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                    overflow: 'hidden'
                                }}
                            >
                            {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                                : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                    key={suggestion.formattedSuggestion.mainText}
                                >
                                    <div style={{paddingTop: "2px", fontSize: this.state.isMobileScreen ? "10px" : "18px"}}>
                                        <LocationOnIcon fontSize='small'/>
                                    </div>
                                    <div>
                                    <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px', fontSize: this.state.isMobileScreen ? "13px" : "15px"}}>{suggestion.formattedSuggestion.mainText}</div>
                                    <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: this.state.isMobileScreen ? "10px" : "12px"}}>{suggestion.formattedSuggestion.secondaryText}</div>
                                    </div>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                </div>
            )
        } else {
            return (
                <div onClick={this.toggleAddressChanging} style={{display: "inline-block", marginLeft: this.state.isMobileScreen ? "10px": "20px", fontSize: "15px", cursor: "pointer", marginTop: this.state.isMobileScreen ? "14px": "0px", flex: 1}}>
                    {this.checkMobileLogo()}
                    
                    {this.state.isMobileScreen ? 
                        <div style={{display: "inline-block", transform: "translateY(-8px)", marginLeft: "10px", top:"27px", position:"absolute"}}>
                            <LocationOnIcon style={{transform: "translateY(0px)", marginRight: "5px", color: "#EA5B2F"}} className="HomeAddressLocationIcon"/>
                            <span style={{display: "inline-block", width: this.getAddressBarWidth(), overflow: "hidden", whiteSpace: "nowrap"}} className="HomeAddressBar">{this.state.pastAddress}</span>
                        </div>
                        :
                        <div style={{display: "inline-block", transform: "translateY(4px)"}}>
                            <LocationOnIcon style={{transform: "translateY(2px)", marginRight: "5px", color: "#EA5B2F"}} className="HomeAddressLocationIcon"/>
                            <span style={{display: "inline-block", width: this.getAddressBarWidth(), overflow: "hidden", whiteSpace: "nowrap"}} className="HomeAddressBar">{this.state.pastAddress}</span>
                        </div>
                    }
               </div>
            )
        }
    }
}


// Log In and Sign Up options
class Authentication extends Component {

    constructor(props){
        super(props);
        this.state = { 
            isMobileScreen: false,
        };
        this.redirectLandingPage = this.redirectLandingPage.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    checkType = (value) => {
        this.setState({
            typeDropdown: value
        });    
      };
      showDropdown = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
      };
      hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
      };
      chooseItem = (value) => {    
        if (this.state.labelItem !== value) {
          this.setState({
            labelItem: value      
          })
        }    
      };
      
      renderDataDropDown = (item, index) => {    
        const {value, label} = this.state.typeDropdown ? {value: index, label: item} : item;    
        return (
          <li
            key={index}
            value={value}
            onClick={() => this.chooseItem(label)}
          >
            <a href={"hello"}>{label}</a>
          </li> 
        )
      };

    componentWillMount() {
        this.toggleSearchModalHandler = this.toggleSearchModalHandler.bind(this);
    }

    toggleSearchModalHandler(e) {
        e.stopPropagation();
        this.props.toggleSearchModal();
    }

    redirectLandingPage(form){
        this.props.showAuthPopupModal(form);
    }

    showSearchBar(){
        if (this.props.searchPresent !== undefined){
            return (
                <span style={{display: "inline-block"}}>
                    <SearchIcon onClick={this.toggleSearchModalHandler} style={{color: "black", cursor: "pointer", marginRight: "5px", fontSize: "16px"}} className="SearchIcon"/>
                    <span onClick={this.toggleSearchModalHandler} style={{marginLeft: "3px", fontFamily: "Poppins-Medium", color: "#404040", fontSize: "16px", cursor: "pointer"}}>
                        Search
                    </span>
                </span>
            )
        } else {
            return null;
        }
    }

    showSearchBarMobile(){
        if (this.props.searchPresent !== undefined){
            return (
                <span style={{display: "inline-block", borderRadius: "50%", backgroundColor: "#F4F4F4 1px solid", boxShadow:"#A5A5A529 0px 3px 6px", padding: "1px 7px 9px 7px"}}>
                    <SearchIcon onClick={(e) => this.toggleSearchModalHandler(e)} style={{top: "5px", color: "#EA5B2F", cursor: "pointer", transform: "translate(0px, 0px)", fontSize:"20px"}} className="ddSearchIcon"/>
                </span> 
            )
        } else {
            return null;
        }
    }

    userAuth(){
        if (localStorage.getItem("auth_token") === null){
            if (this.state.isMobileScreen){
                return(
                    !this.props.isAddressChanging && <div style={{paddingTop: "8px", display: "inline-block"}}>
                        {this.showSearchBarMobile()}
                        <Button style={{marginLeft: "10px"}} onClick={() => this.redirectLandingPage("login")} variant="contained" id="dishes-signup-button-mobile">
                            Login
                        </Button>
                    </div>
                )
            } else {
                return(
                    <div className="OffersAuthBackground" style={{marginRight: "5vw"}}>
                        {this.showSearchBar()}
                        <AddressBar
                            isAddressChanging={this.props.isAddressChanging}
                            toggleAddressChanging={this.props.toggleAddressChanging}/>
                        <Button onClick={() => this.redirectLandingPage("login")} variant="contained" id="dishes-login-button">
                            Login
                        </Button>
                        <Button onClick={() => this.redirectLandingPage("register")} variant="contained" id="dishes-signup-button">
                            Sign Up
                        </Button>
                    </div>
                )
            }
        } else {
            if (this.state.isMobileScreen){
                return(
                    this.props.isAddressChanging ? 
                    <></>
                    :
                    <div className="OffersAuthBackground" style={{display: "inline-block", marginTop:"9px"}}>
                        {this.showSearchBarMobile()}
                    </div>
                    
                )
            } else {
                let user = JSON.parse(localStorage.getItem("user"));
                let username = user["name"];
                if (username === null || username === ""){
                    username = user["username"];
                }
                return(
                    <Grid className="OffersAuthBackground" style={{marginRight: "7.5vw", display: "block"}}>
                        {this.showSearchBar()}
                        <AddressBar 
                            isAddressChanging={this.props.isAddressChanging}
                            toggleAddressChanging={this.props.toggleAddressChanging}/>
                        <span onClick={() => this.props.toggleUserOptions()} style={{display: "inline-block", marginLeft: "40px", cursor: "pointer", fontFamily: "Montserrat-Regular", paddingBottom: "10px"}}>
                            <div style={{display: "inline-block", borderRadius: "50%", backgroundColor: "#ECECEC", width: "36px", height: "36px", position: "relative", top: "2px", zIndex: "1000"}}>
                                <img style={{position: "relative", top: "6px", left: "6px"}} width={24} height={24} src={profile} alt="logo"/>
                            </div>
                            <span style={{marginLeft: "10px"}}>
                                {username} 
                            </span>
                        </span>
                        <span onClick={() => this.props.updateCartModalDisplay(!this.props.showCart)} style={{marginLeft: "15px", fontSize:"16px", backgroundColor: this.props.itemsInCart === 0 ? "#2B2624" : "#EA5B2F"}} className="ddHeaderCartIcon">
                            <RiShoppingCart2Line style={{transform: "translate(2px, 2px)", marginRight: "1px"}}/> {this.props.itemsInCart}
                        </span>
                    </Grid>
                )
            }
        }
    }

    render() {
        return this.userAuth();
    }
}

export default withRouter(Header);