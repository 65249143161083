import React, {Component} from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import DaiDishLoading1 from '../../assets/images/DaiDishLoading1.gif';
// import ratings from '../../assets/images/ratings.svg';
import noDishFound from '../../assets/images/whoops1.gif';
import DishCard from './DishCard';

class DishList extends Component {
    constructor(props){
        super(props);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.showAuthPopupModalHandler = this.showAuthPopupModalHandler.bind(this);
        this.updateCartChangedValueHandler = this.updateCartChangedValueHandler.bind(this);
        this.toggleLikeButtonHandler = this.toggleLikeButtonHandler.bind(this);
        this.state = {
            isMobileScreen: false,
        }
    }
    componentDidMount(){
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    showRatingsModalHandler(dish) {
        this.props.showRatingsModal(dish);
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    showAuthPopupModalHandler(form){
        this.props.showAuthPopupModal(form);
    }

    updateCartChangedValueHandler(val){
        this.props.updateCartChangedValue(val);
    }

    toggleLikeButtonHandler(index){
        this.props.toggleLikeButton(index);
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        return(
            <div className="DishesActions Filters" style={this.state.isMobileScreen ? {marginTop: "10px"} : {marginTop: "10px", paddingLeft: "7vw", paddingRight: "7vw"}}>
                <DishCards 
                    dishes={this.props.dishes} 
                    moreDishesLoading={this.props.moreDishesLoading}
                    dishLoading={this.props.dishLoading} 
                    showReviewsModal={this.showReviewsModalHandler} 
                    showRatingsModal={this.showRatingsModalHandler}
                    toggleImageHover={this.props.toggleImageHover}
                    toggleRateHover={this.props.toggleRateHover}
                    toggleReviewHover={this.props.toggleReviewHover}
                    toggleLikeButton={this.toggleLikeButtonHandler}
                    showAuthPopupModal={this.showAuthPopupModalHandler}
                    isDishScreen={this.props.isDishScreen}
                    toggleConfirmationBox={this.props.toggleConfirmationBox}
                    getReplacementDish={this.props.getReplacementDish}
                    updateCartChangedValue={this.updateCartChangedValueHandler}
                    addToCartAction={this.props.addToCartAction}
                    nonServicable={this.props.nonServicable}
                    repeatCustomisationMessage={this.props.repeatCustomisationMessage}
                    temp_customizations={this.props.temp_customizations}
                    toggleRepeatCustomisation={this.props.toggleRepeatCustomisation}
                    getCustomisationText={this.props.getCustomisationText}
                    popOverAnchorEl={this.props.popOverAnchorEl}
                    popUpId={this.props.popUpId}
                    popDishState={this.props.popDishState}
                    handlePopOverClose={this.props.handlePopOverClose}
                    dishesAddingState={this.props.dishesAddingState}
                />
            </div>
        )
    }
}

class DishCards extends Component {
    constructor(props){
        super(props);
        this.dishCards = this.dishCards.bind(this);
        this.loadMoreDishes = this.loadMoreDishes.bind(this);
        this.checkNoDishes = this.checkNoDishes.bind(this);
        this.checkAspectRatio = this.checkAspectRatio.bind(this);
        this.showAuthPopupModalHandler = this.showAuthPopupModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.toggleImageHover = this.toggleImageHover.bind(this);
        this.toggleLikeButtonHandler = this.toggleLikeButtonHandler.bind(this);
        this.updateCartChangedValueHandler = this.updateCartChangedValueHandler.bind(this);
        this.state = {
            gridSize: 12,
            dishImageWidth: "",
            dishImageTransform: "",
            isMobileScreen: false,
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 650) {
            this.setState({ gridSize: 12 });
            this.setState({ isMobileScreen: true });
        } else if(screen_width < 1000) {
          this.setState({ gridSize: 6 });
          this.setState({ isMobileScreen: true });
        } else if (screen_width < 1400){
            this.setState({ gridSize: 4 }); 
            this.setState({ isMobileScreen: false });
        } else if (screen_width < 2400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else {
            this.setState({gridSize: 2});
            this.setState({ isMobileScreen: false });
        }
    }

    toggleImageHover(index) {
        this.props.toggleImageHover(index);
    }

    toggleRateHover(index) {
        this.props.toggleRateHover(index);
    }

    toggleReviewHover(index) {
        this.props.toggleReviewHover(index);
    }

    showAuthPopupModalHandler(type){
        this.props.showAuthPopupModal(type);
    }

    toggleLikeButtonHandler(index) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.toggleLikeButton(index);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showRatingsModalHandler(dish) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    checkNoDishes(){
        let totalDishes = this.props.dishes.length;
        if (!this.props.isDishScreen){
            totalDishes = this.props.dishes.filter(function(dish) {return dish.vegDisplay && dish.cuisineDisplay && dish.dishTypeDisplay && dish.recommendedDisplay && dish.personalizedDisplay && dish.searchDisplay && dish.freeDeliveryDisplay;}).length;
        }
        if (totalDishes === 0){
            if (this.props.dishes.length === 0 && this.props.isDishScreen){
                if (this.props.nonServicable){
                    return (
                        <Grid alignItems="center" justifyContent="center" container item xs={12}>
                            <img style={{width: this.state.isMobileScreen ? "130vw" : "80vw", marginTop: "100px", marginBottom: "70px", marginLeft: this.state.isMobileScreen ? "30px" : "50px"}} src={noDishFound} alt="No dishes found"/>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid alignItems="center" justifyContent="center" container item xs={12}>
                            {this.props.isDishScreen ? <div style={{fontFamily: "Montserrat-Regular", padding: "60px 0px"}}>No Dishes Found...</div> : <div style={{fontFamily: "Montserrat-Regular"}}></div>}
                        </Grid>
                    )
                }
            } else {
                return (
                    <Grid alignItems="center" justifyContent="center" container item xs={12}>
                        {this.props.isDishScreen ? <div style={{fontFamily: "Montserrat-Regular", marginBottom: "30px"}}>No Dishes Found...</div> : <div style={{fontFamily: "Montserrat-Regular"}}></div>}
                    </Grid>
                )
            }
        } else {
            return null;
        }
    }

    getDescription(description, dishName){
        var maxLength;
        if (this.state.gridSize === 12){
            maxLength = 54;
        } else {
            maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                return description.slice(0, maxLength) + "..";
            }
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        var maxLength;
        if (this.state.gridSize === 12){
            maxLength = 54;
        } else {
            maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length > maxLength){
                return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // if (this.state.gridSize === 12){
                //     return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // } else {
                //     return <div onMouseEnter={() => this.toggleImageHover(dish.id - 1)} onMouseLeave={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">Read more</div> ;
                // }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    discountCard(dish){
        if (dish.discount_present && dish.discount_present === true){
            return (
                <span className="Ribbon">
                    <div className="RibbonText">{dish.discount_text}</div>
                </span>
            )
        } else {
            return null;
        }
    }

    checkAspectRatio({target:img}){
        let aspectRatio = img.offsetHeight/img.offsetWidth;
        if (0.95 < aspectRatio < 1.05){
            this.setState({
                dishImageWidth: "275px !important",
            });
        }

        if (img.offsetWidth > 275){
            let excessWidth = Math.floor(img.offsetWidth - 275 /2);
            this.setState({
                dishImageTransform: "translateX(-" + excessWidth + "px) !important"
            })
        }
    }

    updateCartChangedValueHandler(val){
        this.props.updateCartChangedValue(val);
    }

    dishCards(overlayStyle, yellowLineStyle, blurStyle, likeButtonStyle, descriptionOverlayStyle) {
        if (this.props.dishLoading){
            return (
                <div style={{marginTop: "-120px", zIndex: 100}} id="dish-cards-scroll-tracker">
                    <Grid alignItems="center" justifyContent="center" container item xs={12}>
                        <img style={{width: this.state.isMobileScreen ? "80vw" : "400px" }} src={DaiDishLoading1} alt="Loading Dishes..."/>
                    </Grid>
                </div>
            )
        } else {
            return (
                <div style={{marginBottom: this.props.dishes.filter(function(dish) {return dish.vegDisplay && dish.cuisineDisplay && dish.dishTypeDisplay && dish.recommendedDisplay && dish.personalizedDisplay && dish.searchDisplay;}).length === 0 ? "0px" : "40px"}} className="DishCardSuperContainer">
                    <Grid container spacing={0}>
                    <Grid container item xs={12}>
                    {
                        this.props.dishes.map((dish, index) => {
                            if (this.props.isDishScreen || (dish.vegDisplay && dish.cuisineDisplay && dish.dishTypeDisplay && !dish.isBestDish && dish.recommendedDisplay && dish.personalizedDisplay && dish.searchDisplay && dish.freeDeliveryDispla)){
                                return <DishCard
                                    dish={dish}
                                    descriptionOverlayStyle={descriptionOverlayStyle[index]}
                                    overlayStyle={overlayStyle[index]}
                                    likeButtonStyle={likeButtonStyle[index]}
                                    showReviewsModal={this.showReviewsModalHandler} 
                                    showRatingsModal={this.showRatingsModalHandler}
                                    toggleImageHover={this.toggleImageHover}
                                    toggleRateHover={this.toggleRateHover}
                                    toggleReviewHover={this.toggleReviewHover}
                                    toggleLikeButton={this.toggleLikeButtonHandler}
                                    showAuthPopupModal={this.showAuthPopupModalHandler}
                                    toggleConfirmationBox={this.props.toggleConfirmationBox}
                                    getReplacementDish={this.props.getReplacementDish}
                                    confirmationBoxMessage={this.props.confirmationBoxMessage}
                                    showConfirmationBox={this.props.showConfirmationBox}
                                    updateCartChangedValue={this.updateCartChangedValueHandler}
                                    addToCartAction={this.props.addToCartAction}
                                    index={index}
                                    repeatCustomisationMessage={this.props.repeatCustomisationMessage}
                                    temp_customizations={this.props.temp_customizations}
                                    toggleRepeatCustomisation={this.props.toggleRepeatCustomisation}
                                    getCustomisationText={this.props.getCustomisationText}
                                    popOverAnchorEl={this.props.popOverAnchorEl}
                                    popUpId={this.props.popUpId}
                                    popDishState={this.props.popDishState}
                                    handlePopOverClose={this.props.handlePopOverClose}
                                    key={index}
                                    dishesAddingState={this.props.dishesAddingState}
                                />
                            } else {
                                return null;
                            }
                        })
                    }
                    {/* {this.loadMoreDishes()} */}
                    {this.checkNoDishes()}
                </Grid>
            </Grid>
                </div>
            )
        }
    }

    loadMoreDishes() {
        if (this.props.moreDishesLoading && !this.props.dishLoading){
            return(
                <Grid alignItems="center" justifyContent="center" container item xs={12}>
                    <div style={{fontFamily: "Montserrat-Regular"}}>Loading More Dishes...</div>
                </Grid>
            )
        } else {
            return null;
        }
    }

    render() {
        var overlayStyle = [];
        var yellowLineStyle = [];
        var blurStyle=[];
        var likeButtonStyle = [];
        var descriptionOverlayStyle = [];
        for (var i=0; i<this.props.dishes.length; i++){
            overlayStyle.push({});
            blurStyle.push({});
            descriptionOverlayStyle.push({});
            if (this.props.dishes[i].imageHover === true) {
                overlayStyle[i] = {width: '100%'};
                blurStyle[i] = {height: '158px'};
                descriptionOverlayStyle[i] = {visibility: 'visible', opacity: '0.9'};
            } else {
                overlayStyle[i] = {width: '100%'}
                blurStyle[i] = {height: '190px'};
                descriptionOverlayStyle[i] = {visibility: 'hidden', opacity: '0'};
            }
        }
        for (var j=0; j<this.props.dishes.length; j++){
            yellowLineStyle.push({});
            if (this.props.dishes[j].rateHover === true) {
                yellowLineStyle[j] = {left: "25%", width: "60px"}
            } else {
                yellowLineStyle[j] = {left: "50%", width: "120px"}
            }
        }
        for (var l=0; l<this.props.dishes.length; l++){
            likeButtonStyle.push("");
            if (this.props.dishes[l].liked === true) {
                likeButtonStyle[l] = "LikeNewButton LikeNewButtonActive favme dashicons dashicons-heart";
            } else {
                likeButtonStyle[l] = "LikeNewButton favme dashicons dashicons-heart";
            }
        }
        for (var k=0; k<this.props.dishes.length; k++) {
            if (this.props.dishes[k].reviewHover === true) {
                yellowLineStyle[k] = {left: "75%", width: "60px"};
            } 
        }
        return (
            this.dishCards(overlayStyle, yellowLineStyle, blurStyle, likeButtonStyle, descriptionOverlayStyle)
            
        )
    }
}

export default DishList;