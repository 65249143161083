import React, { Component, createRef } from 'react';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import { Grid } from '@material-ui/core';
import home_burger from '../../assets/images/partner_with_us_illustration.png';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import '../../assets/styles/Home.css';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo_white.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import '../../assets/styles/PartnerWithUs.css';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import "croppie/croppie.css";
import Croppie from "croppie";
import ArrowBack from '@material-ui/icons/ArrowBack';

// Top Header which contains logo, auth options, burger icon and header description
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
        this.toggleDishCardPreviewHandler = this.toggleDishCardPreviewHandler.bind(this);
        this.toggleRestaurantCardPreviewHandler = this.toggleRestaurantCardPreviewHandler.bind(this);
    }

    toggleDishCardPreviewHandler(dish){
        this.props.toggleDishCardPreview(dish);
    }

    toggleRestaurantCardPreviewHandler(dish){
        this.props.toggleRestaurantCardPreview(dish);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getMobileComponents(isMobileScreen, headerStyle){
        if (isMobileScreen){
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Logo/>
                        </div>
                        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                            <BurgerImage/>
                        </Grid>
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        } else {
            return (
                <div style={headerStyle} className="Header">
                    {/* <div style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100vw"}}> */}
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <Logo/>
                        </Grid>
                        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                            <BurgerImage/>
                            <ContactForm
                                authModalOpen={this.props.authModalOpen}
                                history={this.props.history}
                                toggleDishCardPreview={this.toggleDishCardPreviewHandler}
                                toggleRestaurantCardPreview={this.toggleRestaurantCardPreviewHandler}
                            />
                        </Grid> 
                    {/* </div> */}
                    {/* <div style={{marginTop: "84px"}}> */}
                    {/* </div>   */}
                </div> 
            )
        }
    }

   render() {
    var headerStyle = {};
    if (this.state.isMobileScreen){
        headerStyle = {background: "linear-gradient(60deg, #FFFFFF 50%, #F9E841 50%)"};
    } else {
        headerStyle = {background: "linear-gradient(100deg, #F9E841 40%, #FFFFFF 40%)", height: "100vh"};
    }
    return (
        this.getMobileComponents(this.state.isMobileScreen, headerStyle)
    )
   }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        
        if (this.state.isMobileScreen){
            return (
                <div className="Logo LogoHome">
                    <Link  to={'/home'} style={{ textDecoration: 'none' }}>
                        <img style={{cursor: "pointer", marginTop: "10px", marginLeft: "5px"}} src={logo_mobile} alt="" width={40} height={40}/>  
                    </Link>
                </div>
            )
        } else {
            return (
                <Grid container item xs={4}>
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="Logo LogoHome">
                                <Link  to={'/home'} style={{ textDecoration: 'none' }}>
                                    <img style={{marginTop: "10px"}} className="LogoImage" src={logo} alt="logo"/>  
                                </Link>
                            </div>
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }

    }
}

// Burger Image in the Header
class BurgerImage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        var burgerContainerStyle = {};
        var burgerStyle = {};
        if (this.state.isMobileScreen){
            burgerContainerStyle = {paddingTop: "0px", paddingBottom: "20px", transform: "translateX(-5vw)"};
            burgerStyle = {width: '70vw', transform: 'translateX(-5vw)'};
        } else {
            burgerContainerStyle = {paddingTop: "20px", paddingBottom: "20px", paddingLeft: "0vw", transform: "translateY(-2vh)"};
            burgerStyle = {marginLeft: "0vw", width: "36vw"}
        }
        return (
            <Grid style={burgerContainerStyle} className="PartnerWithUsLogoContainer" container item direction="column" justifyContent="center" alignItems="center" xs={6}>
                <img style={burgerStyle} className="Burger" src={home_burger} alt="burger_img"/>
            </Grid>
        )
    }
}

// Description present in header
class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.dishImageRef = createRef(null);
        this.dishImageRef2 = createRef(null);
        this.restaurantImageRef = createRef(null);
        this.state = {
            activeScreen: "contact-details",
            restaurantName: "",
            restaurantNameError: "",
            shortAddress: "",
            address: "",
            addressError: "",
            pincode: "",
            pincodeError: "",
            fssai: "",
            fssaiError: "",
            gst: "",
            gstError: "",
            ownerName: "",
            ownerNameError: "",
            ownerContactNumber: "",
            ownerContactNumberError: "",
            ownerEmailID: "",
            ownerEmailIDError: "",
            latitude: 0.0,
            longitude: 0.0,
            contactDetailsError: "",
            contactDetails2Error: "",
            contactDetailsSubmitting: false,
            sessionID: "",
            otp: "",
            otpError: "",
            otpValidating: false,
            otpValidationError: "",
            resendOTPText: "Resend OTP",
            resendOTPError: "",
            resendOTPSuccess: "",
            clearUserSuggestions: false,
            dishName: "",
            dishNameError: "",
            dishDescription: "",
            dishDescriptionError: "",
            dishCost: "",
            dishCostError: "",
            dishImage: null,
            dishImageError: "",
            restaurantImage: null,
            restaurantImageError: "",
            bestDishDetailsError: "",
            currentDishUploadStep: 1,
            currentDishUploadStep2: 1,
            currentRestaurantUploadStep: 1,
            restoDetailsCompleted: false,
            bestDishDetailsCompleted: false,
            bestDishDetailsCompleted2: false,
            dishImageName: "",
            restaurantImageName: "",
            dishName2: "",
            dishNameError2: "",
            dishDescription2: "",
            dishDescriptionError2: "",
            dishCost2: "",
            dishCostError2: "",
            dishImage2: null,
            dishImageError2: ""
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
        this.handleContactDetailsSubmit = this.handleContactDetailsSubmit.bind(this);
        this.handleInitialContactDetailsSubmit2 = this.handleInitialContactDetailsSubmit2.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
        this.onFileInputChange = this.onFileInputChange.bind(this);
        this.handleInitialContactDetailsSubmit = this.handleInitialContactDetailsSubmit.bind(this);
        this.handleBrowseFileClick = this.handleBrowseFileClick.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    handleChange = address => {
        var lenDiff = address.length - this.state.address.length;
        if (lenDiff < -1){
            this.setState({
                clearUserSuggestions: true
            })
        } else {
            this.setState({
                clearUserSuggestions: false
            })
        }
        this.setState({
            address: address,
            shortAddress: "",
            latitude: 0.0,
            longitude: 0.0
        })
    };

    setMobileScreen(type){
        this.setState({
            isMobileScreen: type
        })
    }

    handleSelect = address => {
        var shortAddress = address.split(',')[0];
        this.setState({
            address: address,
            shortAddress: shortAddress,
            clearUserSuggestions: true
        })
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then((position) =>
            this.setAddressSuggestion(position, address)
        );
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        })
    }

    phoneNumberValidator(value){
        let validator = /^[0]?[6789]\d{9}$/;
        return validator.exec(value);
    }

    handleInitialContactDetailsSubmit(){
        this.setState({
            activeScreen: "contact-details",
            restaurantNameError: "",
            addressError: "",
            pincodeError: "",
            fssaiError: "",
            gstError: "",
            contactDetailsError: "",
        })
        let error_present = false;
        if (this.state.restaurantName === ""){
            this.setState({
                restaurantNameError: "Restaurant Name is required"
            })
            error_present = true;
        }
        if (this.state.address === "" || this.state.latitude === "" || this.state.longitude === "" || this.state.shortAddress === ""){
            this.setState({
                addressError: "Address is required"
            })
            error_present = true;
        }
        if (this.state.pincode === ""){
            this.setState({
                pincodeError: "Pin Code is required"
            })
            error_present = true;
        } else {
            if (parseInt(this.state.pincode) > 999999 || parseInt(this.state.pincode) < 111111 || isNaN(parseInt(this.state.pincode))){
                this.setState({
                    pincodeError: "Please enter a valid pin code"
                })
                error_present = true;
            }
        }
        if (this.state.fssai === ""){
            this.setState({
                fssaiError: "FSSAI Number is required"
            })
            error_present = true;
        }
        if (this.state.fssai.length < 14 && this.state.fssai.length > 0){
            this.setState({
                fssaiError: "FSSAI number must be 14 digits"
            })
            error_present = true;
        }

        let gstRegex = /[0-3]{1}[1-9]{1}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
        if (this.state.gst === ""){}
        else if(this.state.gst.length < 15 || (!gstRegex.exec(this.state.gst))){
            this.setState({
                gstError: "Incorrect GST format"
            })
            error_present = true;
        }
        if (!error_present){
            // this.setState({
            //     restoDetailsCompleted: true
            // });
            this.setState({
                activeScreen: 'contact-details-2'
            })
        }
        
    }

    handleInitialContactDetailsSubmit2(){
        this.setState({
            activeScreen: "contact-details-2",
            ownerNameError: "",
            ownerContactNumberError: "",
            ownerEmailIDError: "",
            contactDetails2Error: "",
        })
        let error_present = false;
        if (this.state.ownerName === ""){
            this.setState({
                ownerNameError: "Restaurant Contact Name is required"
            })
            error_present = true;
        }
        if (this.state.ownerContactNumber === ""){
            this.setState({
                ownerContactNumberError: "Restaurant Contact Number is required"
            })
            error_present = true;
        } else {
            if (!this.phoneNumberValidator(this.state.ownerContactNumber)){
                this.setState({
                    ownerContactNumberError: "Please enter a valid Restaurant Contact Number"
                })
                error_present = true;
            }
        }
        if (this.state.ownerEmailID === ""){
            this.setState({
                ownerEmailIDError: "Restaurant Email ID is required"
            })
            error_present = true;
        } else {
            var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!emailValidator.exec(this.state.ownerEmailID)){
                this.setState({
                    ownerEmailIDError: "Please enter a valid Restaurant Email ID"
                }) 
                error_present = true;
            }
        }
        if (!error_present){
            // this.setState({
            //     restoDetailsCompleted: true
            // });
            this.handleContactDetailsSubmit();
        }
        
    }

    handleBestDishDetailsSubmit(){
        this.setState({
            activeScreen: "best-dish-details",
            dishNameError: "",
            dishDescriptionError: "",
            dishCostError: "",
            bestDishDetailsError: "",
        })
        let error_present = false;
        if (this.state.dishName === ""){
            this.setState({
                dishNameError: "Best dish name is required"
            })
            error_present = true;
        }
        if (this.state.dishCost === ""){
            this.setState({
                dishCostError: "Best dish cost is required"
            })
            error_present = true;
        } else {
            var numberRegex = '^[0-9]*$';
            if (!this.state.dishCost.match(numberRegex)){
                this.setState({
                    dishCostError: "Please enter valid dish cost"
                })
                error_present = true;
            }
        }
        if (this.state.dishDescription === ""){
            this.setState({
                dishDescriptionError: "Best dish description is required"
            })
            error_present = true;
        }
        if (!error_present){
            // this.setState({
            //     bestDishDetailsCompleted: true
            // });
            this.setState({
                activeScreen: 'best-dish-details-2'
            })
        }
    }

    handleBestDishDetailsSubmit2(){
        this.setState({
            activeScreen: "best-dish-details-2",
            dishNameError2: "",
            dishDescriptionError2: "",
            dishCostError2: "",
            bestDishDetailsError2: "",
        })
        let error_present = false;
        if (this.state.dishName2 === ""){
            this.setState({
                dishNameError2: "Best dish name is required"
            })
            error_present = true;
        } else {
            if (this.state.dishName2 === this.state.dishName){
                this.setState({
                    dishNameError2: "Both best dish names cannot be same"
                })
                error_present = true;
            }
        }
        if (this.state.dishCost2 === ""){
            this.setState({
                dishCostError2: "Best dish cost is required"
            })
            error_present = true;
        } else {
            var numberRegex = '^[0-9]*$';
            if (!this.state.dishCost2.match(numberRegex)){
                this.setState({
                    dishCostError2: "Please enter valid dish cost"
                })
                error_present = true;
            }
        }
        if (this.state.dishDescription2 === ""){
            this.setState({
                dishDescriptionError2: "Best dish description is required"
            })
            error_present = true;
        }
        if (!error_present){
            // this.setState({
            //     bestDishDetailsCompleted2: true
            // });
            this.handleContactDetailsSubmit();
        }
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    handleContactDetailsSubmit() {
        if (!this.state.contactDetailsSubmitting){
            this.setState({
                activeScreen: "contact-details-2",
                contactDetailsSubmitting: true
            })
            var form_data = new FormData();
            form_data.append('restaurant_name', this.state.restaurantName);
            form_data.append('short_address', this.state.shortAddress);
            form_data.append('full_address', this.state.address);
            form_data.append('latitude', this.state.latitude);
            form_data.append('longitude', this.state.longitude);
            form_data.append('city', "Bangalore");
            form_data.append('state', "Karnataka");
            form_data.append('pincode', this.state.pincode);
            form_data.append('fssai', this.state.fssai);
            if (this.state.gst !== ""){
                form_data.append('gst', this.state.gst);
            }
            form_data.append('owner_name', this.state.ownerName);
            form_data.append('mobile_number', this.state.ownerContactNumber);
            form_data.append('owner_email', this.state.ownerEmailID);
            // form_data.append('dish_name', this.state.dishName);
            // form_data.append('dish_description', this.state.dishDescription);
            // form_data.append('dish_cost', this.state.dishCost);
            // form_data.append('dish_image', this.dataURLtoFile(this.state.dishImage, this.state.dishImageName), this.state.dishImage.name);
            // form_data.append('dish_name_2', this.state.dishName2);
            // form_data.append('dish_description_2', this.state.dishDescription2);
            // form_data.append('dish_cost_2', this.state.dishCost2);
            // form_data.append('dish_image_2', this.dataURLtoFile(this.state.dishImage2, this.state.dishImageName2), this.state.dishImage2.name);
            // if (this.state.restaurantImage !== null){
            //     form_data.append('restaurant_image', this.dataURLtoFile(this.state.restaurantImage, this.state.restaurantImageName), this.state.restaurantImage.name);
            // }
            let url = CONSTANTS.hostURL + "api/main_app/onboarding/start";
            let headers = {
                'Content-Type' : 'multipart/form-data' 
            }
            axios.post(url, form_data, {
                headers: headers
            }).then(res => {
                var data = res.data;
                if (data.status === -1){
                    this.setState({
                        contactDetailsSubmitting: false
                    })
                    if(data.hasOwnProperty("errors")){
                        let errors = data.errors;
                        if (errors.hasOwnProperty("restaurant_name")){
                            this.setState({
                                restaurantNameError: errors.restaurant_name[0]
                            })
                        }
                        if (errors.hasOwnProperty("full_address")){
                            this.setState({
                                addressError: errors.addressError[0]
                            })
                        }
                        if (errors.hasOwnProperty("pincode")){
                            this.setState({
                                pincodeError: errors.pincode[0]
                            })
                        }
                        if (errors.hasOwnProperty("fssai")){
                            this.setState({
                                fssaiError: errors.fssai[0]
                            })
                        }
                        if (errors.hasOwnProperty("gst")){
                            this.setState({
                                gstError: errors.gst[0]
                            })
                        }
                        if (errors.hasOwnProperty("owner_name")){
                            this.setState({
                                ownerNameError: errors.owner_name[0]
                            })
                        }
                        if (errors.hasOwnProperty("mobile_number")){
                            this.setState({
                                ownerContactNumberError: errors.mobile_number[0]
                            })
                        }
                        if (errors.hasOwnProperty("owner_email")){
                            this.setState({
                                ownerEmailIDError: errors.owner_email[0]
                            })
                        }
                        if (errors.hasOwnProperty("dish_name")){
                            this.setState({
                                dishNameError: errors.dish_name[0]
                            })
                        }
                        if (errors.hasOwnProperty("dish_description")){
                            this.setState({
                                dishDescriptionError: errors.dish_description[0]
                            })
                        }
                        if (errors.hasOwnProperty("dish_cost")){
                            this.setState({
                                dishCostError: errors.dish_cost[0]
                            })
                        }
                        // if (errors.hasOwnProperty("dish_image")){
                        //     this.setState({
                        //         dishImageError: errors.dish_image[0]
                        //     })
                        // }
                        // if (errors.hasOwnProperty("restaurant_image")){
                        //     this.setState({
                        //         restaurantImageError: errors.restaurant_image[0]
                        //     })
                        // }
                    } else {
                        this.setState({
                            contactDetailsError: "Network or Server Error"
                        }) 
                    }
                } else if (data.status === 1) {
                    this.setState({
                        contactDetailsSubmitting: false,
                        sessionID: data.data.session_id,
                        activeScreen: "otp-validation"
                    })
                } else {
                    this.setState({
                        contactDetailsError: "Network or Server Error",
                        contactDetailsSubmitting: false
                    }) 
                }
            }).catch((err) => this.setState({
                contactDetailsError: "Network or Server Error",
                contactDetailsSubmitting: false
            }));
        }
    }

    resendOTP() {
        if (!this.state.otpValidating){
            this.setState({
                resendOTPText: "Resending OTP..",
                resendOTPError: "",
                resendOTPSuccess: "",
                otp: "",
                otpError: "",
                otpValidationError: ""
            })
            var form_data = new FormData();
            form_data.append('restaurant_name', this.state.restaurantName);
            form_data.append('short_address', this.state.shortAddress);
            form_data.append('full_address', this.state.address);
            form_data.append('latitude', this.state.latitude);
            form_data.append('longitude', this.state.longitude);
            form_data.append('city', "Bangalore");
            form_data.append('state', "Karnataka");
            form_data.append('pincode', this.state.pincode);
            form_data.append('fssai', this.state.fssai);
            if (this.state.gst !== ""){
                form_data.append('gst', this.state.gst);
            }
            form_data.append('owner_name', this.state.ownerName);
            form_data.append('mobile_number', this.state.ownerContactNumber);
            form_data.append('owner_email', this.state.ownerEmailID);
            // form_data.append('dish_name', this.state.dishName);
            // form_data.append('dish_description', this.state.dishDescription);
            // form_data.append('dish_cost', this.state.dishCost);
            // form_data.append('dish_image', this.dataURLtoFile(this.state.dishImage, this.state.dishImageName), this.state.dishImage.name);
            // form_data.append('dish_name_2', this.state.dishName2);
            // form_data.append('dish_description_2', this.state.dishDescription2);
            // form_data.append('dish_cost_2', this.state.dishCost2);
            // form_data.append('dish_image_2', this.dataURLtoFile(this.state.dishImage2, this.state.dishImageName2), this.state.dishImage2.name);
            // if (this.state.restaurantImage !== null){
            //     form_data.append('restaurant_image', this.dataURLtoFile(this.state.restaurantImage, this.state.restaurantImageName), this.state.restaurantImage.name);
            // }
            let url = CONSTANTS.hostURL + "api/main_app/onboarding/start";
            axios.post(url, form_data).then(res => {
                var data = res.data;
                if (data.status === -1){
                    this.setState({
                        resendOTPError: "Network or Server Error",
                        resendOTPSuccess: "",
                        resendOTPText: "Resend OTP"
                    })
                } else if (data.status === 1) {
                    this.setState({
                        resendOTPError: "",
                        resendOTPSuccess: "OTP has been resent successfully",
                        resendOTPText: "Resend OTP",
                        sessionID: data.data.session_id
                    })
                } else {
                    this.setState({
                        resendOTPError: "Network or Server Error",
                        resendOTPSuccess: "",
                        resendOTPText: "Resend OTP"
                    }) 
                }
            }).catch((err) => this.setState({
                resendOTPError: "Network or Server Error",
                resendOTPSuccess: "",
                resendOTPText: "Resend OTP"
            }));
        }
    }

    showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading){
        if (this.state.address.length > 1 && !this.state.clearUserSuggestions){
            return (
                <div className="autocomplete-dropdown-container PlaceSuggestn"
                    style={{
                        backgroundColor: "#F0F0F0",
                        zIndex: '0',
                        width: "100%",
                        position: 'absolute',
                        top: '40px',
                        paddingBottom: suggestions.length > 0 ? '10px' : '0px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        overflow: 'hidden'
                    }}
                >
                {loading && <div style={{paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px'}}>Loading...</div>}
                {suggestions.map(suggestion => {
                    const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                    ? { backgroundColor: '#ECECEC', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row' }
                    : { backgroundColor: '#F0F0F0', cursor: 'pointer', padding: '6px 10px', display: 'flex', flexDirection: 'row'  };
                    return (
                    <div
                        {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        })}
                        key={suggestion.formattedSuggestion.mainText}
                    >
                        <div style={{paddingTop: "2px"}}>
                            <LocationOnIcon fontSize='small'/>
                        </div>
                        <div>
                        <div style={{fontFamily: 'Montserrat-Medium', padding: '0px 10px'}}>{suggestion.formattedSuggestion.mainText}</div>
                        <div style={{fontFamily: 'Montserrat-Medium', color: "#989898", padding: '0px 10px', fontSize: '12px'}}>{suggestion.formattedSuggestion.secondaryText}</div>
                        </div>
                    </div>
                    );
                })}
                </div>
            )
        } else {
            return null;
        }
    }

    handleOTPSubmit() {
        this.setState({
            activeScreen: "otp-validation",
            otpError: "",
            otpValidating: true,
            otpValidationError: ""
        })
        if (this.state.otp === ""){
            this.setState({
                otpValidating: false,
                otpError: "OTP is required"
            })
        } else {
            var form_data = new FormData();
            form_data.append('restaurant_name', this.state.restaurantName);
            form_data.append('short_address', this.state.shortAddress);
            form_data.append('full_address', this.state.address);
            form_data.append('latitude', this.state.latitude);
            form_data.append('longitude', this.state.longitude);
            form_data.append('city', "Bangalore");
            form_data.append('state', "Karnataka");
            form_data.append('pincode', this.state.pincode);
            form_data.append('fssai', this.state.fssai);
            if (this.state.gst !== ""){
                form_data.append('gst', this.state.gst);
            }
            form_data.append('owner_name', this.state.ownerName);
            form_data.append('mobile_number', this.state.ownerContactNumber);
            form_data.append('owner_email', this.state.ownerEmailID);
            // form_data.append('dish_name', this.state.dishName);
            // form_data.append('dish_description', this.state.dishDescription);
            // form_data.append('dish_cost', this.state.dishCost);
            form_data.append('session_id', this.state.sessionID);
            form_data.append('otp', this.state.otp);
            // form_data.append('dish_image', this.dataURLtoFile(this.state.dishImage, this.state.dishImageName), this.state.dishImage.name);
            // form_data.append('dish_name_2', this.state.dishName2);
            // form_data.append('dish_description_2', this.state.dishDescription2);
            // form_data.append('dish_cost_2', this.state.dishCost2);
            // form_data.append('dish_image_2', this.dataURLtoFile(this.state.dishImage2, this.state.dishImageName2), this.state.dishImage2.name);
            // if (this.state.restaurantImage !== null){
            //     form_data.append('restaurant_image', this.dataURLtoFile(this.state.restaurantImage, this.state.restaurantImageName), this.state.restaurantImage.name);
            // }
            let url = CONSTANTS.hostURL + "api/main_app/onboarding/validate";
            axios.post(url, form_data).then(res => {
                var data = res.data;
                if (data.status === -1){
                    this.setState({
                        otpValidating: false,
                    })
                    if (data.hasOwnProperty("errors")){
                        this.setState({
                            otpError: data.errors.otp[0]
                        })
                    } else {
                        this.setState({
                            otpValidationError: "Network or Server Error"
                        })
                    }
                } else if (data.status === 1) {
                    this.setState({
                        otpValidating: false,
                        activeScreen: "thank-you"
                    })
                } else {
                    this.setState({
                        otpValidationError: "Network or Server Error",
                        otpValidating: false
                    }) 
                }
            }).catch((err) => this.setState({
                otpValidationError: "Network or Server Error",
                otpValidating: false
            }));
        }
    }

    getSubdetails(){
        if (this.state.activeScreen === 'contact-details'){
            return 'Please enter your restaurant details';
        } else if (this.state.activeScreen === 'best-dish-details'){
            return 'Please enter details of the 1' + 'st'.sup() + ' best dish of your restaurant';
        } else if (this.state.activeScreen === 'best-dish-details-2'){
            return 'Please enter details of the 2' + 'nd'.sup() + ' best dish of your restaurant';
        } else if (this.state.activeScreen === 'otp-validation'){
            return 'Verify OTP';
        }
    }

    contactFormHeader(){
        if (this.state.activeScreen === "contact-details"){
            return(
                <div className="PartnerWithUsHeader">
                    Partner <span style={{color: "#EB622F"}}>with us</span>
                </div>
            )
        } else if (this.state.activeScreen === "contact-details-2"){
            return(
                <div style={{display: "flex", flexDirection: "row"}} className="PartnerWithUsHeader">
                    <div onClick={() => this.setState({activeScreen: 'contact-details', restoDetailsCompleted: false})} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</div>
                    <div style={{flex: "1", transform: "translateX(-30px)"}}>Partner <span style={{color: "#EB622F"}}>with us</span></div>
                </div>
            )
        } else if (this.state.activeScreen === "best-dish-details"){
            return(
                <div style={{display: "flex", flexDirection: "row"}} className="PartnerWithUsHeader">
                    <div onClick={() => this.setState({activeScreen: 'contact-details', restoDetailsCompleted: false})} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</div>
                    <div style={{flex: "1", transform: "translateX(-30px)"}}>1<sup>st</sup> Best Dish <span style={{color: "#EB622F"}}>Information</span></div>
                </div>
            )
        } else if (this.state.activeScreen === "best-dish-details-2"){
            return(
                <div style={{display: "flex", flexDirection: "row"}} className="PartnerWithUsHeader">
                    <div onClick={() => this.setState({activeScreen: 'best-dish-details', bestDishDetailsCompleted: false})} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</div>
                    <div style={{flex: "1", transform: "translateX(-30px)"}}>2<sup>nd</sup> Best Dish <span style={{color: "#EB622F"}}>Information</span></div>
                </div>
            )
        } else if (this.state.activeScreen === 'otp-validation'){
            return(
                <div style={{display: "flex", flexDirection: "row"}} className="PartnerWithUsHeader">
                    <div onClick={() => this.setState({activeScreen: 'contact-details-2', restoDetailsCompleted: false})} className="PartnerWithUsBackContainer"><ArrowBack className="PartnerWithUsBackIcon"/> Back</div>
                    <div style={{flex: "1", transform: "translateX(-30px)"}}>Verify <span style={{color: "#EB622F"}}>Account</span></div>
                </div>
            )
        } else {
            return null;
        }
    }

    screenDecider() {
        if (this.state.activeScreen === "contact-details"){
            return this.contactDetailsForm();
        } else if (this.state.activeScreen === "contact-details-2"){
            return this.contactDetailsForm2();
        } else if (this.state.activeScreen === "otp-validation"){
            return this.otpValidationForm();
        } else if (this.state.activeScreen === "thank-you"){
            return this.thankYouMessage();
        }
    }

    contactDetailsForm(){
        if (!this.state.restoDetailsCompleted){
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    <div className="PartnerWithUsFieldHeader">Restaurant Details</div>
                    <div className="PartnerWithUsFieldError">{this.state.contactDetailsError}</div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Restaurant Name
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.restaurantName} onChange={(event) => this.setState({restaurantName: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Restaurant Name Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.restaurantNameError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Area Name
                        </div>
                        {this.addressSuggestionInput()}
                        <div className="PartnerWithUsFieldError">{this.state.addressError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Pin Code
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.pincode} onChange={(event) => this.setState({pincode: event.target.value})} className="PartnerWithUsField" type="number" placeholder="Enter Pincode Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.pincodeError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            FSSAI Number
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.fssai} onChange={(event) => {
                            if(event.target.value.length === 14 || event.target.value.length === 0){
                                this.setState({
                                    fssai: event.target.value,
                                    fssaiError: ""
                                })
                            }
                            else{
                                this.setState({
                                    fssai: event.target.value,
                                    fssaiError: "FSSAI number must be 14 digits"
                                })
                            }
                        }} className="PartnerWithUsField" type="number" placeholder="Enter FSSAI Number Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.fssaiError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            GST Number
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.gst} onChange={(event) => {
                            let gstRegex = /[0-3]{1}[1-9]{1}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
                            if(event.target.value.length <= 15){
                                if(!gstRegex.exec(event.target.value) && event.target.value.length > 0){
                                    this.setState({
                                        gst: event.target.value,
                                        gstError: "Incorrect GST format"
                                    });
                                }
                                else{
                                    this.setState({
                                        gst: event.target.value,
                                        gstError: ""
                                    });
                                }
                            }
                        }} className="PartnerWithUsField" placeholder="Enter GST Number Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.gstError}</div>
                    </div>
                    <div onClick={() => this.handleInitialContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">Next</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    {this.getUploadImageHeader("restaurant")}
                    {this.decideRestaurantUploadForm()}
                </div>
            )
        }
    }

    contactDetailsForm2(){
        if (!this.state.restoDetailsCompleted){
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    <div className="PartnerWithUsFieldHeader">Restaurant Contact Details</div>
                    <div className="PartnerWithUsFieldSubHeader">This contact will be used to manage everyday orders</div>
                    <div className="PartnerWithUsFieldError">{this.state.contactDetails2Error}</div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Restaurant Contact Name
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.ownerName} onChange={(event) => this.setState({ownerName: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Restaurant Contact Name Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.ownerNameError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Restaurant Contact Number
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.ownerContactNumber} onChange={(event) => this.setState({ownerContactNumber: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Restaurant Contact Number Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.ownerContactNumberError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Restaurant Email ID
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.ownerEmailID} onChange={(event) => this.setState({ownerEmailID: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Restaurant Email ID Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.ownerEmailIDError}</div>
                    </div>
                    <div className="ForgotPasswordContainer">
                        <span style={{left: "0"}} className="ForgotPassword">By signing up, I agree to the &nbsp; 
                        <Link target="_blank" to={'/partnertnc'} style={{ textDecoration: 'none' }}>
                            <span style={{borderBottom: "1px solid #EB622F", color: "#EB622F", cursor: "pointer"}}>Terms & Conditions</span>
                        </Link></span>
                    </div>
                    <div onClick={() => this.handleInitialContactDetailsSubmit2()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">{this.state.contactDetailsSubmitting ? "Proceeding.." : "Proceed"}</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    {this.getUploadImageHeader("restaurant")}
                    {this.decideRestaurantUploadForm()}
                </div>
            )
        }
    }

    addressSuggestionInput(){
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                    componentRestrictions: {country: ['in']}
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div style={{position: 'relative', zIndex: 'inherit'}}>
                    <div style={{ zIndex: 'inherit'}} className="">
                        <input
                        className="PartnerWithUsField"
                        {...getInputProps({
                            placeholder: 'Enter Address',
                        })}
                        />
                    </div>
                    {this.showSuggestions(getInputProps, suggestions, getSuggestionItemProps, loading)}
                </div>
                )}       
            </PlacesAutocomplete>
        )
    }

    bestDishDetailsForm(){
        if (!this.state.bestDishDetailsCompleted){
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    <div className="PartnerWithUsFieldError">{this.state.bestDishDetailsError}</div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Name
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.dishName} onChange={(event) => this.setState({dishName: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Best Dish Name Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.dishNameError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Cost
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.dishCost} onChange={(event) => this.setState({dishCost: event.target.value})} className="PartnerWithUsField" type="number" placeholder="Enter Best Dish Cost Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.dishCostError}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Description
                        </div>
                        <textarea rows={10} style={{border: "#EBEBEB 1px solid", font: "400 13.3333px Arial"}} value={this.state.dishDescription} onChange={(event) => this.setState({dishDescription: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Best Dish Description Here"></textarea>
                        <div className="PartnerWithUsFieldError">{this.state.dishDescriptionError}</div>
                    </div>
                    <div className="ForgotPasswordContainer">
                        <span style={{left: "0"}} className="ForgotPassword">By signing up, I agree to the &nbsp; 
                        <Link target="_blank" to={'/partnertnc'} style={{ textDecoration: 'none' }}>
                            <span style={{borderBottom: "1px solid #EB622F", color: "#EB622F", cursor: "pointer"}}>Terms & Conditions</span>
                        </Link></span>
                    </div>
                    <div onClick={() => this.handleBestDishDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">Next</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    {this.getUploadImageHeader("dish")}
                    {this.decideDishUploadForm()}
                </div>
            )
        }
    }

    bestDishDetailsForm2(){
        if (!this.state.bestDishDetailsCompleted2){
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    <div className="PartnerWithUsFieldError">{this.state.bestDishDetailsError2}</div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Name
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.dishName2} onChange={(event) => this.setState({dishName2: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Best Dish Name Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.dishNameError2}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Cost
                        </div>
                        <input style={{border: "#EBEBEB 1px solid"}} value={this.state.dishCost2} onChange={(event) => this.setState({dishCost2: event.target.value})} className="PartnerWithUsField" type="number" placeholder="Enter Best Dish Cost Here"></input>
                        <div className="PartnerWithUsFieldError">{this.state.dishCostError2}</div>
                    </div>
                    <div className="PartnerWithUsFieldContainer">
                        <div className="PartnerWithUsFieldName">
                            Dish Description
                        </div>
                        <textarea rows={10} style={{border: "#EBEBEB 1px solid", font: "400 13.3333px Arial"}} value={this.state.dishDescription2} onChange={(event) => this.setState({dishDescription2: event.target.value})} className="PartnerWithUsField" type="text" placeholder="Enter Best Dish Description Here"></textarea>
                        <div className="PartnerWithUsFieldError">{this.state.dishDescriptionError2}</div>
                    </div>
                    <div className="ForgotPasswordContainer">
                        <span style={{left: "0"}} className="ForgotPassword">By signing up, I agree to the &nbsp; 
                        <Link target="_blank" to={'/partnertnc'} style={{ textDecoration: 'none' }}>
                            <span style={{borderBottom: "1px solid #EB622F", color: "#EB622F", cursor: "pointer"}}>Terms & Conditions</span>
                        </Link></span>
                    </div>
                    <div onClick={() => this.handleBestDishDetailsSubmit2()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">{this.state.contactDetailsSubmitting ? "Proceeding.." : "Proceed"}</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="PartnerWithUsFieldSuperContainer">
                    {this.getUploadImageHeader("dish2")}
                    {this.decideDishUploadForm2()}
                </div>
            )
        }
    }

    onFileInputChange(event, type, imageType){
        if (!this.state.contactDetailsSubmitting){
            var file;
            if (type === "select"){
                if (event.target.files === null){
                    return;
                } else {
                    file = event.target.files[0];
                }
            } else {
                if (event === null){
                    return;
                } else {
                    file = event[0];
                }
            }
            const reader = new FileReader();
            if (imageType === "dish"){
                reader.onload = function(){
                    const output = document.getElementById('output_dish_image');
                    output.src = reader.result;
                    window.dish_image.bind({ url: reader.result });
                }
                reader.readAsDataURL(file);
                this.setState({
                    currentDishUploadStep: 2,
                    dishImageName: file.name
                }, () => {
                    window.dish_image = new Croppie(document.getElementById("output_dish_image"), {
                        enableExif: true,
                        showZoomer : false,
                        url : 'DSCN3409.JPG',
                        viewport: {
                            width: 300,
                            height: 300,
                            type: 'square'
                        },
                        boundary: {}
                    });
                })
            } else if (imageType === "dish2"){
                reader.onload = function(){
                    const output = document.getElementById('output_dish_image_2');
                    output.src = reader.result;
                    window.dish_image_2.bind({ url: reader.result });
                }
                reader.readAsDataURL(file);
                this.setState({
                    currentDishUploadStep2: 2,
                    dishImageName2: file.name
                }, () => {
                    window.dish_image_2 = new Croppie(document.getElementById("output_dish_image_2"), {
                        enableExif: true,
                        showZoomer : false,
                        url : 'DSCN3409.JPG',
                        viewport: {
                            width: 300,
                            height: 300,
                            type: 'square'
                        },
                        boundary: {}
                    });
                })
            } else {
                reader.onload = function(){
                    const output = document.getElementById('output_restaurant_image');
                    output.src = reader.result;
                    window.restaurant_image.bind({ url: reader.result });
                }
                reader.readAsDataURL(file);
                this.setState({
                    currentRestaurantUploadStep: 2,
                    restaurantImageName: file.name
                }, () => {
                    window.restaurant_image = new Croppie(document.getElementById("output_restaurant_image"), {
                        enableExif: true,
                        showZoomer : false,
                        url : 'DSCN3409.JPG',
                        viewport: {
                            width: 600,
                            height: 170,
                            type: 'square'
                        },
                        boundary: {}
                    });
                })
            }
        }
    }

    handleCroppedImage(resp, type, imageType){
        if (imageType === "dish"){
            this.setState({
                dishImage: resp
            })
            if (type === "upload"){
                this.setState({
                    currentDishUploadStep: 3
                })
            } else {
                this.props.toggleDishCardPreview({
                    id: 1,
                    name: this.state.dishName,
                    description: this.state.dishDescription,
                    image: resp,
                    no_rating: 1,
                    rating: 5,
                    restaurantId: -1,
                    restaurant: this.state.restaurantName,
                    cost: this.state.dishCost,
                    imageHover: false
                });
            }
        } else if (imageType === "dish2"){
            this.setState({
                dishImage2: resp
            })
            if (type === "upload"){
                this.setState({
                    currentDishUploadStep2: 3
                })
            } else {
                this.props.toggleDishCardPreview({
                    id: 1,
                    name: this.state.dishName2,
                    description: this.state.dishDescription2,
                    image: resp,
                    no_rating: 1,
                    rating: 5,
                    restaurantId: -1,
                    restaurant: this.state.restaurantName,
                    cost: this.state.dishCost2,
                    imageHover: false
                });
            }
        } else {
            this.setState({
                restaurantImage: resp
            })
            if (type === "upload"){
                this.setState({
                    currentRestaurantUploadStep: 3
                })
            } else {
                this.props.toggleRestaurantCardPreview({
                    id: 1,
                    name: this.state.restaurantName,
                    address: this.state.address,
                    profile_image_url: resp,
                    logo_url: "",
                    description: 'The best, being delivered to you'
                });
            }
        }
    }

    cropImage(type, imageType) {
        if (imageType === "dish"){
            const resImgWidth = 600; /*Output Image Width*/
            const resImgHeight = 600;
            window.dish_image.result({
            type: 'base64',
            size: {
                width: resImgWidth,
                height: resImgHeight
            },
            format : 'png',
            quality : 0
            }).then((resp) => this.handleCroppedImage(resp, type, imageType));
        } else if (imageType === "dish2"){
            const resImgWidth = 600; /*Output Image Width*/
            const resImgHeight = 600;
            window.dish_image_2.result({
            type: 'base64',
            size: {
                width: resImgWidth,
                height: resImgHeight
            },
            format : 'png',
            quality : 0
            }).then((resp) => this.handleCroppedImage(resp, type, imageType));
        } else {
            const resImgWidth = 1400; /*Output Image Width*/
            const resImgHeight = 672;
            window.restaurant_image.result({
            type: 'base64',
            size: {
                width: resImgWidth,
                height: resImgHeight
            },
            format : 'png',
            quality : 0
            }).then((resp) => this.handleCroppedImage(resp, type, imageType));
        }
    }

    getUploadImageHeader(type){
        if (type === "dish"){
            if (this.state.currentDishUploadStep !== 3){
                return (
                    <div className="UploadImageHeader">Upload Your <span style={{color: "#EB622F"}}>1<sup>st</sup> Best Dish</span> Image here</div>
                )
            } else {
                return (
                    <div className="UploadImageHeader UploadImageSuccessHeader">Image Successfully Uploaded!</div>
                )
            }
        } else if (type === "dish2"){
            if (this.state.currentDishUploadStep2 !== 3){
                return (
                    <div className="UploadImageHeader">Upload Your <span style={{color: "#EB622F"}}>2<sup>nd</sup> Best Dish</span> Image here</div>
                )
            } else {
                return (
                    <div className="UploadImageHeader UploadImageSuccessHeader">Image Successfully Uploaded!</div>
                )
            }
        } else {
            if (this.state.currentRestaurantUploadStep !== 3) {
                return (
                    <div className="UploadImageHeader">Upload Your <span style={{color: "#EB622F"}}>Restaurant</span> Cover here</div>
                )
            } else {
                return (
                    <div className="UploadImageHeader UploadImageSuccessHeader">Image Successfully Uploaded!</div>
                )
            }
        }
    }

    handleBrowseFileClick(event, imageType){
        event.stopPropagation();
        if (!this.state.contactDetailsSubmitting){
            if (imageType === "dish"){
                this.dishImageRef.current.click();
            } else if (imageType === "dish2"){
                this.dishImageRef2.current.click();
            } else {
                this.restaurantImageRef.current.click();
            }
        }
    }

    decideDishUploadForm(){
        if (this.state.currentDishUploadStep === 1){
            return(
                <div onClick={(event) => this.handleBrowseFileClick(event, "dish")} style={{cursor: "pointer"}} className="UploadImageFormContainer">
                    <Dropzone onDrop={files => this.onFileInputChange(files, "drop", "dish")}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div onClick={(event) => this.handleBrowseFileClick(event, "dish")} style={{fontSize: "13px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag & Drop your file here</div>
                                <div onClick={(event) => this.handleBrowseFileClick(event, "dish")} style={{fontSize: "13px", fontFamily: "Poppins-Regular", margin: "5px 0px", textAlign: "center"}}>Or</div>
                                <div style={{textAlign: "center", marginTop: "20px"}}>
                                    <span onClick={(event) => this.handleBrowseFileClick(event, "dish")} style={{padding: "8px 20px", fontSize: "13px", fontFamily: "Poppins-Regular", borderRadius: "5px", color: "white", backgroundColor: "#2B2624", cursor: "pointer"}}>Browse Files</span>
                                </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <input onChange={(event) => this.onFileInputChange(event, "select", "dish")} ref={this.dishImageRef} style={{display: "none"}} type="file" className="PartnerWithUsField" accept="image/png, image/gif, image/jpeg"/>
                </div>
            )
        } else if (this.state.currentDishUploadStep === 2) {
            return (
                <div>
                    <div style={{textAlign: "center", marginBottom: "20px"}}>
                        <div onClick={() => this.cropImage("upload", "dish")} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Upload
                        </div>
                    </div>
                    <div className="UploadImageFormContainer UploadImageFormCropContainer">
                        <div id="output_dish_image"></div>
                    </div>
                    <div style={{margin: "6px auto", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag the image to the portion you need</div>
                    <div style={{textAlign: "center"}}>
                        <span onClick={() => this.cropImage("preview", "dish")} style={{fontSize: "12px", fontFamily: "Poppins-Regular", color: "#EA5B30", borderBottom: "1px solid #EA5B30", cursor: "pointer"}}>Preview</span>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep: 1, dishImage: null, dishImageName: ""})}>Re-Upload</div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="DishPreviewImageContainer">
                        <img style={{width: "300px", height: "300px"}} src={this.state.dishImage} alt="Dish Card"/>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep: 1, dishImage: null, dishImageName: ""})}>Re-Upload</div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <div onClick={() => this.setState({activeScreen: 'best-dish-details-2'})} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Add 2<sup>nd</sup> best dish details
                        </div>
                    </div>
                </div>
            )
        }
    }

    decideDishUploadForm2(){
        if (this.state.currentDishUploadStep2 === 1){
            return(
                <div onClick={(event) => this.handleBrowseFileClick(event, "dish2")} style={{cursor: "pointer"}} className="UploadImageFormContainer">
                    <Dropzone onDrop={files => this.onFileInputChange(files, "drop", "dish2")}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div onClick={(event) => this.handleBrowseFileClick(event, "dish2")} style={{fontSize: "13px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag & Drop your file here</div>
                                <div onClick={(event) => this.handleBrowseFileClick(event, "dish2")} style={{fontSize: "13px", fontFamily: "Poppins-Regular", margin: "5px 0px", textAlign: "center"}}>Or</div>
                                <div style={{textAlign: "center", marginTop: "20px"}}>
                                    <span onClick={(event) => this.handleBrowseFileClick(event, "dish2")} style={{padding: "8px 20px", fontSize: "13px", fontFamily: "Poppins-Regular", borderRadius: "5px", color: "white", backgroundColor: "#2B2624", cursor: "pointer"}}>Browse Files</span>
                                </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <input onChange={(event) => this.onFileInputChange(event, "select", "dish2")} ref={this.dishImageRef2} style={{display: "none"}} type="file" className="PartnerWithUsField" accept="image/png, image/gif, image/jpeg"/>
                </div>
            )
        } else if (this.state.currentDishUploadStep2 === 2) {
            return (
                <div>
                    <div style={{textAlign: "center", marginBottom: "20px"}}>
                        <div onClick={() => this.cropImage("upload", "dish2")} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Upload
                        </div>
                    </div>
                    <div className="UploadImageFormContainer UploadImageFormCropContainer">
                        <div id="output_dish_image_2"></div>
                    </div>
                    <div style={{margin: "6px auto", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag the image to the portion you need</div>
                    <div style={{textAlign: "center"}}>
                        <span onClick={() => this.cropImage("preview", "dish2")} style={{fontSize: "12px", fontFamily: "Poppins-Regular", color: "#EA5B30", borderBottom: "1px solid #EA5B30", cursor: "pointer"}}>Preview</span>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep2: 1, dishImage2: null, dishImageName2: ""})}>Re-Upload</div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="DishPreviewImageContainer">
                        <img style={{width: "300px", height: "300px"}} src={this.state.dishImage2} alt="Dish Card"/>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentDishUploadStep2: 1, dishImage2: null, dishImageName2: ""})}>Re-Upload</div>
                    <div onClick={() => this.handleContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">{this.state.contactDetailsSubmitting ? "Proceeding.." : "Proceed"}</div>
                    </div>
                </div>
            )
        }
    }

    decideRestaurantUploadForm(){
        if (this.state.currentRestaurantUploadStep === 1){
            return(
                <div>
                    <div onClick={(event) => this.handleBrowseFileClick(event, "restaurant")} style={{cursor: "pointer"}} className="UploadRestaurantImageFormContainer">
                        <Dropzone onDrop={files => this.onFileInputChange(files, "drop", "restaurant")}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div style={{fontSize: "13px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag & Drop your file here</div>
                                    <div onClick={(event) => this.handleBrowseFileClick(event, "restaurant")} style={{fontSize: "13px", fontFamily: "Poppins-Regular", margin: "5px 0px", textAlign: "center"}}>Or</div>
                                    <div onClick={(event) => this.handleBrowseFileClick(event, "restaurant")} style={{textAlign: "center", marginTop: "20px"}}>
                                        <span onClick={(event) => this.handleBrowseFileClick(event, "restaurant")} style={{padding: "8px 20px", fontSize: "13px", fontFamily: "Poppins-Regular", borderRadius: "5px", color: "white", backgroundColor: "#2B2624", cursor: "pointer"}}>Browse Files</span>
                                    </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <input onChange={(event) => this.onFileInputChange(event, "select", "restaurant")} ref={this.restaurantImageRef} style={{display: "none"}} type="file" className="PartnerWithUsField" accept="image/png, image/gif, image/jpeg"/>
                    </div>
                    {this.state.contactDetailsSubmitting ? <div onClick={() => this.handleContactDetailsSubmit()} className="PartnerWithUsSubmitButtonContainer">
                    <div className="AuthFormSubmitButton">{this.state.contactDetailsSubmitting ? "Proceeding, please wait.." : "Proceed"}</div>
                </div> : <div style={{margin: "15px", marginTop: "30px", textDecoration: "underline", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({activeScreen: 'best-dish-details'})}>Skip & Proceed</div>}
                </div>
            )
        } else if (this.state.currentRestaurantUploadStep === 2) {
            return (
                <div>
                    <div className="UploadImageFormContainer UploadRestaurantImageFormCropContainer">
                        <div id="output_restaurant_image"></div>
                    </div>
                    <div style={{margin: "6px auto", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center"}}>Drag the image to the portion you need</div>
                    <div style={{textAlign: "center"}}>
                        <span onClick={() => this.cropImage("preview", "restaurant")} style={{fontSize: "12px", fontFamily: "Poppins-Regular", color: "#EA5B30", borderBottom: "1px solid #EA5B30", cursor: "pointer"}}>Preview</span>
                    </div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <div onClick={() => this.cropImage("upload", "restaurant")} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Upload
                        </div>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentRestaurantUploadStep: 1, restaurantImage: null, restaurantImageName: ""})}>Re-Upload</div>
                </div>
            )
        }  else {
            return (
                <div>
                    <div className="RestaurantPreviewImageContainer">
                        <img style={{width: "600px", height: "170px"}} src={this.state.restaurantImage} alt="Restaurant Cover"/>
                    </div>
                    <div style={{margin: "15px", fontSize: "12px", fontFamily: "Poppins-Regular", textAlign: "center", color: "#3356AA", cursor: "pointer"}} onClick={() => this.setState({currentRestaurantUploadStep: 1, restaurantImage: null, restaurantImageName: ""})}>Re-Upload</div>
                    <div style={{textAlign: "center", marginTop: "20px"}}>
                        <div onClick={() => this.setState({activeScreen: 'best-dish-details'})} style={{display: "inline-block", padding: "6px 30px", borderRadius: "5px", fontSize: "12px", fontFamily: "Poppins-Regular", backgroundColor: "#F8D542", cursor: "pointer"}}>
                            Add 1<sup>st</sup> best dish details
                        </div>
                    </div>
                </div>
            )
        }
    }

    otpValidationForm() {
        return (
            <div style={{marginTop: "40px"}} className="PartnerWithUsOTPContainer">
                <div className="PartnerWithUsFieldSuccess">{this.state.resendOTPSuccess}</div>
                <div className="PartnerWithUsFieldError">{this.state.resendOTPError}</div>
                <div className="PartnerWithUsFieldError">{this.state.otpValidationError}</div>
                <div className="PartnerWithUsOTPHeader">Enter Otp</div>
                <div className="PartnerWithUsOTPSubHeader">OTP has been sent to your Mobile Number.</div>
                <div className="PartnerWithUsFieldContainer">
                    <input style={{border: "#EBEBEB 1px solid"}} value={this.state.otp} onChange={(event) => this.setState({otp: event.target.value})} className="PartnerWithUsField" type="number" placeholder="Enter OTP Here"></input>
                    <div onClick={() => this.resendOTP()} className="PartnerWithUsResendOTP">{this.state.resendOTPText}</div>
                    <div className="PartnerWithUsFieldError">{this.state.otpError}</div>
                </div>
                <div onClick={() => this.handleOTPSubmit()} className="PartnerWithUsSubmitButtonContainer">
                    <div className="AuthFormSubmitButton">{this.state.otpValidating ? "Submitting.." : "Submit"}</div>
                </div>
            </div>
        )
    }

    thankYouMessage(){
        return(
            <div className="PartnerWithUsOTPContainer">
                <div className="PartnerWithUsThankYouContainer">
                    <div className="PartnerWithUsThankYouHeader">Successfully Submitted!</div>
                    <div className="PartnerWithUsThankYouSubHeader">Thank You!</div>
                    <div onClick={() => this.refreshForm()} style={{width: "150px", margin: "auto", marginTop: "20px"}} className="PartnerWithUsSubmitButtonContainer">
                        <div className="AuthFormSubmitButton">Ok</div>
                    </div>
                </div>
            </div>
        )
    }

    refreshForm() {
        this.setState({
            activeScreen: "contact-details",
            restaurantName: "",
            restaurantNameError: "",
            shortAddress: "",
            address: "",
            addressError: "",
            pincode: "",
            pincodeError: "",
            fssai: "",
            fssaiError: "",
            gst: "",
            gstError: "",
            ownerName: "",
            ownerNameError: "",
            ownerContactNumber: "",
            ownerContactNumberError: "",
            ownerEmailID: "",
            ownerEmailIDError: "",
            latitude: 0.0,
            longitude: 0.0,
            contactDetailsError: "",
            contactDetailsSubmitting: false,
            sessionID: "",
            otp: "",
            otpError: "",
            otpValidating: false,
            otpValidationError: "",
            resendOTPText: "Resend OTP",
            resendOTPError: "",
            resendOTPSuccess: "",
            clearUserSuggestions: false,
            dishName: "",
            dishNameError: "",
            dishDescription: "",
            dishDescriptionError: "",
            dishCost: "",
            dishCostError: "",
            dishImage: null,
            dishImageError: "",
            restaurantImage: null,
            restaurantImageError: "",
            bestDishDetailsError: "",
            currentDishUploadStep: 1,
            currentDishUploadStep2: 1,
            currentRestaurantUploadStep: 1,
            dishUploadCompleted: false,
            dishUploadCompleted2: false,
            dishImageName: "",
            restaurantImageName: "",
            dishName2: "",
            dishNameError2: "",
            dishDescription2: "",
            dishDescriptionError2: "",
            dishCost2: "",
            dishCostError2: "",
            dishImage2: null,
            dishImageError2: ""
        })
    }

    currentStep(){
        if (this.state.activeScreen === "contact-details"){
            return(
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentNonActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentNonActiveStep"></div>
                    </Grid>
                </Grid>
            )
        } else if (this.state.activeScreen === "contact-details-2"){
            return(
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentNonActiveStep"></div>
                    </Grid>
                </Grid>
            )
        } else if (this.state.activeScreen === 'otp-validation'){
            return(
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                    <Grid className="CurrentStepContainer" container item xs={4} direction="column" alignItems="center">
                        <div className="CurrentActiveStep"></div>
                    </Grid>
                </Grid>
            )
        } else {
            return null;
        }
    }

    render(){
        if (!this.state.isMobileScreen) {
            return (
                <Grid container item xs={6} direction="column" justifyContent="center" style={{height: "95vh", transform: "translateY(-5vh)"}}>
                    <div className="PartnerWithUsFormContainer">
                        {this.contactFormHeader()}
                        {this.currentStep()}
                        {this.screenDecider()}
                    </div>
                </Grid>
            )
        } else {
            return null;
        }
    }
}

export default withRouter(Header);