import React, { Component } from 'react';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import '../../assets/styles/Restaurants.css';
import SearchIcon from '@material-ui/icons/Search';
import dish from '../../assets/images/dish.svg';
import profile from '../../assets/images/profile.png';
import LocationOnIcon from '@material-ui/icons/LocationOn';

class SearchBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeOption: -1,
            searching: false,
            filteredOptions: [],
            showOptions: false,
            userInput: '',
            // options: [
            //     "Chicken Tandoori",
            //     "Chicken Kababs",
            //     "Chicken Shawarama",
            //     "Chocolate Cake",
            //     "Chocolate Ice Cream",
            //     "Paneer Tikka",
            //     "Kadhai Paneer",
            //     "Chicken Momo"
            // ]
            options: [],
            isMobileScreen: false
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.checkSearch = this.checkSearch.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    onChange = (e) => {
    
        const options = this.state.options;
        const userInput = e.currentTarget.value;
    
        const filteredOptions = options.filter(
          (optionName) =>
            optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
    
        this.setState({
          activeOption: -1,
          filteredOptions,
          showOptions: true,
          userInput: e.currentTarget.value
        });
        if (e.currentTarget.value !== ""){
            this.setState({
                searching : true
            })
        } else {
            this.setState({
                searching : false
            })
        }
    };

    onClick = (e) => {
        this.setState({
          activeOption: -1,
          filteredOptions: [],
          showOptions: false,
          userInput: e.currentTarget.innerText,
          searching: false
        });
    };

    onKeyDown = (e) => {
        const { activeOption, filteredOptions } = this.state;

        if (e.keyCode === 13) {
            if (activeOption !== -1){
                this.setState({
                    activeOption: -1,
                    showOptions: false,
                    userInput: filteredOptions[activeOption],
                    searching: false
                });
            }
        } else if (e.keyCode === 38) {
            if (activeOption <= 0) {
                this.setState({ activeOption: filteredOptions.length - 1 });
            } else {
                this.setState({ activeOption: activeOption - 1 });
            }
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                this.setState({ activeOption: 0 });
                return;
            }
            this.setState({ activeOption: activeOption + 1 });
        }
    };

    clearSearch = (e) => {
        this.setState({
            userInput : "",
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        }) 
    }

    checkSearch() {
        this.setState({
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        }) 
    }

    render() {
        let optionList;
        let searchBarColor;
        let containerShadow;
        let crossIcon;
        let searchIconStyle;
        if (this.state.showOptions && this.state.userInput) {
            if (this.state.filteredOptions.length) {
              optionList = (
                <div className="GlobalSearchBarOptions">
                  <div className="GlobalSearchBarOptionsContainer">
                    {this.state.filteredOptions.map((optionName, index) => {
                        let className;
                        if (index === this.state.activeOption) {
                        className = 'GlobalFilteredOptionActive';
                        }
                        return (
                        <div className={className + " GlobalFilteredOption"} key={optionName} onMouseDown={this.onClick}>
                            <img style={{height: "12px"}} src={dish} alt="dish" />
                            <div style={{display: "inline-block", marginLeft: "10px"}}>
                                {optionName}
                            </div>
                        </div>
                        );
                    })}
                  </div>
                </div>
              );
            } else {
              optionList = (
                <div className="GlobalSearchBarOptions">
                    <div className="GlobalFilteredOptionNotFound">Feature coming soon...</div>
                </div>
              );
            }
        }
        if (this.state.searching){
            searchBarColor = {backgroundColor: "#F9F9F9", position: "relative"}
            searchIconStyle = {top: "5px"}
            containerShadow = {boxShadow: "3px 3px 12px #1B1B1B59", marginTop: "15px", borderRadius: "12px 12px 0px 0px"};
        } else {
            searchBarColor = {backgroundColor: "white", display: "flex", position: "relative"}
            containerShadow = {};
            searchIconStyle = {};
        }
        if (this.state.userInput){
            crossIcon = (<span className="GlobalClearSearch" onMouseDown={this.clearSearch}>X</span>);
        } else {
            crossIcon = (<span></span>)
        }
        return (
            <div style={containerShadow} className="GlobalSearchBarSuper">
                <div style={searchBarColor}>
                    <SearchIcon style={searchIconStyle} className="GlobalSearchIcon"/>
                    <input
                        type="text"
                        className="GlobalSearchBar"
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        value={this.state.userInput}
                        onBlur={this.checkSearch}
                        placeholder="Search for Restaurants, Dishes, Cuisines..."
                    />
                    {crossIcon}
                </div>
                {optionList}
            </div>
        );
    }
}

class Cover extends Component {

    componentWillMount() {
        this.setState({
          activeItemIndex: 0,
          noOfItems: 0,
          isMobileScreen: false
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1 });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 2 });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 3 }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 4 }); 
        } else {
            this.setState({noOfItems: 5});
        }
        screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    showContactModalHandler() {
        this.props.showContactModal();
    }

    showTabs(){
        if (this.props.tabPresent){
            var likesStyle = {};
            var reviewsStyle = {};
            var cuisinesStyle = {};
            if (this.props.activeTab === "likes"){
                likesStyle = {color: "#FE042E", borderBottom: "2px solid #FE042E", fontSize: "16px"};
            } else if (this.props.activeTab === "reviews"){
                reviewsStyle = {color: "#FE042E", borderBottom: "2px solid #FE042E", fontSize: "16px"};
            } else {
                cuisinesStyle = {color: "#FE042E", borderBottom: "2px solid #FE042E", fontSize: "16px"};
            }
            return(
                <div className="ProfileTabsContainer">
                    <div className="ProfileTabs"><div onClick={() => this.props.toggleActiveTab("likes")} className="ProfileTabItem" style={likesStyle}>Likes</div></div>
                    <div className="ProfileTabs"><div onClick={() => this.props.toggleActiveTab("reviews")} className="ProfileTabItem" style={reviewsStyle}>Reviews</div></div>
                    <div className="ProfileTabs"><div onClick={() => this.props.toggleActiveTab("cuisines")} className="ProfileTabItem" style={cuisinesStyle}>Preferred Cuisines</div></div>
                </div>
            )
        } else {
            return null;
        }
    }

    getCoverClassName(){
        if (this.props.screenType === "settings"){
            return "RestaurantsCoverContainer ProfileCoverContainer SettingsCoverContainer";
        } else {
            return "RestaurantsCoverContainer ProfileCoverContainer";
        }
    }

    render() {
        let searchBarPopup;
        if (this.props.showSearchModal){
            searchBarPopup = (<div className="GlobalSearchBarContainer">
                <SearchBar/>
            </div>);
        } else {
            searchBarPopup = null;
        }
        var user = JSON.parse(localStorage.getItem("user"));

        if (user !== null && user !== ""){
            var username = user["username"];
            if (username === null || username === ""){
                username = user["name"];
            }
            username = username.split("_");
            var finalUser = "";
            for (var i=0; i<username.length; i++){
                finalUser = finalUser + " " + username[i].charAt(0).toUpperCase() + username[i].slice(1);
            }
            return (
                <div className="RestaurantsCoverSuperContainer ProfileCoverSuperContainer">
                    {searchBarPopup}
                    <div className={this.getCoverClassName()}>
                        <div className="ProfileCoverImage">
                            <img width="100%" height="100%"  src={profile} alt="Cover"/>
                        </div>
                        <div className="ProfileCoverDescription">
                            <div className="ProfileCoverName">{finalUser}</div>
                            <div className="ProfileCoverLocation">
                                <LocationOnIcon 
                                    htmlColor="white"
                                    style={{transform: "translateY(0.25vw)", fontSize: this.state.isMobileScreen ? "10px": "1.1vw"}}
                                /> Bangalore
                            </div>
                        </div>
                        {this.showTabs()}
                    </div>
                </div>
            )
        }
        else{
            return <></>
        }
    }
}


export default Cover;