import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiStarFill } from "react-icons/ri";
import { Star } from '@material-ui/icons';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonLoading from '../../assets/images/btnLoading.gif';

class NonRatedDish extends Component {

    constructor(props){
        super(props);
        this.nonRatedDishDivRef = React.createRef();
        this.itemStyle = this.itemStyle.bind(this);
        this.toggleLikeButton = this.toggleLikeButton.bind(this);
        this.ratingsModalHandler = this.ratingsModalHandler.bind(this);
        this.state = { 
            isMobileScreen: false,
            customisation_set: ""
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.setState({
            customisation_set: ""
        })
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         ["open_"+nextProps.popDishId] : nextProps.popDishState
    //     })
    // }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    componentWillMount() {
        this.setState({
            dishes: this.props.nonRatedDishes.dishes,
        });
    }

    itemStyle(id){
        if (!this.state.isMobileScreen){
            if (id%2 === 0){
                return {marginLeft: "20px"};
            } else {
                return {marginRight: "20px"};
            }
        } else {
            return {margin: "5px 0px", display: "flex", flexDirection: "row"}
        }
    }

    toggleLikeButton(index) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.toggleNonRatedLikeButton(this.props.nonRatedDishes.id, index);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    ratingsModalHandler(dish){
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid orange"};
        }
    }

    checkDisplay(){
        if (Object.keys(this.props.nonRatedDishes).length!==0 && this.props.nonRatedDishes["dishes"].filter(function(dish) {return dish.vegDisplay && dish.searchDisplay;}).length !== 0){
            return "block";
        } else {
            return "none";
        }
    }

    getDescription(description, maxLength){
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                return description.slice(0, maxLength) + "..";
            }
        } else {
            return "";
        }
    }

    getIndex(dish){
        for(let i=0; i<this.props.dishesAddingState.length; i++){
            if(this.props.dishesAddingState[i].dishID === dish.dishId)
                return i
        }
    }

    checkAddToCart(dish) {
        var token = localStorage.getItem("auth_token");
        let itemsPresent = false;
        let dishQuantity = 0;
        if (token !== null && token !== ""){
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI !== null) {
                let cartItems = JSON.parse(cartDetailsAPI)["items"];
                var itemKey = Object.keys(cartItems);
                var itemValue = Object.values(cartItems);
                for (var i=0; i<itemValue.length; i++){
                    if (Number(itemKey[i]) === Number(dish.dishId)){
                        dishQuantity = dishQuantity + itemValue[i]["quantity"];
                        itemsPresent = true;
                        //break;
                    }
                } 
            }
            if (dishQuantity > 0) {

            }
        }

        if(this.props.restaurant.is_accepting_orders){
            if (!itemsPresent) {
                return (
                    <>
                        {dish.in_stock === true ?
                            <>
                                {!this.props.dishesAddingState[this.getIndex(dish)].addingState ? 
                                    <span className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"} onClick={() => this.props.addToCartAction(dish, "add", false)} disabled><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                                    :
                                    <img src={ButtonLoading} alt="Complete Order & Pay" style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"5px", left:"13px", height:"20px"} : {width:"70px", position:"relative", top:"10px", left:"20px", height:"20px"}}/>
                                }
                            </> 
                        : 
                            // <span className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                            <span className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}>{this.state.isMobileScreen ? <>Out of Stock</> : <><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</>}</span>
                        }
                        {dish.customizations !== null && <span className="ddCustomisableTag" style={{position:"absolute", top: this.state.isMobileScreen ? "38px" : "41px", left: this.state.isMobileScreen ? dish.in_stock === true ? "104px" : "108px" : "131px"}}>Customisable</span>}
                    </>
                )
            } else {
                var nonRatedDishDivRef = React.createRef();
                return (
                    <>
                    {dish.in_stock === true ?
                        <span  ref={nonRatedDishDivRef} style={{padding: "6px 3px 6px 3px", marginLeft: this.state.isMobileScreen ? "10px" : "35px"}} className="cart-Item-Controls">
                            {this.props.dishesAddingState[this.getIndex(dish)].addingState ?
                                <img src={ButtonLoading} alt="loading.." style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"1px", height:"20px"} : {width:"63px", position:"relative", top:"1px"}}/>
                                :
                                <>
                                    <RemoveIcon style={{ fontSize: 14 }} className="remove-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={() => {
                                            this.props.addToCartAction(dish, "remove", true, nonRatedDishDivRef.current)}}/>
                                    <span style={{fontSize: "14px"}} className="item-Qty">{dishQuantity}</span>
                                    <AddIcon style={{ fontSize: 14 }} className="add-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={() => {
                                            this.props.addToCartAction(dish, "add", true, nonRatedDishDivRef.current)}}/>
                                </>
                            }
                            <Popover
                                id={this.props.popUpId}
                                open={this.props.popDishState && (this.props.popUpId === "anchorEl_"+dish.dishId+"_"+dish.id)}
                                anchorEl={this.props.popOverAnchorEl}
                                onClose={() => this.props.handlePopOverClose(dish)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {this.popOverDetails(dish.id)}
                            </Popover>
                        </span>
                    : 
                        <span className="ddAddCartBtnDisabled"><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    }
                    
                    {dish.customizations !== null && <span style={this.state.isMobileScreen ? {top:"21px"} : {}} className="ddCustomisableTagAdded">Customisable</span>}
                    </>
                )
            }
        }
        else{
            return(
                <>
                    <span className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"} style={this.state.isMobileScreen ? {padding:"4px 20px 5px"} : {}}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    {dish.customizations !== null && <span className="ddCustomisableTag" style={{position:"absolute", top: this.state.isMobileScreen ? "38px" : "41px", left: this.state.isMobileScreen ? "104px" : "131px"}}>Customisable</span>}
                </>
            )
        }
    }

    onChange(customItem){
        this.setState({
            customisation_set: customItem
        })
    }

    customisationList(){
        if(this.props.temp_customizations !== null){
            const customizations = this.props.temp_customizations;
            let listItems = null;
            let customizationsList = [];
            
            if(customizations.length > 1){
                for(var a=0; a<customizations.length; a++){
                    customizationsList.push(this.props.getCustomisationText(customizations[a]));
                }
                listItems = customizationsList.map((customItem, index) =>
                    <div key={customItem + "_" + index}>
                        <FormControlLabel 
                            checked={this.state.customisation_set === customizations[index] ? true: false}
                            onChange={this.onChange.bind(this,customizations[index])}
                            name={"custom_"+index} 
                            control={<Radio 
                                color="#EA5B2F"
                                size="small"/>} 
                            label={<span style={{ fontSize: '13px', fontFamily:'Poppins-Regular'}}>{customItem}</span>}
                        />                     
                    </div>
                );
            }
            else{
                listItems = this.props.getCustomisationText(customizations[0]);
            }
            
            return (
                <div className="ddCustomisationText2">{listItems}</div>
            );
        }
        else{
            return (
                <div className="ddCustomisationText2">No DATA</div>
            );
        }
    }

    popOverDetails(dishID){
        return(
            this.props.repeatCustomisationMessage === "Repeat Last Customisation?" ?
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.props.temp_customizations.length > 1 ?
                            this.state.customisation_set !== "" ? 
                                <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                    this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.state.customisation_set));
                                }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                            :
                                <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                    Select Customisation
                                </span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.props.temp_customizations[0]));
                            }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                        }
                        <span style={{marginLeft:"10px"}} className={this.state.isMobileScreen ? "ddPopOverIllChooseBtnM" : "ddPopOverIllChooseBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("No","");
                            }}>I'll Choose
                        </span>
                    </div>
                </div>
            :
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.state.customisation_set !== "" ? 
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Remove", JSON.stringify(this.state.customisation_set));
                            }}>Remove Selected</span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                Select Customisation
                            </span>
                        }
                        <span style={{marginLeft:"10px"}} className="ddPopOverIllChooseBtn" onClick={() => {
                                this.props.toggleRepeatCustomisation("","");
                            }}>Cancel
                        </span>
                    </div>
                </div>
        );
    }

    getDescriptionM(description, dishName, dishId){
        var maxLength = 40;
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return(<>{description}</> )
            } else {
                return( 
                    <>
                        {this.state["moreLess"+dishId] === "more" || this.state["moreLess"+dishId] === undefined ? description.slice(0, maxLength) + ".." : description + " "}
                        <div onClick={() => {
                            this.state["moreLess"+dishId] === "more" || this.state["moreLess"+dishId] === undefined ? this.setState({["moreLess"+dishId]: "less"}) : this.setState({["moreLess"+dishId]: "more"})
                            }} className="DishDescriptionReadMoreM">{this.state["moreLess"+dishId] === "more" || this.state["moreLess"+dishId] === undefined ? "Read more" : "Read less"}</div>
                    </> 
                )
            }
        } else {
            return(<>{dishName}</>)
        }
    }

    render() {
        var likeButtonStyle = [];
        if (Object.keys(this.props.nonRatedDishes).length!==0){
            for (var l=0; l<this.props.nonRatedDishes.dishes.length; l++){
                likeButtonStyle.push("");
                if (this.props.nonRatedDishes.dishes[l].liked === true) {
                    likeButtonStyle[l] = "LikeNewButton LikeNewButtonActive favme dashicons dashicons-heart";
                } else {
                    likeButtonStyle[l] = "LikeNewButton favme dashicons dashicons-heart";
                }
            }
            return (
                <div style={{display: this.checkDisplay()}} className="NonRatedDishContainer">
                    <div className="NonRatedDishTitle">{this.props.nonRatedDishes.title} ({this.props.nonRatedDishes?.dishes?.length})</div>
                    <div className="NonRatedDishList">
                        <Grid id={`resto_filter_id${this.props.nonRatedDishes.title}`} container direction="row" item alignItems="center" xs={12}>
                            {this.props.nonRatedDishes.dishes.map((dish) => {
                                if (dish.vegDisplay && dish.searchDisplay){
                                    return (
                                        this.state.isMobileScreen ? 
                                        <Grid item xs={12} key={dish.id}>
                                            <div className="ddHomeDishCardWrapperM">
                                                <Grid container item xs={12} style={{position:"relative"}}>
                                                    <div className="ddHomeDishItemM">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={(dish.image !== null && dish.image !== "") ? 8 : 12} style={{marginTop:"-15px"}}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <div style={{width: (dish.image !== null && dish.image !== "") ? "175px" : "90%", display: "flex", flexDirection: "row", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                                            <div style={{marginLeft:"-8px"}}>
                                                                                {dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                                                            </div>
                                                                            <div className="ddHomeDishNameM" style={{position:"relative", width:"95%"}}>{dish.name}</div>
                                                                        </div>
                                                                    </Grid>                                                                    
                                                                    <Grid item xs={12}>
                                                                        <div className="ddHomeDishCostM" style={{paddingLeft:"6px"}}>
                                                                            Rs {dish.cost}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <div className="ddNonRatedDishDescM" style={{paddingLeft:"6px"}}>
                                                                            { this.getDescriptionM(dish.description, dish.name, dish.dishId) }
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{position:"absolute", bottom:"5px", left:"-3px"}}>
                                                                        <span className="ddDishCardRateButtonM" style={{marginLeft:"7px"}} onClick={() => this.ratingsModalHandler(dish)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                                        {this.checkAddToCart(dish)}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>                                                     
                                                        </Grid>
                                                        
                                                        {(dish.image !== null && dish.image !== "") && <div className="ddHomeDishImageBlockM">
                                                            <img className="ddHomeDishImageM" src={dish.image} alt={dish.name}/>
                                                        </div>}
                                                        <div className="ddNonHomeDishRatingBlockM">
                                                            <Star fontSize="small" className="ddNonRatedDishStarIcon"/>
                                                            <span style={{fontFamily: "Poppins-SemiBold", fontSize:"14px"}}>{dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize:"12px"}}> ({dish.no_rating})</span>
                                                        </div>
                                                        <div className="ddNonRatedDishLikeBlock">
                                                            <div className="LikeButtonContainer">
                                                                <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                                                    <span onClick={() => this.toggleLikeButton(dish.id)} className={likeButtonStyle[dish.id]}></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        :
                                        <Grid key={dish.id} container item xs={6}>
                                            <div style={this.itemStyle(dish.id)} className={dish.in_stock ? "NonRatedDishItem" : "NonRatedDishItemOOS"}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={(dish.image !== null && dish.image !== "") ? 8 : 12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <span style={{marginLeft:"5px"}}>
                                                                    {dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                                                </span>
                                                                <span className="NonRatedDishName" style={{position:"relative", top:"-1px"}}>{dish.name}</span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <span className="NonRatedDishCost" style={{marginLeft:"19px", position:"relative", top:"-10px", fontSize:"15px"}}>
                                                                    Rs {dish.cost}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <div className="ddNonRatedDishDesc">{this.getDescription(dish.description, (dish.image !== null && dish.image !== "") ? 110 : 155)}</div>
                                                            </Grid>
                                                            
                                                            {dish.in_stock !== true && <Grid item xs={12} style={{position:"absolute", bottom:"50px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></Grid>}

                                                            <Grid item xs={12} style={{position:"absolute", bottom:"10px"}}>
                                                                <span className="ddDishCardRateButton" style={{marginLeft:"7px"}} onClick={() => this.ratingsModalHandler(dish)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                                {this.checkAddToCart(dish)}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>                                                     
                                                </Grid>
                                                
                                                {(dish.image !== null && dish.image !== "") && <div className="ddNonRatedDishImageBlock">
                                                    <img className="ddNonRatedDishImage" src={dish.image} alt={dish.name}/>
                                                </div>}
                                                <div className="ddNonRatedDishRatingBlock">
                                                    <StarIcon fontSize="small" className="ddNonRatedDishStarIcon"/>
                                                    <span style={{fontFamily: "Poppins-SemiBold", fontSize:"14px"}}>{dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize:"12px"}}> ({dish.no_rating})</span>
                                                </div>
                                                <div className="ddNonRatedDishLikeBlock">
                                                    <div className="LikeButtonContainer">
                                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                                            <span onClick={() => this.toggleLikeButton(dish.id)} className={likeButtonStyle[dish.id]}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                                

                                                {/* <div style={{margin: "15px 0px 20px 0px", flex: this.state.isMobileScreen? "1" : ""}}>
                                                    <div className="NonRatedDishInfo">
                                                        
                                                    </div>
                                                    <div className="NonRatedDishDetails">
                                                        <span>
                                                            <span style={{backgroundColor: "white", padding: "3px 10px", borderRadius: "15px", boxShadow: "0px 3px 6px #00000029", fontSize: "12px"}}>
                                                                &nbsp; 
                                                                <span style={{color: "#707070"}}>|</span> &nbsp;
                                                            </span>
                                                        
                                                    </div>
                                                </div>
                                                <div className="NonRatedDishLikeButtonContainerSuper">
                                                    <div className="LikeButtonContainer">
                                                        <div style={{backgroundColor: "#EA5B2F", padding: "3px 5px 3px 5px", borderRadius: "8px"}}>
                                                            <span onClick={() => this.toggleLikeButton(dish.id)} className={likeButtonStyle[dish.id]}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="NonRatedDishRateButton" >Rate</div>
                                                <div className="NonRatedDishRateButton" >Add to cart</div> */}
                                            </div>
                                        </Grid>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </Grid>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default NonRatedDish;