import React, { Component } from 'react';
import '../../assets/styles/PartnerTnc.css';
import { withRouter } from 'react-router';
import '../../assets/styles/PartnerWithUs.css';

// Top Header which contains logo, auth options, burger icon and header description
class Content extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

   render() {
    return (
        <div className="TncContainer">
            <Title/>
            <General/>
            <Agreement/>
            <DishName/>
            <FoodPhoto/>
            <FoodOrderTerms/>
            <Representation/>
            <Covenant/>
            <Ownership/>
            <Terms/>
            <Confidentiality/>
            <Disclaimer/>
            <Limitation/>
            <Reviews/>
            <Indemnity/>
            <GoverningLaw/>
            <Miscellaneous/>
            <Notice/>
            <Update/>
            <Survival/>
        </div>
    )
   }
}

class Title extends Component {
    render() {
        return(
            <div className="TncTitle">PARTNER/RESTAURANT TERMS AND CONDITIONS</div>
        )
    }
}

class General extends Component {
    render() {
        return(
            <div className="TncIntroContainer">
                <div className="TncSectionTitle">1. GENERAL</div>
                <div className="TncIntro">
                    This Partner Terms and Conditions (hereinafter referred to as the <span className="TncBold">"Terms"</span> or 
                    <span className="TncBold"> "Agreement"</span>) is entered into by and between Muchroom Technologies Private Limited (hereinafter referred to as the 
                    <span className="TncBold"> "Company"</span>
                     or <span className="TncBold"> "Us"</span> or <span className="TncBold"> "Our"</span> 
                     or <span className="TncBold"> "We"</span>)
                    and the entity/restaurant applying to become a partner of the Company (hereinafter referred to as the
                    <span className="TncBold"> "Partner"</span> or <span className="TncBold"> "You"</span> or <span className="TncBold"> "Your"</span>).
                </div>
                <div className="TncIntro TncAgreement">
                    BY COMPLETING THE PARTNER/RESTAURANT REGISTRATION
                    APPLICATION PROCESS OR CLICKING TO ACCEPT THIS AGREEMENT OR
                    ACCESSING OR USING ANY OF OUR CONTENT OR THE LINKS OR
                    OTHERWISE ENABLING ACCESS TO OUR WEBSITE FROM THE
                    RESTAURANT WEBSITE, YOU ON BEHALF OF THE
                    RESTAURANT/PARTNER AGREE TO BE BOUND BY THIS AGREEMENT.
                </div>
            </div>
        )
    }
}

class Agreement extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">2. TERMS</div>
                <div className="TncSectionContent">
                    The Company owns and operates an online platform in the name of <span className="TncBold"> "DaiDish" </span> 
                    for food product(s) review that enables its users to share ratings, 
                    comments, reviews, photographs, experience, recommendations of certain 
                    dishes and food products that are available at certain listed restaurants 
                    (hereinafter referred to as the <span className="TncBold"> "Listed Restaurant"</span>) through the website 
                    and application of the Company or its affiliated partners, including 
                    without limitation, the website(s) located at www.daidish.com (or such 
                    other URLs as We may provide from time to time) and all related mobile 
                    websites and applications (collectively referred to as the <span className="TncBold"> "Website"</span>).
                </div>
                <div className="TncSectionContent">
                    The Partner maintains a website at the URL provided in the <span className="TncBold">"Partner Website" </span> 
                    field on our website (collectively, with any related mobile site and applications, 
                    referred to as the <span className="TncBold">"Partner Website"</span>), and wishes to provide the links from the 
                    Partner Website to Our Website (hereinafter referred to as the <span className="TncBold">"Links"</span>). 
                    This Agreement contains the standard terms and conditions applicable to 
                    Partner's use of such Links.
                </div>
                <div className="TncSectionContent">
                    In addition, the Website also enables the Partner to list its dish and/or food products for order and 
                    delivery on the Website and on the Partner's Website through such Links. Customers of the Company can 
                    choose to place orders for dish and/or food products through the Website or through the Partner's Website 
                    by using the Links (<span className="TncBold">"Food Order/s"</span>/ <span className="TncBold">"Order/s"</span>
                    )). <span className="TncBold">"Customer"</span> shall mean the user who places order for dish and/or food 
                    products through the Website or the Partner's Website using the Links.
                </div>
                <div className="TncSectionContent">
                    If You are creating an account or claiming a business listing, then You represent to Us that you are 
                    the owner or authorized agent of such business and that there is no impersonation.
                </div>
                <div className="TncSectionContent">
                    You agree to use DaiDish Data owned by the Company (as available on the Services or through any other 
                    means like API etc.)  as per the terms of this Agreement and not distribute the same in any case. 
                </div>
                <div className="TncSectionContent">
                    You shall be responsible for all the User communication sent via the Users dashboard.
                </div>
            </div>
        )
    }
}

class DishName extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">3. FOOD PRODUCT/DISH NAME</div>
                <div className="TncSectionContent">
                    Partners shall put the names & photographs of the food product/dish in a manner 
                    as to how the users generally search for and identify such food product/dish to 
                    order and eat. For the sake of convenience and in order to make the restaurant 
                    listings user-friendly, following are some of the guidelines that must be followed 
                    for Dish/Food product listings by the Partner on our Website:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">3.1</div>
                    <div className="TncSectionSubContentBody">
                        The dish/food product names & photographs on our Website need to be 
                        written and uploaded in a manner that these appear on the physical 
                        menu of Your restaurant.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">3.2</div>
                    <div className="TncSectionSubContentBody">
                        Your restaurant taglines and establishment types must not be mentioned 
                        in the name of the dish/food product listing on our Website. This is 
                        because users usually remember dishes/food products by their name & 
                        looks, rather than by tagline, and this also makes these dishes/food 
                        products quite easier to be searched for.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">3.3</div>
                    <div className="TncSectionSubContentBody">
                        We don't add Dish/Food Product abbreviations in the Food/Product 
                        name on our Website. It is for this reason that this may adversely 
                        affect the search results when a user searches for the dish/food 
                        product organically. If You need to add an abbreviation or an alias, 
                        we can add them separately. For this, please write to us at 
                        (restaurants@daidish.com) if you'd like for us to add anything to 
                        make it searchable.
                    </div>
                </div>
            </div>
        )
    }
}

class FoodPhoto extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">4. FOOD PRODUCT PHOTOS</div>
                <div className="TncSectionContent">
                    We are a Food Product/Dish review and listing platform and the 
                    photostream of a dish or any food product gives reviewers, users and 
                    diners an idea about what to expect at the restaurant in terms of quantity, 
                    quality and looks of a food article.
                </div>
                <div className="TncSectionContent">
                    A complete set of food shots on a dish/food product page gives the 
                    reviewers and users all the information they need to make an informed 
                    decision.
                </div>
                <div className="TncSectionContent">
                    Below mentioned are some of the pointers to remember while uploading photos 
                    for the dishes:-
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.1</div>
                    <div className="TncSectionSubContentBody">
                        We only put up relevant photos that showcase the actual look and feel 
                        of the food article or dish. This is because users looking through 
                        the images would want to know what could affect their experience at 
                        the restaurant, primarily the brightness and colour of the food article.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.2</div>
                    <div className="TncSectionSubContentBody">
                        We do not put up images with people in them.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.3</div>
                    <div className="TncSectionSubContentBody">
                        We do not put up food shots that are stock images or are taken from 
                        Google Images or other websites, as that would be a copyright 
                        infringement. Partners shall be liable for such copyright infringement 
                        and shall keep us indemnified in this regard.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.4</div>
                    <div className="TncSectionSubContentBody">
                        Even if stock photos have been legally purchased by the restaurant, 
                        they cannot be uploaded as they do not depict the true nature of the 
                        food articles provided by the restaurant. This is misleading for the 
                        diners/users.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.5</div>
                    <div className="TncSectionSubContentBody">
                        We support JPEG and PNG file formats for uploading the photographs.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.6</div>
                    <div className="TncSectionSubContentBody">
                        A single frame can only contain a single photo. We try not to upload 
                        photo collages as they don't always show a clear picture of what's the 
                        actual food article/dish looks like.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.7</div>
                    <div className="TncSectionSubContentBody">
                        We do not use images where logos and social media handles take up a 
                        significant portion of the image. We are a neutral platform, and 
                        hence we don't want to seem as if we are promoting any food 
                        article/dish for any partner restaurant. This enhances user 
                        confidence and trustworthiness in our platform. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.8</div>
                    <div className="TncSectionSubContentBody">
                        All pictures the restaurant shares with us for promotion on social media are licence 
                        free, We do not take responsibility of copyright violations. You SHOULD NOT rate the 
                        food yourself or through any staff and friends. We reserve the right to delete any 
                        fraudulent ratings and also unregister You from our Platform.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">4.9</div>
                    <div className="TncSectionSubContentBody">
                        We are not responsible for the changes you do to your dishes, the pictures, cost, content, 
                        discounts, etc that You may provide. We advise you to make the changes carefully and ensure 
                        you check the changes that You have made. 
                    </div>
                </div>
            </div>
        )
    }
}

class FoodOrderTerms extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">5. TERMS FOR FOOD ORDER</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.1</div>
                    <div className="TncSectionSubContentBody">
                        For Food Orders, the Company is merely acting as an intermediary between the Customer 
                        and the Partner. The Company shall not be responsible for the Food Order and its delivery. 
                        The Company shall not be liable in any manner for any services rendered by the 
                        Partners/pick-up and delivery partners (“PDP”) including orders, pickup and delivery services. 
                        Any failure by the Partner/PDS to render the Food Order or pickup and delivery service shall 
                        be the sole responsibility of such PDS and the Partner acknowledges that the Partner shall not 
                        hold the Company responsible for such failure in any manner.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.2</div>
                    <div className="TncSectionSubContentBody">
                        The Company shall be responsible to collect payments for each Food Order from the Customer. 
                        The Partner will pay a commission to the Company for each Food Order. The rate of the commission 
                        payable by the Partner to the Company shall be mutually decided by the Parties.  In the event 
                        the Company does not receive full payment for the Food Order, the Company shall not be 
                        responsible nor liable to make any payments to the Partner. Payments to be made by the 
                        Company to the Partner shall be made in 7(seven) working days.  The Company shall not be 
                        responsible or liable to the Partner for any delay in payments due to the bank or any other reasons. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.3</div>
                    <div className="TncSectionSubContentBody">
                        When you provide your payment information to the Company, You are providing the Company with an 
                        authorization to process any and all payments as provided in this Agreement. The Company reserves 
                        the right to increase the commission payable to the Company.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.4</div>
                    <div className="TncSectionSubContentBody">
                        If we suspect any fraudulent transaction or transactions violating the terms of the Website use, 
                        we shall at Our sole discretion have the right to cancel such orders. These Users/accounts will 
                        be denied access from the use of the Website or purchase of products in the future.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.5</div>
                    <div className="TncSectionSubContentBody">
                        You shall be responsible to immediately remove a PDP from providing delivery services under the 
                        Agreement if such delivery personnel of the PDP are in the reasonable opinion of the Partner, 
                        found unfit to provide the delivery in a proper and professional manner.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.6</div>
                    <div className="TncSectionSubContentBody">
                        All payments made by the Users for Orders on the Website shall be in accordance with the price 
                        displayed on the Website.  Further, such payments shall be as per the terms of the third party 
                        online payment gateway services as applicable to your transaction.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.7</div>
                    <div className="TncSectionSubContentBody">
                        All applicable taxes and levies, the rates thereof and the manner of applicability of such 
                        taxes on the bill shall be charged and determined by the restaurant and PDP.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.8</div>
                    <div className="TncSectionSubContentBody">
                        FSSAI and GST provided by the Restaurant should be correct and valid at all times.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.9</div>
                    <div className="TncSectionSubContentBody">
                        All prices of the Orders listed on the Website are exclusive of GST. GST shall be additionally 
                        applicable to such prices.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">5.10</div>
                    <div className="TncSectionSubContentBody">
                        The Partner is responsible for selecting & informing the company the right GST slab to be 
                        applied on the food sold by the restaurant. The Company shall not be held responsible if the 
                        partner provides incorrect information about GST to be charged on the orders.
                    </div>
                </div>
            </div>
        )
    }
}

class Representation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">6. REPRESENTATIONS AND WARRANTIES</div>
                <div className="TncSectionContent">
                    Partners hereby represent and warrant to the Company that:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.1</div>
                    <div className="TncSectionSubContentBody">
                        It has the right, power, and authority to enter into this Agreement 
                        and perform its obligations as set forth herein. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.2</div>
                    <div className="TncSectionSubContentBody">
                        It is under no obligation or restriction that does or would interfere 
                        or conflict with its obligations hereunder, nor will it assume any 
                        such obligation or restriction.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.3</div>
                    <div className="TncSectionSubContentBody">
                        The information provided by the Partner in connection with the 
                        affiliate partner application & approval process and this Agreement 
                        is true, correct, and complete.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.4</div>
                    <div className="TncSectionSubContentBody">
                        It will upload only original and genuine names and photos of the 
                        food articles and will not provide images that infringe the copyright 
                        of any other person. It will not provide misleading photos or photos 
                        that are heavily edited to give a misleading view.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.5</div>
                    <div className="TncSectionSubContentBody">
                        It will follow all the guidelines and obligations mentioned under this Agreement.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">6.6</div>
                    <div className="TncSectionSubContentBody">
                        It will not violate these Terms, cause injury to any person or entity, or infringe 
                        any third-party rights (including, without limitation, intellectual property rights and 
                        rights of privacy or publicity.
                    </div>
                </div>
            </div>
        )
    }
}

class Covenant extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">7. LICENSE GRANT AND COVENANTS</div>
                <div className="TncSectionContent">
                    Subject to the terms of this Agreement, the Company grants you a personal, limited, non-exclusive, 
                    non-transferable, non-sub-licenseable, revocable license to access the Website. Except for this 
                    limited grant of access and use under this Agreement, the Company does not grant you any other 
                    rights to the Website.
                </div>
                <div className="TncSectionContent">
                    The Restaurants/Partner hereby covenants and agrees that:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.1</div>
                    <div className="TncSectionSubContentBody">
                        Partner shall comply with these Terms and Company's privacy policy available 
                        at <a target="_blank" rel="noopener noreferrer" href="https://daidish.com/privacypolicy">https://daidish.com/privacypolicy</a> as 
                        updated and is in effect, from time to time.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.2</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">The Partner Website</span> shall not be 
                        operated in violation of any applicable Indian Laws at the state 
                        and central or local law, rule or regulation or notification. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.3</div>
                    <div className="TncSectionSubContentBody">
                        The content included on the Partner Website, as well as the operation 
                        of the Partner Website, will not violate or infringe the copyright, 
                        trademark, trade name, patent, literary, intellectual, artistic or 
                        dramatic right, right of publicity or privacy or any other right of any 
                        entity or person or contain any material which is libellous, slanderous 
                        or obscene or immoral or illegal or against public policy.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.4</div>
                    <div className="TncSectionSubContentBody">
                        The content included on the Partner Website, or as uploaded by 
                        Partner on our <span className="TncBold">Website shall be such that 
                        does not promote enmity between any group, or hurt religious 
                        sentiments of any community.</span>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.5</div>
                    <div className="TncSectionSubContentBody">
                        If any of the information provided in the partner website and 
                        approval process changes at any time, Partner will immediately 
                        notify the Company, in writing.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.6</div>
                    <div className="TncSectionSubContentBody">
                        The Partner Website shall not publish, display, link to, sell or 
                        otherwise distribute any of the Prohibited Content, and Partner 
                        will remove any such Prohibited Content as soon as Partner becomes 
                        aware, or is made aware, of such Prohibited Content. For purposes 
                        of this Agreement, "Prohibited Content" means any material, 
                        including textual, audio or video material, which contains or 
                        promotes the following content: <br/>
                        <div className="TncBulletPoint">(i) death and tragedy;</div>
                        <div className="TncBulletPoint">(ii) juvenile or vulgar content;</div>
                        <div className="TncBulletPoint">(iii) profanity and rough language;</div>
                        <div className="TncBulletPoint">(iv) nudity, sexual or suggestive content;</div>
                        <div className="TncBulletPoint">(v) violence;</div>
                        <div className="TncBulletPoint">(vi) use of firearms;</div>
                        <div className="TncBulletPoint">(vii) error pages;</div>
                        <div className="TncBulletPoint">(viii) parked domains;</div>
                        <div className="TncBulletPoint">(ix) offer walls; or</div>
                        <div className="TncBulletPoint">(x) "fake news" or other defamatory or libellous content.</div>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.7</div>
                    <div className="TncSectionSubContentBody">
                        The Partner shall not reverse engineer, decompile, disassemble or otherwise attempt to discover 
                        the source code of the Website or any part thereof, except and only to the extent that such 
                        activity is expressly permitted by Applicable Law.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">7.8</div>
                    <div className="TncSectionSubContentBody">
                        The Partner shall not use the Website for any illegal or unauthorized purpose.
                    </div>
                </div>
            </div>
        )
    }
}

class Ownership extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">8. OWNERSHIP AND LICENSE</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">a)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Our Rights:</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    Our Website and its content shall remain the sole property of the Company. 
                    Your use of or access to this Website shall not in any way transfer or 
                    assign to you any ownership or other proprietary rights in or to this 
                    Website or any content published by Us or our Licensors. This Website 
                    and the Content, including but not limited to the layout, typography, 
                    underlying HTML, Java scripts, text, audio clips, video clips and graphics, 
                    and in the expression of the information contained herein, whether as a 
                    compilation or otherwise is protected by Copyright Act, 1957, Trade Marks 
                    Act, 1999 and other relevant IP laws, rules and regulations.. Trademarks, 
                    trade names and designs appearing on this Website are the exclusive 
                    property of, or are licensed to, the Company and are protected. No use 
                    of a trademark, trade name or design appearing on this Website may be 
                    made without the prior, written permission of the Company.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">b)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Your Rights:</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    The content that you write or upload on the Website (or we do on your behalf), such as photos 
                    and names of the food articles, may be protected by intellectual property laws. You own the 
                    intellectual property rights (things like copyright or trademarks) in any such content that 
                    you write or upload on Our Website. Nothing in these Terms takes away the rights you have to 
                    your content. You are free to share your content with anyone else, wherever you want.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">c)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">License:</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    However, to provide our services we need You to give us some legal permissions 
                    (known as a 'license') to use this content.
                </div>
                <div className="TncSectionContent">
                    You grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide 
                    license to host, use, distribute, modify, copy, publicly perform or display, and translate your content.
                </div> 
            </div>
        )
    }
}

class Terms extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">9. TERMS AND TERMINATION</div>
                <div className="TncSectionContent">
                    The term of this Agreement shall begin on the date that Partner clicks to 
                    accept this Agreement, or accesses, downloads, or uses our Website, services, 
                    links or otherwise enables access to our Website and shall continue until 
                    terminated as provided herein.
                </div>
                <div className="TncSectionContent">
                    We may terminate this Agreement or modify the permission granted herein at any time
                    upon written notice to the Partner. The Partner may terminate this Agreement at any
                    time upon thirty (30) days prior written notice to Us. This Agreement shall terminate
                    automatically if, at any time, Partner is in violation of this Agreement or any term of this
                    Agreement. Immediately upon the termination of the Agreement, we shall remove all
                    Links of the Partner from our website.
                </div>
            </div>
        )
    }
}

class Confidentiality extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">10. CONFIDENTIALITY</div>
                <div className="TncSectionContent">
                    The Company and the Partner hereby acknowledge that in the course of 
                    activities and engagement under this Agreement, each of them may have 
                    access to confidential and proprietary information which relates to the 
                    other party's technology, marketing and business (hereinafter referred to 
                    as the <span className="TncBold">"Confidential Information"</span>). The Confidential Information shall 
                    include the terms of this Agreement. Each party agrees to preserve and 
                    protect the confidentiality of the Confidential Information and to not 
                    use (except as provided for under the terms of this Agreement) or to 
                    disclose, or distribute any Confidential Information to any third party 
                    without the prior written consent of the other party; provided, however, 
                    that any party hereto may disclose to any other party any information 
                    which the receiving party demonstrates: 
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.1</div>
                    <div className="TncSectionSubContentBody">
                        Is or becomes generally known or available by publication, commercial 
                        use, or otherwise through no fault of such party;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.2</div>
                    <div className="TncSectionSubContentBody">
                        Is discovered or created by the receiving party without reference 
                        to the Confidential Information, as shown in records of such party;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.3</div>
                    <div className="TncSectionSubContentBody">
                        Is lawfully obtained (without restriction on disclosure) from a 
                        third party who has the right to make such disclosure;
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.4</div>
                    <div className="TncSectionSubContentBody">
                        Is released for publication by the disclosing party; or
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">10.5</div>
                    <div className="TncSectionSubContentBody">
                        Otherwise learned through legitimate means, other than from a 
                        third party under confidentiality obligations.
                    </div>
                </div>
                <div className="TncSectionContent">
                    Each party may disclose Confidential Information of the other party:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(a)</div>
                    <div className="TncSectionSubContentBody">
                        To its affiliates and its and its affiliates respective directors, 
                        officers, employees, authorized representatives, agents or advisors 
                        who are directly involved in negotiating or performing this Agreement 
                        and who are apprised of their obligations under this Section and 
                        directed by the receiving party to treat such information 
                        confidentially, or 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(b)</div>
                    <div className="TncSectionSubContentBody">
                        To the extent required by law, regulation, stock exchange rule, summon 
                        or court order after providing the disclosing party with the advance 
                        written notice if reasonably possible in such a manner that the 
                        disclosing party is allowed to contest the disclosure or seek an 
                        appropriate protective/restraining order from the court of law.
                    </div>
                </div>
            </div>
        )
    }
}

class Disclaimer extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">11. DISCLAIMER OF WARRANTIES</div>
                <div className="TncSectionContent">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT ALL INFORMATION, CONTENT, MATERIALS OR YOUR USE 
                    OF THE SITE IS AT YOUR SOLE RISK AND THAT THE SITE IS PROVIDED ON AN "AS IS" BASIS WITHOUT 
                    ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE COMPANY HEREBY DISCLAIMS ALL 
                    WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, CONCERNING THE WEBSITE, OUR MARKS, OUR 
                    CONTENT, CONTENT OF OUR LICENSORS/USERS, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY 
                    IMPLIED WARRANTIES OF INFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. 
                    THE COMPANY OBTAINS THE CONTENT APPEARING ON THE OUR WEBSITE FROM USERS, PARTNERS, THIRD PARTIES 
                    AND SOURCES THAT IT CONSIDERS RELIABLE; HOWEVER, NEITHER WE NOR ANY OF OUR VENDORS, AFFILIATES NOR 
                    PARTNERS WARRANT THE ACCURACY OR RELIABILITY OR COMPLETENESS OF SUCH CONTENT, WE DO NOT WARRANT 
                    THAT THE SITE OR SITE CONTENT ARE ERROR OR VIRUS FREE, WILL OPERATE WITHOUT INTERRUPTION, OR ARE 
                    COMPATIBLE WITH ALL EQUIPMENT AND SOFTWARE CONFIGURATIONS AND NO PARTNER SHALL HAVE ANY REMEDY 
                    AGAINST DAIDISH WITH RESPECT TO THE SAME.
                </div>
            </div>
        )
    }
}

class Limitation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">12. LIMITATION OF LIABILITY</div>
                <div className="TncSectionContent">
                    As the Parties are independent contractors, therefore they shall not be liable for the
                    act and conduct of one another, or their partner, affiliates, assignees, employees,
                    directors, licensors, etc.
                </div>
                <div className="TncSectionContent">
                    IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION, 
                    ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES, OR FOR ANY LOST 
                    PROFITS, SAVINGS, DATA OR USE, HOWEVER, CAUSED AND ON ANY THEORY OF LIABILITY (INCLUDING 
                    NEGLIGENCE OR STRICT LIABILITY), ARISING OUT OF THIS AGREEMENT, EVEN IF WE HAVE BEEN WARNED OF 
                    SUCH DAMAGE. YOU AGREE THAT THE LIABILITY OF THE COMPANY, ITS DIRECTORS, AGENTS, EMPLOYEES AND 
                    THIRD PARTIES ASSOCIATED WITH THE WEBSITE IS LIMITED SOLELY TO DIRECT DAMAGES AND IN NO EVENT 
                    SHALL IT EXCEED, THE AGGREGATE OF RS.10000.
                </div>
                <div className="TncSectionContent">
                    We will not be liable for any damage (physical or otherwise) incurred by You upon any asset or 
                    property from the installation or removal of any DaiDish product or add-on in-store and will not be 
                    responsible for replacement or cost of repair if damages occur. 
                </div>
            </div>
        )
    }
}

class Reviews extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">13. REVIEWS AND TESTIMONIALS</div>
                <div className="TncSectionContent">
                    We are a dish review platform and shall receive various reviews and testimonials through
                    a variety of submission methods. The testimonials are not necessarily representative
                    of all of those who will use our website & Application. We are not responsible for the
                    opinions, reviews or comments posted on the Website. All reviews, opinions
                    expressed are strictly the personal views of the user or reviewer. The testimonials
                    displayed are given verbatim except for grammatical or typing error corrections.
                    Some testimonials may have been edited for clarity or shortened in cases where the
                    original testimonial included extraneous information of no relevance to the mass
                    users. Testimonials may be reviewed for authenticity before they are posted for
                    public viewing.
                </div>
            </div>
        )
    }
}

class Indemnity extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">14. INDEMNITY</div>
                <div className="TncSectionContent">
                    Partner shall indemnify and hold harmless the Company, its affiliates, 
                    and their respective officers, directors, members, employees and agents 
                    (the “Company Indemnities”) from and against any and all claims, actions, 
                    suits or proceedings, as well as any and all losses, liabilities, damages, 
                    costs and expenses (including reasonable attorneys' fees) arising out of 
                    or accruing from:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(a)</div>
                    <div className="TncSectionSubContentBody">
                        any misrepresentation or breach of Partner's representations, 
                        warranties, and obligations outlined in this Agreement; and
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(b)</div>
                    <div className="TncSectionSubContentBody">
                        any non-compliance by Partner with any covenants, agreements or 
                        undertakings of Partner contained in or made according to this Agreement.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(c)</div>
                    <div className="TncSectionSubContentBody">
                        any damages, costs and expenses, including reasonable attorney's fees, arising out of or 
                        related to your breach or violation by you of this Agreement.
                    </div>
                </div>
            </div>
        )
    }
}

class GoverningLaw extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">15. GOVERNING LAW & DISPUTE RESOLUTION</div>
                <div className="TncExtraSpace"></div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">15.1</div>
                    <div className="TncSectionSubContentBody">
                        The relationship between the parties shall be governed by the laws of the Republic of India.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">15.2</div>
                    <div className="TncSectionSubContentBody">
                        In case of any claim, dispute, matter or controversy arising out of or 
                        concerning these terms between the parties, the parties shall spend 
                        at least 15 days to try and amicably resolve such dispute, through 
                        mutual discussions.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">15.3</div>
                    <div className="TncSectionSubContentBody">
                        If the Parties fail to resolve such claim, dispute, matter or 
                        controversy through mutual discussions, then they shall refer such 
                        claim, dispute, matter or controversy to a binding expedited 
                        Arbitration, to be headed by a single Arbitrator who shall be 
                        appointed mutually by both parties. The Arbitration proceedings 
                        shall be conducted in the English language and the seat of 
                        Arbitration shall be in Bangalore, Karnataka. The Arbitration 
                        proceedings shall be governed by the provisions of the Arbitration 
                        & Conciliation Act, 1996, and its applicable rules.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">15.4</div>
                    <div className="TncSectionSubContentBody">
                        The Courts of Bangalore, Karnataka shall have the exclusive 
                        jurisdiction to try and entertain any dispute relating to such 
                        Arbitration clause or the disputes that remain unresolved after 
                        arbitration.
                    </div>
                </div>
            </div>
        )
    }
}

class Miscellaneous extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">16. MISCELLANEOUS</div>
                <div className="TncExtraSpace"></div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.1</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Entire Agreement:</span> This Agreement 
                        constitutes the entire agreement between the parties and supersedes 
                        all oral or written agreements and understandings made and entered 
                        into by the parties before the date hereof.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.2</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Assignment:</span> Partner may not 
                        assign this Agreement in whole or in part without the prior written 
                        consent of the Company and any purported assignment in violation of 
                        this provision shall be null and void.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.3</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Successors and Assigns:</span> Subject to 
                        the foregoing limitation, this Agreement shall be binding upon and 
                        inure to the benefit of the parties hereto, their respective heirs, 
                        personal representatives, successors and assigns. 
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.4</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Severability:</span> If any provision 
                        of this Agreement shall be held by a court of competent jurisdiction 
                        to be illegal, invalid or unenforceable, the remaining provisions 
                        shall remain in full force and effect.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.5</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Waiver:</span> No waiver of any breach 
                        of any provision of this Agreement shall constitute a waiver of any 
                        prior, concurrent or subsequent breach of the same or any other 
                        provisions hereof, and no waiver shall be effective unless made in 
                        writing and signed by an authorized representative of the waiving party.
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">16.6</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Relationship:</span> The Agreement shall 
                        not be construed or deemed to create any partnership, joint venture, 
                        agency, franchise, or another form of agreement or relationship than 
                        as expressly set forth herein.
                    </div>
                </div>
            </div>
        )
    }
}

class Notice extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">17. NOTICE</div>
                <div className="TncSectionContent">
                    <span className="TncBold">All notices required</span> or permitted to be 
                    given under this Agreement will be deemed given:
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(a)</div>
                    <div className="TncSectionSubContentBody">
                        three business days after being deposited in the mail, first-class, 
                        postage prepaid,
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(b)</div>
                    <div className="TncSectionSubContentBody">
                        upon delivery, if served personally or sent by any generally 
                        recognized overnight carrier, or
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">(c)</div>
                    <div className="TncSectionSubContentBody">
                        upon transmission, if sent by e-mail during regular business hours 
                        (or on the next business day if after regular business hours).
                    </div>
                </div>
                <div className="TncSectionContent">
                    Concerning the Company, a copy of any notice should be sent to <a href="mailto:info@daidish.com">info@daidish.com</a>.
                </div>
            </div>
        )
    }
}

class Update extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">18. UPDATE TO THESE TERMS</div>
                <div className="TncSectionContent">
                    We may add to or change or update these Terms at any time, from time to 
                    time, entirely at its discretion, with or without any prior written notice. 
                    You are responsible for checking these Terms periodically. Your use of the 
                    Website after any amendments to the Terms shall constitute your acceptance 
                    of such amendments.
                </div>
            </div>
        )
    }
}

class Survival extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">19. SURVIVAL</div>
                <div className="TncSectionContent">
                    The obligations under the provisions of section 10, 11, 12, 14 and 15 will survive any expiration or 
                    termination of these Terms.
                </div>
            </div>
        )
    }
}

export default withRouter(Content);