import axios from "axios";
import React, { Component } from "react";
import CONSTANTS from "../config/constants";
import Footer from "../components/Commons/Footer";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import "../assets/styles/Commons.css";
import Header from "../components/Cart/Header";
import UserOptions from "../components/Dishes/UserOptions";
import LogoutConfirm from "../components/Commons/LogoutConfirm";
import OrderCard from "../components/Cart/OrderCard";
import DeliveryAddressCard from "../components/Cart/DeliveryAddressCard";
import NoItemsInCart from "../assets/images/NoItemsCartImage.png";
import { IoMdPricetags } from "react-icons/io";
// import { MdOutlineEditNote } from "react-icons/md";
import ButtonLoading from "../assets/images/btnLoading.gif";
import { RiInformationLine } from "react-icons/ri";
import Divider from "@material-ui/core/Divider";
import { BsExclamationTriangle } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import DaiDishLoading1 from "../assets/images/DaiDishLoading1.gif";
import { MdLocationPin } from "react-icons/md";
import { RiWallet2Line } from "react-icons/ri";
import OrderReady from "../assets/images/OrderReady.gif";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import PlaceOutlinedIcon from "@material-ui/icons/PlaceOutlined";
import { setDeviceToken } from "../services/notification_handler";
import CFPayments from "../assets/images/cfpayments.png";
import Paylogos from "../assets/images/cards-strip.png";
// import DiscountIcon from "../assets/images/discountIcon.png";
import AuthPopup from "../components/Commons/AuthPopup";
import ConfirmationBox from "../components/Commons/ConfirmationBox";
import DiscountImage from "../assets/images/discountImage.png";
import FreeDelivery from "../assets/images/freeDelivery1.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CouponIcon from "../assets/images/CouponIcon.svg";
import CouponAppliedIcon from "../assets/images/CouponAppliedIcon.svg";

class Cart extends Component {
  constructor() {
    super();
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.showAuthPopupModal = this.showAuthPopupModal.bind(this);
    this.closeAuthPopupModal = this.closeAuthPopupModal.bind(this);
    this.showDeliveryModeCard = this.showDeliveryModeCard.bind(this);
    this.showPaymentCard = this.showPaymentCard.bind(this);
    this.CouponSection = this.CouponSection.bind(this);
    this.RestoDiscount = this.RestoDiscount.bind(this);
    this.OrderSummary = this.OrderSummary.bind(this);
    this.selectedCouponSection = this.selectedCouponSection.bind(this);
    this.changeCoupon = this.changeCoupon.bind(this);
    this.getCoupons = this.getCoupons.bind(this);
    this.getUserAddress = this.getUserAddress.bind(this);
    this.getCartList = this.getCartList.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.getSelectedAddrMob = this.getSelectedAddrMob.bind(this);
    this.updateDeliveryMode = this.updateDeliveryMode.bind(this);
    this.toggleConfirmationBox = this.toggleConfirmationBox.bind(this);
    this.switchToPickup = this.switchToPickup.bind(this);
    this.state = {
      isMobileScreen: false,
      isOpen: false,
      logoutModal: false,
      addresses: {},
      addressCardVisibility: true,
      deliveryModeVisibility: false,
      paymentCardVisibility: false,
      coupons: [],
      sumTotal: 0,
      couponDiscPerc: 0,
      couponDiscAmt: 0,
      taxCharges: 0,
      taxPercent: 10,
      restoDiscPerc: 20,
      restoDiscAmt: 0,
      //deliveryCharges: 25,
      //packagingCharges: 10,
      grandTotal: 0,
      selectedCoupon: -1,
      amountPaidIndicator: false,
      cartList: [],
      deliveryCharges: 0,
      taxesAndCharges: 0,
      packagingCharges: 0,
      gstAndTaxes: 0,
      restaurant_name: "",
      restaurant_id: "",
      selectedDeliveryMode: "RESTO",
      defaultAddresses: "",
      itemsInCart: null,
      restoAddress: "",
      authPopupModal: false,
      activeForm: "",
      is_accepting_orders: false,
      is_delivery_available: false,
      selectedAddr: 0,
      restoDiscount: 0,
      readPolicy: false,
      paymentInitiated: false,
      confirmationBoxMessage: "",
      showConfirmationBox: false,
      dataFetched: false,
      eligibleCoupons: [],
      appliedCouponId: -1,
      couponDiscount: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getUserAddress();
    this.getCoupons();
    this.getEligibleCoupons();
    this.getCartDetails();
    this.getCartList();

    if (
      localStorage.getItem("auth_token") !== null &&
      localStorage.getItem("auth_token") !== ""
    ) {
      let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
      let firebaseReq = {
        token: localStorage.getItem("device_token"),
      };
      let firebaseHeaders = {
        Authorization: "Bearer " + localStorage.getItem("auth_token"),
      };
      axios
        .post(firebaseUrl, firebaseReq, {
          headers: firebaseHeaders,
        })
        .then((response) => {});
    }

    setDeviceToken();
    navigator.serviceWorker.addEventListener("message", (event) => {
      if ("data" in event.data.data) {
        var orderDetails = JSON.parse(event.data.data.data);
        var orderEvent = event.data.data.event;
        if (orderEvent === "new_order") {
          this.setState({
            newOrderModal: true,
            newOrderId: orderDetails.order_id,
          });
        }
      }
    });
  }

  getCartDetails() {
    var token = localStorage.getItem("auth_token");
    if (token) {
      var headers = {
        Authorization: "Bearer " + token,
      };
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios
        .get(url, {
          headers: headers,
        })
        .then((response) => {
          let data = response.data;
          if (data.status === 1 && "id" in data.data) {
            let cart = data.data;
            if (cart?.coupon !== null) {
              this.setState({
                appliedCouponId: cart?.coupon?.id || -1,
                couponDiscount: cart?.extra_data?.coupon?.coupon_discount,
              });
            }
            localStorage.setItem("cartDetailsAPI", JSON.stringify(data.data));
            //this.updateCartChangedValue(true);

            let cartDetails = {};
            let itemsInCart = 0;
            let cartItems = [];
            for (var key in cart.items) {
              if (cart.items[key].customizations.length > 1) {
                for (
                  var k = 0;
                  k < cart.items[key].customizations.length;
                  k++
                ) {
                  cartItems.push({
                    DishID: parseInt(key),
                    DishName: cart.items[key].name,
                    DishCost: cart.items[key].customizations[k].sub_total,
                    DishQuantity: cart.items[key].customizations[k].quantity,
                    DishImage: cart.items[key].image,
                    DishIsVeg: cart.items[key].choice === "V" ? true : false,
                    Customisation: cart.items[key].customizations[k],
                  });
                  itemsInCart =
                    itemsInCart + cart.items[key].customizations[k].quantity;
                }
              } else {
                cartItems.push({
                  DishID: parseInt(key),
                  DishName: cart.items[key].name,
                  DishCost: cart.items[key].mrp,
                  DishQuantity: cart.items[key].quantity,
                  DishImage: cart.items[key].image,
                  DishIsVeg: cart.items[key].choice === "V" ? true : false,
                  Customisation: cart.items[key].customizations[0],
                });
                itemsInCart = itemsInCart + cart.items[key].quantity;
              }
            }
            cartDetails.RestoID = cart.restaurant;
            cartDetails.RestoName = cart.restaurant_name;
            cartDetails.Cart_Items = cartItems;
            //itemsInCart = cartDetails.Cart_Items.length;
            localStorage.setItem("CartDetails", JSON.stringify(cartDetails));

            let cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
            if (cartDetailsAPI !== null && cartDetailsAPI !== "") {
              let restoID = JSON.parse(localStorage.getItem("cartDetailsAPI"))
                .restaurant.id;
              let url =
                CONSTANTS.hostURL +
                "api/main_app/restaurant_details/?restaurant=" +
                restoID;
              axios.get(url).then((res) => {
                if (res.data.results) {
                  if (res.data.results.length === 0) {
                    return;
                  }
                  let result = res.data.results[0];
                  this.setState(
                    {
                      is_accepting_orders: result.is_accepting_orders,
                      is_delivery_available: result.is_delivery_available,
                    },
                    () => {
                      if (!this.state.is_accepting_orders) {
                        this.setState({
                          paymentCardVisibility: false,
                          addressCardVisibility: false,
                        });
                      } else if (
                        this.state.is_accepting_orders &&
                        !this.state.is_delivery_available
                      ) {
                        this.setAsPickup();
                        this.setState({
                          selectedDeliveryMode: "PICKUP",
                          paymentCardVisibility: true,
                          addressCardVisibility: false,
                        });
                      }
                    }
                  );
                }
              });
            }

            this.setState({
              itemsInCart: itemsInCart,
              cartChanged: false,
              restaurant_name: cart.restaurant_name,
              restaurant_id: JSON.parse(localStorage.getItem("cartDetailsAPI"))
                .restaurant.id,
              selectedDeliveryMode:
                JSON.parse(localStorage.getItem("cartDetailsAPI"))
                  .delivery_mode === "SELF_PICKUP"
                  ? "PICKUP"
                  : "RESTO",
              paymentCardVisibility:
                JSON.parse(localStorage.getItem("cartDetailsAPI"))
                  .delivery_mode !== ""
                  ? true
                  : false,
              addressCardVisibility:
                JSON.parse(localStorage.getItem("cartDetailsAPI"))
                  .delivery_mode !== ""
                  ? false
                  : true,
            });
          } else {
            if (data.status === -1) {
              localStorage.removeItem("cartDetailsAPI");
              this.setState({
                itemsInCart: 0,
              });
            }
          }
        });
    }
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if (screen_width < 500) {
      this.setState({ isMobileScreen: true });
    } else {
      this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = { 9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1 };
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener("touchmove", this.preventDefault, wheelOpt); // mobile
    window.addEventListener("keydown", this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
    window.removeEventListener("touchmove", this.preventDefault, wheelOpt);
    window.removeEventListener(
      "keydown",
      this.preventDefaultForScrollKeys,
      false
    );
  }

  componentDidUpdate() {
    try {
      var supportsPassive = false;
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
            return supportsPassive;
          },
        })
      );
    } catch (e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
    if (this.state.authModalOpen && !this.state.isMobileScreen) {
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount() {
    try {
      var supportsPassive = false;
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
            return supportsPassive;
          },
        })
      );
    } catch (e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true,
    });
  }

  setActiveForm(form) {
    if (this.state.activeForm !== form) {
      this.setState({
        activeForm: form,
      });
    }
  }

  closeAuthModal() {
    if (this.state.authModalOpen) {
      this.setState({
        authModalOpen: false,
      });
    }
  }

  toggleUserOptions() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal,
    });
  }

  showAuthPopupModal(form) {
    this.setState({
      authPopupModal: true,
    });
    if (form != null) {
      this.setState({
        activeForm: form,
      });
    }
  }

  closeAuthPopupModal() {
    this.setState({
      authPopupModal: false,
    });
    window.location.pathname = "/";
  }

  editAddressItem(address, updateType) {
    let addressList = this.state.addresses;
    let index = -1;

    if (updateType === "defaultUpdate") {
      for (var j = 0; j < addressList.length; j++) {
        addressList[j].is_default = false;
        if (addressList[j].id === address.id) {
          index = j;
        }
      }
      if (index !== -1) {
        addressList[index] = address;
      }
    } else if (updateType === "delete") {
      for (var k = 0; k < addressList.length; k++) {
        if (addressList[k].id === address) {
          index = k;
        }
      }
      if (index !== -1) {
        addressList.splice(index, 1);
      }
    } else {
      for (var i = 0; i < addressList.length; i++) {
        if (addressList[i].id === address.id) {
          index = i;
        }
      }
      if (index !== -1) {
        addressList[index] = address;
      } else {
        addressList.push(address);
      }
    }

    this.setState({
      addresses: addressList,
    });
  }

  getUserAddress() {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== "") {
      var headers = {
        Authorization: "Bearer " + token,
      };
      var url = CONSTANTS.hostURL + "api/geo/location/";
      axios
        .get(url, {
          headers: headers,
        })
        .then((response) => {
          var resData = response.data;
          if (resData.status === 1) {
            if (
              JSON.parse(localStorage.getItem("cartDetailsAPI")) !== null &&
              JSON.parse(localStorage.getItem("cartDetailsAPI"))[
                "delivery_address"
              ] !== null
            ) {
              for (let i = 0; i < resData.data.locations.length; i++) {
                if (
                  resData.data.locations[i].id ===
                  JSON.parse(localStorage.getItem("cartDetailsAPI"))[
                    "delivery_address"
                  ]["id"]
                ) {
                  this.setState({
                    defaultAddresses: (
                      <>
                        <span style={{ fontFamily: "Poppins-Semibold" }}>
                          {" "}
                          {resData.data.locations[i].tag}
                        </span>
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          ,{" "}
                          {resData.data.locations[i].full_address.replaceAll(
                            ";",
                            ", "
                          )}
                        </span>
                      </>
                    ),
                  });
                }
              }
            } else {
              for (let i = 0; i < resData.data.locations.length; i++) {
                if (resData.data.locations[i].is_default) {
                  this.setState({
                    defaultAddresses: (
                      <>
                        <span style={{ fontFamily: "Poppins-Semibold" }}>
                          {" "}
                          {resData.data.locations[i].tag}
                        </span>
                        <span style={{ fontFamily: "Poppins-Regular" }}>
                          ,{" "}
                          {resData.data.locations[i].full_address.replaceAll(
                            ";",
                            ", "
                          )}
                        </span>
                      </>
                    ),
                  });
                }
              }
            }
            this.setState({ addresses: resData.data.locations });
          } else {
            this.setState({ addresses: [] });
          }
        });
    } else {
      this.showAuthPopupModal("login");
    }
  }

  getCoupons() {
    let coupons = [];
    let restoDiscAmt = 0;
    let couponDiscAmt = 0;
    let cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    if (cartDetailsAPI !== null) {
      let cartDetailsObj = JSON.parse(cartDetailsAPI);
      var couponVals = cartDetailsObj.extra_data.discounts;
      for (var i = 0; i < couponVals.length; i++) {
        if (couponVals[i].discount_type !== "resto_discount") {
          coupons.push(couponVals[i]);
          restoDiscAmt = restoDiscAmt + couponVals[i].discount;
        } else {
          couponDiscAmt = couponDiscAmt + couponVals[i].discount;
        }
      }
    }
    this.setState({
      coupons: coupons,
      restoDiscAmt: restoDiscAmt,
      couponDiscAmt: couponDiscAmt,
    });
  }

  getEligibleCoupons = () => {
    const cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    const token = localStorage.getItem("auth_token");
    if (cartDetailsAPI !== null && token !== null) {
      const cartDetailsJSON = JSON.parse(cartDetailsAPI);
      console.log("cartDetailsJSON", cartDetailsJSON);
      const headers = { Authorization: "Bearer " + token };
      const url =
        CONSTANTS.hostURL +
        `api/main_app/cart/getcoupon/?cart_id=${cartDetailsJSON.id}`;
      axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          const data = res.data;
          if (data.status === 1) {
            this.setState({
              eligibleCoupons: data.data.eligible_coupons,
            });
          }
        })
        .catch((err) => console.log("eligibleCouponsError=>", err));
    }
  };

  applyEligibileCoupon = (couponId, index) => {
    let url = CONSTANTS.hostURL + "api/main_app/cart/applycoupon/";
    const token = localStorage.getItem("auth_token");
    const cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    if (cartDetailsAPI !== null && token !== null) {
      const cartDetailsJSON = JSON.parse(cartDetailsAPI);
      console.log("cartDetailsJSON", cartDetailsJSON);
      const headers = { Authorization: "Bearer " + token };
      const request = { cart_id: cartDetailsJSON.id, coupon_id: couponId };
      axios
        .post(url, request, {
          headers: headers,
        })
        .then((res) => {
          const data = res.data;
          const eligibleCoupons = [...this.state.eligibleCoupons];
          console.log("data", data.errors.message);
          if (data.status === 1) {
            let cart = data.data;
            if (cart?.coupon !== null) {
              this.setState({
                appliedCouponId: cart?.coupon?.id || -1,
                couponDiscount: cart?.extra_data?.coupon?.coupon_discount,
              });
            }
            localStorage.setItem("cartDetailsAPI", JSON.stringify(cart));
            this.getUserAddress();
            this.getCoupons();
            this.getCartDetails();
            this.getCartList();
          } else {
            eligibleCoupons[index].errorMessage = data.errors.message;
          }
          this.setState({
            eligibleCoupons: [...eligibleCoupons],
          });
        })
        .catch((err) => console.log("eligibleCouponsError=>", err));
    }
  };

  removeEligibileCoupon = (couponId, index) => {
    let url = CONSTANTS.hostURL + "api/main_app/cart/removecoupon/";
    const token = localStorage.getItem("auth_token");
    const cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    if (cartDetailsAPI !== null && token !== null) {
      const cartDetailsJSON = JSON.parse(cartDetailsAPI);
      console.log("cartDetailsJSON", cartDetailsJSON);
      const headers = { Authorization: "Bearer " + token };
      const request = { cart_id: cartDetailsJSON.id, coupon_id: couponId };
      axios
        .post(url, request, {
          headers: headers,
        })
        .then((res) => {
          const data = res.data;
          const eligibleCoupons = [...this.state.eligibleCoupons];
          console.log("data", data.errors.message);
          if (data.status === 1) {
            let cart = data.data;
            this.setState({
              appliedCouponId: -1,
              couponDiscount: 0,
            });
            localStorage.setItem("cartDetailsAPI", JSON.stringify(cart));
            this.getUserAddress();
            this.getCoupons();
            this.getCartDetails();
            this.getCartList();
          } else {
            eligibleCoupons[index].errorMessage = data.errors.message;
          }
          this.setState({
            eligibleCoupons: [...eligibleCoupons],
          });
        })
        .catch((err) => console.log("eligibleCouponsError=>", err));
    }
  };

  showAddressCard() {
    this.setState({
      addressCardVisibility: true,
      deliveryModeVisibility: false,
      paymentCardVisibility: false,
    });
  }

  showDeliveryModeCard() {
    this.setState({
      addressCardVisibility: false,
      deliveryModeVisibility: true,
      paymentCardVisibility: false,
    });
  }

  showPaymentCard() {
    this.setState({
      addressCardVisibility: false,
      deliveryModeVisibility: false,
      paymentCardVisibility: true,
    });
  }

  selectCoupon(keyItem) {
    let cartDetails = localStorage.getItem("CartDetails");
    var obj = JSON.parse(cartDetails);
    let sumTotal = 0;
    for (let k = 0; k < obj.Cart_Items.length; k++) {
      sumTotal =
        sumTotal + obj.Cart_Items[k].DishCost * obj.Cart_Items[k].DishQuantity;
    }

    let restoDiscAmt = ((this.state.restoDiscPerc / 100) * sumTotal).toFixed(2);
    let taxCharges = ((this.state.taxPercent / 100) * sumTotal).toFixed(2);
    let couponDiscAmt = ((keyItem.percentage / 100) * sumTotal).toFixed(2);
    let grandTotal = (
      Number(Number(sumTotal) + Number(this.state.deliveryCharges)) -
      Number(
        Number(((keyItem.percentage / 100) * sumTotal).toFixed(2)) +
          Number(((this.state.restoDiscPerc / 100) * sumTotal).toFixed(2))
      )
    ).toFixed(2);

    this.setState({
      sumTotal: sumTotal,
      restoDiscAmt: restoDiscAmt,
      taxCharges: taxCharges,
      grandTotal: grandTotal,
      couponDiscPerc: keyItem.percentage,
      couponDiscAmt: couponDiscAmt,
      selectedCoupon: keyItem.id,
    });
  }

  changeCoupon() {
    let cartDetails = localStorage.getItem("CartDetails");
    var obj = JSON.parse(cartDetails);
    let sumTotal = 0;
    for (let k = 0; k < obj.Cart_Items.length; k++) {
      sumTotal =
        sumTotal + obj.Cart_Items[k].DishCost * obj.Cart_Items[k].DishQuantity;
    }

    let restoDiscAmt = ((this.state.restoDiscPerc / 100) * sumTotal).toFixed(2);
    let taxCharges = ((this.state.taxPercent / 100) * sumTotal).toFixed(2);
    let couponDiscAmt = 0;
    let grandTotal = (
      Number(Number(sumTotal) + Number(this.state.deliveryCharges)) -
      Number(
        Number(((0 / 100) * sumTotal).toFixed(2)) +
          Number(((this.state.restoDiscPerc / 100) * sumTotal).toFixed(2))
      )
    ).toFixed(2);

    this.setState({
      sumTotal: sumTotal,
      restoDiscAmt: restoDiscAmt,
      taxCharges: taxCharges,
      grandTotal: grandTotal,
      couponDiscPerc: 0,
      couponDiscAmt: couponDiscAmt,
      selectedCoupon: -1,
    });
  }

  selectedCouponSection() {
    if (this.state.selectedCoupon > 0) {
      let keyItem = {};
      for (let i = 0; i < this.state.coupons.length; i++) {
        if (this.state.coupons[i].id === this.state.selectedCoupon)
          keyItem = this.state.coupons[i];
      }

      const listItems = (
        <Grid
          item
          key={"Coupon_" + keyItem.id}
          xs={12}
          style={{ paddingTop: "10px" }}
        >
          <Grid container spacing={2} className="coupon-details">
            <Grid item xs={7}>
              <IoMdPricetags
                fontSize="small"
                className="voucher-coupon-style"
              />
              <span className="coupon-name">{keyItem.name}</span>
              <div className="coupon-discount-details-left">
                {keyItem.description}
              </div>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "right" }}>
              <span
                className="coupon-discount-details-right"
                style={{ color: "rgb(79, 147, 236)" }}
              >
                -Rs {this.state.couponDiscAmt}
              </span>
              <div
                className="coupon-discount-details-right"
                style={{ color: "#EA5B2F", cursor: "pointer" }}
                onClick={this.changeCoupon}
              >
                Change Offer
              </div>
              {/* <div className="couponSelectBtn" onClick={() => this.selectCoupon(keyItem)}>SELECT</div> */}
            </Grid>
          </Grid>
        </Grid>
      );
      return (
        <Grid container spacing={2}>
          {listItems}
        </Grid>
      );
    }
  }

  CouponSection() {
    const listItems = this.state.coupons.map(
      (keyItem, index) =>
        keyItem.discount > 0 && (
          <Grid
            item
            key={"Coupon_" + index}
            xs={12}
            style={{ paddingTop: "5px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <span className="coupon-name">
                  {keyItem.text} {keyItem.percent}%
                </span>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right" }}>
                <span className="coupon-discount-details-right">
                  -Rs {keyItem.discount}
                </span>
              </Grid>
            </Grid>
          </Grid>
        )
    );
    return (
      <Grid container spacing={2}>
        {listItems}
      </Grid>
    );
  }

  RestoDiscount() {
    let cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    let coupons = [];
    if (cartDetailsAPI !== null) {
      let cartDetailsObj = JSON.parse(cartDetailsAPI);
      var couponVals = cartDetailsObj.extra_data.discounts;
      for (var i = 0; i < couponVals.length; i++) {
        if (couponVals[i].discount_type === "resto_discount")
          coupons.push(couponVals[i]);
      }
    }

    const listItems = coupons.map(
      (keyItem, index) =>
        keyItem.discount > 0 && (
          <Grid key={index} container spacing={2} style={{ paddingTop: "5px" }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={7} style={{ position: "relative", top: "-2px" }}>
                  <span className="coupon-name">
                    {keyItem.text} {keyItem.percent}%
                  </span>
                </Grid>
                <Grid item xs={5} style={{ textAlign: "right" }}>
                  <span className="coupon-discount-details-right">
                    -Rs {keyItem.discount}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
    );

    return <>{listItems}</>;
  }

  OrderSummary() {
    return (
      <div style={{ paddingTop: "20px" }}>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className="summary-header">Payment Summary</span>
            <br />
          </Grid>
          <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <div className="summary-details-left">Subtotal</div>
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}>
            <div className="summary-details-right">
              Rs {this.state.sumTotal}
            </div>
          </Grid>
          {this.state.selectedDeliveryMode === "RESTO" && (
            <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className="summary-details-left">Delivery Charges</div>
            </Grid>
          )}
          {this.state.selectedDeliveryMode === "RESTO" && (
            <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}>
              {this.state.deliveryCharges > 0 ? (
                <div className="summary-details-right">
                  Rs {this.state.deliveryCharges}
                </div>
              ) : (
                <div className="summary-details-right">
                  <img
                    src={FreeDelivery}
                    alt="FreeDlvImage"
                    style={{
                      width: "20px",
                      marginRight: "5px",
                      position: "relative",
                      top: "5px",
                    }}
                  />
                  <span
                    style={{ color: "#3356AA", fontFamily: "Quicksand-Bold" }}
                  >
                    Free Delivery
                  </span>
                </div>
              )}
            </Grid>
          )}
          {this.state.appliedCouponId !== -1 && (
            <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              {this.state.eligibleCoupons.map((coupon) => {
                if (this.state.appliedCouponId === coupon.id) {
                  return (
                    <div
                      className="summary-details-left"
                      style={{ color: "rgb(248, 0, 165)" }}
                    >
                      Coupon Discount ({coupon.coupon_code})
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          )}
          {this.state.appliedCouponId !== -1 && (
            <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}>
              {this.state.eligibleCoupons.map((coupon) => {
                if (this.state.appliedCouponId === coupon.id) {
                  return (
                    <div
                      className="summary-details-left"
                      style={{ color: "rgb(248, 0, 165)" }}
                    >
                      - Rs {this.state.couponDiscount}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          )}
          {this.state.restoDiscount > 0 && (
            <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div
                className="summary-details-left"
                style={{ color: "rgb(79, 147, 236)" }}
              >
                Restaurant Discount
              </div>
            </Grid>
          )}
          {this.state.restoDiscount > 0 && (
            <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}>
              <div
                className="summary-details-right"
                style={{ color: "rgb(79, 147, 236)" }}
              >
                - Rs {this.state.couponDiscAmt}
              </div>
            </Grid>
          )}
          <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <div className="summary-details-left">
              Taxes & Charges
              <div className="tooltip">
                <RiInformationLine className="ddInfoIcon" />
                <span className="tooltiptext">
                  <div>
                    <span>Packing Charges</span>
                    <span style={{ float: "right" }}>
                      Rs. {this.state.packagingCharges}
                    </span>
                  </div>
                  <div>
                    <span>Taxes</span>
                    <span style={{ float: "right" }}>
                      Rs. {this.state.gstAndTaxes}
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0%", paddingBottom: "0px" }}>
            <div className="summary-details-right">
              Rs {this.state.taxesAndCharges}
            </div>
          </Grid>
          {this.state.restoDiscAmt > 0 && (
            <Grid item xs={7} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div
                className="summary-details-left"
                style={{ color: "rgb(248, 0, 165)" }}
              >
                Daidish Discount
              </div>
            </Grid>
          )}
          {this.state.restoDiscAmt > 0 && (
            <Grid item xs={5} style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}>
              <div
                className="summary-details-right"
                style={{ color: "rgb(248, 0, 165)" }}
              >
                - Rs {this.state.restoDiscAmt}
              </div>
            </Grid>
          )}
          <Grid item xs={7} style={{ paddingTop: "10px", fontStyle: "bold" }}>
            <span className="summary-header">GRAND TOTAL</span>
          </Grid>
          <Grid
            item
            xs={5}
            style={{ textAlign: "right", paddingTop: "10px", fontStyle: "bold" }}
          >
            <span className="summary-header">Rs {this.state.grandTotal}</span>
          </Grid>
        </Grid>
      </div>
    );
  }

  // chooseDeliveryMode(){
  //   return(
  //     <Grid container spacing={1} style={{marginBottom: "10px"}}>
  //       <Grid item xs={12} style={{fontFamily:"Poppins-Medium", fontSize:"15px", marginLeft:"20px", marginTop:"30px"}}>
  //         Choose Mode of Delivery
  //       </Grid>
  //       <Grid item xs={12}>
  //         <div className="ddCartModeOfDeliveryBlocks">
  //           <span>Resto Delivery</span>
  //           <span style={{float:"right"}} className="ddCartBtns" onClick={() => this.showAddressCard()}>PROCEED</span>
  //         </div>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <div className="ddCartModeOfDeliveryBlocks">
  //           <span>Self-Pickup</span>
  //           <span style={{float:"right"}} className="ddCartBtns">PROCEED</span>
  //         </div>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  initiatePayment() {
    let url = CONSTANTS.hostURL + "api/main_app/cart/payment/initiate/";
    this.setState({
      paymentInitiated: true,
    });
    var token = localStorage.getItem("auth_token");
    let cartDetails = localStorage.getItem("cartDetailsAPI");

    if (cartDetails !== null && token != null) {
      var headers = {
        Authorization: "Bearer " + token,
      };
      let req = {
        cart_id: JSON.parse(cartDetails).id,
        schema: "new",
      };
      axios
        .post(url, req, {
          headers: headers,
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.status === 1) {
            const paymentSessionId = responseData.data.payment_session_id;
            const cf = new window.Cashfree(paymentSessionId);
            cf.redirect();
            // window.location.assign(responseData.data.payment_link)
          } else {
            if (responseData.errors.code === "STORE_OFFLINE") {
              this.toggleConfirmationBox("718:" + responseData.errors.message);
            }
            this.setState({
              paymentInitiated: false,
            });
          }
        });
    }
  }

  paymentCard() {
    return this.state.isMobileScreen ? (
      <></>
    ) : (
      <>
        <Grid container spacing={1} style={{ marginBottom: "10px" }}>
          {this.state.selectedDeliveryMode !== "PICKUP" && (
            <Grid
              item
              xs={12}
              style={{
                fontFamily: "Poppins-Medium",
                marginLeft: "20px",
                marginTop: "30px",
                fontSize: "16px",
              }}
            >
              <div
                className="ddCartBackBtns"
                style={{ left: "-18px", position: "relative" }}
                onClick={() => this.showAddressCard()}
              >
                <IoMdArrowRoundBack
                  style={{
                    top: "8px",
                    position: "relative",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />
                Back to Delivery Address
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={
              this.state.selectedDeliveryMode !== "PICKUP"
                ? { textAlign: "center", marginLeft: "-10px" }
                : {
                    textAlign: "center",
                    marginLeft: "-10px",
                    marginTop: "25px",
                  }
            }
          >
            <div
              style={{
                fontFamily: "Quicksand-Bold",
                fontSize: "15px",
                padding: "10px 0px",
                color: "#1A1A1A",
              }}
            >
              Securely Pay Via
            </div>
            <img src={CFPayments} alt="CFPayments" className="ddCFPLogo" />
            <div
              style={{
                fontFamily: "Poppins-Regular",
                color: "#83706A",
                fontSize: "10px",
                padding: "5px 60px",
              }}
            >
              After clicking "Complete Order & Pay", you will be redirected to
              Cashfree Payments - UPI, Cards, Wallets, Paypal, Netbanking to
              complete your purchase securely.
            </div>
            <img
              style={{ width: "200px", marginTop: "10px" }}
              src={Paylogos}
              alt="paymentImgs"
            />
            {this.state.paymentInitiated ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <img
                  src={ButtonLoading}
                  alt="Complete Order & Pay"
                  style={{ width: "100px" }}
                />
              </div>
            ) : (
              <span
                className="ddAddCartBtn"
                style={{ marginTop: "40px", width: "80%" }}
                onClick={() => this.initiatePayment()}
              >
                Complete Order & Pay
              </span>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  couponsAndPriceSummary() {
    return (
      <div
        style={
          this.state.isMobileScreen
            ? { padding: "0px 20px" }
            : { width: "95%", paddingLeft: "30px" }
        }
      >
        {/* {this.state.selectedCoupon > 0 ? this.selectedCouponSection() : this.CouponSection()} */}

        {/* {this.state.restoDiscount > 0 && (
          <Grid
            container
            spacing={2}
            className="coupon-details"
            style={
              this.state.isMobileScreen
                ? { padding: "0px 20px 20px 20px", marginTop: "0px" }
                : { padding: "0px 25px 15px 20px", marginTop: "-12px" }
            }
          >
            <Grid item xs={12}>
              <img
                style={{ width: "20px", margin: "10px 5px 0px -3px" }}
                src={DiscountIcon}
                alt="icon"
              />
              <span
                style={{
                  fontFamily: "Poppins-Medium",
                  color: "#3356AA",
                  position: "relative",
                  top: "-5px",
                }}
              >
                Discounts Applied
              </span>
            </Grid>
            {this.CouponSection()}
            {this.RestoDiscount()}
          </Grid>
        )} */}

        {this.OrderSummary()}
        <div
          style={
            this.state.isMobileScreen
              ? { paddingTop: "10px", paddingBottom: "120px" }
              : { paddingTop: "30px" }
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className="ddReviewNote">
                <div className="ddReviewNoteHeader">
                  <BsExclamationTriangle className="ddInfoIcon" />
                  Review your Order and Address details to avoid cancellations
                </div>
                {this.state.readPolicy === false && (
                  <>
                    <div className="ddReviewNoteText">
                      Orders once placed cannot be cancelled. If you cancel your
                      food order after placing the same, 100% cancellation fee
                      will be applicable.
                    </div>
                    <div>
                      <span
                        className="ddSmallLinks"
                        onClick={() => this.setState({ readPolicy: true })}
                      >
                        Read Policy
                      </span>
                    </div>
                  </>
                )}
                {this.state.readPolicy && (
                  <div className="ddReviewNoteText">
                    Orders once placed cannot be cancelled. If you cancel your
                    food order after placing the same, 100% cancellation fee
                    will be applicable.
                    <ul style={{ paddingInlineStart: "15px" }}>
                      {/* <li>If you choose to cancel, you can do it within 60 seconds after placing the order.</li>
                      <li>Post 60 seconds, you will be charged a 100% cancellation fee.</li> */}
                      <li>
                        However, in the event of an unusual delay of your order,
                        you will not be charged a cancellation fee.
                      </li>
                      <li>
                        This policy helps us to avoid food wastage and
                        compensate restaurants/delivery partners for their
                        efforts.
                      </li>
                    </ul>
                  </div>
                )}
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  deliveryStatusScreen() {
    return (
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          paddingLeft: "228px",
          paddingRight: "228px",
        }}
      >
        <div className="ddOrderStatusImages">
          <img
            src={OrderReady}
            style={{ width: "100%", position: "center" }}
            alt="Order Status"
          />
        </div>
        <div className="ddOrderStatusBanner">Order Status</div>
      </Grid>
    );
  }

  checkAddItems() {
    let cartDetails = JSON.parse(localStorage.getItem("cartDetailsAPI"));
    if (cartDetails !== null) {
      return (
        <Grid
          item
          xs={12}
          style={
            this.state.isMobileScreen
              ? { marginTop: "10px", marginLeft: "20px" }
              : { marginTop: "20px", marginLeft: "40px" }
          }
        >
          <span
            onClick={() =>
              (window.location.pathname =
                "/restaurants/" + cartDetails["restaurant"].id.toString())
            }
            style={{
              cursor: "pointer",
              border: "1px solid #5E4B45",
              color: "#2B2624",
              padding: "5px 10px",
              borderRadius: "15px",
              fontSize: "13px",
              fontFamily: "Poppins-Regular",
            }}
          >
            + Add Items
          </span>
        </Grid>
      );
    } else {
      return null;
    }
  }

  getCustomisationText(customizations) {
    var customText = "";
    if (customizations !== undefined) {
      if (customizations.combination !== null) {
        var combinationValues = Object.values(customizations.combination);
        for (var i = 0; i < combinationValues.length; i++) {
          customText = customText + combinationValues[i].name + " : ";
          var modVals = Object.values(combinationValues[i].modifications);
          for (var k = 0; k < modVals.length; k++) {
            customText = customText + modVals[k].name + ", ";
          }
          customText = customText.substring(0, customText.length - 2);
          customText = customText + "\n";
        }
      } else {
        customText = "No Customization\n";
      }
    }
    return customText;
  }

  compareCustomisation(cartCustomisation, selectedDishCustomisationObj) {
    let cartCustomString = this.getCustomisationText(cartCustomisation);
    let selectedCustomString = this.getCustomisationText(
      selectedDishCustomisationObj
    );
    if (cartCustomString === selectedCustomString) return true;
    else return false;
  }

  getCartList() {
    var cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    var cartList = [];
    var sumTotal = 0;
    if (cartDetailsAPI !== null) {
      var itemKey = Object.keys(JSON.parse(cartDetailsAPI).items);
      var itemValue = Object.values(JSON.parse(cartDetailsAPI).items);

      for (let i = 0; i < itemValue.length; i++) {
        for (var j = 0; j < itemValue[i].customizations.length; j++) {
          if (itemValue[i].customizations[j].combination !== null) {
            cartList.push({
              DishID: Number(itemKey[i]),
              DishName: itemValue[i].name,
              DishImage: itemValue[i].image,
              DishCost: itemValue[i].customizations[j].sub_total,
              DishIsVeg: itemValue[i].choice,
              DishQuantity: itemValue[i].customizations[j].quantity,
              Customisation: itemValue[i].customizations[j],
            });
            sumTotal = sumTotal + itemValue[i].customizations[j].sub_total;
          } else {
            cartList.push({
              DishID: Number(itemKey[i]),
              DishName: itemValue[i].name,
              DishImage: itemValue[i].image,
              DishCost: itemValue[i].customizations[j].sub_total,
              DishIsVeg: itemValue[i].choice,
              DishQuantity: itemValue[i].customizations[j].quantity,
              Customisation: {
                combination: null,
                quantity: itemValue[i].customizations[j].quantity,
                sub_total: itemValue[i].customizations[j].sub_total,
              },
            });
            sumTotal = sumTotal + itemValue[i].customizations[j].sub_total;
          }
        }
      }

      let restoDiscount = 0;
      let availableRestoDiscount = 0;
      let restoDiscountAmount = 0;
      let minAmountForDiscount = 0;
      if (JSON.parse(cartDetailsAPI).extra_data.discounts !== undefined) {
        for (
          let i = 0;
          i < JSON.parse(cartDetailsAPI).extra_data.discounts.length;
          i++
        ) {
          if (
            JSON.parse(cartDetailsAPI).extra_data.discounts[i].text ===
            "Restaurant Discount"
          )
            restoDiscount =
              JSON.parse(cartDetailsAPI).extra_data.discounts[i].percent;
          restoDiscountAmount =
            JSON.parse(cartDetailsAPI).extra_data.discounts[i].discount;
        }
      }

      if (JSON.parse(cartDetailsAPI).extra_data.discounts !== undefined) {
        for (
          let i = 0;
          i < JSON.parse(cartDetailsAPI).extra_data.available_discounts.length;
          i++
        ) {
          if (
            JSON.parse(cartDetailsAPI).extra_data.available_discounts[i]
              .text === "Restaurant Discount"
          )
            availableRestoDiscount =
              JSON.parse(cartDetailsAPI).extra_data.available_discounts[i]
                .percent;
          minAmountForDiscount =
            JSON.parse(cartDetailsAPI).extra_data.available_discounts[i]
              .min_order_value;
        }
      }

      let cart = JSON.parse(cartDetailsAPI);

      if (cart?.coupon !== null) {
        this.setState({
          appliedCouponId: cart?.coupon?.id || -1,
          couponDiscount: cart?.extra_data?.coupon?.coupon_discount,
        });
      } else {
        this.setState({
          appliedCouponId: -1,
          couponDiscount: 0,
        });
      }

      this.getEligibleCoupons();

      this.setState({
        cartList: cartList,
        sumTotal: sumTotal,
        restoAddress: JSON.parse(cartDetailsAPI).restaurant.full_address,
        grandTotal: (
          Math.round(JSON.parse(cartDetailsAPI).net_total * 100) / 100
        ).toFixed(2),
        deliveryCharges:
          JSON.parse(cartDetailsAPI).other_charges.delivery_charge,
        taxesAndCharges:
          Math.round(
            JSON.parse(cartDetailsAPI).other_charges.other_charges * 100
          ) / 100,
        packagingCharges:
          JSON.parse(cartDetailsAPI).other_charges.other_charges_breakdown
            .packaging_charges,
        gstAndTaxes:
          JSON.parse(cartDetailsAPI).other_charges.other_charges_breakdown
            .taxes,
        restoDiscount: restoDiscount,
        availableRestoDiscount: availableRestoDiscount,
        minAmountForDiscount: minAmountForDiscount,
        restoDiscountAmount: restoDiscountAmount,
      });
    }
  }

  toggleConfirmationBox(confirmationBoxMessage) {
    if (confirmationBoxMessage === "Clear cart and go home") {
      this.setState(
        {
          showConfirmationBox: !this.state.showConfirmationBox,
        },
        () => {
          window.location.href = "/home";
          let cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
          if (cartDetailsAPI) {
            cartDetailsAPI = JSON.parse(cartDetailsAPI);
            let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
            let req = {
              cart_id: cartDetailsAPI.id,
            };
            var token = localStorage.getItem("auth_token");
            var headers = {
              Authorization: "Bearer " + token,
            };
            axios
              .post(url, req, {
                headers: headers,
              })
              .then((response) => {
                let data = response.data;
                if (data.status === 1) {
                  localStorage.removeItem("CartDetails");
                  localStorage.removeItem("cartDetailsAPI");
                  this.setState({
                    itemsInCart: 0,
                    cartChanged: true,
                  });
                }
              })
              .catch((error) => this.setState({}));
          } else {
            localStorage.removeItem("CartDetails");
            this.setState({
              itemsInCart: 0,
              cartChanged: true,
            });
          }
        }
      );
      return "Yes";
    } else if (confirmationBoxMessage === "Refresh Delivery Type") {
      this.setState(
        {
          showConfirmationBox: !this.state.showConfirmationBox,
        },
        () => {
          this.getUserAddress();
          this.getCoupons();
          this.getCartDetails();
          this.getCartList();
        }
      );
    } else {
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox,
        confirmationBoxMessage: confirmationBoxMessage,
      });
      return "";
    }
  }

  updateCartValues(keyItem, choice) {
    var cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
    if (cartDetailsAPI !== null) {
      this.getCartList();
      // var obj = JSON.parse(cartDetailsAPI);
      let itemToUpdate = {};
      // let counter = 0;
      // let cartList = [];
      // let sumTotal = 0;

      let cartItems = JSON.parse(cartDetailsAPI)["items"];
      var itemID = Object.keys(cartItems);
      var itemVals = Object.values(cartItems);

      if (keyItem !== undefined) {
        for (var i = 0; i < itemID.length; i++) {
          if (Number(itemID[i]) === Number(keyItem.DishID)) {
            if (keyItem.Customisation.combination !== null) {
              for (var j = 0; j < itemVals[i].customizations.length; j++) {
                if (
                  this.compareCustomisation(
                    itemVals[i].customizations[j],
                    keyItem.Customisation
                  )
                ) {
                  itemToUpdate = itemVals[i].customizations[j];
                }
              }
            } else {
              itemToUpdate = {
                combination: null,
                quantity: keyItem.DishQuantity,
                sub_total: keyItem.DishCost,
              };
            }
          }
        }
      }

      cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
      if (cartDetailsAPI) {
        cartDetailsAPI = JSON.parse(cartDetailsAPI);
        if (choice === "Remove") {
          var url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
          let req = {};
          if (itemToUpdate.combination === null) {
            req = {
              cart_id: cartDetailsAPI.id,
              dish_id: keyItem.DishID,
              quantity: 1,
            };
          } else {
            req = {
              cart_id: cartDetailsAPI.id,
              dish_id: keyItem.DishID,
              quantity: 1,
              combination: itemToUpdate.combination,
            };
          }

          var token = localStorage.getItem("auth_token");
          var headers = {
            Authorization: "Bearer " + token,
          };
          axios
            .post(url, req, {
              headers: headers,
            })
            .then((response) => {
              let data = response.data;
              if (data.status === 1) {
                localStorage.setItem(
                  "cartDetailsAPI",
                  JSON.stringify(data.data)
                );
                this.getCartList();
                //this.props.updateCartChangedValue(true);
                this.getCoupons();
                cartDetailsAPI = localStorage.getItem("cartDetailsAPI");
                var cartDetails = JSON.parse(cartDetailsAPI);
                if (cartDetailsAPI !== null) {
                  var itemKey = Object.keys(cartDetails.items);
                  if (itemKey.length === 0) {
                    let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
                    let req = {
                      cart_id: cartDetails.id,
                    };
                    var token = localStorage.getItem("auth_token");
                    var headers = {
                      Authorization: "Bearer " + token,
                    };
                    axios
                      .post(url, req, {
                        headers: headers,
                      })
                      .then((response) => {
                        let data = response.data;
                        if (data.status === 1) {
                          localStorage.removeItem("cartDetailsAPI");
                          this.getCartList();
                          this.getCoupons();
                          this.setState({
                            itemsInCart: 0,
                          });
                        }
                      });
                  }
                }
              }
            });
        } else if (choice === "Add") {
          url = CONSTANTS.hostURL + "api/main_app/cart/add/";
          let req = {};
          if (itemToUpdate.combination === null) {
            req = {
              cart_id: cartDetailsAPI.id,
              dish_id: keyItem.DishID,
              quantity: 1,
            };
          } else {
            req = {
              cart_id: cartDetailsAPI.id,
              dish_id: keyItem.DishID,
              quantity: 1,
              combination: itemToUpdate.combination,
            };
          }
          token = localStorage.getItem("auth_token");
          headers = {
            Authorization: "Bearer " + token,
          };
          axios
            .post(url, req, {
              headers: headers,
            })
            .then((response) => {
              let data = response.data;
              if (data.status === 1) {
                localStorage.setItem(
                  "cartDetailsAPI",
                  JSON.stringify(data.data)
                );
                //this.props.updateCartChangedValue(true);
                this.getCartList();
                this.getCoupons();
              }
            });
        }
      }
    } else {
      this.setState({
        restoName: "",
        cartList: [],
        sumTotal: 0,
        restoDiscAmt: 0,
        couponDiscAmt: 0,
        grandTotal: 0,
        //cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
      });
    }
    //this.props.updateCartChangedValue(true);
  }

  AddedItems() {
    const listItems = this.state.cartList.map((keyItem) => (
      <Grid
        key={"CartItem_" + this.state.cartList.indexOf(keyItem)}
        item
        xs={12}
      >
        <div className="cart-details">
          <div style={{ paddingBottom: "15px" }} className="cart-item-details">
            <div
              style={{ position: "relative", padding: "4px 0px 3px 0px" }}
              className="cart-Item-Controls"
            >
              <RemoveIcon
                style={{ fontSize: 13 }}
                className="remove-quantity"
                onClick={() => this.updateCartValues(keyItem, "Remove")}
              />
              <span className="item-Qty">{keyItem.DishQuantity}</span>
              <AddIcon
                style={{ fontSize: 13 }}
                className="add-quantity"
                onClick={() => this.updateCartValues(keyItem, "Add")}
              />
            </div>
            <div className="cart-item-names">
              <div>{keyItem.DishName}</div>
              {keyItem.Customisation.combination !== null && (
                <div className="ddCustomisationText">
                  {this.getCustomisationText(keyItem.Customisation)}
                </div>
              )}
              {/* <div style={{fontFamily: "Poppins-Regular"}}>Rs. {keyItem.DishCost}</div> */}
              {this.state.restoDiscount > 0 ? (
                <>
                  <span>
                    {"Rs. " +
                      (
                        keyItem.DishCost -
                        (keyItem.DishCost * this.state.restoDiscount) / 100
                      ).toFixed(2)}
                  </span>
                  <span
                    style={{
                      color: "#83706A",
                      fontStyle: "Poppins-Medium",
                      textDecorationLine: "line-through",
                      fontSize: "11px",
                    }}
                  >
                    {" Rs. " + keyItem.DishCost}
                  </span>
                </>
              ) : (
                <span>{"Rs. " + keyItem.DishCost}</span>
              )}
            </div>
            {keyItem.DishImage !== null && keyItem.DishImage !== "" && (
              <img
                className="cart-food-image"
                src={keyItem.DishImage}
                alt={keyItem.DishName}
              />
            )}
          </div>
        </div>
      </Grid>
    ));
    return (
      <div
        style={
          this.state.isMobileScreen
            ? { padding: "15px 15px" }
            : { padding: "10px 30px" }
        }
      >
        {listItems}
      </div>
    );
  }

  setAsPickup() {
    var req = {
      mode: "SELF_PICKUP",
      cart_id: JSON.parse(localStorage.getItem("cartDetailsAPI"))["id"],
    };
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== "") {
      var headers = {
        Authorization: "Bearer " + token,
      };
      var url = CONSTANTS.hostURL + "api/main_app/cart/update-delivery-mode/";
      axios
        .post(url, req, {
          headers: headers,
        })
        .then((response) => {
          let data = response.data;
          if (data.status === 1) {
            localStorage.setItem("cartDetailsAPI", JSON.stringify(data.data));
            this.getCartList();
          } else {
            this.toggleConfirmationBox("1258:" + data.errors.message);
          }
        });
    } else {
      this.showAuthPopupModal("login");
    }
  }

  getSelectedAddrMob(addressID) {
    this.setState({
      selectedAddr: addressID,
    });
  }

  updateDeliveryMode(addressID) {
    var req = {
      mode: "DELIVERY",
      cart_id: JSON.parse(localStorage.getItem("cartDetailsAPI"))["id"],
      address_id: addressID,
    };
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== "") {
      var headers = {
        Authorization: "Bearer " + token,
      };
      var url = CONSTANTS.hostURL + "api/main_app/cart/update-delivery-mode/";
      axios
        .post(url, req, {
          headers: headers,
        })
        .then((response) => {
          let data = response.data;
          if (data.status === 1) {
            localStorage.setItem("cartDetailsAPI", JSON.stringify(data.data));
            this.getUserAddress();
            this.showPaymentCard();
            this.getCartList();
          } else {
            this.toggleConfirmationBox("1258:" + data.errors.message);
          }
        });
    }
  }

  validateCoupon() {
    return (
      <div
        style={{
          border: "#FFDEE4 1px solid",
          background: "#FFF4F6",
          borderRadius: "12px",
          padding: "10px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
            }}
            className={
              this.state.minAmountForDiscount <= this.state.sumTotal
                ? "LikeButtonActive"
                : ""
            }
          >
            <img
              style={{
                width: "50px",
                filter:
                  this.state.minAmountForDiscount <= this.state.sumTotal
                    ? "grayscale(0%)"
                    : "grayscale(100%)",
              }}
              src={DiscountImage}
              alt={"DiscountImage"}
            />
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "15px",
            }}
          >
            <div
              style={{
                color: "#EA2886",
                font: "Quicksand-SemiBold",
                fontSize: "14px",
              }}
            >
              {this.state.restoDiscount > 0
                ? this.state.restoDiscount
                : this.state.availableRestoDiscount}
              % OFF
            </div>
            {this.state.minAmountForDiscount <= this.state.sumTotal ? (
              <div
                style={{
                  font: "Poppins-Medium",
                  fontSize: "11px",
                  color: "#5E4B45",
                  textTransform: "none",
                }}
              >
                Congratulations! Discount has been applied.
              </div>
            ) : (
              <div
                style={{
                  font: "Poppins-Medium",
                  fontSize: "11px",
                  color: "#5E4B45",
                  textTransform: "none",
                }}
              >
                Add item(s) worth{" "}
                <span style={{ color: "#EB2D85" }}>
                  Rs. {this.state.minAmountForDiscount - this.state.sumTotal}{" "}
                  more{" "}
                </span>
                to avail the discount.
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  switchToPickup() {
    this.setState({
      selectedDeliveryMode: "PICKUP",
      paymentCardVisibility: true,
      addressCardVisibility: false,
    });
    this.setAsPickup();
  }

  render() {
    return (
      <div>
        <div
          className="loadingGif"
          style={
            this.state.itemsInCart === null && !this.state.dataFetched
              ? { visibility: "visible", opacity: "1" }
              : { visibility: "hidden", opacity: "0" }
          }
        >
          <img
            src={DaiDishLoading1}
            alt="DaiDish Loading..."
            style={
              this.state.isMobileScreen
                ? { width: "100%", marginTop: "25%" }
                : { width: "40%", marginTop: "0%" }
            }
          />
        </div>
        <Header
          toggleUserOptions={this.toggleUserOptions}
          toggleSearchModal={this.toggleSearchModal}
          showAuthPopupModal={this.showAuthPopupModal}
          searchPresent={true}
        />
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />

        {this.state.itemsInCart > 0 ? (
          this.state.isMobileScreen ? (
            <Grid
              container
              spacing={0}
              style={{ paddingTop: "55px", width: "100%" }}
            >
              <Grid item xs={12} style={{ padding: "0px 20px 0px 20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div
                      className="ddCartBackBtns"
                      onClick={() =>
                        (window.location.pathname =
                          "/restaurants/" + this.state.restaurant_id)
                      }
                    >
                      <ArrowBackIcon style={{ fontSize: "16px" }} />
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "22px",
                        margin: "10px 0px",
                        letterSpacing: '0.01rem'
                      }}
                    >
                      {this.state.restaurant_name}
                    </div>
                    {this.state.selectedDeliveryMode !== "PICKUP" ? (
                      <>
                        {this.state.defaultAddresses && (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "12px",
                              borderBottom: "1px solid #E8E8E8",
                              paddingBottom: "15px",
                            }}
                          >
                            <PlaceOutlinedIcon
                              style={{
                                fontSize: "12px",
                                color: "#5E4B45",
                                marginRight: "5px",
                                transform: "translateY(2px)",
                              }}
                            />
                            Deliver to{" "}
                            <span style={{ color: "#EA5B2F" }}>
                              {this.state.defaultAddresses}
                            </span>
                          </div>
                        )}
                        {!this.state.addressCardVisibility && (
                          <div
                            style={{
                              float: "right",
                              marginTop: "10px",
                              fontFamily: "Poppins-SemiBold",
                              color: "#EA5B2F",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => this.showAddressCard()}
                          >
                            CHANGE ADDRESS
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "12px",
                        }}
                      >
                        Restaurant Address{" "}
                        <span
                          style={{
                            color: "#EA5B2F",
                            fontFamily: "Poppins-SemiBold",
                          }}
                        >
                          <br />
                          {this.state.restoAddress}
                        </span>
                      </div>
                    )}
                  </Grid>
                  {this.state.amountPaidIndicator === false ? (
                    <>
                      <Grid item xs={12}>
                        <span style={{ textAlign: "left" }}>
                          {this.state.is_accepting_orders &&
                          this.state.is_delivery_available ? (
                            // <><span className={this.state.selectedDeliveryMode === "RESTO" ? "ddSelectedDeliveryMode": "ddDeliveryMode"} onClick={() => this.setState({selectedDeliveryMode : "RESTO", paymentCardVisibility: false, addressCardVisibility: true})}>Delivery</span>
                            // <span className={this.state.selectedDeliveryMode === "PICKUP" ? "ddSelectedDeliveryMode": "ddDeliveryMode"} onClick={() => {this.setState({selectedDeliveryMode : "PICKUP", paymentCardVisibility: true, addressCardVisibility: false}); this.setAsPickup();}}>Pickup</span></>
                            <></>
                          ) : this.state.is_accepting_orders &&
                            !this.state.is_delivery_available ? (
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "12px",
                                color: "#EA5B2F",
                              }}
                            >
                              * {this.state.restaurant_name} is currently not
                              accepting Orders for Delivery. Please collect your
                              order from the restaurant.
                            </div>
                          ) : (
                            <></>
                          )}

                          {this.state.addressCardVisibility && (
                            <>
                              <DeliveryAddressCard
                                addresses={this.state.addresses}
                                getUserAddress={this.getUserAddress}
                                updateCartChangedValue={
                                  this.updateCartChangedValue
                                }
                                editAddressItem={this.editAddressItem}
                                showDeliveryModeCard={this.showDeliveryModeCard}
                                showPaymentCard={this.showPaymentCard}
                                getCartList={this.getCartList}
                                getSelectedAddrMob={this.getSelectedAddrMob}
                                selectedAddr={this.state.selectedAddr}
                                updateDeliveryMode={this.updateDeliveryMode}
                                confirmationBoxMessage={
                                  this.state.confirmationBoxMessage
                                }
                                showConfirmationBox={
                                  this.state.showConfirmationBox
                                }
                                toggleConfirmationBox={
                                  this.toggleConfirmationBox
                                }
                                switchToPickup={this.switchToPickup}
                                setDataFetched={() =>
                                  this.setState({ dataFetched: true })
                                }
                              />
                            </>
                          )}
                          {this.state.paymentCardVisibility &&
                            this.paymentCard()}

                          {!this.state.addressCardVisibility &&
                            !this.state.paymentCardVisibility && (
                              <span>Restaurant Closed</span>
                            )}
                        </span>
                      </Grid>
                    </>
                  ) : (
                    <>{this.deliveryStatusScreen()}</>
                  )}
                </Grid>
              </Grid>
              {this.state.addressCardVisibility && (
                <Grid
                  item
                  xs={12}
                  style={
                    this.state.isMobileScreen
                      ? { position: "fixed", width: "100%", bottom: "-1px" }
                      : {}
                  }
                >
                  <span
                    className="ddCartSubTotalDiv"
                    style={this.state.isMobileScreen ? { height: "100%" } : {}}
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Poppins-Bold",
                          position: "relative",
                          top: "7px",
                        }}
                      >
                        SUB TOTAL
                      </span>
                      <span
                        style={{
                          float: "right",
                          fontFamily: "Poppins-Bold",
                          color: "#EA5B2F",
                          position: "relative",
                          top: "7px",
                        }}
                      >
                        Rs. {this.state.grandTotal}
                      </span>
                    </div>
                    {this.state.isMobileScreen &&
                    this.state.selectedAddr !== 0 ? (
                      <div
                        className="ddAddCartBtn"
                        style={{
                          marginTop: "20px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                        onClick={() =>
                          this.updateDeliveryMode(this.state.selectedAddr)
                        }
                      >
                        DELIVER HERE
                      </div>
                    ) : (
                      <div
                        className="ddAddCartBtnDisabled"
                        style={{
                          marginTop: "20px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        DELIVER HERE
                      </div>
                    )}
                  </span>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={
                  this.state.isMobileScreen ? {} : { background: "#F6F6F6" }
                }
              >
                <Grid container spacing={this.state.isMobileScreen ? 0 : 2}>
                  {this.state.paymentCardVisibility && (
                    <>
                      <Grid item xs={12}>
                        {this.state.selectedDeliveryMode === "PICKUP" && (
                          <div
                            style={{
                              fontFamily: "Poppins-Regular",
                              fontSize: "14px",
                              padding: "0px 20px",
                              marginBottom: "10px",
                            }}
                          >
                            Switch to{" "}
                            <span
                              onClick={() =>
                                this.setState({
                                  selectedDeliveryMode: "RESTO",
                                  paymentCardVisibility: false,
                                  addressCardVisibility: true,
                                })
                              }
                              style={{
                                color: "#3356aa",
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              }}
                            >
                              Delivery by Daidish
                            </span>
                          </div>
                        )}
                        <div
                          className="ddHeaderForCart"
                          style={
                            this.state.isMobileScreen
                              ? { marginLeft: "20px", fontSize: "20px" }
                              : { marginTop: "30px", marginLeft: "40px" }
                          }
                        >
                          {this.state.isMobileScreen
                            ? "Cart Items"
                            : "Order Summary"}
                        </div>

                        {/* {this.state.selectedDeliveryMode !== "PICKUP" ? 
                          <div className="ddSubHeaderForCart" style={{marginLeft:"40px"}}>Deliver to <span style={{color: "#EA5B2F", fontFamily: "Poppins-SemiBold"}}>{this.state.defaultAddresses}</span></div>
                          :
                          <div className="ddSubHeaderForCart" style={{marginLeft:"40px"}}>Restaurant Address <span style={{color: "#EA5B2F", fontFamily: "Poppins-SemiBold"}}><br/>{this.state.restoAddress}</span></div>} */}
                        <div
                          style={
                            this.state.isMobileScreen
                              ? {}
                              : {
                                  height: this.state.paymentCardVisibility
                                    ? window.innerHeight - 277
                                    : window.innerHeight - 255,
                                  overflowY: "scroll",
                                }
                          }
                          className="ddOrderDivScroll"
                        >
                          {this.checkAddItems()}
                          {/* {(this.state.restoDiscount > 0 || this.state.availableRestoDiscount > 0) && this.validateCoupon()} */}
                          {this.AddedItems()}
                          {this.state.eligibleCoupons.map((coupon, index) => {
                            if (
                              this.state.appliedCouponId === coupon.id ||
                              this.state.appliedCouponId === -1
                            ) {
                              return (
                                <div
                                  className={
                                    coupon.id === this.state.appliedCouponId
                                      ? "CouponAppliedCard"
                                      : "CouponCard"
                                  }
                                >
                                  {coupon.id === this.state.appliedCouponId ? (
                                    <div className="CouponAppliedIconContainer">
                                      <img
                                        className="CouponAppliedIcon"
                                        src={CouponAppliedIcon}
                                        alt="Coupon Icon"
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="CouponIcon"
                                      src={CouponIcon}
                                      alt="Coupon Icon"
                                    />
                                  )}
                                  <div className="CouponInfo">
                                    {coupon.id ===
                                    this.state.appliedCouponId ? (
                                      <div className="CouponAppliedTitleContainer">
                                        <div
                                          style={{ marginLeft: "15px" }}
                                          className="CouponAppliedTitle"
                                        >
                                          '{coupon.coupon_code}' APPLIED!
                                        </div>
                                      </div>
                                    ) : null}
                                    {coupon.id ===
                                    this.state.appliedCouponId ? (
                                      <div
                                        style={{ marginLeft: "15px" }}
                                        className="CouponAppliedText"
                                      >
                                        <span className="CouponBold">
                                          Rs. {this.state.couponDiscount} Saved
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          coupon.errorMessage
                                            ? "CouponErrorContainer"
                                            : "CouponInput"
                                        }
                                      >
                                        <div className="CouponText">
                                          {coupon.coupon_code}
                                        </div>
                                        <div
                                          onClick={() =>
                                            coupon.additional_item_cost === 0 &&
                                            this.applyEligibileCoupon(
                                              coupon.id,
                                              index
                                            )
                                          }
                                          className="CouponApply"
                                        >
                                          Apply
                                        </div>
                                      </div>
                                    )}
                                    {coupon.errorMessage && (
                                      <div className="CouponError">
                                        {coupon.errorMessage}
                                      </div>
                                    )}
                                    {coupon.id !== this.state.appliedCouponId &&
                                      (coupon.is_applicable ? (
                                        <div
                                          style={{ color: "#6BA454" }}
                                          className="CouponError"
                                        >
                                          {`Apply this coupon to save Rs. ${coupon.savings}`}
                                        </div>
                                      ) : (
                                        <div
                                          style={{ color: "#507CE7" }}
                                          className="CouponError"
                                        >
                                          {`Add items worth Rs ${coupon.additional_item_cost} to avail this coupon`}
                                        </div>
                                      ))}
                                  </div>
                                  {coupon.id === this.state.appliedCouponId && (
                                    <div
                                      onClick={() =>
                                        this.removeEligibileCoupon(
                                          coupon.id,
                                          index
                                        )
                                      }
                                      className="CouponRemove"
                                    >
                                      Remove
                                    </div>
                                  )}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}

                          {this.couponsAndPriceSummary()}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={
                          this.state.isMobileScreen
                            ? {
                                position: "fixed",
                                width: "100%",
                                bottom: "-1px",
                              }
                            : { position: "relative" }
                        }
                      >
                        {this.state.paymentCardVisibility ? (
                          <span
                            className="ddCartSubTotalDiv"
                            style={
                              this.state.isMobileScreen
                                ? { height: "100%" }
                                : {}
                            }
                          >
                            <div>
                              <span
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  position: "relative",
                                }}
                              >
                                GRAND TOTAL
                              </span>
                              <span
                                style={{
                                  float: "right",
                                  fontFamily: "Poppins-Bold",
                                  color: "#EA5B2F",
                                  position: "relative",
                                }}
                              >
                                Rs. {this.state.grandTotal}
                              </span>
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "10px",
                                position: "absolute",
                              }}
                            >
                              Delivery time{" "}
                              <span style={{ color: "#EA5B2F" }}>
                                40-50 mins
                              </span>
                            </div>
                            {this.state.isMobileScreen &&
                            this.state.paymentInitiated ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={ButtonLoading}
                                  alt="Complete Order & Pay"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "27px",
                                    width: "100px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="ddAddCartBtn"
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                                onClick={() => this.initiatePayment()}
                              >
                                Complete Order & Pay
                              </div>
                            )}
                          </span>
                        ) : (
                          <span className="ddCartSubTotalDiv">
                            <div>
                              <span style={{ fontFamily: "Poppins-Bold" }}>
                                SUBTOTAL
                              </span>
                              <span
                                style={{
                                  float: "right",
                                  fontFamily: "Poppins-Bold",
                                  color: "#EA5B2F",
                                }}
                              >
                                Rs.{" "}
                                {this.state.restoDiscount > 0 &&
                                this.state.minAmountForDiscount <=
                                  this.state.sumTotal
                                  ? (
                                      this.state.sumTotal -
                                      (this.state.sumTotal *
                                        this.state.restoDiscount) /
                                        100
                                    ).toFixed(2)
                                  : this.state.sumTotal}
                              </span>
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "10px",
                              }}
                            >
                              Extra Charges may apply
                            </div>
                          </span>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              style={{ paddingTop: "75px", width: "100%" }}
            >
              <Grid
                item
                xs={7}
                style={{ padding: "0px 150px", marginTop: "20px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <div className="ddCartBackBtns" onClick={() => window.location.pathname = "/restaurants/" + this.state.restaurant_id}><IoMdArrowRoundBack style={{top:"2px", position:"relative", marginRight:"5px", fontSize: "20px"}}/></div> */}
                    <div
                      style={{
                        fontFamily: "Quicksand-Regular",
                        fontSize: "25px",
                      }}
                    >
                      <IoMdArrowRoundBack
                        onClick={() =>
                          (window.location.pathname =
                            "/restaurants/" + this.state.restaurant_id)
                        }
                        style={{
                          top: "2px",
                          position: "relative",
                          marginRight: "5px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                      {this.state.restaurant_name}
                    </div>
                  </Grid>
                  {this.state.amountPaidIndicator === false ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "center",
                          paddingTop: "20px",
                          position: "relative",
                        }}
                      >
                        <span
                          className={
                            this.state.addressCardVisibility
                              ? "ddSelectedIcon"
                              : "ddNotSelectedIcon"
                          }
                        >
                          <MdLocationPin />
                        </span>
                        <span
                          className="ddCartSectionText"
                          style={{
                            position: "relative",
                            top: "-4px",
                            left: "10px",
                          }}
                        >
                          Delivery Address
                        </span>
                        <span
                          className="ddCartSectionText"
                          style={{
                            position: "relative",
                            top: "-2px",
                            padding: "0px 10px 0px 15px",
                          }}
                        >
                          -----------------------------
                        </span>
                        <span
                          className={
                            this.state.paymentCardVisibility
                              ? "ddSelectedIcon"
                              : "ddNotSelectedIcon"
                          }
                        >
                          <RiWallet2Line />
                        </span>
                        <span
                          className="ddCartSectionText"
                          style={{
                            position: "relative",
                            top: "-4px",
                            left: "10px",
                          }}
                        >
                          Payment
                        </span>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <span
                          className="ddCartItemsBlock"
                          style={{
                            textAlign: "left",
                            padding: "20px 0px 20px 20px",
                          }}
                        >
                          {this.state.is_accepting_orders &&
                          this.state.is_delivery_available ? (
                            <>
                              <span
                                className={
                                  this.state.selectedDeliveryMode === "RESTO"
                                    ? "ddSelectedDeliveryMode"
                                    : "ddDeliveryMode"
                                }
                                onClick={() =>
                                  this.setState({
                                    selectedDeliveryMode: "RESTO",
                                    paymentCardVisibility: false,
                                    addressCardVisibility: true,
                                  })
                                }
                              >
                                Delivery
                              </span>
                              <span
                                className={
                                  this.state.selectedDeliveryMode === "PICKUP"
                                    ? "ddSelectedDeliveryMode"
                                    : "ddDeliveryMode"
                                }
                                onClick={() => {
                                  this.setState({
                                    selectedDeliveryMode: "PICKUP",
                                    paymentCardVisibility: true,
                                    addressCardVisibility: false,
                                  });
                                  this.setAsPickup();
                                }}
                              >
                                Pickup
                              </span>
                            </>
                          ) : this.state.is_accepting_orders &&
                            !this.state.is_delivery_available ? (
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "12px",
                                color: "#EA5B2F",
                                marginLeft: "30px",
                                marginRight: "50px",
                                textAlign: "center",
                              }}
                            >
                              * {this.state.restaurant_name} is currently not
                              accepting Orders for Delivery. Please collect your
                              order from the restaurant.
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* {this.state.deliveryModeVisibility && this.chooseDeliveryMode()} */}
                          {this.state.addressCardVisibility && (
                            <DeliveryAddressCard
                              addresses={this.state.addresses}
                              getUserAddress={this.getUserAddress}
                              updateCartChangedValue={
                                this.updateCartChangedValue
                              }
                              editAddressItem={this.editAddressItem}
                              showDeliveryModeCard={this.showDeliveryModeCard}
                              showPaymentCard={this.showPaymentCard}
                              getCartList={this.getCartList}
                              confirmationBoxMessage={
                                this.state.confirmationBoxMessage
                              }
                              showConfirmationBox={
                                this.state.showConfirmationBox
                              }
                              toggleConfirmationBox={this.toggleConfirmationBox}
                              setDataFetched={() =>
                                this.setState({ dataFetched: true })
                              }
                            />
                          )}
                          {this.state.paymentCardVisibility &&
                            this.paymentCard()}

                          {!this.state.addressCardVisibility &&
                            !this.state.paymentCardVisibility && (
                              <span>Restaurant Closed</span>
                            )}
                        </span>
                      </Grid>
                    </>
                  ) : (
                    <>{this.deliveryStatusScreen()}</>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                style={{ background: "#F6F6F6", marginLeft: "-8px" }}
              >
                <Grid container spacing={2}>
                  {this.state.amountPaidIndicator === false ? (
                    <>
                      <Grid item xs={12}>
                        <div
                          className="ddHeaderForCart"
                          style={{ marginTop: "30px", marginLeft: "40px" }}
                        >
                          Order Summary
                        </div>

                        {this.state.selectedDeliveryMode !== "PICKUP" ? (
                          this.state.defaultAddresses ? (
                            <div
                              className="ddSubHeaderForCart"
                              style={{ marginLeft: "40px" }}
                            >
                              Deliver to{" "}
                              <span style={{ color: "#EA5B2F" }}>
                                {this.state.defaultAddresses}
                              </span>
                            </div>
                          ) : null
                        ) : (
                          <div
                            className="ddSubHeaderForCart"
                            style={{ marginLeft: "40px" }}
                          >
                            Restaurant Address{" "}
                            <span
                              style={{
                                color: "#EA5B2F",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              <br />
                              {this.state.restoAddress}
                            </span>
                          </div>
                        )}
                        <div
                          style={{
                            height: this.state.paymentCardVisibility
                              ? window.innerHeight - 277
                              : window.innerHeight - 255,
                            overflowY: "scroll",
                          }}
                          className="ddOrderDivScroll"
                        >
                          {this.checkAddItems()}
                          {/* {(this.state.restoDiscount > 0 || this.state.availableRestoDiscount > 0) && <div style={{padding:"10px 40px"}}>{this.validateCoupon()}</div>} */}
                          {this.AddedItems()}
                          {this.state.eligibleCoupons.map((coupon, index) => {
                            if (
                              this.state.appliedCouponId === coupon.id ||
                              this.state.appliedCouponId === -1
                            ) {
                              return (
                                <div
                                  className={
                                    coupon.id === this.state.appliedCouponId
                                      ? "CouponAppliedCard"
                                      : "CouponCard"
                                  }
                                >
                                  {coupon.id === this.state.appliedCouponId ? (
                                    <div className="CouponAppliedIconContainer">
                                      <img
                                        className="CouponAppliedIcon"
                                        src={CouponAppliedIcon}
                                        alt="Coupon Icon"
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="CouponIcon"
                                      src={CouponIcon}
                                      alt="Coupon Icon"
                                    />
                                  )}
                                  <div className="CouponInfo">
                                    {coupon.id ===
                                    this.state.appliedCouponId ? (
                                      <div className="CouponAppliedTitleContainer">
                                        <div className="CouponAppliedTitle">
                                          '{coupon.coupon_code}' APPLIED!
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.removeEligibileCoupon(
                                              coupon.id,
                                              index
                                            )
                                          }
                                          className="CouponRemove"
                                        >
                                          Remove
                                        </div>
                                      </div>
                                    ) : null}
                                    {coupon.id ===
                                    this.state.appliedCouponId ? (
                                      <div className="CouponAppliedText">
                                        <span className="CouponBold">
                                          Rs. {this.state.couponDiscount} Saved
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          coupon.errorMessage
                                            ? "CouponErrorContainer"
                                            : "CouponInput"
                                        }
                                      >
                                        <div className="CouponText">
                                          {coupon.coupon_code}
                                        </div>
                                        <div
                                          onClick={() =>
                                            coupon.additional_item_cost === 0 &&
                                            this.applyEligibileCoupon(
                                              coupon.id,
                                              index
                                            )
                                          }
                                          className="CouponApply"
                                        >
                                          Apply
                                        </div>
                                      </div>
                                    )}
                                    {coupon.errorMessage && (
                                      <div className="CouponError">
                                        {coupon.errorMessage}
                                      </div>
                                    )}
                                    {coupon.id !== this.state.appliedCouponId &&
                                      (coupon.is_applicable ? (
                                        <div
                                          style={{ color: "#6BA454" }}
                                          className="CouponError"
                                        >
                                          {`Apply this coupon to get a discount of Rs. ${coupon.savings}`}
                                        </div>
                                      ) : (
                                        <div
                                          style={{ color: "#507CE7" }}
                                          className="CouponError"
                                        >
                                          {`Add items worth Rs ${coupon.additional_item_cost} to avail this coupon`}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}

                          {this.state.paymentCardVisibility &&
                            this.couponsAndPriceSummary()}
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ position: "relative" }}>
                        {this.state.paymentCardVisibility ? (
                          <span className="ddCartSubTotalDiv">
                            <div>
                              <span
                                style={{
                                  fontFamily: "Poppins-Bold",
                                  position: "relative",
                                }}
                              >
                                GRAND TOTAL
                              </span>
                              <span
                                style={{
                                  float: "right",
                                  fontFamily: "Poppins-Bold",
                                  color: "#EA5B2F",
                                  position: "relative",
                                }}
                              >
                                Rs. {this.state.grandTotal}
                              </span>
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "10px",
                              }}
                            >
                              Delivery time{" "}
                              <span style={{ color: "#EA5B2F" }}>
                                40-50 mins
                              </span>
                            </div>
                          </span>
                        ) : (
                          <span className="ddCartSubTotalDiv">
                            <div>
                              <span style={{ fontFamily: "Poppins-Bold" }}>
                                SUBTOTAL
                              </span>
                              <span
                                style={{
                                  float: "right",
                                  fontFamily: "Poppins-Bold",
                                  color: "#EA5B2F",
                                }}
                              >
                                Rs.{" "}
                                {this.state.restoDiscount > 0 &&
                                this.state.minAmountForDiscount <=
                                  this.state.sumTotal
                                  ? (
                                      this.state.sumTotal -
                                      (this.state.sumTotal *
                                        this.state.restoDiscount) /
                                        100
                                    ).toFixed(2)
                                  : this.state.sumTotal}
                              </span>
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins-Medium",
                                fontSize: "12px",
                              }}
                            >
                              Select address to view the taxes & discounts
                            </div>
                          </span>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontFamily: "Bree-Regular",
                            marginTop: "30px",
                            fontSize: "20px",
                            marginLeft: "40px",
                          }}
                        >
                          Order Summary
                        </div>
                        <div style={{ marginLeft: "40px" }}>Deliver To</div>
                        <div className="ddCartItemsBlock2">
                          <OrderCard />
                        </div>
                        {this.state.paymentCardVisibility &&
                          this.couponsAndPriceSummary()}
                      </Grid>
                      <Grid item xs={12} style={{ position: "relative" }}>
                        <span className="ddCartSubTotalDiv">
                          <div>
                            <span style={{ fontFamily: "Poppins-Bold" }}>
                              GRAND TOTAL
                            </span>
                            <span
                              style={{
                                float: "right",
                                fontFamily: "Poppins-Bold",
                                color: "#EA5B2F",
                              }}
                            >
                              Rs. {this.state.grandTotal}
                            </span>
                          </div>
                        </span>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <div
            className="ddNoItemsInCart"
            style={{
              width: "40%",
              margin: "100px 0px 30px 30%",
              position: "relative",
            }}
          >
            <img
              className="ddNoItemsInCartImage"
              src={NoItemsInCart}
              alt="No Items in Cart"
            />
            <div style={{ fontFamily: "Quicksand-SemiBold" }}>
              Your Cart Is Empty!
            </div>
            {/* <div style={{fontFamily:"Poppins-Medium", fontSize:"12px", paddingTop:"10px"}}>Before you Checkout, you must add<br/>items in your cart.</div> */}
            <div
              className="ddPopOverAddCartBtn"
              style={{
                marginLeft: this.state.isMobileScreen ? "-15px" : "0px",
              }}
              onClick={() => (window.location.pathname = "/home/")}
            >
              Goto Homepage
            </div>
          </div>
        )}
        <AuthPopup
          showAuthPopupModal={this.state.authPopupModal}
          closeAuthPopupModal={this.closeAuthPopupModal}
          setActiveForm={this.setActiveForm}
          activeForm={this.state.activeForm}
        />
        <ConfirmationBox
          confirmationBoxMessage={this.state.confirmationBoxMessage}
          showConfirmationBox={this.state.showConfirmationBox}
          toggleConfirmationBox={this.toggleConfirmationBox}
        />
        <LogoutConfirm
          showLogoutModal={this.state.logoutModal}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        {!this.state.isMobileScreen && <Footer />}
      </div>
    );
  }
}

export default withRouter(Cart);
