import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import { Divider, Snackbar, SnackbarContent } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { RiShoppingCart2Line } from "react-icons/ri";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AiOutlineCloseCircle } from "react-icons/ai";
import ButtonLoading from '../../assets/images/btnLoading.gif';

class CustomisationOptions extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.readCustomisationUpdates = this.readCustomisationUpdates.bind(this);
        this.state = {
            dishDetails : this.props.dishDetails,
            updatedPrice : this.props.dishDetails.cost,
            selectedCustomisation: "",
            dishCost: 0,
            dishCustomisations: {},
            selectedDishCustomisationObj: {},
            customQty: 1,
            alertPresent: false,
            alertMessage: ""
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.dishDetails !== nextProps.dishDetails){
            var totalPrice = nextProps.dishDetails.cost
            var selectedCustomisation = "";
            if(nextProps.dishDetails.customizations !== undefined){
                var customisationCount = Object.keys(nextProps.dishDetails.customizations).length
                
                var customisationKeys = Object.keys(nextProps.dishDetails.customizations)
                var customisationValues = Object.values(nextProps.dishDetails.customizations)
                
                for(var i=0; i<customisationCount; i++){
                    
                    // var items = Object.keys(customisationValues[i])

                    var modificationCount = Object.keys(customisationValues[i].modifications).length
                    var modificationKeys = Object.keys(customisationValues[i].modifications)
                    var modificationValues = Object.values(customisationValues[i].modifications)
                    var k=0;
                    if(customisationValues[i].max_selections === 1){
                        for(k=0; k<modificationCount; k++){
                            if(modificationKeys[k] === customisationValues[i].default){
                                totalPrice = totalPrice + Number(modificationValues[k].unit_price);
                                selectedCustomisation = selectedCustomisation + modificationValues[k].name + ",";
                            }
                        }
                        this.setState({
                            ["radio_"+customisationKeys[i]] : customisationValues[i].default
                        });
                    }
                    else{
                        for(k=0; k<modificationCount; k++){
                            if(customisationValues[i].default.includes(modificationKeys[k])){
                                totalPrice = totalPrice + Number(modificationValues[k].unit_price);
                                selectedCustomisation = selectedCustomisation + modificationValues[k].name + ",";
                                this.setState({
                                    ["checkBox_"+modificationKeys[k]] : true
                                });
                            }
                            else{
                                this.setState({
                                    ["checkBox_"+modificationKeys[k]] : false
                                });
                            }
                        }
                    }
                }

                this.setState({
                    updatedPrice : totalPrice,
                    customQty: 1,
                    selectedCustomisation: selectedCustomisation.substring(0,selectedCustomisation.length-1),
                    dishCost: nextProps.dishDetails.cost,
                    dishCustomisations: nextProps.dishDetails.customizations,
                }, () => {
                    this.readCustomisationUpdates();
                })
            }
        }
    }

    readCustomisationUpdates(){
        var dishCustomisations = this.state.dishCustomisations;
        var dishCost = this.state.dishCost;
        var totalPrice = dishCost;
        var selectedCustomisation = "";
        var customObj = "{ \"combination\" : {";

        var customisationCount = Object.keys(dishCustomisations).length  
        var customisationKeys = Object.keys(dishCustomisations)
        var customisationValues = Object.values(dishCustomisations)
        let customObj1 = ""
        for(var i=0; i<customisationCount; i++){
            // var items = Object.keys(customisationValues[i])

            var modificationCount = Object.keys(customisationValues[i].modifications).length
            var modificationKeys = Object.keys(customisationValues[i].modifications)
            var modificationValues = Object.values(customisationValues[i].modifications)
            var k=0;
            var counter = 0;
            
            customObj1 = "\"" + customisationKeys[i] + "\" : { \"name\" : \"" + customisationValues[i].name + "\", \"modifications\" : {";
            
            if(customisationValues[i].max_selections === 1){
                for(k=0; k<modificationCount; k++){
                    if(modificationKeys[k] === this.state["radio_"+customisationKeys[i]]){
                        customObj1 = customObj1 + "\""+ modificationKeys[k] + "\" : " + JSON.stringify(modificationValues[k]) + ",";
                        totalPrice = totalPrice + Number(modificationValues[k].unit_price);
                        selectedCustomisation = selectedCustomisation + modificationValues[k].name + ",";
                    }
                }
                customObj1 = customObj1.substring(0, customObj1.length-1) + "},\"max_selections\": " + customisationValues[i].max_selections + ",\"min_selections\": " + customisationValues[i].min_selections + ",";
            }
            else{
                counter = 0;
                for(k=0; k<modificationCount; k++){
                    if(this.state["checkBox_"+modificationKeys[k]]){
                        customObj1 = customObj1 + "\""+ modificationKeys[k] + "\" : " + JSON.stringify(modificationValues[k]) + ",";
                        totalPrice = totalPrice + Number(modificationValues[k].unit_price);
                        selectedCustomisation = selectedCustomisation + modificationValues[k].name + ",";
                        counter = counter + 1;
                    }
                }
                if(counter === 0)
                    customObj1 = customObj1 + "},\"max_selections\": " + counter + ",\"min_selections\": " + customisationValues[i].min_selections + ",";
                else
                    customObj1 = customObj1.substring(0, customObj1.length-1) + "},\"max_selections\": " + counter + ",\"min_selections\": " + customisationValues[i].min_selections + ",";
            }
            if(customisationValues[i].min_selections === 1 || counter > 0){
                customObj = customObj + customObj1.substring(0, customObj1.length-1) + "},";
            }
        }
        customObj = customObj.substring(0, customObj.length-1) + "}}";

        this.setState({
            updatedPrice : totalPrice * this.state.customQty,
            selectedCustomisation: selectedCustomisation.substring(0,selectedCustomisation.length-1),
            dishCost: dishCost,
            dishCustomisations: dishCustomisations,
            selectedDishCustomisationObj : customObj
        })
    }

    closeModalHandler() {
        this.props.closeCustomisationOptionsModal(this.props.dishDetails);
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    onChange(modItem, item){
        this.setState({
            ["radio_"+item]: modItem
        }, () => {
            this.readCustomisationUpdates();
        });
    }

    handleCheckbox(modItem, item, checked){
        let checkedCount = 0;
        Object.keys(item.modifications).map((modificationKey, index) => this.state["checkBox_"+modificationKey] && (checkedCount += 1));
        if (checkedCount < item.max_selections || !checked){
            this.setState({
                ["checkBox_"+modItem]: !this.state["checkBox_"+modItem]
            }, () => {
                this.readCustomisationUpdates();
            });
        } else {
            this.setState({
                alertPresent: true,
                alertMessage: `You can select up to ${item.max_selections} options only`
            })
            setTimeout(() => {
                this.setState({
                    alertPresent: false,
                    alertMessage: ""
                })
            }, 4000);
        }
    }

    sortObjectOfObjects(data, attr) {
        var arr = [];
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                var obj = {};
                obj[prop] = data[prop];
                obj[prop].key = prop;
                if (data[prop].hasOwnProperty(attr)){
                    obj.tempSortName = data[prop][attr];
                } else {
                    obj.tempSortName = 0;
                }
                arr.push(obj);
            }
        }
    
        arr.sort(function(a, b) {
            var at = a.tempSortName,
                bt = b.tempSortName;
            return at < bt ? 1 : ( at > bt ? -1 : 0 );
        });
    
        var result = [];
        for (var i=0, l=arr.length; i<l; i++) {
            obj = arr[i];
            delete obj.tempSortName;
            for (var props in obj) {
                if (obj.hasOwnProperty(props)) {
                    var id = props;
                }
            }
            var item = obj[id];
            result.push(item);
        }
        return result;
    }


    customisationSection(customizations){
        return(
            <>
                {(this.sortObjectOfObjects(customizations, 'sort_order')).map((item,index) => {
                    return(
                        <>
                            <Grid item xs={12}>
                                <div className="ddDishCustomisationCustomTypeName">{item.name}</div>
                                {item.max_selections === 1 ?
                                    <div className="ddDishCustomisationCustomTypeSubHeading">You can choose any one option{item.min_selections === 0 ? " (optional)" : ""}</div>
                                :
                                    <div className="ddDishCustomisationCustomTypeSubHeading">You can choose up to {item.max_selections} options{item.min_selections === 0 ? " (optional)" : ""}</div>
                                }
                            </Grid> 
                            <Grid item xs={12}>
                                {item.max_selections === 1 ?
                                    <>
                                        {(this.sortObjectOfObjects(item.modifications, 'sort_order')).map((modItem,index1) => {
                                            return(
                                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}} key={modItem.name + "_" + index1}>  
                                                    {item.default.includes(modItem.key) ?
                                                        <span style={{flex: 1, alignItems: 'flex-start', position: 'relative', display: 'flex', flexDirection: 'row'}} className="ddDishCustomisationCustomItem">
                                                            <span style={{marginRight: '40px', display: 'inline-block'}}></span>
                                                            <FormControlLabel 
                                                                checked={this.state["radio_"+item.key] === modItem.key ? true: false}
                                                                onChange={this.onChange.bind(this,modItem.key,item.key)}
                                                                value={modItem.key}
                                                                name={"radio-button-"+modItem.key}
                                                                control={<Radio 
                                                                    style={{color: "#EA5B2F", position: 'absolute', left: -10, top: 0}}
                                                                    size="small"/>} 
                                                                label={<span className="ddDishCustomisationCustomItem">{modItem.name}</span>}
                                                            />                                                            
                                                        </span>
                                                    :
                                                    <span style={{flex: 1, alignItems: 'flex-start', position: 'relative', display: 'flex', flexDirection: 'row'}} className="ddDishCustomisationCustomItem">
                                                        <span style={{marginRight: '40px', display: 'inline-block'}}></span>
                                                        <FormControlLabel 
                                                            checked={this.state["radio_"+item.key] === modItem.key ? true: false}
                                                            onChange={this.onChange.bind(this,modItem.key,item.key)}
                                                            value={modItem.key}
                                                            name={"radio-button-"+modItem.key}
                                                            control={<Radio 
                                                                style={{color: "#EA5B2F", position: 'absolute', left: -10, top: 0}}
                                                                size="small"/>} 
                                                            label={<span className="ddDishCustomisationCustomItem">{modItem.name}</span>}
                                                        />
                                                    </span>
                                                    }
                                                    <span className="ddDishCustomisationCustomItem" style={{marginTop:"6px"}}>Rs. {modItem.unit_price}</span>
                                                </div>    
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        {(this.sortObjectOfObjects(item.modifications, 'sort_order')).map((modItem,index1) => {
                                            return(
                                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>  
                                                    {item.default.includes(modItem.key) ?
                                                        <span style={{flex: 1, alignItems: 'flex-start', position: 'relative', display: 'flex', flexDirection: 'row'}} className="ddDishCustomisationCustomItem">
                                                            <span style={{marginRight: '40px', display: 'inline-block'}}></span>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        checked={this.state["checkBox_"+modItem.key]}
                                                                        size="small"
                                                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                                        style={{color: "#EA5B2F", position: 'absolute', left: -10, top: 0}}
                                                                        onChange={this.handleCheckbox.bind(this,modItem.key, item, !this.state["checkBox_"+modItem.key])}
                                                                    />
                                                                }
                                                                label={<span className="ddDishCustomisationCustomItem">{modItem.name}</span>}
                                                            />
                                                        </span>
                                                    :
                                                        <span style={{flex: 1, alignItems: 'flex-start', position: 'relative', display: 'flex', flexDirection: 'row'}} className="ddDishCustomisationCustomItem">
                                                            <span style={{marginRight: '40px', display: 'inline-block'}}></span>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state["checkBox_"+modItem.key]}
                                                                        size="small"
                                                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                                        style={{color: "#EA5B2F", position: 'absolute', left: -10, top: 0}}
                                                                        onChange={this.handleCheckbox.bind(this,modItem.key, item, !this.state["checkBox_"+modItem.key])}
                                                                    />
                                                                }
                                                                label={<span className="ddDishCustomisationCustomItem">{modItem.name}</span>}
                                                            />
                                                        </span>
                                                    }
                                                    <span className="ddDishCustomisationCustomItem" style={{marginTop:"6px"}}>Rs. {modItem.unit_price}</span>
                                                </div>    
                                            )
                                        })}
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    )
                })}
            </>
        )
    }

    addQuantity(){
        this.setState({
            customQty: this.state.customQty + 1,
            updatedPrice: this.state.updatedPrice + (this.state.updatedPrice/this.state.customQty)
        })
    }

    removeQuantity(){
        if(this.state.customQty > 1){
            this.setState({
                customQty: this.state.customQty - 1,
                updatedPrice: this.state.updatedPrice - (this.state.updatedPrice/this.state.customQty)
            })
        }
    }

    render() {
        var dishDetails = this.props.dishDetails
        
        // this.cascacdcsdfdsfsd();
        if(!this.props.showCustomisationOptionsModal){
            return null;
        }
        

        return(
            this.props.isMobileScreen ?
            <div className="CustomisationModalM" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="CustomisationSuccessModalContainerM">
                    <AiOutlineCloseCircle size="1.5em" style={{position:"absolute", color:"#626262", right:"10px", top:"10px", zIndex:"1000"}} onClick={() => this.closeModalHandler()}/>
                    <div className="ddCustomisationModalLayoutM">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span className="ddDishCustomisationFoodName">{dishDetails.name}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            
                            {this.customisationSection(dishDetails.customizations)}
                        </Grid>
                    </div>
                    <div style={{padding: "0px 0px 30px 0px", display: "flex", flexDirection: "row", width: "100%"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} style={{display:"flex", justifyContent:"center"}}>
                                <div style={{padding: "0px 1.5vw 0px 2.5vw"}}>
                                    <span style={{position:"relative", top:"3px"}} className="ddcart-Item-ControlsM">
                                        <RemoveIcon className="ddremove-quantity" 
                                            onClick={() => this.removeQuantity()}
                                        />
                                        <span style={{fontSize: "14px"}} className="dditem-Qty">{this.state.customQty}</span>
                                        <AddIcon className="ddadd-quantity" 
                                            onClick={() => this.addQuantity()}
                                        />
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={7}>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <span className="ddAddCartBtn1M" onClick={() => {this.props.getCustomisationData(this.state.selectedCustomisation, this.state.customQty, this.state.selectedDishCustomisationObj)}}><RiShoppingCart2Line className="ddAddCartBtnIcon1" />Add - Rs. {this.state.updatedPrice}</span>
                                </div>
                            </Grid>
                        </Grid>
                   </div>
                </div>
                <Snackbar
                    open={this.state.alertPresent}
                    onRequestClose={() => this.setState({ alertPresent: false })}
                    autoHideDuration={4000}
                    sx={{ height: "100%" }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    <SnackbarContent style={{
                        backgroundColor: '#1E429A',
                        borderRadius: "4px"
                    }}
                    message={<div style={{color: "white", width: 'calc(100vw - 50px)', fontSize: "16px", display: 'flex', flexDirection: 'row', margin: 'auto', justifyContent: 'center'}}>
                        <span>{this.state.alertMessage}</span>
                        {this.state.alertMessage && <span onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                                alertPresent: false,
                                alertMessage: ""
                            });
                        }} style={{marginLeft: "10px"}}>X</span>}
                    </div>}/>
                </Snackbar>
            </div>
            :
            <div className="CustomisationModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="CustomisationSuccessModalContainer">
                    <AiOutlineCloseCircle size="1.5em" style={{position:"absolute", color:"#626262", right:"10px", top:"10px", zIndex:"1000"}} onClick={() => this.closeModalHandler()}/>
                    <div className="ddCustomisationModalLayout">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <span className="ddDishCustomisationFoodName">{dishDetails.name}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            
                            {this.customisationSection(dishDetails.customizations)}
                        </Grid>
                    </div>
                    <div style={{padding: "0px 0px 10px 0px", display: "flex", flexDirection: "row", width: "100%"}}>
                        <div style={{width: "50%", padding: "0px 1.5vw 0px 4.5vw"}}>
                            <span style={{padding: "10px 50px"}} className="ddcart-Item-Controls">
                                <RemoveIcon className="ddremove-quantity" 
                                    onClick={() => this.removeQuantity()}
                                />
                                <span style={{fontSize: "14px"}} className="dditem-Qty">{this.state.customQty}</span>
                                <AddIcon className="ddadd-quantity" 
                                    onClick={() => this.addQuantity()}
                                />
                            </span>
                        </div>
                        <div style={{width: "50%", padding: "0px 4.5vw 0px 1.5vw", textAlign:"center"}}>
                            {/* <span className="ddAddCartBtn" onClick={() => this.handleAddCart(dish, "add", false)}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add to Cart</span> */}
                            {!this.props.customAddClick ?
                                <span className="ddAddCartBtn1" onClick={() => {this.props.getCustomisationData(this.state.selectedCustomisation, this.state.customQty, this.state.selectedDishCustomisationObj);}}><RiShoppingCart2Line className="ddAddCartBtnIcon1" />Add - Rs. {this.state.updatedPrice}</span>
                                :
                                <div><img src={ButtonLoading} alt="Add" style={{width:"70px", position:"relative", top:"7px", height:"20px"}}/></div>}
                            <span style={{fontSize:"10px", fontFamily:"Poppins-Medium", position:"relative", top: !this.props.customAddClick ? "0px" : "-5px"}}>* Discounts will be applied in the cart</span>
                            {/* <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "12px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                No
                            </div> */}
                        </div>
                   </div>
                </div>
                <Snackbar
                    open={this.state.alertPresent}
                    onRequestClose={() => this.setState({ alertPresent: false })}
                    autoHideDuration={4000}
                >
                    <SnackbarContent style={{
                        backgroundColor: '#1E429A',
                        borderRadius: "4px"
                    }}
                    message={<span style={{color: "white", fontSize: "16px"}}>{this.state.alertMessage}
                        {this.state.alertMessage && <span onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                                alertPresent: false,
                                alertMessage: ""
                            });
                        }} style={{marginLeft: "30px"}}>X</span>}
                    </span>}/>
                </Snackbar>
            </div>
        )
    }
}

export default CustomisationOptions;