import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

class LikedCuisines extends Component{

    constructor(props){
        super(props);
        this.likedCuisineList = this.likedCuisineList.bind(this);
        this.removeUserPreferencesHandler = this.removeUserPreferencesHandler.bind(this);
        this.addUserPreferenceHandler = this.addUserPreferenceHandler.bind(this);
    }

    removeUserPreferencesHandler(item){
        this.props.removeUserPreferences(item);
    }

    addUserPreferenceHandler(id){
        this.props.addUserPreference(id);
    }

    preferenceItem(preference){
        if (preference.display){
            return (
                <div key={preference.preferenceId} className="ProfileCuisineItem">
                    {preference.name}
                    <div onClick={() => this.removeUserPreferencesHandler(preference)} className="ProfileCuisineRemoveIcon">
                        <CloseIcon style={{fontSize: "14px"}}/>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
    
    checkNoCuisines(){
        if (this.props.preferences.filter(function(preference){ return preference.display;}).length === 0 && this.props.preferencesFetched){
            return (
                <div style={{ fontSize: "20px"}} className="TabLikesContainer ProfileCuisineItemContainer">
                    <div style={{margin: "auto"}}>No preference present.</div>
                </div>
            )
        } else {
            return null;
        }
    }

    likedCuisineList(){
        if (this.props.preferencesFetched){
            if (this.props.preferences.length){
                return (
                    <div className="TabLikesContainer ProfileCuisineItemContainer">
                        {
                            this.props.preferences.map((preference) => {
                                return (this.preferenceItem(preference))
                            })
                        }
                    </div>
                )
            } else {
                return (
                    <div style={{ fontSize: "20px"}} className="TabLikesContainer ProfileCuisineItemContainer">
                        <div style={{margin: "auto"}}>No Preference Found.</div>
                    </div>
                )
            }
        } else {
            return (
                <div style={{ fontSize: "20px"}} className="TabLikesContainer ProfileCuisineItemContainer">
                    <div style={{margin: "auto"}}>Loading Preferences...</div>
                </div>
            )
        }
    }
    
    render(){
        return(
            <div>
                <div style={{backgroundColor: "#F9F9F9"}} className="CuisineSearchBar ">
                    <div style={{margin: "0px auto 30px auto"}}>
                        {/* <div className="AddCuisineInputContainer">
                            <div className="AddCuisineInputIcon"><AddIcon htmlColor="#FF143B"/></div>
                            <input placeholder="Add Cuisines" className="AddCuisineInput"/>
                        </div> */}
                        <CuisinesSearchBar
                            preferences={this.props.preferences}
                            addUserPreference={this.addUserPreferenceHandler}
                            preferencesFetched={this.props.preferencesFetched}
                        />
                    </div>
                </div>
                {this.likedCuisineList()}
                {this.checkNoCuisines()}
            </div>
        )
    }
}

class CuisinesSearchBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeOption: -1,
            searching: false,
            filteredOptions: [],
            showOptions: false,
            userInput: '',
        };
        this.onChange = this.onChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.checkSearch = this.checkSearch.bind(this);
    }

    onChange = (e) => {
    
        if (this.props.preferencesFetched){
            const options = this.props.preferences;
            const userInput = e.currentTarget.value;
        
            const filteredOptions = options.filter(
            (optionName) =>
                optionName.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
                && !optionName.display
            );
        
            this.setState({
                activeOption: -1,
                filteredOptions,
                showOptions: true,
                userInput: e.currentTarget.value
            });
            if (e.currentTarget.value !== ""){
                this.setState({
                    searching : true
                })
            } else {
                this.setState({
                    searching : false
                })
            }
        }
    };

    handleClick(option) {
        this.props.addUserPreference(option.id);
        this.setState({
          activeOption: -1,
          filteredOptions: [],
          showOptions: false,
          userInput: '',
          searching: false
        });
    };

    onKeyDown = (e) => {
        const { activeOption, filteredOptions } = this.state;

        if (e.keyCode === 13) {
            if (activeOption !== -1){
                this.setState({
                    activeOption: -1,
                    showOptions: false,
                    userInput: '',
                    searching: false
                });
                this.props.addUserPreference(filteredOptions[activeOption].id);
            }
        } else if (e.keyCode === 38) {
            if (activeOption <= 0) {
                this.setState({ activeOption: filteredOptions.length - 1 });
            } else {
                this.setState({ activeOption: activeOption - 1 });
            }
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                this.setState({ activeOption: 0 });
                return;
            }
            this.setState({ activeOption: activeOption + 1 });
        }
    };

    clearSearch = (e) => {
        this.setState({
            userInput : "",
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        }) 
    }

    checkSearch() {
        this.setState({
            activeOption : -1,
            filteredOptions: [],
            showOptions: false,
            searching: false
        }) 
    }

    render() {
        let optionList;
        let searchBarColor;
        let containerShadow;
        let crossIcon;
        if (this.state.showOptions && this.state.userInput) {
            if (this.state.filteredOptions.length) {
              optionList = (
                <div className="SearchBarOptions" style={{top: "36px", maxHeight: "300px", overflowY: "scroll"}}>
                  {this.state.filteredOptions.map((optionName, index) => {
                    let className;
                    if (index === this.state.activeOption) {
                      className = 'FilteredOptionActive';
                    }
                    return (
                      <div className={className + " FilteredOption"} key={optionName.preferenceId} onMouseDown={() => this.handleClick(optionName)}>
                        {optionName.name}
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              optionList = (
                <div className="SearchBarOptions" style={{top: "36px"}}>
                    <div className="FilteredOptionNotFound">No matched cuisine</div>
                </div>
              );
            }
        }
        if (this.state.searching){
            searchBarColor = {backgroundColor: "#F9F9F9"}
            containerShadow = {boxShadow: "3px 3px 12px #1B1B1B59"};
        } else {
            searchBarColor = {backgroundColor: "white"}
            containerShadow = {};
        }
        if (this.state.userInput){
            crossIcon = (<span className="CancelSearch" onMouseDown={this.clearSearch}>X</span>);
        } else {
            crossIcon = (<span></span>)
        }
        return (
            <div style={containerShadow} className="SearchBarSuper CuisineSearchBarSuper">
                <div style={searchBarColor} className="AddCuisineInputContainer">
                    <div className="AddCuisineInputIcon"><AddIcon htmlColor="#FF143B"/></div>
                    <input
                        type="text"
                        className="AddCuisineInput"
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        value={this.state.userInput}
                        onBlur={this.checkSearch}
                        placeholder="Add Cuisines"
                    />
                    {crossIcon}
                </div>
                {optionList}
            </div>
        );
    }
}

export default LikedCuisines;