import messaging from '../firebase_messaging';

export const setDeviceToken = async () => {
    var device_token = localStorage.getItem('device_token');
    if (device_token === null && messaging !== null){
      messaging.requestPermission().then(() => {
        return messaging.getToken();
      }).then(token=>{
        localStorage.setItem('device_token', token);
      });
    }
}