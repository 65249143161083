import axios from 'axios';
import React, { Component } from 'react';
import CONSTANTS from '../config/constants';
import Footer from '../components/Commons/Footer';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import '../assets/styles/Commons.css';
import Header from '../components/Dishes/Header'
import UserOptions from '../components/Dishes/UserOptions';
import LogoutConfirm from '../components/Commons/LogoutConfirm';
import OrderDetails from '../components/Commons/OrderDetails';
import ApplicationWindow from '../components/Careers/ApplicationWindow';
import SearchIcon from '@material-ui/icons/Search';
import { FiFilter } from "react-icons/fi";
import { RiInformationLine } from "react-icons/ri"
import Divider from '@material-ui/core/Divider';
import { BsExclamationTriangle } from "react-icons/bs";
// import { GoPrimitiveDot } from "react-icons/go";
import OrderPlaced from "../assets/images/OrderPlaced.gif"
import OrderPreparing from "../assets/images/OrderPreparing.gif"
import OrderReady from "../assets/images/OrderReady.gif"
import OrderDispatched from "../assets/images/OrderDispatched.gif"
import Delivered from "../assets/images/Delivered.gif"
import Declined from "../assets/images/orderDeclined.webp"
import RatingStar from "../assets/images/ratingStar.png";
import DaiDishLoading1 from '../assets/images/DaiDishLoading1.gif';
import DiscountIcon from '../assets/images/discountIcon.png';
import AuthPopup from '../components/Commons/AuthPopup';
import TextField from '@material-ui/core/TextField';
import RatingStarSelected from "../assets/images/ratingStarSelected.png";
import UserCart from '../components/Restaurants/UserCart';
import PaymentFailed from "../assets/images/paymentFailed.webp";
import ConfirmationBox from '../components/Commons/ConfirmationBox';
// import DiscountImage from "../assets/images/discountImage.png";
import FreeDelivery from "../assets/images/freeDelivery1.png";
import Vector1 from '../assets/images/vector1_1.png';
import Vector2 from '../assets/images/vector2_1.png';
import Vector3 from '../assets/images/vector3_1.png';

class Careers extends Component {
  constructor(){
    super();
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.showAuthPopupModal = this.showAuthPopupModal.bind(this);
    this.showDeliveryModeCard = this.showDeliveryModeCard.bind(this);
    this.showPaymentCard = this.showPaymentCard.bind(this);
    this.CouponSection = this.CouponSection.bind(this);
    this.RestoDiscount = this.RestoDiscount.bind(this);
    this.OrderSummary = this.OrderSummary.bind(this);
    this.getCoupons = this.getCoupons.bind(this);
    this.getUserAddress = this.getUserAddress.bind(this);
    this.toggleOrderDetailsModal1 = this.toggleOrderDetailsModal1.bind(this);
    this.closeOrderDetailsModal = this.closeOrderDetailsModal.bind(this);
    this.closeApplicationWindow = this.closeApplicationWindow.bind(this);
    this.showRatingsModal = this.showRatingsModal.bind(this);
    this.closeRatingsModal = this.closeRatingsModal.bind(this);
    this.closeRatingsInformationModal = this.closeRatingsInformationModal.bind(this);
    this.closeReviewsModal = this.closeReviewsModal.bind(this);
    this.orderSearch = this.orderSearch.bind(this);
    this.itemsCount = this.itemsCount.bind(this);
    this.getCustomisationText = this.getCustomisationText.bind(this);
    this.closeAuthPopupModal = this.closeAuthPopupModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.updateCartModalDisplay = this.updateCartModalDisplay.bind(this);
    this.updateCartChangedValue = this.updateCartChangedValue.bind(this);
    this.state = {
      isMobileScreen: false,
      isOpen: false,
      logoutModal: false,
      addresses : {},
      addressCardVisibility : false,
      deliveryModeVisibility : true,
      paymentCardVisibility: false,
      coupons: [],
      sumTotal: 0,
      couponDiscPerc: 0,
      couponDiscAmt: 0,
      taxCharges: 0,
      taxPercent: 10,
      restoDiscPerc: 20,
      restoDiscAmt: 0,
      //deliveryCharges: 25,
      //packagingCharges: 10,
      grandTotal: 0,
      selectedCoupon: -1,
      amountPaidIndicator: false,
      cartList: [],
      deliveryCharges: 0,
      taxesAndCharges: 0,
      packagingCharges: 0,
      gstAndTaxes: 0,
      restaurant_name: "",
      order_id: null,
      order_token: null,
      orderData: {},
      order_status: "",
      orderList: [],
      tempOrderList:[],
      showOrderDetailsModal: false,
      showApplicationWindow: false,
      selectedCareer: {},
      orderToShow: {},
      payment_time: "",
      deliveryAddress: "",
      ratingModal: false,
      isFiveStar: false,
      successModal: false,
      emoji: "",
      ratingNumber: "",
      searchBarText:"",
      restoNameAddress: "",
      deliveryMode: "",
      otp: "",
      showLoading: true,
      activeForm: "",
      deliveryPerson: "",
      deliveryPersonNum: "",
      deliveryTracking: "", 
      readPolicy: false,
      paymentInitiated: false,
      confirmationBoxMessage: "",
      showConfirmationBox: false,
      restoDiscount: 0,

      availableVacanciesCount: 0,
      availableVacancies: [],
    }
  }

  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getOpenPositions();
    this.updateCartChangedValue(true);
  }

  updateCartModalDisplay(value) {
    this.setState({
      showCart: value
    })
  }

  updateCartChangedValue(value) {
    this.setState({
      cartChanged: value
    })
    let cartDetails = localStorage.getItem('cartDetailsAPI');
    let itemsInCart = 0;
    if (cartDetails !== null){
      let cartDetailsObj = JSON.parse(cartDetails);
      var itemVal = Object.values(cartDetailsObj.items)
      for(var i=0; i<itemVal.length; i++){
        itemsInCart = itemsInCart + itemVal[i].quantity
      }
    }
    this.setState({
      itemsInCart: itemsInCart
    });
  }

  getOpenPositions(){
    let url = CONSTANTS.hostURL + "api/client/careers/get-open-positions/";
    axios.get(url).then((response) => {
      if (response.data.status === 1){
        let openPositionsData = response.data.data
        this.getCartDetails();
        this.setState({
          availableVacanciesCount: openPositionsData.count,
          availableVacancies: openPositionsData.results
        })
      }
    });
  }

  getCartDetails(){
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios.get(url, {
          headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1 && 'id' in data.data){
          let cart = data.data;
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          let itemsInCart = 0;
          for (var key in cart.items){
            if(cart.items[key].customizations.length > 1){
              for(var k=0; k<cart.items[key].customizations.length; k++){
                itemsInCart = itemsInCart + cart.items[key].customizations[k].quantity
              }
            }
            else{
              itemsInCart = itemsInCart + cart.items[key].quantity
            }
          }
          this.setState({
            itemsInCart: itemsInCart,
            cartChanged: false,
            restaurant_name: cart.restaurant_name
          });
          this.updateCartChangedValue(true);
        } else {
          if (data.status === -1){
            localStorage.removeItem('cartDetailsAPI');
            this.updateCartChangedValue(true);
          }
        }
        let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
        let firebaseReq = { 
          'token': localStorage.getItem('device_token')
        }
        let firebaseHeaders = {
          'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
        }
        axios.post(firebaseUrl, firebaseReq,  {
          headers: firebaseHeaders
        }).then((response) => {});
        setTimeout(() => {
          this.setState({showLoading : false})
        }, 5000);
      });
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  getDeliveryAddress(orderData){
    if(orderData.delivery_mode !== "SELF_PICKUP"){
      var token = localStorage.getItem("auth_token");
      if (token !== null && token !== ""){
        var headers = {
          'Authorization' : 'Bearer ' + token,
        }
        var url = CONSTANTS.hostURL + "api/geo/location/";
        axios.get(url, {
          headers: headers
        }).then((response) => {
            var resData = response.data;
            if (resData.status === 1){
              for(let i=0; i<resData.data.locations.length; i++){
                if(resData.data.locations[i].id === orderData.delivery_address.id){
                  this.setState({deliveryAddress : <><span style={{fontFamily:"Poppins-SemiBold"}}>{resData.data.locations[i].tag}</span><span style={{fontFamily:"Poppins-Regular"}}>, {resData.data.locations[i].full_address.replaceAll(';', ', ')}</span></>});
                }
              }
              this.setState({addresses : resData.data.locations});
            } else {
              this.setState({addresses : []});
            }
        });
      }
      else{
        this.showAuthPopupModal("login");
      }
    }
    else{
      this.setState({deliveryAddress : <span style={{fontFamily:"Poppins-SemiBold"}}>{orderData.restaurant.full_address}</span>});
    }
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  toggleUserOptions(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  
  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal
    })
  }

  showAuthPopupModal(form) {
    this.setState({
      authPopupModal: true,
    })
    if (form != null){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthPopupModal() {
    this.setState({
      authPopupModal: false
    })
    window.location.pathname = "/";
  }

  editAddressItem(address,updateType) {
    let addressList = this.state.addresses;
    let index = -1;
    
    if(updateType === "defaultUpdate"){
      for (var j=0; j< addressList.length; j++){
        addressList[j].is_default = false;
        if (addressList[j].id === address.id){
          index = j;
        }
      }
      if (index !== -1){
        addressList[index] = address;
      }
    }
    else if(updateType === "delete"){
      for (var k=0; k< addressList.length; k++){
        if (addressList[k].id === address){
          index = k;
        }
      }
      if (index !== -1){
        addressList.splice(index, 1);
      }
    }
    else{
      for (var i=0; i< addressList.length; i++){
        if (addressList[i].id === address.id){
          index = i;
        }
      }
      if (index !== -1){
        addressList[index] = address;
      } else {
        addressList.push(address);
      }
    }

    this.setState({
      addresses: addressList
    });
  }

  getUserAddress(){
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      var headers = {
        'Authorization' : 'Bearer ' + token,
      }
      var url = CONSTANTS.hostURL + "api/geo/location/";
      axios.get(url, {
        headers: headers
      }).then((response) => {
          var resData = response.data;
          if (resData.status === 1){
            this.setState({addresses : resData.data.locations});
          } else {
            this.setState({addresses : []});
          }
      });
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  getCoupons(){
    let coupons = []
    let restoDiscAmt = 0
    let couponDiscAmt = 0
    let cartDetailsObj = this.state.orderData;
    if (cartDetailsObj !== null){
      var couponVals = cartDetailsObj.extra_data.discounts
      for(var i=0; i<couponVals.length; i++){
        if(couponVals[i].discount_type !== "resto_discount"){
          coupons.push(couponVals[i])
          restoDiscAmt = restoDiscAmt + couponVals[i].discount
        }
        else{
          couponDiscAmt = couponDiscAmt + couponVals[i].discount
        }
      }
    }
    this.setState({
      coupons : coupons,
      restoDiscAmt: restoDiscAmt,
      couponDiscAmt: couponDiscAmt
    });
  }

  showAddressCard(){
    this.setState({
      addressCardVisibility : true,
      deliveryModeVisibility : false,
      paymentCardVisibility : false,
    })
  }

  showDeliveryModeCard(){
    this.setState({
      addressCardVisibility : false,
      deliveryModeVisibility : true,
      paymentCardVisibility : false,
    })
  }

  showPaymentCard(){
    this.setState({
      addressCardVisibility : false,
      deliveryModeVisibility : false,
      paymentCardVisibility : true,
    })
  }

  selectCoupon(keyItem){
    let cartDetails = localStorage.getItem('CartDetails');
    var obj = JSON.parse(cartDetails);
    let sumTotal = 0;
    for(let k = 0; k < obj.Cart_Items.length; k++){
        sumTotal = sumTotal + (obj.Cart_Items[k].DishCost * obj.Cart_Items[k].DishQuantity);
    }

    let restoDiscAmt = ((this.state.restoDiscPerc/100) * sumTotal).toFixed(2);
    let taxCharges = ((this.state.taxPercent/100) * sumTotal).toFixed(2);
    let couponDiscAmt = ((keyItem.percentage/100) * sumTotal).toFixed(2)
    let grandTotal = ((Number(Number(sumTotal) + Number(this.state.deliveryCharges)) - Number(Number(((keyItem.percentage/100) * sumTotal).toFixed(2)) + Number(((this.state.restoDiscPerc/100) * sumTotal).toFixed(2))))).toFixed(2);

    this.setState({
        sumTotal:sumTotal,
        restoDiscAmt: restoDiscAmt,
        taxCharges: taxCharges,
        grandTotal: grandTotal,
        couponDiscPerc: keyItem.percentage,
        couponDiscAmt: couponDiscAmt,
        selectedCoupon: keyItem.id,
    });
}

CouponSection(){
  const listItems = this.state.coupons.map((keyItem, index) =>
      keyItem.discount > 0 && <Grid item key={"Coupon_"+index} xs={12} style={{paddingTop:"5px"}}>
          <Grid container spacing={2}>
              <Grid item xs={7}>
                  <span className="coupon-name">{keyItem.text} {keyItem.percent}%</span>
              </Grid>
              <Grid item xs={5} style={{textAlign:'right'}}>
                  <span className="coupon-discount-details-right">-Rs {keyItem.discount}</span>
              </Grid>
          </Grid>
      </Grid>
  );
  return(
      <Grid container spacing={2}>
          {listItems}
      </Grid>
  );
}

RestoDiscount(){
  let cartDetailsObj = this.state.orderData;
  let coupons = []
  if (cartDetailsObj !== null){
    var couponVals = cartDetailsObj.extra_data.discounts
    for(var i=0; i<couponVals.length; i++){
      if(couponVals[i].discount_type === "resto_discount")
        coupons.push(couponVals[i]);
    }
  }

  const listItems = coupons.map((keyItem, index) =>
    keyItem.discount > 0 && <Grid container key={"coupons_"+index} spacing={2} style={{paddingTop: "5px"}}>
      <Grid item xs={12}>
          <Grid container spacing={2}>
              <Grid item xs={8} style={{position:"relative", top:"-2px"}}>
                  <span className="coupon-name">{keyItem.text} {keyItem.percent}%</span>
              </Grid>
              <Grid item xs={4} style={{textAlign:'right'}}>
                  <span className="coupon-discount-details-right">-Rs {keyItem.discount}</span>
              </Grid>
          </Grid>
      </Grid>
    </Grid>
  );

  return(
      <>
          {listItems}
      </>
  );
}

OrderSummary(){
    return(
        <div style={{paddingTop:"20px"}}>
            <Divider style={{marginTop:"10px", marginBottom:"10px"}}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <span className="summary-header">Payment Summary</span><br/>
                </Grid>
                <Grid item xs={7} style={{paddingTop:'0%'}}>
                    {/* <div className="summary-details-left">Total Price</div> */}
                    <div className="summary-details-left">Subtotal</div>
                    {this.state.deliveryMode !== "SELF_PICKUP" && <div className="summary-details-left">Delivery Charges</div>}
                    

                    <div className="summary-details-left">
                      Taxes & Charges
                      <div className="tooltip"><RiInformationLine className="ddInfoIcon"/>
                        <span className="tooltiptext">
                          <div>
                            <span>Packing Charges</span>
                            <span style={{float:"right"}}>Rs. {this.state.packagingCharges}</span>
                          </div>
                          <div>
                            <span>Taxes</span>
                            <span style={{float:"right"}}>Rs. {this.state.gstAndTaxes}</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    {this.state.restoDiscAmt > 0 && <div className="summary-details-left" style={{color:"rgb(248, 0, 165)"}}>Daidish Discount</div>}
                    {this.state.couponDiscAmt > 0 && <div className="summary-details-left" style={{color:'rgb(79, 147, 236)'}}>Restaurant Discount</div>}
                    
                </Grid>
                <Grid item xs={5} style={{textAlign:'right', paddingTop:'0%'}}>
                    <div className="summary-details-right">Rs {this.state.sumTotal}</div>
                    {this.state.deliveryMode !== "SELF_PICKUP" && (this.state.deliveryCharges > 0 ? <div className="summary-details-right">Rs {this.state.deliveryCharges}</div> : <div className="summary-details-right"><img src={FreeDelivery} alt="FreeDlvImage" style={{width:"20px", marginRight:"5px", position:"relative", top:"5px"}}/><span style={{color:"#2EA82E", fontFamily:"Poppins-Bold", fontStyle:"italic"}}>Free Delivery</span></div>)}
                    <div className="summary-details-right">Rs {this.state.taxesAndCharges}</div>
                    {/* <div className="summary-details-right">Rs {this.state.packagingCharges}</div> */}
                    {this.state.restoDiscAmt > 0 && <div className="summary-details-right" style={{color:"rgb(248, 0, 165)"}}>- Rs {this.state.restoDiscAmt}</div>}
                    {this.state.couponDiscAmt > 0 && <div className="summary-details-right" style={{color:'rgb(79, 147, 236)'}}>- Rs {this.state.couponDiscAmt}</div>}
                    
                </Grid>
                <Grid item xs={7} style={{paddingTop:'0%', fontStyle:"bold"}}>
                    <span className="summary-header">GRAND TOTAL</span>
                </Grid>
                <Grid item xs={5} style={{textAlign:'right', paddingTop:'0%', fontStyle:"bold"}}>
                    <span className="summary-header">Rs {this.state.grandTotal}</span>
                </Grid>
            </Grid>
        </div>
    );
}

  chooseDeliveryMode(){
    return(
      <Grid container spacing={1} style={{marginBottom: "10px"}}>
        <Grid item xs={12} style={{fontFamily:"Poppins-Medium", fontSize:"15px", marginLeft:"20px", marginTop:"30px"}}>
          Choose Mode of Delivery
        </Grid>
        <Grid item xs={12}>
          <div className="ddCartModeOfDeliveryBlocks">
            <span>Delivery</span>
            <span style={{float:"right"}} className="ddCartBtns" onClick={() => this.showAddressCard()}>PROCEED</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="ddCartModeOfDeliveryBlocks">
            <span>Self-Pickup</span>
            <span style={{float:"right"}} className="ddCartBtns">PROCEED</span>
          </div>
        </Grid>
      </Grid>
    );
  }

  couponsAndPriceSummary(){
    return(
      <div style={this.state.isMobileScreen ? {padding:"0px 20px"} : {width:"95%", paddingLeft:"30px"}}>
        {this.state.restoDiscount > 0 && <Grid container spacing={2} className="coupon-details" style={{padding:"0px 25px 15px 20px", marginTop:"-12px"}}>
          <Grid item xs={12}>
              <img style={{width:"20px", margin:"10px 5px 0px -3px"}} src={DiscountIcon} alt="icon"/>
              <span style={{fontFamily:"Poppins-Medium", color:"#3356AA", position:"relative", top:"-5px"}}>Discounts Applied</span>
          </Grid>
          {this.CouponSection()}
          {this.RestoDiscount()}
        </Grid>}
        {this.OrderSummary()}
        <div style={this.state.isMobileScreen ? {paddingTop:"30px", paddingBlock:"15px"} : {paddingTop:"30px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <span className="ddReviewNote">
                  <div className="ddReviewNoteHeader"><BsExclamationTriangle className="ddInfoIcon"/>Review your Order and Address details to avoid cancellations</div>
                  {this.state.readPolicy === false && <><div className="ddReviewNoteText">
                    Orders once placed cannot be cancelled. If you cancel your food order after placing the same, 100% cancellation fee will be applicable. 
                  </div>
                  <div>
                    <span className="ddSmallLinks" onClick={() => this.setState({readPolicy : true})}>Read Policy</span>
                  </div>
                  </>}
                  {this.state.readPolicy && <div className="ddReviewNoteText">
                    Orders once placed cannot be cancelled. If you cancel your food order after placing the same, 100% cancellation fee will be applicable. 
                    <ul style={{paddingInlineStart: "15px"}}>
                      {/* <li>If you choose to cancel, you can do it within 60 seconds after placing the order.</li>
                      <li>Post 60 seconds, you will be charged a 100% cancellation fee.</li> */}
                      <li>However, in the event of an unusual delay of your order, you will not be charged a cancellation fee.</li>
                      <li>This policy helps us to avoid food wastage and compensate restaurants/delivery partners for their efforts.</li>
                    </ul>
                  </div>}
                </span>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  deliveryStatusScreen(){
    switch(this.state.order_status) {
      case 'ORDERED':
        return <img src={OrderPlaced} style={{width:"100%", position:"center"}} alt="Order Status"/>;
      case 'UNDER_PREPARATION':
        return <img src={OrderPreparing} style={{width:"100%", position:"center"}} alt="Order Status"/>;
      case 'READY_FOR_PICKUP':
        return <img src={OrderReady} style={{width:"100%", position:"center"}} alt="Order Status"/>;
      case 'ON_THE_WAY':
        return <img src={OrderDispatched} style={{width:"100%", position:"center"}} alt="Order Status"/>;
      case 'FULFILLED':
        return <img src={Delivered} style={{width:"100%", position:"center"}} alt="Order Status"/>;
      case 'DECLINED':
        return <img src={Declined} style={{width:"100%", position:"center"}} alt="Order Status"/>; 
      case 'PAYMENT_INITIATED':
        return <img src={PaymentFailed} style={{width:"100%", position:"center"}} alt="Order Status"/>;    
      default:
        return <div>{this.state.order_status} status not mapped</div>;
    }
  }

  getCustomisationText(customizations){
    var customText = "";
    if(customizations !== undefined){
      if(customizations.combination !== null){
        var combinationValues = Object.values(customizations.combination)
        for(var i=0; i<combinationValues.length; i++){
          customText = customText + combinationValues[i].name + " : ";
          var modVals = Object.values(combinationValues[i].modifications)
          for(var k=0; k<modVals.length; k++){
              customText = customText + modVals[k].name + ", ";
          }
          customText = customText.substring(0, customText.length-2);
          customText = customText + "\n"
        }
      }
      else{
        customText = "No Customization\n"
      }
    }
    return(customText);
  }

compareCustomisation(cartCustomisation, selectedDishCustomisationObj){
    let cartCustomString = this.getCustomisationText(cartCustomisation)
    let selectedCustomString = this.getCustomisationText(selectedDishCustomisationObj)
    if(cartCustomString === selectedCustomString)
        return true;
    else
        return false;
}

getCartList(){
  var cartDetailsAPI = this.state.orderData;
  var cartList = []
  var sumTotal = 0
  if (cartDetailsAPI !== null){
      var itemKey = Object.keys(cartDetailsAPI.items)
      var itemValue = Object.values(cartDetailsAPI.items)

      for(let i = 0; i < itemValue.length; i++){
        for(var j=0; j<itemValue[i].customizations.length; j++ ){
          if(itemValue[i].customizations[j].combination !== null){
              cartList.push({
                  "DishID": Number(itemKey[i]), 
                  "DishName": itemValue[i].name,
                  "DishImage": itemValue[i].image,
                  "DishCost": itemValue[i].customizations[j].sub_total, 
                  "DishIsVeg": itemValue[i].choice, 
                  "DishQuantity": itemValue[i].customizations[j].quantity,
                  "Customisation": itemValue[i].customizations[j]
              });
              sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
          }
          else{
              cartList.push({
                  "DishID": Number(itemKey[i]), 
                  "DishName": itemValue[i].name,
                  "DishImage": itemValue[i].image,
                  "DishCost": itemValue[i].customizations[j].sub_total, 
                  "DishIsVeg": itemValue[i].choice, 
                  "DishQuantity": itemValue[i].customizations[j].quantity,
                  "Customisation": {"combination": null, "quantity": itemValue[i].customizations[j].quantity, "sub_total": itemValue[i].customizations[j].sub_total}
              });
              sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
          }  
        }
      }

      let restoDiscount = 0;
      let availableRestoDiscount = 0;
      let restoDiscountAmount = 0;
      let minAmountForDiscount = 0;
      if(cartDetailsAPI.extra_data.discounts !== undefined){
          for(let i=0; i<cartDetailsAPI.extra_data.discounts.length; i++){
              if(cartDetailsAPI.extra_data.discounts[i].text === "Restaurant Discount")
                  restoDiscount = cartDetailsAPI.extra_data.discounts[i].percent
                  restoDiscountAmount = cartDetailsAPI.extra_data.discounts[i].discount
          }
      }

      if(cartDetailsAPI.extra_data.discounts !== undefined){
          for(let i=0; i<cartDetailsAPI.extra_data.available_discounts.length; i++){
              if(cartDetailsAPI.extra_data.available_discounts[i].text === "Restaurant Discount")
                  availableRestoDiscount = cartDetailsAPI.extra_data.available_discounts[i].percent
                  minAmountForDiscount = cartDetailsAPI.extra_data.available_discounts[i].min_order_value
          }
      }


      this.setState({
          cartList: cartList,
          sumTotal: sumTotal,
          grandTotal: (Math.round(cartDetailsAPI.net_total * 100) / 100).toFixed(2),
          deliveryCharges: cartDetailsAPI.other_charges.delivery_charge,
          taxesAndCharges: Math.round(cartDetailsAPI.other_charges.other_charges * 100) / 100,
          packagingCharges: cartDetailsAPI.other_charges.other_charges_breakdown.packaging_charges,
          gstAndTaxes: cartDetailsAPI.other_charges.other_charges_breakdown.taxes,
          restoDiscount: restoDiscount,
          availableRestoDiscount: availableRestoDiscount,
          minAmountForDiscount: minAmountForDiscount,
          restoDiscountAmount: restoDiscountAmount
      });
    }
  }


   AddedItems(){
    const listItems = this.state.cartList.map((keyItem) =>
        <Grid key={"CartItem_"+this.state.cartList.indexOf(keyItem)} item xs={12}>
            <div className="cart-details">
                <div style={{paddingBottom: "15px"}} className="cart-item-details">
                    <div className="ddcartItemControls">
                        {/* <RemoveIcon style={{ fontSize: 13 }} className="remove-quantity" onClick={() => this.updateCartValues(keyItem, "Remove")}/> */}
                        <span className="dditemOrderQty">{keyItem.DishQuantity}</span>
                        {/* <AddIcon style={{ fontSize: 13 }} className="add-quantity" onClick={() => this.updateCartValues(keyItem, "Add")}/> */}
                    </div>
                    <div className="cart-item-names">
                        <div>{keyItem.DishName}</div>
                        {keyItem.Customisation.combination !== null && <div className="ddCustomisationText">{this.getCustomisationText(keyItem.Customisation)}</div>}
                        <div style={{fontFamily: "Poppins-Regular"}}>Rs. {keyItem.DishCost}</div>
                    </div>
                    {(keyItem.DishImage !== null && keyItem.DishImage !== "") && 
                        <img className="cart-food-image" src={keyItem.DishImage} alt={keyItem.DishName} />
                    }
                </div>
            </div>
        </Grid>
    );
    return(
        <div style={this.state.isMobileScreen ? {padding:"10px 20px"} : {padding:"10px 30px"}}>
            {listItems}
        </div>
    );
  }

  showRatingsModalHandler(dish) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        this.showRatingsModal(dish);
    } else {
        this.showAuthPopupModal("login");
    }
  }

  showRatingsModal(dish) {
    this.setState({
      ratingInformationModal: true,
      dishSelected: dish
    });
    setTimeout(() => {
      this.setState({
        ratingInformationModal: false
      })
      if (!this.state.ratingModal){
        this.setState({
          ratingModal: true
        })
      }
    }, 4000);
  };


  itemsCount(orderItem){
    var itemCount = 0
    var itemVal = Object.values(orderItem.items)
    for(var i=0; i<itemVal.length; i++){
      itemCount = itemCount + itemVal[i].quantity
    }
    return itemCount;
  }

  listOrderItems(orderItem){
    var itemKey = Object.keys(orderItem.items)
    var itemValue = Object.values(orderItem.items)
    var cartList = [];
    for(let i = 0; i < itemValue.length; i++){
      cartList.push({
        "order_id": orderItem.id,
        "dishId": Number(itemKey[i]), 
        "name": itemValue[i].name,
        "image": itemValue[i].image,
        "cost": itemValue[i].item_total, 
        "choice": itemValue[i].choice, 
        "DishQuantity": itemValue[i].quantity,
        "restaurant": orderItem.restaurant.name,
        "Customisation": {"combination": null, "quantity": itemValue[i].quantity, "sub_total": itemValue[i].item_total}
      });
    }

    const listItems = cartList.map((keyItem) =>
        <Grid key={"CartItem_"+cartList.indexOf(keyItem)} item xs={12}>
            <div style={this.state.isMobileScreen ? {padding: "10px 0px 20px 0px"} : {padding: "20px 20px 30px 20px"}} className="ddOrderDetailsBox">
                <div className="cart-item-details">
                    <div style={this.state.isMobileScreen ? {marginLeft:"0px"} : {}} className="cart-item-names">
                        <div>{keyItem.name}</div>
                        {keyItem.Customisation.combination !== null && <div className="ddCustomisationText">{this.getCustomisationText(keyItem.Customisation)}</div>}
                        <div style={{fontFamily: "Poppins-Regular"}}>Rs. {keyItem.cost}</div>
                    </div>
                    {keyItem.image !== null && 
                        <img className="cart-food-image" src={keyItem.image} alt={keyItem.name} />
                    }
                </div>
                {/* <span className="ddDishCardRateButton" style={{marginLeft:"3px"}} onClick={() => this.showRatingsModalHandler(keyItem)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span> */}
                {this.starArea(keyItem.dishId, keyItem.order_id)}
            </div>
        </Grid>
    );
    return(
        <div style={this.state.isMobileScreen ? {padding:"10px"} : {padding:"10px 30px"}}>
            {listItems}
        </div>
    );
  }

  starArea(itemId, orderId){
    return(
      this.state["rated_" + orderId + "_" + itemId] !== undefined ? 
        <div style={{position:"relative", height:"25px", left: this.state.isMobileScreen ? "0px" : "15px"}}>
          <div style={{fontFamily:"Poppins-Medium", color:"#83706A", fontSize:"12px"}}>Thank You for Rating this Dish!</div>
          <div style={{color:"#2B2624", fontFamily:"Poppins-SemiBold", fontSize:"18px"}}><img src={RatingStarSelected} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "20px", position:"relative", top:"2px"}} className="ddOrderDishRateStar-notSelected"/>{this.state["rated_" + orderId + "_" + itemId]}</div>
        </div>
      :
      <>
        <div style={{position:"relative", height:"25px", left: this.state.isMobileScreen ? "0px" : "10px"}}>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 1, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 1 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "5px" : "10px"}} className="ddRateNumText">1</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 1.5, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 1.5 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "2px" : "6px"}} className="ddRateNumText">1.5</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 2, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 2 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "5px" : "10px"}} className="ddRateNumText">2</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 2.5, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 2.5 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "2px" : "6px"}} className="ddRateNumText">2.5</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 3, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 3 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "5px" : "10px"}} className="ddRateNumText">3</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 3.5, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 3.5 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "2px" : "6px"}} className="ddRateNumText">3.5</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 4, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 4 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "5px" : "10px"}} className="ddRateNumText">4</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 4.5, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 4.5 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "2px" : "6px"}} className="ddRateNumText">4.5</span>
          </span>
          <span style={{position:"relative"}} onClick={() => this.setState({ ["rating_"+itemId] : 5, ["ratingChange_" + orderId] : true})}>
            <img src={this.state["rating_"+itemId] >= 5 ? RatingStarSelected : RatingStar} alt="StarImage" style={this.state.isMobileScreen ? {width: "15px"} : {width: "25px"}} className="ddOrderDishRateStar-notSelected"/>
            <span style={{top:"20px", left: this.state.isMobileScreen ? "5px" : "10px"}} className="ddRateNumText">5</span>
          </span>
        </div>
        {this.state["rating_"+itemId] > 0 && <div style={{position: "relative", top: "20px", paddingBottom: "10px"}}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            placeholder="Write a review about your dish experience."
            variant="outlined"
            className="ddRatingTextBox"
            value={this.state["review_"+itemId]}
            onChange={e => this.setState({["review_"+itemId]: e.target.value})}
          />
        </div>}
      </>
    );
  }

  getOrderedAt(orderedAt){
    var momentTz = require('moment-timezone');
    var date = momentTz.tz(orderedAt, 'Asia/Kolkata').format('llll');
    return date;
}

  displayOrderList(){
    let listItems = null;
    if(this.state.tempOrderList !== null){
      listItems = this.state.tempOrderList.map((keyItem) =>
        <Grid key={"orderItem_"+keyItem.id} item xs={12} /*style={{paddingLeft:"150px", paddingRight:"150px"}}*/>
            <div className="ddOrderListBox">
              <Grid container spacing={0} style={this.state.isMobileScreen ? {padding: "10px"} : {padding: "30px 40px 30px 40px"}}>
                <Grid item xs={9}>
                  {this.state.isMobileScreen ?
                    <div style={{width:"200px"}}>
                      <div style={{color:"#3356AA", fontFamily:"Poppins-Medium", fontSize:"14px"}}>ORDER #{keyItem.order_id}</div>
                      <div style={{color:"#83706A", fontFamily:"Poppins-Medium", fontSize:"12px"}}>{this.getOrderedAt(keyItem.ordered_at)}</div>
                    </div>
                  :
                    <div>
                      <span style={{color:"#3356AA", fontFamily:"Poppins-Medium", fontSize:"14px"}}>ORDER #{keyItem.order_id}</span>
                      <span style={{color:"#83706A", fontFamily:"Poppins-Medium", fontSize:"12px", marginLeft:"5px"}}> | {this.getOrderedAt(keyItem.ordered_at)}</span>
                    </div>                    
                  }
                  <div><span style={{color:"#EA5B2F", fontFamily:"Poppins-Medium", fontSize:"14px"}}>{keyItem.restaurant.name}, {keyItem.restaurant.address}</span></div>
                  <div style={{marginTop:"10px"}}><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>{this.itemsCount(keyItem) > 1 ? this.itemsCount(keyItem) + " Items" : this.itemsCount(keyItem) + " Item"}</span></div>
                  <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>Rs. {keyItem.net_total}</span></div>
                  <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px"}}>{keyItem.status_update_logs.ON_THE_WAY !== undefined ? "Delivered by " + keyItem.status_update_logs.ON_THE_WAY.delivery_partner_name : "Picked up from Restaurant"}</span></div>
                  <div><span style={{color:"#2B2624", fontFamily:"Poppins-Medium", fontSize:"12px", textDecoration:"underline", cursor:"pointer"}} onClick={() => {this.toggleOrderDetailsModal1(keyItem)}}>View Order Details</span></div>
                </Grid>
                <Grid item xs={3}>
                  {(keyItem.order_status === "FULFILLED") && <div className={this.state.isMobileScreen ? "ddAddCartBtnM2" : "ddAddCartBtn"} style={{float:"right"}} onClick={() => this.showRatingBlock(keyItem)} >{this.state["ratingDone_" + keyItem.id] ? this.state.isMobileScreen ? "Ratings" : "Show Ratings" : "Rate"}</div>}
                </Grid>
              </Grid>
              <div style={{display: this.state["panel_"+keyItem.id]}}>
                {this.listOrderItems(keyItem)}
              </div>
              <div style={this.state.isMobileScreen ? {padding: "10px 10px 13px 10px"} : {padding: "20px 40px"}} className="ddDeliveryStatusBox">
                {(this.state["panel_"+keyItem.id] === "none" || this.state["ratingDone_" + keyItem.id]) ?
                    keyItem.order_status === "ORDERED" ?
                      <span className="ddStatusSticker" style={{background:"#ffc04d"}}>ORDER PLACED</span>
                      :
                      keyItem.order_status === "UNDER_PREPARATION" ?
                        <span className="ddStatusSticker" style={{background:"#4ea8f2"}}>PREPARING</span>
                        :
                        keyItem.order_status === "READY_FOR_PICKUP" ?
                          <span className="ddStatusSticker" style={{background:"#5c7d80"}}>PACKED</span>
                          :
                          keyItem.order_status === "ON_THE_WAY" ?
                            <span className="ddStatusSticker" style={{background:"#ff7f24"}}>ON THE WAY</span>
                            :
                            keyItem.order_status === "FULFILLED" ?
                              <span className="ddStatusSticker" style={{background:"#C5BBC9"}}>DELIVERED</span>
                              :
                                keyItem.order_status === "DECLINED" ?
                                  <>
                                    <div style={{color:"#2B2624", fontFamily:"Quicksand-SemiBold"}}>Your Order has been Declined!</div>
                                    <div style={{color:"#646060", fontFamily:"Poppins-Regular", fontSize:"10px"}}>Any amount debited will be refunded in 4-5 days.</div>
                                  </>
                                  :
                                    keyItem.order_status === "PAYMENT_INITIATED" ?
                                      <>
                                        <div style={{color:"#2B2624", fontFamily:"Quicksand-SemiBold"}}>Payment Failed!</div>
                                        <div style={{color:"#646060", fontFamily:"Poppins-Regular", fontSize:"10px"}}>Would you like to use a different payment method?</div>
                                      </>
                                      :
                                        <span className="ddStatusSticker" style={{background:"#000000"}}>{keyItem.order_status}</span>
                                        :
                                          this.state["ratingChange_" + keyItem.id] ? 
                                            <div className={this.state.isMobileScreen ? "ddAddCartBtnM2" : "ddAddCartBtn"} onClick={() => this.submitReviews(keyItem)}>Submit</div>
                                          :
                                            <div className={this.state.isMobileScreen ? "ddAddCartBtnM2Disabled" : "ddAddCartBtnDisabled"}>Submit</div>
                }
              </div>
            </div>
        </Grid>
      );
    }
    else{
      listItems = <></>
    }
    return(
        <div style={{padding:"10px 30px"}}>
            {listItems}
        </div>
    );
  }

  submitReviews(order){
    let feedback = "{\"order_id\": \"" + order.order_id + "\",\"feedback\" : {"
    var itemKey = Object.keys(order.items)
    let cntr = 0;
    for(let j = 0; j < itemKey.length; j++){
      if(this.state["rating_"+itemKey[j]] > 0){
        cntr = cntr + 1;
        feedback = feedback + "\"" + itemKey[j] + "\" : {\"review\": \"" + this.state["review_"+itemKey[j]] + "\",\"rating\": \"" + this.state["rating_"+itemKey[j]] + "\"},";
      }
    }
    if(cntr > 0){
      feedback = feedback.substring(0, feedback.length-1);
    }
    feedback = feedback + "}}";
    
    var url = CONSTANTS.hostURL + "api/main_app/orders/rate/";
    var token = localStorage.getItem("auth_token");
    var headers = {
        'Authorization' : 'Bearer ' + token 
    }
    axios.post(url, JSON.parse(feedback),  {
        headers: headers
    }).then((response) => {
        let data = response.data;
        if (data.status === 1){
          this.getOrderStatus();
        }
    });

  }

  closeOrderDetailsModal(){
    this.setState({
      showOrderDetailsModal: !this.state.showOrderDetailsModal,
    })
  }

  closeApplicationWindow(){
    this.setState({
      showApplicationWindow: false,
      selectedCareer: {}
    })
  }

  toggleOrderDetailsModal1(keyItem){
    if(keyItem.order_status === "FULFILLED" || keyItem.order_status === "DECLINED"){
      var token = localStorage.getItem("auth_token");
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/orders/get/?order_id="+keyItem.order_id;
      axios.get(url, {
          headers: headers
      }).then((response) => {
        if (response.data.status === 1){
          let orderData = response.data.data
          this.setState({
            showOrderDetailsModal: !this.state.showOrderDetailsModal,
            orderToShow: orderData,
            restoNameAddress: keyItem.restaurant.name + ", " + keyItem.restaurant.address
          })
        }
      });
    }
    else{
      window.location.href='/orders?order_id='+keyItem.order_id
    }
  }

  showRatingBlock(keyItem){
    if(this.state["panel_"+keyItem.id] === "none"){
      for(let i=0; i<this.state.tempOrderList.length; i++){
        if(Number(this.state.tempOrderList[i].id) === Number(keyItem.id)){
          this.setState({
            ["panel_"+this.state.tempOrderList[i].id]: "block",
            ["ratingChange_" + this.state.tempOrderList[i].id] : false
          })
          var itemKey = Object.keys(this.state.tempOrderList[i].items)
          for(let j = 0; j < itemKey.length; j++){
            this.setState({
              ["rating_"+itemKey[j]]: "0",
              ["review_"+itemKey[j]]: "",
            })
          }
          
          var ratingKey = Object.keys(this.state.tempOrderList[i].ratings);
          var ratingValue = Object.values(this.state.tempOrderList[i].ratings);
          for(let j = 0; j < ratingKey.length; j++){
            this.setState({
              ["rated_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].rating,
              ["reviewed_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].review,
            })
          }

          if(itemKey.length === ratingKey.length){
            this.setState({
              ["ratingDone_" + this.state.tempOrderList[i].id]: true,
            })
          }
          else{
            this.setState({
              ["ratingDone_" + this.state.tempOrderList[i].id]: false,
            })
          }
        }
        else{
          this.setState({
            ["panel_"+this.state.tempOrderList[i].id]: "none",
            ["ratingChange_" + this.state.tempOrderList[i].id] : false
          })
          itemKey = Object.keys(this.state.tempOrderList[i].items)
          for(let j = 0; j < itemKey.length; j++){
            this.setState({
              ["rating_"+itemKey[j]]: "0",
              ["review_"+itemKey[j]]: "",
            })
          }

          ratingKey = Object.keys(this.state.tempOrderList[i].ratings);
          ratingValue = Object.values(this.state.tempOrderList[i].ratings);
          for(let j = 0; j < ratingKey.length; j++){
            this.setState({
              ["rated_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].rating,
              ["reviewed_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].review,
            })
          }

          if(itemKey.length === ratingKey.length){
            this.setState({
              ["ratingDone_" + this.state.tempOrderList[i].id]: true,
            })
          }
          else{
            this.setState({
              ["ratingDone_" + this.state.tempOrderList[i].id]: false,
            })
          }
        }
      }
    }
    else{
      for(let i=0; i<this.state.tempOrderList.length; i++){
        this.setState({
          ["panel_"+this.state.tempOrderList[i].id]: "none",
          ["ratingChange_" + this.state.tempOrderList[i].id] : false
        })
        itemKey = Object.keys(this.state.tempOrderList[i].items)
        for(let j = 0; j < itemKey.length; j++){
          this.setState({
            ["rating_"+itemKey[j]]: "0",
            ["review_"+itemKey[j]]: "",
          })
        }

        ratingKey = Object.keys(this.state.tempOrderList[i].ratings);
        ratingValue = Object.values(this.state.tempOrderList[i].ratings);
        for(let j = 0; j < ratingKey.length; j++){
          this.setState({
            ["rated_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].rating,
            ["reviewed_" + this.state.tempOrderList[i].id + "_" + ratingKey[j]]: ratingValue[j].review,
          })
        }

        if(itemKey.length === ratingKey.length){
          this.setState({
            ["ratingDone_" + this.state.tempOrderList[i].id]: true,
          })
        }
        else{
          this.setState({
            ["ratingDone_" + this.state.tempOrderList[i].id]: false,
          })
        }
      }
    }
  }

  closeRatingsInformationModal() {
    this.setState({
      ratingInformationModal: false,
      ratingModal: true
    });
  };

  closeRatingsModal() {
    this.setState({
      ratingModal: false
    });
  };
  
  showSuccessModal(rating) {
    if (rating.value === 5){
      this.setState({
        isFiveStar: true
      })
    }
    this.setState({
      successModal: true,
      emoji: rating.imageHover,
      ratingNumber: rating.value
    });
  };
  
  closeSuccessModal() {
    this.setState({
      successModal: false,
      isFiveStar: false
    });
  };

  closeReviewsModal() {
    this.setState({
      reviewModal: false
    });
  };

  orderSearch(event){
    if(event.target.value === ""){
      this.setState({
        tempOrderList: this.state.orderList,
        searchBarText: event.target.value
      })
    }
    else{
      this.setState({
        searchBarText: event.target.value
      },() => {
        var searchedOrders = []
        for(let i=0; i<this.state.orderList.length; i++){
          var itemValue = Object.values(this.state.orderList[i].items)
          for(let j = 0; j < itemValue.length; j++){
              if(itemValue[j].name.toLowerCase().includes(this.state.searchBarText.toLowerCase()) || this.state.orderList[i].order_id.toLowerCase().includes(this.state.searchBarText.toLowerCase()) || this.state.orderList[i].restaurant.name.toLowerCase().includes(this.state.searchBarText.toLowerCase())){
                searchedOrders.push(this.state.orderList[i])
              }
          }
        }

        this.setState({
          tempOrderList: [...new Set(searchedOrders)]
        })
      })
    }
  }

  initiatePayment(){
    let url = CONSTANTS.hostURL + "api/main_app/cart/payment/initiate/";
    this.setState({
      paymentInitiated: true
    })
    var token = localStorage.getItem("auth_token");
    let cartDetails = localStorage.getItem('cartDetailsAPI');

    if (cartDetails !== null && token != null){
      var headers = {
        'Authorization' : 'Bearer ' + token 
      }
      let req = {
        'cart_id': JSON.parse(cartDetails).id
      };
      axios.post(url, req,  {
          headers: headers
      }).then((response) => {
        var responseData = response.data;
        if(responseData.status === 1){
          window.location.assign(responseData.data.payment_link)
        }
        else{
          if(responseData.errors.code === "STORE_OFFLINE"){
            this.toggleConfirmationBox("1436:" + responseData.errors.message);
          }
          this.setState({
            paymentInitiated: false
          })
        }
      });
    }
  }

  toggleConfirmationBox(confirmationBoxMessage) {
    if(confirmationBoxMessage === "Clear cart and go home"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        window.location.href="/home";
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
          cartDetailsAPI = JSON.parse(cartDetailsAPI);
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetailsAPI.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                localStorage.removeItem('CartDetails');
                localStorage.removeItem('cartDetailsAPI');
                this.setState({
                  itemsInCart: 0,
                  cartChanged: true
                })
              }
          }).catch((error) => this.setState({}));
        } else {
          localStorage.removeItem('CartDetails');
          this.setState({
            itemsInCart: 0,
            cartChanged: true
          })
        }
      })
      return "Yes";
    }
    else if(confirmationBoxMessage === "Refresh Delivery Type"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        this.getUserAddress();
        this.getCoupons();
        this.getCartDetails();
        this.getCartList();
      })
    }
    
    else{
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox,
        confirmationBoxMessage: confirmationBoxMessage
      })
      return "";
    }
  }

  careersList(){
    const listItems = this.state.availableVacancies.map((keyItem, index) =>
      <Grid item key={"Vacancies_"+index} xs={4}>
        <div className='ddCareerBoxes'>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <span style={{fontFamily:"Poppins-Regular", color:"#83706A", fontSize:"10px"}}>{keyItem.domain_name}</span>
            </Grid>
            <Grid item xs={12}>
              <span style={{fontFamily:"Poppins-SemiBold", color:"#2B2624", fontSize:"14px", letterSpacing:"-1px"}}>{keyItem.title}</span>
            </Grid>
            <Grid item xs={12} style={{lineHeight:"0.7", maxHeight:"50px", overflowY:"hidden"}}>
              <span style={{fontFamily:"Poppins-Regular", color:"#5E4B45", fontSize:"10px"}}>{keyItem.description.length > 250 ? keyItem.description.substring(0, 245 ) + "....." : keyItem.description}</span>
            </Grid>
            <Grid item xs={12} style={{marginTop:"10px"}}>
              <span className="ddCareersTag" style={keyItem.role_type_name.includes("Part") ? {color:"#C81E70", border:"#FFCEE6 1px solid"} : {color:"#E4592E", border:"#F2E7E4 1px solid"}}>{keyItem.role_type_name}</span>
            </Grid>
            <Grid item xs={12}>
              <span className="ddCarrerApplyNowBtn" onClick={() => this.showCareerDetailsToApply(keyItem)}>Details</span>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
    return(
      <>
        {listItems}
      </>
    );
  }

  showCareerDetailsToApply(keyItem){
    this.setState({
      showApplicationWindow: true,
      selectedCareer: keyItem
    })
  }

  render() {
    return (
      <div>
        <div className="loadingGif" style={this.state.showLoading ? {visibility:"visible", opacity: '1'} : {visibility:"hidden", opacity: '0'}}>
          <img src={DaiDishLoading1} alt="DaiDish Loading..." style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"40%", marginTop:"0%"}}/>
        </div>
        <Header 
            toggleUserOptions={this.toggleUserOptions} 
            toggleSearchModal={this.toggleSearchModal}
            showAuthPopupModal={this.showAuthPopupModal}
            searchPresent={true}
            itemsInCart={this.state.itemsInCart}
            updateCartModalDisplay={this.updateCartModalDisplay}
            updateCartChangedValue={this.updateCartChangedValue}
        />
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <UserCart 
          cartChanged={this.state.cartChanged}
          showCart={this.state.showCart}
          updateCartChangedValue={this.updateCartChangedValue}
          updateCartModalDisplay={this.updateCartModalDisplay}
          itemsInCart={this.state.itemsInCart}
          restaurantId={-1}
          defaultAddresses={this.state.defaultAddresses}
          addresses={this.state.addresses}
        />
        {
        this.state.isMobileScreen ? 
          <Grid container spacing={0} style={{paddingTop:'90px', width:"100%", minHeight:"40vw", background:"#FFFBE8"}}>
            <Grid item xs={12}>
              <div style={{fontFamily:"Quicksand-Semibold", fontSize:"25px", padding: "20px 30px 0px 30px"}}>If you love food like we do </div>
              <Grid item xs={12} style={{margin:"10px 30px"}}>
                  <div className="ddFoodSearchDiv">
                      <SearchIcon className="ddSearchIcon"/>
                      <input
                          type="text"
                          className="ddSearchTextBox"
                          placeholder="Search for keywords"
                          value={this.state.searchBarText}
                          onChange={this.orderSearch}
                      />
                      <span style={{float:"right", cursor:"pointer"}}>
                          <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"3px", marginRight:"5px"}}></span>
                          <FiFilter style={{color:"#EA5B2F", margin:"0px 5px", position:"relative", top:"6px"}}/> <span style={{fontFamily:"Poppins-Medium", color:"#3C3C3C", position:"relative", top: "4px", paddingRight:"5px"}}>Filter</span>
                      </span>
                  </div>
              </Grid>
              {this.displayOrderList()}
            </Grid>
          </Grid>
        :
          <Grid container spacing={0} style={{paddingTop:'90px', width:"100%", minHeight:"40vw", background:"#FFFBE8"}}>
            <Grid item xs={12} style={{padding: "0px 160px"}}>
              <div className="ddCareersHeader">
                <div style={{fontFamily:"Quicksand-Semibold", fontSize:"25px"}}>If you love food like we do</div>
                <div style={{fontFamily:"Quicksand-Bold", fontSize:"70px"}}>Join team <span style={{color:"#E4592E"}}>foodies!</span></div>
                <div style={{fontFamily:"Quicksand-SemiBold", fontSize:"17px", width:"30%", textAlign:"center", color:"#5E4B45", marginTop:"15px"}}>Our mission is to “place description here or metadata relevant for the title above”</div>
                <div style={{marginTop:"15px"}}>
                  <img src={Vector1} alt="Vector1" className='ddCareerVectorImages'/>
                  <img src={Vector2} alt="Vector2" className='ddCareerVectorImages'/>
                  <img src={Vector3} alt="Vector3" className='ddCareerVectorImages'/>
                </div>
              </div>
              <Grid item xs={12} style={{padding:"25px 30px", background:"#FFFFFF", borderRadius:"10px", border:"#F7F5ED 1px solid", boxShadow:"0px 3px 80px #F5F0D9", marginBottom:"40px"}}>
                  <div style={{fontFamily:"Quicksand-Semibold", fontSize:"20px", color:"#2B2624", marginBottom:"10px"}}>Career opportunities ({this.state.availableVacanciesCount})</div>
                  <div className="ddFoodSearchDiv">
                      <SearchIcon className="ddSearchIcon"/>
                      <input
                          type="text"
                          className="ddSearchTextBox"
                          placeholder="Search for career opportunities"
                          // value={this.state.searchBarText}
                          // onChange={this.orderSearch}
                      />
                      <span style={{float:"right", cursor:"pointer"}}>
                          <span style={{borderLeft:"1px solid #E6E6E6", height:"100%", position:"relative", top:"3px", marginRight:"5px"}}></span>
                          <FiFilter style={{color:"#EA5B2F", margin:"0px 5px", position:"relative", top:"6px"}}/> <span style={{fontFamily:"Poppins-Medium", color:"#3C3C3C", position:"relative", top: "4px", paddingRight:"5px"}}>Filter</span>
                      </span>
                  </div>
                  <div style={{marginTop:"20px"}}>
                    <Grid container spacing={2}>
                      {this.careersList()}
                    </Grid>
                  </div>
              </Grid>
            </Grid>
          </Grid>
        }
        <OrderDetails
          toggleOrderDetailsModal={this.toggleOrderDetailsModal1}
          closeOrderDetailsModal={this.closeOrderDetailsModal}
          itemsCount={this.itemsCount}
          showOrderDetailsModal={this.state.showOrderDetailsModal}
          orderToShow={this.state.orderToShow}
          restoNameAddress={this.state.restoNameAddress}
          getCustomisationText={this.getCustomisationText}
          isMobileScreen={this.state.isMobileScreen}
        />
        <ApplicationWindow
          closeApplicationWindow={this.closeApplicationWindow}
          showApplicationWindow={this.state.showApplicationWindow}
          selectedCareer={this.state.selectedCareer}
          isMobileScreen={this.state.isMobileScreen}
        />
        <AuthPopup
          showAuthPopupModal={this.state.authPopupModal}
          closeAuthPopupModal={this.closeAuthPopupModal}
          setActiveForm={this.setActiveForm}
          activeForm={this.state.activeForm}
        />
        <ConfirmationBox
          confirmationBoxMessage={this.state.confirmationBoxMessage}
          showConfirmationBox={this.state.showConfirmationBox}
          toggleConfirmationBox={this.toggleConfirmationBox}
        />
        <LogoutConfirm
          showLogoutModal={this.state.logoutModal}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Footer/>
      </div>
    );
  }
}

export default withRouter(Careers);
