import React from 'react';
import { Grid, Button } from '@material-ui/core';
import image01 from '../../assets/images/01_1.gif';
import '../../assets/styles/AboutUs.css';
import { Component } from 'react';

// Description of the website 
class Segment1 extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            gridSize: 4,
            buttonID: "signup-button-content",
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ isMobileScreen: false });
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render () {
        if (this.state.isMobileScreen){
            return (
                <div>
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch" style={{background: "#F9D543"}}>
                        <div style={{margin: "auto"}}>
                            <h1 className="topText" style={{fontSize: '28px', padding: '0px 8vw' }}>
                                <br/><span style={{color: "#3356AB", marginLeft:'0'}}>Indulge in Bangalore's Best Food Delivered to Your Doorstep!</span>
                            </h1>
                            <div className="ContentDescriptionText" style={{fontSize: '20px', marginTop: '10px' }}>
                                <span style={{color: "#EA5B2F"}}>No, not geographic locations that sell it.<br/> We love food.</span><br/><br/><br/>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch" style={{marginTop : '-10%'}}>
                        <img style={{width:'60vw'}} src={image01} alt="image01"/>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                        <div>
                            <div>
                                <div className="DescriptionTaglineDetails" style={{textAlign: 'center'}}>
                                    With the number of eateries to choose from in Bangalore, it's easy to get lost when it comes to food delivery. You want to ensure your food order is nothing short of the best. At DaiDish, we understand the importance of indulging in Bangalore's finest culinary delights. Our curated selection guarantees an unforgettable food delivery experience—we bring the best food straight to your doorstep.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center">
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            this.props.toggleAuthModal();
                            this.props.setActiveForm("register");
                        }} id={this.state.buttonID}>
                            SIGN UP NOW
                        </Button>
                    </Grid>
                </div>
            )
        }
        else{
            return (
                <div>
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch" style={{background: "#F9D543"}}>
                        <div style={{margin: "auto"}}>
                            <h1 style={{ width: "600px", maxWidth: "95vw", fontSize: '2.8vw' }} className="topText">
                                <br/><div style={{color: "#3356AB", marginLeft:'0', textAlign: "center"}}>Indulge in Bangalore's Best Food Delivered to Your Doorstep!</div>
                            </h1>
                            <div className="ContentDescriptionText" style={{fontSize: '1.8vw', marginLeft:'5%'}}>
                                <span style={{color: "#EA5B2F"}}>No, not geographic locations that sell it.<br/> We love food.</span><br/><br/><br/>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch" style={{marginTop : '-6%', marginBottom:'30px'}}>
                        <Grid container item xs={6} direction="column" justifyContent="center">
                            <div className="ContentDescription">
                                <div>
                                    <div className="ContentDescriptionDetails">
                                    With the number of eateries to choose from in Bangalore, it's easy to get lost when it comes to food delivery. You want to ensure your food order is nothing short of the best. At DaiDish, we understand the importance of indulging in Bangalore's finest culinary delights. Our curated selection guarantees an unforgettable food delivery experience—we bring the best food straight to your doorstep.
                                    </div>
                                    <Button onClick={() => {
                                        window.scrollTo(0, 0);
                                        this.props.toggleAuthModal();
                                        this.props.setActiveForm("register");
                                    }} id={this.state.buttonID}>
                                        SIGN UP NOW
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={6} direction="column" justifyContent="center" style={{overflow:  'visible'}}>
                            <img style={{width:'42vw'}} src={image01} alt="image01"/>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

export default Segment1;