import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import rating_information1 from '../../assets/images/rating_information1.png';
import rating_information2 from '../../assets/images/rating_information2.png';
import rating_information3 from '../../assets/images/rating_information3.png';


class DishRatingInformationModal extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.ratingContainer = this.ratingContainer.bind(this);
    }

    closeModalHandler() {
        this.props.closeRatingsInformationModal();
    }

    ratingContainer() {
        return (
            <div className="RatingInformationContainer">
                <div className="RatingInformationTop">
                    <div className="RatingInformationImagesContainer">
                        <div className="RatingInformationLeftImage">
                            <div style={{margin: "auto", width: "14vh"}}>
                                <img className="RatingInformationImage LeftImage" src={rating_information1} alt="Rating Information 1"/>
                            </div>
                        </div>
                        <div className="RatingInformationCenterImage">
                            <div style={{margin: "auto", width: "20vh"}}>
                                <img className="RatingInformationImage CenterImage" src={rating_information2} alt="Rating Information 1"/>
                            </div>
                        </div>
                        <div className="RatingInformationRightImage">
                            <div style={{margin: "auto", width: "14vh"}}>
                                <img className="RatingInformationImage RightImage" src={rating_information3} alt="Rating Information 1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RatingInformationHeader">
                    Rate only <span style={{color: "#EA5B2F"}}>'The Dish' !</span>
                </div>
                <div className="RatingInformationSubHeader">
                    Daidish is all about Dishes. Please <span style={{color: "#EA5B2F"}}>don't rate the Service or Ambience or Delivery</span>
                </div>
                <div style={{flex: "1"}}></div>
                <div onClick={() => this.closeModalHandler()} className="DishRateModalSubmitButtonContainer" style={{margin: "4vh auto"}}>
                    Okay
                </div>
            </div>
        )
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.showRatingsInformationModal){
            return null;
        }
        return(
            <div className="RatingModal">
                <div style={{margin: "auto", maxWidth: "600px", width: "92vw"}} onClick={(e) => this.handleModalClick(e)} className="">
                    {/* <div onClick={this.closeModalHandler} title="Close" className="close">X</div> */}
                    {/* <div className="DishImageModalContainer">
                        <img className="DishImageModal" src={this.props.dish.image} alt="Dish Pic" />
                        <div className="DishImageModalBlur"></div>
                            <div className="DishImageModalText">
                                <span className="RedDotModal"></span>
                                <div  className="DishNameModal">
                                    {this.props.dish.name}<span style={{fontSize: "12px"}}> | ₹ {this.props.dish.cost}
                                    </span>
                                </div>
                            <div className="DishRestaurantModal">{this.props.dish.restaurant}</div>
                        </div>
                    </div> */}
                    {this.ratingContainer()}
                </div>
            </div>
        )
    }
}

export default DishRatingInformationModal;