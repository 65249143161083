import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Restaurants.css';
import best_dish from '../../assets/images/best_dish.png';
import BestDishRibbon from '../../assets/images/bestdishribbon.png';
import BestDishStar from '../../assets/images/bestDishStar.png';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { Star } from '@material-ui/icons';
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiStarFill } from "react-icons/ri";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonLoading from '../../assets/images/btnLoading.gif';

class BestDish extends Component {

    constructor(props){
        super(props);
        // this.bestDishDivRef = React.createRef();
        this.toggleRateHover = this.toggleRateHover.bind(this);
        this.toggleImageHover = this.toggleImageHover.bind(this);
        this.toggleReviewHover = this.toggleReviewHover.bind(this);
        this.toggleLikeButton = this.toggleLikeButton.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.state = { 
            isMobileScreen: false,
            customisation_set: ""
        };
    }

    componentWillMount() {
        this.setState({
            dish: {
                image: best_dish, 
                id: 1, 
                name: "Chicken Cheese Burger", 
                restaurant: "Sanky's Burger House", 
                cost: 300, 
                imageHover: false, 
                rateHover: false, 
                reviewHover: false, 
                liked: false
            }
        });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.setState({
            customisation_set: ""
        })

        // console.log(this.props.bestDishes)
        // console.log(this.props.dishesAddingState)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ["open_"+nextProps.popDishId] : nextProps.popDishState
        })
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    toggleImageHover(index) {
        this.props.toggleImageHover(index);
    }

    toggleRateHover(index) {
        this.props.toggleRateHover(index);
    }

    toggleReviewHover(index) {
        this.props.toggleReviewHover(index);
    }

    toggleLikeButton(index) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.toggleLikeButton(index);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showRatingsModalHandler(dish) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    getDescription(description, dishName){
        // let screen_width = window.innerWidth - 20;
        // var maxLength = 190 + Math.floor(((screen_width - 360)/3));
        if (description !== "" && description !== null){
            // if (description.length < maxLength){
            //     return description;
            // } else {
            //     if (this.state.isMobileScreen){
            //         return description.slice(0, maxLength) + "..";
            //     } else {
            //         return description;
            //     }
            // }
            return description;
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        // let screen_width = window.innerWidth - 20;
        var maxLength = 230;
        if (this.state.isMobileScreen){
            if (description !== "" && description !== null){
                if (description.length > maxLength){
                    return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                    // if (this.state.gridSize === 12){
                    //     return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                    // } else {
                    //     return <div onMouseEnter={() => this.toggleImageHover(dish.id - 1)} onMouseLeave={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">Read more</div> ;
                    // }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    discountCard(dish){
        if (dish.discount_present && dish.discount_present === true){
            return (
                this.state.isMobileScreen ? 
                    <span className="RibbonMBP">
                        <div className="RibbonTextM">{dish.discount} % off</div>
                    </span>
                    :
                    <span className="Ribbon">
                        <div className="RibbonText">{dish.discount} % off</div>
                    </span>
            )
        } else {
            return null;
        }
    }

    onChange(customItem){
        this.setState({
            customisation_set: customItem
        })
    }

    customisationList(){
        if(this.props.temp_customizations !== null){
            const customizations = this.props.temp_customizations;
            let listItems = null;
            let customizationsList = [];
            
            if(customizations.length > 1){
                for(var a=0; a<customizations.length; a++){
                    customizationsList.push(this.props.getCustomisationText(customizations[a]));
                }
                listItems = customizationsList.map((customItem, index) =>
                    <div key={customItem + "_" + index}>
                        <FormControlLabel 
                            checked={this.state.customisation_set === customizations[index] ? true: false}
                            onChange={this.onChange.bind(this,customizations[index])}
                            name={"custom_"+index} 
                            control={<Radio 
                                color="#EA5B2F"
                                size="small"/>} 
                            label={<span style={{ fontSize: '13px', fontFamily:'Poppins-Regular'}}>{customItem}</span>}
                        />                     
                    </div>
                );
            }
            else{
                listItems = this.props.getCustomisationText(customizations[0]);
            }
            
            return (
                <div className="ddCustomisationText2">{listItems}</div>
            );
        }
        else{
            return (
                <div className="ddCustomisationText2">No DATA</div>
            );
        }
    }

    popOverDetails(dishID){
        return(
            this.props.repeatCustomisationMessage === "Repeat Last Customisation?" ?
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.props.temp_customizations.length > 1 ?
                            this.state.customisation_set !== "" ? 
                                <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                    this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.state.customisation_set));
                                }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                            :
                                <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                    Select Customisation
                                </span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.props.temp_customizations[0]));
                            }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                        }
                        <span style={{marginLeft:"10px"}} className={this.state.isMobileScreen ? "ddPopOverIllChooseBtnM" : "ddPopOverIllChooseBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("No","");
                            }}>I'll Choose
                        </span>
                    </div>
                </div>
            :
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.state.customisation_set !== "" ? 
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Remove", JSON.stringify(this.state.customisation_set));
                            }}>Remove Selected</span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                Select Customisation
                            </span>
                        }
                        <span style={{marginLeft:"10px"}} className="ddPopOverIllChooseBtn" onClick={() => {
                                this.props.toggleRepeatCustomisation("","");
                            }}>Cancel
                        </span>
                    </div>
                </div>
        );
    }

    getIndex(dish){
        for(let i=0; i<this.props.dishesAddingState.length; i++){
            if(this.props.dishesAddingState[i].dishID === dish.dishId)
                return i
        }
    }

    checkAddToCart(dish) {
        var token = localStorage.getItem("auth_token");
        let itemsPresent = false;
        let dishQuantity = 0;
        if (token !== null && token !== ""){
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI !== null) {
                let cartItems = JSON.parse(cartDetailsAPI)["items"];
                var itemKey = Object.keys(cartItems);
                var itemValue = Object.values(cartItems);
                for (var i=0; i<itemValue.length; i++){
                    if (Number(itemKey[i]) === Number(dish.dishId)){
                        dishQuantity = dishQuantity + itemValue[i]["quantity"];
                        itemsPresent = true;
                        //break;
                    }
                } 
            }
            if (dishQuantity > 0) {

            }
        }

        if(this.props.restaurant.is_accepting_orders){
            if (!itemsPresent) {
                return (
                    <>
                        {/* <span className="ddAddCartBtn" onClick={() => this.props.addToCartAction(dish, "add", false)}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span> */}
                        {dish.in_stock === true ?
                            <>
                                {!this.props.dishesAddingState[this.getIndex(dish)].addingState ? 
                                    <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"} onClick={() => this.props.addToCartAction(dish, "add", false)} disabled><RiShoppingCart2Line className="ddAddCartBtnIcon" /> Add</span>
                                    :
                                    <img src={ButtonLoading} alt="Complete Order & Pay" style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"10px", left:"82px", height:"20px"} : {width:"70px", position:"relative", top:"12px", left:"20px", height:"20px"}}/>
                                }
                            </>
                        : 
                            <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px", padding:"6px 8px 4px 8px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}>{this.state.isMobileScreen ? <>Out of Stock</> : <><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</>}</span>
                        }
                        {dish.customizations !== null && <span className="ddCustomisableTag" style={{position:"absolute", top:"38px", left: this.state.isMobileScreen ? "172px" : "128px"}}>Customisable</span>}
                    </>
                )
            } else {
                var bestDishDivRef = React.createRef();
                return (
                    <>
                    {dish.in_stock === true ?
                        <div ref={bestDishDivRef} style={{position:"relative", padding: "4px 0px 3px 0px", marginLeft: this.state.isMobileScreen ? "90px" : "15px", top:"4px", width:"75px", display:"flex", flexWrap:"wrap", justifyContent:"center", alignItems:"center"}} className="cart-Item-Controls">
                            {this.props.dishesAddingState[this.getIndex(dish)].addingState ?
                                <img src={ButtonLoading} alt="loading.." style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"1px", height:"20px"} : {width:"63px", position:"relative"}}/>
                                :
                                <>
                                    <RemoveIcon style={{ fontSize: 14 }} className="remove-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={() => {
                                            this.props.addToCartAction(dish, "remove", true, bestDishDivRef.current)}}/>
                                    <span style={{fontSize: "14px"}} className="item-QtyTemp">{dishQuantity}</span>
                                    <AddIcon style={{ fontSize: 14 }} className="add-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={() => {
                                            this.props.addToCartAction(dish, "add", true, bestDishDivRef.current)}}/>
                                </>
                            }
                            <Popover
                                id={this.props.popUpId}
                                open={this.props.popDishState && (this.props.popUpId === "anchorEl_"+dish.dishId+"_"+dish.id)}
                                anchorEl={this.props.popOverAnchorEl}
                                onClose={() => this.props.handlePopOverClose(dish)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {this.popOverDetails(dish.id)}
                            </Popover>
                        </div>
                    :
                        this.state.isMobileScreen ?
                            <span style={{marginLeft:"70px", top:"5px", height:"33px", padding:"6px 8px 4px 8px"}} className="ddAddCartBtnDisabledM">Out of Stock</span>
                        :
                            <span className="ddAddCartBtnDisabled"><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    }
                    {dish.customizations !== null && <span style={this.state.isMobileScreen ? {top:"34px", left: dish.in_stock === true ? "-69px" : "-86px"} : {left: dish.in_stock === true ? "-73px" : "-89px", top:"34px"}} className="ddCustomisableTagAdded">Customisable</span>}
                    </>
                )
            }
        }
        else{
            return(
                <>
                    <span style={this.state.isMobileScreen ? {marginLeft:"70px", top:"5px", height:"33px", padding:"4px 20px 5px"} : {}} className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    {dish.customizations !== null && <span className="ddCustomisableTag" style={{position:"absolute", top:"38px", left: this.state.isMobileScreen ? "172px" : "128px"}}>Customisable</span>}
                </>
            )
        }
    }

    render() {
        // var yellowLineStyle = {};
        // var likeButtonStyle = {};
        if (!this.props.bestDishReady){
            return (
                <Grid alignItems="center" justifyContent="center" container item xs={12}>
                    <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                        Loading Best Dish...
                    </div>
                </Grid>
            );
        } else {
            var likeButtonStyle = [];
            var descriptionOverlayStyle = [];
            for (var l=0; l<this.props.bestDishes.length; l++){
                likeButtonStyle.push("");
                if (this.props.bestDishes[l].liked === true) {
                    likeButtonStyle[l] = "LikeNewButton LikeNewButtonActive favme dashicons dashicons-heart";
                } else {
                    likeButtonStyle[l] = "LikeNewButton favme dashicons dashicons-heart";
                }
                descriptionOverlayStyle.push("");
                if (this.props.bestDishes[l].imageHover === true){
                    descriptionOverlayStyle[l] = {display: 'none'};
                } else {
                    descriptionOverlayStyle[l] = {display: 'none'};
                }
            }                                
            return (
                this.state.isMobileScreen ? 
                <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img style={{height : '35px'}} src={BestDishStar} alt="BestDishStar"/>
                            <span className="ddbestDishZoneTextM">Best Dish Zone</span>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{fontFamily:"Poppins-Medium", position:"relative", left:"10px", fontSize:"14px"}}>
                        {/* <span>Curate the<span style={{color:"#3356AA"}}>&nbsp;Best Dishes</span> in your area. We believe that you should 'eat right every time' and that you should be able to decide your orders<span style={{color:"#3356AA"}}>&nbsp;in seconds.</span></span>  */}
                        <span>{this.props.bestDishDescription}</span>
                    </Grid>
                    {this.props.bestDishes.map((dish, index) => {
                        if (dish.vegDisplay && dish.searchDisplay){
                            return(
                                <div className="ddBestDishCardWrapperM" key={index}>
                                    <img className="ddBestDishRibbon" src={BestDishRibbon} alt="BestDishRibbon" style={{width:"140px", top:"-22px"}}/>
                                    <div style={{display: 'none'}} className="DishDescriptionOverlay">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                                {dish.name}
                                            </div>
                                            <CloseIcon style={{color: "black", opacity: this.state.gridSize === 12 ? "1" : "1", cursor: "pointer"}} /*onClick={() => this.toggleImageHover(this.props.dish.id - 1)}*/ />
                                        </div>
                                        <div className="DishDescriptionOverlayText">
                                            {(dish.description === null || dish.description === "") ? dish.name : dish.description}
                                        </div>
                                    </div>
                                    <div className="ddBestDishCardTopContainerM">
                                        {this.discountCard(dish)}
                                        <div style={{display:"block", boxShadow: "10px 10px 0px #E5E5E5", borderRadius: "20px"}} className="ddDishImageOverlayM">
                                            <div><img onLoad={this.checkAspectRatio} src={dish.image} alt="DishImage" className="DishImageM"/></div>
                                            <div className="DishRatingsSuperContainer" style={{cursor: "pointer", borderTopRightRadius:"20px", borderBottomLeftRadius:"20px"}} 
                                                //onClick={() => this.showReviewsModalHandler(dish)}
                                            >
                                                <div className="DishRatingsContainer">
                                                    <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                                    <span style={{fontFamily: "Poppins-SemiBold"}}>{dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58"}}> ({dish.no_rating})</span>
                                                </div>
                                            </div>
                                            <div className="LikeButtonContainerSuper" style={{top:"15px"}}>
                                                <div className="LikeButtonContainer">
                                                    <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                                        <span onClick={() => this.toggleLikeButton(dish)} className={likeButtonStyle[dish.id-1]}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ddBestDishCardBottomContainerMobile">
                                        <div className="DishDetailsRow">
                                            <div>
                                                {dish.is_veg === true ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                            </div>
                                            <div className="DishImageText">
                                                <div className="DishName">{dish.name}</div>
                                                <div  className="DishName"> 
                                                    Rs.{dish.cost}
                                                </div>
                                                <div style={{flex: "1 1 auto"}}>
                                                    <div className="DishDescription" style={{height:"100px"}}>
                                                        { this.getDescription(dish.description, dish.name) }
                                                        { this.getShowMore(dish.description, dish) }
                                                    </div>
                                                </div>
                                                {dish.in_stock !== true && <div style={{position:"absolute", bottom:"60px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                                <div className={this.state.isMobileScreen ? "ddDishCardActionsContainerM" : "ddDishCardActionsContainer"} style={{position: "absolute", width: "400px", display:"flex", flexDirection:"row"}}>
                                                    <div onClick={() => this.showRatingsModalHandler(dish)}>
                                                        <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                    </div>

                                                    {this.checkAddToCart(dish)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        else
                            return null
                    })}
                </div>
                :
                <div className="BestDishSuperContainer" style={{marginTop: "30px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <img style={{height : '60px'}} src={BestDishStar} alt="BestDishStar"/>
                            <span className="ddbestDishZoneText">Best Dish Zone</span>
                        </Grid>
                        <Grid item xs={6}>
                            <hr className="ddHrStyle" style={{ borderTop: "5px solid #3356AA"}}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{fontFamily:"Poppins-Medium", width:"600px", position:"relative", left:"80px"}}>
                        {/* <span>Curate the<span style={{color:"#3356AA"}}>&nbsp;Best Dishes</span> in your area. We believe that you should 'eat right every time' and that you should be able to decide your orders<span style={{color:"#3356AA"}}>&nbsp;in seconds.</span></span>  */}
                        <span>{this.props.bestDishDescription}</span>
                    </Grid>
                    
                    {this.props.bestDishes.map((dish, index) => {
                        if (dish.vegDisplay && dish.searchDisplay){
                            return(
                                <div className="ddBestDishCardWrapper"  key={index}>
                                    <div className="BestDishContainer" style={{height:"365px"}}>
                                        <Grid container spacing={2}>
                                            {index === 1 ?
                                                <>
                                                    <Grid item xs={6} style={{paddingLeft:"60px"}}>
                                                        <div className="BestDishDescription" style={{width:"100%"}}>
                                                            <div className="DishDetailsRow" style={{padding: "10px"}}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} style={{padding: "15px 0px 0px 20px"}}>
                                                                        <span style={{left:"15px", position:"absolute"}}>{dish.choice === "V" ? <FiberManualRecordIcon className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 24 }} className="nonVegTag-BestDish"/>}</span>
                                                                        <div style={{left:"20px", top: "-7px", position:"relative"}}>
                                                                            <div className="BestDishName" style={{width:"515px"}}>
                                                                                {dish.name}
                                                                            </div>
                                                                            {/* <div className="BestDishRestaurant">{dish.restaurant}</div> */}
                                                                            <div className="DishName">
                                                                                Rs.{dish.cost}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{flex: "1 1 auto", paddingLeft:"20px"}}>
                                                                            <div style={{height: "102px", textOverflow: "ellipsis", overflow: "hidden"}} className="BestDishDetails DishDescription">
                                                                                { this.getDescription(dish.description, dish.name) }
                                                                                { this.getShowMore(dish.description, dish) }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{position:"absolute", bottom: dish.in_stock === true ? "-58px" : "-20px", display:"flex", flexDirection:"row"}}>
                                                                            {!dish.in_stock && <div style={{position:"absolute", bottom:"42px", left:"20px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                                                            <span className="ddDishCardRateButton" style={{marginLeft:"7px"}} onClick={() => this.showRatingsModalHandler(dish)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                                            {this.checkAddToCart(dish)}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center"}}>
                                                        <div className="BestDishImageContainer" style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center", overflow:"visible", height:"300px"}}>
                                                            <img className="ddBestDishRibbon" src={BestDishRibbon} alt="BestDishRibbon" style={{top:"-30px", width:"180px"}}/>
                                                            <div style={{width:"300px", height:"300px", boxShadow: "-10px 10px 0px #E5E5E5", border: "1px solid #0000001A", borderRadius: "20px", position:"relative"}}>
                                                                {this.discountCard(dish)}
                                                                <img className="BestDishImage" style={{borderRadius: "20px"}} src={dish.image} alt="bestDish"/>
                                                                <div className="LikeButtonContainerSuper_BestDish1" style={{top:"8px", right:"5px", left:"auto"}}>
                                                                    <div className="LikeButtonContainer">
                                                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                                                            <span onClick={() => this.toggleLikeButton(dish.id - 1)} className={likeButtonStyle[dish.id-1]}></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="DishRatingsSuperContainer" style={{cursor:"pointer", borderTopRightRadius:"20px", borderBottomLeftRadius:"20px", bottom:"-2px", left:"-1px"}}>
                                                                    <div className="DishRatingsContainer" 
                                                                        //onClick={() => this.showReviewsModalHandler(dish)}
                                                                    >
                                                                        <Star style={{color: "#0F9917", fontSize: "18px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                                                        <span style={{fontFamily: "Poppins-SemiBold", fontSize: "18px"}}>{dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize: "15px"}}> ({dish.no_rating})</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </>
                                            :
                                                <>
                                                    <Grid item xs={6} style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center"}}>
                                                        <div className="BestDishImageContainer" style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignContent:"center", overflow:"visible", height:"300px"}}>
                                                            <img className="ddBestDishRibbon" src={BestDishRibbon} alt="BestDishRibbon" style={{top:"-30px", width:"180px"}}/>
                                                            <div style={{width:"300px", height:"300px", boxShadow: "10px 10px 0px #E5E5E5", border: "1px solid #0000001A", borderRadius: "20px", position:"relative"}}>
                                                                {this.discountCard(dish)}
                                                                <img className="BestDishImage" style={{borderRadius: "20px"}} src={dish.image} alt="bestDish"/>
                                                                <div className="LikeButtonContainerSuper_BestDish1" style={{top:"8px", right:"5px", left:"auto"}}>
                                                                    <div className="LikeButtonContainer">
                                                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                                                            <span onClick={() => this.toggleLikeButton(dish.id - 1)} className={likeButtonStyle[dish.id-1]}></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="DishRatingsSuperContainer" style={{cursor:"pointer", borderTopRightRadius:"20px", borderBottomLeftRadius:"20px", bottom:"-2px", left:"-1px"}}>
                                                                    <div className="DishRatingsContainer" 
                                                                        //onClick={() => this.showReviewsModalHandler(dish)}
                                                                    >
                                                                        <Star style={{color: "#0F9917", fontSize: "18px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                                                        <span style={{fontFamily: "Poppins-SemiBold", fontSize: "18px"}}>{dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize: "15px"}}> ({dish.no_rating})</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="BestDishDescription" style={{width:"100%"}}>
                                                            <div className="DishDetailsRow" style={{padding: "10px"}}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} style={{padding: "15px 0px 0px 20px"}}>
                                                                        <span style={{left:"15px", position:"absolute"}}>{dish.choice === "V" ? <FiberManualRecordIcon className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 24 }} className="nonVegTag-BestDish"/>}</span>
                                                                        <div style={{left:"20px", top: "-7px", position:"relative"}}>
                                                                            <div className="BestDishName" style={{width:"515px"}}>
                                                                                {dish.name}
                                                                            </div>
                                                                            {/* <div className="BestDishRestaurant">{dish.restaurant}</div> */}
                                                                            <div className="DishName">
                                                                                Rs.{dish.cost}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{flex: "1 1 auto", paddingLeft:"20px", width:"90%"}}>
                                                                            <div style={{height: "102px", textOverflow: "ellipsis", overflow: "hidden"}} className="BestDishDetails DishDescription">
                                                                                { this.getDescription(dish.description, dish.name) }
                                                                                { this.getShowMore(dish.description, dish) }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{position:"absolute", bottom: dish.in_stock === true ? "-58px" : "-20px", display:"flex", flexDirection:"row"}}>
                                                                            {!dish.in_stock && <div style={{position:"absolute", bottom:"42px", left:"20px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                                                            <span className="ddDishCardRateButton" style={{marginLeft:"7px"}} onClick={() => this.showRatingsModalHandler(dish)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                                            {this.checkAddToCart(dish)}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </div>
                                </div>
                            )
                        }
                        else
                            return null;
                    })}
                </div>
            ) 
        }
    }
}

export default BestDish;