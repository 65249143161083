import axios from 'axios';
import React, { Component } from 'react';
import update from 'react-addons-update';
import LogoutConfirm from '../components/Commons/LogoutConfirm';
import CONSTANTS from '../config/constants';
import UserOptions from '../components/Dishes/UserOptions';
import Header from '../components/Dishes/Header';
import beverages from '../assets/images/beverages.svg';
import appetizers from '../assets/images/appetizers.svg';
import breakfast from '../assets/images/breakfast.svg';
import dessert from '../assets/images/dessert.svg';
import main_course from '../assets/images/main_course.svg';
import salads from '../assets/images/salads.svg';
import Cover from '../components/Restaurants/Cover';
import BestDish from '../components/Restaurants/BestDish';
import PopularDish from '../components/Restaurants/PopularDish';
import NonRatedDish from '../components/Restaurants/NonRatedDish';
import Actions from '../components/Dishes/Actions';
// import ActionsMobile from '../components/Dishes/ActionsMobile';
import Filters from '../components/Dishes/Filters';
// import DishList from '../components/Dishes/DishList';
import dish_card1 from '../assets/images/dish_card1.png';
import dish_card2 from '../assets/images/dish_card2.png';
import dish_card3 from '../assets/images/dish_card3.png';
import dish_card4 from '../assets/images/dish_card4.png';
import dish_card5 from '../assets/images/dish_card5.png';
import dish_card6 from '../assets/images/dish_card6.png';
import dish_card7 from '../assets/images/dish_card7.png';
import dish_card8 from '../assets/images/dish_card8.png';
import dish_card9 from '../assets/images/dish_card9.png';
import dish_card10 from '../assets/images/dish_card10.png';
import dish_card11 from '../assets/images/dish_card11.png';
import dish_card12 from '../assets/images/dish_card12.png';
import DishRating from '../components/Commons/DishRating';
import DishReview from '../components/Commons/DishReview';
import DishCuisines from '../components/Commons/DishCuisines';
import RatingSuccess from '../components/Commons/RatingSuccess';
import ContactRestaurant from '../components/Commons/ContactRestaurant';
import Footer from '../components/Commons/Footer';
import DishRatingInformation from '../components/Commons/DishRatingInformation';
import AuthPopup from '../components/Commons/AuthPopup';
import CustomisationOptions from '../components/Commons/CustomisationOptions';
import DaiDishLoading1 from '../assets/images/DaiDishLoading1.gif';
import UserCart from '../components/Restaurants/UserCart';
import '../assets/styles/UserCart.css';
import ConfirmationBox from '../components/Commons/ConfirmationBox';
import { setDeviceToken } from '../services/notification_handler';
import one from '../assets/images/emojis/one.png';
import one_point_five from '../assets/images/emojis/one_point_five.png';
import two from '../assets/images/emojis/two.png';
import two_point_five from '../assets/images/emojis/two_point_five.png';
import three from '../assets/images/emojis/three.png';
import three_point_five from '../assets/images/emojis/three_point_five.png';
import four from '../assets/images/emojis/four.png';
import four_point_five from '../assets/images/emojis/four_point_five.png';
import five from '../assets/images/emojis/five.png';
import RatingStarSelected from "../assets/images/ratingStarSelected.png";
import RestoMenu from '../components/Commons/RestoMenu';
import { checkValidResult, getFormatedDishAddingStates, getFormattedDishes, getFormattedNonRatedDishItems, getFormattedRestaurant, getFormattedSubMenus } from '../utils';

class Restaurants extends Component {

  constructor(props) {
    super(props);
    this.showRatingsModal = this.showRatingsModal.bind(this);
    this.closeRatingsModal = this.closeRatingsModal.bind(this);
    this.closeRatingsInformationModal = this.closeRatingsInformationModal.bind(this);
    this.setMobileMenuFilter = this.setMobileMenuFilter.bind(this);
    this.showReviewsModal = this.showReviewsModal.bind(this);
    this.closeReviewsModal = this.closeReviewsModal.bind(this);
    this.showCuisinesModal = this.showCuisinesModal.bind(this);
    this.closeCuisinesModal = this.closeCuisinesModal.bind(this);
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.showContactModal = this.showContactModal.bind(this);
    this.closeContactModal = this.closeContactModal.bind(this);
    this.toggleImageHover = this.toggleImageHover.bind(this);
    this.toggleRateHover = this.toggleRateHover.bind(this);
    this.toggleReviewHover = this.toggleReviewHover.bind(this);
    this.toggleLikeButton = this.toggleLikeButton.bind(this);
    this.filterDishTypes = this.filterDishTypes.bind(this);
    this.showRecommendedDishes = this.showRecommendedDishes.bind(this);
    this.showRatingSortedDishes = this.showRatingSortedDishes.bind(this);
    this.showPriceSortedDishes = this.showPriceSortedDishes.bind(this);
    this.toggleVegDishes = this.toggleVegDishes.bind(this);
    this.toggleNonRatedLikeButton = this.toggleNonRatedLikeButton.bind(this);
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.toggleConfirmationBox = this.toggleConfirmationBox.bind(this);
    this.toggleRepeatCustomisation = this.toggleRepeatCustomisation.bind(this);
    this.togglePersonalized = this.togglePersonalized.bind(this);
    this.updateDishTypes = this.updateDishTypes.bind(this);
    this.closeSearchModal = this.closeSearchModal.bind(this);
    this.showAuthPopupModal = this.showAuthPopupModal.bind(this);
    this.addToCartAction = this.addToCartAction.bind(this);
    this.addToCartAction_TEMP = this.addToCartAction_TEMP.bind(this);
    this.closeAuthPopupModal = this.closeAuthPopupModal.bind(this);
    this.closeCustomisationOptionsModal = this.closeCustomisationOptionsModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.searchRestaurantDish = this.searchRestaurantDish.bind(this);
    this.updateCartChangedValue = this.updateCartChangedValue.bind(this);
    this.loadRestaurantDetails = this.loadRestaurantDetails.bind(this);
    this.getUserAddress = this.getUserAddress.bind(this);
    this.editAddressItem = this.editAddressItem.bind(this);
    this.getCoupons = this.getCoupons.bind(this);
    this.updateCartModalDisplay = this.updateCartModalDisplay.bind(this);
    this.getCustomisationData = this.getCustomisationData.bind(this);
    this.handleAddCart = this.handleAddCart.bind(this);
    this.getReplacementDish = this.getReplacementDish.bind(this);
    this.handlePopOverClose = this.handlePopOverClose.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.getEmoji = this.getEmoji.bind(this);
    this.setDishesAddingState = this.setDishesAddingState.bind(this);
    this.getPopularDishes = this.getPopularDishes.bind(this);
    this.state = { 
      ratingModal: false, 
      ratingInformationModal: false,
      dishSelected: {}, 
      reviewModal: false, 
      cuisinesModal: false, 
      searchModal: false, 
      successModal: false, 
      ratingNumber: "",
      contactModal: false,
      emoji: "", 
      isFiveStar: false,
      restaurantId: this.props.match.params.restaurant_id,
      dishes: [],
      bestDishes: [],
      popularDishes: [],
      dishesAddingState: [],
      dishCount: 0,
      dishesLoaded: 0,
      allDishesLoaded: true, 
      dishTypeCount: 0,
      dishTypesLoaded: 0,
      dishTypes: [],
      dishTypeLoading: true,
      moreDishTypeLoading: false,
      allDishTypesLoaded: false,
      vegFilterActive: false,
      dishTypeFilterActive: false,
      restaurant: {},
      restaurantDetailsLoaded: false,
      nonRatedDishes: [],
      nonRatedDishesLoaded: 0,
      isOpen: false,
      logoutModal: false,
      showConfirmationBox: false,
      showRepeatCustomisation: false,
      bestDishReady: false,
      isPersonalizedShown: false,
      isLoggedIn: false,
      cuisines: [],
      restaurants: [],
      recommendedDishes: [],
      activeHeaderType: "header",
      allRecommendedDishesLoaded: false,
      authPopupModal: false,
      customisationOptionsModal: false,
      isMobileScreen: false,
      cartChanged: false,
      showCart: false,
      itemsInCart: 0,
      addresses : {},
      coupons: [],
      dishDetails:{},
      selectedCustomisation : "",
      updatedPrice: 0,
      confirmationBoxMessage: "",
      repeatCustomisationMessage:"",
      temp_dishDetails: "",
      temp_changeType: "",
      temp_dishAlreadyPresent: "",
      temp_customizations: [],
      popOverAnchorEl : null,
      popUpId: undefined,
      popDishId : 0,
      popDishState : false,
      selectedDishCustomisationObj: {},
      defaultAddresses: "",
      customQty: 1,
      mobileMenuFilter: false,
      currentRestoMenu: "",
      bestDishDescription: "Curate the Best Dishes in your area. We believe that you should 'eat right every time' and that you should be able to decide your orders in seconds.",
      popularDishDescription: "Curate the Popular Dishes in your area. We believe that you should 'eat right every time.",
      dishImages: [dish_card1, dish_card2, dish_card3, dish_card4, dish_card5, dish_card6,
        dish_card7, dish_card8, dish_card9, dish_card10, dish_card11, dish_card12],
      dishTypeImages: [beverages, appetizers, breakfast, salads, main_course, dessert,
        beverages, appetizers, breakfast, salads, main_course, dessert]
    };
  } 

  setMobileMenuFilter(filterValue) {
    this.setState({mobileMenuFilter: filterValue});
  }

  setCurrentRestoMenu = (restoMenu) => {
    this.setState({currentRestoMenu: restoMenu});
  }

  componentDidMount(){
    window.fbq('track', 'PageView');
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    var token = localStorage.getItem("auth_token");
    var preferencesPresent = localStorage.getItem("preferences_present");
    if (preferencesPresent === "false" && (token !== null || token !== "")){
      this.props.history.replace("/preferences");
    }
    
    window.addEventListener("scroll", event => {
      if (window.scrollY > 270){
        var elements = document.getElementsByClassName("DishesHeader");
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.add('HeaderShadowBottom');
        }
      } else {
        var elements2 = document.getElementsByClassName("DishesHeader");
        for (var j = 0; j < elements2.length; j++) {
            elements2[j].classList.remove('HeaderShadowBottom');
        }
      }
      var actionsOffset = 700;
      if (this.state.isMobileScreen){
        actionsOffset = 600;
      }
      if (window.scrollY > actionsOffset){
        // var elements3 = document.getElementsByClassName("DishesActionsIdentifier");
        // if (!this.state.isMobileScreen){
        //   for (var k = 0; k < elements3.length; k++) {
        //     elements3[k].classList.add('DishesActionsHeader');
        //   }
        // } else {
        //   for (var m = 0; m < elements3.length; m++) {
        //     elements3[m].classList.remove('DishesActionsHeader');
        //   }
        // }
        // for (var k = 0; k < elements3.length; k++) {
        //   elements3[k].classList.add('DishesActionsHeader');
        // }
        this.setState({
          activeHeaderType: 'actions'
        });
      } else {
        var elements4 = document.getElementsByClassName("DishesActionsIdentifier");
        // if (!this.state.isMobileScreen){
        //   for (var l = 0; l < elements4.length; l++) {
        //     elements4[l].classList.remove('DishesActionsHeader');
        //   }
        // } else {
        //   for (var n = 0; n < elements4.length; n++) {
        //     elements4[n].classList.remove('DishesActionsHeader');
        //   }
        // }
        if (this.state.activeHeaderType !== 'header'){
          for (var l = 0; l < elements4.length; l++) {
            elements4[l].classList.add('DishesActionsHeaderDisappear');
          }
          setTimeout(() => {
            var elements4 = document.getElementsByClassName("DishesActionsIdentifier");
            for (var l = 0; l < elements4.length; l++) {
              elements4[l].classList.remove('DishesActionsHeaderDisappear');
            }
            this.setState({
              activeHeaderType: 'header'
            });
          }, 500)
        }
      }
    });
    if (preferencesPresent !== "true" & token !== null && token !== ""){
      this.props.history.replace("/preferences");
    }
    if (token !== null && token !== ""){
      this.setState({
        isLoggedIn: true
      })
    }
    this.loadBestDishes();
    this.getPopularDishes();
    this.loadRestaurantDetails();
    this.getUserAddress();
    this.getCoupons();
    this.getCartDetails();  

    if (localStorage.getItem("auth_token") !== null && localStorage.getItem("auth_token") !== ""){
      let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
      let firebaseReq = { 
        'token': localStorage.getItem('device_token')
      }
      let firebaseHeaders = {
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
      }
      axios.post(firebaseUrl, firebaseReq,  {
        headers: firebaseHeaders
      }).then((response) => {});
    }
    
    setDeviceToken();
    navigator.serviceWorker.addEventListener('message', event => {
      if ('data' in event.data.data){
        var orderDetails = JSON.parse(event.data.data.data);
        var orderEvent = event.data.data.event;
        if (orderEvent === 'new_order'){
          this.setState({
            newOrderModal: true,
            newOrderId: orderDetails.order_id
          })
        } 
      }
    });
  }

  refreshToken(){
    let url = CONSTANTS.hostURL + "api/accounts/v2/login/refresh-session/";
    let req = {
      'refresh': localStorage.getItem("refresh_token")
    };
    axios.post(url, req).then((response) => {
      let data = response.data;
      if (data.status === 1){
        localStorage.setItem("auth_token", data.data.access);
        localStorage.setItem("refresh_token", data.data.refresh);
        console.log("Token Refreshed")
        return(<>True</>)
      } else {
        console.log("Token Refresh Issue")
        return(<>False</>)
      }
    }).catch((error) => {
      if (error.response){
        if (error.response.data.status === -1){
          console.log("Token Refresh Issue")
        }
      }
      return(<>False</>)
    });
  }

  getCartDetails(){
    var token = localStorage.getItem("auth_token");
    if (token){
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios.get(url, {
          headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1 && 'id' in data.data){
          let cart = data.data;
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          this.updateCartChangedValue(true);
          
          let cartDetails = {};
          let itemsInCart = 0;
          let cartItems = [];
          for (var key in cart.items){
            if(cart.items[key].customizations.length > 1){
              for(var k=0; k<cart.items[key].customizations.length; k++){
                cartItems.push({
                  'DishID': parseInt(key),
                  'DishName': cart.items[key].name,
                  'DishCost': cart.items[key].customizations[k].sub_total,
                  'DishQuantity': cart.items[key].customizations[k].quantity,
                  "DishImage": cart.items[key].image, 
                  'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                  'Customisation': cart.items[key].customizations[k]
                });
                itemsInCart = itemsInCart + cart.items[key].customizations[k].quantity
              }
            }
            else{
              cartItems.push({
                'DishID': parseInt(key),
                'DishName': cart.items[key].name,
                'DishCost': cart.items[key].mrp,
                'DishQuantity': cart.items[key].quantity,
                "DishImage": cart.items[key].image, 
                'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                'Customisation': cart.items[key].customizations[0]
              });
              itemsInCart = itemsInCart + cart.items[key].quantity
            }
          }
          cartDetails.RestoID = cart.restaurant;
          cartDetails.RestoName = cart.restaurant_name;
          cartDetails.Cart_Items = cartItems;
          //itemsInCart = cartDetails.Cart_Items.length;
          localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
          this.setState({
            itemsInCart: itemsInCart,
            cartChanged: false
          });
        } else {
          if(data.status === -1 && data.errors.message.includes("Token")){
            localStorage.removeItem('cartDetailsAPI');
            // alert("refresh token")
            this.refreshToken()
            // console.log("402")
            this.getCartDetails();
          }
          if (data.status === -1){
            localStorage.removeItem('cartDetailsAPI');
          }
        }
      });
    }
  }

  editAddressItem(address,updateType) {
    let addressList = this.state.addresses;
    let index = -1;
    
    if(updateType === "defaultUpdate"){
      for (var j=0; j< addressList.length; j++){
        addressList[j].is_default = false;
        if (addressList[j].id === address.id){
          index = j;
        }
      }
      if (index !== -1){
        addressList[index] = address;
      }
    }
    else if(updateType === "delete"){
      for (var k=0; k< addressList.length; k++){
        if (addressList[k].id === address){
          index = k;
        }
      }
      if (index !== -1){
        addressList.splice(index, 1);
      }
    }
    else{
      for (var i=0; i< addressList.length; i++){
        if (addressList[i].id === address.id){
          index = i;
        }
      }
      if (index !== -1){
        addressList[index] = address;
      } else {
        addressList.push(address);
      }
    }

    this.setState({
      addresses: addressList
    })
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }
  
  getCoupons(){
    let coupons = [
        {
            "id": 1,
            "name": "Meal Deal",
            "description": "5% Off Upto Rs 80",
            "percentage": 5,
            "maxDiscount": 80,
        },
        {
            "id": 2,
            "name": "Diwali Deal",
            "description": "Flat 15% Off",
            "percentage": 15,
            "maxDiscount": "NA",
      }
    ]

    this.setState({coupons : coupons});
  }

  getUserAddress(){
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      var headers = {
        'Authorization' : 'Bearer ' + token,
      }
      var url = CONSTANTS.hostURL + "api/geo/location/";
      axios.get(url, {
        headers: headers
      }).then((response) => {
          var resData = response.data;
          if (resData.status === 1){
            for(let i=0; i<resData.data.locations.length; i++){
              if(resData.data.locations[i].is_default){
                this.setState({defaultAddresses : resData.data.locations[i].tag + ", " + resData.data.locations[i].address});
              }
            }
            this.setState({addresses : resData.data.locations});
          } else {
            this.setState({addresses : []});
          }
      });
    }
  }

  loadBestDishes() {
    let url = CONSTANTS.hostURL + "api/client/restaurant/best-dishes/?resto_id=" + this.state.restaurantId;
    axios.get(url).then((response) => {
      if (checkValidResult(response)){
        let data = response?.data?.data;
        let dishList = getFormattedDishes(data?.best_dishes, 1);
        let bestDishesAddingStateList = getFormatedDishAddingStates(data?.best_dishes);
        this.setState({
          bestDishes: dishList,
          dishesAddingState: [...this.state.dishesAddingState, ...bestDishesAddingStateList],
          bestDishReady: true,
        });
      }
    });
  }

  getPopularDishes(){
    let url = CONSTANTS.hostURL + "api/client/restaurant/popular-dishes/?resto_id=" + this.state.restaurantId;
    axios.get(url).then((response) => {
      if (checkValidResult(response)){
        let data = response?.data?.data;
        let dishList = getFormattedDishes(data?.popular_dishes, 1);
        let popularDishesAddingStateList = getFormatedDishAddingStates(data?.popular_dishes);
        this.setState({
          popularDishes: dishList,
          dishesAddingState: [...this.state.dishesAddingState, ...popularDishesAddingStateList],
        });
      }
    });
  }

  // Calculate distance between two geolocation points
  calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2-lat1);
    var dLon = this.toRad(lon2-lon1);
    var lat1_rad = this.toRad(lat1);
    var lat2_rad = this.toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1_rad) * Math.cos(lat2_rad); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  getNonRatedDishesForSubMenus(sub_menus) {
    sub_menus.forEach((sub_menu, index) => {
      let url = CONSTANTS.hostURL + "api/client/restaurant/restaurant-menu-dishes/?resto_id=" + this.state.restaurantId + "&sub_menu=" + sub_menu.dishTypeId;
      this.fetchNonRatedDishes(url, 0, index, sub_menu);
    })
  }

  loadDishTypes(sub_menus) {
    let subMenuList = getFormattedSubMenus(sub_menus);
    let nonRatedDishItems = getFormattedNonRatedDishItems(sub_menus);
    this.setState({
      dishTypes: subMenuList,
      dishTypeLoading: false,
      dishTypeCount: sub_menus.length,
      dishTypesLoaded: sub_menus.length,
      allDishTypesLoaded: true,
      nonRatedDishes: nonRatedDishItems,
    }, this.getNonRatedDishesForSubMenus(subMenuList));
  }

  updateDishTypes(dishTypeList, dishes){
    for (var i=0; i<dishes.length; i++){
      if (dishes[i].dishType !== null){
        for (var j=0; j<dishes[i].dishType.length; j++){
          for (var k=0; k<dishTypeList.length; k++){
            if (dishTypeList[k].dishTypeId === dishes[i].dishType[j] && dishTypeList[k].display === false){
              dishTypeList[k].display = true;
            }
          }
        }
      }
    }
    this.setState({
      dishTypes: dishTypeList
    })
  }

  fetchNonRatedDishes(url, dishesLoaded, id, sub_menu){
    let headers = {}
    let token = localStorage.getItem("auth_token");
    if (token) {
      headers = {
        'Authorization' : 'Bearer ' + token
      };
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (checkValidResult(res)){
        let data = res?.data?.data;
        let dishList = getFormattedDishes(data?.results, dishesLoaded);
        let nonRatedDishesAddingStateList = getFormatedDishAddingStates(data?.results);
        let nonRatedDishes = [...this.state.nonRatedDishes];
        let nonRatedDishesItem = nonRatedDishes[id];
        nonRatedDishesItem.dishes = [...(dishesLoaded === 0 ? [] : nonRatedDishesItem?.dishes ?? []), ...dishList]
        this.setState({
          nonRatedDishes: nonRatedDishes,
          dishesAddingState: [...this.state.dishesAddingState, ...nonRatedDishesAddingStateList],
        });
        if (data?.next){
          this.fetchNonRatedDishes(data?.next, (data?.results ?? []).length + dishesLoaded, id, sub_menu);
        }
      }
    })
  }

  loadRestaurantDetails(){
    let url = CONSTANTS.hostURL + "api/client/restaurant/restaurant-details/?resto_id=" + this.state.restaurantId;
    axios.get(url).then(res => {
      if (checkValidResult(res)){
        let data = res?.data?.data;
        let restaurant = getFormattedRestaurant(data?.restaurant_details ?? {});
        this.loadDishTypes(restaurant.sub_menus);
        this.setState({
          restaurant: restaurant,
          restaurantDetailsLoaded: true,
          bestDishDescription: restaurant.best_dish_zone_description,
          popularDishDescription: restaurant.popular_dish_zone_description
        });
      }
    })
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 13: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling() { // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling() {
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {  
    if(this.state.isOpen || this.state.logoutModal || this.state.searchModal){
      this.disableScrolling();
      document.getElementById("main-content").style.overflow = "hidden";
      // document.getElementById("main-content").style.height = "100vh";
    } else {
      this.enableScrolling();
      document.getElementById("main-content").style.overflow = "scroll";
      document.getElementById("main-content").style.height = "auto";
    }
  }

  componentWillUnmount(){
    this.enableScrolling();
  }

  toggleUserOptions(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal
    })
  }

  getReplacementDish(dish){
    this.setState({
      replacementDish: dish
    })
  }

  // replaceCartItems(dish){
  //   var token = localStorage.getItem("auth_token");
    
  //   if (token !== null && token !== ""){
  //     let cartDetails = {
  //         "RestoID": this.state.restaurant.id,
  //         "RestoName": this.state.restaurant.name,
  //         "Cart_Items": [
  //             {
  //                 "DishID": dish.dishId, 
  //                 "DishName": dish.name, 
  //                 "DishCost": dish.cost, 
  //                 "DishImage": dish.image, 
  //                 "DishIsVeg": dish.is_veg, 
  //                 "DishQuantity": 1,
  //                 "Customisation": cartItems[i]["Customisation"] !== null ? cartItems[i]["Customisation"] : ""
  //             }
  //         ]
  //     }
  //     localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
  //     this.updateCartChangedValue(true);
  //   } else {
  //       this.showAuthPopupModal("login");
  //   }
  // }

  deleteCart(cartID){
    let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
    let req = {
        'cart_id': cartID
    };
    var token = localStorage.getItem("auth_token");
    var headers = {
        'Authorization' : 'Bearer ' + token 
    }
    axios.post(url, req,  {
        headers: headers
    }).then((response) => {
        let data = response.data;
        if (data.status === 1){
            localStorage.removeItem('cartDetailsAPI');
            this.updateCartChangedValue(true);
        } else {
          if(data.status === -1 && data.errors.message.includes("Token")){
            // alert("refresh token")
            this.refreshToken()
            // console.log(1631)
            this.deleteCart(cartID);
          }
        }
    }).catch((error) => this.setState({}));
  }

  toggleConfirmationBox(confirmationBoxMessage) {
    if(confirmationBoxMessage === "Yes"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
          cartDetailsAPI = JSON.parse(cartDetailsAPI);
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetailsAPI.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                localStorage.removeItem('CartDetails');
                localStorage.removeItem('cartDetailsAPI');
                
                let url = CONSTANTS.hostURL + "api/main_app/cart/create/";
                var token = localStorage.getItem("auth_token");
                var headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                let req = {
                  'resto_id': this.state.temp_restoID
                };
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                      localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                      this.addRemoveWithAPI(this.state.newRestoDish, this.state.newRestoChangeType, this.state.newRestoSelectedDishCustomisationObj);
                      this.updateCartChangedValue(true);
                    }
                }).catch((error) => this.setState({}));
                
                this.setState({
                  // itemsInCart: 0,
                  cartChanged: true
                }, () => {
                  // this.checkForCustomisation1(this.state.replacementDish, "add", false);
                })
              } else {
                if(data.status === -1 && data.errors.message.includes("Token")){
                  // alert("refresh token")
                  this.refreshToken()
                  // console.log(1693)
                  this.toggleConfirmationBox(confirmationBoxMessage);
                }
              }
          }).catch((error) => this.setState({}));
        } else {
          localStorage.removeItem('CartDetails');
          this.setState({
            itemsInCart: 0,
            cartChanged: true
          }, () => {
            this.checkForCustomisation1(this.state.replacementDish, "add", false);
          })
        }
      })
      return "Yes";
    }
    else if(confirmationBoxMessage === "REMOVE RESTO DISHES"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        // Removing all the Items from the cart.
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        var cartDetails = JSON.parse(cartDetailsAPI);
        if(cartDetailsAPI !== null){
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetails.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                  localStorage.removeItem('cartDetailsAPI');
                  this.updateCartChangedValue(true);
              }
              else {
                if(data.status === -1 && data.errors.message.includes("Token")){
                  // alert("refresh token")
                  this.refreshToken()
                  // console.log(1740)
                  this.toggleConfirmationBox(confirmationBoxMessage);
                }
              }
          }).catch((error) => this.setState({}));
        }
      })
    }
    else if(confirmationBoxMessage.includes("REMOVE DISH:")){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        let dishIds = confirmationBoxMessage.split(':');

        let url = CONSTANTS.hostURL + "api/main_app/cart/remove-dish/";
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        // let cartItems = []
        let cart = JSON.parse(cartDetailsAPI)
        let req = {
          'cart_id': cart.id,
          'dish_id': Number(dishIds[1])
        }; 
        let token = localStorage.getItem("auth_token");
        let headers = {
          'Authorization' : 'Bearer ' + token 
        }
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
          let data = response.data;
          if (data.status === 1){
            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
            this.updateCartChangedValue(true);

            cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            var cartDetails = JSON.parse(cartDetailsAPI);
            if(cartDetailsAPI !== null){
              var itemKey = Object.keys(cartDetails.items)
              if(itemKey.length === 0){
                let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
                let req = {
                    'cart_id': cartDetails.id
                };
                var token = localStorage.getItem("auth_token");
                var headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                        localStorage.removeItem('cartDetailsAPI');
                        this.updateCartChangedValue(true);
                    } else if(data.status === -1 && data.errors.message.includes("Token")){
                      // alert("refresh token")
                      this.refreshToken()
                      // console.log(1791)
                      this.toggleConfirmationBox(confirmationBoxMessage);
                    }
                }).catch((error) => this.setState({}));
              }
            }

          }
          else if(data.status === -1 && data.errors.message.includes("Token")){
            // alert("refresh token")
            this.refreshToken()
            // console.log(1805)
            this.toggleConfirmationBox(confirmationBoxMessage);
          }
          else{
            this.loadRestaurantDetails();
          }
        }).catch((error) => this.setState({}));
      })
    }
    else if(confirmationBoxMessage === "Cancel"){
      this.setState({
        showConfirmationBox: false,
        confirmationBoxMessage: confirmationBoxMessage,
        customAddClick: false,
        customisationOptionsModal: false
      }, () => this.setDishesAddingState(this.state.temp_dishDetails, false))
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      var cartDetails = JSON.parse(cartDetailsAPI);
      if(cartDetailsAPI !== null){
        var itemVal = Object.values(cartDetails.items)
        if(itemVal.length === 0){
          this.deleteCart(cartDetails.id)
        }
      }
    }
    else{
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox,
        confirmationBoxMessage: confirmationBoxMessage
      })
      return "";
    }
  }

  handlePopOverClose(dish){
    this.setDishesAddingState(this.state.temp_dishDetails, false)
    this.setState({
        popOverAnchorEl : null,
        popDishId : this.state.temp_dishDetails.id,
        popDishState : false,
    });
  }

  toggleRepeatCustomisation(repeatCustomisationMessage, selectedCustom, eventTarget) {
    if(repeatCustomisationMessage === "Yes"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        });
        this.handleAddCartWithCustomisation1(this.state.temp_dishDetails, "add", true, selectedCustom);
      })
    }
    else if(repeatCustomisationMessage === "No"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          customisationOptionsModal: true,
          dishDetails: this.state.temp_dishDetails,
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        })
      })
    }
    else if(repeatCustomisationMessage === "Remove"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        });
        this.handleAddCartWithCustomisation1(this.state.temp_dishDetails, "remove", true, selectedCustom);
      })
    }
    else if(repeatCustomisationMessage === ""){
      this.setDishesAddingState(this.state.temp_dishDetails, false)
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation,
        popOverAnchorEl : null,
        popDishId : this.state.temp_dishDetails.dishId,
        popDishState : false,
      })
    }
    else{
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation,
        repeatCustomisationMessage: repeatCustomisationMessage,
        popOverAnchorEl : eventTarget,
        popUpId : "anchorEl_"+this.state.temp_dishDetails.dishId+"_"+this.state.temp_dishDetails.id,
        popDishId : this.state.temp_dishDetails.dishId,
        popDishState : true,
      })
      return "";
    }
  }

  showRatingsModal(dish) {
    this.setState({
      ratingInformationModal: true,
      dishSelected: dish
    });
    setTimeout(() => {
      this.setState({
        ratingInformationModal: false
      })
      if (!this.state.ratingModal){
        this.setState({
          ratingModal: true
        })
      }
    }, 4000);
  };

  closeRatingsModal() {
    this.setState({
      ratingModal: false
    });
  };

  closeRatingsInformationModal() {
    this.setState({
      ratingInformationModal: false,
      ratingModal: true
    });
  };

  showAuthPopupModal(form) {
    this.setState({
      authPopupModal: true,
    })
    if (form != null){
      this.setState({
        activeForm: form
      })
    }
  }

  addToCartAction_TEMP(dishDetails, changeType, dishAlreadyPresent, event){
    this.setDishesAddingState(dishDetails, true)
    
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      if (cartDetailsAPI !== null){
        // --> ADD/REMOVE Item
        this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
      }
      else{
        //CHECK FOR AVAILABLE CART
        token = localStorage.getItem("auth_token");
        var headers = {
          'Authorization' : 'Bearer ' + token
        }
        let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
        axios.get(url, {
            headers: headers
        }).then((response) => {
          var verifyCartData = response.data
          if (verifyCartData.status === 1 && 'id' in verifyCartData.data){
            localStorage.setItem('cartDetailsAPI', JSON.stringify(verifyCartData.data));
            this.updateCartChangedValue(true);
            // --> ADD/REMOVE Item
            this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
          }
          //ELSE IF CART NOT AVAILABLE
          else if(verifyCartData.errors.code === "NOT_FOUND"){
            //CRETAE A CART
            let url = CONSTANTS.hostURL + "api/main_app/cart/create/";
            var token = localStorage.getItem("auth_token");
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            let req = {
              'resto_id': this.props.match.params.restaurant_id
            };
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
              var createCartData = response.data
              if (createCartData.status === 1 && 'id' in createCartData.data){
                localStorage.setItem('cartDetailsAPI', JSON.stringify(createCartData.data));
                this.updateCartChangedValue(true);
                // --> ADD/REMOVE Item
                this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
              }
              else if(createCartData.status === -1 && createCartData.errors.message.includes("Token")){
                // alert("refresh token")
                this.refreshToken()
                // console.log(2002)
                this.addToCartAction_TEMP(dishDetails, changeType, dishAlreadyPresent, event);
              }
              else{
                this.toggleConfirmationBox(dishDetails.dishId + ":" + createCartData.errors.message);
              }
            });
          } else if(verifyCartData.status === -1 && verifyCartData.errors.message.includes("Token")){
            // alert("refresh token")
            this.refreshToken()
            // console.log("2014")
            this.addToCartAction_TEMP(dishDetails, changeType, dishAlreadyPresent, event);
          }
        });
      }
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, eventTarget){
    if(dishDetails.customizations !== null && Object.keys(dishDetails.customizations).length !== 0){
      if(dishAlreadyPresent){
        /*Repeat Customisation Code - If Customisable dish is present in cart*/
        this.setState({
          temp_dishDetails: dishDetails,
          temp_changeType: changeType,
          temp_dishAlreadyPresent: dishAlreadyPresent
        }, () => {
          let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
          let dishCustom = [];
          if (cartDetailsAPI !== null) {
            let cartItems = JSON.parse(cartDetailsAPI)["items"];
            var itemID = Object.keys(cartItems)
            var itemVals = Object.values(cartItems)
            for (var i=0; i<itemID.length; i++){
              if (Number(itemID[i]) === Number(dishDetails.dishId)){
                for(var j=0; j<itemVals[i].customizations.length; j++){  
                  dishCustom.push(itemVals[i].customizations[j]);
                }
              }
            } 

          }
          this.setState({
            temp_customizations: dishCustom
          });
          if(changeType === "add"){
            this.toggleRepeatCustomisation("Repeat Last Customisation?", "", eventTarget);
          }
          else if(changeType === "remove" && dishCustom.length > 1){
            this.toggleRepeatCustomisation("Select Customisation to remove", "", eventTarget);
          }
          else{
            this.handleAddCartWithCustomisation1(dishDetails, changeType, dishAlreadyPresent, JSON.stringify(dishCustom[0]));
          }
        })
      }
      else{
        this.setState({
          customisationOptionsModal: true,
          dishDetails: dishDetails,
          temp_dishDetails: dishDetails,
          temp_changeType: changeType,
          temp_dishAlreadyPresent: dishAlreadyPresent
        })
      }
    }
    else{
      this.setState({
        customisationOptionsModal: false,
        temp_dishDetails: dishDetails,
        temp_changeType: changeType,
        temp_dishAlreadyPresent: dishAlreadyPresent
      }, () => {
        this.handleAddCart1(dishDetails, changeType, dishAlreadyPresent);
      });
    }
  }


  addToCartAction(dishDetails, changeType, dishAlreadyPresent){
    //this.getCartDetails();
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let cartDetails = localStorage.getItem('CartDetails');
      if (cartDetails !== null){
        let cartDetailsObj = JSON.parse(cartDetails);
        if (cartDetailsObj["RestoID"] === Number(dishDetails.restaurantId)){
          this.checkForCustomisation(dishDetails, changeType, dishAlreadyPresent); 
        } else{
            this.getReplacementDish(dishDetails);
            this.toggleConfirmationBox("1253 : Items from different restaurant available in the cart. Do you want to remove them?");
        }
      }
      else{
        this.checkForCustomisation(dishDetails, changeType, dishAlreadyPresent);
      }
    }
    else{
      this.showAuthPopupModal("login");
    }
  }

  checkForCustomisation(dishDetails, changeType, dishAlreadyPresent){
    if(dishDetails.customizations !== null /*&& changeType !== "remove"*/){
      if(dishAlreadyPresent){
        /*Repeat Customisation Code - If Customisable dish is present in cart*/
        this.setState({
          temp_dishDetails: dishDetails,
          temp_changeType: changeType,
          temp_dishAlreadyPresent: dishAlreadyPresent
        }, () => {
          let cartDetails = localStorage.getItem('CartDetails');
          let dishCustom = [];
          if (cartDetails !== null) {
              let cartItems = JSON.parse(cartDetails)["Cart_Items"];
              for (var i=0; i<cartItems.length; i++){
                  if (cartItems[i]["DishID"] === dishDetails.dishId){
                      dishCustom.push(cartItems[i]["Customisation"]);
                  }
              } 
          }
          this.setState({
            temp_customizations: dishCustom
          });
          if(changeType === "add")
            this.toggleRepeatCustomisation("Repeat Last Customisation?", "");
          else if(changeType === "remove" && dishCustom.length > 1)
            this.toggleRepeatCustomisation("Select Customisation to remove", "");
          else{
            this.handleAddCartWithCustomisation(dishDetails, changeType, dishAlreadyPresent, JSON.stringify(dishCustom[0]));
          }
        })
      }
      else{
        this.setState({
          customisationOptionsModal: true,
          dishDetails: dishDetails
        })
      }
    }
    else{
      this.setState({
        customisationOptionsModal: false
      }, () => {
        this.handleAddCart(dishDetails, changeType, dishAlreadyPresent);
      });
    }
  }

  checkCartAddOrRemoveItem(restoID, changeType, dish, selectedDishCustomisationObj){
    var token = localStorage.getItem("auth_token");
    if (token){
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios.get(url, {
          headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1 && 'id' in data.data){
          let cart = data.data;
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          this.updateCartChangedValue(true);
          let cartDetails = {};
          let cartItems = [];
          for (var key in cart.items){
            if(cart.items[key].customizations.length > 1){
              for(var k=0; k<cart.items[key].customizations.length; k++){
                cartItems.push({
                  'DishID': parseInt(key),
                  'DishName': cart.items[key].name,
                  'DishCost': cart.items[key].customizations[k].sub_total,
                  'DishQuantity': cart.items[key].customizations[k].quantity,
                  "DishImage": cart.items[key].image, 
                  'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                  'Customisation': cart.items[key].customizations[k]
                });
              }
            }
            else{
              cartItems.push({
                'DishID': parseInt(key),
                'DishName': cart.items[key].name,
                'DishCost': cart.items[key].mrp,
                'DishQuantity': cart.items[key].quantity,
                "DishImage": cart.items[key].image, 
                'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                'Customisation': cart.items[key].customizations[0]
              });
            }
          }
          cartDetails.RestoID = cart.restaurant;
          cartDetails.RestoName = cart.restaurant_name;
          cartDetails.Cart_Items = cartItems;
          localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
          this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
        } else {
          if (data.status === -1 && data.errors.message === "Cart not found."){
            let url = CONSTANTS.hostURL + "api/main_app/cart/create/";
            var token = localStorage.getItem("auth_token");
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            let req = {
              'resto_id': restoID
            };
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                let data = response.data;
                if (data.status === 1){
                  localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                  this.updateCartChangedValue(true);
                  this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
                } else if(data.status === -1 && data.errors.message.includes("Token")){
                  // alert("refresh token")
                  this.refreshToken()
                  // console.log(2227)
                  this.checkCartAddOrRemoveItem(restoID, changeType, dish, selectedDishCustomisationObj);
                }
            }).catch((error) => this.setState({}));
          }
          else if(data.status === -1 && data.errors.message.includes("Token")){
            // alert("refresh token")
            this.refreshToken()
            // console.log("2237")
            this.checkCartAddOrRemoveItem(restoID, changeType, dish, selectedDishCustomisationObj);
          }
        }

      });
    }
    else {
      this.showAuthPopupModal("login");
    }
  }

  setDishesAddingState(dish, trueOrFalse){
    var dishesAddingState = this.state.dishesAddingState
    for(let i=0; i<this.state.dishesAddingState.length; i++){
      if(this.state.dishesAddingState[i].dishID === dish.dishId){
        // console.log(dish.dishId + "--> " + this.state.dishesAddingState[i].dishID)
        dishesAddingState[i].addingState = trueOrFalse
      }
    }
    this.setState({
      dishesAddingState: dishesAddingState
    })
  }

  addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj){
    let url = "";
    if(changeType === "add"){
      url = CONSTANTS.hostURL + "api/main_app/cart/add/";
    }
    else{
      url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
    }

    let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');

    if (cartDetailsAPI){
      cartDetailsAPI = JSON.parse(cartDetailsAPI);
      if(selectedDishCustomisationObj !== "{ \"combination\" : }}"){
        if(JSON.parse(selectedDishCustomisationObj).combination !== null){
          var req = {
            'cart_id': cartDetailsAPI.id,
            'dish_id': dish.dishId,
            'quantity': changeType === "add" ? this.state.customQty : 1,
            'combination': JSON.parse(selectedDishCustomisationObj).combination
          };
        }
        else{
          req = {
            'cart_id': cartDetailsAPI.id,
            'dish_id': dish.dishId,
            'quantity': 1,
          };
        }
      }
      else{
        req = {
          'cart_id': cartDetailsAPI.id,
          'dish_id': dish.dishId,
          'quantity': 1,
        };
      }
      
      let token = localStorage.getItem("auth_token");
      let headers = {
        'Authorization' : 'Bearer ' + token 
      }
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1){
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          this.updateCartChangedValue(true);
          this.setState({customQty : 1})
          cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
          var cartDetails = JSON.parse(cartDetailsAPI);
          if(cartDetailsAPI !== null){
            var itemKey = Object.keys(cartDetails.items)
            if(itemKey.length === 0){
              let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
              let req = {
                  'cart_id': cartDetails.id
              };
              var token = localStorage.getItem("auth_token");
              var headers = {
                  'Authorization' : 'Bearer ' + token 
              }
              axios.post(url, req,  {
                  headers: headers
              }).then((response) => {
                  let data = response.data;
                  if (data.status === 1){
                      localStorage.removeItem('cartDetailsAPI');
                      this.updateCartChangedValue(true);
                      this.setDishesAddingState(dish, false)
                  } else if(data.status === -1 && data.errors.message.includes("Token")){
                    // alert("refresh token")
                    this.refreshToken()
                    // console.log(1791)
                    this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
                  }
              }).catch((error) => {
                this.setDishesAddingState(dish, false)
              });
            }
          }
          this.setDishesAddingState(dish, false)
        }
        else if(data.status === -1 && data.errors.message.includes("Token")){
          // alert("refresh token")
          this.refreshToken()
          // console.log(2337)
          this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
        }
        else{
          this.setState({customQty : 1})
          this.toggleConfirmationBox(dish.dishId + ":" + data.errors.message);
          this.loadRestaurantDetails();
          this.setDishesAddingState(dish, false)
        }
      }).catch((error) => this.setState({}));
    }
    else{
      this.setDishesAddingState(dish, false)
    }
  }

  handleAddCart(dish, changeType, dishAlreadyPresent) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        // this.props.addToCartAction(dish.name);
        let cartDetails = localStorage.getItem('CartDetails');
        let cartItems = [];
        if (cartDetails !== null){
            let cartDetailsObj = JSON.parse(cartDetails);
            if (cartDetailsObj["RestoID"] === Number(dish.restaurantId)){
                cartItems = cartDetailsObj["Cart_Items"];
            } else{
                this.getReplacementDish(dish);
                this.toggleConfirmationBox("1395 : Items from different restaurant available in the cart. Do you want to remove them?");
                return;
            }
        }
        var obj = {
            "RestoID": this.state.restaurant.id,
            "RestoName": this.state.restaurant.name,
            "Cart_Items": cartItems
        };
        if (dishAlreadyPresent) {
            let itemIndex = -1;
            for (var i=0; i<cartItems.length; i++){
                if (cartItems[i]["DishID"] === dish.dishId){
                    obj["Cart_Items"][i] = {
                        "DishID": dish.dishId, 
                        "DishName": dish.name,
                        "DishImage": dish.image, 
                        "DishCost": changeType === "add" ? cartItems[i]["DishCost"] + dish.cost : cartItems[i]["DishCost"] - dish.cost,
                        "DishIsVeg": dish.is_veg, 
                        "DishQuantity": changeType === "add" ? cartItems[i]["DishQuantity"] + 1 : cartItems[i]["DishQuantity"] - 1,
                        "Customisation": cartItems[i]["Customisation"] !== null ? cartItems[i]["Customisation"] : ""
                    };
                    itemIndex = i;
                    break;
                }
            }
            if (itemIndex !== -1){
                if (obj["Cart_Items"][itemIndex]["DishQuantity"] === 0){
                    obj["Cart_Items"].splice(itemIndex, 1);
                }
            }
        } else {
            obj["Cart_Items"].push({
                "DishID": dish.dishId, 
                "DishName": dish.name,
                "DishImage": dish.image,
                "DishCost": dish.cost, 
                "DishIsVeg": dish.is_veg, 
                "DishQuantity": 1,
                "Customisation": {"combination": null, "quantity": 1, "sub_total": dish.cost}
            });
        }
        if (obj["Cart_Items"].length > 0){
            cartDetails = JSON.stringify(obj);
            localStorage.setItem('CartDetails', cartDetails);
        } else {
            localStorage.removeItem('CartDetails');
        }
        
        this.checkCartAddOrRemoveItem(this.props.match.params.restaurant_id, changeType, dish, JSON.stringify({"combination": null, "quantity": 1, "sub_total": dish.cost}))
        //this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
        this.updateCartChangedValue(true);
        return(<UserCart /> );
    } else {
        this.showAuthPopupModal("login");
    }
  }

  handleAddCart1(dish, changeType, dishAlreadyPresent) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        // this.props.addToCartAction(dish.name);
        let cartDetails = localStorage.getItem('cartDetailsAPI');
        // let cartItems = [];
        if (cartDetails !== null){
            let cartDetailsObj = JSON.parse(cartDetails);
            this.setState({temp_restoID: dish.restaurantId})
            if (Number(cartDetailsObj["restaurant"].id) === Number(dish.restaurantId)){
                // cartItems = cartDetailsObj["Cart_Items"];
            } else{
                this.getReplacementDish(dish);
                this.setState({
                  temp_restoID: dish.restaurantId,
                  newRestoDish: dish,
                  newRestoChangeType: changeType,
                  newRestoDishAlreadyPresent: dishAlreadyPresent,
                  newRestoSelectedDishCustomisationObj: JSON.stringify({"combination": null, "quantity": 1, "sub_total": dish.cost})
                })
                this.toggleConfirmationBox("1395 : Items from different restaurant available in the cart. Do you want to remove them?");
                return;
            }
        }
        this.addRemoveWithAPI(dish, changeType, JSON.stringify({"combination": null, "quantity": 1, "sub_total": dish.cost}));
        this.updateCartChangedValue(true);
        return(<UserCart /> );
    } else {
        this.props.showAuthPopupModal("login");
    }
  }

  handleAddCartWithCustomisation1(dish, changeType, dishAlreadyPresent, selectedDishCustomisationObj) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      // let cartItems = [];
      if (cartDetailsAPI !== null){
        let cartDetailsObj = JSON.parse(cartDetailsAPI);
        if (cartDetailsObj["restaurant"].id === Number(dish.restaurantId)){
          // cartItems = cartDetailsObj["Cart_Items"];
        } else{
          this.getReplacementDish(dish);
          this.setState({
            temp_restoID: dish.restaurantId,
            newRestoDish: dish,
            newRestoChangeType: changeType,
            newRestoDishAlreadyPresent: dishAlreadyPresent,
            newRestoSelectedDishCustomisationObj: selectedDishCustomisationObj
          })
          this.toggleConfirmationBox("1509 : Items from different restaurant available in the cart. Do you want to remove them?");
          return;
        }
      }
      this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
      this.updateCartChangedValue(true);
      return(<UserCart /> );
    } else {
        this.showAuthPopupModal("login");
    }
  }

  handleAddCartWithCustomisation(dish, changeType, dishAlreadyPresent, selectedDishCustomisationObj) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        let cartDetails = localStorage.getItem('CartDetails');
        let cartItems = [];
        if (cartDetails !== null){
            let cartDetailsObj = JSON.parse(cartDetails);
            if (cartDetailsObj["RestoID"] === Number(dish.restaurantId)){
                cartItems = cartDetailsObj["Cart_Items"];
            } else{
                this.getReplacementDish(dish);
                this.toggleConfirmationBox("1509 : Items from different restaurant available in the cart. Do you want to remove them?");
                return;
            }
        }
        var obj = {
            "RestoID": this.state.restaurant.id,
            "RestoName": this.state.restaurant.name,
            "Cart_Items": cartItems
        };
        if (dishAlreadyPresent) {
            let itemIndex = -1;
            for (var i=0; i<cartItems.length; i++){
                if (cartItems[i]["DishID"] === dish.dishId && this.compareCustomisation(cartItems[i]["Customisation"], JSON.parse(selectedDishCustomisationObj))){
                    obj["Cart_Items"][i] = {
                        "DishID": dish.dishId, 
                        "DishName": dish.name,
                        "DishImage": dish.image, 
                        "DishCost": changeType === "add" ? Number(cartItems[i]["DishCost"]) + (Number(cartItems[i]["DishCost"])/Number(cartItems[i]["DishQuantity"])) : Number(cartItems[i]["DishCost"]) - (Number(cartItems[i]["DishCost"])/Number(cartItems[i]["DishQuantity"])),
                        "DishIsVeg": dish.is_veg, 
                        "DishQuantity": changeType === "add" ? cartItems[i]["DishQuantity"] + 1 : cartItems[i]["DishQuantity"] - 1,
                        "Customisation": JSON.parse(selectedDishCustomisationObj)
                    };
                    itemIndex = i;
                    break;
                }
            }
            if (itemIndex !== -1){
                if (obj["Cart_Items"][itemIndex]["DishQuantity"] === 0){
                    obj["Cart_Items"].splice(itemIndex, 1);
                }
            }
        } else {
          let itemIndex = -1;
          for (i=0; i<cartItems.length; i++){
            if (cartItems[i]["DishID"] === dish.dishId && this.compareCustomisation(cartItems[i]["Customisation"], JSON.parse(selectedDishCustomisationObj))){
              obj["Cart_Items"][i] = {
                "DishID": dish.dishId, 
                "DishName": dish.name,
                "DishImage": dish.image, 
                "DishCost": changeType === "add" ? Number(cartItems[i]["DishCost"]) + (Number(cartItems[i]["DishCost"])/Number(cartItems[i]["DishQuantity"])) : Number(cartItems[i]["DishCost"]) - (Number(cartItems[i]["DishCost"])/Number(cartItems[i]["DishQuantity"])),
                "DishIsVeg": dish.is_veg, 
                "DishQuantity": changeType === "add" ? cartItems[i]["DishQuantity"] + 1 : cartItems[i]["DishQuantity"] - 1,
                "Customisation": JSON.parse(selectedDishCustomisationObj)
              };
              itemIndex = i;
              break;
            }
          }
          
          if (itemIndex !== -1){
            if (obj["Cart_Items"][itemIndex]["DishQuantity"] === 0){
                obj["Cart_Items"].splice(itemIndex, 1);
            }
          } 
          else{
            obj["Cart_Items"].push({
              "DishID": dish.dishId, 
              "DishName": dish.name,
              "DishImage": dish.image, 
              "DishCost": this.state.updatedPrice, 
              "DishIsVeg": dish.is_veg, 
              "DishQuantity": 1,
              "Customisation": JSON.parse(selectedDishCustomisationObj)
            });
          }
        }
        if (obj["Cart_Items"].length > 0){
            cartDetails = JSON.stringify(obj);
            localStorage.setItem('CartDetails', cartDetails);
        } else {
            localStorage.removeItem('CartDetails');
        }
        
        this.checkCartAddOrRemoveItem(this.props.match.params.restaurant_id, changeType, dish, selectedDishCustomisationObj)
        
        this.updateCartChangedValue(true);
        return(<UserCart /> );
    } else {
        this.showAuthPopupModal("login");
    }
  }

  getCustomisationText(customizations){
    var customText = "";
    if(customizations !== undefined){
      if(customizations.combination !== null){
        var combinationValues = Object.values(customizations.combination)
        for(var i=0; i<combinationValues.length; i++){
          customText = customText + combinationValues[i].name + " : ";
          var modVals = Object.values(combinationValues[i].modifications)
          for(var k=0; k<modVals.length; k++){
              customText = customText + modVals[k].name + ", ";
          }
          customText = customText.substring(0, customText.length-2);
          customText = customText + "\n"
        }
      }
      else{
        customText = "No Customization\n"
      }
    }
    return(customText);
  }

  compareCustomisation(cartCustomisation, selectedDishCustomisationObj){
    let cartCustomString = this.getCustomisationText(cartCustomisation)
    let selectedCustomString = this.getCustomisationText(selectedDishCustomisationObj)
    if(cartCustomString === selectedCustomString)
      return true;
    else
      return false;
  }

  closeAuthPopupModal() {
    this.setState({
      authPopupModal: false
    })
  }

  closeCustomisationOptionsModal(dishDetails){
    this.setState({
      customisationOptionsModal: false
    }, () => this.toggleConfirmationBox("Cancel"))
    this.setDishesAddingState(dishDetails, false)
  }

  setActiveForm(form){
    this.setState({
      activeForm: form
    });
  }

  showReviewsModal(dish) {
    this.setState({
      reviewModal: true,
      dishSelected: dish
    });
  };

  closeReviewsModal() {
    this.setState({
      reviewModal: false
    });
  };

  showCuisinesModal() {
    this.setState({
      cuisinesModal: true,
    });
  };

  closeCuisinesModal() {
    this.setState({
      cuisinesModal: false
    });
  };

  showContactModal() {
    this.setState({
      contactModal: true,
    });
  };

  closeContactModal() {
    this.setState({
      contactModal: false
    });
  };

  toggleSearchModal() {
    if (this.state.searchModal === false){
      window.scrollTo(0, 0);
    }
    this.setState({
      searchModal: !this.state.searchModal
    });
  }

  showSuccessModal(rating) {
    if (rating.value === 5){
      this.setState({
        isFiveStar: true
      })
    }
    this.setState({
      successModal: true,
      emoji: this.getEmoji(rating.value),
      ratingNumber: rating.value
    });
  };

  getEmoji(ratingValue){
    switch(ratingValue) {
      case 1:
        return one;
      case 1.5:
        return one_point_five;
      case 2:
        return two;
      case 2.5:
        return two_point_five;
      case 3:
        return three;
      case 3.5:
        return three_point_five;
      case 4:
        return four;
      case 4.5:
          return four_point_five;
      case 5:
        return five;
      default:
        return RatingStarSelected;
    }
  }

  closeSuccessModal() {
    this.setState({
      successModal: false,
      isFiveStar: false
    });
  };

  toggleImageHover(index) {
    let items = [...this.state.dishes];
    let item = {...items[index]};
    item.imageHover  = !item.imageHover;
    this.setState(update(this.state, {
      dishes: {
            [index]: {
                $set: item
            }
        }
    }));
}

  toggleRateHover(index) {
      let items = [...this.state.dishes];
      let item = {...items[index]};
      item.rateHover  = !item.rateHover;
      this.setState(update(this.state, {
        dishes: {
              [index]: {
                  $set: item
              }
          }
      }));
  }

  toggleReviewHover(index) {
      let items = [...this.state.dishes];
      let item = {...items[index]};
      item.reviewHover  = !item.reviewHover;
      this.setState(update(this.state, {
        dishes: {
              [index]: {
                  $set: item
              }
          }
      }));
  }

  toggleLikeButton(index) {
    let items = [...this.state.dishes];
    let item = {...items[index]};
    item.liked  = !item.liked;
    this.setState(update(this.state, {
      dishes: {
            [index]: {
                $set: item
            }
        }
    }));
    var token = localStorage.getItem("auth_token");
    if (token != null){
      var user = JSON.parse(localStorage.getItem("user"));
      var likes_list = user.likes;
      if (item.liked){
        likes_list.push(item.dishId);
      } else {
        let dishIndex = likes_list.indexOf(item.dishId)
        if (dishIndex > -1) {
          likes_list.splice(dishIndex, 1);
        }
      }
      let type = "like";
      if (!item.liked){
        type = "dislike";
      }
      var headers = {
        'Authorization' : 'Bearer ' + token 
      }
      var req = {
        likes_list: [item.dishId],
        type: type
      }
      var url = CONSTANTS.hostURL + "api/accounts/likes/";
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
          var user = JSON.parse(localStorage.getItem("user"));
          user.likes = likes_list;
          localStorage.setItem("user", JSON.stringify(user));
      });
    } else {
      item.liked  = !item.liked;
        this.setState(update(this.state, {
          dishes: {
                [index]: {
                    $set: item
                }
            }
      }));
    }
  }

  toggleNonRatedLikeButton(index, dishIndex) {
    let items = [...this.state.nonRatedDishes];
    let item = {...items[index]};
    let dishItem = item.dishes[dishIndex];
    dishItem.liked  = !dishItem.liked;
    items[index].dishes[dishIndex] = dishItem;
    this.setState({
      nonRatedDishes: items
    });
    var token = localStorage.getItem("auth_token");
    if (token != null){
      var user = JSON.parse(localStorage.getItem("user"));
      var likes_list = user.likes;
      if (dishItem.liked){
        likes_list.push(dishItem.dishId);
      } else {
        let dishIndex = likes_list.indexOf(dishItem.dishId)
        if (dishIndex > -1) {
          likes_list.splice(dishIndex, 1);
        }
      }
      let type = "like";
      if (!item.liked){
        type = "dislike";
      }
      var headers = {
        'Authorization' : 'Bearer ' + token 
      }
      var req = {
        likes_list: [dishItem.dishId],
        type: type
      }
      var url = CONSTANTS.hostURL + "api/accounts/likes/";
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
          var user = JSON.parse(localStorage.getItem("user"));
          user.likes = likes_list;
          localStorage.setItem("user", JSON.stringify(user));
      });
    } else {
      let dishItem = item.dishes[dishIndex];
      dishItem.liked  = !dishItem.liked;
      items[index].dishes[dishIndex] = dishItem;
      this.setState({
        nonRatedDishes: items
      });
    }
}

handleSearchInput(value) {
  let dishList = this.state.dishes;
  let nonRatedDishList = this.state.nonRatedDishes;
  if (value.length > 2){
    dishList.forEach(function(dish){
      if (dish.name.toLowerCase().includes(value.toLowerCase())){
        dish.searchDisplay = true;
      } else {
        dish.searchDisplay = false;
      }
    })
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          if (dish.name.toLowerCase().includes(value.toLowerCase())){
            dish.searchDisplay = true;
          } else {
            dish.searchDisplay = false;
          }
        })
      }
    })
  } else {
    dishList.forEach(function(dish){
      dish.searchDisplay = true;
    })
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          dish.searchDisplay = true;
        })
      }
    })
  }
  this.setState({
    dishes: dishList,
    nonRatedDishes: nonRatedDishList
  })
}

filterDishTypes(filterList) {
  // console.log(filterList)
  let dishList = this.state.dishes;
  let filterExist = true;
  let nonRatedDishList = this.state.nonRatedDishes;
  if (filterList.length === 0){
    filterExist = false;
    dishList.forEach(function(dish){
      dish.dishTypeDisplay = true;
    })
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          dish.dishTypeDisplay = true;
        })
      }
    })
  } else {
    dishList.forEach(function(dish){
      let dishFound = false;
      for (var i=0; i<filterList.length; i++){
        if (dish.dishType != null){
          for (var j=0; j<dish.dishType.length; j++){
            if (dish.dishType[j] === filterList[i]){
              dishFound = true;
              break;
            }
          }
        }
      }
      if (!dishFound){
        dish.dishTypeDisplay = false;
      } else {
        dish.dishTypeDisplay = true;
      }
    })
    // console.log(nonRatedDishList)
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          let dishFound = false;
          for (var i=0; i<filterList.length; i++){
            if (dish.dishType != null){
              for (var j=0; j<dish.dishType.length; j++){
                if (dish.dishType[j] === filterList[i]){
                  dishFound = true;
                  break;
                }
              }
            }
          }
          if (!dishFound){
            dish.dishTypeDisplay = false;
          } else {
            dish.dishTypeDisplay = true;
          }
        })
      }
    })
  }
  this.setState({
    dishes: dishList,
    dishTypeDisplay: filterExist,
    nonRatedDishes: nonRatedDishList
  })
}

searchRestaurantDish(dishId) {
  let dishList = this.state.dishes;
  let nonRatedDishList = this.state.nonRatedDishes;
  if (dishId === -1){
    dishList.forEach(function(dish){
      dish.searchDisplay = true;
    })
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          dish.searchDisplay = true;
        })
      }
    })
  } else {
    dishList.forEach(function(dish){
      if (dish.dishId === dishId){
        dish.searchDisplay = true;
      } else {
        dish.searchDisplay = false;
      }
    })
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        dishType["dishes"].forEach(function(dish){
          if (dish.dishId === dishId){
            dish.searchDisplay = true;
          } else {
            dish.searchDisplay = false;
          }
        })
      }
    })
  }
  this.setState({
    dishes: dishList,
    nonRatedDishes: nonRatedDishList
  })
}

showRecommendedDishes(toggleState){
  let dishList = this.state.dishes;
  if (toggleState){
    dishList.forEach(function(dish){
      dish.recommendedDisplay = dish.is_recommended;
    })
  } else {
    dishList.forEach(function(dish){
      dish.recommendedDisplay = true;
    })
  }
  let nonRatedDishList = this.state.nonRatedDishes;
  nonRatedDishList.forEach(function(dishType){
    if (Object.keys(dishType).length!==0){
      if (toggleState){
        dishType["dishes"].forEach(function(dish){
          dish.recommendedDisplay = dish.is_recommended;
        })
      } else {
        dishType["dishes"].forEach(function(dish){
          dish.recommendedDisplay = true;
        })
      }
    }
  })
  this.setState({
    dishes: dishList,
    nonRatedDishes: nonRatedDishList
  })
}

  dynamicSort(property,order) {
    var sort_order = 1;
    if(order === "descending"){
        sort_order = -1;
    }
    return function (a, b){
        if(a[property] < b[property]){
          return -1 * sort_order;
        }else if(a[property] > b[property]){
          return 1 * sort_order;
        }else{
          return 0 * sort_order;
        }
    }
}

  showRatingSortedDishes() {
    let sortedDishes = this.state.dishes.sort(this.dynamicSort("rating", "descending"));
    this.setState({
      dishes: sortedDishes
    })
    let nonRatedDishList = this.state.nonRatedDishes;
    let newNonRatedDishes = [];
    for(var i=0; i< nonRatedDishList.length; i++){
      var dishType = nonRatedDishList[i];
      if (Object.keys(dishType).length!==0){
        var dishes = []
        dishes = dishType.dishes;
        let sortedDishes = dishes.sort(this.dynamicSort("rating", "descending"));
        dishType.dishes = sortedDishes;
        // dishType.dishes = sortedDishes;
      }
      newNonRatedDishes.push(dishType);
    }
    this.setState({
      nonRatedDishes: newNonRatedDishes
    })
  }

  showPriceSortedDishes(order) {
    let sortedDishes = this.state.dishes.sort(this.dynamicSort("cost", order));
    this.setState({
      dishes: sortedDishes
    })
    let nonRatedDishList = this.state.nonRatedDishes;
    let newNonRatedDishes = [];
    for(var i=0; i< nonRatedDishList.length; i++){
      var dishType = nonRatedDishList[i];
      if (Object.keys(dishType).length!==0){
        var dishes = []
        dishes = dishType.dishes;
        let sortedDishes = dishes.sort(this.dynamicSort("cost", order));
        dishType.dishes = sortedDishes;
        // dishType.dishes = sortedDishes;
      }
      newNonRatedDishes.push(dishType);
    }
    this.setState({
      nonRatedDishes: newNonRatedDishes
    })
  }

  toggleVegDishes(toggleState) {
    let dishList = this.state.dishes;
    if (toggleState){
      dishList.forEach(function(dish){
        dish.vegDisplay = dish.is_veg;
      })
    } else {
      dishList.forEach(function(dish){
        dish.vegDisplay = true;
      })
    }
    let nonRatedDishList = this.state.nonRatedDishes;
    nonRatedDishList.forEach(function(dishType){
      if (Object.keys(dishType).length!==0){
        if (toggleState){
          dishType["dishes"].forEach(function(dish){
            dish.vegDisplay = dish.is_veg;
          })
        } else {
          dishType["dishes"].forEach(function(dish){
            dish.vegDisplay = true;
          })
        }
      }
    })
    this.setState({
      dishes: dishList,
      vegFilterActive: toggleState,
      nonRatedDishes: nonRatedDishList
    })
  }

  togglePersonalized() {
    let dishList = this.state.dishes;
    let nonRatedDishList = this.state.nonRatedDishes;
    if (!this.state.isPersonalizedShown){
      dishList.forEach(function(dish){
        if (!dish.is_personalized){
          dish.personalizedDisplay = false;
        } else {
          dish.personalizedDisplay = true;
        }
      })
      nonRatedDishList.forEach(function(dishType){
        if (Object.keys(dishType).length!==0){
          dishType["dishes"].forEach(function(dish){
            if (!dish.is_personalized){
              dish.personalizedDisplay = false;
            } else {
              dish.personalizedDisplay = true;
            }
          })
        }
      })
    } else {
      dishList.forEach(function(dish){
        dish.personalizedDisplay = true;
      })
      nonRatedDishList.forEach(function(dishType){
        if (Object.keys(dishType).length!==0){
          dishType["dishes"].forEach(function(dish){
            dish.personalizedDisplay = true;
          })
        }
      })
    }
    this.setState({
      dishes: dishList,
      isPersonalizedShown: !this.state.isPersonalizedShown,
      nonRatedDishes: nonRatedDishList
    })
  }

  closeSearchModal(){
    if (this.state.searchModal){
      this.setState({searchModal: false});
    }
  }

  updateCartModalDisplay(value) {
    this.setState({
      showCart: value
    })
  }

  updateCartChangedValue(value) {
    this.setState({
      cartChanged: value
    })
    let cartDetails = localStorage.getItem('cartDetailsAPI');
    let itemsInCart = 0;
    if (cartDetails !== null){
      let cartDetailsObj = JSON.parse(cartDetails);
      var itemVal = Object.values(cartDetailsObj.items)
      for(var i=0; i<itemVal.length; i++){
        itemsInCart = itemsInCart + itemVal[i].quantity
      }
    }
    this.setState({
      itemsInCart: itemsInCart
    });
  }

  getCustomisationData(selectedCustomisation, customQty, selectedDishCustomisationObj){
    this.setState({
      customisationOptionsModal: false
    })

    this.setState({
      selectedCustomisation : selectedCustomisation,
      customQty: customQty,
      selectedDishCustomisationObj: selectedDishCustomisationObj
    }, () => {
      this.handleAddCartWithCustomisation1(this.state.dishDetails, "add", false, this.state.selectedDishCustomisationObj);
    });
  }

  render() {
    let containerPadding = "0px";
    if (this.state.allDishesLoaded && this.state.nonRatedDishes.length !== 0){
      let nonRatedDishPresent = false;
      for (let i=0; i< this.state.nonRatedDishes.length; i++){
        if (this.state.nonRatedDishes[i].length !== 0){
          nonRatedDishPresent = true;
          break;
        }
      }
      if (nonRatedDishPresent){
        containerPadding = "20px";
      }
    }
    return (
      <div onClick={(e) => {
        e.stopPropagation();
        if (this.state.isOpen){
          this.setState({isOpen: false});
        }
      }}>
        <div className="loadingGif" style={this.state.dishLoading || this.state.moreDishesLoading || this.state.dishTypeLoading ? {visibility:"visible", opacity: '1'} : {visibility:"hidden", opacity: '0'}}>
          <img src={DaiDishLoading1} alt="DaiDish Loading..." style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"40%", marginTop:"0%"}}/>
        </div>
        <Header 
          toggleUserOptions={this.toggleUserOptions} 
          toggleSearchModal={this.toggleSearchModal}
          showAuthPopupModal={this.showAuthPopupModal}
          searchPresent={true}
          itemsInCart={this.state.itemsInCart}
          updateCartModalDisplay={this.updateCartModalDisplay}
          showCart={this.state.showCart}
        />
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <UserCart 
          cartChanged={this.state.cartChanged}
          showCart={this.state.showCart}
          updateCartChangedValue={this.updateCartChangedValue}
          updateCartModalDisplay={this.updateCartModalDisplay}
          itemsInCart={this.state.itemsInCart}
          restaurantId={this.state.restaurantId}
          loadRestaurantDetails={this.loadRestaurantDetails}
          defaultAddresses={this.state.defaultAddresses}
          toggleConfirmationBox={this.toggleConfirmationBox}
        />
        <div id="main-content">
          <Cover 
            showSearchModal={this.state.searchModal}
            showContactModal={this.showContactModal}
            restaurantDetailsLoaded={this.state.restaurantDetailsLoaded}
            restaurant={this.state.restaurant}
            closeSearchModal={this.closeSearchModal}
            cuisines={this.state.cuisines}
            dishes={this.state.recommendedDishes}
            restaurants={this.state.restaurants}
            isDishScreen={false}
          />          
          <ContactRestaurant
            showContactModal={this.state.contactModal}
            closeContactModal={this.closeContactModal}
            restaurantDetailsLoaded={this.state.restaurantDetailsLoaded}
            restaurant={this.state.restaurant}
          />
          <Actions
            showCuisinesModal={this.showCuisinesModal}
            isDishScreen={false}
            showRecommendedDishes={this.showRecommendedDishes}
            showRatingSortedDishes={this.showRatingSortedDishes}
            showPriceSortedDishes={this.showPriceSortedDishes}
            allDishesLoaded={this.state.allDishesLoaded}
            toggleVegDishes={this.toggleVegDishes}
            togglePersonalized={this.togglePersonalized}
            isPersonalizedShown={this.state.isPersonalizedShown}
            isLoggedIn={this.state.isLoggedIn}
            dishes={this.state.dishes}
            nonRatedDishes={this.state.nonRatedDishes}
            activeHeaderType={this.state.activeHeaderType}
            searchRestaurantDish={this.searchRestaurantDish}
            itemsInCart={this.state.itemsInCart}
            updateCartModalDisplay={this.updateCartModalDisplay}
            showCart={this.state.showCart}
            cuisinesModal={this.state.cuisinesModal} 
            handleSearchInput={this.handleSearchInput}
            toggleUserOptions={this.toggleUserOptions}
            showAuthPopupModal={this.showAuthPopupModal}
            filters={this.state.dishTypes}
            setCurrentRestoMenu={this.setCurrentRestoMenu}
            currentRestoMenu={this.state.currentRestoMenu}
          />
          <Filters
            filters={this.state.dishTypes}
            dishTypeLoading={this.state.dishTypeLoading}
            allDishTypesLoaded={this.state.allDishTypesLoaded}
            allDishesLoaded={this.state.allDishesLoaded}
            filterDishTypes={this.filterDishTypes}
            dishes={this.state.dishes}
            updateDishTypes={this.updateDishTypes}
            dishesChanged={this.state.dishesChanged}
            isDishScreen={false}
            setCurrentRestoMenu={this.setCurrentRestoMenu}
            currentRestoMenu={this.state.currentRestoMenu}
          />
          {/* <ActionsMobile
            showCuisinesModal={this.showCuisinesModal}
            isDishScreen={false}
            showRecommendedDishes={this.showRecommendedDishes}
            showRatingSortedDishes={this.showRatingSortedDishes}
            allDishesLoaded={this.state.allDishesLoaded}
            toggleVegDishes={this.toggleVegDishes}
            togglePersonalized={this.togglePersonalized}
            isPersonalizedShown={this.state.isPersonalizedShown}
            isLoggedIn={this.state.isLoggedIn}
            dishes={this.state.dishes}
            activeHeaderType={this.state.activeHeaderType}
          /> */}
          {(!this.state.isMobileScreen || (this.state.isMobileScreen && !this.state.mobileMenuFilter)) && <BestDish
            showRatingsModal={this.showRatingsModal}
            showReviewsModal={this.showReviewsModal}
            bestDishReady={this.state.bestDishReady}
            bestDishes={this.state.bestDishes}
            dishesAddingState={this.state.dishesAddingState}
            toggleRateHover={this.toggleRateHover}
            toggleImageHover={this.toggleImageHover}
            toggleReviewHover={this.toggleReviewHover}
            toggleLikeButton={this.toggleLikeButton}
            showAuthPopupModal={this.showAuthPopupModal}
            addToCartAction={this.addToCartAction_TEMP}
            repeatCustomisationMessage={this.state.repeatCustomisationMessage}
            showRepeatCustomisation={this.state.showRepeatCustomisation}
            temp_customizations={this.state.temp_customizations}
            toggleRepeatCustomisation={this.toggleRepeatCustomisation}
            getCustomisationText={this.getCustomisationText}
            handlePopOverClose={this.handlePopOverClose}
            popOverAnchorEl={this.state.popOverAnchorEl}
            popUpId={this.state.popUpId}
            restaurant={this.state.restaurant}
            popDishId={this.state.popDishId}
            popDishState={this.state.popDishState}
            bestDishDescription={this.state.bestDishDescription}
          />}
          {(!this.state.isMobileScreen || (this.state.isMobileScreen && !this.state.mobileMenuFilter)) && <PopularDish
            restaurant={this.state.restaurant}
            showRatingsModal={this.showRatingsModal}
            showReviewsModal={this.showReviewsModal}
            bestDishReady={this.state.bestDishReady}
            dishes={this.state.dishes}
            toggleRateHover={this.toggleRateHover}
            toggleImageHover={this.toggleImageHover}
            toggleReviewHover={this.toggleReviewHover}
            toggleLikeButton={this.toggleLikeButton}
            showAuthPopupModal={this.showAuthPopupModal}
            addToCartAction={this.addToCartAction_TEMP}
            repeatCustomisationMessage={this.state.repeatCustomisationMessage}
            showRepeatCustomisation={this.state.showRepeatCustomisation}
            temp_customizations={this.state.temp_customizations}
            toggleRepeatCustomisation={this.toggleRepeatCustomisation}
            getCustomisationText={this.getCustomisationText}
            handlePopOverClose={this.handlePopOverClose}
            popOverAnchorEl={this.state.popOverAnchorEl}
            popUpId={this.state.popUpId}
            restaurantId={this.state.restaurantId}
            restoName={this.state.restaurant.name}
            popDishId={this.state.popDishId}
            popDishState={this.state.popDishState}
            getPopularDishes={this.getPopularDishes}
            dishesAddingState={this.state.dishesAddingState}
            popularDishes={this.state.popularDishes}
            popularDishDescription={this.state.popularDishDescription}
          />}
          <DishRatingInformation
            showRatingsInformationModal={this.state.ratingInformationModal} 
            closeRatingsInformationModal={this.closeRatingsInformationModal}
          />
          <AuthPopup
            showAuthPopupModal={this.state.authPopupModal}
            closeAuthPopupModal={this.closeAuthPopupModal}
            setActiveForm={this.setActiveForm}
            activeForm={this.state.activeForm}
          />
          <CustomisationOptions
            dishDetails={this.state.dishDetails}
            getCustomisationData={this.getCustomisationData}
            selectedCustomisation={this.state.selectedCustomisation}
            showCustomisationOptionsModal={this.state.customisationOptionsModal}
            closeCustomisationOptionsModal={this.closeCustomisationOptionsModal}
            handleAddCart={this.handleAddCart}
            isMobileScreen={this.state.isMobileScreen}
            setDishesAddingState={this.setDishesAddingState}
          />
          <div style={{backgroundColor: "#FFFEF5", paddingTop: containerPadding}}>
            {this.state.nonRatedDishes.map((nonRatedDish, index) => {
              return(
                  <NonRatedDish
                    nonRatedDishes={nonRatedDish}
                    key={index}
                    showRatingsModal={this.showRatingsModal}
                    toggleNonRatedLikeButton={this.toggleNonRatedLikeButton}
                    showAuthPopupModal={this.showAuthPopupModal}
                    getCustomisationData={this.getCustomisationData}
                    selectedCustomisation={this.state.selectedCustomisation}
                    addToCartAction={this.addToCartAction_TEMP}
                    updateCartChangedValue={this.updateCartChangedValue}
                    restaurant={this.state.restaurant}
                    toggleConfirmationBox={this.toggleConfirmationBox}
                    getReplacementDish={this.getReplacementDish}
                    repeatCustomisationMessage={this.state.repeatCustomisationMessage}

                    showRepeatCustomisation={this.state.showRepeatCustomisation}
                    temp_customizations={this.state.temp_customizations}
                    toggleRepeatCustomisation={this.toggleRepeatCustomisation}
                    getCustomisationText={this.getCustomisationText}
                    handlePopOverClose={this.handlePopOverClose}
                    popOverAnchorEl={this.state.popOverAnchorEl}
                    popUpId={this.state.popUpId}
                    
                    popDishId={this.state.popDishId}
                    popDishState={this.state.popDishState}

                    dishesAddingState={this.state.dishesAddingState}
                  />
              )
            })}
          </div>
          <DishRating 
            dish={this.state.dishSelected} 
            showRatingsModal={this.state.ratingModal} 
            closeRatingsModal={this.closeRatingsModal}
            showSuccessModal={this.showSuccessModal}
          />
          <RatingSuccess
            showSuccessModal={this.state.successModal}
            closeSuccessModal={this.closeSuccessModal}
            emoji={this.state.emoji}
            isFiveStar={this.state.isFiveStar}
            ratingNumber={this.state.ratingNumber}
          />
          <LogoutConfirm
            showLogoutModal={this.state.logoutModal}
            toggleLogoutModal={this.toggleLogoutModal}
          />
          <ConfirmationBox
            confirmationBoxMessage={this.state.confirmationBoxMessage}
            showConfirmationBox={this.state.showConfirmationBox}
            toggleConfirmationBox={this.toggleConfirmationBox}
            setDishesAddingState={this.setDishesAddingState}
            dishDetails={this.state.temp_dishDetails}

          />
          {/* <RepeatCustomisation
            repeatCustomisationMessage={this.state.repeatCustomisationMessage}
            showRepeatCustomisation={this.state.showRepeatCustomisation}
            temp_customizations={this.state.temp_customizations}
            toggleRepeatCustomisation={this.toggleRepeatCustomisation}
            getCustomisationText={this.getCustomisationText}
          /> */}
          <DishReview 
            dish={this.state.dishSelected} 
            showReviewsModal={this.state.reviewModal} 
            closeReviewsModal={this.closeReviewsModal}
          />
          <DishCuisines
            showCuisinesModal={this.state.cuisinesModal} 
            closeCuisinesModal={this.closeCuisinesModal}
            cuisines={this.state.cuisines}
          />
          <RestoMenu
            filters={this.state.dishTypes}
            filterDishTypes={this.filterDishTypes}
            mobileMenuFilter={this.state.mobileMenuFilter}
            setMobileMenuFilter={this.setMobileMenuFilter}
          />
          <Footer/>
        </div>
      </div>
    )
  };
}

export default Restaurants;
