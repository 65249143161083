import React, { Component } from 'react';
import axios from 'axios';
import update from 'react-addons-update';
import Header from '../components/Dishes/Header';
import Offers from '../components/Dishes/Offers';
import Actions from '../components/Dishes/Actions';
// import ActionsMobile from '../components/Dishes/ActionsMobile';
import Filters from '../components/Dishes/Filters';
import DishList from '../components/Dishes/DishList';
import DishRating from '../components/Commons/DishRating';
import DishReview from '../components/Commons/DishReview';
import dish_card1 from '../assets/images/dish_card1.png';
import dish_card2 from '../assets/images/dish_card2.png';
import dish_card3 from '../assets/images/dish_card3.png';
import dish_card4 from '../assets/images/dish_card4.png';
import dish_card5 from '../assets/images/dish_card5.png';
import dish_card6 from '../assets/images/dish_card6.png';
import dish_card7 from '../assets/images/dish_card7.png';
import dish_card8 from '../assets/images/dish_card8.png';
import dish_card9 from '../assets/images/dish_card9.png';
import dish_card10 from '../assets/images/dish_card10.png';
import dish_card11 from '../assets/images/dish_card11.png';
import dish_card12 from '../assets/images/dish_card12.png';
import beverages from '../assets/images/beverages.svg';
import appetizers from '../assets/images/appetizers.svg';
import breakfast from '../assets/images/breakfast.svg';
import dessert from '../assets/images/dessert.svg';
import main_course from '../assets/images/main_course.svg';
import salads from '../assets/images/salads.svg';
import DishCuisines from '../components/Commons/DishCuisines';
import RatingSuccess from '../components/Commons/RatingSuccess';
import LogoutConfirm from '../components/Commons/LogoutConfirm';
import ConfirmationBox from '../components/Commons/ConfirmationBox';
import CONSTANTS from '../config/constants';
import UserOptions from '../components/Dishes/UserOptions';
import Footer from '../components/Commons/Footer';
import DishRatingInformation from '../components/Commons/DishRatingInformation';
import AuthPopup from '../components/Commons/AuthPopup';
import DaiDishLoading1 from '../assets/images/DaiDishLoading1.gif';
import UserCart from '../components/Restaurants/UserCart';
import '../assets/styles/UserCart.css';
import { setDeviceToken } from '../services/notification_handler';
import CustomisationOptions from '../components/Commons/CustomisationOptions';
import one from '../assets/images/emojis/one.png';
import one_point_five from '../assets/images/emojis/one_point_five.png';
import two from '../assets/images/emojis/two.png';
import two_point_five from '../assets/images/emojis/two_point_five.png';
import three from '../assets/images/emojis/three.png';
import three_point_five from '../assets/images/emojis/three_point_five.png';
import four from '../assets/images/emojis/four.png';
import four_point_five from '../assets/images/emojis/four_point_five.png';
import five from '../assets/images/emojis/five.png';
import RatingStarSelected from "../assets/images/ratingStarSelected.png";
import { checkValidResult, getDishFilterObject, getDishSortingObject, getFormatedDishAddingStates, getFormattedCuisines, getFormattedDishes, getFormattedDishTypeList, getFormattedOfferList, isSameArray } from '../utils';


class Dishes extends Component {

  constructor(props) {
    super(props);
    this.showRatingsModal = this.showRatingsModal.bind(this);
    this.closeRatingsModal = this.closeRatingsModal.bind(this);
    this.closeRatingsInformationModal = this.closeRatingsInformationModal.bind(this);
    this.showReviewsModal = this.showReviewsModal.bind(this);
    this.closeReviewsModal = this.closeReviewsModal.bind(this);
    this.showCuisinesModal = this.showCuisinesModal.bind(this);
    this.closeCuisinesModal = this.closeCuisinesModal.bind(this);
    this.toggleSearchModal = this.toggleSearchModal.bind(this);
    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.loadDishes = this.loadDishes.bind(this);
    this.loadCuisines = this.loadCuisines.bind(this);
    this.loadDishTypes = this.loadDishTypes.bind(this);
    this.showRecommendedDishes = this.showRecommendedDishes.bind(this);
    this.toggleImageHover = this.toggleImageHover.bind(this);
    this.toggleRateHover = this.toggleRateHover.bind(this);
    this.toggleReviewHover = this.toggleReviewHover.bind(this);
    this.toggleLikeButton = this.toggleLikeButton.bind(this);
    this.showPriceSortedDishes = this.showPriceSortedDishes.bind(this);
    this.showDistanceSortedDishes = this.showDistanceSortedDishes.bind(this);
    this.showDiscountSortedDishes = this.showDiscountSortedDishes.bind(this);
    this.toggleVegDishes = this.toggleVegDishes.bind(this);
    this.filterDishTypes = this.filterDishTypes.bind(this);
    this.filterCuisineTypes = this.filterCuisineTypes.bind(this);
    this.showRatingSortedDishes = this.showRatingSortedDishes.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.updateCuisinesList = this.updateCuisinesList.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.applyFilterImages = this.applyFilterImages.bind(this);
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.togglePersonalized = this.togglePersonalized.bind(this);
    this.toggleFreeDelivery = this.toggleFreeDelivery.bind(this);
    this.loadOffers = this.loadOffers.bind(this);
    this.applyCuisineSearch = this.applyCuisineSearch.bind(this);
    this.applyDishTypeSearch = this.applyDishTypeSearch.bind(this);
    this.searchDish = this.searchDish.bind(this);
    this.clearSearchFilter = this.clearSearchFilter.bind(this);
    this.closeSearchModal = this.closeSearchModal.bind(this);
    this.showAuthPopupModal = this.showAuthPopupModal.bind(this);
    this.closeAuthPopupModal = this.closeAuthPopupModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.updateCartChangedValue = this.updateCartChangedValue.bind(this);
    this.updateCartModalDisplay = this.updateCartModalDisplay.bind(this);
    this.toggleConfirmationBox = this.toggleConfirmationBox.bind(this);
    this.getReplacementDish = this.getReplacementDish.bind(this);
    this.addToCartAction_TEMP = this.addToCartAction_TEMP.bind(this);
    this.getCustomisationData = this.getCustomisationData.bind(this);
    this.closeCustomisationOptionsModal = this.closeCustomisationOptionsModal.bind(this);
    this.toggleRepeatCustomisation = this.toggleRepeatCustomisation.bind(this);
    this.handlePopOverClose = this.handlePopOverClose.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.getEmoji = this.getEmoji.bind(this);
    this.setDishesAddingState = this.setDishesAddingState.bind(this);
    this.state = { 
      ratingModal: false, 
      ratingInformationModal: false,
      dishSelected: {}, 
      reviewModal: false, 
      cuisinesModal: false, 
      searchModal: false, 
      successModal: false, 
      ratingNumber: "",
      currentOrder: "",
      currentSortType: "",
      emoji: "", 
      isFiveStar: false,
      dishLoading: true,
      initialDishLoading: true,
      dishDetails:{},
      dishes: [],
      replacementDish: [],
      cuisines: [],
      cuisineLoading: true,
      moreCuisineLoading: false,
      allDishesLoaded: false, 
      dishTypesLoaded: 0,
      dishTypes: [],
      filters: [],
      filteredCuisines: [],
      filteredDishTypes: [],
      dishSearchText: "",
      dishTypeLoading: true,
      moreDishTypeLoading: false,
      allDishTypesLoaded: false,
      allCuisinesLoaded: false,
      vegFilterActive: false,
      cuisineFilterActive: false,
      dishTypeFilterActive: false,
      isOpen: false,
      logoutModal: false,
      isPersonalizedShown: false,
      isFeeDeliveryShown: false,
      isLoggedIn: false,
      cuisinesUpdated: false,
      isDishTypeUpdating: false,
      offersFetched: false,
      offers: [],
      restaurants: [],
      cartChanged: false,
      showCart: false,
      itemsInCart: 0,
      authPopupModal: false,
      activeForm: "login",
      activeHeaderType: "header",
      isMobileScreen: false,
      confirmationBoxMessage: "",
      showConfirmationBox: false,
      defaultAddresses: "",
      addresses: {},
      customisationOptionsModal: false,
      selectedCustomisation : "",
      repeatCustomisationMessage:"",
      temp_customizations: [],
      popUpId: undefined,
      popDishState : false,
      popOverAnchorEl : null,
      customQty: 1,
      dishesAddingState: [],
      showRepeatCustomisation: false,
      customAddClick: false,
      temp_dishDetails: {},
      temp_changeType: "",
      nonServicable: false,
      temp_dishAlreadyPresent: false,
      dishImages: [dish_card1, dish_card2, dish_card3, dish_card4, dish_card5, dish_card6,
        dish_card7, dish_card8, dish_card9, dish_card10, dish_card11, dish_card12],
      dishTypeImages: [beverages, appetizers, breakfast, salads, main_course, dessert,
        beverages, appetizers, breakfast, salads, main_course, dessert]
    };
  } 

  setActiveForm(form){
    this.setState({
      activeForm: form
    });
  }

  componentDidMount(){
    window.fbq('track', 'PageView');

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if (localStorage.getItem("location_present") !== "yes"){
      this.props.history.replace("/");
    }
    var token = localStorage.getItem("auth_token");
    var preferencesPresent = localStorage.getItem("preferences_present");
    if (preferencesPresent !== "true" & token !== null && token !== ""){
      this.props.history.replace("/preferences");
    }
    // let cartDetails = localStorage.getItem('cartDetailsAPI');
    // let itemsInCart = 0;
    
    // if (cartDetails !== null){
    //   let cartDetailsObj = JSON.parse(cartDetails);
    //   var itemVal = Object.values(cartDetailsObj.items)
    //   for(var i=0; i<itemVal.length; i++){
    //     itemsInCart = itemsInCart + itemVal[i].quantity
    //   }
    // }




    
    // this.setState({
    //   itemsInCart: itemsInCart
    // });
    if (token !== null && token !== ""){

      let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
      let firebaseReq = { 
        'token': localStorage.getItem('device_token')
      }
      let firebaseHeaders = {
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
      }
      axios.post(firebaseUrl, firebaseReq,  {
        headers: firebaseHeaders
      }).then((response) => {});

      this.setState({
        isLoggedIn: true
      })
    }
    if (this.state.dishes.length === 0){
      var elements6 = document.getElementsByClassName("DishesHeader");
      for (var h = 0; h < elements6.length; h++) {
          elements6[h].classList.add('HeaderShadowBottom');
      }
    }
    window.addEventListener("scroll", event => {
      if (this.state.dishes.length === 0){
        var elements5 = document.getElementsByClassName("DishesHeader");
        for (var h = 0; h < elements5.length; h++) {
            elements5[h].classList.add('HeaderShadowBottom');
        }
      } else {
        if (window.scrollY > 270){
          var elements = document.getElementsByClassName("DishesHeader");
          for (var i = 0; i < elements.length; i++) {
              elements[i].classList.add('HeaderShadowBottom');
          }
        } else {
          var elements2 = document.getElementsByClassName("DishesHeader");
          for (var j = 0; j < elements2.length; j++) {
              elements2[j].classList.remove('HeaderShadowBottom');
          }
        }
      }
      var actionsOffset = 280;
      if (this.state.isMobileScreen){
        actionsOffset = 280;
      }
      if (window.scrollY > actionsOffset){
        // var elements3 = document.getElementsByClassName("DishesActionsIdentifier");
        // for (var k = 0; k < elements3.length; k++) {
        //     elements3[k].classList.add('DishesActionsHeader');
        // }
        this.setState({
          activeHeaderType: 'actions'
        });
      } else {
        if (this.state.activeHeaderType !== 'header'){
          var elements4 = document.getElementsByClassName("DishesActionsIdentifier");
          for (var l = 0; l < elements4.length; l++) {
            elements4[l].classList.add('DishesActionsHeaderDisappear');
          }
          setTimeout(() => {
            var elements4 = document.getElementsByClassName("DishesActionsIdentifier");
            for (var l = 0; l < elements4.length; l++) {
              elements4[l].classList.remove('DishesActionsHeaderDisappear');
            }
            this.setState({
              activeHeaderType: 'header'
            });
          }, 500)
        }
      }
    });
    var latitude = localStorage.getItem("user_latitude");
    var longitude = localStorage.getItem("user_longitude");
    const queryParams = `?page=1&latitude=${latitude}&longitude=${longitude}`;
    let offerUrl = CONSTANTS.hostURL + `api/client/offers/get/${queryParams}`;
    let dishTypeUrl = CONSTANTS.hostURL + "api/client/dish-types/get/";
    let dishUrl = CONSTANTS.hostURL +  `api/client/dishes/recommended/${queryParams}`;
    this.loadDishes(dishUrl, 0, true);
    this.loadDishTypes(dishTypeUrl, 0);
    this.loadOffers(offerUrl, 0);
    this.getCartDetails();
    this.getUserAddress();
    setDeviceToken();
    navigator.serviceWorker.addEventListener('message', event => {
      if ('data' in event.data.data){
        var orderDetails = JSON.parse(event.data.data.data);
        var orderEvent = event.data.data.event;
        if (orderEvent === 'new_order'){
          this.setState({
            newOrderModal: true,
            newOrderId: orderDetails.order_id
          })
        } 
      }
    });
  }

  refreshToken(){
    console.log("Token Refreshed")
    let url = CONSTANTS.hostURL + "api/accounts/v2/login/refresh-session/";
    let req = {
      'refresh': localStorage.getItem("refresh_token")
    };
    axios.post(url, req).then((response) => {
      let data = response.data;
      if (data.status === 1){
        localStorage.setItem("auth_token", data.data.access);
        localStorage.setItem("refresh_token", data.data.refresh);
        return(true)
      } else {
        console.log("Token Refresh Issue")
        return(false)
      }
    }).catch((error) => {
      if (error.response){
        if (error.response.data.status === -1){
          console.log("Token Refresh Issue")
        }
      }
      return(false)
    });
  }
  
  getCartDetails(){
    var token = localStorage.getItem("auth_token");
    if (token){
      var headers = {
        'Authorization' : 'Bearer ' + token
      }
      let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
      axios.get(url, {
          headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1 && 'id' in data.data){
          let cart = data.data;
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          this.setState({
            cartChanged: true
          })
          let cartDetails = {};
          let itemsInCart = 0;
          let cartItems = [];
            for (var key in cart.items){
              if(cart.items[key].customizations.length > 1){
                for(var k=0; k<cart.items[key].customizations.length; k++){
                  cartItems.push({
                    'DishID': parseInt(key),
                    'DishName': cart.items[key].name,
                    'DishCost': cart.items[key].customizations[k].sub_total,
                    'DishQuantity': cart.items[key].customizations[k].quantity,
                    "DishImage": cart.items[key].image, 
                    'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                    'Customisation': cart.items[key].customizations[k]
                  });
                  itemsInCart = itemsInCart + cart.items[key].customizations[k].quantity
                }
              }
              else{
                cartItems.push({
                  'DishID': parseInt(key),
                  'DishName': cart.items[key].name,
                  'DishCost': cart.items[key].mrp,
                  'DishQuantity': cart.items[key].quantity,
                  "DishImage": cart.items[key].image, 
                  'DishIsVeg': cart.items[key].choice === 'V' ? true : false,
                  'Customisation': cart.items[key].customizations[0]
                });
                itemsInCart = itemsInCart + cart.items[key].quantity
              }
            }
            cartDetails.RestoID = cart.restaurant;
            cartDetails.RestoName = cart.restaurant_name;
            cartDetails.Cart_Items = cartItems;
            // itemsInCart = cartDetails.Cart_Items.length;
            localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
          this.setState({
            itemsInCart: itemsInCart,
            cartChanged: false
          });
        } else {
          if(data.status === -1 && data.errors.message.includes("Token")){
            localStorage.removeItem('cartDetailsAPI');
            // alert("refresh token")
            this.refreshToken()
            // console.log("421")
            this.getCartDetails();
          }
          if (data.status === -1){
            localStorage.removeItem('cartDetailsAPI');
          }
        }
      }).catch((error) => {
        if (error.response){
          if (error.response.data.status === -1){
            localStorage.removeItem('cartDetailsAPI');
          }
        }
      });
    }
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2-lat1);
    var dLon = this.toRad(lon2-lon1);
    var lat1_rad = this.toRad(lat1);
    var lat2_rad = this.toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1_rad) * Math.cos(lat2_rad); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  loadDishes(url, dishesLoaded, isInitial = false) {
    let headers = {}
    let token = localStorage.getItem("auth_token");
    if (token) {
      headers = {
        'Authorization' : 'Bearer ' + token
      };
    }
    axios.get(url, {
      headers: headers
    }).then(res => {
      if (checkValidResult(res)){
        let data = res?.data?.data;
        let dishList = getFormattedDishes(data?.results, dishesLoaded + 1);
        let dishesAddingStateList = getFormatedDishAddingStates(data?.results);
        this.setState({
          dishes: [...(dishesLoaded === 0 ? [] : this.state.dishes), ...dishList],
          dishLoading: false,
          initialDishLoading: false,
          dishesAddingState: [...this.state.dishesAddingState, ...dishesAddingStateList],
        });
        if (data?.next){
          this.loadDishes(data?.next, (data?.results ?? []).length + dishesLoaded);
        } else {
          this.setState({
            allDishesLoaded: true
          })
          if (isInitial && dishesLoaded === 0 && (data?.results ?? []).length === 0){
            this.setState({
              nonServicable: true
            });
          }
        }
      }
    })
  }

  loadCuisines(url, cuisinesLoaded) {
    axios.get(url).then(res => {
      let cuisineList = getFormattedCuisines(res?.data?.results, cuisinesLoaded);
      this.setState({
        cuisines: [...this.state.cuisines, ...cuisineList],
        cuisineLoading: false,
      });
      if (res?.data?.next){
        this.loadCuisines(res?.data?.next, (res?.data?.results ?? []).length + cuisinesLoaded);
      } else {
        this.setState({
          allCuisinesLoaded: true
        });
      }
    })
  }

  loadOffers(url, offersLoaded){
    axios.get(url).then(res => {
      if (checkValidResult(res)){
        let data = res?.data?.data;
        let offerItems = getFormattedOfferList(data?.results, offersLoaded);
        this.setState({
          offers : [...this.state.offers, ...offerItems]
        })
        if (data?.next){
          this.loadOffers(res.data.next, (data?.results ?? []).length + offersLoaded);
        } else {
          this.setState({
            offersFetched: true
          })
        }
      }
    })
  }

  loadDishTypes(url, dishTypesLoaded) {
    axios.get(url).then(res => {
      if (checkValidResult(res)){
        let data = res?.data?.data;
        let filterList = getFormattedDishTypeList(data?.results, dishTypesLoaded);
        this.setState({
          filters: [...this.state.filters, ...filterList],
          dishTypeLoading: false
        });
        if (data?.next){
          this.loadDishTypes(res.data.next, (data?.results ?? []).length + dishTypesLoaded);
        } else {
          this.setState({
            allDishTypesLoaded: true
          });
        }
      }
    })
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 13: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling() {// mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling() {
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() { 
    if(this.state.isOpen || this.state.logoutModal ){
      this.disableScrolling();
      document.getElementById("main-content").style.overflow = "hidden";
      //document.getElementById("main-content").style.height = "100vh";
    } else {
      this.enableScrolling();
      document.getElementById("main-content").style.overflow = "scroll";
      document.getElementById("main-content").style.height = "auto";
    }
    if (this.state.dishes.length === 0){
      var elements3 = document.getElementsByClassName("DishesHeader");
      for (var h = 0; h < elements3.length; h++) {
          elements3[h].classList.add('HeaderShadowBottom');
      }
    } else {
      var elements4 = document.getElementsByClassName("DishesHeader");
      for (var g = 0; g < elements4.length; g++) {
          elements4[g].classList.remove('HeaderShadowBottom');
      }
    }
  }

  componentWillUnmount(){
    this.enableScrolling();
  }

  showRatingsModal(dish) {
    this.setState({
      ratingInformationModal: true,
      dishSelected: dish
    });
    setTimeout(() => {
      this.setState({
        ratingInformationModal: false
      })
      if (!this.state.ratingModal){
        this.setState({
          ratingModal: true
        })
      }
    }, 4000);
  };

  closeRatingsModal() {
    this.setState({
      ratingModal: false
    });
  };

  closeRatingsInformationModal() {
    this.setState({
      ratingInformationModal: false,
      ratingModal: true
    });
  };

  showReviewsModal(dish) {
    this.setState({
      reviewModal: true,
      dishSelected: dish
    });
  };

  showAuthPopupModal(form) {
    this.setState({
      authPopupModal: true,
    })
    if (form != null){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthPopupModal() {
    this.setState({
      authPopupModal: false
    })
  }

  closeReviewsModal() {
    this.setState({
      reviewModal: false
    });
  };

  showCuisinesModal() {
    if (!this.state.allCuisinesLoaded){
      let url = CONSTANTS.hostURL + "api/main_app/cuisine/";
      this.loadCuisines(url, 0);
    }
    let items = [...this.state.cuisines];
    for (var i=0; i <items.length; i++){
      let item = items[i];
        if (item.activated === true){
          item.isActive = true;
        } else {
          item.isActive = false;
        }
    }
    this.setState({
      cuisinesModal: true,
      cuisines: items
    });
  };

  closeCuisinesModal() {
    this.setState({
      cuisinesModal: false
    });
  };

  toggleSearchModal() {
    window.scrollTo(0, 0);
    // document.getElementById("global_search_bar").focus();
    if (this.state.searchModal === false){
      this.setState({
        searchModal: true
      });
    }
  }

  showSuccessModal(rating) {
    if (rating.value === 5){
      this.setState({
        isFiveStar: true
      })
    }
    this.setState({
      successModal: true,
      emoji: this.getEmoji(rating.value),
      ratingNumber: rating.value
    });
  };

  getEmoji(ratingValue){
    switch(ratingValue) {
      case 1:
        return one;
      case 1.5:
        return one_point_five;
      case 2:
        return two;
      case 2.5:
        return two_point_five;
      case 3:
        return three;
      case 3.5:
        return three_point_five;
      case 4:
        return four;
      case 4.5:
          return four_point_five;
      case 5:
        return five;
      default:
        return RatingStarSelected;
    }
  }

  closeSuccessModal() {
    this.setState({
      successModal: false,
      isFiveStar: false
    });
  };

  toggleLogoutModal() {
    this.setState({
      logoutModal: !this.state.logoutModal
    })
  }

  togglePersonalized() {
    this.getFilteredDishList(this.state.vegFilterActive, !this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, this.state.currentSortType, this.state.currentOrder);
    this.setState({
      isPersonalizedShown: !this.state.isPersonalizedShown
    });
  };

  toggleFreeDelivery() {
    let dishList = this.state.dishes;
    if (!this.state.isFeeDeliveryShown){
      dishList.forEach(function(dish){
        if (!dish.is_free_delivery){
          dish.freeDeliveryDisplay = false;
        } else {
          dish.freeDeliveryDisplay = true;
        }
      })
    } else {
      dishList.forEach(function(dish){
        dish.freeDeliveryDisplay = true;
      })
    }
    this.setState({
      dishes: dishList,
      isFeeDeliveryShown: !this.state.isFeeDeliveryShown
    })
  }

  handleSearchInput(value) {
    if (value !== this.state.dishSearchText && (value.length > 2 || (value.length < 3 && this.state.dishSearchText.length >=3))){
      this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, value, this.state.currentSortType, this.state.currentOrder);
    }
    this.setState({
      dishSearchText: value
    });
  }

  showRecommendedDishes(toggleState){
    let dishList = this.state.dishes;
    if (toggleState){
      dishList.forEach(function(dish){
        dish.recommendedDisplay = dish.is_recommended;
      })
    } else {
      dishList.forEach(function(dish){
        dish.recommendedDisplay = true;
      })
    }
    this.setState({
      dishes: dishList,
    })
  }

  dynamicSort(property,order) {
    var sort_order = 1;
    if(order === "descending"){
        sort_order = -1;
    }
    return function (a, b){
        if(a[property] < b[property]){
          return -1 * sort_order;
        }else if(a[property] > b[property]){
          return 1 * sort_order;
        }else{
          return 0 * sort_order;
        }
    }
}

  showPriceSortedDishes(order) {
    this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, "cost", order);
    this.setState({
      currentSortType: "cost",
      order: order
    });
  }

  showRatingSortedDishes() {
    if (this.state.currentSortType !== "rating") {
      this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, "average", "descending");
      this.setState({
        currentSortType: "rating",
        order: "descending"
      });
    }
  }

  showDistanceSortedDishes(order) {
    this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, "distance", order);
    this.setState({
      currentSortType: "distance",
      order: order
    });
  }

  showDiscountSortedDishes() {
    if (this.state.currentSortType !== "discount") {
      this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, "discount", "descending");
      this.setState({
        currentSortType: "discount",
        order: "descending"
      });
    }
  }

  toggleImageHover(index) {
    let items = [...this.state.dishes];
    let item = {...items[index]};
    item.imageHover  = !item.imageHover;
    this.setState(update(this.state, {
      dishes: {
            [index]: {
                $set: item
            }
        }
    }));
}

  toggleRateHover(index) {
      let items = [...this.state.dishes];
      let item = {...items[index]};
      item.rateHover  = !item.rateHover;
      this.setState(update(this.state, {
        dishes: {
              [index]: {
                  $set: item
              }
          }
      }));
  }

  toggleReviewHover(index) {
      let items = [...this.state.dishes];
      let item = {...items[index]};
      item.reviewHover  = !item.reviewHover;
      this.setState(update(this.state, {
        dishes: {
              [index]: {
                  $set: item
              }
          }
      }));
  }

  toggleLikeButton(index) {
      let items = [...this.state.dishes];
      let item = {...items[index]};
      item.liked  = !item.liked;
      this.setState(update(this.state, {
        dishes: {
              [index]: {
                  $set: item
              }
          }
      }));
      var token = localStorage.getItem("auth_token");
      if (token!== null){
        var user = JSON.parse(localStorage.getItem("user"));
        var likes_list = user.likes;
        if (item.liked){
          likes_list.push(item.dishId);
        } else {
          let dishIndex = likes_list.indexOf(item.dishId)
          if (dishIndex > -1) {
            likes_list.splice(dishIndex, 1);
          }
        }
        let type = "like";
        if (!item.liked){
          type = "dislike";
        }
        var headers = {
          'Authorization' : 'Bearer ' + token 
        }
        var req = {
          likes_list: [item.dishId],
          type: type
        }
        var url = CONSTANTS.hostURL + "api/accounts/likes/";
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
            var user = JSON.parse(localStorage.getItem("user"));
            user.likes = likes_list;
            localStorage.setItem("user", JSON.stringify(user));
        });
      } else {
        item.liked  = !item.liked;
        this.setState(update(this.state, {
          dishes: {
                [index]: {
                    $set: item
                }
            }
        }));
      }
  }

  filterCuisineTypes(filterList) {
    if (!isSameArray(filterList, this.state.filteredCuisines)){
      this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, filterList, this.state.filteredDishTypes, this.state.dishSearchText, this.state.currentSortType, this.state.currentOrder);
      this.setState({
        filteredCuisines: filterList
      });
    }
  }

  applyFilterImages(dishTypeList, cuisineList){
    if (!isSameArray(dishTypeList, this.state.filteredDishTypes)){
      this.getFilteredDishList(this.state.vegFilterActive, this.state.isPersonalizedShown, this.state.filteredCuisines, dishTypeList, this.state.dishSearchText, this.state.currentSortType, this.state.currentOrder);
      this.setState({
        filteredDishTypes: dishTypeList
      });
    }
  }

  filterDishTypes(filterList) {
    let dishList = this.state.dishes;
    let filterExist = true;
    if (filterList.length === 0){
      filterExist = false;
      dishList.forEach(function(dish){
        dish.dishTypeDisplay = true;
      })
    } else {
      dishList.forEach(function(dish){
        let dishFound = false;
        for (var i=0; i<filterList.length; i++){
          if (dish.dishType != null){
            for (var j=0; j<dish.dishType.length; j++){
              if (dish.dishType[j] === filterList[i]){
                dishFound = true;
                break;
              }
            }
          }
        }
        if (!dishFound){
          dish.dishTypeDisplay = false;
        } else {
          dish.dishTypeDisplay = true;
        }
      })
    }
    this.setState({
      dishes: dishList,
      dishTypeDisplay: filterExist,
    })
    if (filterExist){
      this.setState({
        cuisineFilterActive: true
      })
    } else {
      this.setState({
        cuisineFilterActive: false
      })
    }
  }

  getFilteredDishList(vegFilterActive, isPersonalizedShown, filteredCuisines, filteredDishTypes, searchText, sortType, order){
    var latitude = localStorage.getItem("user_latitude");
    var longitude = localStorage.getItem("user_longitude");
    const queryParams = `?page=1&latitude=${latitude}&longitude=${longitude}`;
    let dishUrl = CONSTANTS.hostURL +  `api/client/dishes/recommended/${queryParams}`;
    this.setState({
      dishLoading: true,
      allDishesLoaded: false,
    });
    let filterObject = getDishFilterObject(vegFilterActive, isPersonalizedShown, filteredCuisines, filteredDishTypes, searchText);
    if (filterObject.filterPresent){
      dishUrl += `&filters=${JSON.stringify(filterObject.value)}`;
    }
    let sortObject = getDishSortingObject(sortType, order);
    if (sortObject.sortPresent){
      dishUrl += `&sort=${JSON.stringify(sortObject.value)}`;
    }
    this.loadDishes(dishUrl, 0);
  }

  toggleVegDishes(toggleState) {
    this.getFilteredDishList(toggleState, this.state.isPersonalizedShown, this.state.filteredCuisines, this.state.filteredDishTypes, this.state.dishSearchText, this.state.currentSortType, this.state.currentOrder);
    this.setState({
      vegFilterActive: toggleState
    });
  }

  toggleUserOptions(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  
  searchDish(id){
    let items = [...this.state.dishes];
    for (var i=0; i< items.length; i++){
      items[i].searchDisplay = false;
      if (id === items[i].id){
        items[i].searchDisplay = true;
      }
    }
    this.setState({
      dishes: items,
      cuisineFilterActive: true
    })
  }

  clearSearchFilter(){
    let items = [...this.state.dishes];
    for (var i=0; i< items.length; i++){
      items[i].searchDisplay = true;
    }
    this.setState({
      dishes: items,
      cuisineFilterActive: false
    })
  }

  applyCuisineSearch(id){
    if(this.state.allDishesLoaded && this.state.allCuisinesLoaded){
        let items = [...this.state.cuisines];
        for (var i=0; i <items.length; i++){
            let item = items[i];
            if (id === item.cuisineId){
                item.isActive = true;
                item.activated = true;
                items[i] = item;
            }
        }
        this.filterCuisineTypes([id]);
        this.setState({
          cuisines: items,  
          cuisineFilterActive: true
        })
    }
  }

  applyDishTypeSearch(id){
    if(this.state.allDishesLoaded && this.state.allCuisinesLoaded){
        this.filterDishTypes([id]);
        this.setState({
          cuisineFilterActive: true
        })
    }
  }

  changeActiveTab(id){
    if(this.state.allDishesLoaded && this.state.allCuisinesLoaded){
        let items = [...this.state.cuisines];
        for (var i=0; i <items.length; i++){
            let item = items[i];
            if (id === item.id){
                item.isActive = !item.isActive;
                items[i] = item;
            }
        }
        this.setState({
          cuisines: items,  
        })
    }
  }

  clearFilters(){
      let items = [...this.state.cuisines];
      for (var i=0; i <items.length; i++){
          items[i].isActive = false;
          items[i].activated = false;
      }
      this.setState({
        cuisines: items,
        cuisineFilterActive: false
      })
      let activatedItems = [];
      this.filterCuisineTypes(activatedItems);
      this.filterDishTypes(activatedItems);
  }

  applyFilters() {
      let items = [...this.state.cuisines];
      let activatedItems = [];
      for (var i=0; i <items.length; i++){
        let item = items[i];
          if (item.isActive === true){
            item.activated = true;
            activatedItems.push(item.cuisineId);
          } else {
            item.activated = false;
          }
      }
      this.filterCuisineTypes(activatedItems);
      this.setState({
        cuisines: items,
        cuisineFilterActive: true
      })
  }

  updateCuisinesList(filteredOptions){
      let items = [...this.state.cuisines];
      for (var i=0; i<items.length; i++){
          let itemFound = false;
          let item = items[i];
          for (var j=0; j<filteredOptions.length; j++){
              if (item.name === filteredOptions[j]){
                  itemFound = true;
                  break;
              }
          }
          if (!itemFound){
              item.display = false;
          } else {
              item.display = true;
          }
          items[i] = item;
      }
      this.setState({
          cuisines: items
      })
  }

  closeSearchModal(){
    if (this.state.searchModal){
      this.setState({searchModal: false});
    }
  }

  clearSearch(){
      let items = [...this.state.cuisines];
      let filteredItems = [];
      for (var i=0; i<items.length; i++){
          let item = items[i];
          item.display = true;
          filteredItems.push(item);
      }
      this.setState({
          cuisines: filteredItems
      })
  }

  updateCartModalDisplay(value) {
    this.setState({
      showCart: value
    })
  }

  getReplacementDish(dish){
    this.setState({
      replacementDish: dish
    })
  }

  replaceCartItems(dish){
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        let cartDetails = {
            "RestoID": dish.restaurantId,
            "RestoName": dish.restaurant,
            "Cart_Items": [
                {
                    "DishID": dish.dishId, 
                    "DishName": dish.name, 
                    "DishCost": dish.cost, 
                    "DishImage": dish.image, 
                    "DishIsVeg": dish.is_veg, 
                    "DishQuantity": 1
                }
            ]
        }
        localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
    } else {
        this.props.showAuthPopupModal("login");
    }
  }

  addToCartAction_TEMP(dishDetails, changeType, dishAlreadyPresent, event){
    this.setDishesAddingState(dishDetails, true)
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      if (cartDetailsAPI !== null){
        // --> ADD/REMOVE Item
        if(dishDetails.is_delivery_available)
          this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
        else{
          // alert(dishDetails.restaurant + " is currently not delivering orders.")
          this.setState({
            temp_dishDetails: dishDetails,
            temp_changeType: changeType,
            temp_dishAlreadyPresent: dishAlreadyPresent
          })
          this.toggleConfirmationBox("1803 : " + dishDetails.restaurant + " is currently not delivering orders. Would you like to order and collect it from the restaurant?");
        }
      }
      else{
        //CHECK FOR AVAILABLE CART
        token = localStorage.getItem("auth_token");
        var headers = {
          'Authorization' : 'Bearer ' + token
        }
        let url = CONSTANTS.hostURL + "api/main_app/cart/get/recent/";
        axios.get(url, {
            headers: headers
        }).then((response) => {
          var verifyCartData = response.data
          if (verifyCartData.status === 1 && 'id' in verifyCartData.data){
            localStorage.setItem('cartDetailsAPI', JSON.stringify(verifyCartData.data));
            this.updateCartChangedValue(true);
            // --> ADD/REMOVE Item
            if(dishDetails.is_delivery_available)
              this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
            else{
              // alert(dishDetails.restaurant + " is currently not delivering orders.")
              this.setState({
                temp_dishDetails: dishDetails,
                temp_changeType: changeType,
                temp_dishAlreadyPresent: dishAlreadyPresent
              })
              this.toggleConfirmationBox("1830 : " + dishDetails.restaurant + " is currently not delivering orders. Would you like to order and collect it from the restaurant?");
            }
          }
          //ELSE IF CART NOT AVAILABLE
          else if(verifyCartData.errors.code === "NOT_FOUND"){
            //CRETAE A CART
            let url = CONSTANTS.hostURL + "api/main_app/cart/create/";
            var token = localStorage.getItem("auth_token");
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            let req = {
              'resto_id': dishDetails.restaurantId
            };
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
              var createCartData = response.data
              if (createCartData.status === 1 && 'id' in createCartData.data){
                localStorage.setItem('cartDetailsAPI', JSON.stringify(createCartData.data));
                this.updateCartChangedValue(true);
                // --> ADD/REMOVE Item
                if(dishDetails.is_delivery_available)
                  this.checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, event);
                else{
                  // alert(dishDetails.restaurant + " is currently not delivering orders.")
                  this.setState({
                    temp_dishDetails: dishDetails,
                    temp_changeType: changeType,
                    temp_dishAlreadyPresent: dishAlreadyPresent
                  })
                  this.toggleConfirmationBox("1862 : " + dishDetails.restaurant + " is currently not delivering orders. Would you like to order and collect it from the restaurant?");
                }
              }
              else{
                this.setState({
                  temp_dishDetails: dishDetails,
                  temp_changeType: changeType,
                  temp_dishAlreadyPresent: dishAlreadyPresent
                })
                this.toggleConfirmationBox(dishDetails.dishId + ":" + createCartData.errors.message);
              }
            });
          } else {
            if(verifyCartData.status === -1 && verifyCartData.errors.message.includes("Token")){
              // alert("refresh token")
              this.refreshToken()
              // console.log("1887")
              this.addToCartAction_TEMP(dishDetails, changeType, dishAlreadyPresent, event);
            }
          }
        });
      }
    }
    else{
      this.showAuthPopupModal("login");
    }
  }
  
  
  updateCartChangedValue(value) {
    this.setState({
      cartChanged: value
    })
    let cartDetails = localStorage.getItem('cartDetailsAPI');
    let itemsInCart = 0;
    if (cartDetails !== null){
      let cartDetailsObj = JSON.parse(cartDetails);
      var itemVal = Object.values(cartDetailsObj.items)
      for(var i=0; i<itemVal.length; i++){
        itemsInCart = itemsInCart + itemVal[i].quantity
      }
    }
    this.setState({
      itemsInCart: itemsInCart
    });
  }
  
  checkForCustomisation1(dishDetails, changeType, dishAlreadyPresent, eventTarget){
    if(dishDetails.customizations !== null && Object.keys(dishDetails.customizations).length !== 0){
      if(dishAlreadyPresent){
        /*Repeat Customisation Code - If Customisable dish is present in cart*/
        this.setState({
          temp_dishDetails: dishDetails,
          temp_changeType: changeType,
          temp_dishAlreadyPresent: dishAlreadyPresent
        }, () => {
          let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
          let dishCustom = [];
          if (cartDetailsAPI !== null) {
            let cartItems = JSON.parse(cartDetailsAPI)["items"];
            var itemID = Object.keys(cartItems)
            var itemVals = Object.values(cartItems)
            for (var i=0; i<itemID.length; i++){
              if (Number(itemID[i]) === Number(dishDetails.dishId)){
                for(var j=0; j<itemVals[i].customizations.length; j++){  
                  dishCustom.push(itemVals[i].customizations[j]);
                }
              }
            } 

          }
          this.setState({
            temp_customizations: dishCustom
          });
          if(changeType === "add"){
            this.toggleRepeatCustomisation("Repeat Last Customisation?", "", eventTarget);
          }
          else if(changeType === "remove" && dishCustom.length > 1){
            this.toggleRepeatCustomisation("Select Customisation to remove", "", eventTarget);
          }
          else{
            this.handleAddCartWithCustomisation1(dishDetails, changeType, dishAlreadyPresent, JSON.stringify(dishCustom[0]));
          }
        })
      }
      else{
        this.setState({
          customisationOptionsModal: true,
          dishDetails: dishDetails
        })
      }
    }
    else{
      this.setState({
        customisationOptionsModal: false
      }, () => {
        this.handleAddCart1(dishDetails, changeType, dishAlreadyPresent);
      });
    }
  }
  
  getCustomisationText(customizations){
    var customText = "";
    if(customizations !== undefined){
      if(customizations.combination !== null){
        var combinationValues = Object.values(customizations.combination)
        for(var i=0; i<combinationValues.length; i++){
          customText = customText + combinationValues[i].name + " : ";
          var modVals = Object.values(combinationValues[i].modifications)
          for(var k=0; k<modVals.length; k++){
              customText = customText + modVals[k].name + ", ";
          }
          customText = customText.substring(0, customText.length-2);
          customText = customText + "\n"
        }
      }
      else{
        customText = "No Customization\n"
      }
    }
    return(customText);
  }

  toggleRepeatCustomisation(repeatCustomisationMessage, selectedCustom, eventTarget) {
    if(repeatCustomisationMessage === "Yes"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        });
        this.handleAddCartWithCustomisation1(this.state.temp_dishDetails, "add", true, selectedCustom);
      })
    }
    else if(repeatCustomisationMessage === "No"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          customisationOptionsModal: true,
          dishDetails: this.state.temp_dishDetails,
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        })
      })
    }
    else if(repeatCustomisationMessage === "Remove"){
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation
      }, () => {
        this.setState({
          popOverAnchorEl : null,
          popDishId : this.state.temp_dishDetails.dishId,
          popDishState : false,
        });
        this.handleAddCartWithCustomisation1(this.state.temp_dishDetails, "remove", true, selectedCustom);
      })
    }
    else if(repeatCustomisationMessage === ""){
      this.setDishesAddingState(this.state.temp_dishDetails, false)
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation,
        popOverAnchorEl : null,
        popDishId : this.state.temp_dishDetails.dishId,
        popDishState : false,
      })
    }
    else{
      this.setState({
        showRepeatCustomisation: !this.state.showRepeatCustomisation,
        repeatCustomisationMessage: repeatCustomisationMessage,
        popOverAnchorEl : eventTarget,
        popUpId : "anchorEl_"+this.state.temp_dishDetails.dishId+"_"+this.state.temp_dishDetails.id,
        popDishId : this.state.temp_dishDetails.dishId,
        popDishState : true,
      })
      return "";
    }
  }
  
  handleAddCartWithCustomisation1(dish, changeType, dishAlreadyPresent, selectedDishCustomisationObj) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      // let cartItems = [];
      if (cartDetailsAPI !== null){
        let cartDetailsObj = JSON.parse(cartDetailsAPI);
        if (cartDetailsObj["restaurant"].id === Number(dish.restaurantId)){
          // cartItems = cartDetailsObj["Cart_Items"];
        } else{
          this.getReplacementDish(dish);
          this.setState({
            temp_restoID: dish.restaurantId,
            newRestoDish: dish,
            newRestoChangeType: changeType,
            newRestoDishAlreadyPresent: dishAlreadyPresent,
            newRestoSelectedDishCustomisationObj: selectedDishCustomisationObj
          })
          this.toggleConfirmationBox("1509 : Items from different restaurant available in the cart. Do you want to remove them?");
          return;
        }
      }
      this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
      this.updateCartChangedValue(true);
      return(<UserCart /> );
    } else {
        this.props.showAuthPopupModal("login");
    }
  }
  
  handleAddCart1(dish, changeType, dishAlreadyPresent) {
    var token = localStorage.getItem("auth_token");
    if (token !== null && token !== ""){
        // this.props.addToCartAction(dish.name);
        let cartDetails = localStorage.getItem('cartDetailsAPI');
        // let cartItems = [];
        if (cartDetails !== null){
            let cartDetailsObj = JSON.parse(cartDetails);
            this.setState({temp_restoID: dish.restaurantId})
            if (Number(cartDetailsObj["restaurant"].id) === Number(dish.restaurantId)){
                // cartItems = cartDetailsObj["Cart_Items"];
            } else{
                this.getReplacementDish(dish);
                this.setState({
                  temp_restoID: dish.restaurantId,
                  newRestoDish: dish,
                  newRestoChangeType: changeType,
                  newRestoDishAlreadyPresent: dishAlreadyPresent,
                  newRestoSelectedDishCustomisationObj: JSON.stringify({"combination": null, "quantity": 1, "sub_total": dish.cost})
                })
                this.toggleConfirmationBox("1395 : Items from different restaurant available in the cart. Do you want to remove them?");
                return;
            }
        }
        this.addRemoveWithAPI(dish, changeType, JSON.stringify({"combination": null, "quantity": 1, "sub_total": dish.cost}));
        this.updateCartChangedValue(true);
        return(<UserCart /> );
    } else {
        this.props.showAuthPopupModal("login");
    }
  }
  
  deleteCart(cartID){
    let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
    let req = {
        'cart_id': cartID
    };
    var token = localStorage.getItem("auth_token");
    var headers = {
        'Authorization' : 'Bearer ' + token 
    }
    axios.post(url, req,  {
        headers: headers
    }).then((response) => {
        let data = response.data;
        if (data.status === 1){
            localStorage.removeItem('cartDetailsAPI');
            this.updateCartChangedValue(true);
        }
    }).catch((error) => this.setState({}));
  }

  toggleConfirmationBox(confirmationBoxMessage) {
    if(confirmationBoxMessage === "Yes"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox,
        customAddClick: true
      }, () => {
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
          cartDetailsAPI = JSON.parse(cartDetailsAPI);
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetailsAPI.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                localStorage.removeItem('CartDetails');
                localStorage.removeItem('cartDetailsAPI');
                
                let url = CONSTANTS.hostURL + "api/main_app/cart/create/";
                var token = localStorage.getItem("auth_token");
                var headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                let req = {
                  'resto_id': this.state.temp_restoID
                };
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                      localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                      this.addRemoveWithAPI(this.state.newRestoDish, this.state.newRestoChangeType, this.state.newRestoSelectedDishCustomisationObj);
                      this.updateCartChangedValue(true);
                    }
                    else {
                      if(data.status === -1 && data.errors.message.includes("Token")){
                        // alert("refresh token")
                        this.refreshToken()
                        // console.log("2179")
                        this.toggleConfirmationBox(confirmationBoxMessage);
                      }
                    }
                }).catch((error) => this.setState({}));
                
                this.setState({
                  // itemsInCart: 0,
                  cartChanged: true
                }, () => {
                  // this.checkForCustomisation1(this.state.replacementDish, "add", false);
                  //this.addRemoveWithAPI(this.state.newRestoDish, this.state.newRestoChangeType, this.state.newRestoSelectedDishCustomisationObj);
                })
              } else {
                if(data.status === -1 && data.errors.message.includes("Token")){
                  // alert("refresh token")
                  this.refreshToken()
                  // console.log("2198")
                  this.toggleConfirmationBox(confirmationBoxMessage);
                }
              }
          }).catch((error) => this.setState({}));
        } else {
          localStorage.removeItem('CartDetails');
          this.setState({
            itemsInCart: 0,
            cartChanged: true
          }, () => {
            this.checkForCustomisation1(this.state.replacementDish, "add", false);
          })
        }
      })
      return "Yes";
    }
    else if(confirmationBoxMessage === "REMOVE RESTO DISHES"){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        // Removing all the Items from the cart.
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        var cartDetails = JSON.parse(cartDetailsAPI);
        if(cartDetailsAPI !== null){
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetails.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                  localStorage.removeItem('cartDetailsAPI');
                  this.updateCartChangedValue(true);
              }
          }).catch((error) => this.setState({}));
        }
      })
    }
    else if(confirmationBoxMessage.includes("REMOVE DISH:")){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        let dishIds = confirmationBoxMessage.split(':');

        let url = CONSTANTS.hostURL + "api/main_app/cart/remove-dish/";
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        // let cartItems = []
        let cart = JSON.parse(cartDetailsAPI)
        let req = {
          'cart_id': cart.id,
          'dish_id': Number(dishIds[1])
        }; 
        let token = localStorage.getItem("auth_token");
        let headers = {
          'Authorization' : 'Bearer ' + token 
        }
        axios.post(url, req,  {
          headers: headers
        }).then((response) => {
          let data = response.data;
          if (data.status === 1){
            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
            this.updateCartChangedValue(true);

            cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            var cartDetails = JSON.parse(cartDetailsAPI);
            if(cartDetailsAPI !== null){
              var itemKey = Object.keys(cartDetails.items)
              if(itemKey.length === 0){
                let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
                let req = {
                    'cart_id': cartDetails.id
                };
                var token = localStorage.getItem("auth_token");
                var headers = {
                    'Authorization' : 'Bearer ' + token 
                }
                axios.post(url, req,  {
                    headers: headers
                }).then((response) => {
                    let data = response.data;
                    if (data.status === 1){
                        localStorage.removeItem('cartDetailsAPI');
                        this.updateCartChangedValue(true);
                    }
                }).catch((error) => this.setState({}));
              }
            }

          } else{
            this.loadDishes();;
          }
        }).catch((error) => this.setState({}));
      })
    }
    else if(confirmationBoxMessage.includes("Proceed With Pickup")){
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        this.checkForCustomisation1(this.state.temp_dishDetails, this.state.temp_changeType, this.state.temp_dishAlreadyPresent, null);
      })
    }
    else if(confirmationBoxMessage === "Clear cart and go home"){
      this.setDishesAddingState(this.state.temp_dishDetails, false)
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox
      }, () => {
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
          cartDetailsAPI = JSON.parse(cartDetailsAPI);
          let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
          let req = {
              'cart_id': cartDetailsAPI.id
          };
          var token = localStorage.getItem("auth_token");
          var headers = {
              'Authorization' : 'Bearer ' + token 
          }
          axios.post(url, req,  {
              headers: headers
          }).then((response) => {
              let data = response.data;
              if (data.status === 1){
                localStorage.removeItem('CartDetails');
                localStorage.removeItem('cartDetailsAPI');
                this.setState({
                  itemsInCart: 0,
                  cartChanged: true
                })
              }
          }).catch((error) => this.setState({}));
        } else {
          localStorage.removeItem('CartDetails');
          localStorage.removeItem('cartDetailsAPI');
          this.setState({
            itemsInCart: 0,
            cartChanged: true
          })
        }
      })
      return "Yes";
    }
    else if(confirmationBoxMessage === "Cancel"){
      this.setState({
        showConfirmationBox: false,
        confirmationBoxMessage: confirmationBoxMessage,
        customAddClick: false,
        customisationOptionsModal: false
      }, () => this.setDishesAddingState(this.state.dishDetails, false))
      let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
      var cartDetails = JSON.parse(cartDetailsAPI);
      if(cartDetailsAPI !== null){
        var itemVal = Object.values(cartDetails.items)
        if(itemVal.length === 0){
          this.deleteCart(cartDetails.id)
        }
      }
    }
    else{
      this.setState({
        showConfirmationBox: !this.state.showConfirmationBox,
        confirmationBoxMessage: confirmationBoxMessage,
        customAddClick: false
      })
      return "";
    }
  }
  
  setDishesAddingState(dish, trueOrFalse){
    var dishesAddingState = this.state.dishesAddingState
    for(let i=0; i<this.state.dishesAddingState.length; i++){
      if(this.state.dishesAddingState[i].dishID === dish.dishId){
        dishesAddingState[i].addingState = trueOrFalse
      }
    }
    this.setState({
      dishesAddingState: dishesAddingState
    })
  }

  addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj){
    let url = "";
    if(changeType === "add"){
      url = CONSTANTS.hostURL + "api/main_app/cart/add/";
    }
    else{
      url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
    }

    let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');

    if (cartDetailsAPI){
      cartDetailsAPI = JSON.parse(cartDetailsAPI);
      if(selectedDishCustomisationObj !== "{ \"combination\" : }}"){
        if(JSON.parse(selectedDishCustomisationObj).combination !== null){
          var req = {
            'cart_id': cartDetailsAPI.id,
            'dish_id': dish.dishId,
            'quantity': changeType === "add" ? this.state.customQty : 1,
            'combination': JSON.parse(selectedDishCustomisationObj).combination
          };
        }
        else{
          req = {
            'cart_id': cartDetailsAPI.id,
            'dish_id': dish.dishId,
            'quantity': 1,
          };
        }
      }
      else{
        req = {
          'cart_id': cartDetailsAPI.id,
          'dish_id': dish.dishId,
          'quantity': 1,
        };
      }
      
      let token = localStorage.getItem("auth_token");
      let headers = {
        'Authorization' : 'Bearer ' + token 
      }
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
        let data = response.data;
        if (data.status === 1){
          localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
          this.updateCartChangedValue(true);
          this.setState({customQty : 1})
          cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
          var cartDetails = JSON.parse(cartDetailsAPI);
          if(cartDetailsAPI !== null){
            var itemKey = Object.keys(cartDetails.items)
            if(itemKey.length === 0){
              let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
              let req = {
                  'cart_id': cartDetails.id
              };
              var token = localStorage.getItem("auth_token");
              var headers = {
                  'Authorization' : 'Bearer ' + token 
              }
              axios.post(url, req,  {
                  headers: headers
              }).then((response) => {
                  let data = response.data;
                  if (data.status === 1){
                      localStorage.removeItem('cartDetailsAPI');
                      this.updateCartChangedValue(true);
                      this.setDishesAddingState(dish, false)
                  }
              }).catch((error) => this.setState({}));
            }
            else{
              if(changeType === "add"){
                window.location.href='/restaurants/'+cartDetails.restaurant.id
              }
              this.setDishesAddingState(dish, false)
            }
          }
        }
        else if(data.status === -1 && data.errors.message.includes("Token")){
          // alert("refresh token")
          this.refreshToken()
          // console.log("2463")
          this.addRemoveWithAPI(dish, changeType, selectedDishCustomisationObj);
        }
        else{
          this.setState({customQty : 1})
          this.toggleConfirmationBox(dish.dishId + ":" + data.errors.message);
          this.loadDishes();
          this.setDishesAddingState(dish, false)
        }
      }).catch((error) => this.setState({}));
    }
  }
  
  getUserAddress(){
    var token = localStorage.getItem("auth_token");
    this.setState({defaultAddresses : ""});
    if (token !== null && token !== ""){
      var headers = {
        'Authorization' : 'Bearer ' + token,
      }
      var url = CONSTANTS.hostURL + "api/geo/location/";
      axios.get(url, {
        headers: headers
      }).then((response) => {
          var resData = response.data;
          if (resData.status === 1){
            for(let i=0; i<resData.data.locations.length; i++){
              if(resData.data.locations[i].is_default){
                this.setState({defaultAddresses : resData.data.locations[i].tag + ", " + resData.data.locations[i].address});
              }
            }
            this.setState({addresses : resData.data.locations});
          } else {
            this.setState({addresses : []});
          }
      });
    }
  }

  getCustomisationData(selectedCustomisation, customQty, selectedDishCustomisationObj){
    this.setState({
      selectedCustomisation : selectedCustomisation,
      customQty: customQty,
      selectedDishCustomisationObj: selectedDishCustomisationObj,
      customAddClick: true
    }, () => {
      this.handleAddCartWithCustomisation1(this.state.dishDetails, "add", false, this.state.selectedDishCustomisationObj);
    });
  }

  closeCustomisationOptionsModal(dishDetails){
    this.setState({
      customisationOptionsModal: false
    }, () => this.toggleConfirmationBox("Cancel"))
    this.setDishesAddingState(dishDetails, false)
  }


  handlePopOverClose(dish){
    this.setDishesAddingState(this.state.temp_dishDetails, false)
    this.setState({
        popOverAnchorEl : null,
        popDishId : this.state.temp_dishDetails.id,
        popDishState : false,
    });
  }

  render() {
    return (
      <div onClick={(e) => {
        e.stopPropagation();
        if (this.state.isOpen){
          this.setState({isOpen: false});
        }
      }}>
        <div className="loadingGif" style={this.state.initialDishLoading || !this.state.offersFetched ||  this.state.dishTypeLoading ? {visibility:"visible", opacity: '1'} : {visibility:"hidden", opacity: '0'}}>
          <img src={DaiDishLoading1} alt="DaiDish Loading..." style={this.state.isMobileScreen ? {width:"100%", marginTop:"25%"} : {width:"40%", marginTop:"0%"}}/>
        </div>
        <UserOptions
          toggleUserOptions={this.toggleUserOptions}
          isOpen={this.state.isOpen}
          toggleLogoutModal={this.toggleLogoutModal}
        />
        <Header 
          toggleUserOptions={this.toggleUserOptions} 
          toggleSearchModal={this.toggleSearchModal}
          showAuthPopupModal={this.showAuthPopupModal}
          searchPresent={true}
          itemsInCart={this.state.itemsInCart}
          updateCartModalDisplay={this.updateCartModalDisplay}
          showCart={this.state.showCart}
        />
        <UserCart 
          cartChanged={this.state.cartChanged}
          showCart={this.state.showCart}
          updateCartChangedValue={this.updateCartChangedValue}
          updateCartModalDisplay={this.updateCartModalDisplay}
          itemsInCart={this.state.itemsInCart}
          restaurantId={-1}
          defaultAddresses={this.state.defaultAddresses}
          addresses={this.state.addresses}
          toggleConfirmationBox={this.toggleConfirmationBox}
        />
        <div id="main-content">
          <Offers 
            showSearchModal={this.state.searchModal}
            offers={this.state.offers}
            offersFetched={this.state.offersFetched}
            cuisines={this.state.cuisines}
            dishes={this.state.dishes}
            restaurants={this.state.restaurants}
            applyCuisineSearch={this.applyCuisineSearch}
            applyDishTypeSearch={this.applyDishTypeSearch}
            clearFilters={this.clearFilters}
            searchDish={this.searchDish}
            clearSearchFilter={this.clearSearchFilter}
            closeSearchModal={this.closeSearchModal}
            isDishScreen={true}
            nonServicable={this.state.nonServicable}
          />
          <Actions
            showCuisinesModal={this.showCuisinesModal}
            isDishScreen={true}
            showRecommendedDishes={this.showRecommendedDishes}
            showRatingSortedDishes={this.showRatingSortedDishes}
            showPriceSortedDishes={this.showPriceSortedDishes}
            showDistanceSortedDishes={this.showDistanceSortedDishes}
            showDiscountSortedDishes={this.showDiscountSortedDishes}
            allDishesLoaded={this.state.allDishesLoaded}
            toggleVegDishes={this.toggleVegDishes}
            togglePersonalized={this.togglePersonalized}
            toggleFreeDelivery={this.toggleFreeDelivery}
            isPersonalizedShown={this.state.isPersonalizedShown}
            isLoggedIn={this.state.isLoggedIn}
            cuisineFilterActive={this.state.cuisineFilterActive}
            dishes={this.state.dishes}
            clearSearchFilter={this.clearSearchFilter}
            closeSearchModal={this.closeSearchModal}
            clearFilters={this.clearFilters}
            activeHeaderType={this.state.activeHeaderType}
            itemsInCart={this.state.itemsInCart}
            updateCartModalDisplay={this.updateCartModalDisplay}
            showCart={this.state.showCart}
            cuisinesModal={this.state.cuisinesModal} 
            handleSearchInput={this.handleSearchInput}
            toggleUserOptions={this.toggleUserOptions}
            showAuthPopupModal={this.showAuthPopupModal}
            filters={this.state.filters}
            nonServicable={this.state.nonServicable}
          />
          <Filters
            filters={this.state.filters}
            dishTypeLoading={this.state.dishTypeLoading}
            allDishTypesLoaded={this.state.allDishTypesLoaded}
            allDishesLoaded={this.state.allDishesLoaded}
            filterDishTypes={this.filterDishTypes}
            filterCuisineTypes={this.filterCuisineTypes}
            dishes={this.state.dishes}
            isDishScreen={true}
            applyFilters={this.applyFilterImages}
            nonServicable={this.state.nonServicable}
          />
          {/* <ActionsMobile
            showCuisinesModal={this.showCuisinesModal}
            isDishScreen={true}
            showRecommendedDishes={this.showRecommendedDishes}
            showRatingSortedDishes={this.showRatingSortedDishes}
            showPriceSortedDishes={this.showPriceSortedDishes}
            allDishesLoaded={this.state.allDishesLoaded}
            toggleVegDishes={this.toggleVegDishes}
            togglePersonalized={this.togglePersonalized}
            isPersonalizedShown={this.state.isPersonalizedShown}
            isLoggedIn={this.state.isLoggedIn}
            cuisineFilterActive={this.state.cuisineFilterActive}
            dishes={this.state.dishes}
            clearSearchFilter={this.clearSearchFilter}
            closeSearchModal={this.closeSearchModal}
            clearFilters={this.clearFilters}
            initialDishesLoaded={this.state.initialDishesLoaded}
            activeHeaderType={this.state.activeHeaderType}
          /> */}
          <DishList 
            showRatingsModal={this.showRatingsModal}
            showReviewsModal={this.showReviewsModal}
            dishLoading={this.state.dishLoading}
            dishes={this.state.dishes}
            toggleImageHover={this.toggleImageHover}
            toggleRateHover={this.toggleRateHover}
            toggleReviewHover={this.toggleReviewHover}
            toggleLikeButton={this.toggleLikeButton}
            showAuthPopupModal={this.showAuthPopupModal}
            isDishScreen={true}
            toggleConfirmationBox={this.toggleConfirmationBox}
            getReplacementDish={this.getReplacementDish}
            confirmationBoxMessage={this.state.confirmationBoxMessage}
            showConfirmationBox={this.state.showConfirmationBox}
            updateCartChangedValue={this.updateCartChangedValue}
            addToCartAction={this.addToCartAction_TEMP}
            repeatCustomisationMessage={this.state.repeatCustomisationMessage}
            temp_customizations={this.state.temp_customizations}
            toggleRepeatCustomisation={this.toggleRepeatCustomisation}
            getCustomisationText={this.getCustomisationText}
            popOverAnchorEl={this.state.popOverAnchorEl}
            popUpId={this.state.popUpId}
            popDishState={this.state.popDishState}
            handlePopOverClose={this.handlePopOverClose}
            dishesAddingState={this.state.dishesAddingState}
            nonServicable={this.state.nonServicable}
          />
          <CustomisationOptions
            dishDetails={this.state.dishDetails}
            getCustomisationData={this.getCustomisationData}
            selectedCustomisation={this.state.selectedCustomisation}
            showCustomisationOptionsModal={this.state.customisationOptionsModal}
            closeCustomisationOptionsModal={this.closeCustomisationOptionsModal}
            isMobileScreen={this.state.isMobileScreen}
            setDishesAddingState={this.setDishesAddingState}
            customAddClick={this.state.customAddClick}
          />
          <AuthPopup
            showAuthPopupModal={this.state.authPopupModal}
            closeAuthPopupModal={this.closeAuthPopupModal}
            setActiveForm={this.setActiveForm}
            activeForm={this.state.activeForm}
          />
          <DishRatingInformation
            showRatingsInformationModal={this.state.ratingInformationModal} 
            closeRatingsInformationModal={this.closeRatingsInformationModal}
          />
          <DishRating 
            dish={this.state.dishSelected} 
            showRatingsModal={this.state.ratingModal} 
            closeRatingsModal={this.closeRatingsModal}
            showSuccessModal={this.showSuccessModal}
          />
          <RatingSuccess
            showSuccessModal={this.state.successModal}
            closeSuccessModal={this.closeSuccessModal}
            emoji={this.state.emoji}
            isFiveStar={this.state.isFiveStar}
            ratingNumber={this.state.ratingNumber}
          />
          <LogoutConfirm
            showLogoutModal={this.state.logoutModal}
            toggleLogoutModal={this.toggleLogoutModal}
          />
          <ConfirmationBox
              confirmationBoxMessage={this.state.confirmationBoxMessage}
              showConfirmationBox={this.state.showConfirmationBox}
              toggleConfirmationBox={this.toggleConfirmationBox}
              dishDetails={this.state.temp_dishDetails}
              setDishesAddingState={this.setDishesAddingState}
          />
          <DishReview 
            dish={this.state.dishSelected} 
            showReviewsModal={this.state.reviewModal} 
            closeReviewsModal={this.closeReviewsModal}
          />
          <DishCuisines
            showCuisinesModal={this.state.cuisinesModal} 
            closeCuisinesModal={this.closeCuisinesModal}
            cuisineLoading={this.state.cuisineLoading}
            cuisines={this.state.cuisines}
            moreCuisineLoading={this.state.moreCuisineLoading}
            filterCuisineTypes={this.filterCuisineTypes}
            clearFilters={this.clearFilters}
            clearSearch={this.clearSearch}
            updateCuisinesList={this.updateCuisinesList}
            changeActiveTab={this.changeActiveTab}
            applyFilters={this.applyFilters}
          />
          <Footer/>
        </div>
      </div>
    )
  };
}

export default Dishes;
