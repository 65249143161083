import React, { Component } from 'react';
import { withRouter } from 'react-router';

class UserOptions extends Component { 

    constructor(props) {
      super(props);
      this.state = { 
          isMobileScreen: false
      };
    }
  
    componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render () { 
      let userOptionsDropdown;
      let topArrowStyle;
      if (this.props.isOpen){
        if (this.state.isMobileScreen){
          userOptionsDropdown = {right: "2vw", display: "block", transform: "translateY(-2px)"};
          topArrowStyle = {marginLeft: "80px"};
        } else {
          userOptionsDropdown = {right: "7.5vw", display: "block"};
          topArrowStyle = {marginRight: "75px"};
        }
      } else {
        userOptionsDropdown = {display: "none"};
        topArrowStyle = {};
      }
      return (
        <div style={userOptionsDropdown} className={this.state.isMobileScreen ? "dropdown-menuM" : "dropdown-menu"}>
            {!this.state.isMobileScreen && <div style={topArrowStyle} className="dropdown-top-arrow"></div>}
            <div className="DropdownList" style={{backgroundColor: "#EA5B2F", padding: "10px 20px", borderRadius: "5px"}}>
                <li className="dropdown-menu-list" onClick={() => {
                  if (this.props.location.pathname !== "/profile"){
                    this.props.history.push('/profile');
                  } else {
                    this.props.toggleUserOptions();
                  }
                }}>Profile</li>
                <li className="dropdown-menu-list" onClick={() => {
                  if (this.props.location.pathname !== "/orders"){
                    this.props.history.push('/orders');
                  } else {
                    this.props.toggleUserOptions();
                  }
                }}>Orders</li>
                <li className="dropdown-menu-list" onClick={() => {
                  if (this.props.location.pathname !== "/settings"){
                    this.props.history.push('/settings');
                  } else {
                    this.props.toggleUserOptions();
                  }
                }}>Settings</li>
                <li className="dropdown-menu-list" onClick={() => {
                    this.props.toggleUserOptions();
                    this.props.toggleLogoutModal();
                }}>Logout</li>
            </div>
        </div>
      )
    }
  }

export default withRouter(UserOptions);
