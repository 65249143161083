import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import StarIcon from '@material-ui/icons/Star';
// import ReactAudioPlayer from 'react-audio-player';
// import pop_sound from '../../assets/media/pop_sound.mp3';

class RatingSuccess extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.successContainer = this.successContainer.bind(this);
        this.checkFiveStar = this.checkFiveStar.bind(this);
        this.confettiStyle = this.confettiStyle.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    closeModalHandler() {
        this.props.closeSuccessModal();
    }

    getSubmitButtonStyle(){
        if (this.state.isMobileScreen){
            return {padding: "10px 30px", marginTop: "5px", marginBottom: "0px"};
        } else {
            return {padding: "15px 60px", marginTop: "15px", marginBottom: "30px"};
        }
    }

    getRatingModalBelowStyle(){
        if (this.state.isMobileScreen){
            return {transform: "translateY(-20px)", zIndex: "1000000"};
        } else {
            return {};
        }
    }

    successContainer(buttonStyle) {
        return (
            <div style={this.getRatingModalBelowStyle()} className="DishRateModalBelowContainer">
                <div className="DishSuccessModalHeader">Rating <span style={{color: "#EA5B2F", margin: "0px 5px"}}>Successfully</span> Submitted!</div>
                <div className="DishSuccessModalSubHeader">You rated this dish <span style={{color: "#EA5B2F"}}>{this.props.ratingNumber} <StarIcon style={{transform: "translateY(3px)", fontSize: "16px"}}/></span></div>
                <div style={this.getSubmitButtonStyle()} onClick={() => this.closeModalHandler()} className="DishRateModalSubmitButtonContainer">
                    Okay
                </div>
            </div>
        );
    }

    handleModalClick(e) {
        e.stopPropagation();
    }

    confettiStyle(){
        const items = [];
        for(var i=0; i<200; i++) {
            // Random rotation
            var randomRotation = Math.floor(Math.random() * 360);
            // Random width & height between 0 and viewport
            var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
            var randomHeight =  Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
            
            // Random animation-delay
            var randomAnimationDelay = Math.floor(Math.random() * 10);
          
            // Random colors
            var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0']
            var randomColor = colors[Math.floor(Math.random() * colors.length)];
          
            // Create confetti piece
            var confetti = (<div className="confetti" style={{top: randomHeight + "px",  left: randomWidth + "px", backgroundColor: randomColor, transform: 'skew(15deg) rotate(' + randomRotation + 'deg)', animationDelay: randomAnimationDelay + 's'}}></div>)
            items.push(confetti);
        }
        if (this.props.isFiveStar){
            return (<div id="confetti-wrapper">{items}</div>);
        } else {
            return null;
        }
    }

    checkFiveStar() {
        if (this.props.isFiveStar){
            return (
                <div className="SuccessImageModalContainer">
                    <img className="SuccessImageModal" src={this.props.emoji} alt="Dish Pic" />
                    {/* <ReactAudioPlayer
                        src={pop_sound}
                        autoPlay
                        controls
                    /> */}
                </div>
            )
        } else {
            return (
                <div className="SuccessImageModalContainer">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <img className="SuccessImageModal" src={this.props.emoji} alt="Dish Pic" />
                </div>
            )
        }
    }
    
    render() {
        var buttonStyle = [];
        if(!this.props.showSuccessModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                    {this.checkFiveStar()}
                    {this.successContainer(buttonStyle)}
                </div>
                {this.confettiStyle()}
            </div>
        )
    }
}

export default RatingSuccess;