import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import CONSTANTS from '../../config/constants';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import NoItemsInCart from '../../assets/images/NoItemsCartImage.png';
import DiscountImage from '../../assets/images/discountImage.png';

class OrderCard extends Component {

    constructor(props) {
      super(props);
      this.handleChangeL = this.handleChangeL.bind(this);
      this.handleChangeR = this.handleChangeR.bind(this);
      this.OrderList = this.OrderList.bind(this);
      this.OrderList1 = this.OrderList1.bind(this);
      this.AddedItems = this.AddedItems.bind(this);
      this.CouponSection = this.CouponSection.bind(this);
      this.RestoDiscount = this.RestoDiscount.bind(this);
      this.OrderSummary = this.OrderSummary.bind(this);
      this.updateCartValues = this.updateCartValues.bind(this);
      this.updateCartValues1 = this.updateCartValues1.bind(this);
      this.state = { 
        isMobileScreen: false,
        sumTotal: 0,
        couponDiscPerc: 5,
        couponDiscAmt: 0,
        restoDiscPerc: 20,
        restoDiscAmt: 0,
        deliveryCharges: 25,
        grandTotal: 0,
        restoName: "",
        cartList: [],
        cartDetails : JSON.parse(localStorage.getItem('cartDetailsAPI')),
        stateL: {backgroundColor: '#EA5B2F', opacity : '1'},
        stateR: {backgroundColor: '#999999', opacity : '0.5'},
        restoDiscount: 0,
        minAmountForDiscount: 0,
        availableRestoDiscount: 0
      };
    } 
  
    componentDidMount(){
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.updateCartValues();
      if (this.state.cartDetails !== null) {
        this.setState({
            restoName : this.state.cartDetails.restaurant_name,
        });
      }
    }

    componentDidUpdate() {
        if (this.props.cartChanged === true){
            this.updateCartValues();
            this.getCartList();
        }
    }
  
    updateDimensions() {
      let screen_width = window.innerWidth;
      if(screen_width < 500) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
    }
    
    getCustomisationText(customizations){
        var customText = "";
        if(customizations !== undefined){
          if(customizations.combination !== null){
            var combinationValues = Object.values(customizations.combination)
            for(var i=0; i<combinationValues.length; i++){
              customText = customText + combinationValues[i].name + " : ";
              var modVals = Object.values(combinationValues[i].modifications)
              for(var k=0; k<modVals.length; k++){
                  customText = customText + modVals[k].name + ", ";
              }
              customText = customText.substring(0, customText.length-2);
              customText = customText + "\n"
            }
          }
          else{
            customText = "No Customization\n"
          }
        }
        return(customText);
    }
    
    compareCustomisation(cartCustomisation, selectedDishCustomisationObj){
        let cartCustomString = this.getCustomisationText(cartCustomisation)
        let selectedCustomString = this.getCustomisationText(selectedDishCustomisationObj)
        if(cartCustomString === selectedCustomString)
            return true;
        else
            return false;
    }

    getCartList(){
        var cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        var cartList = []
        var sumTotal = 0
        if (cartDetailsAPI !== null){
            var itemKey = Object.keys(JSON.parse(cartDetailsAPI).items)
            var itemValue = Object.values(JSON.parse(cartDetailsAPI).items)

            for(let i = 0; i < itemValue.length; i++){
                for(var j=0; j<itemValue[i].customizations.length; j++ ){
                    if(itemValue[i].customizations[j].combination !== null){
                        cartList.push({
                            "DishID": Number(itemKey[i]), 
                            "DishName": itemValue[i].name,
                            "DishImage": itemValue[i].image,
                            "DishCost": itemValue[i].customizations[j].sub_total, 
                            "DishIsVeg": itemValue[i].choice, 
                            "DishQuantity": itemValue[i].customizations[j].quantity,
                            "Customisation": itemValue[i].customizations[j]
                        });
                        sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
                    }
                    else{
                        cartList.push({
                            "DishID": Number(itemKey[i]), 
                            "DishName": itemValue[i].name,
                            "DishImage": itemValue[i].image,
                            "DishCost": itemValue[i].customizations[j].sub_total, 
                            "DishIsVeg": itemValue[i].choice, 
                            "DishQuantity": itemValue[i].customizations[j].quantity,
                            "Customisation": {"combination": null, "quantity": itemValue[i].customizations[j].quantity, "sub_total": itemValue[i].customizations[j].sub_total}
                        });
                        sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
                    }

                    
                }
                
                
                
                
                
                
                // if(itemValue[i].customizations[0].combination !== null){
                //     for(var j=0; j<itemValue[i].customizations.length; j++ ){
                //         cartList.push({
                //             "DishID": Number(itemKey[i]), 
                //             "DishName": itemValue[i].name,
                //             "DishImage": itemValue[i].image,
                //             "DishCost": itemValue[i].customizations[j].sub_total, 
                //             "DishIsVeg": itemValue[i].choice, 
                //             "DishQuantity": itemValue[i].customizations[j].quantity,
                //             "Customisation": itemValue[i].customizations[j]
                //         });
                //         sumTotal = sumTotal + itemValue[i].customizations[j].sub_total
                //     }
                // }
                // else{
                //     cartList.push({
                //         "DishID": Number(itemKey[i]), 
                //         "DishName": itemValue[i].name,
                //         "DishImage": itemValue[i].image,
                //         "DishCost": itemValue[i].item_total, 
                //         "DishIsVeg": itemValue[i].choice, 
                //         "DishQuantity": itemValue[i].quantity,
                //         "Customisation": {"combination": null, "quantity": itemValue[i].quantity, "sub_total": itemValue[i].item_total}
                //     });
                //     sumTotal = sumTotal + itemValue[i].item_total
                // }
            }

            let restoDiscount = 0;
            let availableRestoDiscount = 0;
            let minAmountForDiscount = 0;
            if(JSON.parse(cartDetailsAPI).extra_data.discounts !== undefined){
                for(let i=0; i<JSON.parse(cartDetailsAPI).extra_data.discounts.length; i++){
                    if(JSON.parse(cartDetailsAPI).extra_data.discounts[i].text === "Restaurant Discount")
                        restoDiscount = JSON.parse(cartDetailsAPI).extra_data.discounts[i].percent
                }
            }

            if(JSON.parse(cartDetailsAPI).extra_data.discounts !== undefined){
                for(let i=0; i<JSON.parse(cartDetailsAPI).extra_data.available_discounts.length; i++){
                    if(JSON.parse(cartDetailsAPI).extra_data.available_discounts[i].text === "Restaurant Discount")
                        availableRestoDiscount = JSON.parse(cartDetailsAPI).extra_data.available_discounts[i].percent
                        minAmountForDiscount = JSON.parse(cartDetailsAPI).extra_data.available_discounts[i].min_order_value
                }
            }

            this.setState({
                cartList: cartList,
                sumTotal: sumTotal,
                restoDiscount: restoDiscount,
                minAmountForDiscount: minAmountForDiscount,
                availableRestoDiscount: availableRestoDiscount,
            });
        }
    }

    updateCartValues(keyItem, choice){
        var cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI !== null){
            this.getCartList();
            // var obj = JSON.parse(cartDetailsAPI);
            let itemToUpdate = {};
            // let counter = 0;
            // let cartList = [];
            // let sumTotal = 0;

            let cartItems = JSON.parse(cartDetailsAPI)["items"];
            var itemID = Object.keys(cartItems)
            var itemVals = Object.values(cartItems)
            
            if(keyItem !== undefined){
                for (var i=0; i<itemID.length; i++){
                    if (Number(itemID[i]) === Number(keyItem.DishID)){
                        if(keyItem.Customisation.combination !== null){
                            for(var j=0; j<itemVals[i].customizations.length; j++){  
                                if(this.compareCustomisation(itemVals[i].customizations[j], keyItem.Customisation)){
                                    itemToUpdate = itemVals[i].customizations[j];
                                }
                            }
                        }
                        else{
                            itemToUpdate = {"combination": null, "quantity": keyItem.DishQuantity, "sub_total": keyItem.DishCost};
                        }
                    }
                }
            }
            
            cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI){
                cartDetailsAPI = JSON.parse(cartDetailsAPI);
                if (choice === "Remove"){
                    var url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
                    let req = {}
                    if(itemToUpdate.combination === null){
                        req = {
                            'cart_id': cartDetailsAPI.id,
                            'dish_id': keyItem.DishID,
                            'quantity': 1
                        };
                    }
                    else{
                        req = {
                            'cart_id': cartDetailsAPI.id,
                            'dish_id': keyItem.DishID,
                            'quantity': 1,
                            'combination': itemToUpdate.combination
                        };
                    }

                    var token = localStorage.getItem("auth_token");
                    var headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                            this.getCartList();
                            this.props.updateCartChangedValue(true);

                            cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
                            var cartDetails = JSON.parse(cartDetailsAPI);
                            if(cartDetailsAPI !== null){
                                var itemKey = Object.keys(cartDetails.items)
                                if(itemKey.length === 0){
                                    let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
                                    let req = {
                                        'cart_id': cartDetails.id
                                    };
                                    var token = localStorage.getItem("auth_token");
                                    var headers = {
                                        'Authorization' : 'Bearer ' + token 
                                    }
                                    axios.post(url, req,  {
                                        headers: headers
                                    }).then((response) => {
                                        let data = response.data;
                                        if (data.status === 1){
                                            localStorage.removeItem('cartDetailsAPI');
                                            this.props.updateCartChangedValue(true);
                                        } else if(data.status === -1 && data.errors.message.includes("Token")){
                                            // alert("refresh token")
                                            this.refreshToken()
                                            this.updateCartValues(keyItem, choice);
                                        }
                                        else{
                                            this.props.toggleConfirmationBox(keyItem.DishID + ":" + data.errors.message);
                                            this.props.loadRestaurantDetails();
                                        }
                                    }).catch((error) => this.setState({}));
                                }
                            }
                        }
                        else if(data.status === -1 && data.errors.message.includes("Token")){
                            // alert("refresh token")
                            this.refreshToken()
                            this.updateCartValues(keyItem, choice);
                        }
                    }).catch((error) => this.setState({}));
                }
                else if(choice === "Add"){
                    url = CONSTANTS.hostURL + "api/main_app/cart/add/";
                    let req = {}
                    if(itemToUpdate.combination === null){
                        req = {
                            'cart_id': cartDetailsAPI.id,
                            'dish_id': keyItem.DishID,
                            'quantity': 1
                        };
                    }
                    else{
                        req = {
                            'cart_id': cartDetailsAPI.id,
                            'dish_id': keyItem.DishID,
                            'quantity': 1,
                            'combination': itemToUpdate.combination
                        };
                    }
                    token = localStorage.getItem("auth_token");
                    headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                            this.props.updateCartChangedValue(true);
                            this.getCartList();
                        } else if(data.status === -1 && data.errors.message.includes("Token")){
                            // alert("refresh token")
                            this.refreshToken()
                            this.updateCartValues(keyItem, choice);
                        }
                        else{
                            this.props.toggleConfirmationBox(keyItem.DishID + ":" + data.errors.message);
                            this.props.loadRestaurantDetails();
                        }
                    }).catch((error) => this.setState({}));
                }                   
            }
        } else {
            this.setState({
                restoName: "",
                cartList: [],
                sumTotal: 0,
                restoDiscAmt: 0,
                couponDiscAmt: 0,
                grandTotal: 0,
                //cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
            }); 
        }
        this.props.updateCartChangedValue(true);
    }

    updateCartValues1(keyItem, choice){
        let cartDetails = localStorage.getItem('CartDetails');
        if (cartDetails !== null){
            var obj = JSON.parse(cartDetails);
            let itemToUpdate = 0;
            let counter = 0;
            let cartList = [];
            let sumTotal = 0;

            for (let i=0; i<obj.Cart_Items.length; i++) {
                if(obj.Cart_Items[i].DishID === keyItem){
                    itemToUpdate = counter;
                }
                counter = counter + 1;
            }
            
            if(choice === "Remove"){
                if(Number(obj.Cart_Items[itemToUpdate].DishQuantity) === 1){
                    obj.Cart_Items.splice(itemToUpdate,1);
                }
                else{
                    obj.Cart_Items[itemToUpdate].DishCost = Number(obj.Cart_Items[itemToUpdate].DishCost) - (Number(obj.Cart_Items[itemToUpdate].DishCost) / Number(obj.Cart_Items[itemToUpdate].DishQuantity));
                    obj.Cart_Items[itemToUpdate].DishQuantity = Number(obj.Cart_Items[itemToUpdate].DishQuantity) - Number(1);
                }
            }
            else if(choice === "Add"){
                obj.Cart_Items[itemToUpdate].DishCost = Number(obj.Cart_Items[itemToUpdate].DishCost) + (Number(obj.Cart_Items[itemToUpdate].DishCost) / Number(obj.Cart_Items[itemToUpdate].DishQuantity));
                obj.Cart_Items[itemToUpdate].DishQuantity = Number(obj.Cart_Items[itemToUpdate].DishQuantity) + Number(1);
            }
            else if(choice === "RemoveItem"){
                obj.Cart_Items.splice(itemToUpdate,1);
            }
            
            for(let k = 0; k < obj.Cart_Items.length; k++){
                cartList.push(obj.Cart_Items[k]);
                sumTotal = sumTotal + obj.Cart_Items[k].DishCost;
            }

            let restoDiscAmt = ((this.state.restoDiscPerc/100) * sumTotal).toFixed(2);
            let couponDiscAmt = ((this.state.couponDiscPerc/100) * sumTotal).toFixed(2);
            let grandTotal = ((Number(Number(sumTotal) + Number(this.state.deliveryCharges)) - Number(Number(((this.state.couponDiscPerc/100) * sumTotal).toFixed(2)) + Number(((this.state.restoDiscPerc/100) * sumTotal).toFixed(2))))).toFixed(2);
            
            if (cartList.length === 0){
                localStorage.removeItem('CartDetails');
            } else {
                cartDetails = JSON.stringify(obj);
                localStorage.setItem('CartDetails', cartDetails);
            }
            this.setState({
                restoName: cartList.length === 0 ? "" : obj["RestoName"],
                cartList: cartList,
                sumTotal: sumTotal,
                restoDiscAmt: restoDiscAmt,
                couponDiscAmt: couponDiscAmt,
                grandTotal: grandTotal,
                cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
            });
            var token;
            var headers;
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI){
                cartDetailsAPI = JSON.parse(cartDetailsAPI);
                if (choice === "Remove"){
                    let url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
                    let req = {
                        'cart_id': cartDetailsAPI.id,
                        'dish_id': obj.Cart_Items[itemToUpdate].DishID,
                        'quantity': 1
                    };
                    token = localStorage.getItem("auth_token");
                    headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                            this.props.updateCartChangedValue(true);
                            this.getCartList();
                        } else if(data.status === -1 && data.errors.message.includes("Token")){
                            // alert("refresh token")
                            this.refreshToken()
                            this.updateCartValues1(keyItem, choice);
                        }
                    }).catch((error) => this.setState({}));
                } else if (choice === "Add"){
                    let url = CONSTANTS.hostURL + "api/main_app/cart/add/";
                    let req = {
                        'cart_id': cartDetailsAPI.id,
                        'dish_id': obj.Cart_Items[itemToUpdate].DishID,
                        'quantity': 1
                    };
                    token = localStorage.getItem("auth_token");
                    headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                            this.props.updateCartChangedValue(true);
                            this.getCartList();
                        }
                        else if(data.status === -1 && data.errors.message.includes("Token")){
                            // alert("refresh token")
                            this.refreshToken()
                            this.updateCartValues1(keyItem, choice);
                        }
                    });
                }
            }
        } else {
            this.setState({
                restoName: "",
                cartList: [],
                sumTotal: 0,
                restoDiscAmt: 0,
                couponDiscAmt: 0,
                grandTotal: 0,
                cartDetails : JSON.parse(localStorage.getItem('CartDetails')),
            }); 
        }
        this.props.updateCartChangedValue(true);
    }

    clearCart() {
        this.setState({
            restoName: "",
            cartList: [],
            sumTotal: 0,
            restoDiscAmt: 0,
            couponDiscAmt: 0,
            grandTotal: 0,
            cartDetails : {},
        });
        let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
        if (cartDetailsAPI){
            cartDetailsAPI = JSON.parse(cartDetailsAPI);
            let url = CONSTANTS.hostURL + "api/main_app/cart/delete/";
            let req = {
                'cart_id': cartDetailsAPI.id
            };
            var token = localStorage.getItem("auth_token");
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                let data = response.data;
                if (data.status === 1){
                    //localStorage.removeItem('CartDetails');
                    localStorage.removeItem('cartDetailsAPI');
                    this.props.updateCartChangedValue(true);
                } else if(data.status === -1 && data.errors.message.includes("Token")){
                    // alert("refresh token")
                    this.refreshToken()
                    this.clearCart();
                }
            });
        } else {
            //localStorage.removeItem('CartDetails');
            this.props.updateCartChangedValue(true);
        }
        this.OrderList();
    }

    refreshToken(){
        let url = CONSTANTS.hostURL + "api/accounts/v2/login/refresh-session/";
        let req = {
            'refresh': localStorage.getItem("refresh_token")
        };
        axios.post(url, req).then((response) => {
            let data = response.data;
            if (data.status === 1){
            localStorage.setItem("auth_token", data.data.access);
            localStorage.setItem("refresh_token", data.data.refresh);
            console.log("Token Refreshed")
            return(<>True</>)
            } else {
            console.log("Token Refresh Issue")
            return(<>False</>)
            }
        }).catch((error) => {
            if (error.response){
            if (error.response.data.status === -1){
                console.log("Token Refresh Issue")
            }
            }
            return(<>False</>)
        });
    }

    handleChangeL(){
        this.setState({
            stateL: {backgroundColor: '#EA5B2F', opacity : '1', transition: 'opacity .25s ease-in'},
            stateR: {backgroundColor: '#999999', opacity : '0.5', transition: 'opacity .25s ease-out'},
        });
    };

    handleChangeR(){
        this.setState({
            stateL: {backgroundColor: '#999999', opacity : '0.5', transition: 'opacity .25s ease-out'},
            stateR: {backgroundColor: '#EA5B2F', opacity : '1', transition: 'opacity .25s ease-in'},
        });
    };

    checkAddItems(){
        let cartDetails = JSON.parse(localStorage.getItem('cartDetailsAPI'));
        if (cartDetails !== null && Number(cartDetails["restaurant"].id) !== Number(this.props.restaurantId)){
            return (
                <Grid item xs={12} style={{marginTop: "8px"}}>
                        <span onClick={() => window.location.pathname = "/restaurants/" + cartDetails.restaurant.id} style={{cursor: "pointer", border: "1px solid #5E4B45", color: "#2B2624", padding: "5px 10px", borderRadius: "15px", fontSize: "13px", fontFamily: "Poppins-Regular"}}>+ Add Items</span>
                </Grid>
            ) 
        } else {
            return null;
        }
    }

    OrderList(){
        let cartDetails = JSON.parse(localStorage.getItem('cartDetailsAPI'));
        return(
            <div>
                <Grid container spacing={0} style={{backgroundColor:"white", borderRadius:'5px'}}>
                    <div className="cart-item-details" style={{marginBottom: "10px"}}>
                        <div style={{color: "#CEC3BC", cursor: "pointer"}} onClick={() => this.props.updateCartModalDisplay(false)} title="Close">X</div>
                        {this.state.restoName === "" ? null : <div style={{color: "#CEC3BC", fontFamily: "Poppins-Regular", fontSize: "13px", textAlign: "right", paddingTop: "2px", float: "right", position: "absolute", right: "20px"}} title="Close"><span style={{cursor: "pointer"}} onClick={() => this.clearCart()}>Clear Cart</span></div>}
                    </div>
                </Grid>
                {cartDetails === null ? 
                <div className="ddNoItemsInCart">
                    <img className="ddNoItemsInCartImage" src={NoItemsInCart} alt="No Items in Cart"/>
                    <div style={{fontFamily:"Quicksand-SemiBold"}}>Your Cart Is Empty!</div>
                    {/* <div style={{fontFamily:"Poppins-Medium", fontSize:"12px", paddingTop:"10px"}}>Before you Checkout, you must add<br/>items in your cart.</div> */}
                </div>
                :
                <Grid container spacing={0} style={{backgroundColor:"white", borderRadius:'5px'}}>
                    <Grid item xs={12}>
                        <span style={{fontSize:"18px", fontFamily: "Quicksand-Regular", letterSpacing: "-1px"}}>{this.state.restoName}</span>
                    </Grid>
                    {this.checkAddItems()}
                    {(this.state.restoDiscount > 0 || this.state.availableRestoDiscount > 0) && this.validateCoupon()}
                </Grid>
                }
            </div>
        );
    }
    
    validateCoupon(){
        return(
            <div style={{border:"#FFDEE4 1px solid", background:"#FFF4F6", borderRadius:"12px", padding:"10px", width:"100%", marginTop:"10px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={2} style={{display:"flex", flexWrap:"wrap", alignContent:"center"}} className={this.state.minAmountForDiscount <= this.state.sumTotal ? "LikeButtonActive" : ""}>
                        <img style={{width:"50px", filter: this.state.minAmountForDiscount <= this.state.sumTotal ? "grayscale(0%)" : "grayscale(100%)"}} src={DiscountImage} alt={"DiscountImage"} />
                    </Grid>
                    <Grid item xs={10} style={{display:"flex", flexDirection:"column", justifyContent:"center", paddingLeft:"15px"}}>
                        <div style={{color:"#EA2886", font:"Quicksand-SemiBold", fontSize:"14px"}}>{this.state.restoDiscount > 0 ? this.state.restoDiscount : this.state.availableRestoDiscount}% OFF</div>
                        {this.state.minAmountForDiscount <= this.state.sumTotal ? 
                            <div style={{font:"Poppins-Medium", fontSize:"11px", color:"#5E4B45", textTransform:"none"}}>Congratulations! Discount has been applied.</div>   
                        : 
                            <div style={{font:"Poppins-Medium", fontSize:"11px", color:"#5E4B45", textTransform:"none"}}>Add item(s) worth <span style={{color:"#EB2D85"}}>Rs. {this.state.minAmountForDiscount - this.state.sumTotal} more </span>to avail the discount.</div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }


    trimAddress(description){
        var maxLength = 32;
        // if (this.state.gridSize === 12){
        //     maxLength = 54;
        // } else {
        //     maxLength = 45;
        // }
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                return description.slice(0, maxLength) + "..";
            }
        } else {
            return "";
        }
    }

    OrderList1(){
        return(
            <div style={{paddingBottom:"15px", paddingTop:"18px", marginLeft:"-8px", marginRight:"-8px", paddingLeft:"4px", paddingRight:"4px", marginTop:"15px", maxHeight: this.state.isMobileScreen ? "40vh" : "55vh"}} className="cartScrollBlock">
                <Grid container spacing={2} style={{borderRadius:'5px'}}>
                    {this.AddedItems()}
                </Grid>
            </div>
        );
    }

    AddedItems(){
        const listItems = this.state.cartList.map((keyItem) =>
            <Grid key={"CartItem_"+this.state.cartList.indexOf(keyItem)} item xs={12}>
                <div className="cart-details">
                    <div style={{paddingBottom: "15px"}} className="cart-item-details">
                        <div className="cart-Item-Controls">
                            <RemoveIcon style={{ fontSize: 13 }} className="remove-quantity" onClick={() => this.updateCartValues(keyItem, "Remove")}/>
                            <span className="item-Qty">{keyItem.DishQuantity}</span>
                            <AddIcon style={{ fontSize: 13 }} className="add-quantity" onClick={() => this.updateCartValues(keyItem, "Add")}/>
                        </div>
                        <div className="cart-item-names">
                            <div>{keyItem.DishName}</div>
                            {keyItem.Customisation.combination !== null && <div className="ddCustomisationText">{this.getCustomisationText(keyItem.Customisation)}</div>}
                            {/* <div style={{fontFamily: "Poppins-Regular"}}>Rs. {keyItem.DishCost}</div> */}
                            {(this.state.restoDiscount > 0 && this.state.minAmountForDiscount <= this.state.sumTotal) ? 
                                <>
                                    <span>{"Rs. " + (keyItem.DishCost-(keyItem.DishCost*this.state.restoDiscount/100)).toFixed(2)}</span>
                                    <span style={{color:"#83706A", fontStyle:"Poppins-Medium", textDecorationLine:"line-through", fontSize:"11px"}}>{" Rs. " + keyItem.DishCost}</span>
                                </> 
                                : 
                                <span>{"Rs. "+keyItem.DishCost}</span>
                            }
                        </div>
                        {(keyItem.DishImage !== null && keyItem.DishImage !== "") && 
                            <img className="cart-food-image" src={keyItem.DishImage} alt={keyItem.DishName} />
                        }
                    </div>
                </div>
            </Grid>
        );
        return(
            <>
                {listItems}
            </>
        );
    }

    CouponSection(){
        return(
            <Grid container spacing={2} style={{backgroundColor:"#F8F8F8", paddingTop:"22px"}}>
                <Grid item xs={12}>
                    <div className="cart-details">
                        <Grid item xs={7}>
                            <ScheduleIcon fontSize="small" className="voucher-coupon-style"/>
                            <span className="coupon-name">Voucher Code Name</span>
                            <div className="coupon-discount-details-left">{this.state.couponDiscPerc}% off upto Rs 80</div>
                        </Grid>
                        <Grid item xs={5} style={{textAlign:'right'}}>
                            <span className="coupon-discount-details-right" style={{color:'rgb(79, 147, 236)'}}>-Rs {this.state.couponDiscAmt}</span>
                            <div className="coupon-discount-details-right" style={{color:'#EA5B2F'}}>Change Offer</div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }

    RestoDiscount(){
        return(
            <Grid container spacing={2} style={{backgroundColor:"#F8F8F8", paddingTop:"22px"}}>
                <Grid item xs={12}>
                    <div className="cart-details">
                        <Grid item xs={8}>
                            <FastfoodIcon fontSize="small" className="resto-coupon-style"/>
                            <span className="coupon-name" style={{color:"rgb(248, 0, 165)"}}>Resto Offer {this.state.restoDiscPerc}%</span>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'right'}}>
                            <span className="coupon-discount-details-right" style={{color:"rgb(248, 0, 165)"}}>-Rs {this.state.restoDiscAmt}</span><br/>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }

    handleCartCheckout(){
        this.props.history.push('/cart');
    }

    OrderSummary(){
        let cartDetails = JSON.parse(localStorage.getItem('cartDetailsAPI'));
        return(
            <div style={{paddingTop:(cartDetails !== null && Number(cartDetails["restaurant"].id) !== Number(this.props.restaurantId)) ? "10px" : "31px", width:this.state.isMobileScreen ? "100%" : "94.2%"}}>
                <Grid container spacing={2} onClick={this.props.showDlvCard}>
                    {this.state.restoName === "" ? null : <div className={this.props.itemsInCart > 0 ? "cart-buttons1" : "cart-buttons1-disabled"} style={{justifyContent:"center"}}>
                        {this.props.itemsInCart > 0 ? <div onClick={() => this.handleCartCheckout()} style={{ margin: "auto" }}>
                            <div>
                                <span className="cart-buttons-text" >CHECKOUT &#8226; Rs. {(this.state.restoDiscount > 0 && this.state.minAmountForDiscount <= this.state.sumTotal) ? (this.state.sumTotal-(this.state.sumTotal*this.state.restoDiscount/100)).toFixed(2) : this.state.sumTotal} (Plus Taxes)</span>
                            </div>
                        </div> : <div style={{margin: "auto"}}>
                            <span className="cart-buttons-text" >CHECKOUT &#8226; Rs. {(this.state.restoDiscount > 0 && this.state.minAmountForDiscount <= this.state.sumTotal) ? (this.state.sumTotal-(this.state.sumTotal*this.state.restoDiscount/100)).toFixed(2) : this.state.sumTotal} (Plus Taxes)</span>
                        </div>}
                    </div>}
                </Grid>
            </div>
        );
    }

    render(){
        return(
            <div style={{height:"100%"}}>
                {this.OrderList()}
                {this.OrderList1()}
                {/* {this.CouponSection()} */}
                {/* {this.RestoDiscount()} */}
                {this.OrderSummary()}
            </div>
        );
    }
}

export default withRouter(OrderCard);