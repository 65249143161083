import React, {Component} from 'react';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import CloseIcon from '@material-ui/icons/Close';
import { Star } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import { RiShoppingCart2Line } from "react-icons/ri";
import { RiStarFill } from "react-icons/ri";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonLoading from '../../assets/images/btnLoading.gif';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

class DishCard extends Component{

    constructor(props){
        super(props);
        this.checkAspectRatio = this.checkAspectRatio.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.showReviewsModalHandler = this.showReviewsModalHandler.bind(this);
        this.showAddToCartModalHandler = this.showAddToCartModalHandler.bind(this);
        this.state = {
            gridSize: 12,
            dishImageWidth: "",
            dishImageTransform: "",
            isMobileScreen: false,
            customisation_set: "",
            dish_description: "",
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.setState({
            customisation_set: "",
        })
        this.getDescriptionM(this.props.dish.description, this.props.dish.name);
    }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 650) {
            this.setState({ gridSize: 12 });
            this.setState({ isMobileScreen: true });
        } else if(screen_width < 1000) {
          this.setState({ gridSize: 6 });
          this.setState({ isMobileScreen: true });
        } else if (screen_width < 1300){
            this.setState({ gridSize: 4 }); 
            this.setState({ isMobileScreen: false });
        } else if (screen_width < 2400){
            this.setState({ gridSize: 3 }); 
            this.setState({ isMobileScreen: false });
        } else {
            this.setState({gridSize: 2});
            this.setState({ isMobileScreen: false });
        }
    }

    toggleImageHover(index) {
        this.props.toggleImageHover(index);
    }

    toggleRateHover(index) {
        this.props.toggleRateHover(index);
    }

    toggleReviewHover(index) {
        this.props.toggleReviewHover(index);
    }

    toggleLikeButton(index) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.toggleLikeButton(index);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showRatingsModalHandler(dish) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            this.props.showRatingsModal(dish);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    checkUserLogIn() {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            return true;
        } else {
            return false;
        }
    }

    showAddToCartModalHandler() {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            let cartDetails = localStorage.getItem('CartDetails');
            // let cartItems = [];
            if (cartDetails !== null){
                let cartDetailsObj = JSON.parse(cartDetails);
                if (cartDetailsObj["RestoID"] === this.props.dish.restaurantId){
                    // Add item to cart---
                    
                }
                else{
                    this.props.getReplacementDish(this.props.dish);
                    this.props.toggleConfirmationBox("Items from different restaurant available in the cart. Do you want to remove them?");
                }
            } else {
                if (token !== null && token !== ""){
                    let cartDetails = {
                        "RestoID": this.props.dish.restaurantId,
                        "RestoName": this.props.dish.restaurant,
                        "Cart_Items": [
                            {
                                "DishID": this.props.dish.dishId, 
                                "DishName": this.props.dish.name, 
                                "DishCost": this.props.dish.cost, 
                                "DishImage": this.props.dish.image, 
                                "DishIsVeg": this.props.dish.is_veg, 
                                "DishQuantity": 1
                            }
                        ]
                    }
                    localStorage.setItem('CartDetails', JSON.stringify(cartDetails));
                    this.props.history.push('/restaurants/' + this.props.dish.restaurantId);
                } else {
                    this.props.showAuthPopupModal("login");
                }
            }
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    showReviewsModalHandler(dish) {
        this.props.showReviewsModal(dish);
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    getDescription(description, dishName){
        var maxLength;
        if (this.state.gridSize === 12){
            if(this.state.isMobileScreen)
                maxLength = 30;
            else
                maxLength = 54;
        } else {
            if(this.state.isMobileScreen)
                maxLength = 30;
            else
                maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                return description;
            } else {
                return description.slice(0, maxLength) + "..";
            }
        } else {
            return dishName;
        }
    }

    getShowMore(description, dish){
        var maxLength;
        if (this.state.gridSize === 12){
            if(this.state.isMobileScreen)
            {
                if(dish.name.length > 40)
                    maxLength = 10;
                else
                    maxLength = 30;
            }
            else
                maxLength = 54;
        } else {
            if(this.state.isMobileScreen)
                maxLength = 30;
            else
                maxLength = 45;
        }
        if (description !== "" && description !== null){
            if (description.length > maxLength){
                return <div onClick={() => this.toggleImageHover(this.props.index)} className={this.state.isMobileScreen ? "DishDescriptionReadMoreM" :"DishDescriptionReadMore"}>{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // if (this.state.gridSize === 12){
                //     return <div onClick={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">{dish.imageHover ? "Read less" : "Read more"}</div> ;
                // } else {
                //     return <div onMouseEnter={() => this.toggleImageHover(dish.id - 1)} onMouseLeave={() => this.toggleImageHover(dish.id - 1)} className="DishDescriptionReadMore">Read more</div> ;
                // }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getDescriptionM(description, dishName){
        var maxLength = 40;
        if (description !== "" && description !== null){
            if (description.length < maxLength){
                this.setState({
                    dish_description: <>{description}</> 
                })
            } else {
                this.setState({
                    dish_description: 
                    <>
                        {description.slice(0, maxLength) + ".."}
                        <div onClick={() => this.getShowMoreM(this.props.dish.description, this.props.dish.name)} className="DishDescriptionReadMoreM">Read more</div>
                    </> 
                })
            }
        } else {
            this.setState({
                dish_description: <>{dishName}</> 
            })
        }
    }

    getShowMoreM(description, dish){
        this.setState({
            dish_description: 
            <>
                {description + " "}
                <div onClick={() => this.getDescriptionM(this.props.dish.description, this.props.dish.name)} className="DishDescriptionReadMoreM">Read less</div>
            </> 
        })
    }

    discountCard(dish){
        if (dish.discount && dish.discount !== 0){
            return (
                this.state.isMobileScreen ? 
                    <span className="RibbonM">
                        <div className="RibbonTextM">{dish.discount} % off</div>
                    </span>
                    :
                    <span className="Ribbon">
                        <div className="RibbonText">{dish.discount} % off</div>
                    </span>
            )
        } else {
            return null;
        }
    }
    
    freeDeliveryCard(dish){
        if (dish.is_free_delivery && dish.is_delivery_available){
            return (
                this.state.isMobileScreen ? 
                    <span className="RibbonFDM" style={dish.discount_present === true ? {top:"28px"} : {}}>
                        <div className="RibbonTextFDM">Free Delivery</div>
                    </span>
                    :
                    <span className="RibbonFD" style={dish.discount_present === true ? {top:"22px"} : {}}>
                        <div className="RibbonFDText">Free Delivery</div>
                    </span>
            )
        } else {
            return null;
        }
    }

    checkAspectRatio({target:img}){
        let aspectRatio = img.offsetHeight/img.offsetWidth;
        if (0.95 < aspectRatio < 1.05){
            if (this.state.gridSize === 12){
                this.setState({
                    dishImageWidth: "300px !important",
                });
            } else {
                this.setState({
                    dishImageWidth: "275px !important",
                });
            }
        }

        if (this.state.gridSize === 12){
            if (img.offsetWidth > 300){
                let excessWidth = Math.floor((img.offsetWidth - 300) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        } else {
            if (img.offsetWidth > 275){
                let excessWidth = Math.floor((img.offsetWidth - 275) /2);
                this.setState({
                    dishImageTransform: "translateY(-" + excessWidth + "px) translateX(-" + excessWidth + "px)"
                })
            }
        }
    }

    handleAddCart(dish, changeType, dishAlreadyPresent) {
        var token = localStorage.getItem("auth_token");
        if (token !== null && token !== ""){
            // this.props.addToCartAction(dish.name);
            let cartDetails = localStorage.getItem('CartDetails');
            let cartItems = [];
            if (cartDetails !== null){
                let cartDetailsObj = JSON.parse(cartDetails);
                if (cartDetailsObj["RestoID"] === this.props.dish.restaurantId){
                    cartItems = cartDetailsObj["Cart_Items"];
                } else{
                    this.toggleConfirmationBox("Items from different restaurant available in the cart. Do you want to remove them?");
                    return;
                }
            }
            var obj = {
                "RestoID": this.props.dish.restaurantId,
                "RestoName": this.props.restaurant,
                "Cart_Items": cartItems
            };
            let itemIndex = -1;
            for (var i=0; i<cartItems.length; i++){
                if (cartItems[i]["DishID"] === dish.dishId){
                    obj["Cart_Items"][i] = {
                        "DishID": dish.dishId, 
                        "DishName": dish.name, 
                        "DishCost": dish.cost, 
                        "DishIsVeg": dish.is_veg, 
                        "DishQuantity": changeType === "add" ? cartItems[i]["DishQuantity"] + 1 : cartItems[i]["DishQuantity"] - 1
                    };
                    itemIndex = i;
                    break;
                }
            }
            if (itemIndex !== -1){
                if (obj["Cart_Items"][itemIndex]["DishQuantity"] === 0){
                    obj["Cart_Items"].splice(itemIndex, 1);
                }
            }
            if (obj["Cart_Items"].length > 0){
                cartDetails = JSON.stringify(obj);
                localStorage.setItem('CartDetails', cartDetails);
            } else {
                localStorage.removeItem('CartDetails');
            }
            var headers;
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI){
                cartDetailsAPI = JSON.parse(cartDetailsAPI);
                if (changeType === "add"){
                    let url = CONSTANTS.hostURL + "api/main_app/cart/add/";
                    let req = {
                        'cart_id': cartDetailsAPI.id,
                        'dish_id': dish.dishId,
                        'quantity': 1
                    };
                    token = localStorage.getItem("auth_token");
                    headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                        }
                    }).catch((error) => this.setState({})); 
                } else{
                    let url = CONSTANTS.hostURL + "api/main_app/cart/remove/";
                    let req = {
                        'cart_id': cartDetailsAPI.id,
                        'dish_id': dish.dishId,
                        'quantity': 1
                    };
                    token = localStorage.getItem("auth_token");
                    headers = {
                        'Authorization' : 'Bearer ' + token 
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 1){
                            localStorage.setItem('cartDetailsAPI', JSON.stringify(data.data));
                        }
                    }).catch((error) => this.setState({}));
                }
            }
            this.props.updateCartChangedValue(true);
        } else {
            this.props.showAuthPopupModal("login");
        }
    }

    getIndex(dish){
        for(let i=0; i<this.props.dishesAddingState.length; i++){
            if(this.props.dishesAddingState[i].dishID === dish.dishId)
                return i
        }
    }

    checkAddToCart(dish) {
        var token = localStorage.getItem("auth_token");
        let itemsPresent = false;
        let dishQuantity = 0;
        if (token !== null && token !== ""){
            let cartDetailsAPI = localStorage.getItem('cartDetailsAPI');
            if (cartDetailsAPI !== null) {
                let cartItems = JSON.parse(cartDetailsAPI)["items"];
                var itemKey = Object.keys(cartItems);
                var itemValue = Object.values(cartItems);
                for (var i=0; i<itemValue.length; i++){
                    if (Number(itemKey[i]) === Number(dish.dishId)){
                        dishQuantity = dishQuantity + itemValue[i]["quantity"];
                        itemsPresent = true;
                        //break;
                    }
                } 
            }
            if (dishQuantity > 0) {

            }
        }

        if(dish.is_accepting_orders){
            if (!itemsPresent) {
                return (
                    <>
                        {dish.in_stock === true ?
                            this.props.screenType === "partner_with_us" ? 
                            <span className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span> 
                            : 
                            this.checkUserLogIn() === true ? 
                                <>
                                    {!this.props.dishesAddingState[this.getIndex(dish)].addingState ? 
                                        <span style={{ textDecoration: 'none' }} onClick={() => this.props.addToCartAction(dish, "add", false)}>
                                            <span className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                                        </span> 
                                        :
                                        <img src={ButtonLoading} alt="Complete Order & Pay" style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"6px", left:"10px", height:"20px"} : {width:"70px", position:"relative", top:"12px", left:"20px", height:"20px"}}/>
                                    }
                                </>
                                :
                                <>
                                    <span className={this.state.isMobileScreen ? "ddAddCartBtnM" : "ddAddCartBtn"} onClick={() => this.props.addToCartAction(dish, "add", false)} disabled><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                                </> 
                        :
                            <span className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"}>{this.state.isMobileScreen ? <>Out of Stock</> : <><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</>}</span>
                        }
                        {dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && <span className="ddCustomisableTag" style={{position:"absolute", top:"38px", left: this.state.isMobileScreen ? dish.in_stock !== true ? "109px" : "104px" : "112px"}}>Customisable</span>}
                    </>
                )
            } else {
                var bestDishDivRef = React.createRef();
                return (
                    <>
                    {dish.in_stock === true ?
                        <span ref={bestDishDivRef} style={{position:"relative", padding: "4px 0px 3px 0px", marginLeft: this.state.isMobileScreen ? "10px" : "35px"}} className="cart-Item-Controls">
                            {this.props.dishesAddingState[this.getIndex(dish)].addingState ?
                                <img src={ButtonLoading} alt="loading.." style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"1px", height:"20px"} : {width:"63px", position:"relative"}}/>
                                :
                                <>
                                    <RemoveIcon style={{ fontSize: 14 }} className="remove-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={(e) => {
                                            this.props.addToCartAction(dish, "remove", true, bestDishDivRef.current)}}/>
                                    <span style={{fontSize: "14px"}} className="item-Qty">{dishQuantity}</span>
                                    <AddIcon style={{ fontSize: 14 }} className="add-quantity" 
                                        aria-describedby={this.props.popUpId} 
                                        onClick={(e) => {
                                            this.props.addToCartAction(dish, "add", true, bestDishDivRef.current)}}/>
                                </>
                            }
                            <Popover
                                id={this.props.popUpId}
                                open={this.props.popDishState && (this.props.popUpId === "anchorEl_"+dish.dishId+"_"+dish.id)}
                                anchorEl={this.props.popOverAnchorEl}
                                onClose={() => this.props.handlePopOverClose(dish)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {this.popOverDetails(dish.id)}
                            </Popover>
                        </span>
                    : 
                        this.state.isMobileScreen ?
                            <span className="ddAddCartBtnDisabledM">Out of Stock</span>
                        :
                            <span className="ddAddCartBtnDisabled"><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    }
                    
                    {dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && <span className="ddCustomisableTagAdded" style={{position:"absolute", top: this.state.isMobileScreen ? "35px": dish.in_stock === true ? "30px" : "37px", left: this.state.isMobileScreen ? dish.in_stock === true ? "102px" : "110px" : dish.in_stock === true ? "126px" : "115px"}}>Customisable</span>}
                    </>
                )
            }
        }
        else{
            return(
                <>
                    <span className={this.state.isMobileScreen ? "ddAddCartBtnDisabledM" : "ddAddCartBtnDisabled"} style={this.state.isMobileScreen ? {padding:"4px 20px 5px 20px"}:{}}><RiShoppingCart2Line className="ddAddCartBtnIcon" />Add</span>
                    {dish.customizations !== null && Object.keys(dish.customizations).length !== 0 && <span className="ddCustomisableTag" style={{position:"absolute", top:"38px", left: this.state.isMobileScreen ? dish.in_stock !== true ? "109px" : "104px" : "112px"}}>Customisable</span>}
                </>
            )
        }
    }
    
    onChange(customItem){
        this.setState({
            customisation_set: customItem
        })
    }

    customisationList(){
        if(this.props.temp_customizations !== undefined){
            const customizations = this.props.temp_customizations;
            let listItems = null;
            let customizationsList = [];
            
            if(customizations.length > 1){
                for(var a=0; a<customizations.length; a++){
                    customizationsList.push(this.props.getCustomisationText(customizations[a]));
                }
                listItems = customizationsList.map((customItem, index) =>
                    <div>
                        <FormControlLabel 
                            checked={this.state.customisation_set === customizations[index] ? true: false}
                            onChange={this.onChange.bind(this,customizations[index])}
                            name={"custom_"+index} 
                            control={<Radio 
                                color="#EA5B2F"
                                size="small"/>} 
                            label={<span style={{ fontSize: '13px', fontFamily:'Poppins-Regular'}}>{customItem}</span>}
                        />                     
                    </div>
                );
            }
            else{
                listItems = this.props.getCustomisationText(customizations[0]);
            }
            
            return (
                <div className="ddCustomisationText2">{listItems}</div>
            );
        }
        else{
            return (
                <div className="ddCustomisationText2">No DATA</div>
            );
        }
    }

    popOverDetails(dishID){
        return(
            this.props.repeatCustomisationMessage === "Repeat Last Customisation?" ?
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.props.temp_customizations.length > 1 ?
                            this.state.customisation_set !== "" ? 
                                <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                    this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.state.customisation_set));
                                }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                            :
                                <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                    Select Customisation
                                </span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Yes", JSON.stringify(this.props.temp_customizations[0]));
                            }}><RiShoppingCart2Line className={this.state.isMobileScreen ? "ddAddCartBtnIconM" : "ddAddCartBtnIcon"} />Add</span>
                        }
                        <span style={{marginLeft:"10px"}} className={this.state.isMobileScreen ? "ddPopOverIllChooseBtnM" : "ddPopOverIllChooseBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("No","");
                            }}>I'll Choose
                        </span>
                    </div>
                </div>
            :
                <div className={this.state.isMobileScreen ? "ddRepeatCustomModalM" : "ddRepeatCustomModal"}>
                    <div style={{fontFamily:"Poppins-Semibold"}}>{this.props.repeatCustomisationMessage}</div>
                    {this.customisationList()}
                    <div style={{textAlign:"center"}}>
                        {this.state.customisation_set !== "" ? 
                            <span className={this.state.isMobileScreen ? "ddPopOverAddCartBtnM" : "ddPopOverAddCartBtn"} onClick={() => {
                                this.props.toggleRepeatCustomisation("Remove", JSON.stringify(this.state.customisation_set));
                            }}>Remove Selected</span>
                        :
                            <span className={this.state.isMobileScreen ? "ddPopOverSelectCustomisationM" : "ddPopOverSelectCustomisation"}>
                                Select Customisation
                            </span>
                        }
                        <span style={{marginLeft:"10px"}} className="ddPopOverIllChooseBtn" onClick={() => {
                                this.props.toggleRepeatCustomisation("","");
                            }}>Cancel
                        </span>
                    </div>
                </div>
        );
    }

    render(){
        return (
            this.state.isMobileScreen ? 
            <Grid key={this.props.index} container justifyContent="center" alignItems="center" item xs={this.state.gridSize}>
                <Grid item xs={12}>
                    <div className="ddHomeDishCardWrapperM">
                        <div style={this.props.descriptionOverlayStyle} className="DishDescriptionOverlayM">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                    {this.props.dish.name}
                                </div>
                                <CloseIcon style={{color: "black", opacity: "0.5", cursor: "pointer", fontSize:"20px"}} onClick={() => this.toggleImageHover(this.props.index)} />
                            </div>
                            <div className="DishDescriptionOverlayTextM">
                                {(this.props.dish.description === null || this.props.dish.description === "") ? this.props.dish.name : this.props.dish.description}
                            </div>
                        </div>
                        <Grid container item xs={12} style={{position:"relative"}}>
                            {this.discountCard(this.props.dish)}
                            {this.freeDeliveryCard(this.props.dish)}
                            <div className="ddHomeDishItemM">
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={8} style={{marginTop:"-15px"}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <div style={{width:"190px", display: "flex", flexDirection: "row", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                    <div style={{marginLeft:"-8px"}}>
                                                        {this.props.dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                                    </div>
                                                    <div className="ddHomeDishNameM" style={{position:"relative", width:"95%"}}>{this.props.dish.name}</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} style={{position:"relative", top:"-13px", left:"6px"}}>
                                                {this.props.dish.restaurantId !== -1 ? <Link to={'/restaurants/' + this.props.dish.restaurantId} style={{ textDecoration: 'none' }}>
                                                    <div className="DishRestaurantM">{this.props.dish.restaurant} <ArrowForwardIosIcon style={{transform: 'translateY(3px)', fontSize: '13px'}}/></div>
                                                </Link> : <div className="DishRestaurantM">{this.props.dish.restaurant} <ArrowForwardIosIcon style={{transform: 'translateY(3px)', fontSize: '13px'}}/></div>}
                                                <div className="ddHomeDishCostM">
                                                {/* {this.props.dish.discount_present ? 
                                                    <>
                                                        <span>{"Rs. " + (this.props.dish.cost-(this.props.dish.cost*this.props.dish.discount/100)).toFixed(2)}</span>
                                                        <span style={{color:"#83706A", fontStyle:"Poppins-Medium", textDecorationLine:"line-through", fontSize:"11px"}}>{" Rs. " + this.props.dish.cost}</span>
                                                    </> 
                                                    : 
                                                    <span>{"Rs. "+this.props.dish.cost}</span>} */}
                                                {this.props.dish.discounted_cost !== this.props.dish.cost ? 
                                                    <>
                                                        <span>{"Rs. " + (this.props.dish.discounted_cost).toFixed(2)}</span>
                                                        <span style={{color:"#83706A", fontStyle:"Poppins-Medium", textDecorationLine:"line-through", fontSize:"11px"}}>{" Rs. " + this.props.dish.cost}</span>
                                                    </> 
                                                    : 
                                                    <span>{"Rs. "+this.props.dish.cost}</span>}
                                                </div>
                                                <div className="ddNonRatedDishDescM">
                                                    { this.state.dish_description }
                                                </div>
                                            </Grid>
                                            
                                            {/* {this.props.dish.in_stock !== true && <Grid item xs={12} style={{position:"absolute", bottom:"40px", left:"15px"}}><span className="ddOOSTag">Out of Stock</span></Grid>} */}

                                            <Grid item xs={12} style={{position:"absolute", bottom:"5px", left:"-3px"}}>
                                                <span className="ddDishCardRateButtonM" style={{marginLeft:"7px"}} onClick={() => this.showRatingsModalHandler(this.props.dish)}><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                                {this.checkAddToCart(this.props.dish)}
                                            </Grid>
                                        </Grid>
                                    </Grid>                                                     
                                </Grid>
                                
                                {this.props.dish.image !== null && <div className="ddHomeDishImageBlockM">
                                    <img className="ddHomeDishImageM" src={this.props.dish.image} alt={this.props.dish.name}/>
                                </div>}
                                <div className="ddNonHomeDishRatingBlockM" 
                                    // onClick={() => this.showReviewsModalHandler(this.props.dish)}
                                >
                                    <Star fontSize="small" className="ddNonRatedDishStarIcon"/>
                                    <span style={{fontFamily: "Poppins-SemiBold", fontSize:"14px"}}>{this.props.dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize:"12px"}}> ({this.props.dish.no_rating})</span>
                                </div>
                                {/* <div className="ddHomeDishLikeBlockM">
                                    <div className="LikeButtonContainer">
                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                            <span onClick={() => this.toggleLikeButton(this.props.dish.id)} className={this.props.likeButtonStyle}></span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Grid>
                        
                        {/* <div className="DishCardTopContainer">
                            {this.discountCard(this.props.dish)}
                            <div style={this.props.overlayStyle} className="DishImageOverlay">
                                <img onLoad={this.checkAspectRatio} style={{transform: this.state.dishImageTransform, width: this.state.dishImageWidth}} src={this.props.dish.image} alt="DishImage" className="DishImage"/>
                                <span className="DishRatingsNumber">({this.props.dish.no_rating})</span>
                                <div className="DishRatingsSuperContainer" style={{cursor: "pointer"}} onClick={() => this.showReviewsModalHandler(this.props.dish)}>
                                    <div className="DishRatingsContainer">
                                        <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "5px"}}/>
                                        <span style={{fontFamily: "Poppins-SemiBold"}}>{this.props.dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58"}}> ({this.props.dish.no_rating})</span>
                                    </div>
                                </div>
                                <div className="LikeButtonContainerSuper">
                                    <div className="LikeButtonContainer">
                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "11px", border:"#EDEDED 1px solid"}}>
                                            <span onClick={() => this.toggleLikeButton(this.props.index)} className={this.props.likeButtonStyle}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="DishTextContainer">
                                <div className="DishText">
                                    <span className="DishDescription">{ this.getDescription(dish.description, dish.name) }</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${this.state.isMobileScreen ? "DishCardBottomContainerMobile" : "DishCardBottomContainer"}`}>
                            <div className="DishDetailsRow">
                                <div>
                                    {this.props.dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <FiberManualRecordIcon style={{ fontSize: 13 }} className="nonVegTag"/>}
                                </div>
                                <div className="DishImageText">
                                    <div className="DishName">{this.props.dish.name}</div>
                                    {this.props.dish.restaurantId !== -1 ? <Link to={'/restaurants/' + this.props.dish.restaurantId} style={{ textDecoration: 'none' }}>
                                        <div className="DishRestaurant">{this.props.dish.restaurant}</div>
                                    </Link> : <div className="DishRestaurant">{this.props.dish.restaurant}</div>}
                                    <div  className="DishName"> 
                                        Rs.{this.props.dish.cost}
                                    </div>
                                    <div style={{flex: "1 1 auto"}}>
                                        <div className="DishDescription">
                                            { this.getDescription(this.props.dish.description, this.props.dish.name) }
                                            { this.getShowMore(this.props.dish.description, this.props.dish) }
                                        </div>
                                    </div>
                                    {this.props.dish.in_stock !== true && <div style={{position:"absolute", bottom:"60px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                    <div className="DishCardActionsContainer" style={{position:"relative"}}>
                                        <div onClick={() => this.showRatingsModalHandler(this.props.dish)}>
                                            <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                        </div>

                                        {this.checkAddToCart(this.props.dish)}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Grid>
            </Grid>
            :
            <Grid key={this.props.index} container justifyContent="center" alignItems="center" item xs={this.state.gridSize}>
                <Grid item xs={12}>
                    <div className="DishCardWrapper HomeDishCardWrapper">
                        <div style={this.props.descriptionOverlayStyle} className="DishDescriptionOverlay">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{flex: "1"}} className="DishDescriptionOverlayHeader">
                                    {this.props.dish.name}
                                </div>
                                <CloseIcon style={{color: "black", opacity: "0.5", cursor: "pointer", fontSize:"20px"}} onClick={() => this.toggleImageHover(this.props.index)} />
                            </div>
                            <div className="DishDescriptionOverlayText">
                                {(this.props.dish.description === null || this.props.dish.description === "") ? this.props.dish.name : this.props.dish.description}
                            </div>
                        </div>
                        <div className="DishCardTopContainer">
                            {this.discountCard(this.props.dish)}
                            {this.freeDeliveryCard(this.props.dish)}
                            <div style={this.props.overlayStyle} className="DishImageOverlay">
                                <img onLoad={this.checkAspectRatio} style={{transform: this.state.dishImageTransform, width: this.state.dishImageWidth}} src={this.props.dish.image} alt="DishImage" className="DishImage"/>
                                <span className="DishRatingsNumber">({this.props.dish.no_rating})</span>
                                <div className="DishRatingsSuperContainer" style={{cursor: "pointer"}} 
                                    // onClick={() => this.showReviewsModalHandler(this.props.dish)}
                                >
                                    <div className="DishRatingsContainer">
                                        <Star style={{color: "#0F9917", fontSize: "14px", transform: "translateY(2px)", marginRight: "2px"}}/>
                                        <span style={{fontFamily: "Poppins-SemiBold"}}>{this.props.dish.rating}</span><span style={{fontFamily: "Poppins-Medium", color: "#735E58", fontSize:"11px"}}> ({this.props.dish.no_rating})</span>
                                    </div>
                                </div>
                                <div className="LikeButtonContainerSuper" style={{marginRight:"4px", marginTop:"-2px"}}>
                                    <div className="LikeButtonContainer">
                                        <div style={{backgroundColor: "#FFFFFF", padding: "4px 5px 3px 5px", borderRadius: "8px", border:"#EDEDED 1px solid"}}>
                                            <span onClick={() => this.toggleLikeButton(this.props.index)} className={this.props.likeButtonStyle}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="DishTextContainer">
                                <div className="DishText">
                                    <span className="DishDescription">{ this.getDescription(dish.description, dish.name) }</span>
                                </div>
                            </div> */}
                        </div>
                        <div className={`${this.state.isMobileScreen ? "DishCardBottomContainerMobile" : "DishCardBottomContainer"}`}>
                            <div className="DishDetailsRow">
                                <div>
                                    {this.props.dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13 }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13 }} className="nonVegTag"/>}
                                </div>
                                <div className="DishImageText" style={{marginTop:"2px"}}>
                                    <div className="DishName">{this.props.dish.name}</div>
                                    {this.props.dish.restaurantId !== -1 ? <Link to={'/restaurants/' + this.props.dish.restaurantId} style={{ textDecoration: 'none' }}>
                                        <div className="DishRestaurant">{this.props.dish.restaurant} <ArrowForwardIosIcon style={{transform: 'translateY(3px)', fontSize: '13px'}}/></div>
                                    </Link> : <div className="DishRestaurant">{this.props.dish.restaurant} <ArrowForwardIosIcon style={{transform: 'translateY(2px)', fontSize: '13px'}}/></div>}
                                    <div style={{fontFamily:"Poppins-Medium", fontSize:"14px"}}> 
                                        {/* {this.props.dish.discount_present ? 
                                        <>
                                            <span>{"Rs. " + (this.props.dish.cost-(this.props.dish.cost*this.props.dish.discount/100)).toFixed(2)}</span>
                                            <span style={{color:"#83706A", fontStyle:"Poppins-Medium", textDecorationLine:"line-through", fontSize:"12px"}}>{" Rs. " + this.props.dish.cost}</span>
                                        </> 
                                        : 
                                        <span>{"Rs. "+this.props.dish.cost}</span>} */}
                                        {this.props.dish.discounted_cost !== this.props.dish.cost ? 
                                        <>
                                            <span>{"Rs. " + (this.props.dish.discounted_cost).toFixed(2)}</span>
                                            <span style={{color:"#83706A", fontStyle:"Poppins-Medium", textDecorationLine:"line-through", fontSize:"12px"}}>{" Rs. " + this.props.dish.cost}</span>
                                        </> 
                                        : 
                                        <span>{"Rs. "+this.props.dish.cost}</span>}
                                    </div>
                                    <div style={{flex: "1 1 auto"}}>
                                        <div className="DishDescription">
                                            { this.getDescription(this.props.dish.description, this.props.dish.name) }
                                            { this.getShowMore(this.props.dish.description, this.props.dish) }
                                        </div>
                                    </div>
                                    {this.props.dish.in_stock !== true && <div style={{position:"absolute", bottom:"60px", left:"30px"}}><span className="ddOOSTag">Out of Stock</span></div>}
                                    <div className="DishCardActionsContainer" style={{position:"relative"}}>
                                        <div onClick={() => this.showRatingsModalHandler(this.props.dish)}>
                                            <span className="ddDishCardRateButton"><RiStarFill className="ddDishCardRateIcon"/>Rate</span>
                                        </div>

                                        <div style={{float:"right"}}>
                                            {this.checkAddToCart(this.props.dish)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }

}

export default withRouter(DishCard);