import React, { Component } from 'react';
import Header from '../components/AboutUs/Header';
import Authentication from '../components/AboutUs/Authentication';
import Footer from '../components/Commons/Footer';
import Segment1 from '../components/AboutUs/Segment1'
import Segment2 from '../components/AboutUs/Segment2'
import Segment3 from '../components/AboutUs/Segment3'
import Segment4 from '../components/AboutUs/Segment4'
import { withRouter } from 'react-router';
import { setDeviceToken } from '../services/notification_handler';
import CONSTANTS from '../config/constants';
import axios from 'axios';

// About Us Page
class AboutUs extends Component {
  
  constructor(){
    super();
    this.toggleAuthModal = this.toggleAuthModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.closeAuthModal = this.closeAuthModal.bind(this);
    this.state = {
      authModalOpen: false,
      activeForm: "",
      isMobileScreen: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    if (localStorage.getItem("auth_token") !== null && localStorage.getItem("auth_token") !== ""){
      let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
      let firebaseReq = { 
        'token': localStorage.getItem('device_token')
      }
      let firebaseHeaders = {
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
      }
      axios.post(firebaseUrl, firebaseReq,  {
        headers: firebaseHeaders
      }).then((response) => {});
    }

    setDeviceToken();
    navigator.serviceWorker.addEventListener('message', event => {
      if ('data' in event.data.data){
        var orderDetails = JSON.parse(event.data.data.data);
        var orderEvent = event.data.data.event;
        if (orderEvent === 'new_order'){
          this.setState({
            newOrderModal: true,
            newOrderId: orderDetails.order_id
          })
        } 
      }
    });
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: true
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  render() {
    return (
      <div>
        <Header
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          authModalOpen={this.state.authModalOpen}
        />
        <Authentication
          authModalOpen={this.state.authModalOpen}
          activeForm={this.state.activeForm}
          toggleAuthModal={this.closeAuthModal}
          setActiveForm={this.setActiveForm}
        />
        <Segment1 
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
        />
        <Segment2 
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
        />
        <Segment3 />
        <Segment4
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
        />
        {/* <HeaderDescription
          authModalOpen={this.state.authModalOpen}
        />
        <Tagline/>
        <Description/>
        <Content
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
        /> */}
        <Footer/>
      </div>
    );
  }
}

export default withRouter(AboutUs);
