import React, { Component } from 'react';
import '../../assets/styles/Home.css';
import '../../assets/styles/Dishes.css';
import '../../assets/styles/Restaurants.css';
import LikedDishes from '../Profile/LikedDishes';
import ReviewUsers from '../Profile/ReviewUsers';
import LikedCuisines from '../Profile/LikedCuisines';

class TabsContent extends Component {

    constructor(props){
        super(props);
        this.tabContentHandler = this.tabContentHandler.bind(this);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.removeLikeHandler = this.removeLikeHandler.bind(this);
        this.removeUserPreferencesHandler = this.removeUserPreferencesHandler.bind(this);
        this.addUserPreferenceHandler = this.addUserPreferenceHandler.bind(this);
    }

    componentWillMount() {
        this.setState({
          activeItemIndex: 0,
          noOfItems: 0
        });
    }

    removeLikeHandler(dish){
        this.props.removeLike(dish);
    }

    showRatingsModalHandler(dish) {
        this.props.showRatingsModal(dish);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
      }

    updateDimensions() {
        let screen_width = window.innerWidth - 20;
        if(screen_width < 400) {
            this.setState({ noOfItems: 1 });
        } else if(screen_width < 800) {
          this.setState({ noOfItems: 2 });
        } else if (screen_width < 1200){
            this.setState({ noOfItems: 3 }); 
        } else if (screen_width < 2000){
            this.setState({ noOfItems: 4 }); 
        } else {
            this.setState({noOfItems: 5});
        }
    }

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    showContactModalHandler() {
        this.props.showContactModal();
    }

    removeUserPreferencesHandler(item){
        this.props.removeUserPreferences(item);
    }

    addUserPreferenceHandler(id){
        this.props.addUserPreference(id);
    }

    tabContentHandler(){
        if (this.props.activeTab === "likes"){
            return <LikedDishes
                likes={this.props.likes}
                likesFetched={this.props.likesFetched}
                showRatingsModal={this.showRatingsModalHandler}
                removeLike={this.removeLikeHandler}
            />;
        } else if (this.props.activeTab === "reviews") {
            return <ReviewUsers
                reviews={this.props.reviews}
                reviewsFetched={this.props.reviewsFetched}
            />;
        } else {
            return <LikedCuisines
                preferences={this.props.preferences}
                preferencesFetched={this.props.preferencesFetched}
                removeUserPreferences={this.removeUserPreferencesHandler}
                addUserPreference={this.addUserPreferenceHandler}
            />
        }
    }


    render() {
        return (
            <div className="TabsContentContainer">
                {this.tabContentHandler()}
            </div>
        )
    }
}


export default TabsContent;