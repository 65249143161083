import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import blue_image from '../../assets/images/blue_image.webp';
import pink_image from '../../assets/images/pink_image.webp';
import yellow_image2 from '../../assets/images/yellow_image2.webp';
import good_food_only from '../../assets/images/good_food_only.webp';
import good_food_only_step1 from '../../assets/images/parallax/goodFoodOnly/step1.png';
import good_food_only_step2 from '../../assets/images/parallax/goodFoodOnly/step2.gif';
import good_food_only_step3 from '../../assets/images/parallax/goodFoodOnly/step3.png';
import good_food_only_step4 from '../../assets/images/parallax/goodFoodOnly/step4.gif';
import best_dish_zone from '../../assets/images/best_dish_zone.webp';
import checkbox from '../../assets/images/checkbox.svg';
import '../../assets/styles/Home.css';

// Detailed Content in Landing Page after Site Description
class ContentNew extends Component {

    constructor(props){
        super(props);
        this.toggleAuthModalHandler = this.toggleAuthModalHandler.bind(this);
        this.setActiveFormHandler = this.setActiveFormHandler.bind(this);
    }

    toggleAuthModalHandler() {
        this.props.toggleAuthModal();
    }

    setActiveFormHandler(form){
        this.props.setActiveForm(form);
    }

    render() {
        return (
            <div className="Content">
                <YellowContainer
                    goodFoodOnlyStep={this.props.goodFoodOnlyStep}
                />
                <BlueContainer 
                    toggleAuthModal={this.toggleAuthModalHandler}
                    setActiveForm={this.setActiveFormHandler}
                />
                <PinkContainer 
                    toggleAuthModal={this.toggleAuthModalHandler}
                    setActiveForm={this.setActiveFormHandler}
                />
                 <YellowContainer2/>
                 <BestDishContainer/>
                 <RedContainer
                    toggleAuthModal={this.toggleAuthModalHandler}
                    setActiveForm={this.setActiveFormHandler}
                 />
            </div>
        )
    }
}

// Grey Container of the content
class BlueContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            buttonID: "signup-button-content"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render() {
        if (this.state.buttonID === 'signup-button-content-mobile'){
            return (
                <div style={{backgroundColor: '#3356AA', paddingBottom: '0px', paddingTop: '50px'}} className="BlueContentNew">
                    <div style={{padding: '0px 8vw'}} className="ContentDescription">
                        <h2 style={{color: 'white', width: '84vw', fontSize: '24px', lineHeight: 'normal'}} className="ContentDescriptionHeader">
                            Fast and Delicious: <br/><span style={{color: "#EA2886"}}>Choose the Best Food in Seconds</span>
                        </h2>
                        <div style={{color: 'white', width: '84vw', fontSize: '16px'}} className="ContentDescriptionDetails">
                            We've made ordering great food in Bangalore a breeze! We handpick the two best dishes from each restaurant, so you don't have to waste time scrolling. No more second-guessing if that new dish will be worth it. Trust us to deliver the best of Bangalore food, right at your doorstep!
                        </div>
                    </div>
                    <div style={{transform: 'translateY(4.2vw)'}} className="HexagonImageContainer">
                        <img style={{paddingLeft: '0vw', paddingRight: '0px', width: '82vw'}} className="HexagonImage" src={blue_image} alt="hexagon_image1"/>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: '#3356AA', paddingBottom: '0vw', paddingTop: '100px'}} className="BlueContentNew">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid style={{transform: 'translateY(1.7vw)'}} className="HexagonImageContainer" container item xs={5} direction="column" justifyContent="center">
                            <img style={{paddingLeft: '0vw', paddingRight: '0px'}} className="HexagonImage" src={blue_image} alt="hexagon_image1"/>
                        </Grid>
                        <Grid container item xs={7} direction="column" justifyContent="center">
                            <div className="ContentDescription">
                                <div>
                                    <h2 style={{color: 'white'}} className="ContentDescriptionHeader">
                                        Fast and Delicious: <br/><span style={{color: "#EA2886"}}>Choose the Best Food in Seconds</span>
                                    </h2>
                                    <div style={{color: 'white', width: '30vw'}} className="ContentDescriptionDetails">
                                        We've made ordering great food in Bangalore a breeze! We handpick the two best dishes from each restaurant, so you don't have to waste time scrolling. No more second-guessing if that new dish will be worth it. Trust us to deliver the best of Bangalore food, right at your doorstep!
                                    </div>
                                    {/* <Button onClick={() => {
                                        window.scrollTo(0, 0);
                                        this.props.toggleAuthModal();
                                        this.props.setActiveForm("register");
                                    }} id={this.state.buttonID}>
                                        SIGN UP NOW
                                    </Button> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

// Yellow Container of the content
class YellowContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getGoodFoodOnlyImage(){
        if (this.props.goodFoodOnlyStep === 1){
            return good_food_only_step1;
        } else if (this.props.goodFoodOnlyStep === 2){
            return good_food_only_step2;
        } else if (this.props.goodFoodOnlyStep === 3){
            return good_food_only_step3;
        } else if (this.props.goodFoodOnlyStep === 4){
            return good_food_only_step4;
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <div className="YellowContainer">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid container item xs={12} direction="column" justifyContent="center">
                            <div style={{margin: '0px 8vw', marginTop: '60px'}} className= "ContentDescription YellowText">
                                <ContentDescription isMobileScreen={this.state.isMobileScreen} buttonText="Find Food Now"/>
                            </div>
                        </Grid>
                        <Grid container item xs={12} direction="column" justifyContent="center">
                            <img style={{margin: '20px 8vw 40px 8vw', width: '84vw'}} className="HexagonImage" src={good_food_only} alt="yellow_image"/>
                        </Grid>
                    </Grid>
                </div>
            )
        } else {
            return (
                <div className="YellowContainer">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid container item xs={7} direction="column" justifyContent="center">
                            <div style={{marginLeft: '8vw', marginTop: '100px'}} className= "ContentDescription YellowText">
                                <ContentDescription isMobileScreen={this.state.isMobileScreen} buttonText="Find Food Now"/>
                            </div>
                        </Grid>
                        <Grid container item xs={5} direction="column" justifyContent="center">
                            <img style={{width: '35vw', marginTop: '100px', marginBottom: '50px'}} className="HexagonImage" src={this.getGoodFoodOnlyImage()} alt="yellow_image"/>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

// Yellow Container of the content
class YellowContainer2 extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <div style={{paddingBottom: '0px'}} className="YellowContainer YellowContentNew">
                    <div style={{marginLeft: '8vw', marginTop: '40px', paddingBottom: '0px'}} className= "ContentDescription YellowText">
                        <ContentDescription2 isMobileScreen={this.state.isMobileScreen} buttonText="Find Food Now"/>
                    </div>
                    <div className='HexagonImageContainer'>
                        <img style={{marginTop: '0px', marginLeft: '0vw', transform: 'translate(-12px, 5px)', width: '90vw'}} className="HexagonImage" src={yellow_image2} alt="yellow_image"/>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{paddingBottom: '0px !important', backgroundColor: '#F9D543'}} className="YellowContentNew">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid container item xs={5} direction="column" justifyContent="center">
                            <img style={{marginTop: '1vw', marginLeft: '0vw'}} className="HexagonImage" src={yellow_image2} alt="yellow_image"/>
                        </Grid>
                        <Grid container item xs={7} direction="column" justifyContent="center">
                            <div style={{marginLeft: '8vw'}} className= "ContentDescription YellowText">
                                <ContentDescription2 isMobileScreen={this.state.isMobileScreen} buttonText="Find Food Now"/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

// Description present in the containers
class ContentDescription extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            buttonID: "signup-button"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ buttonID: "signup-button-mobile" });
        } else {
            this.setState({ buttonID: "signup-button" });
        }
    }

    render(){
        return (
            <div>
                <h2 style={{letterSpacing: '-2px', fontSize: this.props.isMobileScreen? '24px': '2.5vw', width: this.props.isMobileScreen? '84vw': '35vw', lineHeight: 'normal'}} className="ContentDescriptionHeader">
                    DaiDish Recommends <br/><span style={{color: "#3356AA"}}>the Finest Culinary Delights</span>
                </h2>
                <div style={{maxWidth: '420px', fontSize: this.props.isMobileScreen? '16px': '1.1vw', width: this.props.isMobileScreen? '84vw': '35vw'}} className="ContentDescriptionDetails">
                    At DaiDish, we're like your foodie friend who knows all the best dishes. We don't just rate the service or ambience, but focus on what matters most when you order online: the incredible taste. We handpick the top-notch food in Bangalore, so you can indulge in the absolute best.
                </div>
                <Button className='ButtonContentNew' style={{backgroundColor: 'white', color: '#2B2624', border: 'white'}} onClick={() => window.scrollTo(0, 0)} id={this.state.buttonID}>
                    {this.props.buttonText}
                </Button>
            </div>
        )
    }
}

// Description present in the containers
class ContentDescription2 extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            buttonID: "signup-button"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ buttonID: "signup-button-mobile" });
        } else {
            this.setState({ buttonID: "signup-button" });
        }
    }

    render(){
        return (
            <div>
                <h2 style={{letterSpacing: '-2px', fontSize: this.props.isMobileScreen? '24px': '2.5vw', width: this.props.isMobileScreen? '84vw': '35vw', lineHeight: 'normal'}} className="ContentDescriptionHeader">
                    Supporting <br/><span style={{color: "#3356AA"}}>Local Restaurants</span>
                </h2>
                <div style={{maxWidth: '420px', fontSize: this.props.isMobileScreen? '16px': '1.1vw', width: this.props.isMobileScreen? '84vw': '35vw'}} className="ContentDescriptionDetails">
                    We not only care about your dining experience but also support your favorite restaurants in Bangalore. We charge super low commissions to help them thrive and grow.
                </div>
            </div>
        )
    }
}

class PinkContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            buttonID: "signup-button-content"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render() {
        if (this.state.buttonID === 'signup-button-content-mobile'){
            return (
                <div style={{backgroundColor: '#C81E70', paddingBottom: '0vw', paddingTop: '10vw'}} className="">
                    <div style={{transform: 'translate(0px, 5vw)'}} className="ContentDescription">
                        <h2 style={{color: 'white', width: '84vw', fontSize: '24px', lineHeight: 'normal'}} className="ContentDescriptionHeader">
                            Personalized <br/><span style={{color: "#FCD844"}}>for Your Tastes</span>
                        </h2>
                        <div style={{color: 'white', width: '84vw', fontSize: '16px'}} className="ContentDescriptionDetails">
                        We understand your food preferences just like your mom does! We ensure that you receive the best food in Bangalore that matches your taste. Simply let us know your likes and dislikes once, and we'll remember. 
                        </div>
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            this.props.toggleAuthModal();
                            this.props.setActiveForm("register");
                        }} className='ButtonContentNew' id={this.state.buttonID}
                            style={{backgroundColor: 'white', border: 'white', color: '#2B2624'}}
                        >
                            Sign Up Now
                        </Button>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}} className="HexagonImageContainer">
                        <div style={{flex: '1'}}></div>
                        <img style={{width: '100vw', transform: 'translate(25vw, 15vw)'}} className="HexagonImage" src={pink_image} alt="hexagon_image1"/>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{backgroundColor: '#C81E70', paddingBottom: '0vw', paddingTop: '20px'}} className="">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Grid container item xs={7} direction="column" justifyContent="center">
                            <div className="ContentDescription">
                                <div>
                                    <h2 style={{color: 'white'}} className="ContentDescriptionHeader">
                                        Personalized <br/><span style={{color: "#FCD844"}}>for Your Tastes</span>
                                    </h2>
                                    <div style={{color: 'white', width: '30vw'}} className="ContentDescriptionDetails">
                                        We understand your food preferences just like your mom does! We ensure that you receive the best food in Bangalore that matches your taste. Simply let us know your likes and dislikes once, and we'll remember. 
                                    </div>
                                    <Button onClick={() => {
                                        window.scrollTo(0, 0);
                                        this.props.toggleAuthModal();
                                        this.props.setActiveForm("register");
                                    }} className='ButtonContentNew' id={this.state.buttonID}
                                        style={{backgroundColor: 'white', border: 'white', color: '#2B2624'}}
                                    >
                                        Sign Up Now
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="HexagonImageContainer" container item xs={5} direction="column" justifyContent="center">
                            <img style={{paddingRight: '0vw', transform: 'translate(5.4vw, 6.5vw)'}} className="HexagonImage" src={pink_image} alt="hexagon_image1"/>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }
}

class BestDishContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <div style={{width: 'calc(100vw)', overflowX: 'hidden', padding: '30px 0px 30px 0px'}}>
                    <div style={{color: '#2B2624', fontFamily: 'QuickSand-Bold', fontSize: '24px', textAlign: 'center', letterSpacing: '-1px', transform: 'translateY(7vw)', width: '50vw', margin: 'auto'}}>
                        Order From The Best Dish Zone!
                    </div>
                    <img style={{width: 'calc(100vw + 55px)', transform: 'translateX(-50px)'}} src={best_dish_zone} alt="hexagon_image1"/>
                </div>
            )
        } else {
            return (
                <div style={{width: 'calc(100vw)', overflowX: 'hidden', padding: '50px 0px 30px 0px'}}>
                    <div style={{color: '#2B2624', fontFamily: 'QuickSand-Bold', fontSize: '40px', textAlign: 'center', letterSpacing: '-1px', transform: 'translateY(7vw)', width: '80vw', margin: 'auto'}}>
                        Order From The Best Dish Zone!
                    </div>
                    <img style={{width: 'calc(100vw + 55px)', transform: 'translateX(-50px)'}} src={best_dish_zone} alt="hexagon_image1"/>
                </div>
            )
        }
    }
}

class RedContainer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <div style={{height: 'unset', paddingBottom: '40px'}} className="WhyDaidish">
                    <div style={{transform: 'none', width: '84vw', margin: 'auto', paddingTop: '50px'}} className="WhyDaidishContent" >
                        <div style={{fontSize: '24px', textAlign: 'left'}} className="WhyDaidishHeader">
                            Get <span style={{color: "#FCD844"}}>The Highest Discounts:</span>
                        </div>
                        <div style={{fontSize: '20px', textAlign: 'left', padding: '5px 0px', marginTop: '15px', fontFamily: 'QuickSand-SemiBold'}} className="WhyDaidishHeader">
                            <img style={{width: '25px', marginRight: '10px', transform: 'translateY(3px)'}} src={checkbox} alt="hexagon_image1"/>On Any Dish,
                        </div>
                        <div style={{fontSize: '20px', textAlign: 'left', padding: '5px 0px', fontFamily: 'QuickSand-SemiBold'}} className="WhyDaidishHeader">
                            <img style={{width: '25px', marginRight: '10px', transform: 'translateY(3px)'}} src={checkbox} alt="hexagon_image1"/>On Any Given Day,
                        </div>
                        <div style={{fontSize: '20px', textAlign: 'left', padding: '5px 0px', fontFamily: 'QuickSand-SemiBold'}} className="WhyDaidishHeader">
                            <img style={{width: '25px', marginRight: '10px', transform: 'translateY(3px)'}} src={checkbox} alt="hexagon_image1"/>Without A Membership!
                        </div>
                        <div style={{fontSize: '16px', width: '84vw', margin: 'auto', marginTop: '20px', padding: '0px'}} className="WhyDaidishDetails">
                            Save your time comparing different platforms for the best deals on the food you're craving. Just get online on DaiDish and order. Easy peasy!
                        </div>
                        <div style={{paddingTop: '40px', width: '180px'}}>
                            <Button onClick={() => {
                                window.scrollTo(0, 0);
                                this.props.toggleAuthModal();
                                this.props.setActiveForm("register");
                            }} className='ButtonContentNew' id='signup-button-content' style={{backgroundColor: 'white', border: 'white', color: '#2B2624'}}>
                                Sign Up Now
                            </Button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{height: 'unset', paddingBottom: '60px'}} className="WhyDaidish">
                    <div style={{transform: 'none', width: '60vw', margin: 'auto', paddingTop: '60px'}} className="WhyDaidishContent" >
                        <div style={{fontSize: '2.4vw', textAlign: 'center'}} className="WhyDaidishHeader">
                            Get <span style={{color: "#FCD844"}}>The Highest Discounts</span> On Any Dish, <br/>On Any Given Day, Without A Membership!
                        </div>
                        <div style={{fontSize: '1.1vw', textAlign: 'center', width: '45vw', margin: 'auto', marginTop: '40px'}} className="WhyDaidishDetails">
                            Save your time comparing different platforms for the best deals on the food you're craving. Just get online on DaiDish and order. Easy peasy!
                        </div>
                        <div style={{paddingTop: '40px', width: '180px', margin: 'auto'}}>
                            <Button onClick={() => {
                                window.scrollTo(0, 0);
                                this.props.toggleAuthModal();
                                this.props.setActiveForm("register");
                            }} className='ButtonContentNew' id='signup-button-content' style={{backgroundColor: 'white', border: 'white', color: '#2B2624'}}>
                                Sign Up Now
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default ContentNew;