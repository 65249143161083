import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import '../../assets/styles/Dishes.css';
import logo from '../../assets/images/logo_black.png';
import logo_mobile from '../../assets/images/logo_mobile.png';
import profile from '../../assets/images/profile.png';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';
import LocationOnIcon from '@material-ui/icons/LocationOn';


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    componentWillMount() {
        this.toggleSearchModalHandler = this.toggleSearchModalHandler.bind(this);
        this.toggleUserOptionsHandler = this.toggleUserOptionsHandler.bind(this);
        this.showAuthPopupModalHandler = this.showAuthPopupModalHandler.bind(this);
    }

    toggleSearchModalHandler() {
        this.props.toggleSearchModal();
    }

    toggleUserOptionsHandler(){
        this.props.toggleUserOptions();
    }

    showAuthPopupModalHandler(form){
        this.props.showAuthPopupModal(form);
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <div className="DishesHeader" style={{position: "fixed", top: "0px", left: "0px", zIndex: "100000", width: "100%", display: "flex", flexDirection: "row", paddingBottom: "0px"}}>
                    <Logo history={this.props.history} location={this.props.location}/>
                    <Authentication 
                        toggleUserOptions={this.toggleUserOptionsHandler} 
                        history={this.props.history} 
                        toggleSearchModal={this.toggleSearchModalHandler}
                        showAuthPopupModal={this.showAuthPopupModalHandler}
                        searchPresent={this.props.searchPresent}
                    />
                </div>
            ) 
        } else {
            return (
                <div className="DishesHeader" style={{position: "fixed", top: "0px", left: "0px", zIndex: "100000", width: "100%", boxShadow:"1px 0px 8px #aaaaaa"}}>
                    <Grid container item xs={12} spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <Logo history={this.props.history} location={this.props.location}/>
                        <Authentication 
                            toggleUserOptions={this.toggleUserOptionsHandler} 
                            history={this.props.history} 
                            toggleSearchModal={this.toggleSearchModalHandler}
                            showAuthPopupModal={this.showAuthPopupModalHandler}
                            searchPresent={this.props.searchPresent}
                        />
                    </Grid>
                </div>
            )
        }
    }
}

// Website Logo
class Logo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
        if (this.state.isMobileScreen){
            return (
                <AddressBar
                    history={this.props.history}
                    location={this.props.location}
                />
            )
        } else {
            return (
                <div className="Logo">
                    <img style={{cursor: "pointer"}} onClick={() => {
                        if (this.props.location.pathname !== "/home"){
                            this.props.history.push("/home")
                        } else {
                            window.location.reload();
                        }
                    }} className="LogoImage" src={logo} alt="logo"/>
                </div>
            )
        }
    }
}

class AddressBar extends Component {

    constructor(props){
        super(props);
        this.state = { 
            address: "",
            pastAddress: "Add your Location",
            isAddressChanging: false,
            latitude: '',
            longitude: '',
            isMobileScreen: false
        };
        this.setAddressSuggestion = this.setAddressSuggestion.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (localStorage.getItem('user_address') !== null){
            this.setState({
                pastAddress : localStorage.getItem('user_address')
            });
        }
    }

    componentDidUpdate() {
        if (localStorage.getItem('user_address') !== null){
            if (this.state.pastAddress !== localStorage.getItem('user_address')){
                this.setState({
                    pastAddress : localStorage.getItem('user_address')
                });
            }
        }
    }

    setAddressSuggestion(position,  address){
        this.setState({
            latitude: position['lat'],
            longitude: position['lng'],
        })
        var latitude = this.state.latitude;
        var longitude = this.state.longitude;
        if (latitude !== "" && longitude !== ""){
            localStorage.setItem("user_latitude", this.state.latitude);
            localStorage.setItem("user_longitude", this.state.longitude);
            localStorage.setItem("location_present", "yes");
            localStorage.setItem("user_address", this.state.address);
            window.location.reload();
        }
    }

    handleChange = address => {
        this.setState({
            address: address
        })
    };

    toggleAddressChanging = () => {
        this.setState({
            isAddressChanging: !this.state.isAddressChanging,
            address: "",
            latitude: '',
            longitude: ''
        })
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    getAddressBarWidth(){
        if (this.state.isMobileScreen){
            if (localStorage.getItem("auth_token") === null){
                return "165px";
            }
            else{
                return "66vw";
            }
        } else {
            return "320px";
        }
    }

    getInputBarWidth(){
        let screen_width = window.innerWidth;
        if (this.state.isMobileScreen){
            if (localStorage.getItem("auth_token") === null){
                return screen_width - 200 + "px";
            } else {
                return screen_width - 170 + "px";
            }
        } else {
            return "320px";
        }
    }

    checkMobileLogo() {
        if (this.state.isMobileScreen){
            return (
                <img style={{cursor: "pointer"}} src={logo_mobile} alt="" width={30} height={30} onClick={() => {
                    if (this.props.location.pathname !== "/home"){
                        this.props.history.push("/home");
                    } else {
                        window.location.reload();
                    }
                }}/>
            )
        } else {
            return null;
        }
    }

    render() {
        if (this.state.isAddressChanging){
            return (
                <div style={{
                    display: 'inline-block',
                    marginTop: this.state.isMobileScreen ? "14px": "0px",
                    flex: 1,
                    marginLeft: this.state.isMobileScreen ? "10px": "0px"
                }}
                    className="AutoSuggestContainer"
                    onBlur={this.toggleAddressChanging}
                >
                    {this.checkMobileLogo()}
                </div>
            )
        } else {
            return (
                <div onClick={this.toggleAddressChanging} style={{display: "inline-block", marginLeft: this.state.isMobileScreen ? "10px": "20px", fontSize: "15px", cursor: "pointer", marginTop: this.state.isMobileScreen ? "14px": "0px", flex: 1}}>
                    {this.checkMobileLogo()}
                    <div style={{display: "inline-block", transform: this.state.isMobileScreen ? "translateY(-8px)" : "translateY(4px)", marginLeft: this.state.isMobileScreen ? "10px" : "0px", paddingBottom: this.state.isMobileScreen ? "10px" : "0px"}}>
                        <LocationOnIcon style={{transform: this.state.isMobileScreen ? "translateY(0px)" : "translateY(2px)", marginRight: "5px", color: "#EA5B2F"}} className="HomeAddressLocationIcon"/>
                        <span style={{display: "inline-block", width: this.getAddressBarWidth(), overflow: "hidden", whiteSpace: "nowrap"}} className="HomeAddressBar">{this.state.pastAddress}</span>
                    </div>
                </div>
            )
        }
    }
}


// Log In and Sign Up options
class Authentication extends Component {

    constructor(props){
        super(props);
        this.state = { 
            isMobileScreen: false,
        };
        this.redirectLandingPage = this.redirectLandingPage.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    checkType = (value) => {
        this.setState({
            typeDropdown: value
        });    
      };
      showDropdown = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
      };
      hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
      };
      chooseItem = (value) => {    
        if (this.state.labelItem !== value) {
          this.setState({
            labelItem: value      
          })
        }    
      };
      
      renderDataDropDown = (item, index) => {    
        const {value, label} = this.state.typeDropdown ? {value: index, label: item} : item;    
        return (
          <li
            key={index}
            value={value}
            onClick={() => this.chooseItem(label)}
          >
            <a href={"hello"}>{label}</a>
          </li> 
        )
      };

    componentWillMount() {
        this.toggleSearchModalHandler = this.toggleSearchModalHandler.bind(this);
    }

    toggleSearchModalHandler(e) {
        e.stopPropagation();
        this.props.toggleSearchModal();
    }

    redirectLandingPage(form){
        this.props.showAuthPopupModal(form);
    }

    showSearchBar(){
        if (this.props.searchPresent !== undefined){
            return (
                <span style={{display: "inline-block"}}>
                    <SearchIcon onClick={this.toggleSearchModalHandler} style={{color: "black", cursor: "pointer", marginRight: "5px", fontSize: "16px"}} className="SearchIcon"/>
                    <span onClick={this.toggleSearchModalHandler} style={{marginLeft: "3px", fontFamily: "Poppins-Medium", color: "#404040", fontSize: "16px", cursor: "pointer"}}>
                        Search
                    </span>
                </span>
            )
        } else {
            return null;
        }
    }

    showSearchBarMobile(){
        if (this.props.searchPresent !== undefined){
            return (
                <span style={{display: "inline-block", transform: "translateY(2px)", borderRadius: "50%", backgroundColor: "#DCDCDC", padding: "1px 7px 9px 7px"}}>
                    <SearchIcon onClick={(e) => this.toggleSearchModalHandler(e)} style={{top: "5px", color: "black", cursor: "pointer", transform: "translate(0px, 0px)"}} className="SearchIcon"/>
                </span> 
            )
        } else {
            return null;
        }
    }

    userAuth(){
        if (localStorage.getItem("auth_token") === null){
            if (this.state.isMobileScreen){
                return(
                    <div style={{paddingTop: "8px", display: "inline-block"}}>
                        {/* {this.showSearchBarMobile()} */}
                        <Button style={{marginLeft: "10px"}} onClick={() => this.redirectLandingPage("login")} variant="contained" id="dishes-signup-button-mobile">
                            Login
                        </Button>
                    </div>
                )
            } else {
                return(
                    <div className="OffersAuthBackground" style={{marginRight: "5vw"}}>
                        {this.showSearchBar()}
                        <AddressBar/>
                        <Button onClick={() => this.redirectLandingPage("login")} variant="contained" id="dishes-login-button">
                            Login
                        </Button>
                        <Button onClick={() => this.redirectLandingPage("register")} variant="contained" id="dishes-signup-button">
                            Sign Up
                        </Button>
                    </div>
                )
            }
        } else {
            if (this.state.isMobileScreen){
                return(
                    <div className="OffersAuthBackground" style={{display: "inline-block"}}>
                        {/* {this.showSearchBarMobile()} */}
                        {/* <span onClick={() => this.props.toggleUserOptions()} style={{display: "inline-block", marginLeft: "10px", cursor: "pointer", fontFamily: "Montserrat-Regular"}}>
                            <div style={{display: "inline-block", borderRadius: "50%", backgroundColor: "#ECECEC", width: "36px", height: "36px", position: "relative", top: "2px", zIndex: "1000"}}>
                                <img style={{position: "relative", top: "6px", left: "6px"}} width={24} height={24} src={profile} alt="logo"/>
                            </div>
                        </span> */}
                    </div>
                )
            } else {
                let user = JSON.parse(localStorage.getItem("user"));
                let username = user["name"];
                if (username === null || username === ""){
                    username = user["username"];
                }
                return(
                    <Grid className="OffersAuthBackground" style={{marginRight: "7.5vw", display: "block"}}>
                        {/* {this.showSearchBar()} */}
                        {/* <AddressBar/> */}
                        <span onClick={() => this.props.toggleUserOptions()} style={{display: "inline-block", marginLeft: "40px", cursor: "pointer", fontFamily: "Montserrat-Regular", paddingBottom: "10px"}}>
                            <div style={{display: "inline-block", borderRadius: "50%", backgroundColor: "#ECECEC", width: "36px", height: "36px", position: "relative", top: "2px", zIndex: "1000"}}>
                                <img style={{position: "relative", top: "6px", left: "6px"}} width={24} height={24} src={profile} alt="logo"/>
                            </div>
                            <span style={{marginLeft: "10px"}}>
                                {username} 
                            </span>
                        </span>
                    </Grid>
                )
            }
        }
    }

    render() {
        return this.userAuth();
    }
}

export default withRouter(Header);