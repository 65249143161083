import React, { Component } from 'react';
import Header from '../components/HomeNew/Header';
import Footer from '../components/Commons/Footer';
import { withRouter } from 'react-router';
import { setDeviceToken } from '../services/notification_handler';
import CONSTANTS from '../config/constants';
import axios from 'axios';
import WhyDaidish from '../components/HomeNew/WhyDaidish';
import ContentNew from '../components/HomeNew/ContentNew';
import BottomHeader from '../components/HomeNew/BottomHeader';
import CloseIcon from '@material-ui/icons/Close';

// Initial Landing Page
class HomeNew extends Component {
  
  constructor(){
    super();
    this.toggleAuthModal = this.toggleAuthModal.bind(this);
    this.setActiveForm = this.setActiveForm.bind(this);
    this.closeAuthModal = this.closeAuthModal.bind(this);
    this.displayCloseIcon = this.displayCloseIcon.bind(this);
    this.state = {
      authModalOpen: false,
      activeForm: "",
      isMobileScreen: false,
      goodFoodOnlyStep: 3,
      prev: window.scrollY
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("location_present") === "yes"){
      this.props.history.replace("/home");
    }
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    if (localStorage.getItem("auth_token") !== null && localStorage.getItem("auth_token") !== ""){
      let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
      let firebaseReq = { 
        'token': localStorage.getItem('device_token')
      }
      let firebaseHeaders = {
        'Authorization': 'Bearer ' + localStorage.getItem("auth_token")
      }
      axios.post(firebaseUrl, firebaseReq,  {
        headers: firebaseHeaders
      }).then((response) => {});
    }

    window.addEventListener("scroll", event => {
      // console.log("helloooo");
      // var h = document.documentElement, 
      //   b = document.body,
      //   st = 'scrollTop',
      //   sh = 'scrollHeight';
      // var percent = Math.round((h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);
      // console.log(percent);
      // console.log(this.state.prev);
      // console.log(window.scrollY);
      // if (percent <= 16){
      //   if (this.state.goodFoodOnlyStep === 4 && this.state.prev > window.scrollY) {
      //     this.setState({
      //       goodFoodOnlyStep: 1
      //     })
      //   }
      // } else if (percent >= 16 && percent <= 30 ){
      //   if (this.state.prev > window.scrollY) {
      //     this.setState({
      //       goodFoodOnlyStep: 4
      //     })
      //   } else {
      //     this.setState({
      //       goodFoodOnlyStep: 2
      //     })
      //   }
      // } else if (percent > 30 ){
      //   if (this.state.goodFoodOnlyStep === 2 && this.state.prev < window.scrollY) {
      //     this.setState({
      //       goodFoodOnlyStep: 3
      //     })
      //   }
      // }
      // this.setState({
      //   prev: window.scrollY
      // })
    });

    setDeviceToken();
    navigator.serviceWorker.addEventListener('message', event => {
      if ('data' in event.data.data){
        var orderDetails = JSON.parse(event.data.data.data);
        var orderEvent = event.data.data.event;
        if (orderEvent === 'new_order'){
          this.setState({
            newOrderModal: true,
            newOrderId: orderDetails.order_id
          })
        } 
      }
    });
  }

  componentDidUpdate() {   
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.authModalOpen && !this.state.isMobileScreen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  updateDimensions() {
    let screen_width = window.innerWidth;
    if(screen_width < 500) {
        this.setState({ isMobileScreen: true });
    } else {
        this.setState({ isMobileScreen: false });
    }
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 38: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleAuthModal() {
    this.setState({
      authModalOpen: !this.state.authModalOpen
    })
  }

  setActiveForm(form){
    if (this.state.activeForm !== form){
      this.setState({
        activeForm: form
      })
    }
  }

  closeAuthModal(){
    if (this.state.authModalOpen){
      this.setState({
        authModalOpen: false
      })
    }
  }

  displayCloseIcon(){
    if (this.state.authModalOpen === true){
        return (
            <div style={{position: 'absolute', right: '2vw', top: '3vw'}} className="CloseModalWrapper">
                <CloseIcon style={{color: "#807759"}} onClick={() => this.toggleAuthModal()} className="CloseModal" />
            </div> 
        )
    } else {
        return null;
    }
  }

  render() {
    return (
      <div style={this.state.authModalOpen ? {transform: 'translateY(-3px)'} : {}}>
        {this.displayCloseIcon()}
        <Header
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          authModalOpen={this.state.authModalOpen}
          activeForm={this.state.activeForm}
        />
        <WhyDaidish/>
        <ContentNew
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
          goodFoodOnlyStep={this.state.goodFoodOnlyStep}
        />
        <BottomHeader
          toggleAuthModal={this.toggleAuthModal}
          setActiveForm={this.setActiveForm}
        />
        <Footer/>
      </div>
    );
  }
}

export default withRouter(HomeNew);
