import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import { withRouter } from 'react-router';
// import ReactAudioPlayer from 'react-audio-player';
// import pop_sound from '../../assets/media/pop_sound.mp3';

class LogoutConfirm extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
    }

    closeModalHandler() {
        this.props.toggleLogoutModal();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        if(!this.props.showLogoutModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={{height: "unset", margin: "auto"}} onClick={(e) => this.handleModalClick(e)} className="SuccessModalContainer">
                   <div style={{width: "100%", textAlign: "center", fontFamily: "Poppins-Regular", padding: "30px 0px"}}>Are you sure you want to logout?</div>
                   <div style={{padding: "0px 0px 30px 0px", display: "flex", flexDirection: "row", width: "100%"}}>
                        <div style={{width: "50%", padding: "0px 1.5vw 0px 4.5vw"}}>
                            <div onClick={() => {
                                    localStorage.removeItem("auth_token");
                                    localStorage.removeItem("username");
                                    localStorage.removeItem("preferences_present");
                                    localStorage.removeItem("location_present");
                                    localStorage.removeItem("user_latitude");
                                    localStorage.removeItem("user_longitude");
                                    localStorage.removeItem("user");
                                    this.props.history.replace("/");
                                }} 
                                style={{width: "100%", border: "1px solid #707070", textAlign: "center", padding: "12px 0px", borderRadius: "20px", cursor: "pointer"}}>
                                Yes
                            </div>
                        </div>
                        <div style={{width: "50%", padding: "0px 4.5vw 0px 1.5vw"}}>
                            <div onClick={this.closeModalHandler} style={{width: "100%", backgroundColor: "#EA5B2F", border: "1px solid #EA5B2F", textAlign: "center", padding: "12px 0px", color: "white", borderRadius: "20px", cursor: "pointer"}}>
                                No
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LogoutConfirm);