import React, { Component } from 'react';
import why_daidish from '../../assets/images/why_daidish.webp';

// Tagline of the website after the header
class WhyDaidish extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    render() {
       if (this.state.isMobileScreen){
            return (
                <div className='WhyDaidishMobile' style={{backgroundColor: '#EA5B2F'}}>
                    <div style={{transform: 'unset', padding: '60px 20px 0px 20px'}} className="WhyDaidishContent" >
                        <h2 style={{fontSize: '36px'}} className="WhyDaidishHeader">
                            Why <span style={{color: "#FCD844"}}>DaiDish?</span>
                        </h2>
                        <div style={{fontSize: '18px', width: '80vw', paddingLeft: '0px'}} className="WhyDaidishDetails">
                            When you crave the best food in Bangalore, we have the answer to, <span style={{fontStyle: "italic"}}>'So, what's the best dish here?'</span>
                        </div>
                    </div>
                    <div style={{paddingBottom: '0px', paddingTop: '0px'}} className='WhyDaidishImageContainer'>
                        <img style={{transform: 'translate(-24vw, 45px)', width: '100vw', paddingTop: '0px'}} className='WhyDaidishImage' src={why_daidish} alt='Why Daidish'/>
                    </div>
                </div>
            )
       } else {
            return (
                <div className="WhyDaidish">
                    <div className='WhyDaidishImageContainer'>
                        <img className='WhyDaidishImage' src={why_daidish} alt='Why Daidish'/>
                    </div>
                    <div className="WhyDaidishContent" >
                        <h2 className="WhyDaidishHeader">
                            Why <span style={{color: "#FCD844"}}>DaiDish?</span>
                        </h2>
                        <div className="WhyDaidishDetails">
                            When you crave the best food in Bangalore, we have the answer to, <span style={{fontStyle: "italic"}}>'So, what's the best dish here?'</span>
                        </div>
                    </div>
                </div>
            )
       }
    }
}

export default WhyDaidish;