import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import { withRouter } from 'react-router';
import { AiOutlineCloseCircle } from "react-icons/ai";
import DaidishLogo from "../../assets/images/logo_mobile.png"
import TextField from '@material-ui/core/TextField';
import { FiUpload } from "react-icons/fi"
import { BsFillFileEarmarkWordFill, BsFileEarmarkPdfFill } from "react-icons/bs"
import { Grid } from '@material-ui/core';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import ButtonLoading from '../../assets/images/btnLoading.gif';


class ApplicationWindow extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.state = {
            showUploadForm: false,
            resumeButtonText: "Upload Resume",
            resumeFile: "",
            resumeErrorMessage: "",

            candidateName: "",
            candidateNameError:"",

            emailId: "",
            emailIdError:"",

            phoneNumber: "",
            phoneNumberError:"",

            socialProfile: "",

            submitError: false,
            backendResponse: "",

            submitBtnLoading: false,
            showButtons: true
        }
    }

    closeModalHandler() {
        this.setState({
            showUploadForm: false,
            resumeButtonText: "Upload Resume",
            resumeFile: "",
            resumeErrorMessage: "",
            candidateName: "",
            candidateNameError:"",
            emailId: "",
            emailIdError:"",
            phoneNumber: "",
            phoneNumberError:"",
            socialProfile: "",
            submitError: false,
            showButtons: true
        })
        this.props.closeApplicationWindow();
    }

    handleModalClick(e) {
        e.stopPropagation();
    }  

    handleResumeFile(){
        console.log(this.state.resumeFile)
        if(this.state.resumeFile !== undefined && this.state.resumeFile !== ""){
            var fileSize = this.state.resumeFile.size // less than 5000000(5Mb)
            var fileName = this.state.resumeFile.name //.trim().includes('.pdf'), DOC and DOCX
            
            if((fileName.trim().includes('.pdf') || fileName.trim().includes('.doc') || fileName.trim().includes('.docx')) 
                && fileSize < 5000000){
                this.setState({
                    resumeErrorMessage: "",
                    resumeButtonText: fileName
                })
            }
            else{
                if(fileSize >= 5000000){
                    this.setState({
                        resumeErrorMessage: "File size must be less than 5Mb.",
                        resumeButtonText: "Upload Resume",
                        resumeFile: "",
                        submitError: true
                    })
                }
                else{
                    this.setState({
                        resumeErrorMessage: "Please select a .pdf/.doc/.docx file",
                        resumeButtonText: "Upload Resume",
                        resumeFile: "",
                        submitError: true
                    })
                }
            }
        }
        else{
            this.setState({
                resumeErrorMessage: "",
                resumeButtonText: "Upload Resume"
            })
        }
    }

    submitDetails(){        
        var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var phoneValidator = /^[0]?[6789]\d{9}$/;
        var nameValidator = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

        var errorTag = 0
        if (!nameValidator.exec(this.state.candidateName)){
            this.setState({
                candidateNameError: "Please enter a valid Name",
            }) 
            errorTag = errorTag + 1
        }
        else{
            this.setState({
                candidateNameError: "",
            })
        }

        if (!emailValidator.exec(this.state.emailId)){
            this.setState({
                emailIdError: "Please enter a valid email",
            }) 
            errorTag = errorTag + 1
        }
        else{
            this.setState({
                emailIdError: "",
            })
        }
        
        if (!phoneValidator.exec(this.state.phoneNumber)){
            this.setState({
                phoneNumberError: "Please enter a Phone Number",
            }) 
            errorTag = errorTag + 1
        }
        else{
            this.setState({
                phoneNumberError: "",
            })
        }

        if(this.state.resumeFile === undefined || this.state.resumeFile === ""){
            this.setState({
                resumeErrorMessage: "Please select a .pdf/.doc/.docx file to upload.",
            })
            errorTag = errorTag + 1
        }

        if(errorTag === 0){
            this.setState({submitBtnLoading: true})
            let url = CONSTANTS.hostURL + "api/client/careers/apply/";
        
            let form_data = new FormData();
            form_data.append('name', this.state.candidateName);
            form_data.append('email', this.state.emailId);
            form_data.append('phone_number', this.state.phoneNumber);
            form_data.append('position_id', this.props.selectedCareer.id);
            if(this.state.socialProfile !== "")
                form_data.append('social_profile', this.state.socialProfile);
            form_data.append('resume', this.state.resumeFile, this.state.resumeFile.name);

            axios.post(url, form_data).then((response) => {
            if (response.data.status === 1){
                console.log(response.data)
                this.setState({backendResponse : "Details Submitted Successfully! We will get in touch with you soon. Please close the pop up.", submitBtnLoading: false, showButtons: false})
            } else {
                console.log(response.data.errors.message)
                this.setState({backendResponse : response.data.errors.message, submitBtnLoading: false})
            }
            }).catch((error) => {
                console.log("Server error! Please try after sometime.")
                this.setState({backendResponse : "Server error! Please try after sometime.", submitBtnLoading: false})
            });
        }
    }

    render() {
        if(!this.props.showApplicationWindow){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div style={this.props.isMobileScreen ? {height: "unset", margin: "auto", width:"90%", background:"#FFFBE8", overflow:"hidden"} : {height: "90vh", margin: "auto", maxWidth: "70%", background:"#FFFBE8", overflow:"hidden"}} onClick={(e) => this.handleModalClick(e)} className="ddOrderDetailsModalContainer">
                    {!this.props.isMobileScreen && <img src={DaidishLogo} alt="logo" style={{position:"absolute", left:"-22%", top:"-10%", height:"120%", opacity:"0.4"}} />}
                    <AiOutlineCloseCircle size="1.5em" style={{position:"absolute", color:"#626262", right:"10px", top:"10px", zIndex:"1000", cursor:"pointer"}} onClick={this.closeModalHandler}/>
                    <div style={this.props.isMobileScreen ? {padding: "20px 15px", zIndex:"10"} : {padding: "20px", zIndex:"10"}}>
                        <div className="ddCareersHeader" style={{marginTop:"1vh"}}>
                            <div style={{fontFamily:"Quicksand-Bold", fontSize:"50px"}}>Join team <span style={{color:"#E4592E"}}>foodies!</span></div>
                            <div style={{fontFamily:"Poppins-Regular", fontSize:"18px"}}>As our <span style={{color:"#E4592E"}}>{this.props.selectedCareer.title}</span></div>

                            {!this.state.showUploadForm ? 
                            <>
                                <div style={{fontSize:"12px", height:"250px", overflow:"hidden", marginTop:"10px", width:"90%"}}>
                                    <div className="ddNoScrollBar" style={{overflowY:"auto", height:"100%"}}>
                                        {this.props.selectedCareer.description}
                                    </div>
                                </div>
                                <div className="ddCarrerApplyNowBtn" style={{bottom:"30px"}} onClick={() => this.setState({showUploadForm: true})}>Apply Now</div>
                            </>
                            :                                
                                <>
                                    <Grid container spacing={2} style={{width:"80%"}}>
                                        <Grid item xs={6} style={{flexDirection: "column", display: "flex", flexWrap: "wrap"}}>
                                            <TextField
                                                required
                                                style={{marginTop:"5px"}} 
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: !this.state.showButtons }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color: this.state.candidateNameError ? "#E4592E" : "#83706A" } }}
                                                label={this.state.candidateNameError === "" ? "Name" : this.state.candidateNameError}
                                                value={this.state.candidateName}
                                                onChange={(e) => this.setState({candidateName : e.target.value})} />
                                                
                                            <TextField 
                                                required
                                                style={{marginTop:"5px"}}
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: !this.state.showButtons }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color: this.state.emailIdError ? "#E4592E" : "#83706A" } }}
                                                label={this.state.emailIdError === "" ? "Email ID" : this.state.emailIdError}
                                                value={this.state.emailId}
                                                onChange={(e) => this.setState({emailId : e.target.value})} />
                                            
                                            <TextField
                                                required
                                                style={{marginTop:"5px"}}
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: !this.state.showButtons }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color: this.state.phoneNumberError ? "#E4592E" : "#83706A" } }}
                                                label={this.state.phoneNumberError === "" ? "Phone Number" : this.state.phoneNumberError}
                                                type="number"
                                                value={this.state.phoneNumber}
                                                onChange={(e) => this.setState({phoneNumber : e.target.value.slice(0, 10)})} />
                                            <TextField 
                                                style={{marginTop:"5px"}}
                                                label="Social Profile (LinkedIn)"
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: !this.state.showButtons }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color:"#83706A" } }}
                                                value={this.state.socialProfile}
                                                onChange={(e) => this.setState({socialProfile : e.target.value})} />

                                            <label for="uploadResume"style={{ fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px", borderBottom:"1px solid rgba(0, 0, 0, 0.42)", padding:"30px 0px 4px 0px", cursor:"pointer" }}>
                                                { (this.state.resumeFile !== undefined && this.state.resumeFile !== "" ) ? 
                                                    this.state.resumeFile.name.trim().includes('.pdf') 
                                                    ? 
                                                        <BsFileEarmarkPdfFill className="ddInfoIcon"/> 
                                                    : (this.state.resumeFile.name.trim().includes('.doc') || this.state.resumeFile.name.trim().includes('.docx')) 
                                                        ?
                                                        <BsFillFileEarmarkWordFill className="ddInfoIcon"/>
                                                        :
                                                        <FiUpload className="ddInfoIcon"/> 
                                                :
                                                    <FiUpload className="ddInfoIcon"/> 
                                                }
                                                {this.state.resumeButtonText}
                                            </label>
                                            <input type="file" id="uploadResume" style={{display:"none"}} disabled={!this.state.showButtons} onChange={(event) => this.setState({resumeFile: event.target.files[0]}, () => this.handleResumeFile())}/>
                                            <div style={{fontSize:"12px", fontFamily:"Poppins-Regular", color:"#E4592E", fontStyle:"italic"}}>
                                                {this.state.resumeErrorMessage}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} style={{flexDirection: "column", display: "flex", flexWrap: "wrap"}}>
                                            <TextField 
                                                style={{marginTop:"5px"}}
                                                label="Job Role" 
                                                defaultValue={this.props.selectedCareer.title} 
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: true }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color:"#83706A" } }} />
                                            <TextField 
                                                style={{marginTop:"5px"}}
                                                label="Job Category" 
                                                defaultValue={this.props.selectedCareer.domain_name}  
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: true }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color:"#83706A" } }} />
                                            <TextField
                                                style={{marginTop:"5px"}}
                                                label="Work Hours" 
                                                defaultValue={this.props.selectedCareer.role_type_name}  
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: true }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color:"#83706A" } }} />
                                            <TextField 
                                                style={{marginTop:"5px"}}
                                                label="Job Location" 
                                                defaultValue={this.props.selectedCareer.location_name} 
                                                InputProps={{ style: { fontFamily:"Poppins-Medium", color:"#2B2624", fontSize:"13px" }, readOnly: true }}
                                                InputLabelProps={{ style: { fontFamily:"Poppins-Regular", color:"#83706A" } }} />
                                        </Grid>
                                    </Grid>
                                    <div style={{fontSize:"15px", fontFamily:"Poppins-Bold", color: this.state.backendResponse.includes("Successfully") ? "#329C4E" : "#E4592E", fontStyle:"italic", marginTop:"30px"}}>
                                        {this.state.backendResponse}
                                    </div>
                                    {this.state.showButtons &&
                                        <div style={{position:"absolute", bottom:"30px"}}>
                                        <span className="ddCarrerSubmitBackBtn" onClick={() => this.setState({showUploadForm: false})}>Back</span>
                                        {!this.state.submitBtnLoading ?
                                            <span className="ddCarrerSubmitBackBtn" onClick={() => this.submitDetails()}>Submit</span>
                                        :
                                            <img src={ButtonLoading} alt="Complete Order & Pay" style={this.state.isMobileScreen ? {width:"70px", position:"relative", top:"6px", left:"10px", height:"20px"} : {width:"70px", position:"relative", top:"7px", left:"20px", height:"20px", margin:"0px 20px"}}/>
                                        }
                                    </div>}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ApplicationWindow);