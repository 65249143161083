import axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../../assets/styles/Home.css';
import CONSTANTS from '../../config/constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Geocode from "react-geocode";
import { Link } from 'react-router-dom';
import { setDeviceToken } from '../../services/notification_handler';
import messaging from '../../firebase_messaging';

Geocode.setApiKey('AIzaSyDrUWlb7Yw_NQZgfL8s4-KNi9vL6uYwTJ4');
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

// Detailed Content in Landing Page after Site Description
class Authentication extends Component {

    constructor(props) {
        super(props);
        this.formDecider = this.formDecider.bind(this);
        this.loginForm = this.loginForm.bind(this);
        this.registerForm = this.registerForm.bind(this);
        this.toggleAuthModalHander = this.toggleAuthModalHander.bind(this);
        this.passwordToggleIcon = this.passwordToggleIcon.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.formTypeDecider = this.formTypeDecider.bind(this);
        this.handleOTP = this.handleOTP.bind(this);
        this.showLoginError = this.showLoginError.bind(this);
        this.hitLoginAPI = this.hitLoginAPI.bind(this);
        this.hitRegisterAPI = this.hitRegisterAPI.bind(this);
        this.showRegisterError = this.showRegisterError.bind(this);
        this.state = {
            registerPassword: "hidden",
            registerConfirmPassword: "hidden",
            loginAuthenticator: "",
            isLoginAuthenticatorError: false,
            loginAuthenticatorError: "",
            isLoggingIn: false,
            loginSuccessMessage: "",
            loginErrorMessage: "",
            registerName: "",
            registerEmail: "",
            registerMobile: "",
            registerPasswordValue: "",
            isRegisterNameError: false,
            isRegisterEmailError: false,
            isRegisterMobileError: false, 
            isRegisterPasswordError: false,
            registerNameError: "",
            registerEmailError: "",
            registerMobileError: "", 
            registerPasswordError: "",
            isRegistering: false,
            registerErrorMessage: "",
            registerSuccessMessage: "",
            otpFormPresent: false,
            authToken: "",
            otpValue: "",
            otpError: "",
            isOTPError: false,
            isSubmittingOTP: false,
            userDetails: "",
            preferencesPresent: false,
            latitude: "",
            longitude: "",
            resendOTPSuccessMessage: "",
            isResendingOTP: false,
            otpSession: '' 
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        setDeviceToken();
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    toggleAuthModalHander() {
        this.setState({
            registerPassword: "hidden",
            registerConfirmPassword: "hidden",
            loginAuthenticator: "",
            isLoginAuthenticatorError: false,
            loginAuthenticatorError: "",
            isLoggingIn: false,
            loginSuccessMessage: "",
            loginErrorMessage: "",
            registerName: "",
            registerEmail: "",
            registerMobile: "",
            registerPasswordValue: "",
            isRegisterNameError: false,
            isRegisterEmailError: false,
            isRegisterMobileError: false, 
            isRegisterPasswordError: false,
            registerNameError: "",
            registerEmailError: "",
            registerMobileError: "", 
            registerPasswordError: "",
            isRegistering: false,
            registerErrorMessage: "",
            registerSuccessMessage: "",
            otpFormPresent: false
        })
        this.props.toggleAuthModal();
    }

    setActiveFormHandler(form) {
        this.setState({
            registerPassword: "hidden",
            registerConfirmPassword: "hidden",
            loginAuthenticator: "",
            isLoginAuthenticatorError: false,
            loginAuthenticatorError: "",
            isLoggingIn: false,
            loginSuccessMessage: "",
            loginErrorMessage: "",
            registerName: "",
            registerEmail: "",
            registerMobile: "",
            registerPasswordValue: "",
            isRegisterNameError: false,
            isRegisterEmailError: false,
            isRegisterMobileError: false, 
            isRegisterPasswordError: false,
            registerNameError: "",
            registerEmailError: "",
            registerMobileError: "", 
            registerPasswordError: "",
            isRegistering: false,
            registerErrorMessage: "",
            registerSuccessMessage: ""
        })
        this.props.setActiveForm(form);
    }

    phoneNumberValidator(value){
        let validator = /^[0]?[6789]\d{9}$/;
        return validator.exec(value);
    }

    handleLogin(){
        this.setState({
            isLoginAuthenticatorError: false,
            loginAuthenticatorError: "",
            isLoggingIn: true,
            loginErrorMessage: "",
        })
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitLoginAPI, this.showLoginError,  { enableHighAccuracy: false, timeout: 5000, maximumAge: 0,});
        } else {
            this.setState({
                loginErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser",
                isLoggingIn: false
            });
        }
    }

    showLoginError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                loginErrorMessage: "Please enable the location access to proceed further",
                isLoggingIn: false
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                loginErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
                isLoggingIn: false
            });
            break;
          case error.TIMEOUT:
            this.setState({
                loginErrorMessage: "Unable to access location information. Make sure your internet connection is okay",
                isLoggingIn: false
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                loginErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
                isLoggingIn: false
            });
            break;
          default:
            this.setState({
                loginErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
                isLoggingIn: false
            });
            break;
        }
    }

    hitLoginAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        })
        var authenticator = this.state.loginAuthenticator;
        let url = CONSTANTS.hostURL + "api/accounts/v2/login/generate-otp/";
        var isError = false;
        var loginType = "";
        if (!this.phoneNumberValidator(authenticator)){
            let digitValidator = /^[0-9]*$/;
            if (digitValidator.exec(authenticator)){
                this.setState({
                    loginAuthenticatorError: "Enter a valid mobile number",
                    isLoginAuthenticatorError: true,
                })
                isError = true;
            } else {
                var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!emailValidator.exec(authenticator)){
                    this.setState({
                        loginAuthenticatorError: "Please enter a valid email",
                        isLoginAuthenticatorError: true
                    }) 
                    isError = true;
                }
            }
            loginType = "email";
        } else {
            loginType = "phone_number";
        }
        if (authenticator === ""){
            this.setState({
                loginAuthenticatorError: "Email or Mobile Number can't be empty",
                isLoginAuthenticatorError: true
            })
            isError = true;
        }
        if (isError){
            this.setState({
                isLoggingIn: false
            })
            return;
        }
        
        var requestBody = {
            type : loginType,
            authenticator: authenticator
        }
        axios.post(url, requestBody).then(res => {
            var data = res.data;
            if (data.status === -1){
                if (data.error_type === "authenticator"){
                    this.setState({
                        loginAuthenticatorError: data.message,
                        isLoginAuthenticatorError: true,
                        isLoggingIn: false
                    })
                } else {
                    this.setState({
                        loginErrorMessage: data.message,
                        isLoggingIn: false
                    })
                }
            } else if (data.status === 1) {
                this.setState({
                    isLoggingIn: false,
                    otpFormPresent: true,
                    otpSession: data.data.session_id
                })
            } else {
                this.setState({
                    loginErrorMessage: "Network or Server Error",
                    isLoggingIn: false
                }) 
            }
        }).catch((err) => this.setState({
            loginErrorMessage: "Network or Server Error",
            isLoggingIn: false
        }));
    }

    resendOTP(){
        this.setState({
            otpValue: '',
            isResendingOTP : true,
            resendOTPSuccessMessage: ''
        })
        
        var authenticator = this.props.activeForm !== "login" ?  this.state.registerMobile : this.state.loginAuthenticator;
        var url = CONSTANTS.hostURL + "api/accounts/v2/login/generate-otp/";

        var loginType = "";
        if (!this.phoneNumberValidator(authenticator)){
            let digitValidator = /^[0-9]*$/;
            if (digitValidator.exec(authenticator)){
                this.setState({
                    loginAuthenticatorError: "Enter a valid mobile number",
                    isLoginAuthenticatorError: true,
                })
            } else {
                var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!emailValidator.exec(authenticator)){
                    this.setState({
                        loginAuthenticatorError: "Please enter a valid email",
                        isLoginAuthenticatorError: true
                    }) 
                }
            }
            loginType = "email";
        } else {
            loginType = "phone_number";
        }
            
        var requestBody = {
            type : loginType,
            authenticator: authenticator
        }
        axios.post(url, requestBody).then(res => {
            let data = res.data;
            this.setState({
                isResendingOTP : false,
            })
            if (data.status === 1){
                this.setState({
                    resendOTPSuccessMessage: 'OTP has been resent successfully.',
                    isResendingOTP: false,
                    otpSession: data.data.session_id
                })
            } else if (data.status === -1) {
                this.setState({
                    resendOTPSuccessMessage: data.message
                })
            } else {
                this.setState({
                    resendOTPSuccessMessage: 'Network or server error. Please connect after some time.'
                })
            }
        }).catch((error) => this.setState({
            isResendingOTP : false,
            resendOTPSuccessMessage: 'Network or server error. Please connect after some time.'
        }));
    }

    handleOTP(){
        if (!this.state.isResendingOTP){
            this.setState({
                otpError: "",
                isSubmittingOTP: true
            })
            if (this.state.otpValue.trim() === ""){
                this.setState({
                    otpError: "OTP field cannot be blank.",
                    isSubmittingOTP: false
                })
            } else {
                var otp = parseInt(this.state.otpValue.trim());
                if (otp < 100000 || otp > 999999){
                    this.setState({
                        otpError: "Please enter a valid OTP",
                        isSubmittingOTP: false
                    })
                } else {
                    var url = CONSTANTS.hostURL + "api/accounts/v2/login/";
                    var headers = {
                        'Authorization' : 'Bearer ' + this.state.authToken 
                    }
                    var req = {
                        otp: otp,
                        otp_session: this.state.otpSession,
                        authenticator: this.props.activeForm !== "login" ? this.state.registerMobile : this.state.loginAuthenticator,
                        latitude: this.state.latitude,
                        longitude: this.state.longitude
                    }
                    axios.post(url, req,  {
                        headers: headers
                    }).then((response) => {
                        var data = response.data;
                        if (data.status === 1){
                            var deviceToken = localStorage.getItem('device_token');
                            if (messaging !== null && deviceToken !== null && deviceToken !== ''){
                                let firebaseUrl = CONSTANTS.hostURL + "api/accounts/fcm/register/user/";
                                let firebaseReq = { 
                                    'token': deviceToken
                                }
                                let firebaseHeaders = {
                                    'Authorization': 'Bearer ' + response.data.data.access
                                }
                                axios.post(firebaseUrl, firebaseReq,  {
                                    headers: firebaseHeaders
                                }).then((response) => {
                                    let toProceed = true;
                                    if (response.data.status === -1 && response.data.errors.code !== 'DUPLICATE'){  
                                        toProceed = false;
                                    }
                                    if (toProceed === true){
                                        var preferencesPresent;
                                        localStorage.setItem("user", JSON.stringify(data.data.user));
                                        if (data.data.user.preferences.length !== 0){
                                            preferencesPresent = true;
                                        } else {
                                            preferencesPresent = false;
                                        }
                                        localStorage.setItem("auth_token", data.data.access);
                                        localStorage.setItem("refresh_token", data.data.refresh);
                                        localStorage.setItem("preferences_present", preferencesPresent);
                                        localStorage.setItem("user_latitude", this.state.latitude);
                                        localStorage.setItem("user_longitude", this.state.longitude);
                                        localStorage.setItem("location_present", "yes");
                                        Geocode.fromLatLng(this.state.latitude, this.state.longitude).then(
                                            (response) => {
                                            const address = response.results[0].formatted_address;
                                            localStorage.setItem("user_address", address);
                                            this.props.history.push('/home');
                                            }
                                        );
                                        this.props.history.push('/home');
                                    } else {
                                        this.setState({
                                            otpError: response.data.errors.message,
                                            isSubmittingOTP: false
                                        })
                                    }
                                }).catch((error) => this.setState({
                                    otpError: error,
                                    isSubmittingOTP: false
                                }));
                            } else {
                                var preferencesPresent;
                                localStorage.setItem("user", JSON.stringify(data.data.user));
                                if (data.data.user.preferences.length !== 0){
                                    preferencesPresent = true;
                                } else {
                                    preferencesPresent = false;
                                }
                                localStorage.setItem("auth_token", data.data.access);
                                localStorage.setItem("refresh_token", data.data.refresh);
                                localStorage.setItem("preferences_present", preferencesPresent);
                                localStorage.setItem("user_latitude", this.state.latitude);
                                localStorage.setItem("user_longitude", this.state.longitude);
                                localStorage.setItem("location_present", "yes");
                                Geocode.fromLatLng(this.state.latitude, this.state.longitude).then(
                                    (response) => {
                                    const address = response.results[0].formatted_address;
                                    localStorage.setItem("user_address", address);
                                    this.props.history.push('/home');
                                    }
                                );
                                this.props.history.push('/home'); 
                            }
                        } else if (data.status === -1) {
                            this.setState({
                                otpError: data.message,
                                isSubmittingOTP: false
                            })
                        } else {
                            this.setState({
                                otpError: "Network or Server Error",
                                isSubmittingOTP: false
                            })
                        }
                    }).catch((error) => {
                        this.setState({
                            otpError: error.response.data.errors.otp,
                            isSubmittingOTP: false
                        })
                    });
                }
            }
        }
    }

    handleRegister(){
        this.setState({
            isRegisterNameError: false,
            isRegisterEmailError: false,
            isRegisterMobileError: false, 
            isRegisterPasswordError: false,
            registerNameError: "",
            registerEmailError: "",
            registerMobileError: "", 
            registerPasswordError: "",
            registerErrorMessage: "",
            isRegistering: true
        })
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.hitRegisterAPI, this.showRegisterError,  { enableHighAccuracy: false, timeout: 5000, maximumAge: 0,});
        } else {
            this.setState({
                loginErrorMessage: "The current browser can't detect your location. Make sure you are using the latest version of your browser",
                isLoggingIn: false
            });
        }
    }

    showRegisterError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            this.setState({
                registerErrorMessage: "Please enable the location access to proceed further",
                isRegistering: false
            });
            break;
          case error.POSITION_UNAVAILABLE:
            this.setState({
                registerErrorMessage: "Unable to access location information. Make sure you have granted location permission for this site",
                isRegistering: false
            });
            break;
          case error.TIMEOUT:
            this.setState({
                registerErrorMessage: "Unable to access location information. Make sure your internet connection is okay",
                isRegistering: false
            });
            break;
          case error.UNKNOWN_ERROR:
            this.setState({
                registerErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
                isRegistering: false
            });
            break;
          default:
            this.setState({
                registerErrorMessage: "Unable to access location information. Make sure your internet connection is okay and you have granted location permission for this site",
                isRegistering: false
            });
            break;
        }
    }

    hitRegisterAPI(position){
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        })
        var name = this.state.registerName;
        var email = this.state.registerEmail;
        var mobile = this.state.registerMobile;
        var password = this.state.registerPasswordValue;
        let url = CONSTANTS.hostURL + "api/accounts/register/";
        var isError = false;
        if (name === ""){
            this.setState({
                registerNameError: "Name can't be empty",
                isRegisterNameError: true
            })
            isError = true;
        } else {
            let digitValidator = /^[0-9]*$/;
            if (digitValidator.exec(name)){
                this.setState({
                    registerNameError: "Please enter a valid name",
                    isRegisterNameError: true
                })
                isError = true;
            }
        }
        if (email === ""){
            this.setState({
                registerEmailError: "Email can't be empty",
                isRegisterEmailError: true
            })
            isError = true;
        } else {
            var emailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!emailValidator.exec(email)){
                this.setState({
                    registerEmailError: "Please enter a valid email",
                    isRegisterEmailError: true
                }) 
            }
        }
        if (mobile === ""){
            this.setState({
                registerMobileError: "Mobile Number can't be empty",
                isRegisterMobileError: true
            })
            isError = true;
        } else {
            if (!this.phoneNumberValidator(mobile)){
                this.setState({
                    registerMobileError: "Please enter a valid mobile number",
                    isRegisterMobileError: true
                })
                isError = true
            }
        }
        if (password === ""){
            this.setState({
                registerPasswordError: "Password can't be empty",
                isRegisterPasswordError: true
            })
            isError = true;
        }

        if (isError){
            this.setState({
                isRegistering: false
            })
            return;
        }
        var requestBody = {
            name : name,
            email: email,
            phone_number: mobile,
            password: password,
            password2: password,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
        axios.post(url, requestBody).then(res => {
            var data = res.data;
            if (data.status === -1){
                // if(data.hasOwnProperty("name")){
                //     var nameError = data.name[0]
                //     if (nameError.startsWith("account")){
                //         nameError = "An " + nameError;
                //     }
                //     this.setState({
                //         registerNameError: nameError,
                //         isRegisterNameError: true,
                //         isRegistering: false
                //     })
                // }
                // if(data.hasOwnProperty("email")){
                //     var emailError = data.email[0];
                //     if (emailError.startsWith("account")){
                //         emailError = "An " + emailError;
                //     }
                //     this.setState({
                //         registerEmailError: emailError,
                //         isRegisterEmailError: true,
                //         isRegistering: false
                //     })
                // }
                // if(data.hasOwnProperty("phone_number")){
                //     var mobileError = data.phone_number[0];
                //     if (mobileError.startsWith("account")){
                //         mobileError = "An " + mobileError;
                //     }
                //     this.setState({
                //         registerMobileError: mobileError,
                //         isRegisterMobileError: true,
                //         isRegistering: false
                //     })
                // }
                // if(data.hasOwnProperty("password")){
                //     this.setState({
                //         registerErrorMessage: data.password[0],
                //         isRegisterPasswordError: true,
                //         isRegistering: false
                //     })
                // }
                // if(data.hasOwnProperty("message")){
                //     this.setState({
                //         registerErrorMessage: data.message,
                //         isRegistering: false
                //     })
                // }
                this.setState({
                    registerErrorMessage: data.errors.message,
                    isRegistering: false
                })
            } else if (data.status === 1) {
                this.setState({
                    isRegistering: false,
                    registerName: "",
                    //registerEmail: "",
                    //registerMobile: "",
                    registerPasswordValue: "",
                    authToken: data.token,
                    otpFormPresent: true,
                    otpSession: data.data.session_id
                })
            } else {
                this.setState({
                    registerErrorMessage: "Network or Server Error",
                    isRegistering: false
                }) 
            }
        }).catch((err) => this.setState({
            registerErrorMessage: "Network or Server Error",
            isRegistering: false
        }));
    }

    formDecider(){
        if(this.props.activeForm === "login"){
            return this.loginForm();
        } else {
            return this.registerForm();
        }
    }

    passwordToggleIcon(type){
        if (type === "register"){
            if(this.state.registerPassword === "hidden"){
                return(
                    <div style={{transform: "translateY(-5px)"}}>
                        <VisibilityIcon
                            fontSize="small"
                            style={{color: "black"}}
                        />
                    </div>
                )
            } else {
                return(
                    <div style={{transform: "translateY(-5px)"}}>
                        <VisibilityOffIcon
                            fontSize="small"
                            style={{color: "black"}}
                        />
                    </div>
                )
            }
        } else {
            if(this.state.registerConfirmPassword === "hidden"){
                return(
                    <VisibilityIcon
                        fontSize="small"
                        style={{color: "white"}}
                    />
                )
            } else {
                return(
                    <VisibilityOffIcon
                        fontSize="small"
                        style={{color: "white"}}
                    />
                )
            }
        }
    }

    togglePasswordVisibility(type){
        if (type === "register"){
            if (this.state.registerPassword === "hidden"){
                this.setState({
                    registerPassword: "visible"
                });
            } else {
                this.setState({
                    registerPassword: "hidden"
                });
            }
        } else {
            if (this.state.registerConfirmPassword === "hidden"){
                this.setState({
                    registerConfirmPassword: "visible"
                });
            } else {
                this.setState({
                    registerConfirmPassword: "hidden"
                });
            }
        }
    }

    loginForm(){
        var loginAuthenticatorStyle = {};
        var loginAuthenticatorErrorStyle = {};
        if (this.state.isMobileScreen){
            loginAuthenticatorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            loginAuthenticatorErrorStyle = {width: '70vw'};
        }
        if (this.state.isLoginAuthenticatorError){
            loginAuthenticatorStyle = {border: "1px solid red", backgroundColor: '#FFFFFF'};
            loginAuthenticatorErrorStyle = {display: "block"};
            if (this.state.isMobileScreen){
                loginAuthenticatorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid red'};
                loginAuthenticatorErrorStyle = {width: '70vw', display: "block"};
            }
        } else {
            loginAuthenticatorStyle = {backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            loginAuthenticatorErrorStyle = {display: "none"};
            if (this.state.isMobileScreen){
                loginAuthenticatorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
                loginAuthenticatorErrorStyle = {width: '70vw', display: "none"};
            }
        }
        return(
            <div style={{marginTop: "0", width: this.state.isMobileScreen ? '70vw' : '30vw', padding: this.state.isMobileScreen ? '0px' : '20px'}} className="AuthForm">
                <div style={{padding: "0px", margin: "0px"}}>
                    <div className="AuthFormFieldContainer">
                        {/* <input id="loginAuthenticator" style={loginAuthenticatorStyle} value={this.state.loginAuthenticator} onKeyDown={(event) => this.handleKeyPress(event, "loginAuthenticator", "login", "first", "", "")} onChange={(event) => this.setState({loginAuthenticator: event.target.value})} className="AuthFormField" type="text" placeholder="Enter Mobile Number or Email..."></input> */}
                        <input id="loginAuthenticator" 
                            style={loginAuthenticatorStyle} 
                            value={this.state.loginAuthenticator} 
                            // onKeyDown={(event) => this.handleKeyPress(event, "loginAuthenticator", "login", "first", "", "")} 
                            onChange={(event) => {
                                let digitValidator = /^[0-9]*$/;
                                if (digitValidator.exec(event.target.value)){
                                    this.setState({loginAuthenticator: event.target.value.slice(0, 10)})
                                }
                            }} className="AuthFormField" 
                            placeholder="Enter Mobile Number"/>
                    </div>
                    <span style={loginAuthenticatorErrorStyle} className="AuthFormError">{this.state.loginAuthenticatorError}</span>
                    <div style={{backgroundColor: '#FCD844', color: '#2B2624', width: this.state.isMobileScreen ? '70vw' : 'unset'}} onClick={() => this.handleLogin()} className="AuthFormSubmitButtonContainer">
                        <div style={{backgroundColor: '#FCD844', color: '#2B2624'}} className="AuthFormSubmitButton">{this.state.isLoggingIn? "Sending OTP..." : "Send OTP"}</div>
                    </div>
                    <div className="AuthFormToggleContainer">
                        <div className="AuthFormToggle">Don't have an account? <span onClick={() => this.setActiveFormHandler("register")} style={{color: "#EA5B2F", cursor: "pointer", fontFamily: "Poppins-Medium", fontSize: "14px"}}>Sign Up</span> to continue</div>
                    </div>
                </div>
            </div>
        )
    }

    registerForm(){
        var registerPasswordType = "";
        var registerNameStyle = {};
        var registerNameErrorStyle = {};
        var registerEmailStyle = {};
        var registerEmailErrorStyle = {};
        var registerMobileStyle = {};
        var registerMobileErrorStyle = {};
        var registerPasswordStyle = {};
        var registerPasswordErrorStyle = {};
        if (this.state.isMobileScreen){
            registerNameStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerNameErrorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerEmailStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerEmailErrorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerMobileStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerMobileErrorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerPasswordStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
            registerPasswordErrorStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
        }
        if (this.state.registerPassword === "hidden"){
            registerPasswordType = "password";
        } else {
            registerPasswordType = "text";
        }
        if (this.state.isRegisterNameError){
            registerNameStyle = {border: "1px solid red"};
            registerNameErrorStyle = {display: "block"};
            if (this.state.isMobileScreen){
                registerNameStyle = {width: '70vw', border: "1px solid red", backgroundColor: '#FFFFFF'};
                registerNameErrorStyle = {width: '70vw', display: "block"};
            }
        } else {
            registerNameStyle = {border: "#F4F0DC 1px solid"};
            registerNameErrorStyle = {display: "none"};
            if (this.state.isMobileScreen){
                registerNameStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
                registerNameErrorStyle = {width: '70vw', display: "none"};
            }
        }
        if (this.state.isRegisterMobileError){
            registerMobileStyle = {border: "1px solid red"};
            registerMobileErrorStyle = {display: "block"};
            if (this.state.isMobileScreen){
                registerMobileStyle = {width: '70vw', border: "1px solid red", backgroundColor: '#FFFFFF'};
                registerMobileErrorStyle = {width: '70vw', display: "block"};
            }
        } else {
            registerMobileStyle = {border: "#F4F0DC 1px solid"};
            registerMobileErrorStyle = {display: "none"};
            if (this.state.isMobileScreen){
                registerMobileStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
                registerMobileErrorStyle = {width: '70vw', display: "none"};
            }
        }
        if (this.state.isRegisterEmailError){
            registerEmailStyle = {border: "1px solid red"};
            registerEmailErrorStyle = {display: "block"};
            if (this.state.isMobileScreen){
                registerEmailStyle = {width: '70vw', border: "1px solid red", backgroundColor: '#FFFFFF'};
                registerEmailErrorStyle = {width: '70vw', display: "block"};
            }
        } else {
            registerEmailStyle = {border: "#F4F0DC 1px solid"};
            registerEmailErrorStyle = {display: "none"};
            if (this.state.isMobileScreen){
                registerEmailStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
                registerEmailErrorStyle = {width: '70vw', display: "none"};
            }
        }
        if (this.state.isRegisterPasswordError){
            registerPasswordStyle = {border: "1px solid red"};
            registerPasswordErrorStyle = {display: "block"};
            if (this.state.isMobileScreen){
                registerPasswordStyle = {width: '70vw', border: "1px solid red", backgroundColor: '#FFFFFF'};
                registerPasswordErrorStyle = {width: '70vw', display: "block"};
            }
        } else {
            registerPasswordStyle = {border: "#F4F0DC 1px solid"};
            registerPasswordErrorStyle = {display: "none"};
            if (this.state.isMobileScreen){
                registerPasswordStyle = {width: '70vw', backgroundColor: '#FFFFFF', border: '1px solid rgb(244, 240, 220)'};
                registerPasswordErrorStyle = {width: '70vw', display: "none"};
            }
        }
        return (
            <div style={{marginTop: "0", width: this.state.isMobileScreen ? '70vw' : '30vw', padding: this.state.isMobileScreen ? '0px' : '20px'}} className="AuthForm">
                {/* <span style={{color: "green", fontSize: "14px"}} className="AuthFormError">{this.state.registerSuccessMessage}</span> */}
                {/* <span style={{textAlign: 'center', padding: '10px 0px'}} className="AuthFormError">{this.state.registerErrorMessage}</span> */}
                <div className="AuthFormFieldContainer"><input id="registerName" style={registerNameStyle} value={this.state.registerName} onKeyDown={(event) => this.handleKeyPress(event, "registerName", "register", "first", "", "registerEmail")} onChange={(event) => this.setState({registerName: event.target.value})} className="AuthFormField" type="text" placeholder="Name"></input></div>
                <span style={registerNameErrorStyle} className="AuthFormError">{this.state.registerNameError}</span>
                <div className="AuthFormFieldContainer"><input id="registerEmail" style={registerEmailStyle} value={this.state.registerEmail} onKeyDown={(event) => this.handleKeyPress(event, "registerEmail", "register", "middle", "registerName", "registerMobile")} onChange={(event) => this.setState({registerEmail: event.target.value})} className="AuthFormField" type="email" placeholder="Email Address"></input></div>
                <span style={registerEmailErrorStyle} className="AuthFormError">{this.state.registerEmailError}</span>
                <div className="AuthFormFieldContainer"><input id="registerMobile" style={registerMobileStyle} value={this.state.registerMobile} 
                    // onKeyDown={(event) => this.handleKeyPress(event, "registerMobile", "register", "middle", "registerEmail", "registerPassword")}
                    onChange={(event) => {
                        let digitValidator = /^[0-9]*$/;
                        if (digitValidator.exec(event.target.value)){
                            this.setState({registerMobile: event.target.value.slice(0, 10)})
                        }
                    }}
                    className="AuthFormField" placeholder="Mobile Number"></input></div>
                <span style={registerMobileErrorStyle} className="AuthFormError">{this.state.registerMobileError}</span>
                <div className="PasswordWrapper">
                    <div className="AuthFormFieldContainer" style={{position: "relative"}}>
                        <input id="registerPassword" style={registerPasswordStyle} value={this.state.registerPasswordValue} onKeyDown={(event) => this.handleKeyPress(event, "registerPasswordValue", "register", "middle", "registerMobile", "registerConfirmPassword")} onChange={(event) => this.setState({registerPasswordValue: event.target.value})} type={registerPasswordType} className="AuthFormField" placeholder="Password"></input>
                        <span onClick={() => this.togglePasswordVisibility("register")} className="PasswordToggle">{this.passwordToggleIcon("register")}</span>
                    </div>
                </div>
                <span style={registerPasswordErrorStyle} className="AuthFormError">{this.state.registerPasswordError}</span>
                <div className="ForgotPasswordContainer">
                    <span style={{left: "0"}} className="ForgotPassword">By signing up, I agree to the <span style={{color: "#5E4B45", borderBottom: "1px solid #5E4B45", cursor: "pointer"}}>
                        <Link target="_blank" to={'/tnc'} style={{ textDecoration: 'none', color: "#5E4B45" }}>
                            Terms & Conditions
                        </Link>
                    </span></span>
                </div>
                <div style={{backgroundColor: '#FCD844', color: '#2B2624', width: this.state.isMobileScreen ? '70vw' : 'unset'}} onClick={() => this.handleRegister()} className="AuthFormSubmitButtonContainer">
                    <div style={{backgroundColor: '#FCD844', color: '#2B2624'}} className="AuthFormSubmitButton">{this.state.isRegistering? "Signing Up..." : "Continue"}</div>
                </div>
                <div className="AuthFormToggleContainer">
                    <div className="AuthFormToggle">Already have an account? <span onClick={() => this.setActiveFormHandler("login")} style={{color: "#EA5B2F", cursor: "pointer", fontFamily: "Poppins-Medium", fontSize: "14px"}}>Login</span> to continue</div>
                </div>
            </div>
        );
    }

    handleKeyPress = (e, key, type, position, prevElement, nextElement) => {
        const code = e.keyCode ? e.keyCode : e.which;
        if (code === 32) { 
            var value = this.state[key];
            setTimeout(this.setState({ 
                [key]: value + " ",
            }), 100);
        } else if (code === 13){
            if (type === "login"){
                this.handleLogin();
            } else {
                this.handleRegister();
            }
        } else if (code === 9){
            if (type !== "login"){
                if (e.shiftKey){
                    if (position !== "first"){
                        document.getElementById(prevElement).focus();
                    }
                } else {
                    if (position !== "last"){
                        document.getElementById(nextElement).focus();
                    }
                }
            }
        }
    }

    formTypeDecider(){
        var authWrapperStyle = {};
        var authModelStyle = {};
        var authContainerStyle = {width: '35vw'};
        var loginStyle = {};
        var loginStyleMobile = {};
        var loginOTPStyle = {};
        var registerStyle = {};
        var registerSubTitleStyle = {};
        var loginSubTitleStyle = {};
        var loginSubTitleStyleMobile = {};
        var registerSubTitleStyleMobile = {};
        if (this.props.activeForm === "login"){
            loginStyle = {fontFamily: "Quicksand-Regular", display: "block", letterSpacing: "-1px"};
            loginOTPStyle = {fontFamily: "Quicksand-Regular", display: "block"};
            loginStyleMobile = {fontFamily: "Quicksand-Regular", display: "block", marginTop: "30px", marginRight: "0vw", letterSpacing: "-1px"};
            registerStyle = {fontFamily: "Quicksand-Regular", display: "none"};
            registerSubTitleStyle = {fontFamily: "Quicksand-SemiBold", display: "none"};
            registerSubTitleStyleMobile = {fontFamily: "Quicksand-SemiBold", display: "none"};
            loginSubTitleStyle = {fontFamily: "Quicksand-SemiBold", display: "block", fontSize: "1vw", marginBottom: "20px", paddingTop: "0"};
            loginSubTitleStyleMobile = {fontFamily: "Quicksand-SemiBold", display: "block", fontSize: "15px", marginTop: "0px", paddingTop: "0", textAlign: "center"};
        } else {
            loginStyle = {fontFamily: "Quicksand-Regular", display: "none"};
            loginStyleMobile = {fontFamily: "Quicksand-Regular", display: "none"};
            loginOTPStyle = {fontFamily: "Quicksand-Regular", display: "none"};
            registerStyle = {fontFamily: "Quicksand-Regular", display: "block", letterSpacing: "-1px"};
            registerSubTitleStyle = {fontFamily: "Quicksand-SemiBold", display: "block", fontSize: "1.2vw", paddingTop: "0"};
            registerSubTitleStyleMobile = {fontFamily: "Quicksand-SemiBold", display: "block", fontSize: "15px", paddingTop: "0", marginTop: "0px"};
            loginSubTitleStyle = {fontFamily: "Quicksand-SemiBold", display: "none"};
            loginSubTitleStyleMobile = {fontFamily: "Quicksand-SemiBold", display: "none"};
        }
        if (this.state.isMobileScreen){
            if (this.state.otpFormPresent){
                return (
                    <div style={{width: '80vw', marginTop: '0px', marginBottom: '0px'}} className="AuthenticationWrapperNew">
                        <div className="AuthenticationNew" style={authWrapperStyle}>
                            <div>
                                <div style={{width: '80vw'}} className="AuthFormContainer">
                                    <div className="AuthFormButtons LoginWelcomeOTP" style={loginStyle}>Login to Continue</div>
                                    <div className="AuthFormButtons RegisterWelcomeOTP" style={registerStyle}>Welcome to <span style={{color: '#EA5B2F'}}>DaiDish!</span></div>
                                </div>
                                <div style={{width: '80vw'}} className="AuthFormContainer">
                                    <div style={{fontSize: "14px"}} className="AuthFormError">{this.state.loginErrorMessage}</div>
                                </div>
                                <div style={{width: '80vw'}} className="AuthFormContainer">
                                    <div className="AuthFormButtons" style={registerSubTitleStyleMobile}>Sign Up to Continue</div>
                                </div>
                                <div style={{width: '80vw'}} className="AuthFormContainer">
                                    <div className="AuthFormButtons" style={{fontFamily: "Poppins-Regular", display: "block", fontSize: "15px", marginTop: "0px", paddingTop: "0px", textAlign: 'center'}}>OTP has been sent to your Mobile Number.</div>
                                </div>
                                <div style={{marginBottom: this.state.resendOTPSuccessMessage === '' ? '0px': '5px'}} className="ResendOTPSuccessMessage">{this.state.resendOTPSuccessMessage}</div>
                                <div style={{backgroundColor: "white", borderRadius: "5px", margin: "5px 0px"}}>
                                    <input style={{width: '70vw', marginLeft: '5vw', backgroundColor: '#FFFFFF', border: '1px solid red'}} id="otpValue" value={this.state.otpValue} onChange={(event) => this.setState({otpValue: event.target.value})} className="AuthFormField" type="number" placeholder="Enter OTP here"></input>
                                </div>
                                <div style={{marginBottom: '15px', marginRight: '5vw'}} onClick={() => this.resendOTP()} className="ResendOTPText">{this.state.isResendingOTP ? 'Resending OTP...' : 'Resend OTP'}</div>
                                <div style={{textAlign: "center", display: "block", marginBottom: '15px',}} className="AuthFormError OTPError">{this.state.otpError}</div>
                                <div style={{marginTop: "-10px", marginBottom:"20px", backgroundColor: '#FCD844', color: '#2B2624', width: this.state.isMobileScreen ? '70vw' : 'unset'}} onClick={() => this.handleOTP()} className="AuthFormSubmitButtonContainer">
                                    <div style={{backgroundColor: '#FCD844', color: '#2B2624'}} className="AuthFormSubmitButton">{this.state.isSubmittingOTP? "Submitting OTP..." : "Submit OTP"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{width: '80vw', marginTop: '0px', marginBottom: '0px', paddingTop: '0px'}} className="AuthenticationWrapperNew">
                        <div className="AuthenticationNew" style={authWrapperStyle}>
                            <div style={{width: '80vw'}} className="AuthFormContainer">
                                <div className="AuthFormButtons" style={loginStyleMobile}>Login to Continue</div>
                                <div className="AuthFormButtons" style={registerStyle}>Welcome to DaiDish</div>
                            </div>
                            <div style={{width: '80vw'}} className="AuthFormContainer">
                                {/* <div className="AuthFormButtons" style={loginSubTitleStyleMobile}>Login using mobile number or Email ID</div> */}
                                <div className="AuthFormButtons" style={loginSubTitleStyleMobile}>Login using mobile number</div>
                            </div>
                            <div style={{width: '80vw'}} className="AuthFormContainer">
                                <div className="AuthFormButtons" style={registerSubTitleStyleMobile}>Sign Up to Continue</div>
                            </div>
                            <div style={{width: '80vw'}} className="AuthFormContainer">
                                <div style={{fontSize: "14px", textAlign: 'center'}} className="AuthFormError">{this.state.loginErrorMessage}</div>
                            </div>
                            <div style={{width: '80vw'}} className="AuthFormContainer">
                                <div style={{fontSize: "14px", textAlign: 'center'}} className="AuthFormError">{this.state.registerErrorMessage}</div>
                            </div>
                            {this.formDecider()}
                        </div>
                    </div>
                )
            }
        } else {
            if (this.state.otpFormPresent){
                return (
                    <div className="AuthenticationWrapperNew">
                        <div className="AuthenticationNew" style={authModelStyle}>
                            <div style={{width: '35vw'}}  className="AuthFormContainer">
                                <div className="AuthFormButtons LoginWelcomeOTP" style={loginOTPStyle}>Login to Continue</div>
                            </div>
                            <div style={{width: '35vw'}}  className="AuthFormContainer">
                                <div style={{fontSize: "14px"}} className="AuthFormError">{this.state.loginErrorMessage}</div>
                            </div>
                            <div style={{width: '35vw'}}  className="AuthFormContainer">
                                <div className="AuthFormButtons" style={registerStyle}>Sign Up to Continue</div>
                            </div>
                            <div style={{width: '35vw'}}  className="AuthFormContainer">
                                <div className="AuthFormButtons" style={{fontFamily: "Quicksand-SemiBold", display: "block", fontSize: "1vw",  paddingTop: "0"}}>OTP has been sent to your Mobile Number.</div>
                            </div>
                            <div style={{marginTop: "20px", width: '30vw'}} className="AuthForm">
                                <div style={{width: "25vw", margin: "auto", marginBottom: this.state.resendOTPSuccessMessage === '' ? '0px': '5px'}} className="ResendOTPSuccessMessage">{this.state.resendOTPSuccessMessage}</div>
                                <div style={{backgroundColor: "white", borderRadius: "5px", margin: "5px auto", width: "25vw"}}>
                                    <input style={{border: "#F4F0DC 1px solid"}} id="otpValue" value={this.state.otpValue} onChange={(event) => this.setState({otpValue: event.target.value})} className="AuthFormField" type="number" placeholder="Enter OTP here"></input>
                                </div>
                                <div onClick={() => this.resendOTP()} style={{width: "25vw", margin: "auto"}} className="ResendOTPText">{this.state.isResendingOTP ? 'Resending OTP...' : 'Resend OTP'}</div>
                                <div style={{width: "25vw", margin: "auto", marginTop: this.state.otpError === '' ? '0px': '5px', textAlign: "center"}} className="OTPError">{this.state.otpError}</div>
                            </div>
                            <div style={{margin: "auto", width: "25vw", marginTop: "10px", backgroundColor: '#FCD844', color: '#2B2624'}} onClick={() => this.handleOTP()} className="AuthFormSubmitButtonContainer">
                                <div style={{backgroundColor: '#FCD844', color: '#2B2624'}} className="AuthFormSubmitButton">{this.state.isSubmittingOTP? "Submitting OTP..." : "Submit OTP"}</div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='AuthenticationWrapperNew'>
                        <div className="AuthenticationNew" style={authModelStyle}>
                            <div>
                                <div style={authContainerStyle} className="AuthFormContainer">
                                    <div className="AuthFormButtons" style={loginStyle}>Welcome Back to <span style={{color: '#EA5B2F'}}>DaiDish!</span></div>
                                    <div className="AuthFormButtons" style={registerStyle}>Welcome to <span style={{color: '#EA5B2F'}}>DaiDish!</span></div>
                                </div>
                                <div style={{width: '35vw'}} className="AuthFormContainer">
                                    {/* <div className="AuthFormButtons" style={loginSubTitleStyle}>Login using mobile number or Email ID</div> */}
                                    <div className="AuthFormButtons" style={loginSubTitleStyle}>Login using mobile number</div>
                                </div>
                                <div style={{width: '35vw'}} className="AuthFormContainer">
                                    <div className="AuthFormButtons" style={registerSubTitleStyle}>Signup to Continue</div>
                                </div>
                                <div style={{width: '35vw'}} className="AuthFormContainer">
                                    <div style={{fontSize: "14px", textAlign: 'center'}} className="AuthFormError">{this.state.loginErrorMessage}</div>
                                </div>
                                <div style={{width: '35vw'}} className="AuthFormContainer">
                                    <div style={{fontSize: "14px", textAlign: 'center'}} className="AuthFormError">{this.state.registerErrorMessage}</div>
                                </div>
                                {this.formDecider()}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        
        return (
            <div>
                {this.formTypeDecider()}
            </div>
        )
    }
}


export default withRouter(Authentication);