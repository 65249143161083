import axios from 'axios';
import React, {Component} from "react";
import '../../assets/styles/Commons.css';
import CONSTANTS from '../../config/constants';
import user_image from '../../assets/images/profile.png';
import ratings from '../../assets/images/ratings_white.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';
import DefaultImage from '../../assets/images/vector3_1.png';

class DishReviewModal extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.reviewsContainer = this.reviewsContainer.bind(this);
        this.state = {
            reviewList: [],
            reviewLoading: true,
            page: 0,
            reviewsCount: 0,
            reviewsLoaded: 0,
            isMobileScreen: false
        }
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }
    
    componentDidUpdate (newProps) {
        let dishesLoaded = false;
        if( newProps.showReviewsModal !== this.props.showReviewsModal && this.state.reviewLoading){
            this.loadReviews();
            dishesLoaded = true;
        }
        if (newProps.dish !== this.props.dish && !dishesLoaded){
            this.loadReviews();
        }
      }

    loadReviews() {
        this.setState({
            reviewList: [],
            reviewLoading: true,
            page: 0,
            reviewsCount: 0,
            reviewsLoaded: 0
        })
        if (this.props.showReviewsModal){
            let url = CONSTANTS.hostURL + "api/main_app/dish_review/?dish=" + this.props.dish.dishId;
            axios.get(url).then(res => {
                let reviewList = this.state.reviewList;
                let results = res.data.results;
                for (var i=0; i<results.length; i++){
                    let reviewItem = results[i];
                    let item = {};
                    item.id = i + 1;
                    item.reviewId = reviewItem.id;
                    item.name = "Test user";
                    if (reviewItem.user.name !== null && reviewItem.user.name !== ""){
                        item.name = this.capitalize(reviewItem.user.name)
                    } else {
                        item.name = this.capitalize(reviewItem.user.username)
                    }
                    item.rating = reviewItem.rating;
                    item.description = reviewItem.description;
                    reviewList.push(item);
                }
                this.setState({
                    reviewList: reviewList,
                    reviewLoading: false,
                    reviewsCount: res.data.count,
                    reviewsLoaded: results.length
                }, this.fetchMoreReviews(res.data.count, results.length, 2));
            })
        }
      }

      fetchMoreReviews(reviewsCount, reviewsLoaded, currentPage){
        if (reviewsCount > reviewsLoaded){
            let url = CONSTANTS.hostURL + "api/main_app/dish_review/?dish=" + this.props.dish.dishId + "&page=" + currentPage;
            axios.get(url).then(res => {
            let reviewList = this.state.reviewList;
            let results = res.data.results;
            for (var i=0; i<results.length; i++){
                let reviewItem = results[i];
                let item = {};
                item.id = i + reviewsLoaded + 1;
                item.reviewId = reviewItem.id;
                if (reviewItem.user.name !== null && reviewItem.user.name !== ""){
                    item.name = this.capitalize(reviewItem.user.name)
                } else {
                    item.name = this.capitalize(reviewItem.user.username)
                }
                item.rating = reviewItem.rating;
                item.description = reviewItem.description;
                reviewList.push(item);
            }
            let nextPage = currentPage + 1;
            this.setState({
                reviewList: reviewList,
                reviewLoading: false,
                reviewsCount: res.data.count,
                reviewsLoaded: results.length
            }, this.fetchMoreReviews(res.data.count, results.length + reviewsLoaded, nextPage));
            })
        } 
      }
    
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    closeModalHandler() {
        this.props.closeReviewsModal();
    }

    getReviewHeader(){
        if (this.state.reviewLoading){
            return "Loading Reviews...";
        } else {
            if (this.state.reviewsCount === 1){
                return this.state.reviewsCount + " Review";
            } else {
                return this.state.reviewsCount + " Reviews";
            }
        }
    }


    reviewsContainer(buttonStyle) {
        return (
            <div className="DishRateModalBelowContainer">
                <div className="DishReviewModalHeader">{this.getReviewHeader()}</div>
                <div className="DishReviewList">
                    {this.state.reviewList.map((review) => {
                        return (
                            <div key={review.id} className="DishReviewCard">
                                <div className="DishReviewUserImageContainer"><img className="DishReviewUserImage" src={user_image} alt="User Pic"/></div>
                                <div className="DishReviewDetails">
                                    <div className="DishReviewUser">{review.name}</div>
                                    <div className="DishReviewRating">{review.rating}<img className="DishReviewRatingImage" src={ratings} alt="Ratings White Star"/></div>
                                    <div className="DishReviewDescription">{review.description}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        var buttonStyle = [];
        if(!this.props.showReviewsModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div onClick={(e) => this.handleModalClick(e)} className="RatingModalContainer">
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div className="DishImageModalContainer">
                        <img className={`${this.state.isMobileScreen ? "DishImageModalMobile" : "DishImageModal"}`} src={this.props.dish.image === null ? DefaultImage : this.props.dish.image} alt="Dish Pic" />
                        <div className="DishImageModalBlur"></div>
                            <div className="DishImageModalText">
                                <div>
                                    {this.props.dish.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13, left: '20px', top: '19px' }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13, left: '20px', top: '19px' }} className="nonVegTag"/>}
                                </div>
                                <div  className="DishNameModal">
                                    {this.props.dish.name}<span style={{fontSize: "12px"}}> | ₹ {this.props.dish.cost}
                                    </span>
                                </div>
                            <div className="DishRestaurantModal">{this.props.dish.restaurant}</div>
                        </div>
                    </div>
                    {this.reviewsContainer(buttonStyle)}
                </div>
            </div>
        )
    }
}

export default DishReviewModal;