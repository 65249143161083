import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { IoTriangleSharp } from 'react-icons/io5';

class LikedDishes extends Component{

    constructor(props){
        super(props);
        this.showRatingsModalHandler = this.showRatingsModalHandler.bind(this);
        this.removeLikeHandler = this.removeLikeHandler.bind(this);
        this.checkNoLikes = this.checkNoLikes.bind(this);
        this.state = {
            isMobileScreen: false,
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    showRatingsModalHandler(dish) {
        this.props.showRatingsModal(dish);
    }

    removeLikeHandler(dish){
        this.props.removeLike(dish);
    }

    checkNoLikes(){ 
        if (this.props.likes.filter(function(like) {return like.display;}).length === 0){
            return (
                <div className="TabLikesReview" style={{marginTop: "80px", textAlign: "center", fontSize: "20px"}}>
                    No liked dishes present.
                </div>
            )
        } else {
            return null;
        }
    }

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    render(){
        if (this.props.likesFetched){
            var likeButtonStyle = [];
            for (var l=0; l<this.props.likes.length; l++){
                likeButtonStyle.push("");
                if (this.props.likes[l].liked === true) {
                    likeButtonStyle[l] = "LikeButton LikeButtonActive";
                } else {
                    likeButtonStyle[l] = "LikeButton";
                }
            }
            return(
                <div>
                    <div style={{marginTop: "40px"}}>
                        {
                            this.props.likes.map((like) => {
                                if (like.display){
                                    return(
                                        this.state.isMobileScreen ? 
                                            <div key={like.dishId} className="TabLikesContainer">
                                                {like.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13, top:"3px" }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13, top:"3px" }} className="nonVegTag"/>}
                                                <div className="TabLikesDescription">
                                                    <div className="TabLikesHeader">{like.name}</div>
                                                    <div className="TabLikesCost">Rs. {like.cost}</div>
                                                    <Link  to={'/restaurants/' + like.restaurantID} style={{ textDecoration: 'none' }}>
                                                        <div style={{cursor: "pointer"}} className="TabLikesRestaurant">{like.restaurant}</div>
                                                    </Link>
                                                </div>
                                                <div className="LikeButtonContainerSuper" style={{position: "static"}}>
                                                    <div onClick={() => this.removeLikeHandler(like)} className="LikeButtonContainer">
                                                        <span style={{top:"20px", right:"65px"}} className={likeButtonStyle[like.id]}></span>
                                                    </div>
                                                </div>
                                                <div style={{cursor: "pointer"}} onClick={() => this.showRatingsModalHandler(like)} className="TabLikesRate">Rate</div>
                                            </div>
                                        :
                                            <div key={like.dishId} className="TabLikesContainer">
                                                {like.choice === "V" ? <FiberManualRecordIcon style={{ fontSize: 13, top:"3px" }} className="vegTag"/> : <IoTriangleSharp style={{ fontSize: 13, top:"3px" }} className="nonVegTag"/>}
                                                <div className="TabLikesDescription">
                                                    <div className="TabLikesHeader">{like.name} <span className="TabLikesDivider">|</span><span className="TabLikesCost">Rs. {like.cost}</span></div>
                                                    <Link  to={'/restaurants/' + like.restaurantID} style={{ textDecoration: 'none' }}>
                                                        <div style={{cursor: "pointer"}} className="TabLikesRestaurant">{like.restaurant}</div>
                                                    </Link>
                                                </div>
                                                <div className="LikeButtonContainerSuper" style={{position: "static"}}>
                                                    <div onClick={() => this.removeLikeHandler(like)} className="LikeButtonContainer">
                                                        <span style={{top:"22px", right:"150px"}} className={likeButtonStyle[like.id]}></span>
                                                    </div>
                                                </div>
                                                <div style={{cursor: "pointer"}} onClick={() => this.showRatingsModalHandler(like)} className="TabLikesRate">Rate</div>
                                            </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                    {this.checkNoLikes()}
                </div>
            )
        } else {
            return(
                <div className="TabLikesReview" style={{marginTop: "80px", textAlign: "center", fontSize: "20px"}}>
                    Loading liked dishes...
                </div>
            )
        }
        
    }
}

export default withRouter(LikedDishes);