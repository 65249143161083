import React from 'react';
import { Grid, Button } from '@material-ui/core';
import image02 from '../../assets/images/02_1.gif';
import '../../assets/styles/AboutUs.css';
import { Component } from 'react';

// Description of the website 
class Segment2 extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            gridSize: 4,
            buttonID: "signup-button-content",
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ isMobileScreen: false });
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render () {
        if (this.state.isMobileScreen){
            return(
                <Grid container style={{marginTop : '30px', marginBottom : '30px'}}>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                        <img style={{width:'65vw'}} src={image02} alt="image02"/>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="stretch">
                        <div>
                            <div><br/>
                                <div className="DescriptionTaglineDetails" style={{textAlign: 'center'}}>
                                In the era of restaurant ratings and reviews, DaiDish brings the focus back to what truly matters when you order food online: the dishes themselves. We believe in empowering you to "eat right every time" by providing the best food delivery experience in Bangalore. Our mission is to make the world of food a better place for food lovers, ensuring you can savour the best dishes from top-rated restaurants every day.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center">
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            this.props.toggleAuthModal();
                            this.props.setActiveForm("register");
                        }} id={this.state.buttonID}>
                            SIGN UP NOW
                        </Button>
                    </Grid>
                </Grid> 
            )
        }
        return (
            <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch" style={{background: "-webkit-linear-gradient(130deg, #FCF9CF 66%, #FFFFFF 33%)", marginTop : '30px', marginBottom : '30px'}}>
                <Grid container item xs={6} direction="column" justifyContent="center" style={{overflow:  'visible', paddingLeft:'10%'}}>
                    <img style={{width:'45vw'}} src={image02} alt="image02"/>
                </Grid>
                <Grid container item xs={6} direction="column" justifyContent="center" style={{marginTop : '30px'}}>
                    <div style={{paddingLeft: '7vw'}}>
                        <div>
                            <div className="ContentDescriptionDetails">
                            In the era of restaurant ratings and reviews, DaiDish brings the focus back to what truly matters when you order food online: the dishes themselves. We believe in empowering you to "eat right every time" by providing the best food delivery experience in Bangalore. Our mission is to make the world of food a better place for food lovers, ensuring you can savour the best dishes from top-rated restaurants every day.
                            </div>
                            <Button onClick={() => {
                                window.scrollTo(0, 0);
                                this.props.toggleAuthModal();
                                this.props.setActiveForm("register");
                            }} id={this.state.buttonID}>
                                SIGN UP NOW
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default Segment2;