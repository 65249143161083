import React, { Component } from 'react';
import '../../assets/styles/PartnerTnc.css';
import { withRouter } from 'react-router';
import '../../assets/styles/PartnerWithUs.css';

// Top Header which contains logo, auth options, burger icon and header description
class Content extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

   render() {
    return (
        <div className="TncContainer">
            <Title/>
            <General/>
            <WhatInformation/>
            <HowInformation/>
            <ThirdParty/>
            <AnonymousData/>
            <PublicInformation/>
            <Advertising/>
            <ProtectInformation/>
            <AdvertisingLinks/>
            <Storage/>
            <DataRetention/>
            <Changes/>
            <ContactUs/>
            <GoverningLaw/>
            <Grievance/>
            <Suggestions/>
            <Agreement/>
        </div>
    )
   }
}

class Title extends Component {
    render() {
        return(
            <div className="TncTitle">PRIVACY POLICY</div>
        )
    }
}

class General extends Component {
    render() {
        return(
            <div className="TncIntroContainer">
                <div className="TncSectionTitle">1. GENERAL</div>
                <div className="TncSubIntro">
                    This privacy policy (<span className="TncBold">
                    "Privacy Policy"</span>)  is a contract between Muchroom Technologies Pvt Ltd,  
                    (hereinafter referred to the <span className="TncBold">
                    "Company"</span> or <span className="TncBold">"Daidish"</span> or 
                    <span className="TncBold"> "Us"</span>  or <span className="TncBold">
                    "Our"</span> or <span className="TncBold">"We"</span>). The link to the Terms of 
                    Use is  <a target="_blank" rel="noopener noreferrer" href="https://daidish.com/tnc">https://daidish.com/tnc</a>. 
                    In this Policy, the user or you shall be referred to as <span className="TncBold">"You"</span> or 
                    <span className="TncBold">"Your"</span>, <span className="TncBold">"User"</span> or 
                    <span className="TncBold"> "Users"</span>. <span className="TncBold">DaiDish</span> is the owner and operator of 
                    the website <a target="_blank" rel="noopener noreferrer" href="https://daidish.com">https://daidish.com</a> and 
                    its related mobile applications, DaiDish Services (as defined in the Terms of Use) and services that 
                    DaiDish may provide now or in the future (collectively, referred 
                    to as <span className="TncBold">"Platform"</span>/ <span className="TncBold">"Website"</span>
                    / <span className="TncBold">"App"</span>/ <span className="TncBold">"Site"</span>). 
                </div>
                <div className="TncSubIntro">
                    This Privacy Policy describes how we collect, use, and disclose information 
                    when you visit or use our platform. 
                </div>
                <div className="TncIntro">
                    We respect your privacy and are committed to protecting it through its compliance with its Our Privacy Policy. This policy describes:
                </div>
                <div className="TncBulletPoint">
                    a) The types of information that we may collect from you when you access 
                    or use our website or our Services;
                </div>
                <div className="TncBulletPoint">
                    b) Its practices for collecting, using, maintaining, protecting and 
                    disclosing that information, and;
                </div>
                <div className="TncBulletPoint">
                    c) How you can control the collection, correction, and/or deletion 
                    of the information.
                </div>
                <div className="TncSubIntro">
                    We will not knowingly use or share your information with anyone, except 
                    as described in this Privacy Policy. The use of information collected 
                    through Our Site shall be limited to the purposes described under this 
                    Privacy Policy and our Terms of Use. This policy applies only to the 
                    information We collect through Our offerings and platform, in email, 
                    text and other electronic communications sent through or in connection with our platform.
                </div>
                <div className="TncSubIntro">
                    This policy DOES NOT apply to information that you provide to any 
                    third party, such as readers, website users, social networks that you 
                    use in connection with our website. We encourage you to consult directly 
                    with such third parties about their privacy practices or read their 
                    Privacy Policy and Terms of Use statement that may be provided on their 
                    website. 
                </div>
                <div className="TncSubIntro">
                    We will not knowingly use or share your information with anyone, except 
                    as described in this Privacy Policy. The use of information collected 
                    through Our Site shall be limited to the purposes described under this 
                    Privacy Policy and our Terms of Use. 
                </div>
                <div className="TncSubIntro">
                    Please read this policy carefully to understand our policies and practices 
                    regarding your information and how we will treat it. By accessing, downloading 
                    or using Our Platform and/or registering for an account with 
                    us, you agree to this privacy policy and you are consenting to the use, 
                    disclosure, retention, and protection of your personal information as 
                    described here. If you do not provide the information we require, we may 
                    not be able to provide all of our Services to you or provide such 
                    Services most efficiently and interactively.
                </div>
            </div>
        )
    }
}

class WhatInformation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">2. WHAT INFORMATION DO WE COLLECT AND HOW IS IT USED</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">a)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Information You Voluntarily Submit to us on Website: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We may collect personal information from you such as your 
                    <span className="TncBold"> name</span>, <span className="TncBold"> address</span> 
                    or <span className="TncBold"> email address, mobile number, gender, 
                    state, city</span>. For example, you may 
                    voluntarily submit information to us by leaving a comment, subscribing 
                    to a newsletter, or submitting a 'contact us' form. In addition, you can 
                    create a user profile, which allows you to create a username and 
                    password, and in such a process as well you give provide us with certain 
                    personally identifiable information (PII). In addition, We will store 
                    the username, but your password will not be visible in our records.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">b)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Information We Collect from Others: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We may receive information about you from other sources. For example, 
                    You may link your Facebook and/or Google accounts to your user profile. 
                    If you choose to link your Facebook and/or Google accounts to your user 
                    profile on our Website, we will receive your name, email address and 
                    profile picture associated with that account. 
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">c)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Automatically-Collected Information: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We automatically collect certain information about you and the device 
                    with which you access the App or Website. For example, when you use the 
                    App or Website, we will log your IP address, location, operating system 
                    type, browser type, referring app, pages you viewed, and the dates/times 
                    when you accessed the App or Website. We may also collect information 
                    about actions you take when using the App or Website, such as links 
                    clicked and the number of times they've been clicked.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">d)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Cookies: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We may log information using cookies, which are small data files stored 
                    on your browser by the website. We may use both session cookies, which 
                    expire when you close your browser, and persistent cookies, which stay 
                    on your browser until deleted, to provide you with a more personalized 
                    experience on the website. However, in case you do not wish for us to 
                    collect such information, simply change the cookie settings on your web 
                    browser. Some features and services may not function properly if your cookies are disabled.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">e)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Location: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    When you use one of our location-enabled services (for example, when 
                    you access our services from a mobile device), we may collect and 
                    process information about your mobile device's GPS location 
                    (including the latitude, longitude or altitude of your mobile device) 
                    and the time the location information is recorded to customize the 
                    Services with location-based information and features (for example, 
                    to inform you about restaurants in your area or applicable promotions). 
                    Some of these services require your personal data for the feature to 
                    work and we may associate location data with your device ID and other 
                    information we hold about you. We keep this data for no longer than is 
                    reasonably necessary for providing services to you. If you wish to use 
                    the particular feature, you will be asked to consent to your data being 
                    used for this purpose. You can withdraw your consent at any time by 
                    disabling the GPS or other location-tracking functions on your device, 
                    provided your device allows you to do this. See your device 
                    manufacturer's instructions for further details.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">f)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Restaurant: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We may share some information with the restaurants, such as information 
                    about your dining preferences and history or information that we collect 
                    from various means. Information you choose to share with a restaurant 
                    when you place any direct ordered may be used by the restaurant for 
                    its own purposes. We do not control the privacy practices of restaurants. 
                    Please contact the restaurant directly if you want to know about its 
                    privacy policies.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">g)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Public Posts: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    You may provide information (such as ratings, reviews, tips, photos, 
                    comments, likes, bookmarks, friends, lists, etc.) to be published or 
                    displayed (hereinafter, "posted") on publicly accessible areas of our 
                    Services, or transmitted to other users of our Services or third-parties 
                    (collectively, "User Contributions"). Your User Contributions are posted 
                    on and transmitted to others at your own risk. Although we limit access 
                    to certain pages, you may set certain privacy settings for such 
                    information by logging into your account profile. We cannot control the 
                    actions of other users of our Services with whom you may choose to share 
                    your User Contributions. Therefore, we cannot and do not guarantee that 
                    your User Contributions will not be viewed by unauthorized persons. We may 
                    display this information on the Services, share it with businesses, and 
                    further distribute it to a wider audience through third party sites and 
                    services. You should be careful about revealing any sensitive details 
                    about yourself in such postings.
                </div>
                <div className="TncSectionContent">
                    We use the information you provide to us to enhance the functionality 
                    and improve the quality of our Services, and to personalize your 
                    experience while using our Services. We also use this information to 
                    display relevant advertising, provide support to you, communicate with 
                    you, and comply with our legal obligations.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">h)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Financial Information: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    In order to provide the Platform for Your use We may require You to provide DaiDish 
                    with certain financial information such as bank account or credit card or debit card 
                    or any other payment information. The credit or debit card information goes directly 
                    to our third party secure payment gateway and is not retained in our servers at all.
                </div>
            </div>
        )
    }
}

class HowInformation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">3. HOW YOUR INFORMATION MAY BE USED</div>
                <div className="TncSectionContent">
                    We may use the information collected from you, in the following ways:
                    <div className="TncBulletPoint">
                        a) To administer promotions and contests.
                    </div>
                    <div className="TncBulletPoint">
                        b) To operate and maintain the App or the Website;
                    </div>
                    <div className="TncBulletPoint">
                        c) To create your account, identify you as a user of the App or 
                        the Website, and customize the App or the Website for your account; 
                    </div>
                    <div className="TncBulletPoint">
                        d) To send you promotional information, such as newsletters. 
                        Each email promotion will provide information on how to opt-out of 
                        future mailings;
                    </div>
                    <div className="TncBulletPoint">
                        e) To send you administrative communications, such as administrative 
                        emails, confirmation emails, technical notices, updates on policies, 
                        or security alerts;
                    </div>
                    <div className="TncBulletPoint">
                        f) To respond to your comments or inquiries;
                    </div>
                    <div className="TncBulletPoint">
                        g) To provide you with user support;
                    </div>
                    <div className="TncBulletPoint">
                        h) To track, measure and improve advertisement on the Website; 
                    </div>
                    <div className="TncBulletPoint">
                        i) To help verify accounts and activity, and to promote safety 
                        and security of our Services, such as by investigating suspicious 
                        activity or violations of our terms or policies. 
                    </div>
                    <div className="TncBulletPoint">
                        j) To protect, investigate, and deter against unauthorized or 
                        illegal activity. 
                    </div>
                    <div className="TncBulletPoint">
                        k) If you interact with social media features on our Services, such 
                        as the Facebook Like button, or you use your social media credentials 
                        to log-in or post content, these features may collect information 
                        about your use of the Services, as well as post information about 
                        your activities on the social media service. Your interactions with 
                        social media companies are governed by their privacy policies.
                    </div>
                    <div className="TncBulletPoint">
                        l) To provide information to law enforcement agencies or in 
                        connection with an investigation on matters related to public safety or Applicable Law.
                    </div>
                    <div className="TncBulletPoint">
                        m) For personalizing content and experiences of Our Service, including 
                        providing you reports, recommendations, and feedback based on your preferences.
                    </div>
                </div>
            </div>
        )
    }
}

class ThirdParty extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">4. THIRD-PARTY USE OF PERSONAL INFORMATION</div>
                <div className="TncSectionContent">
                    We may share your information with outside vendors/third parties. 
                    Additionally, the website may use third-party service providers to 
                    service various aspects of the website. We may also share information 
                    about you with certain vendors to enable them to perform their services. 
                    Each third-party service provider's use of your personal information 
                    is dictated by their respective privacy policies. For example, we use 
                    Google Cloud Platform, for the storage of your personal data.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Disclosure of Information: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    In the event of a corporate sale, merger, reorganization, dissolution, 
                    or similar event, your information may be shared, disposed of, transferred, 
                    or otherwise disclosed as part of that transaction.
                </div>
                <div className="TncSectionContent">
                    We engage certain trusted third parties to perform functions and 
                    provide services to us, including cloud hosting services, off-site backups, 
                    email service providers, and customer support providers. We will only share 
                    your information with third parties to the extent necessary to perform 
                    these functions, in accordance with the purposes set out in this 
                    Privacy Policy and Applicable Laws.
                </div>
                <div className="TncSectionContent">
                    We do not sell or trade your information to third parties.
                </div>
            </div>
        )
    }
}

class AnonymousData extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">5. ANONYMOUS DATA</div>
                <div className="TncSectionContent">
                    From time to time, we may use anonymous data, which does not identify 
                    you alone, or when combined with data from other parties. This type 
                    of anonymous data may be provided to other parties for 
                    marketing, advertising, or other uses. 
                    Examples of this anonymous data may include analytics or information 
                    collected from cookies.
                </div>
            </div>
        )
    }
}

class PublicInformation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">6. PUBLICLY VISIBLE INFORMATION</div>
                <div className="TncSectionContent">
                    If you create a user profile on the website or leave a comment, certain 
                    information may be publicly visible. To create a user profile, you must 
                    choose a username and password and input your email address for profile 
                    confirmation. Your email address will never be available publicly. At 
                    your option, you may also add an avatar, a profile description, and a 
                    link to your profile on the website. You may also choose to link your 
                    Facebook and Google Account. Users may see your username, avatar, 
                    profile description and profile information.   
                </div>
            </div>
        )
    }
}

class Advertising extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">7. ADVERTISING</div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">a)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Display Ads</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    We may use third-party advertising companies to serve content and 
                    advertisements when you visit the App, which may use cookies, as 
                    noted above.
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">b)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Retargeting Ads</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    From time to time, the Website may engage in remarketing efforts with 
                    third-party companies, such as Google or Facebook to market the website. 
                    These companies use cookies to serve ads based on someone's past visits 
                    to the App. 
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentTitle">c)</div>
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Affiliate Program Participation</span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    The website may engage in affiliate marketing, which is done by 
                    embedding tracking links into the website. If you click on a link for 
                    an affiliate partnership, a cookie will be placed on your browser to 
                    track any sales for purposes of commissions.
                </div>
                <div className="TncSectionContent">
                    We may also share your information in response to a legal request, 
                    such as requests from courts, tribunals, government or law enforcement 
                    agencies.
                </div>
                <div className="TncSectionContent">
                    Besides the above, we keep your information safe and do not share your 
                    information with any other third party. However, If we merge with or are 
                    acquired by another company or we sell Our Website or business unit, or 
                    if all or a substantial portion of our assets are acquired by another 
                    company, in those cases, your information will likely be one of the 
                    assets that would be transferred.
                </div>
            </div>
        )
    }
}

class ProtectInformation extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">8. HOW WE PROTECT YOUR INFORMATION</div>
                <div className="TncSectionContent">
                    We have implemented appropriate physical, electronic, and managerial 
                    procedures to safeguard and help prevent unauthorized access to your 
                    information and to maintain data security. These safeguards take into 
                    account the sensitivity of the information that we collect, process and 
                    store and the current state of technology. 
                </div>
                <div className="TncSectionContent">
                    We follow generally accepted industry standards to protect the personal 
                    information submitted to us, both during transmission and once we receive 
                    it. The third-party service providers concerning payment gateway and 
                    payment processing are all validated as compliant with the payment card 
                    industry standard. 
                </div>
                <div className="TncSectionContent">
                    We assume no liability or responsibility for the disclosure of your 
                    information due to errors in transmission, unauthorized third-party 
                    access, or other causes beyond our control. You play an important role 
                    in keeping your personal information secure. You should not share your 
                    user name, password, or other security information for your account with 
                    us or with anyone else. If we receive instructions using your user name 
                    and password, we will consider that you have authorized the instructions. 
                    You shall solely be responsible for maintaining the security of your 
                    account details.
                </div>
            </div>
        )
    }
}

class AdvertisingLinks extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">9. ADVERTISING AND LINKS TO OTHER SITES</div>
                <div className="TncSectionContent">
                    The Website and the Service may contain links to third-party websites and 
                    online services that are not owned or controlled by us. We have no control 
                    over and assume no responsibility for, such websites and online services. 
                    Be aware when you leave the Website or Service; we suggest you read the 
                    terms and privacy policy of each third-party website and online service 
                    that you visit.  
                </div>
            </div>
        )
    }
}

class Storage extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">10. STORAGE AND SECURITY OF INFORMATION</div>
                <div className="TncSectionContent">
                    Please note that the Daidish Platform stores your data with the cloud 
                    platform of Google, namely Google Cloud Platform, which stores such 
                    data on their servers which might be located outside of India. Google 
                    Cloud Platform has their own security measures in place to protect the 
                    loss, misuse and alteration of the information, details of which are 
                    available at <a target="_blank" rel="noopener noreferrer" href="https://cloud.google.com/gcp/">https://cloud.google.com/gcp/</a>. 
                    The privacy policy adopted by Google Cloud Platform are available 
                    in <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. 
                    If you have questions or concerns about the security measures adopted by 
                    Google Cloud Platform, you can contact their data protection / 
                    privacy team / legal team or designated grievance officer at these 
                    organisations, whose contact details are available in their privacy 
                    policy, or can also freely write to our Grievance Officer 
                    at <a href="mailto:grievances@daidish.com">grievances@daidish.com</a>.   
                </div>
            </div>
        )
    }
}

class DataRetention extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">11. DATA RETENTION & ACCOUNT TERMINATION</div>
                <div className="TncSectionContent">
                    If you would like to review, change or delete your personal information 
                    that we have collected from you, or in case you would like to delete your 
                    account, please write to us at the email mentioned under 
                    the <span className="TncBold">"Contact Us"</span> section at the 
                    bottom of every page. We will remove your public posts 
                    from view and/or disassociate them from your account profile, but we 
                    may retain certain information about you for the purposes authorized 
                    under this Privacy Policy unless prohibited by applicable law. 
                    Thereafter, we will either delete your personal information or de-identify 
                    it so that it becomes anonymous and stops being attributed to your identity. 
                    For example, we may retain information to prevent, investigate, or identify 
                    possible wrongdoing in connection with the Service or to comply with legal obligations. 
                </div>
                <div className="TncSectionContent">
                    All information provided by you, except upon withdrawal or termination, shall be 
                    retained as long as Your account is created and for use in operational purposes only. 
                    Upon withdrawal or termination of Your account with Us, We will delete information 
                    based on a request received from you within a reasonable period and latest within 
                    90 days of receiving a deletion request. 
                </div>
            </div>
        )
    }
}

class Changes extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">12. CHANGES IN PRIVACY POLICY</div>
                <div className="TncSectionContent">
                    We reserve the right to amend this Privacy Policy from time to time to 
                    reflect changes in the law, our data collection and use practices, the 
                    features of our services, or advances in technology. Please check this 
                    page periodically for changes. The use of the information we collect 
                    is subject to the Privacy Policy in effect at the time such information 
                    is used. If we make any material changes to this Privacy Policy, we 
                    will post the changes here. Please review the changes carefully. Your 
                    continued use of the Services following the posting of changes to this 
                    Privacy Policy will constitute your consent and acceptance of those changes. 
                </div>
            </div>
        )
    }
}

class ContactUs extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">13. CONTACT US</div>
                <div className="TncSectionContent">
                    If you have any queries relating to the processing/ usage of information 
                    provided by you or our Privacy Policy, you may email us 
                    at <a href="mailto:info@daidish.com">info@daidish.com</a>. 
                </div>
            </div>
        )
    }
}

class GoverningLaw extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">14. GOVERNING LAW AND DISPUTE RESOLUTION</div>
                <div className="TncSectionContent">
                    In case of any disputes, matters, claims or controversies arising out of 
                    or in relation to this Privacy Policy, the governing law and dispute 
                    resolution mechanism mentioned in the Term of Use shall continue to apply. 
                </div>
            </div>
        )
    }
}

class Grievance extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">15. GRIEVANCE REDRESSAL MECHANISM AND CONTACT US</div>
                <div className="TncSectionContainer">
                    In compliance with The Information Technology Act, 2000 and the 
                    rules made thereunder, as well as The Consumer Protection Act 2019, 
                    and the rules made thereunder, the grievance redressal mechanism, 
                    including the contact details of the Grievance Officer are provided below:
                </div>
                <div className="TncSectionContainer">
                    For any grievance you may have please reach out to us 
                    on <a href="mailto:grievances@daidish.com">grievances@daidish.com</a>.
                </div>
                <div className="TncSectionContainer">
                    <span className="TncBold">Details of Grievance officer:</span>
                    <div className="TncBulletPoint">
                        Name: Vinit R
                    </div>
                    <div className="TncBulletPoint">
                        Email ID: <a href="mailto:grievances@daidish.com">grievances@daidish.com</a>
                    </div>
                </div>
                <div className="TncSectionSubContentContainer">
                    <div className="TncSectionSubContentBody">
                        <span className="TncBold">Grievance Mechanism: </span>
                    </div>
                </div>
                <div className="TncSectionContent">
                    DaiDish will acknowledge receipt of your complaints or grievances within 48 hours 
                    of receipt of your complaint or grievances. DaiDish will redress your complaint or 
                    grievances within 1(one) month from the date of receipt of your complaints or grievances.
                </div>
            </div>
        )
    }
}

class Suggestions extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">16. WELCOMING OF SUGGESTIONS</div>
                <div className="TncSectionContainer">
                    We welcome your comments regarding this Privacy Policy. Please 
                    write to us at <a href="mailto:info@daidish.com">info@daidish.com</a>.
                </div>
            </div>
        )
    }
}

class Agreement extends Component{
    render() {
        return(
            <div className="TncSectionContainer">
                <div className="TncSectionTitle">17. ENTIRE AGREEMENT</div>
                <div className="TncSectionContainer">
                    This Privacy Policy and the Terms of Use of the Platform constitute the 
                    entire agreement between You and DaiDish and govern Your use of the Platform, 
                    superseding any prior agreements between You and DaiDish.
                </div>
            </div>
        )
    }
}

export default withRouter(Content);