import React, {Component} from "react";
import update from 'react-addons-update';
import axios from 'axios';
import CONSTANTS from '../../config/constants';
import '../../assets/styles/Commons.css';
import ratings from '../../assets/images/ratings_grey.svg';
import ratings_active from '../../assets/images/ratings_white.svg';
import StarIcon from '@material-ui/icons/Star';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DefaultImage from '../../assets/images/vector3_1.png';
import RatingStar from "../../assets/images/ratingStar.png";
import RatingStarSelected from "../../assets/images/ratingStarSelected.png";


class DishRatingModal extends Component {
    constructor(props){
        super(props);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.handleRatingStar = this.handleRatingStar.bind(this);
        this.toggleStarActive = this.toggleStarActive.bind(this);
        this.formContainer = this.formContainer.bind(this);
        this.ratingContainer = this.ratingContainer.bind(this);
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
        this.makeEmojisBigger = this.makeEmojisBigger.bind(this);
        this.makeEmojisSmaller = this.makeEmojisSmaller.bind(this);
        this.starIcon = this.starIcon.bind(this);
        this.state = {
            ratingList: [
                {id: 0, value: 1, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 1, value: 1.5, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 2, value: 2, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 3, value: 2.5, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 4, value: 3, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 5, value: 3.5, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 6, value: 4, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 7, value: 4.5, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected},
                {id: 8, value: 5, hovered: false, selected: false, image: RatingStar, imageHover: RatingStarSelected}
            ],
            currentSelectedRatingID: -1,
            ratingText: "Rate",
            description: "",
            readyToSave: false,
            isSaving: false,
            savingText: "Done",
            charactersRemaining: 50,
            isMobileScreen: false,
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
        } else {
            this.setState({ isMobileScreen: false });
        }
    }

    closeModalHandler() {
        let ratings = [];
        
        for(let i=0; i<9; i++){
            let currentRating = this.state.ratingList[i];
            currentRating.selected = false;
            currentRating.hovered = false;
            ratings.push(currentRating)
        }
        this.setState({
            ratingList: ratings,
            currentSelectedRatingID: -1,
            formSubmitted: false,
            ratingText: "Rate",
            readyToSave: false,
            isSaving: false,
            description: "",
            savingText: "Done"
        })
        this.props.closeRatingsModal()
    }

    handleRatingStar(selected){
        if (!selected){
            return ratings;
        } else {
            return ratings_active;
        }
    }

    makeEmojisBigger(index){
        if (!this.state.isSaving){
            let ratings = [...this.state.ratingList];
            let rating = ratings[index];
            for (var i=0; i<ratings.length; i++){
                if (i === index){
                    ratings[i].hovered = true;
                } else {
                    ratings[i].hovered = false;
                    // if (this.state.currentSelectedRatingID !== i){
                    //     ratings[i].hovered = false;
                    // }
                }
            }
            this.setState({
                ratingList : ratings,
                ratingText : rating.value
            })
        }
    }

    makeEmojisSmaller(index){
        if (!this.state.isSaving){
            if (this.state.currentSelectedRatingID === -1){
                let ratings = [...this.state.ratingList];
                for (var i=0; i<ratings.length; i++){
                    ratings[i].hovered = false;
                }
                this.setState({
                    ratingList : ratings,
                    ratingText: "Rate"
                })
            } else {
                let ratings = [...this.state.ratingList];
                let value = "";
                for (var j=0; j<ratings.length; j++){
                    if (j !== this.state.currentSelectedRatingID){
                        ratings[j].hovered = false;
                    } else {
                        ratings[j].hovered = true;
                        value = ratings[j].value;
                    }
                }
                this.setState({
                    ratingList : ratings,
                    ratingText: value
                })
            }
        }
    }

    toggleStarActive(index){
        if (!this.state.isSaving){
            let ratings = [...this.state.ratingList];
            let rating = {...ratings[index]};
            let currentRatingID = this.state.currentSelectedRatingID;
            let readyToSave = true;
            if (currentRatingID !== -1){
                let currentRating = {...ratings[currentRatingID]};
                if (currentRatingID === index){ 
                    readyToSave = false;
                    currentRating.selected = false;
                    currentRating.hovered = false;
                    this.setState(update(this.state, {
                        ratingList: {
                            [currentRatingID]: {
                                $set: currentRating
                            }
                        }
                    }), () => {
                            this.setState({
                                currentSelectedRatingID: -1
                            }, () => this.glowStars());
                        }
                    )
                }else {
                    currentRating.selected = false;
                    currentRating.hovered = false;
                    rating.selected  = !rating.selected;
                    rating.hovered = true;
                    this.setState(update(this.state, {
                        ratingList: {
                            [index]: {
                                $set: rating
                            }
                        }
                    }), () => this.setState(update(this.state, {
                        ratingList: {
                            [currentRatingID]: {
                                $set: currentRating
                            }
                        }
                    }), () => {
                            this.setState({
                                currentSelectedRatingID: index
                            }, () => this.glowStars());
                        }
                    ))
                }
            } else {
                rating.selected  = true;
                rating.hovered = true;
                this.setState(update(this.state, {
                    ratingList: {
                        [index]: {
                            $set: rating
                        }
                    }
                }), () => {
                        this.setState({
                            currentSelectedRatingID: index
                        }, () => this.glowStars());
                    }
                )
            }
            if (this.state.description.length >= 50 && readyToSave){
                this.setState({
                    readyToSave: true
                })
            } else {
                this.setState({
                    readyToSave: false
                })
            }
        }
    }

    glowStars(){
        // var ratingItem = this.state.ratingList.filter((item) => item.id === this.state.currentSelectedRatingID)[0];
        let ratingList = [...this.state.ratingList];
        for(let i=0; i<this.state.ratingList.length; i++){
            let ratingListItem = {...ratingList[i]};
            if(this.state.currentSelectedRatingID >= 0){
                if(i <= this.state.currentSelectedRatingID){
                    ratingListItem.selected = true;
                }
                else{
                    ratingListItem.selected = false;
                }
            }
            else{
                ratingListItem.selected = false;
            }
            ratingList[i] = ratingListItem;
        }
        this.setState({ratingList});
    }

    starIcon(){
        if (this.state.ratingText !== "Rate"){
            return <StarIcon className="RatePopupStar"/>;
        } else {
            return null;
        }
    }

    ratingContainer(emojiStyle, emojis, emptySpace, ratingStyle, ratingStarStyle) {
        return (
            <div className="DishRateModalBelowContainer" style={{background:"none"}}>
                <div className="DishImageModalText" style={{position:"initial", textAlign:"center", maxWidth:"100%", transform:"translateY(0px)"}}>
                    <div style={{color:"#2B2624", fontFamily:"Quicksand-Medium", fontSize:"18px"}}>How was your</div>
                    <div style={{color:"#2B2624", fontFamily:"Quicksand-Bold", fontSize:"22px"}}>{this.props.dish.name}?</div>
                    {(this.props.dish.restaurant !== undefined) && <div style={{fontFamily:"Poppins-Regular", fontSize:"15px"}}><span style={{color:"#5E4B45"}}>From </span><span style={{color:"#1E429A"}}>{this.props.dish.restaurant}</span></div>}
                </div>
                <div style={{margin:"10px auto 0px auto"}}>
                    {
                        this.state.ratingList.map((rating) => {
                            return(
                                this.state.isMobileScreen ? 
                                <div key={rating.id} style={{display: "inline-block"}}>
                                    <div onClick={() => {this.toggleStarActive(rating.id);}} 
                                    className="DishRateModalStars"> 
                                        <img className="DishRateModalStarsImage" 
                                            src={emojis[rating.id]} 
                                            alt="Rating Star Grey"
                                            style={emojiStyle[rating.id]}
                                        />
                                        <div style={emptySpace[rating.id]}></div>
                                    </div>
                                    <div style={ratingStyle[rating.id]}>
                                        {rating.value} 
                                        {/* <span style={ratingStarStyle[rating.id]}>&#9733;</span> */}
                                    </div>
                                </div>
                                :
                                <div key={rating.id} style={{display: "inline-block"}}>
                                    <div onMouseDown={() => {this.toggleStarActive(rating.id);}}
                                    onMouseEnter={() => this.makeEmojisBigger(rating.id)}
                                    onMouseLeave={ () => this.makeEmojisSmaller(rating.id)} 
                                    className="DishRateModalStars"> 
                                        <img className="DishRateModalStarsImage" 
                                            src={emojis[rating.id]} 
                                            alt="Rating Star Grey"
                                            style={emojiStyle[rating.id]}
                                        />
                                        <div style={emptySpace[rating.id]}></div>
                                    </div>
                                    <div style={ratingStyle[rating.id]}>
                                        {rating.value} 
                                        {/* <span style={ratingStarStyle[rating.id]}>&#9733;</span> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {this.formContainer()}
            </div>
        )
    }

    handleDescriptionInput(event){
        this.setState({})
        if (!this.state.isSaving){
            this.setState({
                description: event.target.value
            }, () => {
                if (this.state.description.length > 50){
                    this.setState({
                        charactersRemaining: 0
                    })
                } else {
                    this.setState({
                        charactersRemaining: 50 - this.state.description.length
                    })
                }
            });
            if (this.state.currentSelectedRatingID !== -1 && event.target.value.length >= 50){
                this.setState({
                    readyToSave: true
                })
            } else {
                this.setState({
                    readyToSave: false
                })
            }
        }
    }

    reviewCharacters() {
        if (this.state.charactersRemaining >0){
            return(
                <div className="WriteReviewCharacters">{this.state.charactersRemaining}</div>
            )
        } else {
            return null;
        }
    }

    formContainer(){
        var buttonColor = {};
        if (this.state.readyToSave){
            buttonColor = {backgroundColor: "#EA5B2F", marginTop:"10px"};
        } else {
            buttonColor = {backgroundColor: "rgba(234, 91, 47, 0.5)", marginTop:"10px"};
        }
        return (
            <div className="DishRateModalFormContainer" style={{paddingTop: "10px"}}>
                <div className="DishRateModalWriteReviewContainer" style={{margin:"5px 0px"}}>
                    <textarea maxLength="1000" value={this.state.description} onChange={(event) => this.handleDescriptionInput(event)} className="DishRateModalTextArea" placeholder="Write a Review (minimum 50 characters)"></textarea>
                    {this.reviewCharacters()}
                </div>
                <div style={buttonColor} onClick={() => this.handleFormSubmission()} className="DishRateModalSubmitButtonContainer">
                    {this.state.savingText}
                </div>
            </div>
        )
    }

    handleFormSubmission(){
        if (this.state.readyToSave && !this.state.isSaving){
            this.setState({
                savingText: "Processing..."
            })
            var user = JSON.parse(localStorage.getItem("user"));
            var user_id = user["id"];
            var token = localStorage.getItem("auth_token");
            var ratingItem = this.state.ratingList.filter((item) => item.id === this.state.currentSelectedRatingID)[0];
            var req = {
                rating: ratingItem.value,
                user: user_id,
                dish: this.props.dish.dishId,
                status: false,
                description: this.state.description
            }
            var headers = {
                'Authorization' : 'Bearer ' + token 
            }
            let url = CONSTANTS.hostURL + "api/main_app/review/";
            axios.post(url, req,  {
                headers: headers
            }).then((response) => {
                let ratings = [...this.state.ratingList];
                let rating = ratings[this.state.currentSelectedRatingID];
                this.closeModalHandler();
                this.props.showSuccessModal(rating);
            }).catch((error) => this.setState({
                isSaving: false,
                savingText: "Done"
            }));
        }
    }

    handleModalClick(e) {
        e.stopPropagation();
    }
    
    render() {
        let emojiStyle = [];
        let emojis = [];
        let emptySpace = [];
        let ratingStyle = [];
        let ratingStarStyle = [];
        for (var i=0; i < this.state.ratingList.length; i++){
            emojiStyle.push({});
            emojis.push("");
            emptySpace.push({});
            ratingStyle.push({});
            ratingStarStyle.push({});
            if (this.state.ratingList[i].hovered === true){
                emojiStyle[i] = {width: "30px"}
                emojis[i] = this.state.ratingList[i].imageHover;
                emptySpace[i] = {width: "0px"};
                ratingStyle[i] = {fontSize: "12px", textAlign: "center", color: "black", fontWeight: "bold"};
                ratingStarStyle[i] = {color: "black"};
            } else {
                if (this.state.isMobileScreen){
                    emojiStyle[i] = {width: "20px"};
                } else {
                    emojiStyle[i] = {width: "30px"};
                }
                emojis[i] = this.state.ratingList[i].image;
                emptySpace[i] = {width: "0px"};
                if (this.state.isMobileScreen){
                    ratingStyle[i] = {fontSize: "8px", textAlign: "center", color: "#A9A9A9"};
                } else {
                    ratingStyle[i] = {fontSize: "12px", textAlign: "center", color: "#A9A9A9"};
                }
                ratingStarStyle[i] = {color: "#A9A9A9"};
            }
            if (this.state.ratingList[i].selected === true){
                if(this.state.currentSelectedRatingID === i){
                    if (this.state.isMobileScreen){
                        ratingStyle[i] = {fontSize: "8px", textAlign: "center", color: "black", fontWeight: "bold", textShadow: "0px 3px 40px #00000029", margin: "0px 3px"};
                    } else {
                        ratingStyle[i] = {fontSize: "12px", textAlign: "center", color: "black", fontWeight: "bold", textShadow: "0px 3px 40px #00000029", margin: "0px 3px"};
                    }
                }
                else{
                    if (this.state.isMobileScreen){
                        ratingStyle[i] = {fontSize: "8px", textAlign: "center", color: "#A9A9A9"};
                    } else {
                        ratingStyle[i] = {fontSize: "12px", textAlign: "center", color: "#A9A9A9"};
                    }
                }
                
                if (this.state.isMobileScreen){
                    emojiStyle[i] = {width: "20px"};
                } else {
                    emojiStyle[i] = {width: "30px"};
                }
                emojis[i] = this.state.ratingList[i].imageHover;
                emptySpace[i] = {width: "0px"};
                ratingStarStyle[i] = {color: "#FFEC36"};
            }
        }
        if(!this.props.showRatingsModal){
            return null;
        }
        return(
            <div className="RatingModal" onClick={this.closeModalHandler}>
                <div onClick={(e) => this.handleModalClick(e)} className="RatingModalContainer" style={{borderRadius:"10px", background: "transparent linear-gradient(180deg, #FCDA4B, #FFFBDB 50%, #FFFFFF 80%) 0% 0% no-repeat padding-box"}}>
                    <div onClick={this.closeModalHandler} title="Close" className="close">X</div>
                    <div className="ddDishImageModalContainer">
                        <img className={`${this.state.isMobileScreen ? "DishImageModalMobile" : "ddDishImageModal"}`} src={this.props.dish.image === null ? DefaultImage : this.props.dish.image} alt="Dish Pic" />
                    </div>
                    {this.ratingContainer(emojiStyle, emojis, emptySpace, ratingStyle, ratingStarStyle)}
                </div>
            </div>
        )
    }
}

export default DishRatingModal;