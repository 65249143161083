import React, { Component } from 'react';
import '../assets/styles/Settings.css';
import { Grid } from '@material-ui/core';
import '../assets/styles/Preferences.css';
import update from 'react-addons-update';
import CONSTANTS from '../config/constants';
import axios from 'axios';
import { withRouter } from 'react-router';
import logo from '../assets/images/logo_white.png';
import dish_card1 from '../assets/images/dish_card1.png';
import dish_card2 from '../assets/images/dish_card2.png';
import dish_card3 from '../assets/images/dish_card3.png';
import dish_card4 from '../assets/images/dish_card4.png';
import dish_card5 from '../assets/images/dish_card5.png';
import dish_card6 from '../assets/images/dish_card6.png';
import dish_card7 from '../assets/images/dish_card7.png';
import dish_card8 from '../assets/images/dish_card8.png';
import dish_card9 from '../assets/images/dish_card9.png';
import dish_card10 from '../assets/images/dish_card10.png';
import dish_card11 from '../assets/images/dish_card11.png';
import dish_card12 from '../assets/images/dish_card12.png';

class Preferences extends Component {

  constructor() {
    super();
    this.loadCuisines = this.loadCuisines.bind(this);
    this.toggleImageHover = this.toggleImageHover.bind(this);
    this.handlePreferenceSubmit = this.handlePreferenceSubmit.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.state = { 
      isOpen: false,
      logoutModal: false,
      searchModal: false,
      allPreferenceLoaded: false,
      preferenceCount: 0,
      preferenceLoaded: 0,
      preferences : [],
      preferenceSelected: 0,
      allPreferenceSelected: false,
      dish_cards: [dish_card1, dish_card2, dish_card3, dish_card4, dish_card5, dish_card6,
      dish_card7, dish_card8, dish_card9, dish_card10, dish_card11, dish_card12],
      addingPreference: false,
      isMobileScreen: false,
      backgroundColors: ["#FFFFFF", "#FFF05A" , "#EA5B2F"],
      screenWidth: 0
    }
  } 

  updateDimensions() {
      let screen_width = window.innerWidth - 20;
      if(screen_width < 400) {
          this.setState({ noOfItems: 1 });
      } else if(screen_width < 800) {
        this.setState({ noOfItems: 2 });
      } else if (screen_width < 1200){
          this.setState({ noOfItems: 3 }); 
      } else if (screen_width < 2000){
          this.setState({ noOfItems: 4 }); 
      } else {
          this.setState({noOfItems: 5});
      }
      screen_width = window.innerWidth;
      this.setState({
        screenWidth: screen_width
      })
      if(screen_width < 600) {
          this.setState({ isMobileScreen: true });
      } else {
          this.setState({ isMobileScreen: false });
      }
  }

  loadCuisines() {
    let url = CONSTANTS.hostURL + "api/main_app/cuisine/";
    axios.get(url).then(res => {
      let cuisineList = this.state.preferences;
      let results = res.data.results;
      for (var i=0; i<results.length; i++){
        let cuisineItem = results[i];
        let item = {};
        item.id = i;
        item.preferenceId = cuisineItem.id;
        item.name = cuisineItem.name;
        item.image = "";
        if (cuisineItem.image !== null){
          item.image = cuisineItem.profile_image_url;
        }
        item.selected = false;
        cuisineList.push(item);
      }
      this.setState({
        preferences: cuisineList,
        preferenceCount: res.data.count,
        preferenceLoaded: results.length
      }, this.fetchMoreCuisines(res.data.count, results.length, 2));
    })
  }

  fetchMoreCuisines(cuisineCount, cuisinesLoaded, nextPage) {
    if(cuisineCount>cuisinesLoaded && !this.state.moreCuisineLoading){
      this.setState({
        moreCuisineLoading: true
      })
      let url = CONSTANTS.hostURL + "api/main_app/cuisine/?limit=12&page=" + nextPage;
      axios.get(url).then(res => {
        let cuisineList = this.state.preferences;
        let results = res.data.results;
        for (var i=0; i<results.length; i++){
          let cuisineItem = results[i];
          let item = {};
          item.id = i + cuisinesLoaded;
          item.preferenceId = cuisineItem.id;
          item.name = cuisineItem.name;
          item.image = "";
          if (cuisineItem.image !== null){
            item.image = cuisineItem.profile_image_url;
          }
          item.selected = false;
          cuisineList.push(item);
        }
        this.setState({
          preferences: cuisineList,
          preferenceCount: res.data.count,
          preferenceLoaded: results.length + cuisinesLoaded
        }, this.fetchMoreCuisines(res.data.count, results.length + cuisinesLoaded, nextPage + 1));
        })
      } else {
        this.setState({
          allPreferenceLoaded: true
        });
      }
  }

  componentDidMount(){
    window.scrollTo(0,0);
    var preferencesPresent = localStorage.getItem("preferences_present");
    if (preferencesPresent !== null && preferencesPresent === "true"){
      this.props.history.replace("/home");
    }
    this.loadCuisines();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  preventDefault(e) {
    e.preventDefault();
  }

  preventDefaultForScrollKeys(e) {
    var keys = {9: 1, 37: 1, 38: 1, 39: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};
    if (keys[e.keyCode]) {
      e.preventDefault();
      return false;
    }
  }

  disableScrolling(wheelOpt, wheelEvent) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
    window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  enableScrolling(wheelOpt, wheelEvent) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
    window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
  }

  componentDidUpdate() {  
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    if(this.state.logoutModal  || this.state.searchModal || this.state.isOpen){
      this.disableScrolling(wheelOpt, wheelEvent);
    } else {
      this.enableScrolling(wheelOpt, wheelEvent);
    }
  }

  componentWillUnmount(){
    try {
      var supportsPassive = false;
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return(supportsPassive) } 
      }));
    } catch(e) {}
    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    this.enableScrolling(wheelOpt, wheelEvent);
  }

  toggleImageHover(index) {
    if (!this.state.addingPreference){
      let items = this.state.preferences;
      let item = items[index];
      item.selected  = !item.selected;
      items[index] = item;
      this.setState(update(this.state, {
        preferences: {
              [index]: {
                  $set: item
              }
          }
      }), () => {
        var selectedPreferencesCount = this.state.preferences.filter((preference) => preference.selected).length;
        this.setState({
          preferenceSelected: selectedPreferencesCount
        });
      });
    }
  }

  getHoneyCombContainerHeigth(){
    if (this.state.isMobileScreen){
      if (this.state.screenWidth >= 360 && this.state.screenWidth < 600){
        return "560px";
      }
    } else {
      return "460px";
    }
  }

  toggleSelectAll(){
    if (!this.state.addingPreference){
      let items = this.state.preferences;
      items.forEach((item) => item.selected = !this.state.allPreferenceSelected);
      this.setState({
        preferences: items,
        allPreferenceSelected: !this.state.allPreferenceSelected
      }, () => {
        var selectedPreferencesCount = this.state.preferences.filter((preference) => preference.selected).length;
        this.setState({
          preferenceSelected: selectedPreferencesCount
        });
      });
    }
  }

  preferencesList(dishBeforeStyle, textBeforeStyle){
    if (this.state.allPreferenceLoaded){
      return (
        <div style={{margin: this.state.isMobileScreen ? "0vh 5vw 0vh 5vw" : "0vh 10vw 0vh 10vw", padding: "0px 1vw", display: "flex", flexDirection: "column", height: this.getHoneyCombContainerHeigth(), borderRadius: this.state.isMobileScreen? "160px" : "50vh", width: this.state.isMobileScreen ? "90vw" : "80vw", backgroundColor: "#232323"}}>
          <div className="PreferenceListHeader" style={{textAlign: "center", margin: "15px auto 20px auto", color: "rgba(256,256,256, 0.4)", fontFamily: "Poppins-Regular", fontSize: "16px", maxWidth: "30vw"}}>Select the Cuisines <span style={{color: "white", fontFamily: "Poppins-SemiBold"}}>YOU</span> love (3 or more)</div>
          <div onClick={() => this.toggleSelectAll()} style={{textAlign: "center", margin: "auto", backgroundColor: "rgba(256,256,256, 0.2)", padding: "3px 8px", borderRadius: "4px", color: "rgba(256,256,256, 1)", fontFamily: "Poppins-Medium", fontSize: "13px", maxWidth: "30vw", cursor: "pointer"}}>{this.state.allPreferenceSelected ? "Deselect All" : "Select All"}</div>
          <div style={{flex: "1"}}>
            <ul className="honeycomb" lang="es">
              {this.state.preferences.map((preference) => {
                return (
                  <li onClick={() => this.toggleImageHover(preference.id)} key={"list" + preference.preferenceId} className="honeycomb-cell">
                    <img key={"image" + preference.preferenceId} className="honeycomb-cell__image" src={preference.image} alt=""/>
                    <div style={textBeforeStyle[preference.id]} key={"div2" + preference.preferenceId} className="honeycomb-cell__title">{preference.name}</div>
                    <div style={dishBeforeStyle[preference.id]} key={"div1" + preference.preferenceId} className="honeycomb-cell-before"></div>
                    <div style={{backgroundColor: this.state.backgroundColors[preference.id % 3]}} key={"div3" + preference.preferenceId} className="honeycomb-cell-after"></div>
                    <div className="honeycomb-cell-overlay"></div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{margin: "40px 10vw 6vh 10vw", padding: "0px 1vw", display: "flex", flexDirection: "column", height: "460px", borderRadius: "50vh", width: "80vw", backgroundColor: "#232323"}}>
          <div style={{textAlign: "center", margin: "auto", color: "rgba(256,256,256, 0.4)", fontFamily: "Poppins-Regular", fontSize: "15px", maxWidth: "40vw"}}>Loading Preferences, please wait...</div>
        </div>
      )
    }
  }

  handlePreferenceSubmit(){
    if (this.state.preferenceSelected >=3 && !this.state.addingPreference){
      this.setState({
        addingPreference: true
      })
      var token = localStorage.getItem("auth_token");
      var preference_list = []
      var selectedPreferences = this.state.preferences.filter((preference) => preference.selected);
      for (var i=0; i<selectedPreferences.length; i++){
        preference_list.push(selectedPreferences[i].preferenceId);
      }
      var headers = {
        'Authorization' : 'Bearer ' + token 
      }
      var req = {
        preference_list: preference_list
      }
      var url = CONSTANTS.hostURL + "api/accounts/preference/";
      axios.post(url, req,  {
        headers: headers
      }).then((response) => {
          localStorage.setItem("preferences_present", true);
          var user = JSON.parse(localStorage.getItem("user"));
          user.preferences = preference_list;
          localStorage.setItem("user", JSON.stringify(user));
          this.props.history.replace("/home");
      }).catch((error) => this.setState({
          addingPreference: false
      }));
    }
  }

  render() {
    var dishBeforeStyle = [];
    var textBeforeStyle = [];
    var buttonColor = {};
    for (var i=0; i<this.state.preferences.length; i++){
      var textStyle;
      if (this.state.preferences[i].selected){
        var beforeStyle = {backgroundColor: "#F2DE1D"};
        if (this.state.isMobileScreen){
          textStyle = {fontSize: "13px"};
        } else {
          textStyle = {fontSize: "15px"};
        }
      } else {
        beforeStyle = {}
        textStyle = {};
      }
      dishBeforeStyle.push(beforeStyle);
      textBeforeStyle.push(textStyle);
    }
    if (this.state.preferenceSelected >= 3){
        buttonColor = {backgroundColor: "#EA5B2F", margin: "auto", padding: "15px 4vw", borderRadius: "30px", fontFamily: "Poppins-SemiBold", cursor: "pointer"};
    } else {
        buttonColor = {backgroundColor: "#EFE7C8", color:"#C4AD48", margin: "auto", padding: "15px 4vw", borderRadius: "30px", fontFamily: "Poppins-SemiBold", cursor: "pointer"};
    }
    return (
      <div style={{backgroundColor: "#1F1F1F", width: "100vw", height: "100vh", display: "flex", flexDirection: "column", overflowY: "scroll"}} className="ddnoScroll">
          <div className="DishesHeader" style={{position: "fixed", top: "0px", left: "0px", zIndex: "10000", width: "100%", backgroundColor: "#1F1F1F"}}>
                <Grid container item xs={12} spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                    <Logo history={this.props.history} location={this.props.location}/>
                    {/* <Authentication toggleUserOptions={this.toggleUserOptionsHandler} history={this.props.history} toggleSearchModal={this.toggleSearchModalHandler}/> */}
                </Grid>
            </div>
            <div style={{paddingTop: "80px", height: "100vh"}}>
              <div style={{display: "flex", margin: "auto"}}>
                {this.preferencesList(dishBeforeStyle, textBeforeStyle)}
              </div>
              <div style={{color: "white", display: "flex", marginTop: this.state.isMobileScreen ? "-50px" : "10px"}}>
                <div style={{backgroundColor: "white", margin: "auto", borderRadius: "30px"}}>
                  <div className="PreferenceSubmitButton" onClick={() => this.handlePreferenceSubmit()} style={buttonColor}>{this.state.addingPreference ? "Processing..." : "Done"}</div>
                </div>
              </div>
            </div>
      </div>
    )
  };
}

// Website Logo
class Logo extends Component {
    render() {
        return (
            <Grid container item xs={6}>
                <React.Fragment>
                    <Grid item xs={12}>
                        <div className="Logo">
                            <img style={{cursor: "pointer"}} onClick={() => {
                                if (this.props.location.pathname !== "/home"){
                                    this.props.history.push("/home")
                                }
                            }} className="LogoImage" src={logo} alt="logo"/>
                        </div>
                    </Grid>
                </React.Fragment>
            </Grid>
        )
    }
}

export default withRouter(Preferences);
