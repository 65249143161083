import React, { Component } from 'react';
import ratings from '../../assets/images/ratings_white.svg';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class ReviewUsers extends Component{

    dotColour(dish){
        if (dish.choice === "V"){
            return {backgroundColor: "green", border: "2px solid white", outline: "2px solid green"};
        } else if (dish.choice === "N") {
            return {backgroundColor: "red", border: "2px solid white", outline: "2px solid red"};
        } else {
            return {backgroundColor: "orange", border: "2px solid white", outline: "2px solid green"};
        }
    }

    render(){
        if (this.props.reviewsFetched){
            if (this.props.reviews.length){
                return(
                    <div style={{marginTop: "40px"}}>
                        {
                            this.props.reviews.map((review) => {
                                return (
                                    <div key={review.reviewId} className="TabLikesContainer">
                                        <div style={this.dotColour(review)} className="TabLikesDot"></div>
                                        <div className="TabLikesDescription">
                                            <div className="TabLikesHeader">{review.dish} <span className="TabLikesDivider">|</span><span className="TabLikesCost">Rs. {review.cost}</span></div>
                                            <Link  to={'/restaurants/' + review.restaurantID} style={{ textDecoration: 'none' }}>
                                                <div style={{cursor: "pointer"}} className="TabLikesRestaurant">{review.restaurant}</div>
                                            </Link>
                                            <div className="TabLikesReview">{review.description}</div>
                                        </div>
                                        <div style={{height: "18px", marginRight: "1vw"}} className="DishReviewRating">{review.rating}<img className="DishReviewRatingImage" src={ratings} alt="Ratings White Star"/></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            } else {
                return(
                    <div className="TabLikesReview" style={{marginTop: "80px", textAlign: "center", fontSize: "20px"}}>
                        No reviews present.
                    </div>
                )
            }
        } else {
            return(
                <div className="TabLikesReview" style={{marginTop: "80px", textAlign: "center", fontSize: "20px"}}>
                    Fetching reviews...
                </div>
            )
        }
    }
}

export default withRouter(ReviewUsers);