import React from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import curve_image from '../../assets/images/pinkBubble.png';
import image03 from '../../assets/images/03.png';
import image04 from '../../assets/images/04.png';
import image05 from '../../assets/images/05.png';
import image06 from '../../assets/images/06.png';
import '../../assets/styles/AboutUs.css';
import { Component } from 'react';

// Description of the website 
class Segment3 extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            gridSize: 4,
            buttonID: "signup-button-content",
            isMobileScreen: false
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let screen_width = window.innerWidth;
        if(screen_width < 500) {
            this.setState({ isMobileScreen: true });
            this.setState({ buttonID: "signup-button-content-mobile" });
        } else {
            this.setState({ isMobileScreen: false });
            this.setState({ buttonID: "signup-button-content" });
        }
    }

    render () {
        if (this.state.isMobileScreen){
            return(
                <Grid style={{marginLeft:'-2%', backgroundColor: "#fef8db", paddingBottom : '30px'}}>
                    <Grid style={{height: '28vw', overflow: 'hidden'}} container spacing={0} direction="row" justifyContent="space-between">
                        <img className="CurveImage" style={{marginLeft:'8px'}} src={curve_image} alt="curve_img"/>
                    </Grid>
                    <Grid container spacing={0} direction="row" style={{marginTop:'-5%'}}>
                        <div style={{margin: "auto"}}>
                            <div className="topText">
                                <br/>What we <span style={{color: "#EA5B2F", marginLeft:'0'}}>Do?<br/><br/></span>
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent='center'>
                        <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'2%'}}>
                            <CardMedia style={{margin:'auto'}}>
                                <div style={{alignItems: 'center', position:'relative'}}>
                                <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image03} alt="image03"/>
                                </div>
                            </CardMedia>
                            <br/>
                            <CardContent>
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Curate the Best Dishes<br/>in your area.</span><br/>
                                    <span style={{fontFamily: 'Poppins-Regular', fontSize: '3vw'}}>(Only 'that one best dish'<br/>from every restaurant)</span>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent='center'>
                        <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'2%'}}>
                            <CardMedia style={{margin:'auto'}}>
                                <div style={{alignItems: 'center'}}>
                                    <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image04} alt="image04"/>
                                </div>
                            </CardMedia>
                            <br/>
                            <CardContent>
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Suggest dishes<br/>Customised to your taste</span><br/>
                                    <span style={{fontFamily: 'Poppins-Regular', fontSize: '3vw'}}>(Personalize your experience)</span>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent='center'>
                        <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'2%'}}>
                            <CardMedia style={{margin:'auto'}}>
                                <div style={{alignItems: 'center'}}>
                                    <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image06} alt="image05"/>
                                </div>
                            </CardMedia>
                            <br/>
                            <CardContent>
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Save your time</span><br/>
                                    <span style={{fontFamily: 'Poppins-Regular', fontSize: '3vw'}}>(no more browsing through the<br/>reviews before ordering)</span>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent='center' style={{marginBottom:'20px'}}>
                        <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'2%'}}>
                            <CardMedia style={{margin:'auto'}}>
                                <div style={{alignItems: 'center'}}>
                                    <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image05} alt="image06"/>
                                </div>
                            </CardMedia>
                            <br/>
                            <CardContent>
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Help you Have<br/>Only Good Food days</span><br/>
                                    <span style={{fontFamily: 'Poppins-Regular', fontSize: '3vw'}}>Forever!</span>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
        else{
            return (
                <Grid container direction="row" alignItems="center" style={{backgroundColor: "#fef8db"}}>
                    <Grid style={{height: '28vw', overflow: 'hidden'}} container spacing={0} direction="row" justifyContent="space-between" alignItems="stretch">
                        <img className="CurveImage" src={curve_image} alt="curve_img"/>
                    </Grid>
                    <Grid container spacing={0} direction="row" style={{marginTop:'-5%'}}>
                        <div style={{margin: "auto"}}>
                            <div className="topText">
                                <br/>What we <span style={{color: "#EA5B2F", marginLeft:'0'}}>Do?<br/><br/></span>
                            </div>
                        </div>
                    </Grid>
                    <div style={{margin:'auto'}}>
                        <Grid container spacing={2} justifyContent="center">
                            <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px'}}>
                                <CardMedia style={{margin:'auto'}}>
                                    <div style={{alignItems: 'center', position:'relative'}}>
                                    <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image03} alt="image03"/>
                                    </div>
                                </CardMedia>
                                <br/>
                                <CardContent>
                                    <div style={{textAlign: 'center'}}>
                                        <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Curate the Best Dishes<br/>in your area.</span><br/>
                                        <span style={{fontFamily: 'Poppins-Regular', fontSize: '1vw'}}>(Only 'that one best dish'<br/>from every restaurant)</span>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'5%'}}>
                                <CardMedia style={{margin:'auto'}}>
                                    <div style={{alignItems: 'center'}}>
                                        <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image04} alt="image04"/>
                                    </div>
                                </CardMedia>
                                <br/>
                                <CardContent>
                                    <div style={{textAlign: 'center'}}>
                                        <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Suggest dishes<br/>Customised to your taste</span><br/>
                                        <span style={{fontFamily: 'Poppins-Regular', fontSize: '1vw'}}>(Personalize your experience)</span>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px'}}>
                                <CardMedia style={{margin:'auto'}}>
                                    <div style={{alignItems: 'center'}}>
                                        <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image06} alt="image05"/>
                                    </div>
                                </CardMedia>
                                <br/>
                                <CardContent>
                                    <div style={{textAlign: 'center'}}>
                                        <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Save your time</span><br/>
                                        <span style={{fontFamily: 'Poppins-Regular', fontSize: '1vw'}}>(no more browsing through the<br/>reviews before ordering)</span>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card elevation={24} style={{height: '340px', width: '250px', borderRadius: '8px', marginTop:'5%'}}>
                                <CardMedia style={{margin:'auto'}}>
                                    <div style={{alignItems: 'center'}}>
                                        <img style={{width:'150px', marginLeft: '20%', marginTop:'5%'}} src={image05} alt="image06"/>
                                    </div>
                                </CardMedia>
                                <br/>
                                <CardContent>
                                    <div style={{textAlign: 'center'}}>
                                        <span style={{fontFamily: 'Quicksand-Bold', letterSpacing: "-1px"}}>Help you Have<br/>Only Good Food days</span><br/>
                                        <span style={{fontFamily: 'Poppins-Regular', fontSize: '1vw'}}>Forever!</span>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </div>
                </Grid>
            )
        }
    }
}

export default Segment3;