import beverages from './assets/images/beverages.svg';
import appetizers from './assets/images/appetizers.svg';
import breakfast from './assets/images/breakfast.svg';
import dessert from './assets/images/dessert.svg';
import main_course from './assets/images/main_course.svg';
import salads from './assets/images/salads.svg';
import restaurant_cover from './assets/images/restaurant_cover.png';

export const dishTypeImages = [beverages, appetizers, breakfast, salads, main_course, dessert,
    beverages, appetizers, breakfast, salads, main_course, dessert, beverages, appetizers, breakfast];

export const checkValidResult = response => {
    return response?.data?.status === 1;
}

export const getFormattedOfferList = (offers, offersLoaded) => {
    let formattedOffers = (offers ?? []).map((offer, index) => {
        return {
            id: index + offersLoaded,
            offerId: offer.id,
            restaurantId: offer.restaurant.id,
            restaurant: offer.restaurant.name,
            tagline: offer.tagline,
            description: offer.description,
        };
    });
    return formattedOffers;
}

export const getFormattedDishTypeList = (dishTypes, dishTypesLoaded) => {
    let formattedDishTyppes = (dishTypes ?? []).map((dishType, index) => {
        return {
            id: index + dishTypesLoaded,
            dishTypeId: dishType.id,
            name: dishType.name,
            image: dishType.profile_image_url ?? dishTypeImages[index],
            isActive: false,
            display: true,
            type: "dish_type",
        };
    });
    return formattedDishTyppes;
}

export const getFormattedCuisines = (cuisines, cuisinesLoaded) => {
    let formattedCuisines = (cuisines ?? []).map((cuisine, index) => {
        return {
            id: index + 1 + cuisinesLoaded,
            cuisineId: cuisine.id,
            name: cuisine.name,
            isActive: false,
            display: true,
            activated: false,
        };
    });
    return formattedCuisines;
}

export const getFormattedDishes = (dishes, dishesLoaded) => {
    let formattedDishes = (dishes ?? []).map((dish, index) => {
        return {
            id: index + dishesLoaded,
            dishId: dish.id,
            name: dish.name,
            image: dish.profile_image_url,
            cost: dish.cost,
            discounted_cost: dish.discounted_cost,
            choice: dish.choice,
            description: dish.description,
            liked: dish.is_liked,
            rating: dish.avg_rating,
            no_rating: dish.rating_count,
            in_stock: dish.in_stock,
            discount: dish.restaurant.discount_percent ?? 0,
            is_veg: dish.choice === 'V',
            vegDisplay: true,
            searchDisplay: true,
            restaurantId: dish.restaurant.id,
            restaurant: dish.restaurant.name,
            customizations: dish.customizations,
            discount_present: dish.discount && dish.discount !== 0,
            is_accepting_orders: dish.restaurant.is_accepting_orders,
            is_delivery_available: dish.restaurant.is_delivery_available,
        };
    });
    return formattedDishes;
}

export const getFormatedDishAddingStates = (dishes) => {
    let formattedDishAddingStates = (dishes ?? []).map(dish => {
        return {
            dishID: dish.id,
            addingState: false,
        };
    });
    return formattedDishAddingStates;
}

export const getFormattedRestaurant = (restaurant) => {
    return {
        id: restaurant.id,
        name: restaurant.name,
        address: restaurant.address,
        cost_for_two: restaurant.cost_for_two,
        profile_image_url: restaurant.profile_image_url ?? restaurant_cover,
        description: restaurant.description,
        discount: restaurant.discount_percent,
        is_accepting_orders: restaurant.is_accepting_orders,
        is_delivery_available: restaurant.is_delivery_available,
        sub_menus: restaurant.sub_menus,
        best_dish_zone_description: restaurant.best_dish_description,
        popularDishDescription: restaurant.popular_dish_description
    };
}

export const getFormattedSubMenus = (sub_menus) => {
    let formattedSubMenus = (sub_menus ?? []).map((sub_menu, index) => {
        return {
            id: index + 1,
            dishTypeId: sub_menu.id,
            name: sub_menu.name,
            type: "dish_type",
            isActive: false,
            display: true,
        };
    });
    return formattedSubMenus;
}

export const getFormattedNonRatedDishItems = (sub_menus) => {
    let formattedNonRatedDishItems = (sub_menus ?? []).map((sub_menu, index) => {
        return {
            id: index,
            title: sub_menu.name,
            dishes: []
        };
    });
    return formattedNonRatedDishItems;
}

export const getDishFilterObject = (vegFilterActive, isPersonalizedShown, filteredCuisines, filteredDishTypes, searchText) => {
    let filterObject = {};
    if (vegFilterActive){
        filterObject.choice = "V";
    }
    if (isPersonalizedShown){
        filterObject.personalized = "1";
    }
    if (filteredCuisines?.length > 0){
        filterObject.cuisine = filteredCuisines;
    }
    if (filteredDishTypes?.length > 0){
        filterObject.type = filteredDishTypes;
    }
    if (searchText?.length > 2){
        filterObject.name = searchText;
    }
    return {
        filterPresent: Object.keys(filterObject).length !== 0,
        value: filterObject,
    };
}

export const getDishSortingObject = (sortType, order) => {
    let sortObject = {};
    if (sortType){
        sortObject = {
            field: sortType,
            direction: order === "descending" ? "desc" : "asc"
        }
    }
    return {
        sortPresent: Object.keys(sortObject).length !== 0,
        value: sortObject,
    };
}

export const isSameArray = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}